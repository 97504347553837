import { Component, OnInit } from '@angular/core';
import { SignerLandingService } from '../../services/signer-landing.service';
import { CommonService } from '../../services/common.service';
import { Router } from '@angular/router';
import { CommonConstants, EnvelopeStatus, RecipientType, ResourceKeys, RouteUrls } from '../../constants/common-constants';
import { TranslationsService } from '../../services/translations.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeclineModalComponent } from 'src/app/modal/decline-modal/decline-modal.component';
import { ChangeSignerModalComponent } from 'src/app/modal/change-signer-modal/change-signer-modal.component';
import { ToastrService } from 'ngx-toastr';
import { AttachmentsComponent } from 'src/app/modal/attachments/attachments.component';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-sign-multi-template',
  templateUrl: './sign-multi-template.component.html',
  styleUrls: ['./sign-multi-template.component.scss']
})
export class SignMultiTemplateComponent implements OnInit {
  htmlFooterString: string = '';

  showAndHideDecline: boolean = false;
  showAndHideChangeSigner: boolean = false;

  constructor(private router: Router, private signerLandingService: SignerLandingService, private commonService: CommonService, private modalService: NgbModal,
    private toastr: ToastrService, private translationsService: TranslationsService, private titleService:Title) { }
  showLoader: boolean = false;
  SignMultipleTemplate: any;
  UploadMaxID: any;
  IsAllSigned: boolean = false;
  SignMultipleTemplateCollection: any;
  EnvelopeAdditionalUploadInfoList: any;
  attachmentList: any = [];
  divShowCompleteText: boolean = false;
  PendingAttachRequests: any
  resourceKeys = ResourceKeys;
  auditTrailPopupStyle: any = "none";
  docList: any;

  ngOnInit(): void {
    this.titleService.setTitle("RSign | Sign Multiple Template");
    this.htmlFooterString = '<p> ' +
      '<a href="https://rpost.com/legal-notices/terms-and-conditions" target="_blank" class="footerLinks">General Terms & Legal Notices</a>: Click to view service level terms, conditions, privacy, ' +
      '<a class="footerLinks" href="https://rpost.com/legal-notices/legal-and-patent-notice" target="_blank"> patent, trademark</a>, and other service and legal notices. ' +
      '</p>';
    sessionStorage.removeItem("LaungaueTransalation");
    sessionStorage.removeItem("LanguageValidation");
    this.getTemplateDetailsForSignMultiTemplate();
  }

  getTemplateDetailsForSignMultiTemplate() {
    this.showLoader = true;
    let envelopeID: any = localStorage.getItem("EnvelopeID");
    let recipientID: any = localStorage.getItem("RecipientID");
    let url = CommonConstants.GetTemplateDetailsForSignMultiTemplate + "/" + envelopeID + "/" + recipientID;
    this.signerLandingService.getcall(url).subscribe(
      (resp: any) => {
        if (resp && resp.StatusCode == 200 && resp.StatusMessage.toLowerCase() == "ok") {
          let cultureInfo: string = '';
          if (resp.SignMultipleTemplate.CultureInfo == "") cultureInfo = "en-us";
          else cultureInfo = resp.SignMultipleTemplate.CultureInfo;
          localStorage.setItem("cultureInfo", cultureInfo);
          this.translationsService.getLanguageTranslations(null, null);
         
          setTimeout(() => {
            this.SignMultipleTemplate = resp.SignMultipleTemplate;
            this.SignMultipleTemplateCollection = resp.SignMultipleTemplate.SignMultipleTemplateCollection;
            this.UploadMaxID = resp.MaxUploadID;
            this.IsAllSigned = this.SignMultipleTemplate.IsAllSigned;
            this.EnvelopeAdditionalUploadInfoList = this.SignMultipleTemplate.EnvelopeAdditionalUploadInfoList;
            localStorage.setItem("EnvelopeID", this.SignMultipleTemplate.EnvelopeID);
            localStorage.setItem("RecipientID", this.SignMultipleTemplate.CurrentRecipientID);
            localStorage.setItem("CurrentRecipientEmail", this.SignMultipleTemplate.CurrentRecipientEmail);
            this.RefreshEnvelopeStats();

            this.showAndHideDecline =  this.SignMultipleTemplate.DisableDeclineOption;
            this.showAndHideChangeSigner = this.SignMultipleTemplate.DisableChangeSigner;

          }, 200);
        }
        else if (resp && resp.originalError && resp.originalError.error && resp.originalError.error.StatusCode == 400) {
          this.showLoader = false;
          localStorage.setItem("DisplayMessage", resp.originalError.error.Message);
          localStorage.setItem("MessageType", "Warning");
          this.commonService.getReturnUrlDirection(RouteUrls.Info);
        }
        else {
          this.showLoader = false;
          localStorage.setItem("DisplayMessage", "An Application error occurred.");
          localStorage.setItem("MessageType", "Error");
          localStorage.setItem("IsApplicationError", "true");
          localStorage.setItem("PageName", "SignMultiTemplate");
          localStorage.setItem("EnvelopeID", envelopeID);
          localStorage.setItem("EmailId", "");
          this.commonService.getReturnUrlDirection(RouteUrls.Info);
        }
      })
  }

  RefreshEnvelopeStats() {
    var url = CommonConstants.GetMasterEnvelopeStats;
    var uploadRequest = {
      EnvelopeId: this.SignMultipleTemplate.EnvelopeID,
      RecipientId: this.SignMultipleTemplate.CurrentRecipientID,
      EmailId: this.SignMultipleTemplate.CurrentRecipientEmail
    };

    this.signerLandingService.postcall(url, uploadRequest).subscribe(
      (resp: any) => {
        this.showLoader = false;
        if (resp && resp.StatusCode == 200 && resp.StatusMessage.toLowerCase() == "ok") {
          if (resp.Data != null || resp.Data != 'undefined') {
            if (resp.Data.TotalEnvelopes == resp.Data.TotalSigned && resp.Data.PendingAttachRequests == 0) {
              this.divShowCompleteText = true;
            }
            else {
              document.getElementById("lblPending")!.innerHTML = resp.Data.TotalPending;
              document.getElementById("lblSigned")!.innerHTML = resp.Data.TotalSigned;
              document.getElementById("lblPendingAttachments")!.innerHTML = resp.Data.PendingAttachRequests;
              this.PendingAttachRequests = resp.Data.PendingAttachRequests;
              this.divShowCompleteText = false;
            }
          }
        }
      });
  }

  UpdateEnvelopeRecords(event: any) {
    let chkToggleEnvelope: any = document.getElementById("chkToggleEnvelope");
    let table: any = document.getElementById("tblSignMultipleDocument");
    let tBodyRows = table.tBodies[0].rows;
    if (chkToggleEnvelope.checked) {
      Array.from(tBodyRows).forEach((row: any, idx) => {
        let rowEmail = row.getAttribute("data-env-recepient-email");
        if (this.SignMultipleTemplate.CurrentRecipientEmail != rowEmail) {
          row.style["display"] = "none";
        }
      });
    }
    else {
      Array.from(tBodyRows).forEach((row: any, idx) => {
        let rowEmail = row.getAttribute("data-env-recepient-email");
        if (this.SignMultipleTemplate.CurrentRecipientEmail != rowEmail) {
          row.style["display"] = "table-row";
        }
      });
    }
  }

  chkSelectAll() {
    let table: any = document.getElementById("tblSignMultipleDocument");
    let chkToggleEnvelope: any = document.getElementById("chkToggleEnvelope");
    let tBodyRows = table.tBodies[0].rows;
    let ckbox1: any = document.getElementById("ckbox1");
    if (ckbox1.checked) {
      Array.from(tBodyRows).forEach((row: any, idx) => {
        let index = row.getAttribute("index");
        let ckbox1: any = document.getElementById("inbox_" + index);
        if (ckbox1 != null && ckbox1 != undefined) {
          ckbox1.checked = true;
        }
      });
      chkToggleEnvelope.checked = true;
    }
    else {
      Array.from(tBodyRows).forEach((row: any, idx) => {
        let index = row.getAttribute("index");
        let ckbox1: any = document.getElementById("inbox_" + index);
        if (ckbox1 != null && ckbox1 != undefined) {
          ckbox1.checked = false;
        }
      });
      chkToggleEnvelope.checked = false;
    }
  }

  showToaster(msg: string, errorType: any) {
    if (errorType === "Error") { this.toastr.error(msg, errorType, { timeOut: 2000 }); }
    else if (errorType === "Warning") { this.toastr.warning(msg, errorType, { timeOut: 2000 }); }
    else if (errorType === "Success") { this.toastr.success(msg, errorType, { timeOut: 2000 }); }
  }

  openModal(modalVal: any) {
    if (modalVal === 'Decline') {
      this.checkDeclineValidations(modalVal);
    }
    else if (modalVal === 'Change Signer') {
      this.checkChangeSignerValidations(modalVal);
    }
    else if (modalVal === 'Attachments') {
      const modalRef = this.modalService.open(this.getModalPopup(modalVal), { windowClass: 'attachment-class', keyboard: false, backdrop: 'static', });
      modalRef.componentInstance.attachmentList = this.attachmentList;
      localStorage.setItem("IsGroupTemplateSignerAttachments", "true");
      modalRef.componentInstance.event.subscribe((attachmentsCount: any) => {
        if (attachmentsCount > 0) {
          document.getElementById("lblPendingAttachments")!.innerHTML = "0";
        }
        else {
          this.RefreshEnvelopeStats();
        }

        localStorage.setItem("IsGroupTemplateSignerAttachments", "false");
      });
    }
  }

  getModalPopup(val: any) {
    var retVal = null;
    switch (val) {
      case 'Decline':
        retVal = DeclineModalComponent;
        break;
      case 'Change Signer':
        retVal = ChangeSignerModalComponent;
        break;
      case 'Attachments':
        retVal = AttachmentsComponent;
        break;
    }
    return retVal;
  }

  checkDeclineValidations(modalVal: any) {
    let table: any = document.getElementById("tblSignMultipleDocument");
    let tBodyRows = table.tBodies[0].rows;
    let count = 0;

    Array.from(tBodyRows).forEach((row: any, idx) => {
      let index = row.getAttribute("index");
      let ckbox1: any = document.getElementById("inbox_" + index);
      if (ckbox1 != null && ckbox1 != undefined && ckbox1.checked) {
        count = count + 1;
      }
    });

    if (count > 0) {
      let signedCount: any = document.getElementsByClassName("text-green");
      if (signedCount != null && signedCount.count > 0) {
        this.showToaster('Envelope has been signed, now it can not be Declined or Terminated.', 'Warning');
        return;
      }
      else {
        this.modalService.open(this.getModalPopup(modalVal), { windowClass: 'decline-class', keyboard: false, backdrop: 'static', });
      }
    }
    else {
      this.showToaster('Please select one or more Templates to Decline or Terminate.', 'Warning');
      return;
    }
  }

  checkChangeSignerValidations(modalVal: any) {
    let table: any = document.getElementById("tblSignMultipleDocument");
    let tBodyRows = table.tBodies[0].rows;
    let count = 0;
    let recepientId = "";

    Array.from(tBodyRows).forEach((row: any, idx) => {
      let index = row.getAttribute("index");
      let ckbox1: any = document.getElementById("inbox_" + index);
      if (ckbox1 != null && ckbox1 != undefined && ckbox1.checked) {
        count = count + 1;
        if (recepientId == "")
          recepientId = row.getAttribute("data-env-recepient-id");
        else
          recepientId = recepientId + ',' + row.getAttribute("data-env-recepient-id");
      }
    });

    if (count > 0) {
      let signedCount: any = document.getElementsByClassName("text-green");
      if (signedCount != null && signedCount.count > 0) {
        this.showToaster('Envelope has been signed, now it can not be Delegated.', 'Warning');
        return;
      }
      else {
        let modalPopupObj = {
          envelopeID: this.SignMultipleTemplate.EnvelopeID,
          ipAddress: '',
          recipientID: recepientId.split(','),
          isMultiTemplate: true
        }
        const modalRef = this.modalService.open(this.getModalPopup(modalVal), { windowClass: 'changeSigner-modal', keyboard: false, backdrop: 'static', });
        modalRef.componentInstance.modalPopupObj = modalPopupObj;
      }
    }
    else {
      this.showToaster('Please select one or more Templates to Delegate.', 'Warning');
      return;
    }
  }

  finishEnvelope() {
    this.showLoader = true;
    var request = {
      EnvelopeId: this.SignMultipleTemplate.EnvelopeID,
      RecipientEmail: this.SignMultipleTemplate.CurrentRecipientEmail,
      RecipientMobile: this.SignMultipleTemplate.DialCode+this.SignMultipleTemplate.MobileNumber
    };
    var url = CommonConstants.FinishSubEnvelope;
    this.signerLandingService.postcall(url, request).subscribe(
      (resp: any) => {
        if (resp && resp.StatusCode == 200 && resp.StatusMessage.toLowerCase() == "ok") {
          let returnUrl: any;
          this.showLoader = false;
          if (resp.ReturnURL == "Info/Index") {
            returnUrl = RouteUrls.Info;
          }
          else {
            returnUrl = resp.ReturnURL;
          }
          this.commonService.getReturnUrlDirection(resp.ReturnURL);
        }
        else if (resp.Message != null && resp.ErrorAction == null) {
          // this.showToaster('Please Sign/Decline/Delegate all the documents to Finish.', 'Warning'); return;
          this.showLoader = false;
          if(resp.originalError && resp.originalError.error && resp.originalError.error.Message != ""){
            this.showToaster(resp.originalError.error.Message, 'Warning'); return;
          }
          else{
            this.showToaster(resp.Message, 'Warning'); return;
          }

        }
      });
  }

  actiondropdownChange(event: any, index: any, eid: any, rid: any, tid: any) {
    let sentActionsdropdowns: any = document.getElementById("sentActionsdropdowns_" + index);
    let selControlValue: any = sentActionsdropdowns.selectedOptions[0].value;

    var signDocumentUrl = '';
    if (tid != 'null' && tid != 'undefined' && tid != '' && tid != '0' && tid != 0 && tid != null)
      signDocumentUrl = eid + ',' + rid + ',' + tid;
    else
      signDocumentUrl = eid + ',' + rid + ',';

    if (selControlValue != "0") {
      switch (selControlValue) {
        case '88a6639f-ed04-40af-99ca-fad0e196f46d':
          this.openSignLink(signDocumentUrl, eid);
          break;
        case '4f54e5db-7fd5-4551-bcbf-9f22de811160':
          this.openRejectModalForDDOption();
          break;
        case 'f9dcfb52-2b8e-4d9f-96c7-6d02dcdddee3':
          this.openDelegateModalForDDOption(index, rid);
          break;
        case '61a41834-db59-4008-813e-8fac0a432e45':
          // downloadCurrentPDF(eid);
          break;
        case '11f52f5c-ebf1-4507-aab2-6f9f1831385f':
          this.LoadDetailAuditTrail(eid);
          break;
      }
    }
  }

  openSignLink(signDocumentUrl: any, rowEnvelopeID: any) {
    this.showLoader = true;
    let arrID: any = signDocumentUrl.split(',');
    let encrytedString: any;
    let emailId: any = "";
    if (arrID[2] != null) {
      encrytedString = 'EnvelopeId=' + arrID[0] + '&RecipientId=' + arrID[1] + "&TemplateKey=" + arrID[2] + "&EmailId=" + emailId;
    }
    else {
      encrytedString = 'EnvelopeId=' + arrID[0] + '&RecipientId=' + arrID[1] + "&EmailId=" + emailId;
    }
    let encryptURL = CommonConstants.EncryptQueryParamString + "/" + encrytedString;
    this.signerLandingService.getEncryptQueryString(encryptURL).subscribe(
      (encryptresp: any) => {
        let signerLandingUrl = environment.uiAngularBaseUrl + RouteUrls.SignerLanding + "?";
        signerLandingUrl = signerLandingUrl + encryptresp.EnvryptedEncodedText;
        this.showLoader = false;
        sessionStorage.setItem("isFromSignMultiTemplate", "true");
        window.location.href = signerLandingUrl;
      });
  }

  LoadDetailAuditTrail(rowEnvelopeID: any) {
    this.showLoader = true;
    var url = CommonConstants.GetBasicEnvelopeHistory + "/" + rowEnvelopeID;
    this.signerLandingService.getcall(url).subscribe(
      (result: any) => {
        this.showLoader = false;
        if (result && result.EnvelopeCodeDisplay != null) {
          this.ResetAuditTrailPopup();

          let spnAuditEnvelopeDisplayCode: any = document.getElementById("spnAuditEnvelopeDisplayCode");
          spnAuditEnvelopeDisplayCode.innerHTML = result.EnvelopeCodeDisplay;

          this.docList = result.DocumentList;

          let spnAuditSender: any = document.getElementById("spnAuditSender");
          spnAuditSender.innerHTML = result.Sender;

          let spnAuditRecipient: any = document.getElementById("spnAuditRecipient");
          spnAuditRecipient.innerHTML = result.RecipientNameList;

          let spnAuditSequential: any = document.getElementById("spnAuditSequential");
          spnAuditSequential.innerHTML = result.IsSequential == true ? this.getLanguageTranslationValue(ResourceKeys.Yes) : this.getLanguageTranslationValue(ResourceKeys.No);

          if (result.ReferenceCode != null && result.ReferenceCode != "") {
            let trAuditReferenceCode: any = document.getElementById("trAuditReferenceCode");
            trAuditReferenceCode.style.display = "block";
            let spnReferenceCodeAuditTrail: any = document.getElementById("spnReferenceCodeAuditTrail");
            spnReferenceCodeAuditTrail.innerHTML = result.ReferenceCode;
          }
          else {
            let trAuditReferenceCode: any = document.getElementById("trAuditReferenceCode");
            trAuditReferenceCode.style.display = "none";
          }

          if (result.ReferenceEmail != null && result.ReferenceEmail != "") {
            let trAuditReferenceEmail: any = document.getElementById("trAuditReferenceEmail");
            trAuditReferenceEmail.style.display = "block";
            let spnReferenceEmailAuditTrail: any = document.getElementById("spnReferenceEmailAuditTrail");
            spnReferenceEmailAuditTrail.innerHTML = result.ReferenceEmail;
          }
          else {
            let trAuditReferenceEmail: any = document.getElementById("trAuditReferenceEmail");
            trAuditReferenceEmail.style.display = "none";
          }

          if (result.StatusId == EnvelopeStatus.Completed && result.IsDiclaimerAccepted) {
            let trStatusDisclaimer: any = document.getElementById("trStatusDisclaimer");
            trStatusDisclaimer.style.display = "block";
          }
          else {
            let trStatusDisclaimer: any = document.getElementById("trStatusDisclaimer");
            trStatusDisclaimer.style.display = "none";
          }

          if (result.PasswordToSign != null || result.PasswordToOpen != null) {
            let passwordsignopen: any = document.getElementById("passwordsignopen");
            passwordsignopen.style.display = "block";

            let spnPasswordToSign: any = document.getElementById("spnPasswordToSign");
            spnPasswordToSign.innerHTML = result.PasswordToSign != null ? result.PasswordToSign : "";

            let spnPasswordToOpen: any = document.getElementById("spnPasswordToOpen");
            spnPasswordToOpen.innerHTML = result.PasswordToOpen != null ? result.PasswordToOpen : ""

            if (result.PasswordToSign != null && result.PasswordToOpen != null) {
              let passwordsign: any = document.getElementById("passwordsign");
              passwordsign.style.display = "block";
              let passwordopen: any = document.getElementById("passwordopen");
              passwordopen.style.display = "block";
            }
            else if (result.PasswordToSign != null && result.PasswordToOpen == null) {
              let passwordsign: any = document.getElementById("passwordsign");
              passwordsign.style.display = "block";
            }
            else if (result.PasswordToSign == null && result.PasswordToOpen != null) {
              let passwordopen: any = document.getElementById("passwordopen");
              passwordopen.style.display = "block";
            }
          }

          var rowHTML = "";
          result.RecipientsDetail.map(function (recipient: any, index: any) {
            rowHTML += "<tr>";
            rowHTML += '<td class="" style="font-size:14px">' + (recipient.StatusTypeID == "1B5D8208-B1BF-40E1-9FBE-1D18F8A87B44" ? recipient.FormattedSentDate : recipient.FormattedCreatedDateTime) + '</td>';
            rowHTML += '<td class="" style="font-size:14px">' + recipient.Description + '</td>';
            if (recipient.StatusTypeID == "3B229C22-16AE-48BB-AA06-CF05C78A60D7") {  //Delegated
              rowHTML += '<td class="fixedSize" style="font-size:14px">' + '<section>' + recipient.DelegateFrom + '</section>' + '<section>' + recipient.DelegateFromEmail + '</section>' + '</td>';
              rowHTML += '<td class="fixedSize" style="font-size:14px">' + '<section>' + recipient.DelegateTo + '</section>' + '<section>' + recipient.DelegateToEmail + '</td>';
            }
            else if (recipient.StatusTypeID == "7659B486-EBE3-4708-A351-BB12ED66DD6A" || recipient.StatusTypeID == "EFEB4B3C-D039-4677-9D7D-758AD8ACCBCC" || recipient.Description == "Edited, Pending") {//Resend,Edit
              rowHTML += '<td class="fixedSize" style="font-size:14px">' + '<section>' + recipient.CurrentRecipient + '</section>' + '<section>' + recipient.CurrentEmail + '</section>' + '</td>';
              rowHTML += '<td class="fixedSize" style="font-size:14px">' + '<section>' + recipient.OldRecipient + '</section>' + '<section>' + recipient.OldEmail + '</td>';
            }
            else {
              rowHTML += '<td class="fixedSize" style="font-size:14px">' + '<section>' + recipient.Name + '</section>' + '<section>' + recipient.EmailAddress + '</section>' + '</td>';
              rowHTML += '<td class="fixedSize" style="font-size:14px">' + '<section> - </section>' + '<section> - </section>' + '</td>';
            }
            rowHTML += '<td class="fixedSize" style="font-size:14px">' + (recipient.UpdatedBy != "" && recipient.UpdatedBy != null ? recipient.UpdatedBy : '-') + '</td>';
            rowHTML += '<td class="fixedSize" style="font-size:14px">' + (recipient.IPAddress != "" && recipient.IPAddress != null ? recipient.IPAddress : '-') + '</td>';
            rowHTML += "</tr>"
          });

          let tableTBody: any = document.getElementById("tableTBody");
          tableTBody.innerHTML = rowHTML;

          this.auditTrailPopupStyle = "block";
        }
        else if (result.StatusCode == 203 && result.Message != null && result.ErrorAction == null) {
          this.showToaster(result.Message, 'Warning'); return;
        }
      });
  }

  ResetAuditTrailPopup() {
    let spnAuditEnvelopeDisplayCode: any = document.getElementById("spnAuditEnvelopeDisplayCode");
    spnAuditEnvelopeDisplayCode.innerHTML = "";

    let spnAuditSender: any = document.getElementById("spnAuditSender");
    spnAuditSender.innerHTML = "";

    let spnAuditRecipient: any = document.getElementById("spnAuditRecipient");
    spnAuditRecipient.innerHTML = "";

    let spnAuditSequential: any = document.getElementById("spnAuditSequential");
    spnAuditSequential.innerHTML = "";

    let trStatusDisclaimer: any = document.getElementById("trStatusDisclaimer");
    trStatusDisclaimer.style.display = "none";

    let passwordsignopen: any = document.getElementById("passwordsignopen");
    passwordsignopen.style.display = "none";

    let passwordsign: any = document.getElementById("passwordsign");
    passwordsign.style.display = "none";

    let passwordopen: any = document.getElementById("passwordopen");
    passwordopen.style.display = "none";

    let spnPasswordToSign: any = document.getElementById("spnPasswordToSign");
    spnPasswordToSign.innerHTML = "";

    let spnPasswordToOpen: any = document.getElementById("spnPasswordToOpen");
    spnPasswordToOpen.innerHTML = "";

    let tableTBody: any = document.getElementById("tableTBody");
    tableTBody.innerHTML = "";
  }

  openRejectModalForDDOption() {
    this.modalService.open(this.getModalPopup('Decline'), { windowClass: 'decline-class', keyboard: false, backdrop: 'static', });
  }

  openDelegateModalForDDOption(index: any, rid: any) {
    let modalPopupObj = {
      envelopeID: this.SignMultipleTemplate.EnvelopeID,
      ipAddress: '',
      recipientID: rid.split(','),
      isMultiTemplate: true
    }
    const modalRef = this.modalService.open(this.getModalPopup('Change Signer'), { windowClass: 'changeSigner-modal', keyboard: false, backdrop: 'static', });
    modalRef.componentInstance.modalPopupObj = modalPopupObj;
  }

  getLanguageTranslationValue(resourcekeyId: any) {
    return this.translationsService.getLanguageTranslationVal(resourcekeyId);
  }

  onAuditTrailClose() {
    this.modalService.dismissAll();
    this.auditTrailPopupStyle = 'none';
  }

  viewDocument(eid: any, docId: any) {
    this.showLoader = true;
    let url = CommonConstants.DownloadDocument + "/" + eid+ "/" + docId + "/0";
    this.signerLandingService.getcall(url).subscribe(
      (result: any) => {
        this.showLoader = false;
        if (result && result.StatusCode == 200) {
          let base64FileData: any = result.Base64FileData;
                if (base64FileData) {
                    this.toastr.success('Downloaded successfully.', 'Success', { timeOut: 2000 });
                    var blob = this.commonService.b64toBlob(base64FileData, 'application/pdf');
                    let a = document.createElement('a');
                    document.body.appendChild(a);
                    let bloburl: any = window.URL.createObjectURL(blob);
                    a.href = bloburl;
                    a.download = String(result.FileName);
                    a.click();
                    window.URL.revokeObjectURL(bloburl);
                    a.remove();
                }
        }
        else if (result.StatusCode == 203 && result.Message != null && result.ErrorAction == null) {
          this.showToaster(result.Message, 'Warning'); return;
        }
      });
  }

}
