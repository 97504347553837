import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'underscore';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { CommonConstants, ResourceKeys, UploadDriveType } from 'src/app/constants/common-constants';
import { SignerLandingService } from 'src/app/services/signer-landing.service';
import { TranslationsService } from 'src/app/services/translations.service';
import { environment } from '../../../environments/environment';

declare let gapi: any;
declare let google: any
declare var Dropbox: Dropbox;
declare var OneDrive: OneDrive;

interface Dropbox {
    choose(options: DropboxChooseOptions): void;
}
interface DropboxChooseOptions {
    success(files: DropboxFile[]): void;
    cancel?(): void;
    linkType: "direct";
    multiselect: boolean;
    extensions?: string[];
}
interface DropboxFile {
    name: string;
    link: string;
    bytes: number;
    icon: string;
    thumbnailLink?: string;
    isDir: boolean;
}

interface OneDriveResult {
    value: OneDriveFile[];
    webUrl: string | null;
}
interface OneDriveFile {
    "@microsoft.graph.downloadUrl": string;
    "thumbnails@odata.context": string;
    id: string;
    name: string;
    size: number;
    thumbnails: Thumbnails[];
    webUrl: string;
}
interface Thumbnails {
    id: string;
    large: Thumbnail;
    medium: Thumbnail;
    small: Thumbnail;
}

interface Thumbnail {
    height: number;
    width: number;
    url: string;
}
interface OneDriveOpenOptions {
    clientId: string;
    action: "download" | "share" | "query";
    multiSelect: boolean;
    openInNewWindow: boolean;
    advanced: {
        filter?: string;
        redirectUri: string
    }
    success(result: OneDriveResult): void;
    cancel(): void;
    error(e: any): void;
}

interface OneDrive {
    open(options: OneDriveOpenOptions): void;
}

@Component({
    selector: 'app-attachments',
    templateUrl: './attachments.component.html',
    styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent implements OnInit {
    attachmentsList: any[] = [];
    files: any[] = [];
    filesList: any[] = [];
    filesResponseList: any = [];
    duplicateFilesResponseList: any = [];
    processing: boolean = false;
    showLoader: boolean = false;
    googleDriveClientId = environment.googleDriveClientIdKey;
    oneDriveApplicationId = environment.oneDriveApplicationId;
    gooleDriveScope = ['https://www.googleapis.com/auth/drive.readonly'].join(' ');
    pickerApiLoaded = false;
    oauthToken: any;
    oauthData: any;
    showFileSelectionOptions: boolean = false;
    uploadDivStyle: string = 'none';
    uploadedfilename: any;
    isAddNewRowEnabled: boolean = false;
    extCheck: boolean = true;
    fileNameLength: boolean = true;
    folderCheck: boolean = true;
    fileCount: any = 0;
    foldersize: any = 0;
    uploadForm: any = FormGroup;
    form: FormGroup;
    maxUploadIDCount: any = 0;
    maxUploadID: any;
    envelopeID: any;
    recipientID: any;
    onOpenAddAttachmentId: any;
    confirmationPopupStyle: string = 'none';
    attachmentStyle: string = 'none';

    changedValues: any[] = [];
    callAddNewRowFlag: boolean = false;
    changedAttachments: any = [];
    operation: any;
    defaultmaxUploadID: any;
    attachmentModalObj: any;
    onOpenAddAttachmentIdRequired: boolean = false;
    public event: EventEmitter<any> = new EventEmitter();
    webOrMobile: any = 'web';
    isFromGroupTemplateSignerAttachments: boolean = false;
    resourceKeys = ResourceKeys;
    InviteSignerModel: any;
    IsAdditionalAttmReq: any;

    constructor(
        public activeModal: NgbActiveModal,
        private toastr: ToastrService,
        private fb: FormBuilder,
        private signerLandingService: SignerLandingService,
        private translationsService: TranslationsService
    ) {
        this.form = this.fb.group({
            txtName: [''],
            txtDescription: [''],
            txtAdditionalInfo: [''],
            file: [null]
        });
    }

    ngOnInit(): void {
        this.attachmentModalObj = this;
        this.envelopeID = localStorage.getItem('EnvelopeID')!;
        this.recipientID = localStorage.getItem('RecipientID')!;
        this.IsAdditionalAttmReq = localStorage.getItem('IsAdditionalAttmReq');

        let isStatic: any = localStorage.getItem("isStatic");
        if (isStatic == "true") {
            this.envelopeID = localStorage.getItem('StaticEnvelopId')!;
        }

        let inviteSignersData: any = localStorage.getItem("InviteSignerModel");
        if (inviteSignersData != "" && inviteSignersData != undefined && inviteSignersData != null) {
            this.InviteSignerModel = JSON.parse(inviteSignersData);
        }

        if ((/android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
            //  console.log('mobile')
            this.webOrMobile = 'mobile';
        }

        let isGroupTemplateSignerAttachments: any = localStorage.getItem("IsGroupTemplateSignerAttachments");
        if (isGroupTemplateSignerAttachments == "true") {
            this.isFromGroupTemplateSignerAttachments = true;
        }
        else {
            this.isFromGroupTemplateSignerAttachments = false;
        }

        if (this.isFromGroupTemplateSignerAttachments) {
            this.getAdditonalfileAttachmentInfoForGroups(false);
        }
        else {
            this.getAdditonalfileAttachmentInfo(false);
        }
    }

    //By defualt getting any saved attachment files are available in DB
    getAdditonalfileAttachmentInfo(inLineSave: boolean) {
        this.showLoader = true;
        let additionalRecipients: any = "";

        if (this.InviteSignerModel && this.InviteSignerModel != null && this.InviteSignerModel.length > 0) {
            Array.from(this.InviteSignerModel).forEach((item: any) => {
                if (item.SignNowOrInvitedEmail == 1 && item.RoleId != this.recipientID) {
                    if (additionalRecipients == "") {
                        additionalRecipients = item.RoleId;
                    }
                    else {
                        additionalRecipients = additionalRecipients + "," + item.RoleId;
                    }
                }
            });
        }
        else {
            additionalRecipients = "";
        }

        if (additionalRecipients == "") {
            additionalRecipients = "null"
        }

        let fileAttachmentInfoUrl: any = CommonConstants.GetAdditonalfileAttachmentInfo + '/' + this.envelopeID + '/' + this.recipientID + '/' + additionalRecipients;
        this.signerLandingService.getAdditonalfileAttachmentInfo(fileAttachmentInfoUrl).subscribe(
            (resp: any) => {
                this.showLoader = false;
                if (resp && resp.EnvelopeAdditionalUploadInfoList) {
                    if (!inLineSave) {
                        this.filesResponseList = resp;
                        this.duplicateFilesResponseList = resp;
                        this.maxUploadID = this.filesResponseList.MaxUploadID;

                        // if (this.attachmentModalObj.attachmentList && this.attachmentModalObj.attachmentList.length) {
                        //     this.attachmentModalObj.attachmentList.splice(0, this.attachmentModalObj.attachmentList.length);
                        //     this.filesResponseList.EnvelopeAdditionalUploadInfoList.forEach((ele: any, index: any) => {
                        //         if (ele.FileName != null && ele.FileName != "" && ele.FileName != undefined) {
                        //             this.attachmentModalObj.attachmentList.push(ele.FileName);
                        //         }
                        //     });
                        // }

                        this.attachmentModalObj.attachmentList.splice(0, this.attachmentModalObj.attachmentList.length);
                        this.filesResponseList.EnvelopeAdditionalUploadInfoList.forEach((ele: any, index: any) => {
                            if (ele.FileName != null && ele.FileName != "" && ele.FileName != undefined) {
                                this.attachmentModalObj.attachmentList.push(ele.FileName);
                            }
                        });

                        // this.event.next(this.filesResponseList.EnvelopeAdditionalUploadInfoList.filter((c: any) => c.FileName != null && c.FileName != "").length);

                        let attachObj = {
                            attchlist: this.attachmentModalObj.attachmentList,
                            attchlistCount: this.filesResponseList.EnvelopeAdditionalUploadInfoList.filter((c: any) => c.FileName != null && c.FileName != "").length
                        };
                        this.event.next(attachObj);
                    }
                    else {
                        this.maxUploadID = resp.MaxUploadID;

                        let attachObj = {
                            attchlist: this.attachmentModalObj.attachmentList,
                            attchlistCount: this.filesResponseList.EnvelopeAdditionalUploadInfoList.filter((c: any) => c.FileName != null && c.FileName != "").length
                        };
                        this.event.next(attachObj);

                        // this.event.next(resp.EnvelopeAdditionalUploadInfoList.filter((c: any) => c.FileName != null && c.FileName != "").length);
                    }
                }
            })
    }

    disableSubmitButton() {
        let submitEnvelopeBtn: any = (<HTMLInputElement>document.getElementById('submitEnvelopeBtn'));
        let attchLength: any = this.filesResponseList.EnvelopeAdditionalUploadInfoList.filter((c: any) => c.FileName != null && c.FileName != "").length;
        if (attchLength > 0) {
            submitEnvelopeBtn.removeAttribute('disabled');
            submitEnvelopeBtn.style.cursor = "pointer";
        }
        else {
            submitEnvelopeBtn.setAttribute('disabled', 'disabled');
            submitEnvelopeBtn.style.cursor = "no-drop";
        }
    }

    onOpenAddAttachment(fileObj: any) {
        if (fileObj.IsRequired) {
            this.uploadDivStyle = 'block';
            this.onOpenAddAttachmentId = fileObj.ID;
            this.onOpenAddAttachmentIdRequired = true;

            let divUploadPopup: any = document.getElementById("divUploadPopup");
            divUploadPopup.style["display"] = "block";
        }
        else {
            let dname: any = (<HTMLInputElement>document.getElementById('txtName' + fileObj.ID)).value;
            let description: any = (<HTMLInputElement>document.getElementById('txtDescription' + fileObj.ID)).value;
            if (dname.length && description.length) {
                this.uploadDivStyle = 'block';
                this.onOpenAddAttachmentId = fileObj.ID;
                this.onOpenAddAttachmentIdRequired = false;

                let divUploadPopup: any = document.getElementById("divUploadPopup");
                divUploadPopup.style["display"] = "block";
            }
            else {
                this.toastr.warning('Please enter document name and description.', 'Warning', { timeOut: 2000 });
            }
        }

        localStorage.setItem("onOpenAddAttachmentId", this.onOpenAddAttachmentId);
        localStorage.setItem("onOpenAddAttachmentIdRequired", this.onOpenAddAttachmentIdRequired == true ? "1" : "0");
    }

    validateFileExtension(name: any) {
        this.extCheck = true;
        let ext: any = (name).substr(((name).lastIndexOf('.') + 1));
        var extensions: any = ['exe', 'msi', 'js', 'jar', 'vb', 'vbs', 'bat', 'mp4', 'mov', 'wmv', 'avi', 'flv', 'f4v', 'swf', 'mkv', 'webm', 'json', 'sql'];
        extensions.forEach((ele: any) => {
            if (ext.toLowerCase() === ele.toLowerCase()) {
                this.extCheck = false;
            }
        })
    }

    validateFileNameLength(name: any) {
        if (name.length > 60) {
            this.fileNameLength = false;
        }
        else {
            this.fileNameLength = true;
        }
    }

    validateFolderDetails(files: any) {
        this.fileCount = parseInt(files.length); // parseInt(fileCount) +
        this.foldersize = 0;
        let isValidSize: any

        files.forEach((ele: any) => {
            var size;
            if (ele.size == undefined) {
                if (ele.bytes != undefined) {
                    size = ele.bytes;
                }
                else {
                    size = ele.FileSize;
                }
            }
            else {
                size = ele.size;
            }
            isValidSize = parseInt(this.foldersize) + parseInt(size);
        })
        if (isValidSize > 15728640 || this.fileCount > 10) {
            this.folderCheck = false;
            this.fileCount = 0;
        }
        else {
            this.foldersize = isValidSize;
            this.folderCheck = true;
        }
    }

    closeDivUploadPopup() {
        this.uploadDivStyle = 'none';
    }

    onCloseDrives() {
        this.showFileSelectionOptions = false;
    }

    onChange(event: any) {
        this.files = [];
        this.extCheck = true;
        this.onFileChange(event.target.files);
    }

    getfileCheckedColor(colorId: any) {
        let className: any = 'font-iconColor';
        let filenameInputctrl: any = (<HTMLInputElement>document.getElementById('fileNameInput_' + colorId));
        if (filenameInputctrl !== '' && filenameInputctrl !== undefined && filenameInputctrl !== null) {
            let value: any = filenameInputctrl.value;
            if (value !== '' && value !== undefined && value !== null) {
                className = 'text-green';
            }
        }
        return className;
    }

    getSaveCheckedColor(colorId: any) {
        let className: any = 'hide';
        let filenameInputctrl: any = (<HTMLInputElement>document.getElementById('fileNameInput_' + colorId));
        if (filenameInputctrl !== '' && filenameInputctrl !== undefined && filenameInputctrl !== null) {
            let value: any = filenameInputctrl.value;
            if (value !== '' && value !== undefined && value !== null) {
                className = 'text-blue';
            }
        }
        return className;
    }

    getDeleteCheckedColor(colorId: any) {
        let className: any = 'font-iconColor';
        let filenameInputctrl: any = (<HTMLInputElement>document.getElementById('fileNameInput_' + colorId));
        if (filenameInputctrl !== '' && filenameInputctrl !== undefined && filenameInputctrl !== null) {
            let value: any = filenameInputctrl.value;
            if (value !== '' && value !== undefined && value !== null) {
                className = 'text-red cursor-pointer';
                let deleteUpload: any = (<HTMLInputElement>document.getElementById('deleteUpload_' + colorId));
                deleteUpload.removeAttribute('disabled');
                deleteUpload.style.cursor = "pointer";
            }
            else {
                let deleteUpload: any = (<HTMLInputElement>document.getElementById('deleteUpload_' + colorId));
                deleteUpload.setAttribute('disabled', 'disabled');
                deleteUpload.style.cursor = "no-drop";
            }
        }
        return className;
    }

    callAddNewRow() {
        let obj: any;
        this.maxUploadID = this.maxUploadID + 1;
        obj = {
            AdditionalInfo: '', Description: '', FileName: '', ID: this.maxUploadID, IsActive: null, IsRequired: false,
            MasterEnvelopeID: '', Name: '', OriginalFileName: '', RecipientID: '', UploadedDateTime: '',
            'maxUploadID': this.maxUploadID
        }
        this.isAddNewRowEnabled = true;
        this.filesResponseList.EnvelopeAdditionalUploadInfoList.push(obj)
        setTimeout(() => {
            let ele: any = (<HTMLInputElement>document.getElementById('uploadsDoc' + this.maxUploadID))
            ele?.setAttribute('data-newrow', 'true');
        }, 1000)
    }

    onAddNewRow() {
        setTimeout(() => {
            if (this.filesResponseList.EnvelopeAdditionalUploadInfoList.length) {
                this.filesResponseList.EnvelopeAdditionalUploadInfoList.forEach((ele: any) => {
                    this.callAddNewRowFlag = false;
                    let nameinput: any = (<HTMLInputElement>document.getElementById('txtName' + ele.ID));
                    let descriptioninput: any = (<HTMLInputElement>document.getElementById('txtDescription' + ele.ID));
                    let fileNameInputinput: any = (<HTMLInputElement>document.getElementById('fileNameInput_' + ele.ID));
                    let name: any = ""; let description: any = ""; let fileNameInput: any = "";
                    if (ele.IsRequired) {
                        if (nameinput != null && nameinput != undefined) { name = nameinput.innerHTML; }
                        if (descriptioninput != null && descriptioninput != undefined) { description = descriptioninput.innerHTML; }
                    }
                    else {
                        if (nameinput != null && nameinput != undefined) { name = nameinput.value; }
                        if (descriptioninput != null && descriptioninput != undefined) { description = descriptioninput.value; }
                    }
                    if (fileNameInputinput != null && fileNameInputinput != undefined) { fileNameInput = fileNameInputinput.value; }

                    if (name !== '' && description !== '' && fileNameInput !== '') {
                        this.callAddNewRowFlag = true;
                    } else if (fileNameInput === '') {
                        this.toastr.warning('Please upload a document.', 'Warning', { timeOut: 2000 });
                    } else {
                        this.toastr.warning('Please enter document name and description.', 'Warning', { timeOut: 2000 });
                    }
                });
                if (this.callAddNewRowFlag) {
                    this.callAddNewRow();
                }
            } else {
                this.callAddNewRow();
            }
        }, 200);
    }

    getDefaultMaxUploadId(i: any, fileid: any) {
        if (this.filesResponseList.EnvelopeAdditionalUploadInfoList.length === 0 && i === 0) {
            this.defaultmaxUploadID = this.filesResponseList.MaxUploadID + 1;
        } else {
            this.defaultmaxUploadID = fileid;
        }
        return this.defaultmaxUploadID;
    }

    getUploadSignerAttachments(id: any, selFileName: any, attachRequired: boolean) {
        let name: any;
        let description: any;
        let additionalInfo: any;
        if (attachRequired) {
            name = (<HTMLInputElement>document.getElementById('txtName' + id)).innerHTML;
            description = (<HTMLInputElement>document.getElementById('txtDescription' + id)).innerHTML;
            additionalInfo = (<HTMLInputElement>document.getElementById('txtAdditionalInfo' + id)).innerHTML;
        }
        else {
            name = (<HTMLInputElement>document.getElementById('txtName' + id)).value;
            description = (<HTMLInputElement>document.getElementById('txtDescription' + id)).value;
            additionalInfo = (<HTMLInputElement>document.getElementById('txtAdditionalInfo' + id)).value;
        }

        this.files.forEach((file) => {
            this.validateFileExtension(file.name);
            this.validateFileNameLength(file.name);
        })
        if (this.fileNameLength === false) {
            this.toastr.warning('One of the files has larger name.', 'Warning', { timeOut: 2000 });
            (<HTMLInputElement>document.getElementById('fileNameInput_' + id)).value = '';
            (<HTMLInputElement>document.getElementById('fileUploadInput')).value = '';
            return;
        }
        if (this.extCheck === false) {
            this.toastr.warning('One of the files is in invalid format.', 'Warning', { timeOut: 2000 });
            (<HTMLInputElement>document.getElementById('fileNameInput_' + id)).value = '';
            (<HTMLInputElement>document.getElementById('fileUploadInput')).value = '';
            return;
        }
        else {
            this.validateFolderDetails(this.files);
        }

        if (this.folderCheck === false) {
            this.toastr.warning('Maximum 10 files with combined attachment size of 15MB allowed.', 'Warning', { timeOut: 2000 });
            (<HTMLInputElement>document.getElementById('fileNameInput_' + id)).value = '';
            (<HTMLInputElement>document.getElementById('fileUploadInput')).value = '';
            return;
        }

        if (this.files.length > 0 && this.extCheck === true && this.fileNameLength === true && this.folderCheck === true) {
            let uploadSignerAttachmentsUrl: any = CommonConstants.UploadSignerAttachments;
            var formData: any = new FormData();
            formData.append('NameSiA', name);
            formData.append('DescriptionSiA', description);
            formData.append('AdditionalInfoSiA', additionalInfo);
            formData.append('file', this.form.get('file')!.value);
            formData.append('envelopeIDSiA', this.envelopeID);
            formData.append('recipientEmailSiA', localStorage.getItem('recipientEmail')!);
            formData.append('recipientIDSiA', localStorage.getItem('RecipientID')!);
            formData.append('uploadAttachmentIDSiA', this.operation === 'A' ? 0 : id);
            formData.append('IsStaticTemplate', localStorage.getItem('isStatic')!);
            this.showLoader = true;
            this.signerLandingService.getUploadSignerAttachments(uploadSignerAttachmentsUrl, formData).subscribe(
                (resp: any) => {
                    // console.log('res', resp);
                    this.showLoader = false;
                    if (resp && resp.DocumentDetails && resp.DocumentDetails.length > 0) {
                        let newRowId: any = parseInt(resp.DocumentDetails[0].ID);
                        this.changedValues.push({ id: newRowId, originalFileName: resp.UploadedFileName, attachRequired: attachRequired });
                        this.toastr.success(resp.Message, 'Success', { timeOut: 2000 });
                        let ele: any = (<HTMLInputElement>document.getElementById('uploadsDoc' + newRowId))
                        ele?.removeAttribute('data-newrow');
                        //console.log('newRowId', newRowId);

                        let uploadedRowId: any = id; // this.maxUploadID;

                        // this.filesResponseList.EnvelopeAdditionalUploadInfoList = _.reject(this.filesResponseList.EnvelopeAdditionalUploadInfoList, function (el) {
                        //     if (el.ID === uploadedRowId) {
                        //         el.ID = newRowId;
                        //     }
                        // });

                        (<HTMLInputElement>document.getElementById('uploadsDoc' + uploadedRowId)).id = 'uploadsDoc' + newRowId;
                        (<HTMLInputElement>document.getElementById('txtName' + uploadedRowId)).id = ('txtName' + newRowId);
                        (<HTMLInputElement>document.getElementById('txtDescription' + uploadedRowId)).id = ('txtDescription' + newRowId);
                        (<HTMLInputElement>document.getElementById('txtAdditionalInfo' + uploadedRowId)).id = ('txtAdditionalInfo' + newRowId);
                        (<HTMLInputElement>document.getElementById('lnkGreenTick_' + uploadedRowId)).id = ('lnkGreenTick_' + newRowId);
                        (<HTMLInputElement>document.getElementById('deleteUpload_' + uploadedRowId)).id = ('deleteUpload_' + newRowId);
                        (<HTMLInputElement>document.getElementById('Upload_' + uploadedRowId)).id = ('Upload_' + newRowId);

                        if (!attachRequired) {
                            (<HTMLInputElement>document.getElementById('lnkDeleteRow_' + uploadedRowId)).id = ('lnkDeleteRow_' + newRowId);
                            (<HTMLInputElement>document.getElementById('lnkSave_' + uploadedRowId)).id = ('lnkSave_' + newRowId);
                        }

                        (<HTMLInputElement>document.getElementById('fileNameInput_' + uploadedRowId)).id = ('fileNameInput_' + newRowId);
                        (<HTMLInputElement>document.getElementById('rec_' + uploadedRowId)).id = ('rec_' + newRowId);
                        (<HTMLInputElement>document.getElementById('uploadedFileName_' + uploadedRowId)).id = ('uploadedFileName_' + newRowId);
                        (<HTMLInputElement>document.getElementById('FileName_' + uploadedRowId)).id = ('FileName_' + newRowId);
                        (<HTMLInputElement>document.getElementById('OriginalFileName_' + uploadedRowId)).id = ('OriginalFileName_' + newRowId);
                        this.getchangedValues('', newRowId);

                        setTimeout(() => {
                            let oldfilename: any;
                            if (this.operation === 'U') {
                                oldfilename = (<HTMLInputElement>document.getElementById('uploadedFileName_' + newRowId)).value;
                            }
                            (<HTMLInputElement>document.getElementById('uploadedFileName_' + newRowId)).value = resp.UploadedFileName;
                            let updatedRow: any;
                            if (this.operation === 'A') {
                                (<HTMLInputElement>document.getElementById('FileName_' + newRowId)).value = resp.UploadedFileName;
                                (<HTMLInputElement>document.getElementById('OriginalFileName_' + newRowId)).value = selFileName;
                            }

                            let fName: any = (<HTMLInputElement>document.getElementById('FileName_' + newRowId)).value;
                            if ((fName == "" || fName == null) && this.operation === 'U') {
                                (<HTMLInputElement>document.getElementById('FileName_' + newRowId)).value = resp.UploadedFileName;
                            }

                            if (this.operation === 'U') {
                                //  console.log(this.attachmentModalObj.attachmentList);
                                if (this.attachmentModalObj.attachmentList && this.attachmentModalObj.attachmentList.length) {
                                    this.attachmentModalObj.attachmentList.forEach((ele: any, index: any) => {
                                        if (ele === oldfilename) {
                                            this.attachmentModalObj.attachmentList.splice(index, 1);
                                            this.attachmentModalObj.attachmentList.splice(index, 0, resp.UploadedFileName);
                                        }
                                    });
                                }
                            }
                            else {
                                this.attachmentModalObj.attachmentList.push(resp.UploadedFileName);
                            }

                            updatedRow = {
                                AttachmentId: newRowId.toString(),
                                AddUpdate: this.operation,
                                FileName: (<HTMLInputElement>document.getElementById('FileName_' + newRowId)).value, //resp.UploadedFileName,
                                OriginalFileName: (<HTMLInputElement>document.getElementById('OriginalFileName_' + newRowId)).value, //fileObj.name,
                                RecipientId: this.recipientID,
                                attachRequired: attachRequired
                            }

                            this.maxUploadID = newRowId;
                            this.getchangedAttachment(updatedRow);
                            this.files = [];

                            Array.from(this.filesResponseList.EnvelopeAdditionalUploadInfoList)
                                .forEach((row: any) => {
                                    if (row.ID == uploadedRowId) {
                                        row.ID = newRowId;
                                    }
                                });

                        }, 500);
                    }
                    else if (resp && resp.originalError && resp.originalError.error && resp.originalError.error.Message) {
                        if (resp.originalError.error.Message == "Cannot attach more than 15MB." || resp.originalError.error.Message == "Cannot attach more than 20MB.") {
                            this.toastr.warning('Maximum 10 files with combined attachment size of 15MB allowed.', 'Warning', { timeOut: 2500 });
                        }
                        else {
                            this.toastr.warning(resp.originalError.error.Message, 'Warning', { timeOut: 2500 });
                        }

                        (<HTMLInputElement>document.getElementById('fileNameInput_' + id)).value = '';
                        (<HTMLInputElement>document.getElementById('fileUploadInput')).value = '';
                    }
                    else {
                        if (resp && resp.originalError) {
                            this.toastr.warning(resp.originalError.error.Message, 'Warning', { timeOut: 2500 });
                        }
                        (<HTMLInputElement>document.getElementById('fileNameInput_' + id)).value = '';
                        (<HTMLInputElement>document.getElementById('fileUploadInput')).value = '';
                    }
                })
        } else {
            this.files = [];
            this.fileNameLength = true;
            this.extCheck = true;
            this.folderCheck = true;
        }
    }

    getchangedAttachment(updatedRowObj: any) {
        if (this.changedAttachments.length === 0) {
            this.changedAttachments.push(updatedRowObj);
        } else {
            var idAvailable = this.changedAttachments.filter(function (o: any) {
                return o.AttachmentId === updatedRowObj.AttachmentId;
            });
            if (idAvailable.length == 0 ) {
                this.changedAttachments.push(updatedRowObj);
            }
        }
    }

    getchangedValues(e: any, newLastId: any) {
        if (this.changedValues.length == 0) {
            this.changedValues.push({ id: newLastId });
        } else {
            var idAvailable = this.changedValues.filter(function (o) {
                return o.id === newLastId;
            });
            if (idAvailable.length == 0 ) {
                this.changedValues.push({ id: newLastId });
            }
        }
    }

    onFileChange(files: any) {
        this.uploadDivStyle = 'none';
        console.log('onFileChange!', files);
        for (const item of files) {
            item.progress = 0;
            this.files.push(item);
        }
        const file: any = this.files[0];
        this.form.patchValue({
            file: file,
        });

        this.operation = (<HTMLInputElement>document.getElementById('uploadsDoc' + this.onOpenAddAttachmentId)).getAttribute('data-newrow') == 'true' ? 'A' : 'U';
        //console.log('operation', this.operation);
        this.form.get('file')!.updateValueAndValidity();

        this.validateFileExtension(file.name);
        if (this.extCheck === false) {
            this.toastr.warning('One of the files is in invalid format.', 'Warning', { timeOut: 2000 });
            (<HTMLInputElement>document.getElementById('fileUploadInput')).value = '';
            (<HTMLInputElement>document.getElementById('fileNameInput_' + this.onOpenAddAttachmentId)).value = '';
            return;
        }
        else {
            this.validateFolderDetails(this.files);
        }

        if (this.folderCheck === false) {
            this.toastr.warning('Maximum 10 files with combined attachment size of 15MB allowed.', 'Warning', { timeOut: 2000 });
            (<HTMLInputElement>document.getElementById('fileUploadInput')).value = '';
            (<HTMLInputElement>document.getElementById('fileNameInput_' + this.onOpenAddAttachmentId)).value = '';
            return;
        }
        else {
            (<HTMLInputElement>document.getElementById('fileNameInput_' + this.onOpenAddAttachmentId)).value = files[0].name;
            this.getUploadSignerAttachments(this.onOpenAddAttachmentId, files[0].name, this.onOpenAddAttachmentIdRequired);
            (<HTMLInputElement>document.getElementById('fileUploadInput')).value = '';
        }
    }

    //Removing the files
    onFileRemove(fileObj: any) {
        let reqParams: any = {
            'EnvelopeID': this.envelopeID,
            'RecipientID': this.recipientID,
            'UploadAttachmentId': fileObj.ID,
            'TempRecipientID': this.recipientID
        }
        let uploadedFileNameValue: any = document.getElementById('uploadedFileName_' + fileObj.ID);

        if (uploadedFileNameValue.value !== '') {
            this.showLoader = true;
            let deleteRequestedUploadsUrl: any = CommonConstants.DeleteRequestedUploads;
            this.signerLandingService.getDeleteRequestedUploads(deleteRequestedUploadsUrl, reqParams).subscribe(
                (resp: any) => {
                    // console.log('res', resp);
                    this.showLoader = false;
                    if (resp && resp.StatusCode === 200 && resp.StatusMessage.toLowerCase() === 'ok') {
                        this.toastr.success(resp.Message, 'Success', { timeOut: 2000 });
                        this.form.patchValue({
                            file: [null],
                        });
                        (<HTMLInputElement>document.getElementById('fileNameInput_' + fileObj.ID)).value = '';
                        (<HTMLInputElement>document.getElementById('uploadedFileName_' + fileObj.ID)).value = '';
                        if (this.attachmentModalObj.attachmentList && this.attachmentModalObj.attachmentList.length) {
                            this.attachmentModalObj.attachmentList.forEach((ele: any, index: any) => {
                                if (ele === uploadedFileNameValue.value) {
                                    this.attachmentModalObj.attachmentList.splice(index, 1);
                                }
                            });

                            Array.from(this.filesResponseList.EnvelopeAdditionalUploadInfoList).forEach((item: any) => {
                                if (item.ID == fileObj.ID) {
                                    item.FileName = "";
                                }
                            });

                            if (this.filesResponseList.EnvelopeAdditionalUploadInfoList.length > 0) {
                                let attchArray: any = [];
                                this.filesResponseList.EnvelopeAdditionalUploadInfoList.forEach((ele: any, index: any) => {
                                    if (ele.FileName != null && ele.FileName != "" && ele.FileName != undefined) {
                                        attchArray.push(ele.FileName);
                                    }
                                });

                                let attachObj = {
                                    attchlist: attchArray,
                                    attchlistCount: this.filesResponseList.EnvelopeAdditionalUploadInfoList.filter((c: any) => c.FileName != null && c.FileName != "").length
                                };
                                this.event.next(attachObj);

                                // this.event.next(this.filesResponseList.EnvelopeAdditionalUploadInfoList.filter((c: any) => c.FileName != null && c.FileName != "").length);
                            } else {
                                this.filesResponseList.EnvelopeAdditionalUploadInfoList = [];
                                // this.event.next(0);
                                let attachObj = { attchlist: [], attchlistCount: 0 };
                                this.event.next(attachObj);
                            }
                        }
                        else {
                            // this.event.next(0);
                            let attachObj = { attchlist: [], attchlistCount: 0 };
                            this.event.next(attachObj);
                        }
                    }
                })
        } else {
            // this.toastr.warning('Please upload document.', 'Warning', { timeOut: 2000 });
            // this.showLoader = false;
        }
    }

    onRowDelete(id: any) {
        let deleteRowUrl: any = CommonConstants.DeleteNewlyAddedUpload;
        let reqParams: any = {
            'EnvelopeID': this.envelopeID,
            'RecipientID': this.recipientID,
            'TempRecipientID': this.recipientID,
            'UploadAttachmentId': id
        }
        this.showLoader = true;
        this.signerLandingService.getDeleteNewlyAddedUpload(deleteRowUrl, reqParams).subscribe(
            (resp: any) => {
                this.showLoader = false;
                // console.log('res', resp);
                if (resp && resp.StatusCode === 200 && resp.StatusMessage.toLowerCase() === 'ok') {
                    this.toastr.success('Attachment removed successfully.', 'Success', { timeOut: 2000 });
                    this.changedValues.forEach((a: any, b: any) => {
                        if (id === a.id)
                            this.changedValues.splice(b, 1)
                    });
                    this.changedAttachments.forEach((a: any) => {
                        if (id === a.AttachmentId) {
                            let fileName_: any = document.getElementById('FileName_' + a.AttachmentId);
                            fileName_.value = (<HTMLInputElement>document.getElementById('uploadedFileName_' + a.AttachmentId)).value;
                            let originalFileName_: any = document.getElementById('OriginalFileName_' + a.AttachmentId)
                            originalFileName_.value = (<HTMLInputElement>document.getElementById('fileNameInput_' + a.AttachmentId)).value;
                            this.changedAttachments.splice(a, 1);
                        }
                    });
                    if (this.attachmentModalObj.attachmentList && this.attachmentModalObj.attachmentList.length) {
                        this.attachmentModalObj.attachmentList.forEach((ele: any, ind: any) => {
                            let fileName: any = document.getElementById('FileName_' + id);
                            if (ele === fileName.value) {
                                this.attachmentModalObj.attachmentList.splice(ind, 1);
                            }
                        });
                    }

                    if (this.filesResponseList.EnvelopeAdditionalUploadInfoList.length > 0) {
                        this.filesResponseList.EnvelopeAdditionalUploadInfoList = this.filesResponseList.EnvelopeAdditionalUploadInfoList.filter((c: any) => c.ID !== id);

                        let attchArray: any = [];
                        this.filesResponseList.EnvelopeAdditionalUploadInfoList.forEach((ele: any, index: any) => {
                            if (ele.FileName != null && ele.FileName != "" && ele.FileName != undefined) {
                                attchArray.push(ele.FileName);
                            }
                        });

                        let attachObj = {
                            attchlist: attchArray,
                            attchlistCount: this.filesResponseList.EnvelopeAdditionalUploadInfoList.filter((c: any) => c.FileName != null && c.FileName != "").length
                        };
                        this.event.next(attachObj);

                        // this.event.next(this.filesResponseList.EnvelopeAdditionalUploadInfoList.filter((c: any) => c.FileName != null && c.FileName != "").length);
                    } else {
                        this.filesResponseList.EnvelopeAdditionalUploadInfoList = [];
                        //this.event.next(0);
                        let attachObj = { attchlist: [], attchlistCount: 0 };
                        this.event.next(attachObj);
                    }
                }
            })
    }

    onContinue() {
        //  console.log('onContinue')
    }

    onSaveAttachFile(fileObj: any, inLineSave: any) {
        var tempRecipientID = this.recipientID;
        var SaveDocumentRequest: any = [];

        let name: any;
        let description: any;
        let additionalInfo: any;

        if (inLineSave === true) {
            if (inLineSave && fileObj.IsRequired) {
                name = (<HTMLInputElement>document.getElementById('txtName' + fileObj.ID)).innerHTML;
                description = (<HTMLInputElement>document.getElementById('txtDescription' + fileObj.ID)).innerHTML;
                additionalInfo = (<HTMLInputElement>document.getElementById('txtAdditionalInfo' + fileObj.ID)).innerHTML;
            }
            else if (inLineSave) {
                name = (<HTMLInputElement>document.getElementById('txtName' + fileObj.ID)).value;
                description = (<HTMLInputElement>document.getElementById('txtDescription' + fileObj.ID)).value;
                additionalInfo = (<HTMLInputElement>document.getElementById('txtAdditionalInfo' + fileObj.ID)).value;
            }
            SaveDocumentRequest.push({
                MasterEnvelopeID: this.envelopeID,
                Name: name,
                Description: description,
                AdditionalInfo: additionalInfo,
                ID: fileObj.ID,
                RecipientID: tempRecipientID,
                FileName: (<HTMLInputElement>document.getElementById('uploadedFileName_' + fileObj.ID)).value,
                OriginalFileName: (<HTMLInputElement>document.getElementById('fileNameInput_' + fileObj.ID)).value,
            });
        }
        else {
            if (this.changedValues.length > 0) {
                this.changedValues.forEach((value: any) => {
                    if (value.id != '') {
                        let isRequiredAttchment: boolean = value.attachRequired;
                        if (isRequiredAttchment) {
                            name = (<HTMLInputElement>document.getElementById('txtName' + value.id)).innerHTML;
                            description = (<HTMLInputElement>document.getElementById('txtDescription' + value.id)).innerHTML;
                            additionalInfo = (<HTMLInputElement>document.getElementById('txtAdditionalInfo' + value.id)).innerHTML;
                        }
                        else {
                            name = (<HTMLInputElement>document.getElementById('txtName' + value.id)).value;
                            description = (<HTMLInputElement>document.getElementById('txtDescription' + value.id)).value;
                            additionalInfo = (<HTMLInputElement>document.getElementById('txtAdditionalInfo' + value.id)).value;
                        }

                        SaveDocumentRequest.push({
                            MasterEnvelopeID: this.envelopeID,
                            Name: name,
                            Description: description,
                            AdditionalInfo: additionalInfo,
                            ID: value.id,
                            RecipientID: this.recipientID,
                            FileName: (<HTMLInputElement>document.getElementById('uploadedFileName_' + value.id)).value,
                            OriginalFileName: (<HTMLInputElement>document.getElementById('fileNameInput_' + value.id)).value,
                        });
                    }
                })
            }

            //This is commented because if sender adds one required and one optional. if show this message we are restricting signer to upload the attachment but it is optional
            // let valEmptyRowsCount: any = this.ShowValidationWhenNoAttachmentsExists();
            // if (valEmptyRowsCount > 0) {
            //     this.toastr.info('Attachment has not been added.', 'Attention', { timeOut: 2000 });
            //     return;
            // }
        }

        if (inLineSave === true || this.changedValues.length > 0) {
            let saveAttachmentRequestUrl: any = CommonConstants.SaveAttachmentRequest;
            this.showLoader = true;
            this.signerLandingService.getSaveAttachmentRequest(saveAttachmentRequestUrl, SaveDocumentRequest).subscribe(
                (resp: any) => {
                    this.showLoader = false;
                    // console.log('saveAttachmentRequest res', resp);
                    if (resp && resp.StatusCode === 200 && resp.StatusMessage.toLowerCase() === 'ok') {
                        if (inLineSave) {
                            this.changedValues.forEach((a: any, b: any) => {
                                if (fileObj.ID === a.id) {
                                    this.changedValues.splice(b, 1)
                                }
                            });
                            this.changedAttachments.forEach((a: any, b: any) => {
                                if (fileObj.ID === parseInt(a.AttachmentId)) {
                                    let fileName_: any = document.getElementById('FileName_' + a.AttachmentId);
                                    if (fileName_ != null) {
                                        fileName_.value = (<HTMLInputElement>document.getElementById('uploadedFileName_' + a.AttachmentId)).value;
                                        let originalFileName_: any = document.getElementById('OriginalFileName_' + a.AttachmentId)
                                        originalFileName_.value = (<HTMLInputElement>document.getElementById('fileNameInput_' + a.AttachmentId)).value;
                                        this.changedAttachments.splice(b, 1);

                                        Array.from(this.filesResponseList.EnvelopeAdditionalUploadInfoList).forEach((item: any) => {
                                            if (item.ID == a.AttachmentId) {
                                                item.FileName = fileName_.value;
                                            }
                                        });
                                    }
                                }
                            });
                        } else {
                            this.changedAttachments.forEach((a: any, b: any) => {
                                let fileName_: any = document.getElementById('FileName_' + a.AttachmentId);
                                if (fileName_ != null) {
                                    fileName_.value = (<HTMLInputElement>document.getElementById('uploadedFileName_' + a.AttachmentId)).value;
                                    Array.from(this.filesResponseList.EnvelopeAdditionalUploadInfoList).forEach((item: any) => {
                                        if (item.ID == a.AttachmentId) {
                                            item.FileName = fileName_.value;
                                        }
                                    });
                                }
                            });

                            this.changedValues = [];
                            this.changedAttachments = [];
                            // this.activeModal.dismiss('Cross click');
                        }

                        this.toastr.success(resp.Message, 'Success', { timeOut: 2000 });
                        // this.returnCountToParentComponent(inLineSave);
                        this.readMandatoryAttachmentFileCount(this.filesResponseList.EnvelopeAdditionalUploadInfoList, inLineSave);
                    }
                })
        }
        else if (!inLineSave) {
            this.changedValues = [];
            this.changedAttachments = [];
            // this.activeModal.dismiss('Cross click');
            // this.returnCountToParentComponent(inLineSave);
            this.readMandatoryAttachmentFileCount(this.filesResponseList.EnvelopeAdditionalUploadInfoList, inLineSave);
        }
    }

    onCloseAttachment() {
        if (this.changedAttachments.length > 0) {
            this.confirmationPopupStyle = 'block';
        } else {
            this.activeModal.dismiss('Cross click');
        }
    }

    getFinalAttachmentValidation() {
        this.onSaveAttachFile({}, false);
    }

    onCloseConfirmationPopup() {
        this.confirmationPopupStyle = 'none';
    }

    onConfirmationPopup() {
        let deleteAndUpdateSignerDocUrl = CommonConstants.DeleteAndUpdateSignerDocFromDirectory;
        this.showLoader = true;
        this.onCloseConfirmationPopup();
        this.signerLandingService.getDeleteAndUpdateSignerDocFromDirectory(deleteAndUpdateSignerDocUrl, this.changedAttachments).subscribe(
            (resp: any) => {
                this.showLoader = false;
                // console.log('res', resp);
                if (resp && resp.StatusCode === 200 && resp.StatusMessage.toLowerCase() === 'ok') {
                    setTimeout(() => {
                        this.uploadDivStyle = 'none';
                        this.activeModal.dismiss('Cross click');
                    }, 200);
                    var files = resp.data;

                    if (files != null && files != undefined) {
                        this.returnCountToParentComponent(false);
                        this.toastr.success('Document deleted successfully.', 'Success', { timeOut: 2000 });
                    } else {
                        // this.event.next(0);
                        let attachObj = { attchlist: [], attchlistCount: 0 };
                        this.event.next(attachObj);
                    }
                }
            })
    }

    returnCountToParentComponent(inLineSave: boolean) {
        if (this.isFromGroupTemplateSignerAttachments) {
            this.getAdditonalfileAttachmentInfoForGroups(inLineSave);
        }
        else {
            this.getAdditonalfileAttachmentInfo(inLineSave);
        }
    }

    readMandatoryAttachmentFileCount(envelopeAdditionalUploadInfoList: any, inLineSave: boolean) {
        if (this.isFromGroupTemplateSignerAttachments) {
            this.getAdditonalfileAttachmentInfoForGroups(inLineSave);
        }
        else {
            this.getAdditonalfileAttachmentInfo(inLineSave);
        }
        setTimeout(() => {
            let mandatoryCount: any = 0;
            console.log(this.filesResponseList.EnvelopeAdditionalUploadInfoList);
            if (this.filesResponseList.EnvelopeAdditionalUploadInfoList != null && this.filesResponseList.EnvelopeAdditionalUploadInfoList.length > 0) {
                Array.from(this.filesResponseList.EnvelopeAdditionalUploadInfoList).forEach((item: any) => {
                    if (item.IsRequired) {
                        if (item.FileName == "" || item.FileName == null) {
                            mandatoryCount = mandatoryCount + 1;
                        }
                    }
                });
            }
            else if (this.duplicateFilesResponseList != null && this.duplicateFilesResponseList.length > 0) {
                Array.from(this.duplicateFilesResponseList).forEach((item: any) => {
                    if (item.IsRequired) {
                        if (item.FileName == "" || item.FileName == null) {
                            mandatoryCount = mandatoryCount + 1;
                        }
                    }
                });
            }

            if (mandatoryCount > 0) {
                localStorage.setItem("MandatoryAttachmentFileCount", mandatoryCount);
                this.toastr.warning("Attachment has not been added.", 'Warning', { timeOut: 1500 });
            }
            else {
                localStorage.setItem("MandatoryAttachmentFileCount", "0");
                if (!inLineSave) {
                    this.activeModal.dismiss('Cross click');
                }
            }
        }, 150);
    }

    loadGoogleDrive() {
        gapi.load('auth2', { 'callback': this.onAuthApiLoad.bind(this) });
        gapi.load('picker', { 'callback': this.onPickerApiLoad.bind(this) });
    }

    onAuthApiLoad() {
        gapi.auth2.authorize(
            {
                client_id: this.googleDriveClientId,
                scope: this.gooleDriveScope,
                immediate: false,
                prompt: 'select_account',
                authuser: -1,
                plugin_name: 'NewSignersUI'
            },
            this.handleAuthResult.bind(this));
    }

    onPickerApiLoad() {
        this.pickerApiLoaded = true;
    }

    handleAuthResult(authResult: any) {
        let src;
        if (authResult && !authResult.error) {
            if (authResult.access_token) {
                this.oauthToken = authResult.access_token;
                this.oauthData = authResult;
                localStorage.setItem("authResultToken", this.oauthToken);
                this.createPicker();
            }
        }
    }

    createPicker() {
        if (this.pickerApiLoaded && this.oauthToken) {
            let view = new google.picker.DocsView(google.picker.ViewId.DOCS);
            view.setMode(google.picker.DocsViewMode.LIST);
            view.setIncludeFolders(true);
            view.setOwnedByMe(true);
            let picker = new google.picker.PickerBuilder().
                addView(view).
                enableFeature(google.picker.Feature.NAV_HIDDEN).
                setOAuthToken(this.oauthToken).
                setCallback((e: any) => {
                    if (e[google.picker.Response.ACTION] == google.picker.Action.PICKED) {
                        let documents = e[google.picker.Response.DOCUMENTS];
                        let name: any;
                        let description: any;
                        let additionalInfo: any;
                        this.onOpenAddAttachmentId = localStorage.getItem("onOpenAddAttachmentId");
                        let attachReq: any = localStorage.getItem("onOpenAddAttachmentIdRequired");
                        this.onOpenAddAttachmentIdRequired = attachReq == "1" ? true : false;

                        if (this.onOpenAddAttachmentIdRequired) {
                            name = (<HTMLInputElement>document.getElementById('txtName' + this.onOpenAddAttachmentId)).innerHTML;
                            description = (<HTMLInputElement>document.getElementById('txtDescription' + this.onOpenAddAttachmentId)).innerHTML;
                            additionalInfo = (<HTMLInputElement>document.getElementById('txtAdditionalInfo' + this.onOpenAddAttachmentId)).innerHTML;
                        }
                        else {
                            name = (<HTMLInputElement>document.getElementById('txtName' + this.onOpenAddAttachmentId)).value;
                            description = (<HTMLInputElement>document.getElementById('txtDescription' + this.onOpenAddAttachmentId)).value;
                            additionalInfo = (<HTMLInputElement>document.getElementById('txtAdditionalInfo' + this.onOpenAddAttachmentId)).value;
                        }

                        let isStatic: boolean = localStorage.getItem('isStatic') == "true" ? true : false;
                        let driveFiles: any[] = []
                        let preparedObjects = {
                            GAuthToken: localStorage.getItem("authResultToken"), //gapi.auth2.getToken().access_token,
                            EnvelopeID: this.envelopeID,
                            Stage: localStorage.getItem('RecipientID')!,
                            IsStaticTemplate: isStatic,
                            DriveFiles: driveFiles,
                            UploadAttachmentID: this.onOpenAddAttachmentId === '' ? "1" : this.onOpenAddAttachmentId,
                            NameSiA: name,
                            DescriptionSiA: description,
                            AdditionalInfoSiA: additionalInfo,
                            recipientEmailSiA: localStorage.getItem('recipientEmail')!,
                            UploadDocDriveType: UploadDriveType.Google
                        };

                        let attachmentloader: any = (<HTMLInputElement>document.getElementById("attachmentloader"));
                        attachmentloader.style["display"] = "block";
                        let respFileName: string = "";
                        documents.forEach((doc: any) => {
                            gapi.client.request({
                                'path': 'https://www.googleapis.com/drive/v2/files/' + doc.id,
                                'method': 'GET',
                            }).then((resp: any) => {
                                if (resp.status == 200) {
                                    let responsejs = resp.result;
                                    if (responsejs.exportLinks != undefined) {
                                        if (responsejs.mimeType.indexOf('spreadsheet') > -1) {
                                            if (responsejs.exportLinks["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"] != '') {
                                                respFileName = responsejs.title + ".xlsx";
                                                preparedObjects.DriveFiles.push({
                                                    FileSource: responsejs.exportLinks["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"],
                                                    FileName: responsejs.title + ".xlsx",
                                                    FileSize: responsejs.fileSize,
                                                });
                                            }
                                        }
                                        else {
                                            if (responsejs.exportLinks["application/pdf"] != '') {
                                                respFileName = responsejs.title + ".pdf";
                                                preparedObjects.DriveFiles.push({
                                                    FileSource: responsejs.exportLinks["application/pdf"],
                                                    FileName: responsejs.title + ".pdf",
                                                    FileSize: responsejs.fileSize,
                                                });
                                            }
                                        }
                                    }
                                    else if (responsejs.selfLink != undefined && responsejs.selfLink != '') {
                                        if (responsejs.fileSize < 15728640) {
                                            respFileName = responsejs.originalFilename;
                                            preparedObjects.DriveFiles.push({
                                                FileSource: responsejs.selfLink + "?alt=media&source=downloadUrl",
                                                FileName: responsejs.originalFilename,
                                                FileSize: responsejs.fileSize,
                                            });
                                        }
                                        else {
                                            this.folderCheck = false;
                                        }
                                    }

                                    if (preparedObjects.DriveFiles.length == 0 && this.folderCheck == false) {
                                        setTimeout(() => {
                                            this.showToaster("Maximum 10 files with combined attachment size of 15MB allowed.", 'Warning');
                                            attachmentloader.style["display"] = "none";
                                            (<HTMLInputElement>document.getElementById('fileNameInput_' + this.onOpenAddAttachmentId)).value = '';
                                        }, 1500);
                                        this.toastr.warning('File size exceeded - Maximum 10 files with combined attachment size of 15MB allowed.', 'Warning', { timeOut: 2000 });
                                        return;
                                    }

                                    preparedObjects.DriveFiles.forEach((file) => {
                                        this.validateFileExtension(file.FileName);
                                        this.validateFileNameLength(file.FileName);
                                        if (this.fileNameLength === false) {
                                            // this.toastr.warning('One of the files has larger name.', 'Warning', { timeOut: 2000 });
                                            // attachmentloader.style["display"] = "none";
                                            // return;

                                            setTimeout(() => {
                                                this.showToaster("One of the files has larger name.", 'Warning');
                                                attachmentloader.style["display"] = "none";
                                                (<HTMLInputElement>document.getElementById('fileNameInput_' + this.onOpenAddAttachmentId)).value = '';
                                            }, 1500);
                                            this.toastr.warning("Selected One of the files has larger name.", 'Warning', { timeOut: 2000 });
                                            return;


                                        }
                                        if (this.extCheck === false) {
                                            // this.toastr.warning('One of the files is in invalid format.', 'Warning', { timeOut: 2000 });
                                            // attachmentloader.style["display"] = "none";
                                            setTimeout(() => {
                                                this.showToaster("One of the files is in invalid format.", 'Warning');
                                                attachmentloader.style["display"] = "none";
                                                (<HTMLInputElement>document.getElementById('fileNameInput_' + this.onOpenAddAttachmentId)).value = '';
                                            }, 1500);
                                            this.toastr.warning("Selected file is in invalid format.", 'Warning', { timeOut: 2000 });
                                            return;
                                        }
                                        else {
                                            this.validateFolderDetails(preparedObjects.DriveFiles);
                                        }

                                        if (this.folderCheck === false) {
                                            setTimeout(() => {
                                                this.showToaster("Maximum 10 files with combined attachment size of 15MB allowed.", 'Warning');
                                                attachmentloader.style["display"] = "none";
                                                (<HTMLInputElement>document.getElementById('fileNameInput_' + this.onOpenAddAttachmentId)).value = '';
                                            }, 1500);
                                            this.toastr.warning('File size exceeded - Maximum 10 files with combined attachment size of 15MB allowed.', 'Warning', { timeOut: 2000 });
                                            return;
                                        }

                                        if (preparedObjects.DriveFiles.length > 0 && this.extCheck === true && this.fileNameLength === true && this.folderCheck === true) {
                                            this.saveToServer(preparedObjects, respFileName);
                                        }
                                        else {
                                            attachmentloader.style["display"] = "none";
                                        }
                                    })
                                }
                                else {
                                    attachmentloader.style["display"] = "none";
                                }
                            })
                        });
                    }
                    else if (e[google.picker.Response.ACTION] == google.picker.Action.CANCEL) {
                        let attachmentClass: any = document.getElementsByClassName("attachment-class");
                        attachmentClass[0].style["z-index"] = "1055";
                        let modalBackdrop: any = document.getElementsByClassName("modal-backdrop");
                        modalBackdrop[0].style["z-index"] = "1055";
                    }
                }).
                build();
            picker.setVisible(true);

            let attachmentClass: any = document.getElementsByClassName("attachment-class");
            attachmentClass[0].style["z-index"] = "5";

            let modalBackdrop: any = document.getElementsByClassName("modal-backdrop");
            modalBackdrop[0].style["z-index"] = "5";

            let divUploadPopup: any = document.getElementById("divUploadPopup");
            divUploadPopup.style["display"] = "none";
        }
    }

    saveToServer(preparedObjects: any, selFileName: any) {
        let attachmentloader: any = (<HTMLInputElement>document.getElementById("attachmentloader"));
        attachmentloader.style["display"] = "block";
        let uploadDriveSignerAttachmentsUrl: any = CommonConstants.DownloadDriveDocumentsForSigner;
        let onOpenAddAttachmentId = localStorage.getItem("onOpenAddAttachmentId");
        let operation = (<HTMLInputElement>document.getElementById('uploadsDoc' + onOpenAddAttachmentId)).getAttribute('data-newrow') == 'true' ? 'A' : 'U';
        this.signerLandingService.uploadDriveSignerAttachmentsUrl(uploadDriveSignerAttachmentsUrl, preparedObjects).subscribe(
            (resp: any) => {
                if (resp && resp.StatusCode == 200) {
                    if (resp && resp.data && resp.data.length > 0) {
                        this.toastr.success(resp.message, 'Success', { timeOut: 1500 });
                        let uploadedResultFileName = resp.data[0];
                        let newRowId: any = parseInt(resp.returnUrl); //returnUrl means id here
                        this.changedValues.push({ id: newRowId, originalFileName: selFileName, attachRequired: this.onOpenAddAttachmentIdRequired });
                        let ele: any = (<HTMLInputElement>document.getElementById('uploadsDoc' + newRowId))
                        ele?.removeAttribute('data-newrow');
                        let uploadedRowId: any = onOpenAddAttachmentId; //this.maxUploadID;

                        // this.filesResponseList.EnvelopeAdditionalUploadInfoList = _.reject(this.filesResponseList.EnvelopeAdditionalUploadInfoList, function (el) {
                        //     if (el.ID === uploadedRowId) {
                        //         el.ID = newRowId;
                        //     }
                        // });

                        (<HTMLInputElement>document.getElementById('uploadsDoc' + uploadedRowId)).id = 'uploadsDoc' + newRowId;
                        (<HTMLInputElement>document.getElementById('txtName' + uploadedRowId)).id = ('txtName' + newRowId);
                        (<HTMLInputElement>document.getElementById('txtDescription' + uploadedRowId)).id = ('txtDescription' + newRowId);
                        (<HTMLInputElement>document.getElementById('txtAdditionalInfo' + uploadedRowId)).id = ('txtAdditionalInfo' + newRowId);
                        (<HTMLInputElement>document.getElementById('lnkGreenTick_' + uploadedRowId)).id = ('lnkGreenTick_' + newRowId);
                        (<HTMLInputElement>document.getElementById('deleteUpload_' + uploadedRowId)).id = ('deleteUpload_' + newRowId);
                        (<HTMLInputElement>document.getElementById('Upload_' + uploadedRowId)).id = ('Upload_' + newRowId);

                        if (!this.onOpenAddAttachmentIdRequired) {
                            (<HTMLInputElement>document.getElementById('lnkDeleteRow_' + uploadedRowId)).id = ('lnkDeleteRow_' + newRowId);
                            (<HTMLInputElement>document.getElementById('lnkSave_' + uploadedRowId)).id = ('lnkSave_' + newRowId);
                        }

                        (<HTMLInputElement>document.getElementById('fileNameInput_' + uploadedRowId)).id = ('fileNameInput_' + newRowId);
                        (<HTMLInputElement>document.getElementById('rec_' + uploadedRowId)).id = ('rec_' + newRowId);
                        (<HTMLInputElement>document.getElementById('uploadedFileName_' + uploadedRowId)).id = ('uploadedFileName_' + newRowId);
                        (<HTMLInputElement>document.getElementById('FileName_' + uploadedRowId)).id = ('FileName_' + newRowId);
                        (<HTMLInputElement>document.getElementById('OriginalFileName_' + uploadedRowId)).id = ('OriginalFileName_' + newRowId);
                        this.getchangedValues('', newRowId);

                        setTimeout(() => {
                            let oldfilename: any;
                            if (operation === 'U') {
                                oldfilename = (<HTMLInputElement>document.getElementById('uploadedFileName_' + newRowId)).value;
                            }
                            (<HTMLInputElement>document.getElementById('fileNameInput_' + newRowId)).value = selFileName;
                            (<HTMLInputElement>document.getElementById('uploadedFileName_' + newRowId)).value = uploadedResultFileName;
                            let updatedRow: any;
                            if (operation === 'A') {
                                (<HTMLInputElement>document.getElementById('FileName_' + newRowId)).value = uploadedResultFileName;
                                (<HTMLInputElement>document.getElementById('OriginalFileName_' + newRowId)).value = selFileName;
                            }

                            let fName: any = (<HTMLInputElement>document.getElementById('FileName_' + newRowId)).value;
                            if ((fName == "" || fName == null) && operation === 'U') {
                                (<HTMLInputElement>document.getElementById('FileName_' + newRowId)).value = uploadedResultFileName;
                            }

                            if (operation === 'U') {
                                if (this.attachmentModalObj.attachmentList && this.attachmentModalObj.attachmentList.length) {
                                    this.attachmentModalObj.attachmentList.forEach((ele: any, index: any) => {
                                        if (ele === oldfilename) {
                                            this.attachmentModalObj.attachmentList.splice(index, 1);
                                            this.attachmentModalObj.attachmentList.splice(index, 0, uploadedResultFileName);
                                        }
                                    });
                                }
                            }
                            else {
                                this.attachmentModalObj.attachmentList.push(uploadedResultFileName);
                            }

                            updatedRow = {
                                AttachmentId: newRowId.toString(),
                                AddUpdate: this.operation,
                                FileName: (<HTMLInputElement>document.getElementById('FileName_' + newRowId)).value, //resp.UploadedFileName,
                                OriginalFileName: (<HTMLInputElement>document.getElementById('OriginalFileName_' + newRowId)).value, //fileObj.name,
                                RecipientId: this.recipientID,
                                attachRequired: this.onOpenAddAttachmentIdRequired
                            }

                            this.maxUploadID = newRowId;
                            this.getchangedAttachment(updatedRow);

                            Array.from(this.filesResponseList.EnvelopeAdditionalUploadInfoList)
                                .forEach((row: any) => {
                                    if (row.ID == uploadedRowId) {
                                        row.ID = newRowId;
                                    }
                                });

                            attachmentloader.style["display"] = "none";
                            let attachmentClass: any = document.getElementsByClassName("attachment-class")
                            attachmentClass[0].style["z-index"] = "1055";
                            let modalBackdrop: any = document.getElementsByClassName("modal-backdrop")
                            modalBackdrop[0].style["z-index"] = "1055";
                            this.toastr.success("Document saved successfully.", 'Success', { timeOut: 2500 });
                        }, 300);
                    }
                    else {
                        attachmentloader.style["display"] = "none";
                    }
                }
                else if (resp.originalError.error.StatusCode == 400 || resp.originalError.error.StatusCode == 204) {
                    let validationmessage: any = this.translationsService.getLanguageValidationVal(resp.originalError.error.message);
                    this.toastr.warning(validationmessage, 'Warning', { timeOut: 1500 });
                    attachmentloader.style["display"] = "none";
                    let attachmentClass: any = document.getElementsByClassName("attachment-class")
                    attachmentClass[0].style["z-index"] = "1055";

                    let modalBackdrop: any = document.getElementsByClassName("modal-backdrop")
                    modalBackdrop[0].style["z-index"] = "1055";

                    this.toastr.warning("Maximum 10 files with combined attachment size of 15MB allowed.", 'Warning', { timeOut: 2500 });
                    this.toastr.warning(validationmessage, 'Warning', { timeOut: 3000 });

                    (<HTMLInputElement>document.getElementById('fileNameInput_' + onOpenAddAttachmentId)).value = '';
                    (<HTMLInputElement>document.getElementById('fileUploadInput')).value = '';
                }
            })
    }

    loadDropBox() {
        var options: DropboxChooseOptions = {
            success: (files) => {
                for (const file of files) {
                    let filesData = [];
                    let isFileSizeExceed = false;
                    if (file.bytes < 15728640) {
                        filesData.push({ FileSource: file.link, FileName: file.name, FileSize: file.bytes });
                        this.uploadDivStyle = 'none';
                        this.generatePostObject(filesData, file.name, UploadDriveType.Dropbox);
                    } else {
                        isFileSizeExceed = true;
                        // let validationmessage: any = this.translationsService.getLanguageValidationVal("lang_under25MB");
                        // this.toastr.warning(validationmessage, 'Warning', { timeOut: 2500 });
                        this.showToaster("Maximum 10 files with combined attachment size of 15MB allowed.", 'Warning');
                        this.uploadDivStyle = 'none';
                        return;
                    }
                }
            },
            cancel: () => { this.uploadDivStyle = 'none'; },
            linkType: "direct",
            multiselect: false
            //extensions: ['.pdf', '.doc', '.docx', '.html'],
        };

        Dropbox.choose(options);
    }

    loadOneDrive() {
        this.launchOneDrivePicker()
            .then(result => {
                if (result) {
                    for (const file of result.value) {
                        let filesData = [];
                        let isFileSizeExceed = false;
                        if (file.size < 15728640) {
                            filesData.push({ FileSource: file["@microsoft.graph.downloadUrl"], FileName: file.name, FileSize: file.size });
                            this.uploadDivStyle = 'none';
                            this.generatePostObject(filesData, file.name, UploadDriveType.Skydrive);
                        } else {
                            isFileSizeExceed = true;
                            // let validationmessage: any = this.translationsService.getLanguageValidationVal("lang_under25MB");
                            // this.toastr.warning(validationmessage, 'Warning', { timeOut: 2500 });
                            this.showToaster("Maximum 10 files with combined attachment size of 15MB allowed.", 'Warning');
                            this.uploadDivStyle = 'none';
                            return;
                        }
                    }
                }
                else {
                    this.uploadDivStyle = 'none';
                }
            }).catch(reason => {
                console.error(reason);
            });
    }

    launchOneDrivePicker() {
        return new Promise<OneDriveResult | null>((resolve, reject) => {
            var odOptions: OneDriveOpenOptions = {
                clientId: this.oneDriveApplicationId,
                action: "download",
                multiSelect: false,
                openInNewWindow: true,
                advanced: {
                    redirectUri: environment.uiAngularBaseUrl
                },
                success: function (files) { resolve(files); },
                cancel: function () { resolve(null); },
                error: function (e) { reject(e); }
            };

            OneDrive.open(odOptions);
        });
    }

    generatePostObject(filesData: any, selFilename: any, uploadSource: any) {
        let name: any;
        let description: any;
        let additionalInfo: any;
        this.onOpenAddAttachmentId = localStorage.getItem("onOpenAddAttachmentId");
        let attachReq: any = localStorage.getItem("onOpenAddAttachmentIdRequired");
        this.onOpenAddAttachmentIdRequired = attachReq == "1" ? true : false;
        let operation = (<HTMLInputElement>document.getElementById('uploadsDoc' + this.onOpenAddAttachmentId)).getAttribute('data-newrow') == 'true' ? 'A' : 'U';

        if (this.onOpenAddAttachmentIdRequired) {
            name = (<HTMLInputElement>document.getElementById('txtName' + this.onOpenAddAttachmentId)).innerHTML;
            description = (<HTMLInputElement>document.getElementById('txtDescription' + this.onOpenAddAttachmentId)).innerHTML;
            additionalInfo = (<HTMLInputElement>document.getElementById('txtAdditionalInfo' + this.onOpenAddAttachmentId)).innerHTML;
        }
        else {
            name = (<HTMLInputElement>document.getElementById('txtName' + this.onOpenAddAttachmentId)).value;
            description = (<HTMLInputElement>document.getElementById('txtDescription' + this.onOpenAddAttachmentId)).value;
            additionalInfo = (<HTMLInputElement>document.getElementById('txtAdditionalInfo' + this.onOpenAddAttachmentId)).value;
        }

        let isStatic: boolean = localStorage.getItem('isStatic') == "true" ? true : false;
        let driveFiles: any[] = []
        let preparedObjects = {
            EnvelopeID: this.envelopeID,
            Stage: localStorage.getItem('RecipientID')!,
            IsStaticTemplate: isStatic,
            DriveFiles: filesData,
            UploadAttachmentID: this.onOpenAddAttachmentId,
            NameSiA: name,
            DescriptionSiA: description,
            AdditionalInfoSiA: additionalInfo,
            recipientEmailSiA: localStorage.getItem('recipientEmail')!,
            UploadDocDriveType: uploadSource
        };

        let attachmentloader: any = (<HTMLInputElement>document.getElementById("attachmentloader"));
        attachmentloader.style["display"] = "block";
        preparedObjects.DriveFiles.forEach((file: any) => {
            this.validateFileExtension(file.FileName);
            this.validateFileNameLength(file.FileName);
        })
        if (this.fileNameLength === false) {
            this.toastr.warning('One of the files has larger name.', 'Warning', { timeOut: 2000 });
            attachmentloader.style["display"] = "none";
            return;
        }
        if (this.extCheck === false) {
            this.toastr.warning('One of the files is in invalid format.', 'Warning', { timeOut: 2000 });
            attachmentloader.style["display"] = "none";
            return;
        }
        else {
            this.validateFolderDetails(preparedObjects.DriveFiles);
        }

        if (this.folderCheck === false) {
            this.toastr.warning('Maximum 10 files with combined attachment size of 15MB allowed.', 'Warning', { timeOut: 2000 });
            attachmentloader.style["display"] = "none";
            return;
        }

        if (preparedObjects.DriveFiles.length > 0 && this.extCheck === true && this.fileNameLength === true && this.folderCheck === true) {
            this.saveToServer(preparedObjects, selFilename);
        }
        else {
            attachmentloader.style["display"] = "none";
        }
    }

    //get Additonal file Attachment Info For Groups
    getAdditonalfileAttachmentInfoForGroups(inLineSave: boolean) {
        this.showLoader = true;
        let envelopeID: any = localStorage.getItem("EnvelopeID");
        let recipientID: any = localStorage.getItem("RecipientID");
        let url = CommonConstants.GetTemplateDetailsForSignMultiTemplate + "/" + envelopeID + "/" + recipientID;
        this.signerLandingService.getcall(url).subscribe(
            (resp: any) => {
                this.showLoader = false;
                if (resp && resp.StatusCode == 200 && resp.StatusMessage.toLowerCase() == "ok") {
                    if (!inLineSave) {
                        this.filesResponseList.EnvelopeAdditionalUploadInfoList = resp.SignMultipleTemplate.EnvelopeAdditionalUploadInfoList;
                        this.duplicateFilesResponseList = resp.SignMultipleTemplate.EnvelopeAdditionalUploadInfoList;
                        this.maxUploadID = resp.MaxUploadID;

                        let attchArray: any = [];
                        this.filesResponseList.EnvelopeAdditionalUploadInfoList.forEach((ele: any, index: any) => {
                            if (ele.FileName != null && ele.FileName != "" && ele.FileName != undefined) {
                                attchArray.push(ele.FileName);
                            }
                        });

                        let attachObj = {
                            attchlist: attchArray,
                            attchlistCount: this.filesResponseList.EnvelopeAdditionalUploadInfoList.filter((c: any) => c.FileName != null && c.FileName != "").length
                        };
                        this.event.next(attachObj);

                        // this.event.next(this.filesResponseList.EnvelopeAdditionalUploadInfoList.filter((c: any) => c.FileName != null && c.FileName != "").length);
                    }
                    else {
                        this.maxUploadID = resp.MaxUploadID;
                        let attchArray: any = [];
                        resp.SignMultipleTemplate.EnvelopeAdditionalUploadInfoList.forEach((ele: any, index: any) => {
                            if (ele.FileName != null && ele.FileName != "" && ele.FileName != undefined) {
                                attchArray.push(ele.FileName);
                            }
                        });

                        let attachObj = {
                            attchlist: attchArray,
                            attchlistCount: resp.SignMultipleTemplate.EnvelopeAdditionalUploadInfoList.filter((c: any) => c.FileName != null && c.FileName != "").length
                        };
                        this.event.next(attachObj);
                        // this.event.next(resp.SignMultipleTemplate.EnvelopeAdditionalUploadInfoList.filter((c: any) => c.FileName != null && c.FileName != "").length);
                    }
                }
            })
    }

    getLanguageTranslationValue(resourcekeyId: any) {
        return this.translationsService.getLanguageTranslationVal(resourcekeyId);
    }

    ShowValidationWhenNoAttachmentsExists() {
        let returnCount = 0;
        let attachmentFilePathCollection = document.getElementsByClassName("attachmentFilePath");
        Array.from(attachmentFilePathCollection).forEach(item => {
            let fileInputAttrId: any = item.getAttribute("id");
            let fileInputidInput: any = document.getElementById(fileInputAttrId)!;
            let fileInputidValue: any = fileInputidInput.value;
            if (fileInputidValue == null || fileInputidValue == undefined || fileInputidValue == "") {
                returnCount = returnCount + 1;
            }
        });
        return returnCount;
    }

    showToaster(msg: string, errorType: any) {
        if (errorType === "Error") {
            this.toastr.error(msg, errorType, {
                timeOut: 2000
            });
        }
        else if (errorType === "Warning") {
            this.toastr.warning(msg, errorType, {
                timeOut: 5000
            });
        }
    }

    UpdateInputValues(event:any,fileId:any){
        var s = event;
        let dataUploadId:any = event.target.id;
        this.getchangedValues('', fileId);
    }
}