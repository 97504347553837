<app-header></app-header>
<main id="mainContainer" class="vh-100 d-flex flex-column h-100">
    <div class="main-container flex-shrink-0">
        <div class="container">
            <div class="row">
                <div class="wrapper fadeInDown">
                    <div class="formContent">
                        <div class="front-arrow">
                            <input type="hidden" id="hdnRolesCount" name="hdnRolesCount" value="{{rolesCount}}" />
                            <h4> {{getLanguageTranslationValue(resourceKeys.TermsandConditions)}}</h4>
                            
                            <p id="pDisplayTag" class="my-3 text-center">{{displayText}}</p>

                            <div class="mt-0" *ngIf="rolesCount == 1 || rolesCount > 1">
                                <label for="sel1"
                                    class="form-label">{{getLanguageTranslationValue(resourceKeys.SigningAs)}}</label>
                                <div class="input-group">
                                    <span class="input-group-text material-icons font-20">assignment_ind</span>
                                    <select *ngIf="rolesCount == 1" class="form-control" id="slRoles" name="sellist1"
                                        (ontouchstart)="onChangeRoleDropdownSelection($event)"
                                        (change)="onChangeRoleDropdownSelection($event)">
                                        <option *ngFor="let role of rolsTypesInfo" value="{{role.RecipientId}}"
                                            [selected]="false">{{role.RoleName}}</option>
                                    </select>
                                    <select *ngIf="rolesCount > 1" class="form-select form-control" id="slRoles"
                                        name="sellist1" (ontouchstart)="onChangeRoleDropdownSelection($event)"
                                        (change)="onChangeRoleDropdownSelection($event)">
                                        <option value="0" [selected]="true">Select</option>
                                        <option *ngFor="let role of rolsTypesInfo" value="{{role.RecipientId}}">
                                            {{role.RoleName}}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="mb-1 divDeliveryModeEmail" *ngIf="divDeliveryModeEmail"
                                style="margin-top: 15px;">
                                <label for="email"
                                    class="form-label">{{getLanguageTranslationValue(resourceKeys.Email)}} <span
                                        id="spnFirstSignerEmail" class="spnFirstSignerEmail"
                                        style="color: red;display: inline-block;">*</span></label>
                                <div class="input-group mb-3">
                                    <span class="input-group-text material-icons font-20">email</span>
                                    <input *ngIf="!isNullOrEmptyCheck(recipientEmail)" type="text" class="form-control"
                                        id="txtFirstSignerEmail" placeholder="Enter your email" name="email"
                                        maxlength="50" value="{{recipientEmail}}" autocomplete="off">
                                    <input *ngIf="isNullOrEmptyCheck(recipientEmail)" type="text" class="form-control"
                                        id="txtFirstSignerEmail" placeholder="Enter your email" name="email"
                                        maxlength="50" value="{{recipientEmail}}" autocomplete="off" disabled>
                                </div>
                            </div>

                            <div class="mb-1 divDeliveryModeMobile"
                                *ngIf="EnableMessageToMobile && divDeliveryModeMobile" style="margin-top: 15px;">
                                <label for="dialcode" class="form-label" style="width:138px;">{{getLanguageTranslationValue(resourceKeys.CountryCode)}} <span
                                        id="spnConfirmSignerDialCode" class="spnConfirmSignerDialCode"
                                        style="color: red;display: inline-block;">*</span></label>

                                <label for="mobileNumber" class="form-label" style1="margin-left: 40px;">{{getLanguageTranslationValue(resourceKeys.Mobile)}}
                                    <span id="spnConfirmSignerMobileNumber" class="spnConfirmSignerMobileNumber"
                                        style="color: red;display: inline-block;">*</span></label>
                                <div class="input-group mb-3">

                                    
                                    <div ngbDropdown class="dropdown" #myDrop="ngbDropdown" style="width: 127px;"
                                        id="ddrCountryFlag">
                                        <a class="selecttext" ngbDropdownToggle style="text-decoration-line: none; color: #000;">
                                            <span id="spnTitle" style="display: inline;">Select</span>
                                        </a>
                                        <ul ngbDropdownMenu class="dropdown-menu" id="DialCodeUl"
                                            (click)="selectedCountryDialCode($event);myDrop.close();">
                                            <li class="liCountryItem"
                                                *ngFor="let dialcodes of DialCodeDropdownList; let i = index;">
                                                <a *ngIf="dialcodes.DialCode == 'Select'" [attr.data-maxlength]="0" class="selecttext"
                                                    style="text-decoration-line: none;color: #000;padding-top: 15px;padding-bottom: 15px; background-color: #bc0001;">
                                                    <span id="spnCountryFlag"></span>Select</a>
                                                <a  style="text-decoration-line: none;color: #000;" class="selecttext"
                                                    *ngIf="dialcodes.DialCode != 'Select'"
                                                    [attr.data-countrycode]="dialcodes.CountryCode.toLowerCase()"
                                                    [attr.data-maxlength]="dialcodes.MobileMaxLength"
                                                    [attr.data-countryclass]="dialcodes.CountryCode.toLowerCase()">
                                                    <span id="spnCountryFlag" 
                                                        class="bg-{{dialcodes.CountryCode.toLowerCase()}} "
                                                        style="top: -8px; display: inline-block; width: 25px;"></span>{{dialcodes.DialCode}}</a>
                                            </li>
                                        </ul>
                                    </div>

                                    <input *ngIf="isNullOrEmptyCheck(mobileNumber)" type="text"
                                        style="margin-left: 10px;cursor:no-drop"
                                        class="form-control txtConfirmSignerMobileNumber"
                                        id="txtConfirmSignerMobileNumber" name="mobile number" pattern="[0-9]*"
                                        ondrop="return false" inputmode="numeric" typeof="number" spellcheck="false"
                                        value="{{mobileNumber}}" autocomplete="off" disabled
                                        (input)="validateInputValue($event)">

                                    <input *ngIf="!isNullOrEmptyCheck(mobileNumber)" type="text"
                                        style="margin-left: 10px;" class="form-control txtConfirmSignerMobileNumber"
                                        id="txtConfirmSignerMobileNumber" placeholder="Enter mobile number"
                                        pattern="[0-9]*" ondrop="return false" inputmode="numeric" typeof="number"
                                        spellcheck="false" name="mobilenumber" value="{{mobileNumber}}"
                                        autocomplete="off" (input)="validateInputValue($event)">
                                </div>
                            </div>

                            <div id="divSignerTermsAndConditions">
                                <div class="checkbox checkstyle">
                                    <label class="ps-0">
                                        <input id="chkterms" type="checkbox" (change)="onEnableContinue($event)" />
                                        <span class="cr"><i class="cr-icon material-icons">check</i></span>
                                        {{SignerTermsCond}}
                                    </label>
                                </div>

                                <div *ngIf="rolesCount > 1" class="d-flex mt-3 w-93">
                                    <p> <b>*</b> {{Acceptingthetermsandconditions}}</p>
                                    <input type="hidden" id="hdnAcceptingthetermsandconditions" value="1" />
                                </div>
                            </div>

                        </div>
                        <div class="form-buttons">
                            <button id="btnFirstAccept" class="btn btn-full-width cursorpoint"
                                data-key-config="btnFirstAccept"
                                (click)="continue($event)">{{getLanguageTranslationValue(resourceKeys.Continue)}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer style="background: #eee;" class="default-footer mt-auto py-2">
        <div class="container1" style="font-size: 10px;">
            <div class="fotertext" [innerHTML]="htmlFooterString">
            </div>
        </div>
    </footer>
</main>


<div *ngIf="showLoader" class="loader">
</div>