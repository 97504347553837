import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { CommonConstants, MessageDeliveryModes, ReturnUrl, RouteUrls } from '../constants/common-constants';
@Injectable({
    providedIn: 'root'
})
export class CommonService {

    // private userSource$ = new BehaviorSubject<string>('');
    // user = this.userSource$.asObservable();
    uservalue: any = 1;
    currentPinchZoomScale: any;
    // documentLength:any;

    documentData = new Subject<any>();
    userSource$ = new Subject<any>();
    currentPinchZoomScale$ = new Subject<any>();

    constructor(private router: Router) {
        this.documentData.next(1)
    }

    setUser(user: any): void {
        this.userSource$.next(user);
        this.uservalue = user;
    }

    getUser() {
        return this.uservalue;
    }

    setDocumentData(id_estado: any) {
        this.documentData.next(id_estado);
    }

    getCookie(cname: any) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    setCookie(cname: any, cvalue: any, exdays: any) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    checkCookie(cname: any) {
        var cookieExists = this.getCookie(cname);
        return (cookieExists != "");
    }

    deleteCookie(cname: any) {
        document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }

    isNumber(str: string): boolean {
        if (typeof str !== 'string') return false;
        if (str.trim() === '') return false;
        return !Number.isNaN(Number(str));
    }

    isNumberExists(str: string): boolean {
        if (typeof str !== 'string') return false;
        if (str.trim() === '') return false;
        if (str.trim() === ' ') return false;
        if (/\s/.test(str)) return false;
        if (str.indexOf(".") >= 0) return false;
        return !Number.isNaN(Number(str));
    }

    b64toBlob(b64Data: any, contentType: any) {
        contentType = contentType || '';
        let sliceSize = 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    getReturnUrlDirection(returnUrlValue: any) {
        let returnUrl: any = ReturnUrl.List;
        let filteredReturnUrl: any = returnUrl.filter((c: any) => c === returnUrlValue);
        if (filteredReturnUrl.length >= 1) {
            this.router.navigate([returnUrlValue]);
        } else {
            localStorage.clear();
            sessionStorage.clear();
            window.location.href = returnUrlValue;
        }
    }

    checkIsUrlExistsInBrowserMemory() {
       // localStorage.setItem("encryptedSender", '');
        localStorage.setItem("DisplayMessage", CommonConstants.SessionExpirationMessage);
        localStorage.setItem("MessageType", "Warning");
        this.getReturnUrlDirection(RouteUrls.Info);
    }

    //Checking all pages are loaded or not
    loadImages(urls: string[]): Promise<boolean> {
        const imagePromises: Promise<boolean>[] = [];

        urls.forEach((url) => {
            const imagePromise = new Promise<boolean>((resolve) => {
                const image = new Image();
                image.onload = () => resolve(true);
                image.onerror = () => resolve(false);
                image.src = url;
            });

            imagePromises.push(imagePromise);
        });

        return Promise.all(imagePromises)
            .then((results) => results.every((result) => result));
    }

    setPinchZoomScale(scale: any): void {
        this.currentPinchZoomScale$.next(scale);
        this.currentPinchZoomScale = scale;
    }

    getPinchZoomScale() {
        return this.currentPinchZoomScale;
    }

    redirectTo(type: any) {
        switch (type) {
            case "Features":
                window.open("https://rsign.com/product/electronic-signature", "_blank");
                break;
            case "CustomerSuccess":
                window.open("https://rsign.com/customer-success", "_blank");
                break;
            case "Webinars":
                window.open("https://rsign.com/webinars", "_blank");
                break;
            case "Videos":
                window.open("https://rsign.com/product-video-playlist", "_blank");
                break;
            case "Apps":
                window.open("https://rsign.com/apps", "_blank");
                break;
            case "Blogs":
                window.open("https://rsign.com/blog", "_blank");
                break;
            case "News":
                window.open("https://rsign.com/news", "_blank");
                break;
            case "ContactUs":
                window.open("https://rsign.com/contact/questions", "_blank");
                break;
            case "Support":
                window.open("https://support.rpost.com/hc/en-us", "_blank");
                break;
        }
    }

    GenerateMaskedEmail(emailAddr: any) {
        let emailArray = emailAddr.split('@');
        let arrayfirstPartstr = emailArray[0];
        let arraylastPaststr = emailArray[1];
    
        var firstDigits = arrayfirstPartstr.substring(0, 3);
        var maskedString1 = firstDigits + "***" + "@";
    
        let lastDotPosition = arraylastPaststr.lastIndexOf('.');
        let firstLastPart = arraylastPaststr.substring(0, lastDotPosition);
        let firstLastPartDigits = firstLastPart.substring(0, 3);
        var maskedString2 = firstLastPartDigits + "***";
    
        let lastPart = arraylastPaststr.substring(lastDotPosition + 1);
        let maskedString3 = maskedString1 + maskedString2 + "." + lastPart;
        return maskedString3;
      }

    returnValBasedOnDeliveryMode(deliveryModeval:any){ //Email&Mobile
        if(deliveryModeval == 3 || deliveryModeval == 7 || deliveryModeval == 10 || deliveryModeval == 2 || deliveryModeval == 4 || deliveryModeval == 8)return true;  
        else if(deliveryModeval == "3" || deliveryModeval == "7" || deliveryModeval == "10" || deliveryModeval == "2" || deliveryModeval == "4" || deliveryModeval == "8")return true;  
        else return false; 
    }
    returnValBasedOnDeliveryModeVal(deliveryModeval:any){ //Email
        if(deliveryModeval == 3 || deliveryModeval == 7 || deliveryModeval == 10 || deliveryModeval == 1 || deliveryModeval == 5 || deliveryModeval == 9)return true;  
        else if(deliveryModeval == "3" || deliveryModeval == "7" || deliveryModeval == "10" || deliveryModeval == "1" || deliveryModeval == "5" || deliveryModeval == "9")return true;  
        else return false; 
    }


    returnEmailAndMobileDeliveryModeVal(deliveryMode:any) {
        if (deliveryMode == 2 || deliveryMode == 3 || deliveryMode == 5 || deliveryMode == 7 || deliveryMode == 8 || deliveryMode == 9 || deliveryMode == 10 || deliveryMode == 12) return true;
        else if (deliveryMode == "2" || deliveryMode == "3" || deliveryMode == "5" || deliveryMode == "7" || deliveryMode ==" 8" || deliveryMode == "9" || deliveryMode == "10" || deliveryMode == "12") return true;    
        else return false;
      }
    
      returnEmailBasedOnDeliveryModeVal(deliveryMode:any) {
        // if (deliveryMode == 2 || deliveryMode == 3 || deliveryMode == 5 || deliveryMode == 7 || deliveryMode == 8 || deliveryMode == 9 || deliveryMode == 10 || deliveryMode == 12 || deliveryMode == 1 || deliveryMode == 11) return true;
        // else if (deliveryMode == "2" || deliveryMode == "3" || deliveryMode == "5" || deliveryMode == "7" || deliveryMode ==" 8" || deliveryMode == "9" || deliveryMode == "10" || deliveryMode == "12" || deliveryMode == "1" || deliveryMode == "11") return true;    
        //
        if (deliveryMode == 1 || deliveryMode == 11 || deliveryMode == "1" || deliveryMode == "11") return true;  
         else return false;
      }
    
      returnMobileDeliveryModeVal(deliveryMode:any) {
        // if (deliveryMode == 2 || deliveryMode == 3 || deliveryMode == 5 || deliveryMode == 7 || deliveryMode == 8 || deliveryMode == 9 || deliveryMode == 10 || deliveryMode == 12 || deliveryMode == 4 || deliveryMode == 6) return true;
        // else if (deliveryMode == "2" || deliveryMode == "3" || deliveryMode == "5" || deliveryMode == "7" || deliveryMode ==" 8" || deliveryMode == "9" || deliveryMode == "10" || deliveryMode == "12" || deliveryMode == "4" || deliveryMode == "6") return true;    
        if (deliveryMode == 4 || deliveryMode == 6 || deliveryMode == "4" || deliveryMode == "6") return true;
        else return false;
      }

      returnShowEmailBasedOnDeliveryModeVal(deliveryMode:any) {
        if (deliveryMode == 2 || deliveryMode == 3 || deliveryMode == 5 || deliveryMode == 7 || deliveryMode == 8 || deliveryMode == 9 || deliveryMode == 10 || deliveryMode == 12 || deliveryMode == 1 || deliveryMode == 11) return true;
        else if (deliveryMode == "2" || deliveryMode == "3" || deliveryMode == "5" || deliveryMode == "7" || deliveryMode ==" 8" || deliveryMode == "9" || deliveryMode == "10" || deliveryMode == "12" || deliveryMode == "1" || deliveryMode == "11") return true;    
        else return false;
      }
    
      returnShowMobileDeliveryModeVal(deliveryMode:any) {
        if (deliveryMode == 2 || deliveryMode == 3 || deliveryMode == 5 || deliveryMode == 7 || deliveryMode == 8 || deliveryMode == 9 || deliveryMode == 10 || deliveryMode == 12 || deliveryMode == 4 || deliveryMode == 6) return true;
        else if (deliveryMode == "2" || deliveryMode == "3" || deliveryMode == "5" || deliveryMode == "7" || deliveryMode ==" 8" || deliveryMode == "9" || deliveryMode == "10" || deliveryMode == "12" || deliveryMode == "4" || deliveryMode == "6") return true;    
         else return false;
      }

      GetRecipientDeliveryMode(deliveryMode:any)
      {
          if (deliveryMode == 1 || deliveryMode == null) return MessageDeliveryModes.EmailSlashEmail;
          else if (deliveryMode == 2) return MessageDeliveryModes.EmailSlashMobile;
          else if (deliveryMode == 3) return MessageDeliveryModes.EmailSlashEmailAndMobile;
          else if (deliveryMode == 4) return MessageDeliveryModes.MobileSlashMobile;
          else if (deliveryMode == 5) return MessageDeliveryModes.MobileSlashEmail;
          else if (deliveryMode == 6) return MessageDeliveryModes.MobileSlashNone;
          else if (deliveryMode == 7) return MessageDeliveryModes.MobileSlashEmailAndMobile;
          else if (deliveryMode == 8) return MessageDeliveryModes.EmailAndMobileSlashMobile;
          else if (deliveryMode == 9) return MessageDeliveryModes.EmailAndMobileSlashEmail;
          else if (deliveryMode == 10) return MessageDeliveryModes.EmailAndMobileSlashEmailAndMobile;
          else if (deliveryMode == 11) return MessageDeliveryModes.EmailSlashNone;
          else if (deliveryMode == 12) return MessageDeliveryModes.EmailAndMobileSlashNone;
          else return MessageDeliveryModes.EmailSlashEmail;
      }

      returnCodeToolTipText(deliverymode:any) {
        var messageCodeText = "";
        switch (deliverymode) {
            case 1: case "1": messageCodeText = "Email / Email"; break;
            case 2: case "2": messageCodeText = "Email / Mobile"; break;
            case 3: case "3": messageCodeText = "Email / Email & Mobile"; break;
            case 4: case "4": messageCodeText = "Mobile / Mobile"; break;
            case 5: case "5": messageCodeText = "Mobile / Email"; break;
            case 6: case "6": messageCodeText = "Mobile / None"; break;
            case 7: case "7": messageCodeText = "Mobile / Email & Mobile"; break;
            case 8: case "8": messageCodeText = "Email & Mobile / Mobile"; break;
            case 9: case "9": messageCodeText = "Email & Mobile / Email"; break;
            case 10: case "10": messageCodeText = "Email & Mobile / Email & Mobile"; break;
            case 11: case "11": messageCodeText = "Email / None"; break;
            case 12: case "12": messageCodeText = "Email & Mobile / None"; break;
            default: messageCodeText = "Email / Email";
        }
        return messageCodeText;
    }
}
