import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { RouteUrls } from 'src/app/constants/common-constants';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-signer-validate',
  templateUrl: './signer-validate.component.html',
  styleUrls: ['./signer-validate.component.scss']
})
export class SignerValidateComponent implements OnInit {

  token: string | undefined;
  showLoader: boolean = false;
  htmlFooterString: any;

  constructor(private commonService: CommonService, private titleService:Title) { this.token = undefined; }

  ngOnInit(): void {
    this.titleService.setTitle("RSign | Validate Signer");
    this.showLoader = true;
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.htmlFooterString = '<p> ' +
      '<a href="https://rpost.com/legal-notices/terms-and-conditions" target="_blank" class="footerLinks">General Terms & Legal Notices</a>: Click to view service level terms, conditions, privacy, ' +
      '<a class="footerLinks" href="https://rpost.com/legal-notices/legal-and-patent-notice" target="_blank"> patent, trademark</a>, and other service and legal notices. ' +
      '</p>';
     
      this.showLoader = false;     
  }

  public validateCaptcha(form: NgForm): void {
    this.showLoader = true;
    if (form.invalid) {
      for (const control of Object.keys(form.controls)) {
        form.controls[control].markAsTouched();
      }
      this.showLoader = false;
      return;
    }

    localStorage.setItem("IsFromBotClick", "1");     
    this.commonService.getReturnUrlDirection(RouteUrls.SignerLanding);
  }
}
