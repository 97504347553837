import { PipeTransform, Pipe } from '@angular/core';

@Pipe(
    {
        name: 'filter'
    }
)

export class FilterPipe implements PipeTransform {

    // transform(values: any, key?: string): any[] {
    //     console.log('key', key)
    //     if (!key) return values;
    //     key = key.trim().toLowerCase();
    //     //val.ControlName is search key
    //     return values.filter((val: any) => val.LanguageControlName.toLowerCase() === key);
    // }

    transform(items: any, filter: any, isAnd: any): any {
        if (filter && Array.isArray(items)) {
          let filterKeys = Object.keys(filter);
          if (isAnd) {
            return items.filter(item =>
                filterKeys.reduce((memo, keyName) =>
                    (memo && new RegExp(filter[keyName], 'gi').test(item[keyName])) || filter[keyName] === '', true));
          } else {
            return items.filter(item => {
                if (!filter.ControlName) return items;
                filter.ControlName = filter.ControlName.trim().toLowerCase();
                //val.ControlName is search key
                return item.LanguageControlName.toLowerCase() === filter.ControlName;
            //   return filterKeys.some((keyName) => {
            //     console.log(keyName);
            //     return new RegExp(filter[keyName], 'gi').test(item[keyName]) || filter[keyName] === '';
            //   });
            });
          }
        } else {
          return items;
        }
      }

}