export class CommonConstants {
    public static bkey: string = 'WI_RSign';
    public static SaveSignature: string = 'Save signature';
    public static AddSignatureAgreement: string = 'I agree that the email address as indicated is the address associated with me and is acceptable for use for the purpose of creating a record of this electronic transaction, for receiving confirmations related to this transaction, and for receiving records and notices associated with this transaction.';
    public static FilterControlNoteInfo: string = 'Click the field name below to quickly locate them in the document.Incomplete fields are coloured whereas completed are going to grey out.Asterisk symbol indicates mandatory field.';
    public static ConfirmToBeginAgreement: string = 'I agree that the email address as indicated is the address associated with me and is acceptable for use for the purpose of creating a record of this electronic transaction, for receiving confirmations related to this transaction, and for receiving records and notices associated with this trasaction.';
    public static ConfirmToBeginNote: string = 'After clicking on next button, you will be able to invite additionals signers by clicking the invite signer button on the document signing page.';
    public static ClickToSignNote: string = 'Confirm your name, initials and signature to click to sign these fields in the document:';
    public static ClickToSignAgreement: string = 'I agree that the email address as indicated is the address associated with me and is acceptable for use for the purpose of creating a record of this electronic transaction, for receiving confirmations related to this transaction, and for receiving records and notices associated with this transaction.';
    public static InvalidUrl: string = 'The URL of the envelope is incorrect.';
    public static SessionExpirationMessage: string = 'Session expired. Please close this browser tab and open the link in a new tab.';
  //  public static SessionTimeOutPopUpExpirationMessage: string = 'Session expired. Please close this browser tab and open the link in a new tab.';

    //Decline
    public static SelectAtleastOneOption: string = 'Select at least one option to decline.';
    public static ErrorTitle: string = 'Please let us know why you want to decline signing this documents.';
    public static DocumentGenerationMessage: string = 'The document is getting generated. Please wait or download the document later from the completed folder of your RSign account.';
    public static DeleteFinalContractDisplayMsg: string = 'The signed contract is deleted from RSign server.';

    public static LearnMoreTextOnly: string = 'Learn More';
    public static FreeTrialText: string = 'Buy Now';
    public static RSignMakesESignatureText: string = 'RSign makes E-Signatures simple with drag and drop document preparation, shareable templates, reporting, document access, and more. It has everything advanced users and administrators need in an e-sign service.';
    public static RpostValidateBotFailure: string = 'There is a problem with validating user authentication. Please try again or contact administrator.';

    //API's

    // Get
    public static Brand: string = '/api/v1/brand?emailaddress=';
    public static GetDeclineTemplate: string = '/api/V1/SignDocument/GetDeclineTemplate';
    public static GetDownloadPDFPreview: string = '/api/V1/SignDocument/GetDownloadPDFPreview';
    public static GetEnvelopeOrTemplateFields: string = '/api/V1/SignDocument/GetEnvelopeOrTemplateFields';
    public static EncryptQueryParamString: string = '/api/V1/SignDocument/EncryptQueryParamString';
    public static GetAdditonalfileAttachmentInfo: string = '/api/V1/SignDocument/GetAdditonalfileAttachmentInfo';

    public static DownloadFinalContract: string = '/api/V1/SignDocument/DownloadFinalContract';
    public static GetDownloadFileReview: string = '/api/V1/SignDocument/GetDownloadFileReview';
    public static DownloadDisclaimerPDF: string = '/api/V1/SignDocument/DownloadDisclaimerPDF';

    // Post
    public static getLanguageTranslationsUrl: string = '/api/V1/SignDocument/GetLanguageTranslations';
    public static DecryptSigningUrl: string = '/api/V1/SignDocument/DecryptSigningUrl';
    public static InitializeSignerSignDocument: string = '/api/V1/SignDocument/InitializeSignerSignDocument';
    // public static InitializeSignerSignDocument1: string = '/api/V1/SignDocument/InitializeSignerSignDocument1';
    public static UpdatedEvelopeStatusbySigner: string = '/api/V1/SignDocument/UpdatedEvelopeStatusbySigner';
    public static ChangeSigner: string = '/api/V1/SignDocument/ChangeSigner';
    public static SignDocument: string = '/api/V1/SignDocument/SignDocument';
    public static FinishLater: string = '/api/V1/SignDocument/FinishLaterSubmit';
    public static SaveAttachmentRequest: string = '/api/V1/Document/SaveAttachmentRequest';
    public static DeleteRequestedUploads: string = '/api/V1/Document/DeleteRequestedUploads';
    public static DeleteNewlyAddedUpload: string = '/api/V1/Document/DeleteNewlyAddedUpload';
    public static UploadSignerAttachments: string = '/api/V1/Document/UploadSignerAttachments';
    public static DownloadDriveDocumentsForSigner: string = '/api/V1/SignDocument/DownloadDriveDocumentsForSigner';
    public static UploadSignerSignature: string = '/api/V1/Document/UploadSignerSignature';
    public static DownloadDriveImageForSigner: string = '/api/V1/SignDocument/DownloadDriveImageForSigner';
    public static UploadSignerSignatureFromOtherDirves: string = '/api/V1/Document/UploadSignerSignatureFromOtherDirves';
    public static DownloadOtherDriveDocumentsForSigner: string = '/api/V1/SignDocument/DownloadOtherDriveDocumentsForSigner';
    public static ConvertTextToSignImage: string = '/api/V1/SignDocument/ConvertTextToSignImage';
    public static ConvertHandDrawnSignImageUpload: string = '/api/V1/SignDocument/ConvertHandDrawnSignImageUpload';
    public static ConvertHandDrawnSignImage: string = '/api/V1/SignDocument/ConvertHandDrawnSignImage';
    // public static UploadBase64StringToImage: string = '/api/V1/Document/UploadBase64StringToImage';
    public static DeleteAndUpdateSignerDocFromDirectory: string = '/api/V1/Document/DeleteAndUpdateSignerDocFromDirectory';
    public static DeleteSignerDocFromDirectory: string = '/api/V1/Document/DeleteSignerDocFromDirectory';

    //Authenticate Signer
    public static AuthenticateSigner: string = '/api/V1/SignDocument/AuthenticateSigner';
    public static GetCodeAndValidateSigner: string = '/api/V1/SignDocument/GetCodeAndValidateSigner';

    //GetImages and GetImagesForSubEnvelopePrefill
    public static GetEnvelopeImages: string = '/api/V1/Envelope/GetImages';
    public static GetTemplateImages: string = '/api/V1/Template/GetImages';
    public static GetImagesForSubEnvelopePrefill: string = '/api/V1/Envelope/GetImagesForSubEnvelopePrefill';

    //url direction
    public static HelpAndSupport: string = 'https://support.rpost.com/hc/en-us?rsign-list';
    public static SignupROne: string = 'https://rsign.com/#signup';
    public static RSignSignupCompleted: string = "https://rsign.com/#signup-completed";
    public static RSignSignIn: string = "https://rsign.com/login";

    //Security Code
    public static DecryptSecurityCodeUrl: string = '/api/V1/SignDocument/DecryptSecurityCodeUrl';
    public static ValidateRecipientBySecurityCode: string = '/api/V1/SignDocument/ValidateRecipientBySecurityCode';

    public static GetRedirectURL: string = '/api/V1/Envelope/GetRedirectURL';

    //StaticLink
    public static DecryptStaticLinkSigningUrl: string = '/api/V1/SignDocument/DecryptStaticLinkUrl';
    public static InitializeStaticTemplate: string = '/api/V1/SignDocument/InitializeStaticTemplate';
    public static AllowFirstSigner: string = '/api/V1/SignDocument/AllowFirstSigner';
    public static AllowStaticSigner: string = '/api/V1/SignDocument/AllowStaticSigner';
    public static ReSendPasswordEmail: string = '/api/V1/SignDocument/ReSendPasswordEmail';
    public static InitializeMultiSignerStaticDocument: string = '/api/V1/SignDocument/InitializeMultiSignerStaticDocument';
    public static InitializeMultiSignerStaticDocument1: string = '/api/V1/SignDocument/InitializeMultiSignerStaticDocument1';
    public static InitializeSignerStaticSignDocument: string = '/api/V1/SignDocument/InitializeSignerStaticSignDocument';
    public static InitializeSignerStaticSignDocument1: string = '/api/V1/SignDocument/InitializeSignerStaticSignDocument1';
    public static CreateStaticEnvelope: string = '/api/V1/Envelope/CreateStaticEnvelope';
    public static CreateInviteSignerEnvelope: string = '/api/V1/Envelope/CreateInviteSignerEnvelope';
    public static DeleteInviteByEmailUsersAttachments: string = '/api/V1/Document/DeleteInviteByEmailUsersAttachments';

    //Group Envelopes
    public static GetTemplateDetailsForSignMultiTemplate: string = '/api/V1/SignDocument/GetTemplateDetailsForSignMultiTemplate';
    public static GetMasterEnvelopeStats: string = '/api/V1/Dashboard/GetMasterEnvelopeStats';
    public static FinishSubEnvelope: string = '/api/V1/SignDocument/FinishSubEnvelope';
    public static DelegateMultiSigner: string = '/api/V1/SignDocument/DelegateMultiSigner';
    public static GetBasicEnvelopeHistory: string = '/api/V1/SignDocument/GetBasicEnvelopeHistory'
    public static DownloadDocument: string = '/api/V1/Document/DownloadDocument'

    public static ConvertSignImageWithStamp: string = '/api/V1/SignDocument/ConvertSignImageWithStamp';
    public static ValidateBotClick: string = '/api/V1/Validate/botClick';
    public static ConfirmUserOnFinalSubmit: string = '/api/V1/SignDocument/ConfirmUserOnFinalSubmit';
    public static ValidateFinalSubmitOTP: string = '/api/V1/SignDocument/ValidateFinalSubmitOTP';
    public static RetriveAllDocumentControlData: string = '/api/V1/SignDocument/RetriveAllDocumentControlData';
}


export class RecipientType {
    public static CC: string = "63EA73C2-4B64-4974-A7D5-0312B49D29D0";
    public static Signer: string = "C20C350E-1C6D-4C03-9154-2CC688C099CB";
    public static Sender: string = "26E35C91-5EE1-4ABF-B421-3B631A34F677";
    public static Prefill: string = "712f1a0b-1ac6-4013-8d74-aac4a9bf5568";
}

export class Controls {
    public static Signature: string = "CC8F7E49-E9CF-41A2-9D7B-5BBC4759D9CA";
    public static Initial: string = "9506A039-DF8D-4650-8552-8B650C9889B1";
}

export class ControlsName {
    public static Signature: string = "Signature";
    public static Initial: string = "Int.";
}

export class SignatureType {
    public static Auto: string = "05280C40-3DC8-479E-BD58-AE2FE0D62A97";
    public static Hand: string = "AEB3D4A0-9DCF-4441-98A8-8ECDB7169936";
    public static UploadSignature: string = "8D122A62-56FB-4118-9140-FCFF5FD66AC5";
}

export class InitialType {
    public static Auto: string = "05280C40-3DC8-479E-BD58-AE2FE0D62A97";
    public static Hand: string = "AEB3D4A0-9DCF-4441-98A8-8ECDB7169936";
}

export class Defaults {
    public static DefaultImages: string = "../../../assets/images/"; // "/Content/NewTheme/img/";
}

export class ResourceKeys {
    // public static LearnMoreTextOnly: string = "30DAEF26-A701-4CB4-8C9D-9A63E5D760DB";
    // public static freeTrial: string = "A6FD5C43-BF20-49C5-A321-616DD7ADC006";
    // public static RedirectPostSigningLandingPage: string = "A43B475A-1180-4117-965C-53E65B4C1B82";
    // public static Sessionpopupseconds: string = "3367D21B-93C0-48F7-B299-AD51327DD48C";
    // public static eSignExpiredFeatureList: string = "25b27318-817c-4aa8-9972-837de7d41e92";
    // public static eSignExpiredServiceFeature: string = "435424f7-2ef0-4a54-a9b4-eb479f1f4a43";
    // public static RSignMakesESignatureText: string = "EF529659-7036-4772-982C-0D7EF54A51F2";
    // public static SignerTermsCond: string = "4C983255-3604-4C21-84D0-92990960941E";
    // public static Acceptingthetermsandconditions: string = "6880F76B-6136-423E-8DA4-D2B6E9D26BA1";
    // public static ShowTags: string = "18CE2312-4D51-4518-8A8F-B643A21B0D80";
    // public static HideTags: string = "EB421BC0-73D6-4ACD-A868-2051593B657C";
    // public static DGPostSignPopupDesc:string = "E3950879-FFE2-42AA-9BF2-E0DF2320F549";

    public static RsignFeatureReminderLabel: string = "C2A4AFA8-AD47-4F3F-A705-0012A06EBF24";
    public static AddRecipients: string = "816D417A-B3B0-4A54-A738-00BD7B8C99EE";
    public static DeleteSignedContracts: string = "6B4027A8-072C-4529-BB82-02315F27CACE";
    public static SessionExpiredMessage: string = "B9D51C87-87BB-4A0A-BB01-02360567E29F";
    public static AccessCodeRequiredToOpenSignedDocument: string = "F50FFA81-3992-43CE-9D80-0273F965BFF9";
    public static Updatedby: string = "FF8B9EE8-AD55-4C68-8453-02CD7A3055C4";
    public static NoFileUploaded: string = "363945CD-0687-4D74-87C0-02E5EC5FFE67";
    public static eSignFeatureList: string = "EBBF75FC-C885-4340-A373-037927C184C7";
    public static Reject: string = "C344955E-874A-4B46-9ED0-03897DCC67FE";
    public static RejectedBy: string = "71CA5FEB-C532-4A43-8869-03B36FDFECC3";
    public static TextModalHeaderP: string = "E626AEFB-4D77-4D23-96FF-04BF184C6979";
    public static Messagesignaturetext: string = "A0BC5FEC-B337-4B15-B8EB-04CAC4F163A9";
    public static FALSE: string = "1372287A-F6DD-49BA-AB90-06CAD1EB6BC0";
    public static RoleHeading: string = "6EC5A6B7-84E9-475E-A3C1-06D3B9CD6625";
    public static Subject_EnvelopeRejected: string = "4E9E254A-22AC-499C-B14A-06F5B531A9E4";
    public static AdminEmailResponse: string = "381076FE-7A67-4287-A510-078020E0C6C7";
    public static lang_cntrlingField: string = "72D31789-BCB4-49B5-8FCA-07E952011C97";
    public static SrNo: string = "A1F67F2A-C9AC-4342-BD58-087A0148D2B9";
    public static Terminated: string = "4FAA67B6-3847-4B02-A122-08DAF97BA966";
    public static Open: string = "C875780A-B5D9-4F60-87A1-0933B179FE09";
    public static Numeric: string = "A4539C35-C491-4435-BA4C-09891FEF2E77";
    public static CreateStaticLinkforTemplate: string = "F20ED49A-F9CE-4A38-9F88-0A481BC1B501";
    public static PasswordInvalid: string = "CC76B3B1-9BFB-4690-8614-0BB32FA307CB";
    public static FileFormat: string = "02DB7641-A475-433B-BB88-0C3712A07D35";
    public static ProfileSaveSuccess: string = "4A6AAA43-3279-4082-B2FB-0C5BD8C16F40";
    public static EmailSuccessfullySent: string = "FD33F192-FEE5-485D-8389-0D019A761989";
    public static SuccessInitializeEnvelope: string = "F09D694F-C854-47DF-B5D9-0D23D1839507";
    public static EnvelopeData: string = "66E777DF-365D-4DE4-9188-0DA8EEF29108";
    public static SelectedLanguage: string = "779B7FB8-A247-47D2-8B08-0DDD81FD2D37";
    public static NoDraft: string = "5D53E5CE-AD61-49B8-BB24-0E9A317CF638";
    public static UnitsRemaining: string = "786FA05C-0018-4F4C-B6B4-0F0DFAF25B2E";
    public static SignedDoc: string = "1FA50861-CA0E-41DD-8A6F-0F3ECE2458E3";
    public static Name: string = "391FF1F4-6A14-4782-A7C9-0FA49F36A104";
    public static Checkbox: string = "B5D83695-8B5B-4475-BAE0-0FC873C77DA1";
    public static Templates: string = "4A8C6D07-F4CA-41B5-BCD1-102CAE6615AE";
    public static Width: string = "22B062B3-E204-47B5-B179-10AE944565D7";
    public static AwaitingMySignature: string = "3B1099DB-A54B-4DB9-89C7-13020AD13EE1";
    public static ContainsText: string = "F4B6AC3B-006C-48D7-A085-13098A8818B3";
    public static Address: string = "C1841288-A024-42C4-868C-134A33C92B2C";
    public static TypeSignature: string = "72C42EB0-F203-4456-83DB-14EDB767DBDC";
    public static MuchMore: string = "F2A4C744-BEB9-48ED-9C41-16BA46502B11";
    public static EnvelopeDeletedSuccess: string = "87C71D7A-888D-43B4-9389-171CE36213F7";
    public static title_DeleteselectedControls: string = "E937C40F-4572-40F3-B261-1736689CBC09";
    public static FinalContractContentN: string = "AD1D84CE-4ADC-4B80-A8D0-17583CBF6F47";
    public static Submit: string = "CBD33E2D-8F50-4A92-B934-183D03D0EE34";
    public static ReminderEmail: string = "2B7F2DDE-F94E-45FE-A795-1903DF9A3912";
    public static RadioButton: string = "8C91184D-447F-443F-9914-194F3CE03E42";
    public static SignerName: string = "E440BF9C-E8FA-4727-B5CA-19B9D463BBD0";
    public static EnvelopeDraftSuccess: string = "FCAED700-04B8-41B9-ABA3-19D2CEF2265F";
    public static title_pasteselectedControl: string = "B0F7717E-B627-4AAD-9D5C-1A8359BF494F";
    public static DefaultSignature: string = "F10734C1-189F-4545-A2F0-1A8848AB1056";
    public static Iagree: string = "F54DEA9B-E74E-4280-B543-1AC466C16A79";
    public static SignDocument: string = "553133C6-3D00-4DE6-BC40-1BA68EF06D52";
    public static lang_DeclineEnvelopeProgress: string = "C2CB8495-BFBA-4F89-AAAC-1BB67A4E5207";
    public static ResendEmail: string = "181670B1-BA07-48E9-A5B7-1BBC585C96CA";
    public static PendingBy: string = "ACC3D802-EC47-42A3-B049-1C47DE76D80F";
    public static title_sortEnvelopeSubject: string = "C0AEBA7A-84A8-4CBC-8965-1CA33208B22A";
    public static EnvelopeXMLContentN: string = "65777238-DD71-4453-9D32-1D673B15DD5A";
    public static viewDoc: string = "B838349C-BD18-4933-9EA6-1DEA92617D95";
    public static CompanyName: string = "0FD9DABD-D58F-4E22-B4DA-1E85ABF2E760";
    public static DateFormatStr: string = "113E2BA8-F40A-47CC-9384-1F1E8CB58368";
    public static AddMe: string = "345A6F27-05AC-4B5C-9651-1F2070D39740";
    public static TimeoutRefresh: string = "351645D0-B7BA-4880-B5F2-1F2C26F4A6FC";
    public static ApplicationError: string = "FAAD2ADA-0E04-4D3C-ACDA-1F4FB62B3C48";
    public static migrateNewUser: string = "8510EC25-A8A9-4979-AE5C-1F76A05EAE60";
    public static UPGRADE: string = "79810927-83B8-4198-A038-1F8BD0303471";
    public static alreadySigned: string = "B899BF6C-2BF4-4856-97C0-200DE7DBBC35";
    public static TypeYourName: string = "85965CF8-F230-42FA-B2E6-20518AA6C115";
    public static Myplan: string = "E28556C2-E755-48F8-9C28-20845C302701";
    public static uplaodLogoImg: string = "B582C532-41D5-4FFE-AFAE-2086701CA87F";
    public static SharedTemplatesandRules: string = "3BFF00AB-8523-4167-BF3F-209ABFDACDD2";
    public static AddMessage: string = "96E9EA7D-13C9-47A7-9ECA-214B7FBF662D";
    public static GrpNameMessage: string = "033B5F51-E04D-4316-83D3-217B612C573D";
    public static Link: string = "A02A4EDC-13FF-4DA2-B400-226F998F4434";
    public static signatureImage: string = "FDA3A303-39EB-4569-B10D-22CBB3E53EC5";
    public static Expand: string = "4C13C666-B884-446B-B887-231095A6846A";
    public static DownloadData: string = "E9CC051A-29F2-4EF9-B3CE-234AFEFD8DDE";
    public static LoadingDashboard: string = "2465B554-9531-43E7-A63D-23B0125A9ED3";
    public static MadeSimpleLabel: string = "D4BE3E6C-1AA3-4A6C-91A9-2468420FFED4";
    public static DelegatedBy: string = "38562864-FAD1-410E-A54E-24B88D26CF1D";
    public static AlreadyExistValue: string = "E6326EFE-E8FE-495B-9D0F-24C80AD83117";
    public static InvalidFile: string = "0AAF35C9-B823-4504-B203-25065AF7EE4B";
    public static Hi: string = "EE0583CE-968E-4BCC-B32A-25252C31E152";
    public static UserDelegatedPartOfEnvelope: string = "8153A5AA-62E4-4E1E-A3AF-252FED2A800F";
    public static DocumentSent: string = "33C8D394-B5A9-4D8C-8032-259F4FCF99D0";
    public static EnvelopeTerminated: string = "DD2A2D57-CBF0-419F-8E48-25D449E313ED";
    public static Draft: string = "F0E1DC46-F352-4D69-A2FE-26BC4740C96F";
    public static SessionLogOut: string = "E1D5B882-A547-498C-AC7D-2872178EDE78";
    public static LegalNotice: string = "66C9D9F0-EC72-4AEC-8886-28F5701AF892";
    public static Please: string = "78F254F2-6687-4729-8F50-2A2AF57EE482";
    public static migrateCompanyReview: string = "B4158B5A-2B68-4D1C-944D-2A9E9318E0CC";
    public static TextModalApprLength: string = "44CDC1E6-1E05-439D-A106-2BF11201A1CC";
    public static FromDate: string = "9CF517C6-EB48-496B-88AE-2C5E9AFE85D1";
    public static ThenSendReminderEvery: string = "5036967F-6516-43A2-91DD-2C7BBB6950F5";
    public static DocumentHash: string = "806105C9-4D67-4A32-9862-2C93F44B17DA";
    public static GENERALSETTINGS: string = "1DFC1FA9-FC98-4ED1-B421-2D1C291BDFB6";
    public static CompanyNameBlank: string = "05295426-130F-47E5-8D53-2D661B3B803F";
    public static DocumentDelegated: string = "90FDBCFB-15EE-4AE7-A2C6-2DB3AB0A5C30";
    public static DomainNameBlank: string = "C9B03242-F53C-406B-8CDB-2DFBE1B8496F";
    public static AdminEmail: string = "9C07343E-11A8-4DFB-9C68-2E1D6334DFFB";
    public static TRUE: string = "438F4CC9-47D8-40D6-898A-2E8F1F9E1F38";
    public static RPostTechnologies: string = "A264B5C8-53C9-482C-8DFF-2FED0FD88E87";
    public static Visitusat: string = "AC16B6A3-A21D-4510-A23A-300A066A809B";
    public static CreateRule: string = "09EB67BA-4FF7-49AA-8728-3091428E6757";
    public static Report: string = "013710AB-5EB8-488D-AE07-319854850F67";
    public static ReSend: string = "74C263DE-AF18-4CFE-88BF-31F0552BAB0D";
    public static PostSignerLandingPage: string = "7DDA3A1D-BA8A-455F-A368-320EBEE6E0C0";
    public static EnableContentEditing: string = "0BA50979-6531-4B41-A524-3255E32E5336";
    public static TemplateCreated: string = "08530191-8CA2-464A-9B45-32C7116FCC6E";
    public static Home: string = "B77C9677-7217-4E21-9422-330C0CA983A1";
    public static EnvelopeCancelled: string = "E0BB7985-04E1-4366-B7C9-339B2C45DCF9";
    public static SentFor: string = "562FDEC3-33CF-4985-BBE4-343C85E8EC43";
    public static AwaitingMy: string = "815F46BC-7ABC-4EE8-A6F2-34574D29A769";
    public static Company: string = "78348D80-2C99-4C2A-9A19-3483966A5AD6";
    public static title_Alignselectedcontrolstoright: string = "45359ABC-23FC-4B40-8883-34DBA307C58B";
    public static Date: string = "0EAB0BE2-0420-4D2D-AC8E-34FF54A73A3A";
    public static getStarted: string = "A91F0BA8-4711-438A-ABAD-35012E00958D";
    public static enterEmail: string = "93668F73-7023-4E26-ABE7-356B620DA9F5";
    public static supportedFormat: string = "11C4F434-45E4-4534-8DA8-35E28187E4C5";
    public static Documents: string = "B8EE7347-F40B-4C57-87F5-369E44565ADB";
    public static TermsServiceOncertificate: string = "D296AC02-7D15-48C8-830E-36BF5B24ED91";
    public static title_redoselectedControl: string = "274FDD6E-8CAF-43AC-8E4E-399E5A17851C";
    public static Changepaswd: string = "394BE551-6883-4AD6-9067-39DD88731473";
    public static resendToThis: string = "9ED8DB87-8B5B-40AB-A3A9-3A0233A1FD2C";
    public static CurrentUser: string = "B0E1080C-4B0B-4FFB-9D22-3A05DA1FF594";
    public static DuplicateFile: string = "557033B5-3AD6-4179-9A46-3AA61346B535";
    public static ResendToall: string = "FFE08E94-4AF3-4F70-B147-3EB0AC2F52A5";
    public static TransparencyContent: string = "79F83826-BA58-45F7-8CA2-3ED169722984";
    public static SessionKeepSign: string = "C4F375B8-E46C-410D-A774-3F1C8B72A2E7";
    public static CancelledDoc: string = "69D32A88-F79C-4F51-AC8E-3F69F40799B1";
    public static DefaultValue: string = "1006CB52-2CDA-405B-9D2D-3FB854E6DA7A";
    public static Prefill: string = "712F1A0B-1AC6-4013-8D74-AAC4A9BF5568";
    public static RsignNextLevelLabel: string = "7DE04548-F7A4-4791-B4F1-41C4F57773C5";
    public static EditEmailAndUserName: string = "82E38999-A032-4BE8-B707-428D2744E763";
    public static ManageDomain: string = "E09856FB-3D73-4EF6-BD6A-42D55561FEB7";
    public static AllSignersSigned: string = "D44A9528-9621-407E-8B33-430970ED6501";
    public static TermsOfService: string = "626C7F9E-B167-424F-B195-43C99C4BCC3E";
    public static Sessionpopupsigninbtn: string = "C0B4BB8D-7DBC-4173-ABEA-43DA1F178862";
    public static EnvelopeRejected: string = "5E901B5B-588B-4FDE-B373-44DE1B02AA86";
    public static DraftSuccessfullySaved: string = "5F3D08A4-5384-4D56-B094-452171BF8EB5";
    public static FinalContractContent: string = "A7A9192B-FFA8-4E97-9964-45E7F3B2E295";
    public static Type: string = "1911745D-DB10-41E9-A6A3-465DE82553C6";
    public static LastName: string = "AF83E84B-291D-4855-AD1B-4689F23F8754";
    public static Sender: string = "26E35C91-5EE1-4ABF-B421-3B631A34F677";
    public static NoEnvelope: string = "C78D921D-C6FB-48A6-B931-46C17299DD78";
    public static NoEnvelopeSearchMessage1: string = "3AB2CFC4-DDE0-4757-A263-AF3CF5BCF5A4";
    public static NoEnvelopeSearchMessage2: string = "1AF71911-9DC1-427C-8801-88B9B2FDB017";
    public static UserAuthentication: string = "FC06967E-6DAC-4AF6-A0FF-46D05533F872";
    public static ActivationMessage: string = "A03D776E-5520-4D09-8C3C-470974D0B395";
    public static Envelope: string = "53384CC3-1AD6-4E75-8C1E-47121AE47491";
    public static TemplateData: string = "8A2879EE-E0FF-44D7-AFD9-4748CDFE5ABF";
    public static title_viewAll: string = "433B4C60-BE01-4C88-BF09-474EC1499D2B";
    public static AllFieldsMessage: string = "7B4DC04C-F3E3-4260-83FB-47AD03A27E46";
    public static Myprofile: string = "72D9CAC8-2414-48E9-AA3E-48BD66C5663E";
    public static title_Alignselectedcontrolstoleft: string = "BABF8DC9-A66B-45DA-85E7-48C2BCE32175";
    public static DomainIDProvide: string = "133A6E20-BB14-4B49-990E-492D8D6283FA";
    public static EnvelopeTransferred: string = "969A143F-1637-40A4-9D60-4942C443A0EE";
    public static staticLinkEnabled: string = "B396310A-B977-4664-98E2-4A232C80277A";
    public static invalidSize: string = "76BEE30E-F1E4-4850-B22C-4AAA66A06FE9";
    public static Recipient: string = "32128ED5-531A-4AB1-ACE6-4B8953C70D13";
    public static Sessionpopupminutes: string = "2EADAFC9-0378-4849-85F0-4D20C9C87210";
    public static Signed: string = "CA4F7024-9FA0-46B3-91B5-4D494877046B";
    public static StatusDate: string = "810E7ABD-0325-4238-96D0-4E12F08B40D1";
    public static ViewPDF: string = "5A1DE24B-ACD1-41BD-A300-4E646D5DECE8";
    public static DownloadLinkOnManage: string = "AECAE4AC-3DFF-476C-84F7-4EA9160D18E3";
    public static AccessCodeToOpenSignedDocument: string = "2E4DF021-1399-4531-9302-4EF4FE08D204";
    public static Completed: string = "9574A292-D570-43E5-9EF9-4F1ADCBF800C";
    public static EnvelopeSubmitted: string = "CA117F9E-0D73-4F67-8E5A-4F298D857E72";
    public static Created: string = "56B5F353-1CDB-4CF0-B782-4F4DB88EF84D";
    public static Logout: string = "7DCB707A-56AA-49B6-9CB7-4FE9CB4412B4";
    public static Local: string = "756661BB-2461-45C2-8113-5201EB58EBE5";
    public static TemplateCode: string = "C607560E-54F0-48F1-91ED-52D032B57962";
    public static DraftIt: string = "3BCA6EA3-E26B-412D-A50A-52F3C14EBEE5";
    public static Sosionpopupsignoutbtn: string = "E115E028-FDF2-4121-833A-52F784F561B2";
    public static Expired: string = "9473F326-C53F-407C-B7ED-5349C3469F79";
    public static Yes: string = "F0E0417A-2482-45FD-89B0-54269409999E";
    public static lang_dependentField: string = "E948C454-B0A1-47B6-9F5E-56E02F18E69A";
    public static Staticlinktemplate: string = "0C9B679E-7165-4FCC-85F2-578BB264872A";
    public static ChooseFile: string = "DFEEC95D-9BDD-4E49-B710-57AF70707015";
    public static SessionKeepOut: string = "01C61630-ABF3-4E28-BB0E-57E7F869C7BC";
    public static Password: string = "BE2EABAF-9096-4027-BE83-5802B8F3A50B";
    public static FooterTextLegalNoticeLink: string = "00CBE5D0-1393-46E6-AE4A-5806B45B0B1C";
    public static RejectionReason: string = "A99D3BFF-CF0E-4ADD-AF44-58B5CB29D9DB";
    public static Sequence: string = "3747D0FE-1021-4BCF-B22A-590779C19799";
    public static Checklist: string = "05985821-7ED3-4581-B982-5973D3CB8F01";
    public static IsActive: string = "E2C10EC8-06B9-4CEA-B6EA-59BAE0B9BD63";
    public static Label: string = "735BF0E9-FC01-40A6-82A3-5A037A3FF9B5";
    public static ConatctSender: string = "411CECE9-2884-423D-A83B-5B02AAD11ED7";
    public static Signature: string = "CC8F7E49-E9CF-41A2-9D7B-5BBC4759D9CA";
    public static AlreadySignedUp: string = "95106BFF-97C8-4DF4-88BE-5CD8E4928095";
    public static Localmachine: string = "7FD82CF2-6413-418A-B3A7-5D2400CE1808";
    public static EnvelopeCompleted: string = "117327B1-C2B6-4D7B-8700-5D252D9D1822";
    public static Download: string = "C4BF6ECB-D741-4F1F-B1CC-5D80A066F2F8";
    public static ResendAndEditUpdatedBy: string = "2BB3AC8D-00A0-4FA1-9832-5E0466D86BE8";
    public static AvgTimeOfCompletion: string = "BAD02A17-381E-40D9-A35E-5E37D2BB0F3B";
    public static Dimensions: string = "A1E86660-4235-451E-8914-5E9F6D8CC702";
    public static EditProfile: string = "52AE55CE-AECA-4537-AE1C-602D22855A67";
    public static ToDate: string = "A4E832B8-D0D8-4C7B-9A47-603B2E0FAFB5";
    public static EmailAddress: string = "04F496B6-4135-42F9-B2C0-604C569B0D0C";
    public static Comments: string = "9BAC9CFB-7EC4-4C2B-88E1-9DCC65D430BE";
    public static EmailAccessCode: string = "FE1C80C7-E56B-4EF3-A15A-607E63D9652F";
    public static AccessCodeRequiredToSign: string = "38AE42E3-11A1-41A6-8414-61162BC635DD";
    public static freeTrial: string = "A6FD5C43-BF20-49C5-A321-616DD7ADC006";
    public static TimeZone: string = "7D5EBF97-49DD-4A70-8D2B-620EE152EDA9";
    public static EnvelopeCancelledFailed: string = "53F81332-AEB1-4E3C-B885-621DBEFBB105";
    public static EnvelopeContentNotFouund: string = "F756A843-EA16-4585-B403-62B207136A0B";
    public static Pagesize: string = "1B627C82-5E57-4267-9DE1-6306206B7676";
    public static DeleteFinalContractSuccess: string = "C5A535B8-2B64-4206-878F-635081599076";
    public static UpdateUserSetting: string = "BFAD21AD-512C-4C06-BFE8-643E52FEC8A2";
    public static VisibleRequired: string = "8EA7ED1B-ECB1-4CAB-B85E-6499C455381F";
    public static SigningDocumentCompleted: string = "E474057C-CEB4-427A-A3CF-64D77C90193E";
    public static SignUp: string = "0A2A4C06-A2BF-446F-97D9-655B281B63CB";
    public static CompanyURL: string = "C09E3C0B-3272-4E15-9DF9-659DA6A89F2F";
    public static ExpiringSoon: string = "30B08A89-259F-4927-9888-660FDA431095";
    public static FinishLater: string = "B50BBC95-D622-4C6C-94FF-664629F1C524";
    public static EnvelopeAlreadyDelegate: string = "7B6B87A6-491F-4FFC-AA28-665897019D10";
    public static EnvelopeExpired: string = "76BE755F-A451-42B7-8417-665D55867920";
    public static PrintTS: string = "CD5A23DF-9FCF-49CA-816A-66B2E7A0E422";
    public static Rejected: string = "2E4496F4-677B-4B24-9B8C-66CABDDC4CED";
    public static title_resendtoAllButton: string = "E42114A8-1D3F-4BA7-BAD6-676401A8C257";
    public static Height: string = "54010589-9302-42BF-888E-677D763AC631";
    public static Signer: string = "C20C350E-1C6D-4C03-9154-2CC688C099CB";
    public static EnvelopeXMLContent: string = "3E37FE99-B960-46EA-AA54-680414DE400B";
    public static IncompleteAndExpiredDoc: string = "7BFCBD48-0A4B-4DE1-B4B9-688E96EDA2AE";
    public static CurrentEmail: string = "5B375A19-7224-4877-895D-68C6B0FA5B33";
    public static ExpiresIn: string = "ACE132A6-3F58-4B3B-BE82-691446C0A0B8";
    public static UploadNew: string = "D27CA932-6095-4F9A-8918-694729C1F9EA";
    public static DocumentEvents: string = "71CCE528-6FC1-45AF-95AE-6980862D14E7";
    public static lang_SavingControlDetailsProgress: string = "CF1B69FC-6E9A-4267-A1D1-6A494F393781";
    public static PersonalTemplates: string = "2D0F6F50-EECB-4A84-A76A-6A58C82FC66E";
    public static Patented: string = "09D88CDF-7416-45E1-B626-6AAE7FDA9EDC";
    public static Prepare: string = "D291245F-5C98-46B1-BB29-6AC823A3BAB4";
    public static Sign: string = "D6BA71C2-91A4-41CE-ABE8-6AD9CD5F757A";
    public static Remove: string = "1342D97C-743C-4F29-AD78-F12A16743009";
    public static SeparateMultipleDocumentsAfterSigning: string = "95266884-7C92-46FC-8A32-6C31D078EF6D";
    public static Recomemded: string = "04380F78-9009-4573-96E4-6CF3FFD72D10";
    public static ShowSettingstab: string = "BF384411-1B3D-4E3F-A5B5-6CFBBEB34C64";
    public static SpecifyaTimeZone: string = "15B93935-8750-4F3F-8333-6D2B1C31252B";
    public static RecordUpdate: string = "59DC22BF-21A1-42B9-BBD1-6D700686AC2E";
    public static Initialed: string = "C50DDA04-9E80-46CA-A9BD-6DF4953F5F53";
    public static Required: string = "93F9CE7C-3295-4B61-AA5B-6E116BC5F078";
    public static PendingDocuments: string = "6EB1298A-FAB8-43C9-B8B8-6E17934A09B5";
    public static RememberPwd: string = "CB9AF93A-A8B3-46CC-8C16-6E5799A71C3A";
    public static Initials: string = "0F001FA1-C71F-490B-93CF-6E5A55ABAAD5";
    public static Subject_DocumentDelegated: string = "9E9E0E40-B6A3-414A-888E-6EA320E58DAA";
    public static XMLCreateError: string = "2BA77314-AD49-40DB-8781-6EB7F76EAF8B";
    public static WrongDelegatedUser: string = "64C1C12A-4EDF-4A32-97B4-6F0EA29B8DC7";
    public static CompanyDescription: string = "B7743F69-C687-4473-8C61-6F463DC94BEC";
    public static rangeBetween: string = "061EE9E3-0439-41A2-98BA-7015FA726271";
    public static VerifyDimension: string = "4F3F27D4-7715-478F-941E-70EFFAFB9960";
    public static JobTitle: string = "4AF0FEFD-8EE9-44E0-B240-717D59072231";
    public static ChooseSignature: string = "4DA35121-888A-488C-A466-71F7C8D8B22A";
    public static RsignFeatureTrackingLabel: string = "4F164C4D-9408-4BDB-8CED-72FBE60A4EFB";
    public static RuleData: string = "10E71507-EFAF-4DBD-B1A6-73454AB9DD19";
    public static preSelected: string = "5AD8935E-32C4-4C0A-AE16-738053932F3E";
    public static NoFileSelected: string = "765D0E22-BFEE-469B-B7EF-75133E8BF292";
    public static PhoneNumber: string = "DB5FAE8A-C60A-4C74-89D6-7531063262BC";
    public static Save: string = "99ACCB6E-0617-46DA-A539-75AB0F047ED6";
    public static lang_processing: string = "9ECF474A-193C-45D5-B1DD-760B5D1C32A8";
    public static Add: string = "06547D5E-8427-4956-A8A3-76211E98B1C7";
    public static title_Alignselectedcontrolstocenter: string = "7DE4AB94-982C-4AFF-8A56-7649333D80C4";
    public static title_viewEmailBody: string = "A70BFF36-2625-47AA-99AF-767C629F84F6";
    public static EnvelopeDelegatedTo: string = "ECBAC9EB-37A9-4D64-95B7-7731C258BB6D";
    public static Sync: string = "3D4277E0-0CEE-4D03-9E9D-789264C89CD0";
    public static NoUsersForThisCompany: string = "34F77774-B946-4C25-9D34-790EB961F2F7";
    public static responseFinishLater: string = "C8C1D755-0ABF-40C4-BE27-79EF8DDF5C08";
    public static lang_Actions: string = "4C70C532-730C-4C21-871B-79FEFA3F1D06";
    public static RsignFeatureShareTemplateLabel: string = "FDEEA25E-55E9-4CA9-A0CC-7A4340221794";
    public static TemplateEditable: string = "5B0E5841-7C67-40D0-98E7-7B501F1EDC48";
    public static RsignFeaturePrefillLabel: string = "A5B5C9CB-A383-4540-B909-7B7D52ECBF9C";
    public static Sent: string = "CEA7D4C5-14C4-44B6-9B42-7BE91A40FF61";
    public static Enable: string = "6FB06C1E-0291-418A-A668-7BED3373E3E5";
    public static RuleCreatedUpdateSuccess: string = "16406FEC-E895-4C5E-B740-7C234C383FAD";
    public static TerminatedDoc: string = "B4C7CDE2-E31B-4EA5-8875-7C2F2666D8B7";
    public static NoTemplateRule: string = "70303EB9-3E6E-4884-AE85-7C671C11157D";
    public static RsignFeatureReportsLabel: string = "E589EFC1-51E7-47B0-870C-7CC37F975948";
    public static RejectionComment: string = "DE49519B-C69E-4742-91C8-7D013FBFFA9B";
    public static EnvelopeID: string = "9C2A99C8-DF21-4FCF-9968-7E2F5A8629BC";
    public static enterValid: string = "981497D4-EEE6-4730-BB9D-7E52E723D313";
    public static CompanyIDProvide: string = "AC6AE96B-ED23-44F2-9F60-7E765ADDFE28";
    public static TextType: string = "6E90B110-5AC8-4B23-A086-7EEAC8F41154";
    public static Share: string = "C2D79D51-E607-489C-8CA3-802068DEA247";
    public static RuleCode: string = "78F7E70A-5F8A-4DF9-9F90-805CC8E20634";
    public static No: string = "0E9CDDC4-25BF-4BF0-AD53-80D8C89E185D";
    public static CreateCompany: string = "27833374-F166-4C5E-862C-8136B7DCF23F";
    public static title_copyselectedControl: string = "E48BEFC1-E152-44AB-8EBB-82223C148A42";
    public static ToLearnMoreAboutRPostServices: string = "2FA3250E-7C3E-4319-910C-83BA77D7BB23";
    public static Subject_EnvelopeSubmitted: string = "90B2BD0A-18B5-466C-B80D-8453CE4067F7";
    public static ExportToPDF: string = "E4807FAA-76C7-4487-ADF2-84D34263DD23";
    public static DeleteFinalContractFailed: string = "3F4386B1-91CA-4D6A-BA91-8570FD1904C5";
    public static ResetPaswd: string = "D4F33217-9AC7-4DD4-83DC-860230BB9B79";
    public static DownloadTS: string = "7C89B3BB-9097-4815-BAAE-868010FAFCA4";
    public static DelegatedTo: string = "A5F1954D-C956-4D28-8A41-86D9A78DAF49";
    public static SessionAboutExpire: string = "04C148F5-E4B8-4EE7-A7B8-87152953E8AA";
    public static lang_accessAuthPswdTitle: string = "BC9FC450-934E-4941-860E-877CE083BBBA";
    public static AddSignatureCertificate: string = "700361D4-36E6-434A-8925-87B68710395C";
    public static CancelledTrans: string = "41B582AE-B4F0-46C8-8AD4-8942973557AF";
    public static sampleDoc: string = "BD55D09E-EAFD-4D84-9CFF-899263BF1253";
    public static Template: string = "0924B054-2E88-4E0B-9F26-8A3DBD2121AD";
    public static EnvelopeAlreadyDelegated: string = "80A13FCC-4900-44C2-94E2-8A840733FEAA";
    public static ForgotPassword: string = "E4FCD335-539F-4A13-AE60-8B4F98D1CAA3";
    public static Prev: string = "5F2140EB-6923-43F2-8ED4-8B5CB3A0164E";
    public static OldUser: string = "2B501A77-5A69-4DD2-85F3-8B8B87EB5217";
    public static All: string = "F874B17E-F1E9-4195-8687-8C4D0C6067F5";
    public static EnvelopeSessionNotSet: string = "10EA367D-6A8F-4ADC-8179-8C70CE26385B";
    public static Document: string = "23930417-0123-4AAB-8B7F-8CD783FDD770";
    public static lang_DelegaeEnvelopeProgress: string = "66736C91-24D1-4B1A-BFEB-8DB3095505A8";
    public static title_sortEnvelopeDatetime: string = "BE3F22BF-D20D-4F5C-897B-8DC348556BC4";
    public static UploadImgError: string = "155A9150-3948-4721-B623-8E01621F3F93";
    public static anApplicationErrorContact: string = "91DF60FD-C9BC-4FEF-9331-8E372675F310";
    public static TimeoutRedirect: string = "9687C9A5-A28F-41CC-9370-8F1FA33FC359";
    public static Sessionexpiredialogheader: string = "FD82ADD2-72EA-4CD9-8484-8FCD0B32A7F8";
    public static EmailAdminLoinSub: string = "7D9E2F1C-B048-44DB-9C07-9014E2D54EC4";
    public static EmailSendingFail: string = "288C5B6C-146B-4661-858D-903CA09E859B";
    public static SentDocuments: string = "17286B4C-8888-4E8E-9939-91C0D7750E5F";
    public static Dropdown: string = "81C0A7D6-88B4-4BB2-85C8-91F88022BB71";
    public static AttachXML: string = "65347815-79BA-4E57-9AC5-921289EE098D";
    public static DocumentHistory: string = "06FAA940-4879-47C3-AFCE-927894DEE691";
    public static title_otherOwnerTemplate: string = "771F1CED-6B1D-4D45-9416-92FC4603C738";
    public static ds: string = "2E96F0A1-1BB4-4D27-A8DE-934BC0BEE1C6";
    public static CompanyProfile: string = "5680ED93-3666-4994-B8E2-947245B06A69";
    public static ViewedBy: string = "AEC45EA8-8E51-414C-98FF-951D43243526";
    public static DropdownOptionList: string = "814FEEF9-B6DC-4BB7-81ED-964E46EE93CB";
    public static RecipientAttachment: string = "A09C93B2-837F-494D-A953-9714B05DFFB6";
    public static SignerAttachmentOptions: string = "9E6663BD-92E9-4194-8822-4E61AF07FACA";
    public static AllowRecipienttoAttachFileswhileSigning: string = "B10F7B58-2044-4E49-B32A-C0FE7951E29A";
    public static NoTemplate: string = "25E5FF6E-2387-4D1E-BB34-97584799FEB5";
    public static Sessionpopupsigninmsg: string = "2450F2B5-C3AB-4F3F-BD69-986D677CFFCF";
    public static Filename: string = "53E5EC53-BDB9-412E-84AE-987AFB08BA48";
    public static Title: string = "A17FC9D6-CA82-4E80-9F6B-9974AA1709F7";
    public static PreviewDocument: string = "B50DE0F3-9048-4B8C-B780-9BA1DF610654";
    public static DocumentDetails: string = "BDC91145-B75D-4C12-9D2D-9C6FBCB5694A";
    public static DocumentRetrieval: string = "82933370-3865-4411-8719-9C73450BE77C";
    public static AddTempSignatureCertificate: string = "164CBAFE-659D-4201-BF1A-9CC7A00F2095";
    public static SignedDocuments: string = "95F2EBC1-008F-442A-BA40-9DC8FE2CBBDA";
    public static SupportedDoc: string = "711E4774-9989-4739-BF6D-9E43FA5902B7";
    public static NoTemplateorRules: string = "5B0E58D1-7966-4D31-AF13-9E4AA2648974";
    public static DownloadTransparency: string = "BC625635-7C50-4B37-81B5-9E9DCB9B6505";
    public static IncorrectURL: string = "CDCBF7EA-367C-4496-A27F-A009C505B864";
    public static Disable: string = "473E44BC-133C-4924-8844-A04A6F1D3161";
    public static CarbonCopyEvents: string = "2270CF18-A94B-4300-A00F-A0ABF9BF299D";
    public static RsignFeatureRecipientLabel: string = "4ED695FC-6D5B-4AA0-9A58-A0DF8A4F18B7";
    public static Rejection: string = "2435AC30-718E-44B0-AB84-A0FB5EF7835D";
    public static CompanyLastAdminActive: string = "A447AA98-BD0B-460D-A851-A1B7ED82AED5";
    public static RuleEditable: string = "8357F427-9FD2-48CD-9735-A1E4B9D89DB0";
    public static DocumentSubimittedSuccessfully: string = "0D302E16-0448-46B4-9FB5-A229AE9B726E";
    public static DropdownList: string = "D4BBFB45-6956-4993-9B99-A22B63ACE089";
    public static DeleteEmailBody: string = "BFB9B9BF-D468-49C4-BC41-A3D0447F0AE4";
    public static CompanyLogo: string = "A7C8CACF-995C-4920-ABF0-A435DE4B007D";
    public static lang_ErrorSomethingWrong: string = "41F007D6-5931-439E-BE5B-A4FA9A63F535";
    public static confirmPassword: string = "3F29D0D7-C911-4574-B37A-A50DFE75DC99";
    public static ExpiresOn: string = "918CCBED-C91C-49BC-A90A-A61F8DC72550";
    public static SpecificText: string = "879FCB96-AEEA-4D67-97D9-A6A961D56B7C";
    public static EmailResent: string = "9AFC556B-ADF1-4362-8D00-A6CD4F695B94";
    public static title_sortEnvelopeCode: string = "B80C9381-14E6-49C4-9671-A7BE05531D08";
    public static Unitsent: string = "C2B717C8-1D91-436E-B325-A7C59EF68884";
    public static newSubscription: string = "6FBDA172-1C83-448D-8614-A7D88C3DAE19";
    public static Event: string = "E1B30131-0D14-4374-9640-A805693899A8";
    public static title_sortEnvelopecurrentStatus: string = "032B231F-3A83-4061-B726-A85046A17329";
    public static AccessAuthentication: string = "4FC99518-EF53-44F3-8BB5-A893C574C7DD";
    public static Step: string = "1CB5E303-3853-4F7B-A7D1-A8F7C26DCB8D";
    public static SignedBy: string = "F2E6C452-6515-493D-8ACA-AA2A6E77994F";
    public static AddDomainName: string = "179855DD-AD43-4652-AB0C-AA7DB53C130B";
    public static Cancel: string = "66B1DC00-482B-4980-8710-AAFC3DADA3C3";
    public static Personal: string = "97736E5F-2913-41D2-8B10-AB2B8CCEC8ED";
    public static Update: string = "57A8EA2B-246A-4491-944B-ACC90CE67CE6";
    public static Planname: string = "D04991CF-D54B-404C-8878-AD2768F180DE";
    public static IndividualPlanType: string = "77814EBA-65D7-4BD1-B892-AD3A99BDD74F";
    public static Sessionpopupseconds: string = "3367D21B-93C0-48F7-B299-AD51327DD48C";
    public static title_undoselectedControl: string = "083BE54A-C836-4794-965D-AD6853DA5DBD";
    public static Soon: string = "B5B17ABD-C6FA-46E9-B203-AD92AD9F8627";
    public static DateFormatEU: string = "D0E30CFA-955E-430C-BB21-ADE421FC934B";
    public static SendReminderIn: string = "1C6B0B1B-205C-490F-A4CD-ADE9A1C65D5A";
    public static FooterTextParantedLink: string = "FE18BFAB-9DA2-4905-81A6-ADF74687B91B";
    public static Notinitialed: string = "D1B91C22-F004-494D-89D5-AE05B255734B";
    public static eSignServiceFeature: string = "C88C2043-E52F-40CD-9DCB-AE4188766703";
    public static DateFormat: string = "DB6ECFE0-ABE6-44E2-AA3B-AE8819F0C5C3";
    public static RsignFeatureLabel: string = "5AC9D2C0-B471-4F9C-AB57-AEAB6D9D43C3";
    public static emailBody: string = "9D45ADB4-DF15-4140-AA93-AED0F568B862";
    public static OutFor: string = "786EF75C-6DA6-47C8-910F-AF7F18CB2EA8";
    public static AnyText: string = "6475D52A-43A3-4071-8B2B-AFA43C212E6D";
    public static Option: string = "41036A50-AAA3-410C-8723-B0057F037609";
    public static AdminEmailWrong: string = "948B60EA-096F-4E35-BCEA-B088D4DD3817";
    public static Expiring: string = "B1FECDFE-AE54-41A5-ADE8-B0AC06EB5B06";
    public static Status: string = "0EBF7088-E4B7-4692-8DBE-B14A6100850C";
    public static TimezoneHelp: string = "5C7F6C25-C0BE-488B-BF09-B1E77BED56C0";
    public static WaitingForSignature: string = "E380FC43-4AB6-444B-96B3-B1EDAFAC36B7";
    public static ProfilePicture: string = "86B264B3-1045-42A8-BB41-B25F87EABD08";
    public static Return: string = "9D85A8DE-E9A7-4EDC-BAE1-B29D383CB641";
    public static Code: string = "7EA5E590-FC03-4D80-91B3-B312177DD486";
    public static FirstName: string = "157F519D-455A-4C71-A8BF-B3217AD074F4";
    public static DateTimeStamp: string = "A022376B-9495-4EAA-8CFE-B3293618BEBE";
    public static Drives: string = "1269B6E7-E2DA-4CFE-B458-B330CB279E21";
    public static lang_resendActivationLink: string = "D1766F62-E2EA-42A9-AD89-B3C14B9784CD";
    public static Continue: string = "38F7EC6A-02D4-4D13-8498-B3F089AB057E";
    public static FormFieldAlignment: string = "A2AB70D9-CBC9-4F21-A110-B46DEC8D4F77";
    public static RsignFeatureAdminMonitoringLabel: string = "D2C1E19C-AE7F-4A4E-87A6-B5216C55ADA5";
    public static CompanyAlready: string = "D3D9BC71-671C-45D2-BAEC-B5308A0794EA";
    public static Email: string = "982A95AB-6FE0-4764-A8E1-B5760121CCBC";
    public static Subject: string = "A521C3A5-5AEF-4E26-BFBD-B5D6B3E1054D";
    public static TemplateCreatedUpdateSuccess: string = "2794A634-47A4-4E52-9844-B607C2CB38D6";
    public static AccessCode: string = "D052E338-5447-4619-B07A-B61D6F31EA25";
    public static User: string = "C6661AE6-8E62-4165-92C4-B650EE2ACCB1";
    public static SignatureBlank: string = "95483640-1100-4E46-BEB2-B6FCE404357E";
    public static HandDrawn: string = "191DDB81-C976-4381-B40B-B76D42A506BE";
    public static AttachFiles: string = "9ED198C0-A1CD-4855-A6BB-B883EA3D4153";
    public static AlternateEmailAddress: string = "E794F8DB-7D20-43C4-B541-B990E40B81EC";
    public static PageNotSet: string = "DE66E7B7-C2F3-45B0-8513-B99BEBE53ECF";
    public static Recipients: string = "C5F1A5C7-11A2-424C-9186-B9D23A1CBFA6";
    public static SignerSignatures: string = "0F24BB34-8862-484C-ABEC-BA8E2AEDB0BF";
    public static CompanyNot: string = "470A616F-09E8-4F47-B65B-BAA7E04F0A9B";
    public static IncludeTransparencyDocument: string = "EA0FF30F-6545-4D0D-A66B-BB96A99BCA4B";
    public static VisibleNoRequired: string = "A1D2A72B-F130-4DA4-9E99-BB9D7A70AB0A";
    public static Hello: string = "A2B9797E-4120-4EBB-B602-BBA2D1F25F00";
    public static Unshare: string = "3B1F67ED-ADED-4A83-A4DF-BCD9D4D0ED90";
    public static Accepted: string = "D9AA6F04-E337-4332-9D81-C0A1DDF0F635";
    public static CancelTrans: string = "2BDC9E02-047E-4626-B6FD-C12CC5FC12E9";
    public static DefaultSettings: string = "0D0E1A54-CDB5-464F-A04D-C2A278839C08";
    public static Options: string = "61F98795-6DFC-4A84-8155-C34EC1112738";
    public static newPassword: string = "1B176C04-2ADD-4A4B-8C68-C3AC4722C8FD";
    public static IPAddress: string = "09122DE2-BF2F-46A3-A89B-C3B0F3093A55";
    public static SYSTEMSETTINGS: string = "117692A5-B579-4D1F-9AD9-C3F34101FCF5";
    public static Empty: string = "87274578-D3DB-4098-AF4D-C416FE8DCD03";
    public static NewUserRegistration: string = "D0BA0856-2FAE-4312-81F4-C4B58389F94B";
    public static DomainInvalid: string = "FA7F87EE-809A-44CB-8EC2-C4EA3617AB35";
    public static pleaseContact: string = "088813D1-491A-4B9C-A353-C53028D57C89";
    public static IncompleteAndExpired: string = "BD971750-67E3-4D58-8AF2-C5CE6CBB426B";
    public static CreateNewAccount: string = "D33216CB-BB76-4523-9C8A-C61D1EA7E937";
    public static AttachXMLDocument: string = "4AAE5631-B7A0-4659-95DF-C7FAAB6B680A";
    public static RSignAutomatedWorkflowLabel: string = "065C7475-82AD-423E-8E8C-C8A1F9683F72";
    public static Unchecked: string = "EA688CD4-86A5-4E82-A6EE-C9C88A15FBEE";
    public static Radio: string = "582A741D-F1E4-4E2C-A81A-CA3D1407F0CF";
    public static SignIn: string = "87262D2C-DFD5-4471-B07E-CA98AF4B1BCE";
    public static lang_conditions: string = "56083321-92BA-44E0-96AB-CAD3B5792B1C";
    public static UserIsNotValid: string = "F149D06A-059A-4E06-9E0E-CADBD270FC09";
    public static DependencyKey: string = "E11CE2B1-C308-46CC-A977-CB485C603F9E";
    public static DocumentSubmitConfirmation: string = "D2DBF537-3143-464D-BC9B-CB7291B20CAA";
    public static Rules: string = "6F52E523-3ED4-4DA6-BD06-CD081E474B7A";
    public static Attachment: string = "D1D63F89-FAAD-43C2-9764-CE115FC7441D";
    public static Footer: string = "9609B8D6-4743-4C9C-927E-CEC2A531FAB2";
    public static NoSubject: string = "2DA77939-C4CA-4A8C-AB1F-CF2BFBF031FC";
    public static Allowedunits: string = "6359FF55-24F8-490D-BA8A-D06522A6FD80";
    public static Page: string = "1F1728BC-8711-4ECD-B62E-D08C4D4B5A94";
    public static ErrorOccurred: string = "9525C4B8-2324-43C0-8415-D17EF42F23D8";
    public static staticLinkDisabled: string = "919C7AE5-4D31-48EC-B43E-D2AB4F87E583";
    public static Text: string = "CB5F06CB-FFD6-4E4B-AD47-D2DE3944DFDB";
    public static Welcome: string = "F7BB5F89-0DF4-4AC9-8754-D2FAC65C140B";
    public static RsignFeatureCreateTemplateLabel: string = "B30DD448-89C7-4660-B69A-D33A8FE6A091";
    public static Oldpassword: string = "A16B1AEE-39EC-427E-9B82-D3D9742F81E9";
    public static Admin: string = "65ED1BC3-818C-480F-838D-D3F7363295F2";
    public static AccessCodeToSign: string = "BCD4D6D2-430C-48E6-B718-D542BA475A9C";
    public static Discard: string = "2245B299-51D3-4877-9699-D58B994A24DF";
    public static AcceptTS: string = "0D3E0743-51DC-4C14-A9CC-D68B9EEA8FB2";
    public static PersonalTemplatesRules: string = "27587AE1-6894-4AA2-80FE-D6BD8888EFA2";
    public static AlreadyConfirmed: string = "BDD3E3C1-4E27-4B46-A1F7-D819A9203EB9";
    public static IsSequential: string = "0AFF8DB3-873D-47C8-A41D-D89249CE09E7";
    public static CoordinatedUniversalTime: string = "989B5B6D-D6C2-41D1-9DDB-D93E1D9A17F8";
    public static EnvelopeAccepted: string = "9C978EE2-D034-4D94-9577-D99A0640073F";
    public static Days: string = "096DD340-6003-4770-90CB-DAC0A0001541";
    public static Weeks: string = "e3f79a7b-469d-456a-9a1e-76f2ea11af35";
    public static CurrentStatus: string = "BD13AAD1-5DC9-4751-9A2A-DC042951456D";
    public static Select: string = "D0565066-0ABD-40DA-A9F8-DC769E6D4F48";
    public static Copy: string = "380EF85A-E330-4516-8675-DC7DFE285257";
    public static CompanyValidation: string = "F6F51558-DD59-401D-ADA4-DDBFE5325EF1";
    public static SignerAttachmentContent: string = "4DACC35E-CF9C-4D28-B91F-DDCE0E78831A";
    public static CreatedDate: string = "96858DAF-3F15-4E1F-AAF5-DE1287218C4F";
    public static CreateRoles: string = "7741B070-1F28-4AF2-87E0-DE8AAA628F6A";
    public static Order: string = "FC9AA510-7ABC-449D-B260-DE9F1A81B634";
    public static AccessAuthEmailSendFlag: string = "15323032-AF08-49C7-932D-DF700ADB7C56";
    public static title_loadEnvelopeHistory: string = "5EAAA101-EEB9-4DF2-8BCA-DF920E0DDEF4";
    public static Reset: string = "39214709-9E4D-46DE-B68D-DFBAE0A6940C";
    public static EnvelopeCode: string = "862FAD9D-9101-4CDC-A4C7-E0A55C6613AB";
    public static sessionPopUplogoutwarn: string = "E590BA40-0E59-43D9-8917-E126576F0767";
    public static CreateTemplate: string = "A47B2CE1-BEE1-4BD2-9F3C-E19C686CA617";
    public static OldEmail: string = "869FAF5C-FC11-4632-BE95-E37A7AA3A937";
    public static AddRole: string = "3591F8F3-99E5-45F5-BD4D-E47B0FD07852";
    public static Unsigned: string = "FB5DA801-4C4A-48C9-ABC4-E526C3ADA39B";
    public static StoreEmailBody: string = "2E3D2D8A-89E7-4C11-A244-E553E66F26E0";
    public static OverrideUserSettings: string = "BFE5E953-D6AF-41E7-B8EB-E67F0C847485";
    public static lang_DeleteFileProgress: string = "455933D4-CB43-4FD8-9EE1-E6FBF8061A27";
    public static ReTypePwd: string = "18ECAAFD-E4C7-4DCE-9386-E81548D76DA7";
    public static Settings: string = "9A76FB60-3DE2-4C4E-9326-E81EB4CE9292";
    public static lang_DocumentDeletedMsg: string = "24DC9E44-CDCC-4CAF-B504-E83A3919C609";
    public static Checked: string = "ED57F769-EA90-473F-9DFE-E87773385026";
    public static Delete: string = "F42DF6C3-B200-4264-A9EF-E8F343161071";
    public static title_Close: string = "548A19E7-DE15-40DD-B461-E96F4FDA5A49";
    public static Decline: string = "784FC921-B967-4D4B-974F-EB19E69422A3";
    public static Refresh: string = "90349BED-5015-4CEF-BECE-EB34B44FA29F";
    public static title_removeEmailBody: string = "BB02FE46-0F26-4679-BF85-EB50129DCB71";
    public static EnvelopeRejectedWithMail: string = "A9B4453E-FD57-4826-AA4D-EB9CE3555C39";
    public static CreateTemplateorRule: string = "C3C02FE6-8605-4FC9-A053-EBB08C215D5A";
    public static Send: string = "1CE1860B-143A-4958-99A4-EC0967B21FFE";
    public static Delegated: string = "23E7AF66-C0FA-4708-841A-EC2796766F96";
    public static TemplateRuleList: string = "FECC825B-91EC-4A8B-B8BB-ECA44BE44A63";
    public static ExpiresToday: string = "D67BBB5A-3EE7-43BE-A868-ECA81BA5B140";
    public static Description: string = "8E9D6844-5904-4C08-83CE-ECC621B36DAF";
    public static Character: string = "913128DE-1D41-4E8E-898C-ED8F51E51FD6";
    public static NotValidUrl: string = "2804A8CC-82D1-4648-B2BD-EDEED06DB630";
    public static RememberMe: string = "9025BF59-D718-4FF2-8E48-EED1842EBDF3";
    public static DomainName: string = "87A93899-D79A-441A-9946-EEF365E28B2F";
    public static SendDocumentEncrypted: string = "B8056141-D4DE-4CB3-8849-EF22AD6199EA";
    public static Storagedrivesavailable: string = "D2F93CC2-5D17-4636-A110-EF44B4E8345B";
    public static Viewed: string = "7564AA3C-97B7-4B26-9FC6-EFCDF17DD41C";
    public static SaveAs: string = "D4254E74-AA24-47CC-B363-EFDB4F9DE2CE";
    public static ShareTemplateRule: string = "8D142C1B-8171-41F6-9312-F0CE8D26F106";
    public static Roles: string = "C46815DA-40EA-49A6-83DD-F1787793BE58";
    public static Settinghelpicon: string = "0FEFC2EA-A363-422B-8FDD-F228E384A44C";
    public static GroupName: string = "2C91AA05-3BDB-4F22-9698-F26263CE318B";
    public static Next: string = "1DDC6AB4-C61A-4617-8D88-F27A5BFEABC1";
    public static SucccesfullySubmitted: string = "56587859-D747-4B5A-89BA-F27C09027B16";
    public static SharedTemplateList: string = "809EE023-7906-4AD9-9232-F2A13C24190E";
    public static RequiredConfirmation: string = "2E57F2F2-5B10-4C6D-82AC-F2B2DA70D0E4";
    public static ChangeSignerName: string = "659FFD2E-F36C-4A3B-8FEA-F2C152DFCAB0";
    public static TransparencyContentN: string = "E3C35303-4894-4871-B18F-F36071C46A35";
    public static ADMINSETTINGS: string = "91886562-AF78-481C-9B80-F3FCE23B53AC";
    public static ADVANCEDSETTINGS: string = "C374F9B7-D23D-4E69-985D-C561B345EADA";
    public static DocumentSizeMsg: string = "207F5702-5817-4380-9358-F470F77AB4F0";
    public static reviewpageAnyQuestionText: string = "80793863-AA6E-40F6-95F6-F47BD7719036";
    public static TextFormatting: string = "96A2B245-E49C-44A9-9B2C-F4BA2D9BA080";
    public static RecipientRole: string = "18CE629B-E294-4237-8533-F585F0CE088F";
    public static Edit: string = "6927A498-E2FE-498D-A904-F5BD36DE64C7";
    public static PasswordWindow: string = "CE950E8D-4BB0-48B1-93E7-F5C68D073F34";
    public static DeclineMsg: string = "BFCBE8F1-D5D2-42ED-80EF-F6815784EECB";
    public static Manage: string = "619550AA-5AB1-4EE2-95DF-F752EEF08460";
    public static SignatureCapture: string = "E67C9564-988D-4A6E-A4A4-F75C7A06D448";
    public static ManageAdmin: string = "5EE6B225-D6AC-443A-9471-F7ADF62D7B37";
    public static lang_addRole: string = "5849E15F-88FF-4F33-BE52-F7D3EF977FAF";
    public static emailConfirmation: string = "5878154D-6A20-4E41-AC52-F94801406D7D";
    public static CopyLink: string = "4A835791-060B-4CA5-9581-F9CEB759A433";
    public static SessionStayIn: string = "BFEFEC19-5256-4A37-A408-FA547AF5B89B";
    public static FooterTextRpostTechnologyLink: string = "1091A590-02C1-4419-BA35-FCA75367C64B";
    public static title_accessAuthPwd: string = "E390A331-46A5-4013-A7ED-FCAFBB49A6CC";
    public static SignerVerificationPlaceHolder: string = "DB1298EE-5FCC-4C5A-AF15-2D671015D45B";
    public static Stats: string = "D86AD7F7-68D1-46E4-9BCD-FCB1157C0D45";
    public static Role: string = "5243E6A3-1860-4C27-B718-FCF44F4C5933";
    public static EditRecipients: string = "022A044A-6D1C-4409-A477-FD5578CC4C05";
    public static ChangeSigner: string = "6598064B-33FD-450D-A982-FDACA94B1F57";
    public static Length: string = "DBA33721-35C8-405E-99B5-FDFAA0914E3A";
    public static lang_confirmation: string = "A6EBEF12-7AEF-4082-BC87-FE29042430D7";
    public static Content: string = "3CB8114B-F89E-4823-84A2-FE30E3F9CA77";
    public static Clear: string = "81938C0A-696C-493A-9B31-FE8C81EFBD18";
    public static DeleteFinalContractDisplayMsg: string = "F7E5511F-DED4-41BA-A668-FE947218FD8A";
    public static NoCompany: string = "88ADD6E5-816B-43A3-9795-FEF20CB22737";
    public static Search: string = "E5BD2380-F8E2-4EE3-B171-FF02E3F60FC3";
    public static SignerAttachmentContentN: string = "E45075FF-B234-48C9-B753-FFF5428541DC";
    public static lang_pleaseWait: string = "15ABAE70-E2CB-4EDF-99DD-8B5B3228C8AE";
    public static WaitingMsgTopForStatic: string = "3606EF5A-DAA6-423E-828D-B586AEFA3AA5";
    public static WaitingMsgBottomForStatic: string = "D6340425-388D-4C58-9550-EA50963DEFA2";
    public static lang_attention: string = "683C2554-3FF9-4E5A-BBC0-BC5259ECAE4C";
    public static waitingMsgAddressStatic: string = "79D71697-27E3-46A9-862E-CE9280F28AC2";
    public static waitingMsgSignStatic: string = "F643AFF9-00EF-4975-9D45-466AB068286A";
    public static waitingMsgTryAgainStatic: string = "3728ABE7-3343-4049-8887-7867B198E31C";
    public static waitingMsgProceedStatic: string = "074490B9-972A-41C6-974F-28D9AAB5583D";
    public static Approx: string = "56EFEADD-1124-4F24-834A-FD51AE6C39B7";
    public static lang_Group: string = "200B9721-E350-46C7-B31B-23D5E8D94DBF";
    public static lang_yourNameImg: string = "FFD64B83-8E49-464F-90EB-33A2D0FBF31F";
    public static lang_SelectTemplate: string = "230AB9F8-52EE-4F8B-924F-25839D9D4A4C";
    public static lang_SelectRule: string = "7CD34AE9-E97C-48CE-A131-8DED64FE2ED8";
    public static FirstLineBlank: string = "BFEBE496-868F-4823-B03C-92727C1007D0";
    public static UIFileConvertorOptions: string = "2EBC257A-95F2-476A-BF67-33FEB4E6A6D7";
    public static SignatureControlDefaultRequired: string = "DC0D8411-06EB-4F0D-8D1D-F98F2CB6C78A";
    public static EsignedEmailCopyAddress: string = "79761B5A-BA3D-4FC4-B997-12A554EB53D2";
    public static EsignedEmailRerouteAddress: string = "8AEEA3A2-3183-4E26-8ED3-0F3D45750148";
    public static Day: string = "B3EA7CD5-2FE0-458E-83E0-4692BE68E5A6";
    public static RadioName: string = "8A8A0B45-6E8D-4B4A-B7CC-A46D0A179823";
    public static RsignSupport: string = "6C374C58-5EAD-4BA4-AD53-5FD5E0CC6C80";
    public static SendingConfirmationEmail: string = "13AF4ECE-C831-4E7B-A89D-518D58F0CA8F";
    public static HeaderFooterSettings: string = "3A271DB4-C2F1-43A8-81E7-A7EF5BC220B7";
    public static lang_UpdateResend: string = "42CC063D-71B7-4543-82D9-43594431ECE1";
    public static EnvelopeUpdating: string = "B352717B-2B1A-4017-A69E-F8A23ECF9B6C";
    public static EnvelopeTerminatedForRecipient: string = "6F1AE2D4-8120-4A04-982D-AC3278B135F8";
    public static EnvelopeOutOfdateResend: string = "976A77CC-5EAE-45D5-B3F1-49FD323B18E6";
    public static UnauthorisedSigner: string = "7A35CD13-7D31-4007-A846-1C516F7111F7";
    public static EnvelopeDiscarded: string = "2A24CF5D-ECF5-417D-8627-42C5E15E121C";
    public static EnvelopeResendLatest: string = "E1664050-4EDA-4942-A100-CE3334CDC8FE";
    public static EnvelopeSignProcessMsg: string = "A9F3F208-6481-46C4-B6F1-06A26750D9E5";
    public static FinalContractConversion: string = "B35AC153-4DDE-4E98-91A5-5492E1762AAC";
    public static AttachSignedPdf: string = "11F8B179-0D81-4E2E-B697-580F7AE3199A";
    public static lang_Y: string = "D3C56440-6A50-4259-B24E-24BD9A0693BE";
    public static lang_N: string = "A5F19D7F-FB5C-4451-9DB2-F499FE9AC583";
    public static EmailCannotResent: string = "A387AEA4-B7FF-43B4-9045-D5CFB2E0F8A6";
    public static SignerConfirmed: string = "4743E1D7-E9FB-472C-B455-0845E946CDAE";
    public static ResendConfirmEmail: string = "6E3DF55D-DD10-4794-B5F9-9C45EDFAC7DE";
    public static ReviewDetail: string = "39421f57-bc74-4f71-85c2-fe54dd2dbc4a";
    public static title_downloadFinalDocument: string = "E247D100-A697-44E4-AA95-104C988FCD04";
    public static lang_CancelUpdate: string = "52F4B2C9-0845-4A2F-A0C5-E743638CB873";
    public static lang_UpdateInProgress: string = "04d946da-58a8-4e69-8b60-57b902280e88";
    public static selectTimeZone: string = "F1098702-4BC8-40CE-ACCA-103246E668D5";
    public static lang_Numeric: string = "29F04A74-E857-403F-9994-2C70D42F144C";
    public static lang_Text: string = "5A870858-6BB7-4EF9-8889-1F51A637D04E";
    public static IncludeEnvelopeXmlData: string = "EB97FE21-81D1-4CA8-8105-8C62BD7962BB";
    public static AllowTemplateEditing: string = "df9dd0e3-5bcb-48ff-8686-a5551c9b9f49";
    public static EnablePostSigningLoginPopup: string = "DAB3A97B-DAE6-4778-8E4E-EE0EF055E7E1";
    public static SetAsDefault: string = "5F1D3C69-7092-4C44-898A-9E77A413524D";
    public static DeleteSignature: string = "FEF3FBD0-E03F-4AC1-B400-6517FEEEE23F";
    public static SaveSignature: string = "AC91FEC8-14E4-418E-904C-886BCB551888";
    public static EnterSignatureTextDialogLabel: string = "A130F81D-8DBC-465E-9912-71EB4ED4F96E";
    public static SignatureNameLabel: string = "F850745D-45D9-42C2-B629-F48F29F80396";
    public static lang_UserSignatureSuccess: string = "B3D145DC-2ADD-4551-9618-74AC63CD59B7";
    public static lang_SignatureUniqueNameValidation: string = "EF46AFF8-4FFE-4139-960B-B030E70E80C6";
    public static lang_SignatureNumberofCharactersValidation: string = "41B83DA5-A96B-4968-B490-2D5714392437";
    public static lang_SignatureNameSpaceValidation: string = "C14245AF-AD8C-4F86-ADE8-5CF48B594E1F";
    public static lang_UnsavedSignatureChange: string = "4E06D936-6A9F-4168-95B1-8CFDDACE7624";
    public static NewSignature: string = "50236229-3305-4244-8A62-EE72C6A6F3D1";
    public static ValidSignatureName: string = "6A980BC9-4F49-4275-8ABC-6C15D13B36B2";
    public static PleaseSelectSignName: string = "855B2FAC-96CF-4A6A-8552-FF5E1BD83649";
    public static lang_SignatureTextSetDefault: string = "DC10D115-5C0F-469B-B1F2-139E5373D958";
    public static lang_SignatureTextDelete: string = "2B05B26E-825B-42C3-B73E-2F0D4851B94D";
    public static SignatureTextRequired: string = "D5E2FE24-93CB-480E-B1E4-4EEB4AEABCB4";
    public static CreateAccount: string = "c5586342-7cc3-42cf-96d9-0aa744580566";
    public static SignupForFree: string = "b8af9d7b-7d57-47ca-b751-63e9c3aa9a44";
    public static EmailResentTo: string = "de3d178a-e3a3-4607-b3fc-12aa42187b37";
    public static SendDocuments: string = "665b8d7e-5a99-4165-aacc-e2b566d5c46e";
    public static ManageDocuments: string = "f9aeaa1d-4d56-435e-b9ee-66aeebb7b7d4";
    public static CreateTemplates: string = "625d5467-9614-457f-a888-f202c88dd17f";
    public static CreateRules: string = "cf5dc542-b906-4acf-bbd4-99b550132331";
    public static AutomateNotifications: string = "19574b35-1829-49a8-afc8-3fcbb0a2c481";
    public static TermsAndConditions: string = "de3f7d3c-4887-4806-ac77-2c5e0d456aeb";
    public static PrivacyPolicy: string = "4469bc07-5c24-4796-876a-f720d09ab182";
    public static LoginInto: string = "D90E1DCF-8E8A-4DAA-84D9-1CBE9C21CE8E";
    public static lang_ConfirmDeleteSign: string = "E67B2090-2E27-456C-9494-3AF5609BD12C";
    public static Period: string = "3011b997-417a-4b26-bc4f-0a02cdbd76d2";
    public static OrderMissing: string = "86727ccf-011a-43be-b411-0e0e7a429c88";
    public static FileSupported: string = "18bf6a56-6c0f-48fc-b16f-59d02c988a65";
    public static lang_authSuccess: string = "F33AD0C7-F55E-4E4E-8906-AE9D109723D4";
    public static EmailandSettings: string = "ca7cb620-5618-4645-9d28-a80d66f28948";
    public static eSignExpiredFeatureList: string = "25b27318-817c-4aa8-9972-837de7d41e92";
    public static eSignExpiredServiceFeature: string = "435424f7-2ef0-4a54-a9b4-eb479f1f4a43";
    public static LearnMoreAboutRsign: string = "40083db3-a4b8-490a-8efc-727bb59dd3e4";
    public static FreeTrial: string = "d0f7d3f7-42aa-4a47-ba58-fcde4364fb4e";
    public static RestoreDefaults: string = "5085a8d2-b2a9-4958-bde1-43d2f353c33d";
    public static title_SaveDocumentinDraft: string = "2197DBD8-2769-4F7B-AC17-7C574E0EB11A";
    public static title_ClearDocumentofControls: string = "2D498D13-A7A8-4522-977D-9DF9306AFD0C";
    public static title_DeleteDocument: string = "C55D26C1-BD07-4417-A234-7BD41C50E5B3";
    public static title_ViewDocumentAsPDF: string = "3A93DF17-52EE-4C47-ACFF-4E2AADB0A5C2";
    public static lang_addControllingField: string = "22AE37D8-F388-4101-ADB0-7948FE8015E8";
    public static Back: string = "958EE23F-6E23-4AEB-8B8D-091934AEE2D0";
    public static lang_BackClickSaveConfirmation: string = "E21BF7F2-0773-4F2F-9E80-C33A4C8E8F99";
    public static lang_Information: string = "79A6075C-5313-404C-93A5-6099751E823C";
    public static lang_BackConfirmationP1: string = "44B55505-EA2C-4D63-BA92-B44D73621AE5";
    public static lang_BackConfirmationP2: string = "81870E93-D039-4B10-984F-9CCBD0EFFBC5";
    public static lang_BackConfirmationP3: string = "521D25B0-083A-4C3D-BF3B-6A56552FC478";
    public static lang_BackConfirmationP4: string = "EC597424-5893-441B-9418-D71CCD256891";
    public static lang_EnvelopealreadyDrafted: string = "F124C92D-31D0-4D96-9AEE-55CCD72D07AA";
    public static CreateMessageTemplate: string = "6e70d70f-62eb-4b9e-a79f-ac116854835c";
    public static CreateMessageTemplateSettings: string = "a3bb1519-a122-41fc-81d4-d980119e9611";
    public static PersonalMessageTemplates: string = "7dd50a01-1520-424f-a168-1b89c905b18a";
    public static SharedMessageTemplates: string = "d2e7d631-07bc-40c7-9d43-d1ed7c03d200";
    public static View: string = "264976A0-132E-4507-AFE2-875D1648E3A0";
    public static Setting: string = "67361109-6F8D-4D1E-925B-B8C5161131BF";
    public static SequentialSigning: string = "954CE0D6-ABB9-4DC9-A61F-5F05604045DE";
    public static PrintSettings: string = "4B81F695-2EC1-4BFF-BBAC-AE5D736C46E2";
    public static AllTemplates: string = "47abcadc-8541-4662-8067-8b46742eb688";
    public static IAgreeTo: string = "cb2310fb-87f6-4a5e-abb0-a6425fe30699";
    public static AutoPopulateSignature: string = "DD570012-AB78-4269-8045-DDE21A7778AE";
    public static Disclaimer: string = "1F451BC4-8095-4979-AFFF-70CD92C8EB6F";
    public static SendIndividualNotifications: string = "c222c3d9-6c2c-4b9c-8e9b-4809c103c40d";
    public static AddDatetoSignedDocumentName: string = "CB015116-7BF7-4C81-9194-05205241FF13";
    public static UploadDocumentDragDropFiles: string = "2A03EF70-C758-4C3A-A28E-0596A0DF6710";
    public static DragDropFiles: string = "38FFDB00-9C9C-4A4B-B7A9-70A8747A5A37";
    public static Display: string = "E75C13B9-C06F-4971-B653-7B317F327674";
    public static lang_Int: string = "9506A039-DF8D-4650-8552-8B650C9889B1";
    public static NewInitials: string = "166F5919-7B0E-4A7E-916B-0D0811EB2D63";
    public static IsDefaultSignatureRequiredForStaticTemplate: string = "6021D655-0062-4198-A24F-DFF809C7DE51";
    public static lang_PleaseCheckEmailAndSpam: string = "48EE783E-DFDB-440E-991C-1762D5BC44A4";
    public static lang_PFP_StillCantLoginContactAdmin: string = "5A5AC3CA-77BC-497B-B74E-7FFBA0677567";
    public static lang_PFP_ReadytoStartUsingRsign: string = "6A943D8E-1FB6-4272-B0C2-AFDB8E222958";
    public static lang_PFP_ViewVideoTraining: string = "9BEF9E87-7CCD-4A91-825C-8D6C289B9249";
    public static lang_LogIn: string = "1494F113-AA0A-4697-9510-158079AF6D18";
    public static lang_NewToRSign: string = "DC5821BA-D39E-4912-B40A-BA63DE32CDD2";
    public static lang_EnterEmailSearchStr: string = "689A469F-75A1-48E5-9D56-E68C49F34C67";
    public static lang_SelectSignature: string = "26FBE34A-6A4B-442D-B353-0BBF5234444F";
    public static lang_DownloadSignatureTooptip: string = "D65763C4-61ED-4257-B11F-A8BBB3F21A9E";
    public static lang_MessageTemplate: string = "E269F298-3DE5-406F-BC12-CB11D10C8A75";
    public static lang_UploadImage: string = "24DABD5C-C5F6-4BBD-BBE0-6D72D701CBCD";
    public static lang_LOGINUpperCase: string = "7F158550-3BCE-4930-A8A9-5B89C3E52818";
    public static Lang_SignUpUpperCase: string = "C4E2D154-FDC3-4D6D-9ED0-9DB46E9F3F29";
    public static lang_NOTHANKSUpperCase: string = "64C6DFE9-B452-433F-85E6-6F5DFAB9A62C";
    public static lang_PostSignInfo: string = "386BE4A1-AF8C-4A88-A159-87B55FB72BCB";
    public static lang_SignatureCertificate: string = "A4898B06-D587-4A12-984F-F1BAD1B39119";
    public static lang_loaderLodingSettings: string = "982B5394-7AC1-4F01-BD1B-BE87860324A8";
    public static OUTOFOFFICESETTINGS: string = "4B9F2716-5EDE-4857-8289-3277A5234C1B";
    public static EnableOutOfOfficeMode: string = "FD68EC1A-5B3B-48A6-82CF-126528D60363";
    public static FirstDay: string = "1D8864DA-0515-4573-ADCA-982C70683AE7";
    public static LastDay: string = "1725A930-B618-4A31-8F6A-43610F66B776";
    public static CopyAllRSignEmailsToThisaddress: string = "2841C7ED-D1BE-4A8E-8671-7131446D837B";
    public static RerouteAllRSignEmailsToThisaddress: string = "E3EDD14C-1A42-43FA-800C-4BCE1A9B5D26";
    public static DateRange: string = "A385CEEF-62D6-4C19-818C-957E5B5BF6E4";
    public static EnableTheDependenciesFeature: string = "9CDDD110-12F4-45A7-9535-627C605B5933";
    public static ModifySelectedGroupSettingsTitle: string = "4B2FF617-685F-4069-919A-FEC0F243C124";
    public static Inbox: string = "BE86EA1C-698F-4DBB-91DD-2E0D4423CF4E";
    public static SelectTemplate: string = "8C003C86-320C-4DF7-8CE8-253CFC397BEF";
    public static SendFinalReminderBeforeExpiration: string = "BF9B7DCC-950E-4A00-8877-38D06770E2E1";
    public static SendFirstReminderIn: string = "120F50FA-FDAB-4D8D-99E3-540E462B3BBF";
    public static ThenSendFollowUpRemindersEvery: string = "363D9101-8682-41FC-8F6B-2DF61BD78984";
    public static SendInvitationEmailToSigner: string = "D9C0BCDA-B359-4A67-97D7-128BAB99A523";
    public static DocumentsCombinedInOneEnvelope: string = "F154A9DD-2DE9-4ED4-821F-FFED4EC90D62";
    public static SentForSignature: string = "EFBE0156-FE26-4883-ACF7-75C7E82D7653";
    public static TypeInitials: string = "D1BFA64C-A0A4-40B3-895B-BA859C971EB1";
    public static Hyperlink: string = "93D87429-C34A-4810-B3F1-570F6511BB26";
    public static ReferenceCode: string = "C9CC6C6E-6F8E-4A88-8549-C95BD6EFCA2F";
    public static ReferenceEmail: string = "C350C9F2-A49D-4F27-93CE-DD164B55BD04";
    public static CopyTemplateConfirm: string = "5C04C08B-AD43-4985-9013-D59606290BCE";
    public static CopyRuleConfirm: string = "CB69C681-64B3-41EC-B49C-6E65A4921B0F";
    public static Drive: string = "CA3D34FD-F0C3-41DF-971C-13E602786EC6";
    public static Deleted: string = "9EACA382-0670-4B6E-AD5E-93BF4E0ED929";
    public static Folders: string = "BA96A64F-B8C7-43BF-9CA9-73BD4F1EC4E6";
    public static ActionRequired: string = "30B210B7-8C22-4D94-88BF-9266DE8F7347";
    public static WaitingforOthers: string = "147760AD-7EDC-42A9-BE65-4532A49C7FF8";
    public static Waitingforyou: string = "C195D0EA-DDF6-4F40-BCFF-A057588EC686";
    public static SendDocument: string = "E61F4FF1-926A-4D81-99EE-9B69853A864E";
    public static ActivitySummary: string = "B1BD1A05-C015-43DA-A7DD-3DA343D0C6CD";
    public static Past30days: string = "AC5D4B86-61F6-457E-A4AF-603C075884DB";
    public static Today: string = "42C1674D-E06B-4701-AC15-CD5FE2796E9B";
    public static Past24hours: string = "D9662136-C27F-4521-8B61-D40C68B43BC2";
    public static Past48hours: string = "BA6234EA-15F9-41B4-91DA-A597A2057294";
    public static Past7days: string = "EAE861DB-6882-4824-B407-4BFBCA307E38";
    public static Past14days: string = "36BBD481-EA7B-44E1-B7FF-A1F072CBDB19";
    public static Past90days: string = "4905825E-5DF3-4EE7-9793-CA3973AA2F00";
    public static SelectDays: string = "B0EBB263-B423-45FD-BEB0-F6A6A900B6D4";
    public static PercentTotalSent: string = "1365A2F8-7665-430A-8FF1-47D0383C73BE";
    public static Times: string = "31FECC0B-FF60-40DB-A724-EF68F9F2282E";
    public static Best7Minutes: string = "551A0915-3A18-4296-8538-CE53CB77A3DA";
    public static AverageHours: string = "9F72B75A-1F21-4D96-A94F-88768331ADE8";
    public static Pending: string = "38B4B6B8-EB89-4D77-9494-73BBEECC2103";
    public static ManageFolder: string = "EC73441C-EEDE-40E0-AD1D-FC35A0755FF3";
    public static CreateFolder: string = "8F887B15-F8F6-40C6-BB6F-3303723C322F";
    public static NewFolder: string = "9D2CD908-A40B-4862-BA7B-D49EA4E94B40";
    public static MoveAll: string = "345C89C3-1B11-4C56-86E3-71016015E65B";
    public static AuditTrail: string = "DBC21A45-FE01-41A7-800F-0E7BC7ED1149";
    public static Action: string = "1B4C429D-F3FD-4F58-95BA-00B18D720583";
    public static DragDropFileText: string = "67CD4916-3562-4EEC-B698-BC0D542670C0";
    public static MoveSelectedEnvelope: string = "4A4F3DE3-83AB-4C93-B479-5CE912A511E1";
    public static ConfirmEnvelopeDelete: string = "CE92BEB6-0D2A-4869-BA84-EF3209A2011B";
    public static EnvelopeMoveSuccess: string = "002F3E87-22F2-4985-919C-9EAEC3492868";
    public static ConfirmRestoreEnvelopes: string = "74930BED-819C-4652-B13C-14C8D7B84A0B";
    public static ConfirmRestoreEnvelopesFromFolder: string = "9A2D4FD9-F877-44DB-A554-79F40EB686F4";
    public static FolderNameExist: string = "D204A056-8685-4A2B-A4AA-8549216A3116";
    public static FolderDeleteSuccess: string = "2B305E57-6B4E-48E9-AEEE-9DB348CEEAEA";
    public static FolderCannotBeDeleted: string = "32FD915D-00B0-407D-A545-9F09FD967548";
    public static ErrorDeletingFolder: string = "420A66D3-7091-4C2D-80CC-407F25F2D94C";
    public static CreateFolderValidation: string = "79EC7CEF-036D-45BF-9449-E977D27EF3F5";
    public static AddLevel: string = "DCFABEF0-DD61-4758-A997-E461B12328FA";
    public static EnterNameAndDescription: string = "5BB2BA85-0873-4878-BB9A-6767A76A83C1";
    public static EnvelopeDeclineValidation: string = "DD882899-F945-42AE-BD28-89239BFFC183";
    public static SelectTempValidation: string = "15195A7C-7BB4-4E13-91BD-234717E5FAF1";
    public static DelegateEnvelopeError: string = "205F728D-B5F2-4B68-A7A8-B2E4316E3601";
    public static SelectOptionValidation: string = "7B7767A6-117B-41B7-BDE4-2E6D5C291667";
    public static CommentsValidation: string = "7DDD3262-D913-46A0-936E-7EC509307E80";
    public static EnvelopeRestoreSuccess: string = "E1707E32-2185-4A4A-93AF-0AB48A3CDA69";
    public static lang_RestoreValidation: string = "BB292A01-5EF2-43F2-A081-A5CAD6EB9879";
    public static SignMultipleTempLabel: string = "E446F305-B910-4163-BA56-50158958E8A7";
    public static SignMultipleTempLabel2: string = "0FE5BDE6-F0BC-45E0-B987-C1B72AAF30C4";
    public static lang_RestoreEnvelopeFromFolder: string = "F2A0BA80-B91A-4A66-BDCC-5880312F9A32";
    public static lang_selectEnvelopeValid: string = "8DE2C367-E551-4E08-BBEE-77C4CC5A54EB";
    public static SenttoOthers: string = "16FB1E05-7525-4F90-B44C-A42FFFFBD64B";
    public static SelectAction: string = "632F68A2-5F8A-4744-A1FB-1DE41B40587C";
    public static Finish: string = "057DC8AB-1B98-4E88-8FDB-6473CCA54346";
    public static Prescriber: string = "54CCAAEF-A269-4963-8553-16565458FF1B";
    public static UpdatedStatusDate: string = "A936EB37-A890-4141-9EEC-D086B6205B3D";
    public static AdditionalInfo: string = "B8079C9D-D1E9-4506-A12B-48AE6F7093B2";
    public static AddRow: string = "E113EF52-D985-4B8C-8D91-FA65E7D8F053";
    public static AttachmentRequest: string = "555D976C-FF5C-417C-9D4C-37855905DF7F";
    public static Upload: string = "36AD1580-7F22-4BD8-B84B-4951BE47530C";
    public static AddNew: string = "C5197180-05EB-46BD-A64F-A3A03E575E3F";
    public static lang_ExportingtoCSV: string = "2D5064D0-9A7A-4486-B61D-A45CF9668565";
    public static Any: string = "568D8350-89EC-489A-BD8B-91A8A8978C9E";
    public static Expiringwithin: string = "F53EE39E-5BB2-4C44-8989-3F5B7090546D";
    public static MoveEnvelopesbyReferencecode: string = "9D006199-CAD9-4D92-9497-546F9A64802D";
    public static MoveEnvelopesBySender: string = "D2616C14-8D9F-4F87-B5A6-E03F9854BCEE";
    public static MoveEnvelopesbyReferenceAddress: string = "76EB4582-6A3D-4C64-B2A2-CCEAC0306AF6";
    public static lang_delegate: string = "F8FA7589-3D2A-425A-9F5A-E5079428FBD5";
    public static SenttoMe: string = "36D9ACE4-5679-4EC5-B90E-600FF3C56B80";
    public static SameRecipientsforalltemplates: string = "11A13B84-CD79-4AF8-A2E5-F93A1E84F01D";
    public static RetailGroupConfirm: string = "179F55FD-AAF0-4961-BEA0-659F02625428";
    public static WaitingforMe: string = "E59A1A1D-6C8D-45D0-B143-652EC53C280B";
    public static Apply: string = "007DDDA4-68B0-447D-9EB2-F2E636CAF1CF";
    public static Received: string = "AF79DAE0-0E1E-4C68-B44A-D2D66FAE6512";
    public static CreatedOn: string = "8C3371FD-8C8D-418F-ABE0-748911174755";
    public static SelectFromTo: string = "50612D76-F786-4BBB-B3A2-BD2A0B985317";
    public static AddMore: string = "FA422594-753A-422E-B2C5-522A64DB65A2";
    public static AddItemGroup: string = "7A21FF91-7C2A-4DD8-B515-069B645BB1C6";
    public static CreateDocumentGroup: string = "3353CB17-3C92-4462-B503-B46D9CD55089";
    public static LoadMore: string = "E70EB727-19C2-40DF-BC78-48B047D0550F";
    public static DeleteAll: string = "30D1948B-84AF-4076-8351-5C3AF87D0EFF";
    public static RestoreAll: string = "419E6238-6561-43C9-90BC-A9A445AB9652";
    public static A4: string = "B8C5A1AE-53FF-4B9D-A867-3A5EC7FD0D13";
    public static USLegal: string = "744C3245-3BEC-4FD9-A732-41B7E83C83C0";
    public static USLetter: string = "5C40037B-EA83-45F4-9B84-9CC7020D4E28";
    public static AutomaticallyDetect: string = "E9EF5B2B-54AF-4EBB-AC77-73AB382BE16A";
    public static SelectRule: string = "1A177D36-4D1C-42E6-A908-5717CCDA94D7";
    public static StampWatermarkSetting: string = "45AD3108-2229-4F74-BA93-077088F0D585";
    public static DGPostSignPopupDesc: string = "E3950879-FFE2-42AA-9BF2-E0DF2320F549";
    public static DGPostSignPopupSignAnotherButton: string = "8C8CA552-782C-4951-9236-A5BCD3B11F25";
    public static DGPostSignPopupBackButton: string = "D219EB75-B9F2-4B0D-B949-D678C2CBBE19";
    public static DGClickFinishToComplete: string = "E9D13197-DEF1-45F5-910B-64A7E31FA655";
    public static DGPendingAttachRequests: string = "C8B78D3B-623A-40C0-8976-185CB8039E38";
    public static DGMyList: string = "60F23E27-8E50-44F6-BBEA-E353313D2A04";
    public static ViewingPersonalSettings: string = "B91213FD-953D-4957-9903-998AF97878B7";
    public static YouAreViewing: string = "DFCF3FBB-D227-48EF-BFC9-F978AD029031";
    public static CompanySettings: string = "DC9184CC-A094-4768-8E25-F33647007490";
    public static UserSettings: string = "D47A53F1-A7E1-4B94-B057-A3A8774621CE";
    public static DeclineWarning: string = "8A4589B4-7B1B-44B0-B0AD-0CE30D1EADC4";
    public static EnvelopefinishedMessage: string = "5D86E565-F701-4A16-9515-EB3DC7738961";
    public static SignatureRequestReplyAddress: string = "03301BE2-DAE7-49DE-851E-44E441BEAE38";
    public static SenderEmail: string = "B698C905-7766-4DCD-B2A2-C5C8F333AAA0";
    public static SignatureRequestReplyToMessage: string = "32407470-2F58-4374-AF6C-99CAE7DED8EE";
    public static Sending: string = "17E3E69C-6538-446A-8884-EA3C2E937371";
    public static ShowRSignLogo: string = "99ef7eb6-22db-417a-ad08-362e6a363b9d";
    public static ShowCompanyLogo: string = "a1544a83-f345-48f5-8ea6-f856b5ddab38";
    public static CompanyLogoImage: string = "e5bfb06e-c1d4-4ede-8fd6-e5f6c062830f";
    public static lang_selectCompany: string = "AE12971B-B970-4FA6-8562-67E5690C851E";
    public static DisplayOnSendTab: string = "5CBAA26F-BC91-402D-8CBC-9C72A4059CFA";
    public static Profile: string = "FFBE3506-7B5F-4023-BD26-A9FA68B03224";
    public static Storage: string = "8A6AAC8A-96A6-4079-AED6-6A0AF366C469";
    public static Advanced: string = "C7C99BD1-D59E-49F5-A74D-C33124A43BF2";
    public static ServicePlan: string = "FC9F6A02-7979-4660-BB6A-1D291440F3D7";
    public static EDisclosure: string = "38B19751-59FD-4D13-B53F-469438C36295";
    public static EnableIntegrationAccess: string = "BF37A7C2-44B5-4071-AB82-3DA7D9AC7551";
    public static EmailRouting: string = "0AA274DF-D8CF-4EA3-9E54-66D572D3A2FE";
    public static ElectronicSignatureIndication: string = "BFA01C85-A0F8-4341-AFDC-CCCE660C1D30";
    public static TextforAllOther: string = "AF749A4A-696A-4B00-B992-776913AB800B";
    public static ReferenceKey: string = "44075B63-4B42-4A6F-95A9-5A5403702F82";
    public static SigningCertificatePaperSize: string = "025BBD2F-4048-4E45-ABE7-9E2069C471D1";
    public static SearchCompanyUser: string = "C14E6DE2-5AC8-4628-B17A-7B9011C4302C";
    public static SearchUser: string = "1C9A5E6C-F79C-4E47-8AE8-05475A4A4508";
    public static TextForSender: string = "2740974C-7F98-4993-903F-E5F605508090";
    public static EnterTextforSender: string = "573D1858-2CD8-44B5-8655-21DE1D5E98B6";
    public static EnterTextforAllOther: string = "479880D6-D8DE-4124-937C-B338718BF3E8";
    public static Responses: string = "ED060C78-D8A4-4BD0-986E-C0FBB0F218A4";
    public static AddOtherWithTextFields: string = "3732EFA8-67A2-4AF3-BD06-53A603252431";
    public static Controls: string = "8E857F1B-072D-47F1-B79E-399B21E3F1DE";
    public static DeclineEnvelope: string = "8089D5C0-08CB-4752-B6EC-3333A4F5EA45";
    public static TitleText: string = "F9CBB171-437F-4CDA-9246-5DC5855EB0F8";
    public static EmailBanner: string = "9A1545EF-6663-458D-BC14-E06D924B9845";
    public static EnterLastName: string = "3C8F88EA-839E-483D-9237-18306821978F";
    public static EnterCompanyName: string = "19E3E879-EF3E-45F0-88A0-025553006FAB";
    public static EnterJobTitle: string = "39B3A2FA-7C03-42F1-A097-0088A1781673";
    public static EnterFirstName: string = "C02E81E3-C100-432D-BEB2-C01729E713AE";
    public static EnterInitials: string = "7676B253-5FE6-4E77-81B1-7CCA9A55AFCD";
    public static BannerTextColor: string = "c8c6f521-3ab4-4834-b9ca-253e0ef06337";
    public static BannerColor: string = "a4bfe463-f7dd-484f-8cfe-23d13ac78479";
    public static ModeViewOnly: string = "5BA869DA-438A-4285-B50D-03FA48B286DA";
    public static OverrideTooltip: string = "92DB27C8-00FE-43E0-999F-A7F9F895C1F2";
    public static DeleteData: string = "ECAA2CD8-358C-4637-80EB-5027140B083F";
    public static DataMasking: string = "D6B591B8-6DE3-4460-B48A-1A7C42FFB764";
    public static GDPR: string = "3CC5D6BA-A0BA-422C-B0D9-8E22C124662B";
    public static PrivateMode: string = "1E29E18D-44C0-49F8-A92E-9910E4525927";
    public static Privacy: string = "F19EBF72-F694-4565-B008-25CFA06657B4";
    public static RemoveData: string = "1342D97C-743C-4F29-AD78-F12A16743009";
    public static Envelopes: string = "66822ECC-1DF6-49AB-B1F3-88538CF1440F";
    public static Account: string = "E8A1DD0B-1E20-440A-BCBA-DEC03D2476E8";
    public static Or: string = "F77AF692-0768-4B62-A6C8-D354B7BCC624";
    public static Optional: string = "B776E314-5228-4A64-811F-E3F4C1F07A6C";
    public static StoreOriginalDocument: string = "24EC9EA9-BCD0-40C6-BF3F-F603541AD285";
    public static StoreSignatureCertificate: string = "1DA95824-EE7F-4DA5-8E3D-48322B8F2D88";
    public static DeleteOriginalDocument: string = "05166F76-B258-476C-908A-2CC76CACC89E";
    public static AllowBulkSending: string = "DCDE76BA-9A2D-49A3-83AB-FF612F79773E";
    public static lang_DownloadBulkSendingCSV: string = "D5AA0EB1-9D3D-4282-9EEB-BFBA0932D735";
    public static ExternalDrives: string = "E3AEC0EB-7B9F-4842-A747-5835B38848CE";
    public static ExternalDrivesSetup: string = "9A66765D-6B28-4700-BCBE-A1640FBEFAD8";
    public static Browse: string = "FE7DAAE7-6AB7-419B-B59C-99EA8ADADC67";
    public static TemplateDetails: string = "B56B1324-8E06-4DA6-AC9C-42F68C169FC6";
    public static DownloadandFillCSV: string = "AD204CD3-22E2-4CD6-B4CC-4C64879D224B";
    public static RoleType: string = "6FFFBB7E-3C69-4D3E-931A-F10AD559B3E7";
    public static RecipientName: string = "D041C7AB-5391-4CAB-BFE5-77B535BDCA01";
    public static MessageTemplateCode: string = "56FDB6A4-BD39-47AB-8935-1DE34CD0ACCF";
    public static Message: string = "333851F9-10D6-484A-83B6-1AB5A940EA26";
    public static BulkSend: string = "1396734F-7F33-45E8-B9F2-C625567952BD";
    public static UploadBulkSendCSV: string = "FD33222E-06E4-47AE-B7DE-4699593E845A";
    public static RoleName: string = "46397B51-D70D-435F-91C6-3F50D55EB59F";
    public static RecipientEmail: string = "7CA44903-E9BD-4E2B-A1FF-BA97CB5499BF";
    public static BulkSendReview: string = "37C496A2-A0FA-4EDA-B0C9-B544B4549F4C";
    public static RenameFolder: string = "823F8E04-2456-4AEE-8BF4-34C934D37682";
    public static AlignTop: string = "AA70A8D5-BCA6-4914-8224-04232A689CC9";
    public static AlignMiddle: string = "82669E74-787A-48C2-806D-AEB4F1C93C85";
    public static AlignBottom: string = "06C0667C-2026-41AC-9656-E0FA601BCC9A";
    public static DistributeHorizontally: string = "4ACBB5B0-B418-43DC-A2E8-E13E6421454B";
    public static DistributeVertically: string = "FCB0EF2D-BE1C-4108-9178-D26B6CABE435";
    public static TemplateOrRuleSearch: string = "56899DDC-6186-4524-8028-A0E3C0A3E717";
    public static SendDocumentConfirmation: string = "5A62DC25-A68F-4E92-B7DE-396364E50923";
    public static BacktoSendDocument: string = "4B78BC92-A10D-4A29-BDA7-F68750EEDFF8";
    public static ServiceLanguage: string = "68F4A856-8C3F-4702-9970-EA3731A2B664";
    public static LockSettings: string = "E62BB4D7-9857-43CA-A632-9CDD9A2A1821";
    public static SignIntoYourAccount: string = "25EE3A5E-5D2D-487D-9DB5-4377530E8798";
    public static Notyou: string = "9F112364-4747-463D-ADC0-B3AC36FC7C0A";
    public static Signinginas: string = "0F0E0477-02CE-4597-A20D-A6F6C7A1B8B6";
    public static PostSigningUrl: string = "EF51757F-F0F7-4F29-A74E-87B02B8E9F8E";
    public static ColorPickerMsg: string = "4134C9B7-5D87-43DD-9A37-372502A0AF8D";
    public static EnvelopesPostSigningUrl: string = "0162FD3C-957D-410F-97F4-497103BA2A8B";
    public static Other: string = "D96A5866-E541-45C3-88CE-473CF512B4BA";
    public static History: string = "E2FD5A60-D72C-4224-9D26-DBCB8B568614";
    public static ModifiedDate: string = "D2848BB3-CC74-4301-AF00-6779BDEEAF2D";
    public static NotSelected: string = "47AE9D46-C5C8-4650-89AD-BC77DE51E19B";
    public static CompanyHistory: string = "BE98EDE7-E68E-4C97-A3CE-E8083AA67C90";
    public static UserHistory: string = "A3499E71-E713-4821-9F90-877B09767C60";
    public static PreviousData: string = "F311A251-E8D3-4C0A-904D-1D467230B578";
    public static UpdatedData: string = "A51EE5E2-3DFE-45E2-B939-BCC5A5D21D78";
    public static GoogleDrive: string = "5458DC49-A9F4-40CA-846C-FDEBA283AA5F";
    public static OneDrive: string = "3DAD5DBE-C374-4434-B4C5-C13C3EE32F5D";
    public static Dropbox: string = "E593BD02-C8BA-45DE-8546-0441F6F146AA";
    public static lang_notfoundHistory: string = "A01BB9FC-8C79-4CC7-AE8B-4EE6710FC482";
    public static lang_NA: string = "D3458BBC-6262-4605-917E-4E97AC651EDB";
    public static lang_DocumentSentInProcess: string = "7123E18D-1B88-414F-836B-8894D36E9436";
    public static lang_DocumentFormatChangedWarning: string = "01836DB4-A221-4CA2-B154-FAC798EF9FEF";
    public static iManage: string = "48C710C8-9F49-410A-A6AD-6132E5C35927";
    public static netdocuments: string = "2E5DD5D3-672B-493E-8A8A-1C83C1903B9C";
    public static AppliedEpic: string = "AFE3E372-3B08-42CB-B7B9-6D8AD510CD71";
    public static Bullhorn: string = "060AC0D1-5713-4DEF-8FFD-D6A910DE6E1B";
    public static Source: string = "3331AEAD-348E-4B4F-8C8F-DE02526B9AB3";
    public static lang_none: string = "23EE52A0-41EB-485C-AEC3-B446768C8F8C";
    public static CurrentSettings: string = "64F9CC80-E2FC-4518-A721-7140976C87A5";
    public static Size: string = "DAB925F0-3FB9-4F57-8B82-EE9BB2A68910";
    public static ManageEnvelope: string = "229A92EE-8558-4F28-A225-D1ADF9136697";
    public static RegenerateFinalDocument: string = "897806E7-7044-4A66-97A9-8A67C816A6A2";
    public static Contracts: string = "C91BC834-D590-4D35-B05D-8C17DD9B4B7A";
    public static SignerAttachedFile: string = "663FEADE-1EF3-48CB-A0BC-B4275820E6C3";
    public static lang_MoreActions: string = "A69529B5-BC00-4C99-8744-48BBC408B686";
    public static DocumentType: string = "29918858-B944-4B22-B81C-794761145A45";
    public static OriginalDocument: string = "99D5CE62-CD39-46D1-80CB-EBA4EF59B5C9";
    public static EnableFileReview: string = "A1921EC5-2708-4163-9B04-518611042F57";
    public static ShowControlID: string = "8336357A-D384-4C9C-A74F-9D61474429A3";
    public static TemplateName: string = "E7599CAD-30C1-49BA-811B-014569A75E89";
    public static RuleName: string = "89726913-C134-4940-B62B-F81F5D388CA0";
    public static FileReviewStandardTemplate: string = "4FBF0F57-48E8-4DB2-8943-F6E70097B75D";
    public static DeletedBy: string = "47A8C53C-93A3-4841-856E-F0BCC8AFB7E5";
    public static DeletedOn: string = "456B7672-3CE5-4503-A139-E66C959F52AD";
    public static SaveAndContinue: string = "BD2861F5-3133-4701-9A85-09B45DEC2A3F";
    public static AllowRuleEditing: string = "1B26376D-AB62-4354-89CB-68DB71659F09";
    public static AllowRuleUse: string = "049E8599-6C90-4DF4-B82B-2778E989896B";
    public static SignatureRequest: string = "560129B3-D5C3-45E3-BB0B-4A686AB4C238";
    public static SendingConfirmation: string = "1BD2C2A3-EB87-4813-B79D-406D9258E6F5";
    public static PasswordNotification: string = "0A065A6D-B244-4100-AF87-28848DF0BAE3";
    public static SignatureCarbonCopy: string = "87922E84-6F1C-430A-A23C-2B2E2CC6E087";
    public static SignatureReminder: string = "4EA9B13E-4747-437F-A448-FE6E9475DF18";
    public static DocumentSigned: string = "B8F31C47-9A7E-4E0C-B440-50BF03341339";
    public static FinalDocument: string = "DABA451D-F985-42BF-9C2D-2AF7C62F5996";
    public static SharedRuleCode: string = "A91B4801-31C7-4280-BAF6-12A086FCF2FD";
    public static SharedRuleName: string = "EB327189-2D5D-4E58-9377-198365A7F439";
    public static SharedTemplateName: string = "CF6892FC-E102-457A-B640-1A8B5FAAAAF8";
    public static RuleVersionHistory: string = "3BAB1783-8249-4FE8-B36E-239803F5EF35";
    public static SendToRSign: string = "F31DB518-621A-4171-9FD3-381FA9D506EF";
    public static SharedBy: string = "33EE694B-A073-4305-AD94-42135F7B0274";
    public static CreatedBy: string = "E219FE4B-E6D4-4992-B786-5C10C1596415";
    public static TemplateVersionHistory: string = "6AB802A5-2D79-425C-B6B0-7C21605308DB";
    public static Envelopecreationinprogress: string = "206F81CC-1D4C-4559-85C8-848B2E0A967B";
    public static VersionNo: string = "3675AD3F-ACBC-45EF-B124-A7A87F9DF22E";
    public static LastModifiedDate: string = "7A530B73-7F32-436D-93A0-A994C78DD9D5";
    public static SharedTemplateCode: string = "F3CB62D3-5EEE-4302-B906-B249CABC9EB7";
    public static ViewHistory: string = "F6D56928-87CA-4D80-9AB2-C1A74A888CFA";
    public static UpdatedDate: string = "77CDFF27-79BA-41E6-9AAC-F4F6D87212C4";
    public static AllowCopyTemplate: string = "16DE5081-F3AB-45BF-AC41-DAA8D1681AC5";
    public static ShareMasterTemplate: string = "286D47DA-5CCB-4C20-8892-A7A75AACC742";
    public static Both: string = "D85B9268-6131-4C8A-9805-95B748D8AE05";
    public static ConfirmShareTemplate: string = "1059E8E5-FCF5-4A9A-88BB-F1E311CDCD1A";
    public static SharedOption: string = "A35C77BA-29CB-47FD-9F6E-BF92B84DDE85";
    public static lang_sharing: string = "51D69A43-A8BD-4297-8AEF-6902039823B2";
    public static AllowMessageTemplate: string = "466A8951-281F-4EB3-BBC2-A554877E6209";
    public static MasterTemplates: string = "1178B156-7F13-46E3-9E73-B81242D602A9";
    public static lang_templateNotAvailable: string = "5CD65B79-C563-46E9-84C9-B971E34170BB";
    public static MasterRules: string = "DE84F6CC-1C5A-46B3-8D0A-9C2A787AB4CD";
    public static PersonalRules: string = "E2CCD302-9357-46DF-8A09-BEFECFC3EB84";
    public static lang_ruleNotAvailable: string = "47DF468C-ADCF-49A1-B47D-7CCA13D416CC";
    public static lang_ValidAllowEdit: string = "157139C6-FF15-454A-AAA3-1FE3BF6439FE";
    public static lang_ValidSharing: string = "2E0D2D91-7BE2-4948-98E0-14E0259907FA";
    public static Master: string = "01E9D0AA-88CD-4229-A02E-7654260AD6D6";
    public static SharedAsMaster: string = "D5F6EBFD-87E1-4CE3-92C6-610B7310078E";
    public static SharedAsCopy: string = "5BEC15DB-3543-410C-AD4C-E3498BEDAFF3";
    public static SharedAsBoth: string = "D749D834-0D69-4B9B-A2F9-46303EB93A56";
    public static lang_EmptyMasterTemplate: string = "5CCF1DAB-4BA2-4ABB-BDF7-D01D53112CB3";
    public static lang_EmptyMasterRule: string = "2D98AB06-B1FA-424A-ADCA-A4F75CE508B7";
    public static lang_AllRoles: string = "894E96B5-9428-4226-92FA-B768B6E75C4C";
    public static ClickToShare: string = "5ED5544D-EA51-459D-979D-F2FE2A5694A3";
    public static ClickToDelete: string = "B2499F5F-6402-4133-9BB3-717C718B3EEC";
    public static ClickToEdit: string = "5EDCF1A1-8734-474F-8485-830A32FAB993";
    public static ClickToUnshare: string = "EF407CF3-C515-4EC1-89A0-C52670DDEFB4";
    public static ClickToCopy: string = "2C729465-D818-4C62-B262-AF4F3E182D9B";
    public static ClickToAdd: string = "4EBBEF78-A894-42F6-A263-F3F45629715A";
    public static Preview: string = "258DBE19-9F81-4D8D-913F-0469AE78B324";
    public static AssignedTo: string = "24B7610C-989E-480E-AC85-238A1E9E51C7";
    public static AdditionalAttachments: string = "A7FF94BC-0836-4AEA-9DAD-F072F866DD00";
    public static DeliveryStatus: string = "22FBF1A2-01A9-48F7-8EE6-A172AC67452C";
    public static AssignedToAll: string = "F90FED0F-08BD-45F3-B18D-6FBCFB249876";
    public static EnableClickToSign: string = "29ADE289-EC1F-461A-AAFD-0591F8F32FBE";
    public static PostSendingNavigationPage: string = "989C2B87-C7ED-4F6D-A58F-DBDA175C9837";
    public static EnableAutoFillTextControls: string = "D300901A-F359-455A-BD7E-F01C575478E1";
    public static SignerDetails: string = "B5C303E2-85A0-47BA-AE07-3F1C6C5B0251";
    public static ClicktoSign: string = "908BC191-6129-40DD-9018-E168245475FC";
    public static ConfirmClicktoSignMsg: string = "AD015C70-E514-471D-862D-65A4E7FD4028";
    public static SignerTermsCond: string = "4C983255-3604-4C21-84D0-92990960941E";
    public static AcceptandSign: string = "5FE387E8-1304-49CF-A6B4-07B47A1FC3B3";
    public static DataControl: string = "B20205F1-85C8-4B77-A7E6-A397598F12D9";
    public static PersonalControl: string = "65A22287-ED2C-4163-B5D2-24ACCD692863";
    public static SignatureControl: string = "E02FF794-35AC-4BE8-9B4E-3EA19CD329FC";
    public static AdvanceControl: string = "D93CF3D0-0A9D-4A25-93DF-95B6AAF44566";
    public static lang_dataControl: string = "4EE21C4E-C0D7-4267-9B54-2D18DD2897EE";
    public static lang_PersonalControl: string = "FA893233-9099-4C8A-9A5F-D0E779EEFF8D";
    public static lang_SignatureControl: string = "896312C6-9B8C-44E3-8A4F-A416DBCDB64E";
    public static lang_AdvanceControl: string = "1833B5B4-F202-4C19-B906-E9175389DF0A";
    public static ClickToMoveRightControl: string = "07E2BA10-B31B-4FDA-BF53-D9606E1C9E0C";
    public static ClickToMoveLeftControl: string = "47621C76-E9B9-4919-B076-A370EEBC7E97";
    public static BottomControl: string = "83444A8F-D1F5-4C55-BEC0-6CA32B51B386";
    public static lang_defaultDateSigned: string = "97C864A2-653C-4830-8187-F42B6376B194";
    public static CustomTooltip: string = "431484AF-6EBB-4414-9210-D2937B4B46C9";
    public static TextColor: string = "1BBBAB56-D8D5-4F84-9396-F6DD50ABE600";
    public static URL: string = "627A03AA-9CA4-48FA-BADB-6947E415D53C";
    public static TexttoDisplay: string = "8C2F49BD-D038-485C-8E9A-6D82AAD2A565";
    public static lang_savedefault: string = "8DA068CF-9E61-4C8F-9CA8-357A32032822";
    public static lang_removedefault: string = "5EBE0307-9020-4956-91CC-3A07FAA74865";
    public static lang_successsavedefault: string = "B509672C-C7D1-4273-96E1-778F0F4515A1";
    public static lang_successremovedefault: string = "D400D7FF-7D1F-4EE7-8F9C-607602A61D49";
    public static AlternateSigningOption: string = "AA0C3458-D155-42EC-BD3C-8FCE5C810DDD";
    public static QuestionsAboutDocumentText: string = "715B8173-7B8F-42AA-9A3B-75498A72DDAA";
    public static QuestionsAboutDocument: string = "7ABEA5E1-3930-4694-9E89-FB17B75E0437";
    public static DoNotShareMail: string = "1F473762-D287-401A-8C33-59E7251AAFAF";
    public static InviteExpiresIn: string = "C1F08281-3B92-4341-BB36-416389523AFC";
    public static SignFromRSignWebSite: string = "B952A746-8DB2-470C-B53C-010A7E3D6D67";
    public static LeagalNotices: string = "293D1DA7-910B-4F9E-81B6-4EDCCF430465";
    public static PatentTrademark: string = "2AEB7505-2BB0-4D98-9340-89E668D0DA8F";
    public static TermsConditionsPrivacy: string = "BE494177-324D-46E6-8CCE-C74A06DA9F7D";
    public static GeneralTermsAndConditions: string = "4FEB8761-3F73-46CE-9636-672755254901";
    public static EnableWebhook: string = "775B86A4-1137-4254-94C5-93430DF5B4C3";
    public static ValidateSigner: string = "E99F3D36-D42C-4634-ABAA-6EAE51892B95";
    public static TypographySizeHint: string = "7985D3B9-3596-49BC-8C32-247E0A193BC5";
    public static TypographySize: string = "BBBAA225-9330-4200-A954-0FABAB6C377F";
    public static UploadSignature: string = "F09ABA05-C4BB-4383-B382-30AE01F812A4";
    public static ViewSettings: string = "CC147CC8-ED00-47CE-939C-F120CC6894B8";
    public static OnlycharactersAndnumbers: string = "CAFD9E78-1CA7-4A80-9AF1-B11C35E77DC6";
    public static checkboxGroupsOptional: string = "52258AAE-CC2F-4ED1-B8FC-1D8DCDF177F2";
    public static ForgotPasswordSubmit: string = "8DE5D787-26F9-4756-B68A-3D030D87DF32";
    public static BackToLogin: string = "E273049D-E34B-4571-B219-504CCA3D7855";
    public static ForgotPasswordLabelText: string = "FB9EA4C5-6C0F-4C9F-AC51-9F1FF0FEC1BF";
    public static EmailIsRequired: string = "78AC958F-4BF8-4EF6-B7BE-39152C04F8CB";
    public static lang_export: string = "B77BCAFE-DE2A-4395-8E0C-6D853DD418EB";
    public static exportCurrentView: string = "67837E88-1969-482B-8C6F-AE66B03F8F6D";
    public static exportAll: string = "E91CB2F4-16CE-4063-8026-DEFE0C22096E";
    public static LoggedinUser: string = "6092A97B-BCB6-4F09-835D-875BEA1AE10E";
    public static UploadSignedDocument: string = "B832E543-D93C-4432-BD10-38139130966A";
    public static Move: string = "ACCE125A-B620-4B8F-96F3-1A3EE7308F68";
    public static SearchAll: string = "BB9C9736-3AE7-4005-84F7-A97BEBE90FA8";
    public static SelectFromToFilter: string = "7EEE4F8D-9C3E-4242-9B9F-696349EB879E";
    public static SelectReferenceCode: string = "4E5BB8C5-4F02-45CE-9F86-510E38E6F13D";
    public static StartDate: string = "FBB25A2C-DF10-4D31-AFDD-27593FE63830";
    public static EndDate: string = "ADA247F1-648F-4815-AF82-F1B00F93A8EE";
    public static orText: string = "F4A168B9-A7BC-4F77-B8EC-F6317EC0FF1F";
    public static AddName: string = "54D9E3B9-F24D-49A9-B487-58D8EB8C3564";
    public static AddDescription: string = "9A87A712-8D4B-4EA9-B7CF-CD9D23EE5DA1";
    public static AddAdditionalInfo: string = "4792A16C-90C6-4D01-94F4-414A78EB78D5";
    public static CreateEnvelope: string = "6CA51FB2-7E65-4936-B34C-9521C6872022";
    public static EnvelopeStatus: string = "29F725D7-0C91-433C-9141-02C5095176F6";
    public static HyperTexttoDisplay: string = "D3CD7352-5A9D-46E2-B17B-B8866D176806";
    public static Register: string = "1D504526-617E-41AF-A20E-C132A57DAE5C";
    public static DaysExpire: string = "95F81600-5DE3-404C-AEEB-5A32A1DE7B3F";
    public static lang_SearchUser: string = "096E1695-0ECD-4690-9C09-C5538C9AD64E";
    public static SelectContact: string = "040C1768-4AB3-41F5-8620-0D7B82C35E6B";
    public static ContactSearch: string = "8F3110F5-A42E-4AC8-828E-4A5DD8D977DC";
    public static NoContactsAreAvaliable: string = "6F5167DF-90BF-4546-84D3-AB8E2DBB86EE";
    public static ManageContacts: string = "02838C2A-F916-445F-94B1-BB5B0CC5F6D9";
    public static Contacts: string = "40F9BCCB-C636-4E76-9302-0ABF6D5B552B";
    public static AddContact: string = "D7FD00E1-62AF-402C-9A66-32F3FD3D17BD";
    public static EditContact: string = "5CA56DBD-EAFA-4FDA-968D-C86E212AE6D8";
    public static DownloadOrDragDrop: string = "C9B2DA42-7F93-4732-883B-3C17608C38DB";
    public static DownloadXLS: string = "8272BE28-6205-484D-9D66-4D82D9B140E6";
    public static DownloadCSV: string = "02169A30-AC59-482A-8C9F-28C1C0465EB7";
    public static SupportedFileFormats: string = "8E282745-387E-49ED-84DC-AC79886373CA";
    public static Warning: string = "C0544551-2B5F-49EC-9DF8-F1099C3E38C2";
    public static UploadedBy: string = "B56A57E9-7EF8-4D10-A20B-5FB0A08450AE";
    public static UploadedDate: string = "A98D0140-B3C6-4D84-8F6C-46C0A654CD7A";
    public static LoadingFiles: string = "F1FA7068-B6AE-4DC4-B637-DEDB1FCF13B3";
    public static LoadingContacts: string = "0ECD9345-A042-4D42-B0C8-048A6DC4F2AE";
    public static DeletingContact: string = "3FF0DF03-A755-453F-98B2-945E5494ED7D";
    public static DeletingContactText: string = "58DAC415-7575-445C-9CD1-EFD5CCFDBD35";
    public static SupportedFilesFormatValidationText: string = "32B40711-1B71-4CD8-90D4-8BF2EE1FB75F";
    public static ValidCSVOrExcelFile: string = "5244A0B1-D1B9-4D6C-8FF4-5081D9C049F4";
    public static InValidEmailAddressText: string = "7C787FCD-241B-4F40-B4BE-EE4D75624A5C";
    public static DuplicateEmailExistsInFile: string = "BCAB0613-30A5-43A5-B054-4B345338275D";
    public static OverWriteText: string = "6B56B859-7D8D-4630-A270-D79E4C4CB1A5";
    public static DeletingContactSuccess: string = "143B8F70-3B64-45DF-8FA3-40CFDFA53E16";
    public static ContactSavingSuccess: string = "BDDEECEE-25B9-40B2-9672-FE0E93B9ACA8";
    public static ContactNotExists: string = "92318E8D-6398-459C-9893-BCDB8AEEEFEC";
    public static NameEmailRequired: string = "E2DC7642-7991-4795-AB8B-2521A7A200BD";
    public static SavingContacts: string = "9903D0AD-7B1D-4F07-AE04-5B8629C6CCC0";
    public static OverWriteContact: string = "B7A6DE04-1834-4B1E-91F8-4E55AD93DCC5";
    public static ContactAlreadyExists: string = "41FDE2FE-700D-4A67-960D-B2C92F4C17F8";
    public static ClickHereToSort: string = "6B8AD2AB-000F-4E48-86F0-BF59EB353637";
    public static ContactPlaceHolderSearchtext: string = "8F3110F5-A42E-4AC8-828E-4A5DD8D977DC";
    public static SerialNumberText: string = "0F5C6A8E-882E-4008-B078-699E41583361";
    public static EnterName: string = "C36E85AB-74B5-420B-9838-BAEE73DA499A";
    public static EnterEmailonly: string = "3D35EE0E-891B-4420-B14D-B55CB24C9A96";
    public static EnterCompanyonly: string = "A088AF8B-F6F1-453A-891D-0E9B0BDA9E36";
    public static EnterPassword: string = "A965B8FB-52A4-4065-9B30-4035E9B71F2F";
    public static Success: string = "CC207874-D065-46AD-8C51-07B00BAFA1EB";
    public static Redirecting: string = "6A80203D-D572-49B9-A967-4E0E666BB328";
    public static DocumentPassword: string = "65E0A063-BDA0-4BC6-97EF-0173B00B28B6";
    public static InvalidPassword: string = "5FC1AF01-90EE-4637-AB16-4CDF8D9A83A9";
    public static EnvelopeExpiredGDPRSettings: string = "550185F6-9745-4628-AC65-F1C51F937098";
    public static Remarks: string = "CE3B29EE-2920-42C1-AD33-D1AB2ABA811B";
    public static Lang_LogonCamelCase: string = "5E917F61-9697-4918-AB67-57506FC6E65C";
    public static Lang_NoThanksCamelCase: string = "9D9DBEC3-BF4F-489F-9A62-36CB488DF029";
    public static SelectColumnsLabelText: string = "7CEB184D-4EB5-4267-BBB3-9E7E8E2AE28A";
    public static CustomColumnLayoutLabelText: string = "91F4B022-3ED5-4E95-8D75-F62650124915";
    public static AvailableColumnsLabelText: string = "597E6E7E-888E-4F4E-914A-25AC8AB7A9A1";
    public static SelectedColumnsLabelText: string = "E3EC426D-FFF7-49E7-BF0C-7B208CA4C3BD";
    public static title_sortSender: string = "1AA8A98B-8369-4023-ABBD-C75283E3C8C0";
    public static MandatoryColumnsValidationText: string = "4685685D-E170-405B-9E74-085BAB9454F8";
    public static Welcomeback: string = "0C7E0314-8A0C-4C83-9DA7-32F43723FF87";
    public static SendmetheLink: string = "EBA32940-11A8-4815-92C5-734F90E68307";
    public static ForgotyourPassword: string = "FC3A03D3-D98B-4258-A617-14804A25B595";
    public static SignmeIn: string = "364E245F-FA63-4142-90FD-1B4D43CE3706";
    public static Letsgetstarted: string = "A4ADD2AA-6370-4A1E-A1EA-15C0DDC220FF";
    public static CreateNewPassword: string = "BAA601A7-5C72-4272-B351-EB00650C6377";
    public static SendReminderTillExpiration: string = "B31655E5-E726-4EB8-81D8-ABBD612110FA";
    public static EnvelopeExpirationRemindertoSender: string = "7E70886B-54D8-4BC4-B059-A1FA0593F28A";
    public static PrefillConfirmationFinishLater: string = "605A516E-8873-4622-AD32-804AD827E5D4";
    public static OKText: string = "9D30B519-47FF-463A-82A5-6FBB42A57E5A";
    public static lang_BackConfirmationS1: string = "FCB3DFF0-6F92-4437-A452-FBD4A9BE62B2";
    public static lang_BackConfirmationS2: string = "4867AE81-EC7B-49C9-8FED-06A2F8ADB66C";
    public static From: string = "D7842720-BEB0-416E-95BC-689CBA3B03B6";
    public static Final: string = "DAE0F7E6-EB61-4836-8543-6FE69E286AA0";
    public static FinalStepDescription1: string = "95253969-A90D-4E39-9511-4CDB8947EE6A";
    public static FinalStepDescription2: string = "9042C47F-C8A9-4038-9DD6-20B4F06131E1";
    public static FinalStepDescription3: string = "50D28A33-A64D-437A-863B-C5CC65BC6CCB";
    public static RPostActivationEmail: string = "5EE4D0F5-CDEE-463C-B2FA-41DB22854615";
    public static FinalStepDescription4: string = "820F4C15-84C6-4281-B788-416392325641";
    public static AllowSingleSigner: string = "F9C6FE71-B8EF-4683-811C-42CA3D6BD249";
    public static AllowMultipleSigners: string = "A22131B1-BE90-4A2C-8CB1-4DFC4971B53F";
    public static AddSignControlStaticTemplate: string = "F97A6596-577E-4FAD-9ECA-ABE680D1E4E0";
    public static TermsandConditions: string = "2DEB715A-6B4C-4A7B-91AC-D456E709E62E";
    public static ConfirmYourEmailAccept: string = "CA0B41AB-BE2A-4284-ACBF-993B9CA0EDA9";
    public static AcceptandContinue: string = "C86CA854-92A6-45DA-ADBB-004D464065F6";
    public static SigningAs: string = "743E2355-CB89-4296-87B7-7A554FCBD5E2";
    public static InviteSigners: string = "E3F93499-AD60-4DF5-8C0E-FC7EE0FC400C";
    public static SignAs: string = "6B112C1A-5AB3-4274-B0AD-86697D5B2EF4";
    public static SignNow: string = "D362F62F-CA4E-4880-8F1E-6903CDBA5892";
    public static InviteByEmail: string = "29FAA277-105E-479C-A654-80B67D62AF91";
    public static CanEdit: string = "BDEA933F-9664-43A4-AB54-92A0468997CC";
    public static Confirm: string = "3B1F4201-52DD-4501-BFAD-8DDACD240F68";
    public static SelectInviteSignerAndSignNow: string = "0ABC205D-D1EE-46B7-938C-F6F199D3914E";
    public static CannotInsertDuplicateEmails: string = "E9289ECA-FC95-4D65-9AF4-76CFFC94E05C";
    public static SelectSignAs: string = "F2FB594B-BF9A-4A70-955A-C30F0412EDFA";
    public static MultipleSignersRequired: string = "A8398865-DCDF-4F69-BAF8-48BA11BB9A6C";
    public static FinalStepDescription5: string = "A7344641-9CE0-4DB9-87DC-45419036FFD9";
    public static ChooseInitials: string = "F1601DF5-5C75-40B1-A648-1D76EE7CF592";
    public static Acceptingthetermsandconditions: string = "6880F76B-6136-423E-8DA4-D2B6E9D26BA1";
    public static Lang_Information: string = "95C106C9-F93D-4BE1-8EF6-DECE953C7E3B";
    public static Lang_InformationWarning: string = "6EBE22F5-5F9A-476E-89A5-4EC946EDD096";
    public static Lang_SigningWarning: string = "41F3BFD7-0241-43FE-AD56-66671AC2071B";
    public static Lang_Signing: string = "FFD945D7-261A-4492-8954-5A96B8F51760";
    public static SendConfirmationEmail: string = "06E9C7F1-C1EB-438A-95CE-2ECA5E4D1C11";
    public static EmailVerification: string = "1593CED0-6F85-4CA3-87CC-8D52050C3772";
    public static MyDetails: string = "66674093-9F44-47D4-BF24-3835085E4964";
    public static EnterSignerDetails: string = "0A4E48F2-E290-4CCF-A9C0-943A719DF3B3";
    public static ShowTags: string = "18CE2312-4D51-4518-8A8F-B643A21B0D80";
    public static HideTags: string = "EB421BC0-73D6-4ACD-A868-2051593B657C";
    public static AccessAuthenticationTip: string = "9DD29819-46E4-4695-9037-FA1DB2C82B7F";
    public static AddDatetoSignedDocumentNameTip: string = "621000AF-E774-4EF4-841F-BC789009E583";
    public static AddresstoCopyAllRSignEmailsTip: string = "B261DE90-0B4D-4B9D-97A6-4BC018F06A2B";
    public static AddresstoReRouteAllRSignEmailsTip: string = "FB88E754-3E06-4500-9C9E-F0D7256FBC15";
    public static AllowBulkSendingTip: string = "A1603172-CCD8-4844-9A29-02165ECB918F";
    public static AllowedUnitsTip: string = "494A747A-2A41-4F23-AEA3-06D12699D807";
    public static AllowPostSigningLandingPageTip: string = "0BDDBE34-8A67-48E9-A90B-6A95B63E2781";
    public static AllowRulesEditingTip: string = "F4632619-F687-4CF9-B3FD-1830D8F4FDAA";
    public static AllowSharingOfMessageTemplateTip: string = "A689E316-BE9D-4957-B6A1-7AD8EF3F9E21";
    public static AllowSharingOfTemplatesRulesTip: string = "1F1ED7E5-112C-40FA-84AB-7993F55819AB";
    public static AllowTemplateEditingTip: string = "A5E6B5BA-36FD-44A2-8EB9-6CAD7F494E58";
    public static AllowTemplatePostSigningLandingPageTip: string = "BED40AD3-DD9B-4264-9A66-C5A01726D55E";
    public static AllowUsersToAlignFormFieldTip: string = "85EA0989-BC6D-45C3-883B-DDCCD63D57C5";
    public static AllowUsersToCreateAndUseRulesTip: string = "F67664F7-38A1-487C-9C06-F8ACFB2DF977";
    public static AllowUsersToCreateAndUseTemplateGroupsTip: string = "A022CFF9-1EFE-489B-914B-792D0B6096D2";
    public static AllowUsersToCreateMessageTemplatesTip: string = "F108BE6E-23B5-4D45-8906-BD5C3C2DCDDD";
    public static AllowUsersToDeleteEmailBodyTip: string = "1054FA5E-6EAD-47E4-BB2B-5B8E7F914C81";
    public static AllowUsersToDeleteOriginalDocumentsTip: string = "7542E821-5B80-4955-9AD8-A119D3DB6016";
    public static AllowUsersToDeleteSignedContractsTip: string = "C230EA3F-8AE1-4E43-9E8C-A96A1DD5046B";
    public static AllowUsersToUseRulesTip: string = "04204601-DD44-4562-AB7F-CEAF6F751903";
    public static AttachSignedPDFForTheseRecipientsTip: string = "BC74BE20-D79E-49E9-AF09-1D27EBCA78F0";
    public static AttachXMLToSenderOnlyTip: string = "E639E119-FE6D-4462-9E12-D49148B7D86C";
    public static AutoPopulateSignatureWhileSigningTip: string = "AC499F3F-28EA-4E7F-B49D-613207B0CD1B";
    public static AvailableOptionsForSignatureCaptureTip: string = "9D822970-B5AE-4CC8-9977-806BF8EEBF19";
    public static BannerColorTip: string = "24CB2438-319C-476C-B36A-36D37FBE8836";
    public static BannerTextColorTip: string = "DE10A163-F49B-4D5C-A983-5C002303EF27";
    public static CreateStaticLinkforTemplateTip: string = "94DE36B8-6B82-40EE-B3E1-384A00AA357B";
    public static DataMaskingTip: string = "F2635463-16DE-4C38-BD24-2A0ACC2360AA";
    public static DateFormatTip: string = "69875C08-E4B4-4A9B-B485-A80ECAACE18B";
    public static DaysBeforeExpirationReminderTip: string = "27FBE8C2-064B-4B06-AC29-1DBFED70FDE3";
    public static DaysBeforeFirstReminderTip: string = "B396B48B-A0BB-4442-AA33-E76752CA1323";
    public static DaysBetweenRemindersTip: string = "086AB8E7-14FD-488F-AB63-763D92184F7A";
    public static DeclineEnvelopeTip: string = "73D05C4F-1EC9-4280-A646-DA8C66757EA7";
    public static DefaultFontSizeMeasurementTip: string = "2DE80C4E-760B-41DF-9D79-9AAA3D8432CB";
    public static DeleteDataTip: string = "E76175E5-93E8-44BF-8E5A-C7CC2F2EAA59";
    public static DisclaimerEmailFooterTip: string = "AB518504-515A-442F-91A5-C43719E84215";
    public static DocumentAvailabilityTip: string = "07242036-E178-498C-BC8A-A39D3C85C30A";
    public static ElectronicSignatureIndicationTip: string = "0AB920A5-1B21-41B1-AB11-01895EF311F1";
    public static EmailAccessCodeTip: string = "1CA3E6C7-136E-45CD-AF07-79EC3D3B3E62";
    public static EnableAutoFillTextControlsOfSameLabelTip: string = "A305E065-F8C5-4EBF-A7FF-15EA43845A56";
    public static EnableClickToSignTip: string = "F2E70AFD-B4DC-4AE7-B8F9-6ED98C291FA3";
    public static EnableIntegrationAccessReferenceKeyTip: string = "15C94239-0A42-404E-B4DF-7A3D0CC8DE23";
    public static EnableOutofOfficeModeTip: string = "C1823B8F-3D5D-41B8-87C2-588530368EF0";
    public static EnablePostSigningLoginPopupTip: string = "40F9BD65-7670-420E-A464-08FFCA6E7210";
    public static EnableTheDependenciesFeatureTip: string = "039B28E3-D2F5-403D-AB46-685E65463410";
    public static EnableTheFileReviewTip: string = "FC4BF904-DA31-4EEA-B263-3AEFC10472D1";
    public static EnableWebhookTip: string = "6C9696AC-5BB5-41C0-BDC0-0DD3B06879AA";
    public static EnvelopeExpirationRemindertoSenderTip: string = "AED1BC9D-DD4F-4711-90B3-DF4DFAAE3192";
    public static EnvelopeTimeDateStampLocationTip: string = "3C931FD8-B605-46ED-A5BE-EFA17F740A56";
    public static FinalContractConversionOptionTip: string = "3D73DA10-38A7-4F14-AB57-23D6915FA1D8";
    public static FirstDayTip: string = "AC25246A-9213-4E7D-B2E5-ECB7CA2C391E";
    public static IncludeEnvelopeDataXMLInTheEnvelopeTabTip: string = "D399C5D8-ED8C-438D-BE2F-87D360DC23CF";
    public static IncludeSignatureCertificateonPDFTip: string = "1A3DD958-2DAE-45C9-A325-9FED99CDC964";
    public static IncludeTransparencyDocumentTip: string = "67BCCEB1-7BC0-4542-8D47-DF0B5D8554FE";
    public static LastDayTip: string = "4265731C-5C72-4B6F-B323-AE1F0468638D";
    public static LoggedinUserTip: string = "AEE49CED-B361-450B-94B2-1D896FE9D18A";
    public static NetDocsLoggedinUserTip: string = "985E933C-1B04-455B-A0AF-18D8CA9150AB";
    public static MessageSignatureTextTip: string = "B7200D82-1D62-4B3E-B4A5-23BC2550A92A";
    public static PlanNameTip: string = "0BD626D0-AAED-4C5E-816D-D0320E25E84D";
    public static PostSendingNavigationPageTip: string = "19EA02E8-9ED6-4DC9-8DE0-3D15CB06DD02";
    public static PrivateModeTip: string = "1046F972-1A33-415B-8746-2EF40D5F217B";
    public static ReceiveSendingConfirmationEmailTip: string = "84BBEEBE-1CE2-45D2-925F-20950274B4CB";
    public static ReferenceCodeTip: string = "52359B52-984E-42AB-88BA-98CD47229BB5";
    public static SendAConfirmationEmailToValidateFirstSignerTip: string = "F6EBD287-254B-42AB-BAAD-F1B5F5A2DB0C";
    public static SendIndividualSignatureNotificationsTip: string = "6FFB6D88-9782-4158-A945-FA3DF164BA47";
    public static SendReminderUntilExpirationtoSenderTip: string = "986CC792-4868-4A68-928D-D553DA4DF61A";
    public static SeparateMultipleDocumentsAfterSigningTip: string = "9E055C4F-033E-4AC1-B71B-50A6E15E5E43";
    public static ServiceLanguageTip: string = "C12D2E29-56B4-4BE5-93C2-88DE3B9BFBF5";
    public static SetDefaultSignatureControlValuetoRequiredTip: string = "BEE7C9E8-55FA-4DDD-9FDC-0F6E714A1C9E";
    public static ShowCompanyLogoTip: string = "74E81348-3F0D-409A-BFF9-2640BCB99492";
    public static ShowControlIDTip: string = "D6254986-674B-467B-BD7A-0812046D99AD";
    public static ShowRSignLogoTip: string = "7BE057A2-1AA6-4B37-9BCD-DC0022513A67";
    public static ShowSettingsTabtoEndUsersTip: string = "11DCF693-84DB-46D8-B26C-A8703D56D191";
    public static SignatureCertificatePaperSizeTip: string = "67FECCB8-4E22-4D8A-A248-B6B3FDD7F326";
    public static SignatureControlRequiredForStaticTemplateTip: string = "52268279-60C8-4366-886C-899AB9B890F7";
    public static SignatureRequestReplyAddressTip: string = "7E3F5140-08E5-4096-AE2A-ECCCCABD6943";
    public static SignedDocumentCopyAddressTip: string = "72AACB0A-F652-4F39-B4A2-106B21FC4A52";
    public static SignedDocumentReRouteAddressTip: string = "40ABCD00-81B1-45F4-87AD-77F979253312";
    public static SignerAttachmentOptionsTip: string = "202C3883-4018-4EB8-9461-81EDBAF4D8F8";
    public static SignInSequenceTip: string = "13A632C3-4010-422C-A490-AACE57ABE492";
    public static StampWatermarkOntoSignersCompletedDocument: string = "5F737299-6CD9-42D9-B7F9-F025D500EF84";
    public static StorageDrivesAvailableTip: string = "D8D732C6-129D-4984-976B-5975D32EA4D2";
    public static StoreEmailBodyTip: string = "D69D05FB-61D2-4BAC-AC8C-43C9FC8E238A";
    public static StoreOriginalDocumentTip: string = "C9033AAE-3AC6-48E5-AA24-E711399EAF2B";
    public static StoreSignatureCertificateTip: string = "A27880C0-E9DF-4ADE-8C9C-60E177F38AAD";
    public static StoreSignedPDFTip: string = "923D267A-17DF-4B08-B254-CE6B4CC736B8";
    public static TermsOfServiceInSignatureCertificateTip: string = "7FF8FB13-80A7-426A-BB56-B2C84F661F1A";
    public static TermsOfServiceTip: string = "1AD1B3AB-354F-4AAE-8A26-4CBE7D070D21";
    public static TimeZoneTip: string = "BDCE2B56-4CE3-4607-94AB-7C891C039D9C";
    public static UnitSentTip: string = "31ADE655-7050-41E6-8FDC-12875BCB19DB";
    public static UploadSignedDocumentTip: string = "B7B69363-7E52-4C81-AC38-EF257C78EEAC";
    public static SelectDigitalCertificate: string = "E566D1E3-D9B3-4529-A739-41BB1C3346A7";
    public static EnvelopesTip: string = "91ad82e0-619d-4ec0-a6ba-b16f323e08b7";
    public static ActivitySummaryTip: string = "5e40128d-bfe2-4f8d-b7da-d187079f52c8";
    public static SendInvitationEmailToSignerTip: string = "3BBE8BE3-4B62-42E7-8299-0FDCA79B17F1";
    public static ChooseImage: string = "7B5DD33D-6547-447D-8C9B-05A38C50E0CC";
    public static UploadPictureSignature: string = "773A5C51-C1D4-45F4-9F77-E8FB6F24162D";
    public static DragandDropSignature: string = "39C12BB5-2480-4D5D-9B03-A4B40E7F04CA";
    public static UploadDocumentValidation: string = "E8B6D88B-F56F-449E-9BF3-F889ECDB6835";
    public static RedirectPostSigningLandingPage: string = "A43B475A-1180-4117-965C-53E65B4C1B82";
    public static ThankYouSessionTextOnConfirm: string = "04CF54CD-15C0-4D6C-96B8-6392EABD8350";
    public static MaxFileSize: string = "77D40282-883B-476D-B7FA-82244FA391EB";
    public static ConfirmEmailAddress: string = "A22CA1F9-F7ED-4412-9F55-1019105CD264";
    public static ConfirmYourIdentityText: string = "117E105A-D32B-4423-84AC-8AB816FE020F";
    public static RSignConfirmationMail: string = "5F3665F1-3E35-4D4E-9F4E-99023CBEBF34";
    public static AdditionalSignerRequiredDocument: string = "B9CEC1C9-9CC5-4CD4-A67C-23878A55FA37";
    public static DuplicateEmailsNotAllowed: string = "DB3F20AB-7654-4354-9D5A-2EC35449DDC1";
    public static Translations: string = "98D8401A-98D6-4231-8CF9-843870FEDFAE";
    public static AppKeyTip: string = "206A8DD0-A138-4453-B142-631D33F724B9";
    public static AppKey: string = "EF286951-843B-4749-9AC4-9768F74F6EF9";
    public static AttachPDFForCCRecipientsTip: string = "145CC8E3-4032-447B-A1B1-81D6E4D304F9";
    public static EnableCcOptions: string = "00D85A80-EA48-4A0E-BD36-5F3C699FBB8C";
    public static Copiedfrom: string = "1FC446B7-0C38-4A82-BC99-84E5D3117094";
    public static RuleDetails: string = "C03BBADB-16D0-4F6D-825A-5DD01358AD3A";
    public static Lang_TemplateDetails: string = "DECF0237-E4F7-4E5A-B8EA-95D7B6C3CC67";
    public static RSignMakesESignatureText: string = "EF529659-7036-4772-982C-0D7EF54A51F2";
    public static DisclaimerLocation: string = "9C56FD65-81F8-492C-B7FA-F15D6F788981";
    public static DisclaimerLocationTip: string = "9892C1BF-8233-443F-8505-D53F2529CEED";
    public static LearnMore: string = "b7c92495-5f50-4d67-ad3d-f3e83a68f71f";
    public static DefaultLandingPage: string = "ED788190-A3CF-4385-BAE4-038C91FD0F33";
    public static DefaultLandingPageTip: string = "0A038A9D-2607-41AD-B270-B5524DE0BCC0";
    public static SigningProcessCompleteComment: string = "14B0012C-6E59-47A1-A3A7-C948328C632B";
    public static FilingFinalSignedDocuments: string = "c2800372-86e8-43af-85dd-59a7b1126b7b";
    public static FinalSignedDocumentNameFormat: string = "952e067e-c763-4bff-a749-a0d9779c6e7d";
    public static SignatureCertificateDocumentFormat: string = "7490AD04-03D8-46A8-BEF7-93CB0402D909";
    public static SignedDocumentLocation: string = "8F5F9BDF-04D7-4152-860A-F6D65C6B6DA2";
    public static CheckOutComment: string = "C1BF45D6-4DA0-4F90-AA63-317B8BA15BAC";
    public static CheckInComment: string = "F52023FC-C3D5-48F8-B20D-EF681F921EA0";
    public static FilingFinalSignedDocumentOptionsTip: string = "93DF9DEE-3736-46AA-B497-AA3E4FF6A7CB";
    public static FilingFinalSignedDocumentOptions: string = "51FF8695-5716-4992-8322-BC7EAAB83D4D";
    public static CheckInCommentTip: string = "2A44E9C8-0B06-4055-B773-62BF0879AEBE";
    public static CheckOutCommentTip: string = "3A7564E2-9B90-4DD0-B967-370B9BFAECA0";
    public static SignedDocumentLocationTip: string = "AD8BDB06-7D28-4250-9AF0-0A6B8549506E";
    public static SignatureCertificateDocumentFormatTip: string = "9F47A993-D028-4BA8-99A6-DC7A12AC3178";
    public static FinalSignedDocumentNameFormatTip: string = "0223E425-0FB8-430E-8D11-605CCFB411C8";
    public static FilingFinalSignedDocumentsTip: string = "4B7E5148-828F-4D61-B33F-D33B2020A618";
    public static SigningProcessCompleteCommentTip: string = "AB176DE6-7501-4F4F-8DD5-5742BD68DA04";
    public static EnableRecipientLanguage: string = "68fd5876-629c-49ed-ac72-0468dfeb0dbf";
    public static EnableRecipientLanguageTip: string = "64ae8d33-c9a0-44e8-94dc-47046e236698";
    public static SignInWithLabel: string = "D2CC05B0-B4E5-4F57-A17A-4B6853B8FD1C";
    public static SingleSignOnLabel: string = "F793CB37-2985-484F-ADDF-D5AFE40F602E";
    public static Language: string = "F00A27CA-2E8A-4CAB-AA92-9CA579847348";
    public static RSignSingleSignerConfirmationMail: string = "CE42E1E3-745B-4765-82C9-54E4DB94350A";
    public static ResellerName: string = "470FE3BF-5361-4767-A328-FB9F30E05C51";
    public static ApiID: string = "30943731-7825-4D7C-B9FD-0AC04C7964CC";
    public static ApiIDTip: string = "8CCDCABC-0C62-4F29-B421-C46222C6E871";
    public static ApiKey: string = "FDA13640-4F8C-47FB-A201-9BC6325DF25B";
    public static IntegrationEmail: string = "C5A722DE-AF63-4425-867E-7679D37F419B";
    public static IntegrationEmailTip: string = "8DD14289-8267-4133-94EC-66D0EA22A203";
    public static ResellerNameTip: string = "338AB3A4-FCDB-4D15-A3AB-A350AB150494";
    public static CreateAutoReminders: string = "40CA02CE-FCE2-4E94-AA5A-79A2C71D8D98";
    public static GetStartedHere: string = "699d9c5c-715b-4f36-a582-612dcb65a8bd";
    public static DisplayAppliedEpicContacts: string = "502D3E84-7273-4E8B-869C-8192E6B3D88D";
    public static DisplayBullhornContacts: string = "72A2AD10-F2D9-402D-B76D-78A5278E1979";
    public static LearnMoreTextOnly: string = "30DAEF26-A701-4CB4-8C9D-9A63E5D760DB";
    public static CheckYourEmail: string = "A983F157-5788-4F47-9723-96DB485186FF";
    public static RememberToLookInYourText: string = "98189B0E-13C4-43AD-A7A8-F87D5B86C641";
    public static Spam: string = "15D6EF00-2823-4F56-997D-864CB2ECEA2C";
    public static Folder: string = "825AC9B5-D5FB-417F-86D2-B88D73907063";
    public static ExportAsCSV: string = "4A592E63-18C3-4BB6-AAFD-3C11F78C79B7";
    public static ApplicationURL: string = "4B934FBE-5AB1-467C-A0F9-58F1C453F4F3";
    public static APIServerURL: string = "6E4105BB-7646-49A2-9AAD-E289B69CBC13";
    public static ClientId: string = "4C62DF09-5EEB-4752-A6AB-6FEE985A1065";
    public static UnsignedTooltip: string = "62C261F7-DDDF-476C-A094-F68A162BE74C";
    public static SignedTooltip: string = "1BC914FC-4536-4D31-A292-3B2EAE835CC2";
    public static UnsignedAndSignedTooltip: string = "288B4A95-10CA-463C-A8D3-0A02E5E2E7D4";
    public static NotificationTooltip: string = "FF838BD8-D0C6-4347-9050-0CE7E4154B2D";
    public static NotificationAndSignedTooltip: string = "112BB3AC-DD18-49A7-A772-E7F9589E9E14";
    public static Region: string = "E4A389C4-3CD0-44BF-856A-B02672941BCF";
    public static RegionTooltip: string = "783832BD-C4FF-42A7-B414-528A29E8B2DF";
    public static CustomEntity: string = "D7A727F6-25AE-4FF7-880C-9D27BC0E2BD8";
    public static EntityFields: string = "954D609B-E686-4168-AD10-7AE8DA06C110";
    public static Awaiting: string = "73132718-1076-41D2-9202-FEE24C1B24E8";
    public static Details: string = "1B1F8664-9269-46F2-87E9-11E52CFB8CC0";
    public static AwaitingRecipients: string = "D9A0CCA5-038C-4A97-B23A-5805B93C2BC6";
    public static Auto: string = "A79AAC55-CD5C-4B18-8CDD-5A86F5AA0409";
    public static Manual: string = "83A42615-9388-4849-A5C0-06474E5E5AC8";
    public static RPortalStatus: string = "CB04EB61-B1FA-48CB-A9AF-F05E5AD3EB77";
    public static lang_SSORegMessage: string = "E47F90AD-032E-47D6-BAC0-6EDBB2BDB8A8";
    public static lang_SSOLoginMessage: string = "6AD865EB-DB35-469D-AD2E-C731E67F17FB";
    public static lang_SSOForgotPwdMessage: string = "47405858-D240-4264-B4A0-757C5EB376C6";
    public static lang_applicationError: string = "1694D5E7-6AE6-4758-B9D8-9934D0692361";
    public static ClickOnCreateEnvelope: string = "7716D373-5C3C-4722-B14A-FA9F4C824192";
    public static ClickOnCreateTemplate: string = "4896A9E7-244D-44B8-B816-38C35A68DD3B";
    public static BullhornNOTE: string = "18A22A55-42CD-4A7F-93CF-E39C0070803D";
    public static SelectTemplateToEdit: string = "5C5DED51-4697-4958-8C45-3CEE2439D225";
    public static SelectRuleToEdit: string = "A039742D-D825-412D-A081-77C94F8C5404";
    public static WelcometoRSign: string = "7FA3E417-0D94-41BD-A6C0-48FB891F65AE";
    public static ArchivingSoon: string = "7A4E788A-D0F3-475F-B8CE-1625F2185419";
    public static PasswordRequirements: string = "44CBC6F2-C35C-4A26-A5AE-D7644288558F";
    public static UpperCaseLetter: string = "EECD1225-ED24-45E8-993D-9C687CB8DC21";
    public static LowerCaseLetter: string = "551B6FAE-22D4-4CBC-BF4F-8A4A03BCCB83";
    public static OneNumber: string = "CAFDB262-E11B-4CA7-A718-53DB7009E23E";
    public static SpecialCharacter: string = "CBDF0959-1F3A-477D-865D-C567BA19BD40";
    public static CharactersInLength: string = "07560BB4-8EA7-4679-85BD-3CCFD0F6837D";

    public static lang_addName: string = "9B4D2B05-A4BB-4822-AF93-C6B5A10636FA";
    public static lang_addEmail: string = "2F71DA25-2BCE-4D84-BFE8-3EF6D345D03E";
    public static lang_invalidEmailAddress: string = "5C78379A-C472-47B8-98FA-E23F8E61F846";
    public static lang_under25MB: string = "5E3595B8-9D82-47CF-B491-AE23A4E57893";
    public static lang_IncompleteProcess: string = "75A108E1-B8D9-4B6C-8D58-8FCE2C3496D5";
    public static lang_VerifyYourEmailFirstSigner: string = "4870257D-34B3-48A9-B252-87D7ECC36421";
    public static lang_SlectOption: string = "6308473C-B431-4241-A588-142C5B69CA6A";
    public static lang_PleaseEnter: string = "665C2646-9818-455E-9D1F-3CF909077640";
    public static lang_SelectRadio: string = "05B398C1-E49C-4B63-92A0-A71C9FC8B84F";
    public static lang_SelectCheckbox: string = "9BF6ABAB-FAB9-4B1A-9408-4169A77F0762";
    public static lang_PleaseSelect: string = "F7B28788-2575-4D7C-9D1A-CF9171AF35D6";

    public static FilesforReview: string = "5965EE72-4A75-4668-8F89-F9BA93F9A458";
    public static DownloadAndReviewFiles: string = "08F02C00-CC7F-451A-920C-157EB7A36158";
    public static ConfirmFilesReviewed: string = "CF1B673C-20A4-442A-91F8-0050C598348F";
    public static DownloadFiles: string = "2E2D10EB-9E9A-4E9A-9466-9BDB682D6EF6";
    public static SendFinalSignDocumentChangeSigner: string = "69A5E504-6AB5-4871-887B-E1A5F3530AD5";

    public static EmailMobileRequiredConfirmation: string = "7E851868-367A-40DC-8EBA-5CF158BBFD48";
    public static MobileRequiredConfirmation: string = "F292001B-AA91-49E1-82DC-2BFCD0B9280D";
    public static lang_VerifyYourMobileFirstSigner: string = "C1F054B7-7FE1-48B0-946D-FA2F84DBE1CB";
    public static lang_VerifyYourEmailMobileFirstSigner: string = "A62137E3-73C2-4C64-A0AA-D339B2C3E892";
    public static ThankYouSessionTextOnMobileConfirm: string = "C7DFF58E-530E-4FA5-AB39-DE2C6D0F04EA";
    public static ThankYouSessionTextOnEmailMobileConfirm: string = "03BB8C92-EEC5-4884-917E-5545E0D8FBD1";
    public static MobilePaswordText: string = "82EF8EDF-64D9-4203-8130-0C5DBDD673BF";
    public static MobileEmailPasswordText: string = "2D569ED9-38E0-4E7F-85A2-9CCA8C84B8ED";
    public static InviteSignerHeaderText: string = "EA216AB3-4549-4C24-8C01-7885B0E8E026";
    public static SendConfirmationMobileText: string = "C13FB96E-3E4A-4006-A857-A3FEC0E5DA95";
    public static SendConfirmationEmailMobileText: string = "527EDA87-5534-4F68-8C0E-29459509F57A";
    public static ConfirmEmailOnly: string = "07A75514-EC57-42B0-B38C-38AEE36D2C82";
    public static ConfirmMobileText: string = "3E116D0C-DEDC-4F2D-A881-6E7BFF4B1F49";
    public static MultiSignerConfirmEmailMobileText: string = "CA3C3B74-5E6D-428A-AB31-9EFD46D83D2A";
    public static ConfirmEmailMobileText: string = "A8BB729A-4A47-48BB-BDF1-9D3601C9A459";
    public static ConfirmSingleSignerEmailOnly: string = "A42BD520-A8B4-4020-A0FB-35464AD4826A";
    public static SignerTermsCondEmailOrMobile: string = "49BB6191-3F3E-46E0-8437-C33772C46934";
    public static SignerTermsCondMobile: string = "A8822828-7E19-4297-A959-8D49CD584760";
    public static EmailPaswordText: string = "67FE0B83-C563-4240-9E8E-6D3A39385CEF";
    public static SendSingleSignerConfirmationEmailMobileText: string = "D65EB200-D15B-443A-A0E1-FDE471D31907";
    public static SendSingleSingnerConfirmationMobileText: string = "5315364D-5D9E-45D2-8488-A8C66B79E39A";
    public static SendConfirmMobileHeaderText: string = "53E588CD-6293-4825-8B41-B5DDDA4822B4";
    public static SendConfirmEmailMobileHeaderText: string = "2ECBE31C-72CE-4DAC-97FD-5B8A5AB420AC";
    public static lang_MobileNumber: string = "4670FE7E-0DF6-4234-964F-2CD6B7985373";

    public static DownloadFinalSignedDocText: string = "FB1A24BD-3B96-4EB7-946C-39BB7EC789FE";
    public static DocumentAlreadySignedText: string = "2391C14E-A914-4761-BA1E-16F27602B156";
    public static DownloadDocAfterSignedText: string = "BB0C81DD-8766-4AEB-92C6-93BC7F6131DB";
    public static Lang_DownloadFinalSignedDoc: string = "C55681A4-0E36-4F64-94E7-997385A4A68A";
    public static DocumentGenerationMessage: string = "DA9B9765-5116-4291-AAF7-B8AD40B0FE97";
    public static SignupDownloadFinalSignedDocText: string = "6B4597D8-EAD8-4725-A689-0FED9D40E74D";
    public static Lang_DeclineDownloadFinalSignedDoc: string = "4AE24E94-6D93-44BD-A20C-914C34D6284F";

    public static CountryCode: string = "FA034064-961C-406B-9ACE-07974408484A";
    public static Mobile: string = "C340A9BC-622A-4C39-85D4-BA311056D212";

    public static CopyEmailDownloadFinalDoc: string = "AA072DC9-C2B4-4085-B4E6-097D893BE651";

}

export class MessageTypes {
    public static Success: string = "Success";
    public static Error: string = "Error";
    public static Warning: string = "Warning";
    public static SuccessWarning: string = "SuccessWarning";
    public static SuccessErrorMessage: string = "SuccessErrorMessage";
    public static Expired: string = "Expired";
    public static SendConfirmationEmail: string = "SendConfirmationEmail";
    public static ArchivedOrPurged: string = "ArchivedOrPurged";
}

export class RouteUrls {
    public static SignDocument: string = 'sign-document';
    public static SignerLanding: string = 'signer-landing';
    public static SignMultiTemplate: string = 'sign-multi-template';
    public static AllowMultipleSigner: string = 'confirm-to-begin';
    public static PasswordWindow: string = 'password-window';
    public static Resend: string = 'resend';
    public static Expire: string = 'expire';
    public static Info: string = 'info';
    public static Result: string = 'result';
    public static StaticTemplateLanding: string = 'static-template-landing';
    public static StaticTemplate: string = 'static-template';
    public static SignerLandingStaticTemplate: string = 'signer-landing-static-template';
    public static AuthenticateSigner: string = 'authenticate-signer';
    public static ViewDocumentLanding: string = 'view-document';
    public static ConfirmToBegin: string = 'confirm-to-begin';
    public static ValidateSigner: string = 'validate-signer';
    public static GlobalError: string = 'global-error';
}

export class ReturnUrl {
    public static List: any = [
        'sign-document',
        'signer-landing',
        'sign-multi-template',
        'allow-multiple-signer',
        'password-window',
        'resend',
        'expire',
        'info',
        'result',
        'static-template-landing',
        'static-template',
        'get-allow-multiple-signer',
        'signer-landing-static-template',
        'authenticate-signer',
        'confirm-to-begin',
        'view-document',
        'validate-signer',
        'global-error',
        'rclick'
    ];
}

export class UploadDriveType {
    public static Google: string = 'Google';
    public static Dropbox: string = 'Dropbox';
    public static Skydrive: string = 'Skydrive';
}

export class StaticUrlWatingTime {
    public static StaticUrlWatingTimeInSeconds: string = '300';
}

export class RPostUrl {
    public static TermAndService: string = 'https://rpost.com/legal-notices/terms-and-conditions';
    public static PrivacyPolicy: string = 'https://rpost.com/legal-notices/privacy-notice';
}
export class Signer {
    public static Sent: string = "1B5D8208-B1BF-40E1-9FBE-1D18F8A87B44}";
    public static Viewed: string = "D30DBB92-E6D6-4729-93E2-BD02F56CEBE1}";
    public static Rejected: string = "B03B5387-50BB-49BE-92A0-0745C1136092}";
    public static Signed: string = "4F564EA5-009C-4F52-A3DE-C6D0AC598617}";
    public static Pending: string = "20D49160-82E6-48F2-93AF-CE78F86177D2}";
    public static Delegated: string = "3B229C22-16AE-48BB-AA06-CF05C78A60D7}";
    public static Incomplete_and_Expired: string = "468DF264-DD7A-41BE-A0AF-D0EC9B95001F}";
    public static Finish_Later_Selected: string = "D5ADF209-18AC-4D7E-985E-ECAE72307F2F}"; // Changed status to Finish_Later_Selected from Saved_As_Draft as per ticketID:S3-74
    public static AwaitingConfirmation: string = "51E85610-7671-4407-A4DE-2A2AA5851E89";
    public static Update_And_Resend: string = "B656B331-D44C-4489-9CB8-BB8DACB56682";
}

export class DateFormatString {
    public static US_mm_dd_yyyy_slash: string = "mm/dd/yyyy";
    public static US_mm_dd_yyyy_colan: string = "mm-dd-yyyy";
    public static US_mm_dd_yyyy_dots: string = "mm.dd.yyyy";
    public static Europe_mm_dd_yyyy_slash: string = "dd/mm/yyyy";
    public static Europe_mm_dd_yyyy_colan: string = "dd-mm-yyyy";
    public static Europe_mm_dd_yyyy_dots: string = "dd.mm.yyyy";
    public static Europe_yyyy_mm_dd_dots: string = "yyyy.mm.dd.";
    public static US_dd_mmm_yyyy_colan: string = "dd-mmm-yyyy";
}
export class DateFormatGuid {
    public static US_mm_dd_yyyy_slash: string = "E3DAD8D9-E16F-40F5-B112-BBA204538136";
    public static US_mm_dd_yyyy_colan: string = "9FC73C2B-34D7-42A1-B2A6-702ED2FD312B";
    public static US_mm_dd_yyyy_dots: string = "BDA0023F-AFC1-46E5-A134-884EDCA48799";
    public static Europe_mm_dd_yyyy_slash: string = "577D1738-6891-45DE-8481-E3353EB6A963";
    public static Europe_mm_dd_yyyy_colan: string = "6685D1ED-60D2-4028-94E9-BC875C2E551D";
    public static Europe_mm_dd_yyyy_dots: string = "374FE10E-4313-4541-B3CB-627310A14499";
    public static Europe_yyyy_mm_dd_dots: string = "8FAC284C-AB19-456C-BC73-1CE0D66D7220";
    public static US_dd_mmm_yyyy_colan: string = "7F472F00-CD12-443E-B38D-085F8872115F";
}
export class ActionTypes {
    public static Sign: string = "Sign";
    public static Review: string = "Review";
}
export class AttachSignedPDF {
    public static SenderOnly = "CDFC7178-03C4-4864-B3FB-097AA2335557";
    public static SenderSigner = "AAFB0BBB-21DC-4AAD-816F-6814B191CEDC";
    public static RetrievalLink = "037CC450-5065-4DC1-BF01-DA12637C4FB8";
    public static SenderOnlyandNoemailtoRecipient: string = "6F0CC529-F418-4A33-852C-3E470A150DD9";
    public static SenderRetrievallinkandNoemailtoRecipient: string = "EA92FA10-71FC-445E-B5D9-50E317A9F7A1";
    public static SenderOnlyandRecipientSignIn = "EA92FA10-71FC-445E-B5D9-50E317A9F7A1";
    public static SenderRetrievallinkandRecipientSignIn = "461EEB77-4DFF-44B9-B2A0-00A5DD015B5D";

}
export class EnvelopeStatus {
    public static Incomplete_and_Expired = "2A8C3F8D-B512-43A6-8579-00BFF4EFE546";
    public static Terminated = "EB0BB5BD-DADA-4C37-AD2F-704CE4992E1C";
    public static Completed = "C9596319-6F6B-4840-B49D-85010206C7C7";
    public static Waiting_For_Signature = "63E17398-AFDC-48C7-B9EC-90582CFEB562";
    public static CancelledTransaction = "3AC65996-8529-4415-B895-54F27B3CC609";
}
export class EnvelopeStatusText {
    public static EnvelopeAccepted = "EnvelopeAccepted";
    public static Completed = "ContactSender";
    public static Declined = "Declined";
}

export class EmailAddressFormat {
    public static ValidEmail = /^(?!\.)+(?!.*?\.\.)+([a-zåäöÅÄÖ\d_!#$&%'*+-/=?^{}`|~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-zåäöÅÄÖ\d_!#$&%'*.+-/=?^{}`|~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-zåäöÅÄÖ\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-zåäöÅÄÖ\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-zåäöÅÄÖ\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-zåäöÅÄÖ\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-zåäöÅÄÖ\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-zåäöÅÄÖ\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-zåäöÅÄÖ\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-zåäöÅÄÖ\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
}

export class MessageDeliveryModes {
    public static PrefillNA = "NA";
    public static EmailSlashEmail = "Email / Email";
    public static EmailSlashMobile = "Email / Mobile";
    public static EmailSlashEmailAndMobile = "Email / Email & Mobile";
    public static MobileSlashMobile = "Mobile / Mobile";
    public static MobileSlashEmail = "Mobile / Email";
    public static MobileSlashNone = "Mobile / None";
    public static MobileSlashEmailAndMobile = "Mobile / Email & Mobile";
    public static EmailAndMobileSlashMobile = "Email & Mobile / Mobile";
    public static EmailAndMobileSlashEmail = "Email & Mobile / Email";
    public static EmailAndMobileSlashEmailAndMobile = "Email & Mobile / Email & Mobile";
    public static EmailSlashNone = "Email / None";
    public static EmailAndMobileSlashNone = "Email & Mobile / None";
}

export class DeliveryModes {
    public static EmailSlashEmail = "1";
    public static EmailSlashMobile = "2";
    public static EmailSlashEmailAndMobile = "3";
    public static MobileSlashMobile = "4";
    public static MobileSlashEmail = "5";
    public static MobileSlashNone = "6";
    public static MobileSlashEmailAndMobile = "7";
    public static EmailAndMobileSlashMobile =" 8";
    public static EmailAndMobileSlashEmail = "9";
    public static EmailAndMobileSlashEmailAndMobile = "10";
    public static EmailSlashNone = "11";
    public static EmailAndMobileSlashNone = "12";
}