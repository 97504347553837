import { Component, OnInit } from '@angular/core';
import { SignerLandingService } from '../../services/signer-landing.service';
import { CommonService } from '../../services/common.service';
import { Router } from '@angular/router';
import { CommonConstants, MessageTypes, RecipientType, RouteUrls } from '../../constants/common-constants';
import { TranslationsService } from '../../services/translations.service';

@Component({
  selector: 'app-static-link',
  templateUrl: './static-link.component.html',
  styleUrls: ['./static-link.component.scss']
})
export class StaticLinkComponent implements OnInit {

  responseSigningUrlModel: any;
  documentControlsResponse: any;
  commonConstants = CommonConstants;
  isDirect: string = '';
  isFromInbox: boolean = false;
  isFromSignMultiTemplate: boolean = false;
  showLoader: boolean = false;

  constructor(private router: Router, private signerLandingService: SignerLandingService, private commonService: CommonService,
    private translationsService: TranslationsService) { }

  ngOnInit(): void {
    this.showLoader = true;
    localStorage.clear();
    // sessionStorage.clear();
    sessionStorage.removeItem("SignerProfileDetails");
    sessionStorage.removeItem("isSignerpopupCount");
    sessionStorage.removeItem("LaungaueTransalation");
    sessionStorage.removeItem("LanguageValidation");
    localStorage.setItem("documentControlsResponse", '');
    localStorage.setItem("responseSigningUrlModel", '');
    let actualStaticSigningUrl: any = window.location.href;
    localStorage.setItem("actualStaticSigningUrl", actualStaticSigningUrl);
    actualStaticSigningUrl = actualStaticSigningUrl.substring(0, actualStaticSigningUrl.length - 1);
    let signingUrl = decodeURI(actualStaticSigningUrl.split('?')[1]);
    // console.log('SigningUrl', signingUrl);
    if (signingUrl == undefined || signingUrl == "undefined" || signingUrl == null) {
     // localStorage.setItem("encryptedSender", '');
      localStorage.setItem("DisplayMessage", CommonConstants.InvalidUrl);
      localStorage.setItem("MessageType", "Warning");
      this.commonService.getReturnUrlDirection(RouteUrls.Info);
    }
    else {
      this.getDecryptStaticLinkSigningUrl(signingUrl);
    }
  }

  getDecryptStaticLinkSigningUrl(signingUrl: string) {
    this.showLoader = true;
    let decryptStaticLinkSigningUrl = this.commonConstants.DecryptStaticLinkSigningUrl;
    let paramsObj = { SigningUrl: signingUrl };
    this.signerLandingService.getDecryptSigningData(decryptStaticLinkSigningUrl, paramsObj).subscribe(
      (resp: any) => {
        if (resp && resp.StatusCode == 200 && resp.StatusMessage.toLowerCase() == "ok" && resp.ResponseSigningUrlModel) {
          this.showLoader = true;
          this.responseSigningUrlModel = resp.ResponseSigningUrlModel;
          localStorage.setItem("EnvelopeID", this.responseSigningUrlModel.EnvelopeID);
          localStorage.setItem("RecipientID", this.responseSigningUrlModel.RecipientID);
          localStorage.setItem("EmailId", this.responseSigningUrlModel.EmailId);
          localStorage.setItem("CopyMailId", this.responseSigningUrlModel.CopyEmailId);         

          let languageTranslations: any = resp.LanguageTranslationsModel;          
          sessionStorage.setItem("LaungaueTransalation", JSON.stringify(languageTranslations.Language));
          sessionStorage.setItem("LanguageValidation", JSON.stringify(languageTranslations.LanguageValidation));
          resp.LanguageTranslationsModel = null;
          localStorage.setItem("responseSigningUrlModel", JSON.stringify(this.responseSigningUrlModel));

          this.getInitializeStaticSignerSignDocument(resp.ResponseSigningUrlModel);
        }
        else if (resp && resp.originalError && resp.originalError.error && resp.originalError.error.StatusCode == 400) {
          this.showLoader = false;
          localStorage.setItem("DisplayMessage", resp.originalError.error.StatusMessage);
          localStorage.setItem("MessageType", "Warning");
          this.commonService.getReturnUrlDirection(RouteUrls.Info);
        }
        else {
          this.showLoader = false;
          localStorage.setItem("DisplayMessage", "An Application error occurred.");
          localStorage.setItem("MessageType", "Error");
          localStorage.setItem("IsApplicationError", "true");
          localStorage.setItem("PageName", "DecryptStaticLink");
          this.commonService.getReturnUrlDirection(RouteUrls.Info);
        }
        this.showLoader = false;
      })
  }

  getInitializeStaticSignerSignDocument(paramsObj: any) {
    this.showLoader = true;
    let signDocumentUrl = this.commonConstants.InitializeStaticTemplate;
    let requestObj = {
      "EnvelopeId": paramsObj.EnvelopeID,
      "templateKey": paramsObj.TemplateKey,
      "IPAddress": ''
    };

    this.signerLandingService.getSignerSignDocumentData(signDocumentUrl, requestObj).subscribe(
      (resp: any) => {
        // console.log('res', resp)
        if (resp && resp.StatusCode == 200 && resp.StatusMessage.toLowerCase() == "ok") {
          this.showLoader = false;
          this.documentControlsResponse = resp;
          let cultureInfo: string = '';
          if (this.documentControlsResponse.EnvelopeInfo.CultureInfo == "") cultureInfo = "en-us";
          else cultureInfo = this.documentControlsResponse.EnvelopeInfo.CultureInfo;
          localStorage.setItem("cultureInfo", cultureInfo);
          this.getLanguageTranslations();
          setTimeout(() => {
            this.showLoader = true;

            let respEnvelopeImageControlData: any = this.documentControlsResponse.EnvelopeImageControlData;
           // let respLanguage: any = this.documentControlsResponse.Language;
            let respCheckListData: any = this.documentControlsResponse.CheckListData;
            let respUserdata: any = this.documentControlsResponse.userdata;
            let respEnvelopeInfo: any = this.documentControlsResponse.EnvelopeInfo;           
            localStorage.setItem("EnvelopeImageControlData", JSON.stringify(respEnvelopeImageControlData));          
            localStorage.setItem("CheckListData", JSON.stringify(respCheckListData));
            localStorage.setItem("Userdata", JSON.stringify(respUserdata));    
            localStorage.setItem("EnvelopeInfo", JSON.stringify(this.documentControlsResponse.EnvelopeInfo));
            this.documentControlsResponse.EnvelopeImageControlData = null;
            this.documentControlsResponse.Language = null;
            this.documentControlsResponse.CheckListData = null;
            this.documentControlsResponse.userdata = null;
            this.documentControlsResponse.EnvelopeInfo = null;
            localStorage.setItem("documentControlsResponse", JSON.stringify(this.documentControlsResponse));
            this.documentControlsResponse.EnvelopeImageControlData = respEnvelopeImageControlData;
           this.documentControlsResponse.Language = null;
            this.documentControlsResponse.CheckListData = respCheckListData;
            this.documentControlsResponse.userdata = respUserdata;            
            this.documentControlsResponse.EnvelopeInfo = respEnvelopeInfo;

            localStorage.setItem("isStatic", this.documentControlsResponse.EnvelopeInfo.IsStatic);
            localStorage.setItem("prefill", this.documentControlsResponse.Prefill);
            localStorage.setItem("senderEmail", this.documentControlsResponse.EnvelopeInfo.SenderEmail);
            localStorage.setItem("recipientEmail", this.documentControlsResponse.EnvelopeInfo.RecipientEmail);
           // localStorage.setItem("encryptedGlobalEnvelopeID", this.documentControlsResponse.EncryptedGlobalEnvelopeID);
           // localStorage.setItem("encryptedGlobalRecipientID", this.documentControlsResponse.EncryptedGlobalRecipientID);
            localStorage.setItem("currentRecipientID", this.documentControlsResponse.CurrentRecipientID);
           // localStorage.setItem("encryptedSender", this.documentControlsResponse.EncryptedSender);
            localStorage.setItem("isPasswordMailToSigner", this.documentControlsResponse.IsPasswordMailToSigner);
            localStorage.setItem("isStaticPwd", this.documentControlsResponse.EnvelopeInfo.PasswordReqdtoSign);
            localStorage.setItem("recipientEmail", this.documentControlsResponse.EnvelopeInfo.RecipientEmail);
            localStorage.setItem("recipientTypeId", this.documentControlsResponse.EnvelopeInfo.RecipientTypeId);
            localStorage.setItem("eDisplayCode", this.documentControlsResponse.EnvelopeInfo.GlobalEnvelopeID);
           // localStorage.setItem("passwordReqdtoSign", this.documentControlsResponse.EnvelopeInfo.PasswordReqdtoSign);
            localStorage.setItem("passwordReqdtoOpen", this.documentControlsResponse.EnvelopeInfo.PasswordReqdtoOpen);
           // localStorage.setItem("maxUploadID", this.documentControlsResponse.MaxUploadID);
            localStorage.setItem("rolsInfos", JSON.stringify(this.documentControlsResponse.TemplateRolesInfo));
            localStorage.setItem("DialCodeDropdownList", JSON.stringify(this.documentControlsResponse.DialCodeDropdownList));
            localStorage.setItem("IsSMSAccessCode", this.documentControlsResponse.IsSMSAccessCode);

            if (this.documentControlsResponse && this.documentControlsResponse.TemplateRolesInfo && this.documentControlsResponse.TemplateRolesInfo.length > 0) {
              localStorage.setItem("rolesCount", this.documentControlsResponse.TemplateRolesInfo.length);
            }
            else {
              localStorage.setItem("rolesCount", "0");
            }

            if ((this.documentControlsResponse.EnvelopeInfo.IsStatic == true && this.documentControlsResponse.IsDefaultSignatureForStaticTemplate == false) ||
              (this.documentControlsResponse.IsSharedTemplateContentUnEditable == true && this.documentControlsResponse.IsDefaultSignatureForStaticTemplate == false)) {
              let isStaticTemplate: any = true;
              localStorage.setItem("isStaticTemplate", isStaticTemplate);
            }

            //Based on response,need to redirect to other pages
            this.commonService.getReturnUrlDirection(RouteUrls.ConfirmToBegin);
            this.showLoader = false;
          }, 50);
        }
        else if (resp && resp.originalError && resp.originalError.error && (resp.originalError.error.StatusCode == 400 || resp.originalError.error.StatusCode == 204)) {
          let encryptedSender: string = resp.originalError.error.EncryptedSender;
          //localStorage.setItem("encryptedSender", encryptedSender);
          localStorage.setItem("DisplayMessage", resp.originalError.error.Message);
          localStorage.setItem("MessageType", "Warning");
          if (resp.originalError.error.ErrorAction == "Resend") {
            this.commonService.getReturnUrlDirection(RouteUrls.Info);
          }
          else if (resp.originalError.error.ErrorAction == "Expire") {
            resp.originalError.error.Message == "" ? localStorage.setItem("DisplayMessage", "This envelope is expired.") : localStorage.setItem("DisplayMessage", resp.originalError.error.Message);
            this.commonService.getReturnUrlDirection(RouteUrls.Info);
          }
          else if (resp.originalError.error.ErrorAction == "EnvelopeAccepted" || resp.originalError.error.ErrorAction == "ContactSender") {
            resp.originalError.error.Message == "" ? localStorage.setItem("DisplayMessage", "This document has already been signed and returned to the sender.") : localStorage.setItem("DisplayMessage", resp.originalError.error.Message);
            localStorage.setItem("DisplayMessage", resp.originalError.error.Message);
            localStorage.setItem("MessageType", MessageTypes.Success);
            this.commonService.getReturnUrlDirection(RouteUrls.Info);
          }
          else if (resp.originalError.error.ErrorAction == "StaticLinkDisabled" ||
            resp.originalError.error.ErrorAction == "StaticLinkDocExpired") {
            localStorage.setItem("DisplayMessage", resp.originalError.error.Message);
            localStorage.setItem("MessageType", MessageTypes.Warning);
            this.commonService.getReturnUrlDirection(RouteUrls.Info);
          }
          else {
            if (resp.originalError.error.Message == "") {
              localStorage.setItem("MessageType", "Error");
              localStorage.setItem("DisplayMessage", "An Application error occurred.")
              localStorage.setItem("IsApplicationError", "true");
              localStorage.setItem("PageName", "InitializeStaticTemplate");
              localStorage.setItem("EnvelopeID", paramsObj.EnvelopeID);
              localStorage.setItem("EmailId", paramsObj.EmailId);
            }
            else {
              localStorage.setItem("DisplayMessage", resp.originalError.error.Message);
            }

            this.commonService.getReturnUrlDirection(RouteUrls.Info);
          }
          this.showLoader = false;
        }
        else {
          this.showLoader = false;
         // localStorage.setItem("encryptedSender", "");
          localStorage.setItem("MessageType", "Warning");
          localStorage.setItem("IsApplicationError", "true");
          localStorage.setItem("PageName", "InitializeStaticTemplate");
          localStorage.setItem("DisplayMessage", CommonConstants.InvalidUrl);
          localStorage.setItem("EnvelopeID", paramsObj.EnvelopeID);
          localStorage.setItem("EmailId", paramsObj.EmailId);
          this.commonService.getReturnUrlDirection(RouteUrls.Info);
        }
      });
  }

  getLanguageTranslations() {
    if (sessionStorage.getItem('LaungaueTransalation') == null || sessionStorage.getItem('LanguageValidation') == null
      || sessionStorage.getItem('LaungaueTransalation') == undefined || sessionStorage.getItem('LanguageValidation') == undefined) {
      this.translationsService.getLanguageTranslations(null, null);
      // this.translationsService.getLanguageTranslations();
    }
  }

}
