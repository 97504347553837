<app-header></app-header>
<main>
    <div class=" main-container ">
        <div class="container">
            <div class="row">
                <div class="wrapper fadeInDown">
                    <div id="formContent" >
                        <div class="front-arrow">
                            <h4>{{getLanguageTranslationValue(resourceKeys.EnterPassword)}}</h4>
                            <label class="form-field mt-3">
                                <input type="{{showPassword ? 'text' : 'password' }}" class="inp-full-width cursor txtDocPassword headerSpan"
                                    placeholder="{{getLanguageTranslationValue(resourceKeys.DocumentPassword)}}" ondrop="return false"
                                    spellcheck="false" id="txtDocPassword" autocomplete="off" (keyup.enter)="ValidatePassword()">
                                <i class="fa fa-key keyicon" aria-hidden="true"></i>
                                <i class="{{showPassword ? 'fas fa-eye-slash' : 'fas fa-eye' }}" class="fa eyeicon" aria-hidden="true"
                                (click)="togglePassword()"></i>
                            </label>
                        </div>
                        <div class="form-buttons mt-1 mb-3">
                            <button id="btnSubmit" type="submit" class="btn btn-full-width cursorpoint btn-green"
                                data-key-config="Submit" (click)="ValidatePassword()">{{getLanguageTranslationValue(resourceKeys.Submit)}}</button>
                        </div>

                    </div>

                    <div id="divSuccess" class="formContent" style="display: none;text-align: center" >
                        <img src="../../../assets/images/success_info.png" class="success-img">
                        <h4 class="sucess-text">{{getLanguageTranslationValue(resourceKeys.Success)}}!</h4>
                        <div class="front-arrow d-flex justify-content-center" >
                            <label class="form-field" style="color: #000;margin-left:10px;">
                                <strong style="color:#c3272e;"><i class="fa fa-arrow-circle-o-right" aria-hidden="true"></i> </strong> {{getLanguageTranslationValue(resourceKeys.Redirecting)}}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>

<footer style="background: #eee;" class="default-footer mt-auto pt-2 pb-2">
    <div class="container1" style="font-size: 10px;">
        <div  class="fotertext" [innerHtml]="htmlFooterString"></div>
    </div>
</footer>

<div *ngIf="showLoader" class="loader">
</div>