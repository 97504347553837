<app-header></app-header>
<main class="container-login100">
    <div class="wrapperIndex">
        <div class="dvRadius">
            <div class="divMainWrapper user_card">
                <div id="formContent" class="dvglobalInfo_2">
                    <div *ngIf="isSuccessFlag && !isFromFinishLater" class="dvdevices">
                        <img src="../../../assets/images/success_info.png" style="width:70px;" />
                        <h5 class="Globalinfo_1 mt-3 mb-0 font-w600">{{displayMessage}}</h5>
                        <div *ngIf="AllowSignersDownloadFinalContract">
                           
                            <p *ngIf="!envelopeCompleted && isDelegated != 'true' && IsSendConfirmEmailPopupOpen != 'true'" class="ptextAlign">                               
                                {{downloadDocAfterSignedText}}
                            </p>

                            <p *ngIf="isDisplaySignUpButton && envelopeCompleted" class="ptextAlign">
                               {{signupDownloadFinalSignedDocText}}
                            </p>

                            <p *ngIf="isDisplayLoginButton && envelopeCompleted" class="ptextAlign">
                                {{downloadFinalSignedDocFromCompletedFolderText}}
                            </p>
                        </div>
                    </div>
                    <div *ngIf="isFromFinishLater" class="dvdevices">
                        <img *ngIf="isFromSessionExpired !== '1'" src="../../../assets/images/success_info.png"
                            style="width:70px;" />
                        <img *ngIf="isFromSessionExpired === '1'" src="../../../assets/images/success_info.png"
                            style="width:70px;" />
                        <h5 class="Globalinfo_1 mt-3  mb-0 font-w600">
                            <h5 *ngIf="isFromSessionExpired === '1'" style="font-weight: bold;color:#000;">Session
                                Expired.</h5>
                            <div [innerHtml]="displayMessage"></div>
                        </h5>
                    </div>
                    <div *ngIf="isWarningFlag" class="dvdevices">                       
                        <img src="../../../assets/images/success_info.png" style="width:70px;" />                       
                        <h5 class="Globalerror_1 mt-3 mb-0 font-w600">{{displayMessage}}</h5>
                    </div>
                    <div *ngIf="isErrorFlag" class="dvdevices">    
                        <img src="../../../assets/images/success_info.png" style="width:70px;" />
                        <h5 class="Globalerror_1 mt-3 mb-0 font-w600">Oops! we are having trouble processing your request.</h5>

                    </div>
                    <div *ngIf="isSuccessWarning" class="dvdevices">
                        <img src="../../../assets/images/success_info.png" style="width:70px;" />
                        <h5 class="Globalinfo_1 mt-3 mb-0 font-w600">{{displayMessage}}</h5>
                    </div>
                    <div *ngIf="isSuccessErrorMessage" class="dvdevices">
                        <img src="../../../assets/images/success_info.png" style="width:70px;" />
                        <h5 class="Globalerror_1 mt-3 mb-0 font-w600">{{displayMessage}}</h5>
                    </div>
                    <div *ngIf="isApplicationErrorFlag" class="dvdevices">                       
                        <img src="../../../assets/images/success_info_warning.png" style="width:70px;" />
                        <div class="d-flex flex-column mt-3">
                            <h5 class="Globalerror_1 mb-0 font-w600">Oops! we are having trouble processing your request.</h5>                          
                        </div>
                    </div>

                    <div *ngIf="!isDisplayLoginButton && !isDisplaySignUpButton">
                        <p *ngIf="isExpired" class="ptextAlign">
                            {{eSignExpiredFeatureList}}
                        </p>
                        <p *ngIf="isExpired" class="pserviceFeature">
                            {{eSignExpiredServiceFeature}}
                        </p>

                        <p *ngIf="isSendConfirmationEmail" class="ptextAlign" style="text-align:left;">
                            {{RSignMakesESignatureText}}
                        </p>

                        <p *ngIf="(isFromFinishLater || !AllowSignersDownloadFinalContract || AllowSignersDownloadFinalContract) && !isSendConfirmationEmail && !isExpired"
                            class="ptextAlign">
                            {{RSignMakesESignatureText}}
                        </p>
                    </div>
                </div>

                <div class="dvglobalInfo_3 form-buttons">
                    <button *ngIf="isDisplayLoginButton" id="btnSubmit" type="submit"
                        class="btn btn-full-width cursorpoint btn-green" data-key-config="Submit"
                        style="background-color: #3ab16d;color: white;border-radius: 5px;width: 100%;"
                        (click)="onSignin()">{{SignInText}}</button>
                    <button *ngIf="isDisplaySignUpButton" id="btnSubmit" type="submit"
                        class="btn btn-full-width cursorpoint btn-green" data-key-config="Submit"
                        style="background-color: #3ab16d;color: white;border-radius: 5px;width: 100%;"
                        (click)="onSignup()">{{SignUpText}}</button>
                </div>

                <div class="dvglobalInfo_3">
                    <p *ngIf="isExpired && !isApplicationErrorFlag" class="pbottomeStyle">
                        <i class="fa fa-play-circle faCircleIcon"></i> &nbsp; <a class="anchorColor"
                            style="cursor: pointer;" (click)="redirectCorporateURL('RSignSignUp','self')"
                            (ontouchstart)="redirectCorporateURL('RSignSignUp','self')">
                            {{LearnMoreTextOnly}}
                        </a>
                    </p>
                    <p *ngIf="isExpired && !isApplicationErrorFlag" class="pbottomeStyle">
                        <i class="fa fa-play-circle faCircleIcon"></i> &nbsp; <a class="anchorColor"
                            style="cursor: pointer;" (click)="redirectCorporateURL('FreeTrail','self')"
                            (ontouchstart)="redirectCorporateURL('FreeTrail','self')">
                            {{freeTrial}}
                        </a>
                    </p>

                    <input *ngIf="isSendConfirmationEmail" type="hidden" id="hdnLandingPageUrl"
                        value="@ViewBag.LandingPageUrl" />
                    <p *ngIf="isSendConfirmationEmail && !isApplicationErrorFlag" class="pbottomeStyle">
                        <i class="fa fa-play-circle faCircleIcon"></i> &nbsp; <a class="anchorColor"
                            style="cursor: pointer;" (click)="redirectCorporateURL('RSignTrainingVideos','self')"
                            (ontouchstart)="redirectCorporateURL('RSignTrainingVideos','self')"> {{LearnMoreTextOnly}}
                        </a>
                    </p>

                    <p *ngIf="isSuccessWarning && isSendConfirmationEmail" class="plswait"
                        style="font-weight:bold;padding-top:10px"> {{RedirectPostSigningLandingPage}}
                        <span id="countdown" class="timer" style="color:red;"></span>
                        {{Sessionpopupseconds}}
                    </p>

                    <p *ngIf="notExpiredAndEmail && !isApplicationErrorFlag" class="pbottomeStyle">
                        <i class="fa fa-play-circle faCircleIcon"></i> &nbsp; <a class="anchorColor"
                            style="cursor: pointer;" (click)="redirectCorporateURL('RSignSignUp','self')"
                            (ontouchstart)="redirectCorporateURL('RSignSignUp','self')"> {{LearnMoreTextOnly}} </a>
                    </p>

                    <p *ngIf="notExpiredAndEmail && !isApplicationErrorFlag" class="pbottomeStyle">
                        <i class="fa fa-play-circle faCircleIcon"></i> &nbsp; <a class="anchorColor"
                            style="cursor: pointer;" (click)="redirectCorporateURL('FreeTrail','self')"
                            (ontouchstart)="redirectCorporateURL('FreeTrail','self')"> {{freeTrial}} </a>
                    </p>

                </div>

                <div id="formContent" class="dvglobalInfo_2">
                    <p *ngIf="isApplicationErrorFlag" class="ptextAlign" style="font-size: 13px;">
                        {{this.DateTimeMessage1}}
                        {{this.DateTimeMessage2}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</main>

<footer style="background: #eee;" class="default-footer mt-auto pt-2 pb-2">
    <div style="font-size: 10px;">
        <div class="fotertext" [innerHTML]="htmlFooterString">
        </div>
    </div>
</footer>

<div *ngIf="showLoader" class="loader">
</div>