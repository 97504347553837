<div *ngIf="showLoader" class="loader">
</div>

<!-- newly added model -->
<div>
    <!-- Modal Header -->
    <div class="modal-header bg-modal modalhead-padding">
        <h4 class="modal-title">Add Initials</h4>
        <div class="d-flex align-items-center">
            <span class="material-icons modal-close" aria-label="Close" (click)="onClosePopup()">
                close
            </span>
        </div>
    </div>

    <!-- Modal body -->
    <div class="modal-body modal-bodybg pt-0" style="margin-top:10px;">
        <div>
            <nav>
                <div class="nav nav-tabs modal-tabs mb-3 d-flex justify-content-between" id="nav-tab" role="tablist">
                    <div class="d-flex align-items-center">
                        <button class="nav-link ms-0 active d-flex align-items-center" id="select-type-tab"
                            data-bs-toggle="tab" data-bs-target="#select-type" type="button" role="tab"
                            aria-controls="select-type" aria-selected="false" style="font-size: 14px;" title="Filter"
                            (click)="onSignatureType()">
                            Type
                        </button>

                        <button class="nav-link ms-0 d-flex align-items-center" id="select-draw-tab" data-bs-toggle="tab"
                            data-bs-target="#select-draw" type="button" role="tab" aria-controls="select-draw"
                            aria-selected="true" style="font-size: 14px;" (click)="onSignatureHandDrawn()">
                            Draw
                        </button>
                    </div>
                </div>
            </nav>
            <div class="tab-content border-top-0 border-bottom-0 bg-white w-100 h-100" id="nav-tabContent">
                <div style="overflow: hidden;" class="tab-pane fade active show" id="select-type" role="tabpanel" aria-labelledby="select-type-tab">
                    <div *ngIf="initialsContainer && !showPolishInitialDiv" id="dvDefaultSignatureFonts">
                        <div class="mb-2 mt-0 border-boxsize">
                            <label for="defaultInitialsName" class="form-label label-font14">Type Initials</label>
                            <input *ngIf="typeSignature" type="text" style="" [(ngModel)]="initialsInputText" autocomplete="off"
                                class="form-control typeSignature" placeholder="Type your initials" id="defaultInitialsName"
                                value="" maxlength="4" [ngStyle]="{'display':newInitsignatureTypeDisplay}"
                                (keyup)="UpdateInitialText($event)">
                        </div>
                        <p style="margin-bottom: 2px;">
                            <label class="form-label label-font14">Select Initials</label>
                        </p>
                        <ul class="newInitialsignatureType" style="display :block;padding:0px;overflow: hidden;margin-bottom: 5px;">
                            <div class="d-flex align-items-center res-selfstart me-1">
                                <li class="lifontchange HomemadeApple" (click)="onSignatureSelection(0, $event)">
                                    <input type="radio" id="HomemadeApple" name="newInitialsignSelect" class="initControl">
                                    <label for="HomemadeApple" class="HomemadeApple"><span></span>
                                        <i *ngIf="initialsText.length === 0" class="signatureText newInitialsignatureText">
                                            Int.</i>
                                        <i *ngIf="initialsText.length !== 0" class="signatureText newInitialsignatureText">
                                            {{initialsText}}</i>
                                    </label>
                                </li>
                                <li class="lifontchange DawningofaNewDay" (click)="onSignatureSelection(1, $event)">
                                    <input type="radio" id="DawningofaNewDay" name="newInitialsignSelect"
                                        class="initControl">
                                    <label for="DawningofaNewDay" class="DawningofaNewDay"><span></span>
                                        <i *ngIf="initialsText.length === 0" class="newInitialsignatureText signatureText">Int.</i>
                                        <i *ngIf="initialsText.length !== 0" class="newInitialsignatureText signatureText">{{initialsText}}</i>
                                    </label>
                                </li>
                                <li class="lifontchange Kristi" (click)="onSignatureSelection(2, $event)">
                                    <input type="radio" id="Kristi" name="newInitialsignSelect" class="initControl">
                                    <label for="Kristi" class="Kristi"><span></span>
                                        <i *ngIf="initialsText.length === 0" class="newInitialsignatureText signatureText">Int.</i>
                                        <i *ngIf="initialsText.length !== 0" class="newInitialsignatureText signatureText">{{initialsText}}</i>
                                    </label>
                                </li>
                            </div>
                            <div class="d-flex align-items-center res-selfstart me-1">
                                <li class="lifontchange LaBelleAurore" (click)="onSignatureSelection(3, $event)">
                                    <input type="radio" id="LaBelleAurore" name="newInitialsignSelect" class="initControl">
                                    <label for="LaBelleAurore" class="LaBelleAurore"><span></span>
                                        <i *ngIf="initialsText.length === 0" lass="newInitialsignatureText signatureText">Int.</i>
                                        <i *ngIf="initialsText.length !== 0" class="newInitialsignatureText signatureText">{{initialsText}}</i>
                                    </label>
                                </li>
                                <li class="lifontchange OvertheRainbow" (click)="onSignatureSelection(4, $event)">
                                    <input type="radio" id="OvertheRainbow" name="newInitialsignSelect" class="initControl">
                                    <label for="OvertheRainbow" class="OvertheRainbow"><span></span>
                                        <i *ngIf="initialsText.length === 0" class="newInitialsignatureText signatureText">Int.</i>
                                        <i *ngIf="initialsText.length !== 0" class="newInitialsignatureText signatureText">{{initialsText}}</i>
                                    </label>
                                </li>
                                <li class="lifontchange Brush" (click)="onSignatureSelection(5, $event)">
                                    <input type="radio" id="Brush" name="newInitialsignSelect" class="initControl">
                                    <label for="Brush" class="Brush"><span></span>
                                        <i *ngIf="initialsText.length === 0" class="newInitialsignatureText signatureText">Int.</i>
                                        <i *ngIf="initialsText.length !== 0" class="newInitialsignatureText signatureText">{{initialsText}}</i>
                                    </label>
                                </li>
                            </div>
                        </ul>
                        <div class="form-group mt-n2 res-mb10 border-boxsize">
                            <label for="txtsignerProfileName" class="form-label label-font14">Preview Initials</label>
                            <div class="img-frame p-2">
                                <div id="userSignControl" class="signaturewidget">
                                    <img class="img-responsive negative userSignature settingsPropertyOnChange h-60px"
                                        id="imgProfileInitialsPreview" data-is-change="False" src=""
                                        >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="initialsContainer && showPolishInitialDiv" id="dvPolishInitialFonts">
                        <div class="mb-2 mt-0 border-boxsize">
                            <label for="defaultSignerName" class="form-label label-font14">Type Initials</label>
                            <input *ngIf="typeSignature" type="text" style="" [(ngModel)]="initialsInputText"
                                class="form-control typeSignature" placeholder="Enter your initials" id="defaultSignerName"
                                value="" maxlength="4" [ngStyle]="{'display':newInitsignatureTypeDisplay}"
                                (keyup)="UpdateInitialText($event)">
                        </div>
                        <p style="margin-bottom: 2px;">
                            <label class="form-label label-font14">Select Initials</label>
                        </p>
                        <ul class="newInitialsignatureType" style="display :block;padding:0px;overflow: hidden;">
                            <div class="d-flex align-items-center res-selfstart me-1">
                                <li class="lifontchange HomemadeApple" (click)="onSignatureSelection(0, $event)">
                                    <input type="radio" id="DancingScript" name="newInitialsignSelect" class="initControl">
                                    <label for="DancingScript" class="DancingScript"><span></span>
                                        <i *ngIf="initialsText.length === 0" class="signatureText newInitialsignatureText">
                                            Int.</i>
                                        <i *ngIf="initialsText.length !== 0" class="signatureText newInitialsignatureText">
                                            {{initialsText}}</i>
                                    </label>
                                </li>
                                <li class="lifontchange Parisienne" (click)="onSignatureSelection(1, $event)">
                                    <input type="radio" id="Parisienne" name="newInitialsignSelect" class="initControl">
                                    <label for="Parisienne" class="Parisienne"><span></span>
                                        <i *ngIf="initialsText.length === 0" class="newInitialsignatureText signatureText">Int.</i>
                                        <i *ngIf="initialsText.length !== 0" class="newInitialsignatureText signatureText">{{initialsText}}</i>
                                    </label>
                                </li>
                                <li class="lifontchange Allison" (click)="onSignatureSelection(2, $event)">
                                    <input type="radio" id="Allison" name="newInitialsignSelect" class="initControl">
                                    <label for="Allison" class="Allison"><span></span>
                                        <i *ngIf="initialsText.length === 0" class="newInitialsignatureText signatureText">Int.</i>
                                        <i *ngIf="initialsText.length !== 0" class="newInitialsignatureText signatureText">{{initialsText}}</i>
                                    </label>
                                </li>
                            </div>
                            <div class="d-flex align-items-center res-selfstart me-1">
                                <li class="lifontchange LaBelleAurorePolish" (click)="onSignatureSelection(3, $event)">
                                    <input type="radio" id="LaBelleAurorePolish" name="newInitialsignSelect" class="initControl">
                                    <label for="LaBelleAurorePolish" class="LaBelleAurorePolish"><span></span>
                                        <i *ngIf="initialsText.length === 0" lass="newInitialsignatureText signatureText">Int.</i>
                                        <i *ngIf="initialsText.length !== 0" class="newInitialsignatureText signatureText">{{initialsText}}</i>
                                    </label>
                                </li>
                                <li class="lifontchange OvertheRainbowPolish" (click)="onSignatureSelection(4, $event)">
                                    <input type="radio" id="OvertheRainbowPolish" name="newInitialsignSelect" class="initControl">
                                    <label for="OvertheRainbowPolish" class="OvertheRainbowPolish"><span></span>
                                        <i *ngIf="initialsText.length === 0" class="newInitialsignatureText signatureText">Int.</i>
                                        <i *ngIf="initialsText.length !== 0" class="newInitialsignatureText signatureText">{{initialsText}}</i>
                                    </label>
                                </li>
                                <li class="lifontchange GreatVibes" (click)="onSignatureSelection(5, $event)">
                                    <input type="radio" id="GreatVibes" name="newInitialsignSelect" class="initControl">
                                    <label for="GreatVibes" class="GreatVibes"><span></span>
                                        <i *ngIf="initialsText.length === 0" class="newInitialsignatureText signatureText">Int.</i>
                                        <i *ngIf="initialsText.length !== 0" class="newInitialsignatureText signatureText">{{initialsText}}</i>
                                    </label>
                                </li>
                            </div>
                        </ul>
                        <div class="form-group mt-n2 res-mb10 border-boxsize">
                            <label for="txtsignerProfileName" class="form-label label-font14">Preview Initials</label>
                            <div class="img-frame p-2">
                                <div id="userSignControl" class="signaturewidget">
                                    <img class="img-responsive negative userSignature settingsPropertyOnChange h-60px"
                                        id="imgProfileInitialsPreview" data-is-change="False" src=""
                                        >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="overflow: hidden;" class="tab-pane fade" id="select-draw" role="tabpanel" aria-labelledby="select-draw-tab">
                    <!-- Right vside menu [hidden]="hidePagesViewSection"-->
                    <div class="d-flex justify-content-center  flex-column w-100">
                    <div class="d-flex flex-column justify-content-center" #dvHandDrawn>
                        <div class="d-flex align-items-center justify-content-between border-boxsize">
                            <label class="form-label label-font14">Draw Initials</label>
                            <div class="d-flex align-items-center">
                                <a class="clearlink me-3" id="ankSignProfpopUploadSignature" (click)="clearPad()"
                                    style="color: #c3272e;">Clear</a>
                                <a class="clearlink" id="ankSignProfpopUploadSignature"
                                    (click)="onInitialsSubmit('save')">Preview</a>
                            </div>
                        </div>
                        <!-- (touchstart)="startDrawing($event)" (touchmove)="moved($event)"-->
                        <!-- <div #dvHandDrawn>
                            <canvas #canvas width="375" ></canvas>
                        </div> -->
                    </div>
                    <div #dvHandDrawn id="initialCanvas" class="d-flex justify-content-center">
                        <canvas #canvas width="{{getCanvasWidth()}}" height="115"></canvas>
                    </div>
                    <div class="form-group mt-3 res-mb10 border-boxsize">
                        <label for="txtsignerProfileName" class="form-label label-font14">Preview Initials</label>
                        <div class="img-frame p-2 ">
                            <div id="userSignControl" class="signaturewidget">
                                <img class="img-responsive negative userSignature settingsPropertyOnChange"
                                    id="imgProfileInitialsDrawPreview" data-is-change="False" src=""
                                    style="height:78px !important;">
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal footer -->
    <div class="modal-footer bg-modal modalfooter-padding" style="padding: 3px 10px; height: 56px;">
        <button type="button" class="btn btncancel px-3 py-1" (click)="activeModal.dismiss('Cross click')"
            id="btnSignerCancel" style="height: 23px;border-radius: 4px;">Cancel</button>
        <button type="button" class="btn btn-default btn_accept button-29 ms-3" id="btnAccept"
            (click)="onInitialsSubmit('Ok')"> Ok </button>
    </div>
</div>