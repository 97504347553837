import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EnvelopeStatusText, MessageTypes, ResourceKeys, RouteUrls } from 'src/app/constants/common-constants';
import { SignerLandingService } from '../../services/signer-landing.service';
import { CommonConstants } from '../../constants/common-constants';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { TranslationsService } from '../../services/translations.service';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/services/common.service';
@Component({
    selector: 'app-info',
    templateUrl: './info.component.html',
    styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {
    title = 'RSign | Info';
    showLoader: boolean = false;
    isErrorFlag: boolean = false;
    isSuccessFlag: boolean = false;
    isWarningFlag: boolean = false;
    isApplicationErrorFlag: boolean = false;
    displayMessage: any;
    controlInfo: any;
    isExpired: boolean = false;
    isSendConfirmationEmail: boolean = false;
    notExpiredAndEmail: boolean = false;
    isSuccessWarning: boolean = false;

    LearnMoreTextOnly: any;
    freeTrial: any;
    RedirectPostSigningLandingPage: any;
    Sessionpopupseconds: any;
    isSuccessErrorMessage: boolean = false;
    eSignExpiredFeatureList: any;
    eSignExpiredServiceFeature: any;
    RSignMakesESignatureText: any;
    laungaueTransalation = [];
    commonConstants = CommonConstants;
    resourceKeys = ResourceKeys;
    isFromFinishLater: any;
    IsApplicationError: any;
    DateTimeMessage1: any;
    DateTimeMessage2: any;
    htmlFooterString: any;
    isFromSessionExpired: any;
    isDisplayLoginButton: boolean = false;
    isDisplaySignUpButton: boolean = false;
    envelopeCompleted: boolean = false;
    AllowSignersDownloadFinalContract: boolean = false;
    SignInText: any;
    SignUpText: any;
    downloadFinalSignedDocFromCompletedFolderText: any;
    downloadDocAfterSignedText: any;
    signupDownloadFinalSignedDocText: any;
    isDelegated:any = "";
    IsSendConfirmEmailPopupOpen:any = "";
    

    constructor(private router: Router, private signerLandingService: SignerLandingService, private sanitizer: DomSanitizer,
        private translationsService: TranslationsService, private commonService: CommonService, private titleService:Title) { }

    @HostListener('window:popstate', ['$event'])
    onPopState(event: any) {
        console.log('Back button pressed');
        window.location.reload();
    }

    ngOnInit(): void {
        this.titleService.setTitle(this.title);
        window.scrollTo({ top: 0, behavior: 'smooth' });

        this.IsApplicationError = localStorage.getItem("IsApplicationError");
        let envelopeId = localStorage.getItem("EnvelopeID");
        let emailAddr = localStorage.getItem("EmailId");
        let MessageType = localStorage.getItem("MessageType");
        if (this.IsApplicationError == "true" || MessageType == MessageTypes.Error) {
            this.commonService.getReturnUrlDirection(RouteUrls.GlobalError);
            return;
        }
        else {
            this.htmlFooterString = '<p> ' +
                '<a href="https://rpost.com/legal-notices/terms-and-conditions" target="_blank" class="footerLinks">General Terms & Legal Notices</a>: Click to view service level terms, conditions, privacy, ' +
                '<a class="footerLinks" href="https://rpost.com/legal-notices/legal-and-patent-notice" target="_blank"> patent, trademark</a>, and other service and legal notices. ' +
                '</p>';

            this.showLoader = true;
            //let encryptedSender = localStorage.getItem("encryptedSender");
            this.displayMessage = localStorage.getItem("DisplayMessage");           
            this.controlInfo = localStorage.getItem("ControlInfo");
            this.isFromFinishLater = localStorage.getItem("IsFromFinishLater");
            this.isFromSessionExpired = localStorage.getItem("IsFromSessionExpired");
            this.isDelegated = localStorage.getItem("IsDelegated");
            this.IsSendConfirmEmailPopupOpen = localStorage.getItem ("IsSendConfirmEmailPopupOpen");

            if (this.displayMessage == "null" || this.displayMessage == null) this.displayMessage = "";

            if (this.isFromFinishLater) this.displayMessage = this.sanitizer.bypassSecurityTrustHtml(this.displayMessage);
            
            if (MessageType == MessageTypes.Error) {
                this.isErrorFlag = true;
            }
            else if (MessageType == MessageTypes.Warning) {
                this.isWarningFlag = true;
            }
            else if (MessageType == MessageTypes.Success) {
                this.isSuccessFlag = true;
            }
            else if (MessageType == MessageTypes.SuccessWarning) {
                this.isSuccessWarning = true;
            }
            else if (MessageType == MessageTypes.SuccessErrorMessage) {
                this.isSuccessErrorMessage = true;
            }
            else if (MessageType == MessageTypes.ArchivedOrPurged) {
                this.isSuccessErrorMessage = true;
            }
            else {
                this.isApplicationErrorFlag = true;
            }       

            if (this.controlInfo == MessageTypes.Expired) {
                this.isExpired = true;
            }
            if (this.controlInfo == MessageTypes.SendConfirmationEmail) {
                this.isSendConfirmationEmail = true;
            }

            if (this.controlInfo == "" || this.controlInfo == null || this.controlInfo == undefined) {
                this.notExpiredAndEmail = true;
            }

            if (this.isErrorFlag == false && this.isWarningFlag == false && this.isSuccessFlag == false &&
                this.isSuccessWarning == false && this.isSuccessErrorMessage == false && this.isSuccessErrorMessage == false
                && this.displayMessage == "" && (MessageType == "" || MessageType == null)) {
                this.isApplicationErrorFlag = false;
                this.displayMessage = "Session expired. Please close this browser tab.";
                this.isWarningFlag = true;
            }

            let allowSignerDownloadFinalContract: any = localStorage.getItem("AllowSignersDownloadFinalContract");
            if (allowSignerDownloadFinalContract == "true") {
                let DisplayLoginButton: any = localStorage.getItem("DisplayLoginButton");
                let DisplaySignUpButton: any = localStorage.getItem("DisplaySignUpButton");
                let EnvelopeStatus: any = localStorage.getItem("EnvelopeStatus");

                if (DisplayLoginButton != undefined && DisplayLoginButton != null && DisplayLoginButton != "" && DisplayLoginButton == "true") {
                    this.isDisplayLoginButton = true; this.isDisplaySignUpButton = false;
                }
                if (DisplaySignUpButton != undefined && DisplaySignUpButton != null && DisplaySignUpButton != "" && DisplaySignUpButton == "true") {
                    this.isDisplayLoginButton = false; this.isDisplaySignUpButton = true;
                }

                if (EnvelopeStatus == EnvelopeStatusText.Completed) this.envelopeCompleted = true;
                else if (EnvelopeStatus == EnvelopeStatusText.EnvelopeAccepted) this.envelopeCompleted = false;
                else if (EnvelopeStatus == EnvelopeStatusText.Declined) this.envelopeCompleted = true;
                this.AllowSignersDownloadFinalContract = true;
            }

            this.getLanguageTranslations();
        }
    }

    redirectCorporateURL(PageName: any, OpenType: any) {
        let getRedirectUrl = this.commonConstants.GetRedirectURL + "/" + PageName;
        this.signerLandingService.getRedirectURL(getRedirectUrl).subscribe(
            (resp: any) => {
                localStorage.clear();
                sessionStorage.clear();
                if (resp && resp.StatusCode == 200) {
                    if (resp.Message != null) {
                        OpenType == 'self' ? window.location.href = resp.Message : window.open(resp.Message, "_blank");
                    }
                }
            })
    }

    getLanguageTranslations() {
        if (sessionStorage.getItem('LaungaueTransalation') == null || sessionStorage.getItem('LanguageValidation') == null
            || sessionStorage.getItem('LaungaueTransalation') == undefined || sessionStorage.getItem('LanguageValidation') == undefined) {

            let requestObj = { EnvelopeId: "", RecipientId: "", CultureInfo: "en-us" };

            let getLanguageTranslationsUrl = CommonConstants.getLanguageTranslationsUrl;
            this.signerLandingService.getLanguageTranslations(getLanguageTranslationsUrl, requestObj).subscribe(
                (resp: any) => {
                    if (resp && resp.StatusCode == 200) {
                        let languageTranslations: any = resp.LanguageTranslationsModel;
                        sessionStorage.setItem("LaungaueTransalation", JSON.stringify(languageTranslations.Language));
                        sessionStorage.setItem("LanguageValidation", JSON.stringify(languageTranslations.LanguageValidation));
                        this.getResourceKeyLanguagevalues();
                    }
                    else {
                        this.showLoader = false;
                        this.RSignMakesESignatureText = CommonConstants.RSignMakesESignatureText;
                        this.LearnMoreTextOnly = CommonConstants.LearnMoreTextOnly;
                        this.freeTrial = CommonConstants.FreeTrialText;
                    }
                })
                   
        }
        else {
            this.getResourceKeyLanguagevalues();
        }
    }

    getResourceKeyLanguagevalues() {
        this.LearnMoreTextOnly = this.translationsService.getLanguageTranslationVal(ResourceKeys.LearnMoreTextOnly);
        this.freeTrial = this.translationsService.getLanguageTranslationVal(ResourceKeys.freeTrial);
        this.RedirectPostSigningLandingPage = this.translationsService.getLanguageTranslationVal(ResourceKeys.RedirectPostSigningLandingPage);
        this.Sessionpopupseconds = this.translationsService.getLanguageTranslationVal(ResourceKeys.Sessionpopupseconds);
        this.eSignExpiredFeatureList = this.translationsService.getLanguageTranslationVal(ResourceKeys.eSignExpiredFeatureList);
        this.eSignExpiredServiceFeature = this.translationsService.getLanguageTranslationVal(ResourceKeys.eSignExpiredServiceFeature);
        this.RSignMakesESignatureText = this.translationsService.getLanguageTranslationVal(ResourceKeys.RSignMakesESignatureText);

        this.SignInText = this.translationsService.getLanguageTranslationVal(ResourceKeys.lang_LogIn);
        this.SignUpText = this.translationsService.getLanguageTranslationVal(ResourceKeys.SignUp);
        this.downloadFinalSignedDocFromCompletedFolderText = this.translationsService.getLanguageTranslationVal(ResourceKeys.DownloadFinalSignedDocText);
        this.downloadDocAfterSignedText = this.translationsService.getLanguageTranslationVal(ResourceKeys.DownloadDocAfterSignedText);
        this.signupDownloadFinalSignedDocText = this.translationsService.getLanguageTranslationVal(ResourceKeys.SignupDownloadFinalSignedDocText);

        localStorage.clear();
        sessionStorage.clear();
        this.showLoader = false;
    }

    getLanguageTranslationValue(resourcekeyId: any) {
        return this.translationsService.getLanguageTranslationVal(resourcekeyId);
    }

    onSignin() {
        localStorage.clear();
        sessionStorage.clear();
        let baseUrl = environment.uiBaseUrl;
        window.location.href = environment.uiBaseUrl + "Account/LogOn";
    }

    onSignup() {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = CommonConstants.RSignSignupCompleted;

    }
}
