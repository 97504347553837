<div class="modal-header bg-modal modalhead-padding">
    <h4 class="modal-title"> {{getLanguageTranslationValue(resourceKeys.InviteSigners)}}</h4>
    <div class="d-flex align-items-center">
        <span class="material-icons modal-close" aria-label="Close" (click)="closeInviteSignerModal()">
            close
        </span>
    </div>
</div>


<div class="modal-body modal-bodybg py-3">
    <div *ngIf="this.EnableMessageToMobile" class="d-flex">
        <p class="d-flex flex-wrap mb-0">
            <!-- Select Sign now to invite signers to sign in person or invite them by email, mobile, or both to sign
            offline. -->        
            {{getLanguageTranslationValue(resourceKeys.InviteSignerHeaderText)}}
        </p>
    </div>
    <form class="mt-3">
        <fieldset class="border rounded-3 p-3 py-1 res-p0">
            <legend class="float-none w-auto px-3 font-15 font-libre" style="font-weight:600;">{{getLanguageTranslationValue(resourceKeys.EnterSignerDetails)}} </legend>
            <div class="justify-content-end mb-3 mt-n2" [ngStyle]="{'display':dvInviteAddNewStyle}" id="dvInviteAddNew">
                <a id="anchorInviteAddNew" class="btn btn-outline-primary d-flex align-items-center"
                    (click)="InviteAddNewRow($event)">
                    <span class="material-icons me-2 font-18"> add_circle_outline </span> Add New</a>
            </div>
            <div *ngIf="webOrMobile === 'web'" class="d-sm-none d-md-block">
         

                <!-- New Table -->
                <table class="tableSection">
                    <thead *ngIf="this.EnableMessageToMobile">
                        <tr style="background: #deecf89e;">
                            <th class="text-center font-libre" style="width: 175px;">
                                <label class="ms-2 font-14">
                                    {{getLanguageTranslationValue(resourceKeys.SignAs)}}</label>
                            </th>
                            <th class="text-center font-libre" style="width: 203px;">
                                <label class="font-14"> {{getLanguageTranslationValue(resourceKeys.Name)}}</label>
                            </th>
                            <th class="text-center font-libre" style="width: 204px;">
                                <label class=" font-14" style="margin-left: -15px;"> {{getLanguageTranslationValue(resourceKeys.Email)}}</label>
                            </th>
                            <th class="text-center font-libre" style="width: 285px;">
                                <div class="d-flex align-items-center flex-wrap">
                                    <label class="font-14">
                                        <span>Country code</span>
                                        <span style="margin-left: 20px;">Mobile number</span> </label>
                                </div>
                           
                            </th>
                            <th class="text-center font-14 font-libre" style="width: 95px;">
                               <span class="">{{getLanguageTranslationValue(resourceKeys.SignNow)}}</span> </th>
                            <th class="text-center font-14 font-libre" style="width: 80px;">
                               <span class="left-n12">Invite by Email/Mobile</span> </th>
                            <th class="text-center font-14 font-libre" style="width: 85px;">
                               <span class=""></span></th>
                            <th style="width: 50px;"></th>
                            <!-- <th></th> -->
                        </tr>
                    </thead>

                    <thead *ngIf="!this.EnableMessageToMobile">
                        <tr style="background: #deecf89e;">
                            <th class="text-center font-libre" style="width: 200px;">
                                <label class="ms-2 font-14">
                                    {{getLanguageTranslationValue(resourceKeys.SignAs)}}</label>
                            </th>
                            <th class="text-center font-libre" style="width: 250px;">
                                <label class=" font-14"> {{getLanguageTranslationValue(resourceKeys.Name)}}</label>
                            </th>
                            <th class="text-center font-libre" style="width: 250px;">
                                <label class=" font-14"> {{getLanguageTranslationValue(resourceKeys.Email)}}</label>
                            </th>
                            <th class="text-center font-14 font-libre" style="width: 100px;">
                               <span>{{getLanguageTranslationValue(resourceKeys.SignNow)}}</span> </th>
                            <th class="text-center font-14 font-libre" style="width: 200px;">
                              <span>{{getLanguageTranslationValue(resourceKeys.InviteByEmail)}}</span> </th>
                            <th style="width: 70px;"></th>
                            <!-- <th></th> -->
                        </tr>
                    </thead>

                    <tbody id="tbodyRolesInfo" *ngIf="this.EnableMessageToMobile">
                        <tr *ngFor="let signer of InviteSignerDataList; let i=index;" class="trClsInvSign"
                            id="trInvSign_{{signer.roleSeq}}">
                            <td style="width: 175px;">
                                <select id="{{signer.roleSeq}}_ddrRolesTypes"
                                    class="form-control form-select InviteRoles {{signer.roleSeq}}_ddrRolesTypes"
                                    (change)="UpdateRolesOptions(signer.roleSeq)">
                                </select>
                            </td>
                            <td>
                                <input id="txtInvSignName_{{signer.roleSeq}}" type="text" placeholder="Signer's name"
                                    class="form-control w-100" maxlength="50" autocomplete="off">
                            </td>
                            <td>
                                <input id="txtInvSignEmail_{{signer.roleSeq}}" type="text" placeholder="Signer's email"
                                    class="form-control w-100" maxlength="50" autocomplete="off">
                            </td>
                            <td style="width:285px">
                                <div class="input-group dropup divCountryCode_{{signer.roleSeq}}">
                                    <div ngbDropdown id="ngbDropdown_{{signer.roleSeq}}"
                                        class="dropdown ngbDropdown_{{signer.roleSeq}}"
                                        style="width: 115px;cursor: no-drop;" #myDrop="ngbDropdown">
                                        <a ngbDropdownToggle style="text-decoration-line: none; color: #000;">
                                            <span id="spnTitle_{{signer.roleSeq}}"
                                                style="display: inline;position: relative;">Select</span>
                                        </a>
                                        <ul ngbDropdownMenu class="dropdown-menu DialCodeUl"
                                            id="DialCodeUl_{{signer.roleSeq}}"
                                            (click)="selectedCountryDialCode($event,signer.roleSeq );myDrop.close();">
                                            <li *ngFor="let dialcodes of DialCodeDropdownList; let i = index;"
                                                id="bg-{{dialcodes.CountryCode.toLowerCase()}}"
                                                class="liCountryItem {{dialcodes.CountryCode.toLowerCase()}}">

                                                <a *ngIf="dialcodes.DialCode == 'Select'" [attr.data-maxlength]="0"
                                                    style="text-decoration-line: none;color: white;padding-top: 15px;padding-bottom: 15px; background-color: #bc0001;">
                                                    <span id="spnCountryFlag"></span>Select</a>

                                                <a style="text-decoration-line: none;color: #000;"
                                                    *ngIf="dialcodes.DialCode != 'Select'"
                                                    [attr.data-countrycode]="dialcodes.CountryCode.toLowerCase()"
                                                    [attr.data-maxlength]="dialcodes.MobileMaxLength">
                                                    <span id="spnCountryFlag_{{signer.roleSeq}}"
                                                        class="bg-{{dialcodes.CountryCode.toLowerCase()}}"
                                                        style="top: -8px; display: inline-block; width: 25px;position: relative;"></span>{{dialcodes.DialCode}}</a>
                                            </li>
                                        </ul>
                                    </div>

                                    <input type="text"
                                        style="margin-left: 6px;padding-left: 6px;padding-right: 0px;height: 36px;"
                                        class="form-control txtInvSignMobile" id="txtInvSignMobile_{{signer.roleSeq}}"
                                        placeholder="Mobile number" name="mobilenumber" value="" pattern="[0-9]*"
                                        ondrop="return false" inputmode="numeric" typeof="number" spellcheck="false"
                                        (input)="validateInputValue($event)" autocomplete="off">
                                </div>
                            </td>
                            <td class="text-left" style="width: 60px;" id="tdInvSignNow_{{signer.roleSeq}}">
                                <input id="radioSignNow_{{signer.roleSeq}}"
                                    name="SignAndInviteChange_{{signer.roleSeq}}"
                                    (change)="getSignAndInviteValue($event, signer.roleSeq,1)"
                                    class="SignAndInviteChange" style=" margin-left: 15px;" type="checkbox" checked>
                            </td>

                            <td class="text-center" style="width:90px" id="tdInvSignEmail_{{signer.roleSeq}}">
                                <input id="radioInvitebyEmail_{{signer.roleSeq}}"
                                    (change)="getSignAndInviteValue($event, signer.roleSeq, 2)"
                                    name="SignAndInviteChange_{{signer.roleSeq}}" class="SignAndInviteChange"
                                    type="checkbox">
                            </td>
                            <td class="text-center" style="width:85px" id="tdInvSignMobile_{{signer.roleSeq}}">
                               
                                    <div class="CouponcodeEnvelope" id="couponcodeEnvelope_{{signer.roleSeq}}" (mouseover) ="showInviteMessageCodePopOver(1,signer.roleSeq)" (mouseout) ="showInviteMessageCodePopOver(2, signer.roleSeq)">
                                        <span style="color: #000; padding: 0px 0px !important;cursor: pointer;" class="fa fa-info-circle btn" aria-hidden="true" data-toggle="popover" data-trigger="hover" data-placement="right" data-container="body" data-content=""></span>
                                        <span *ngIf="!this.IsSendMessageCodetoAvailableEmailorMobile" class="Coupontooltip Coupontooltip_{{signer.roleSeq}}" id="Coupontooltip_{{signer.roleSeq}}" style="text-align: left;">
                                            <span style="padding-left: 5px;">Message To: <span id="messageTo_{{signer.roleSeq}}"></span></span> <br />
                                            <span style="padding-left: 5px;">Code To: <span id="codeTo_{{signer.roleSeq}}"></span></span>
                                        </span>
                                        <span *ngIf="this.IsSendMessageCodetoAvailableEmailorMobile" class="Coupontooltip Coupontooltip_{{signer.roleSeq}}" id="Coupontooltip_{{signer.roleSeq}}" style="text-align: left;width:630px;margin-left:-602px;margin-top:-63px;top:20px;">
                                            <!-- <span>Message and code will be sent to either mobile or email, which ever is provided.</span> -->
                                            <span>Message To/Code To is configured as <span id="messagecodeTo_{{signer.roleSeq}}"></span>. When Email or Mobile is missing, all communication will be sent to the available email or mobile.</span> 
                                        </span>
                                    </div>
                            </td>

                            <td class="text-center" style="width: 50px;">
                                <span id="tdInvSignReq_{{signer.roleSeq}}"
                                    style="padding-left:5px; font-size:24px;font-weight: 600;vertical-align:middle;color:red;display: none;">*</span>
                                <a class="font-iconColor deleteOptionalRow" title="Delete Row"
                                    id="btnInvSignDelete_{{signer.roleSeq}}"
                                    (click)="deleteOptionalInviteRow($event, signer.roleSeq)"
                                    style="padding-left:5px;vertical-align:middle; color:red"><i
                                        class="fa fa-trash fa-iconsSize" aria-hidden="true"></i></a>
                            </td>
                            <input type="hidden" id="tdCultureInfo_{{signer.roleSeq}}" value="{{signer.cultureInfo}}" />
                            <input type="hidden" id="DeliveryMode_{{signer.roleSeq}}" value="" />
                           
                        </tr>
                    </tbody>

                    <tbody id="tbodyRolesInfo" *ngIf="!this.EnableMessageToMobile">
                        <tr *ngFor="let signer of InviteSignerDataList; let i=index;" class="trClsInvSign"
                            id="trInvSign_{{signer.roleSeq}}">
                            <td style="width:200px;">
                                <select id="{{signer.roleSeq}}_ddrRolesTypes"
                                    class="form-control form-select InviteRoles {{signer.roleSeq}}_ddrRolesTypes"
                                    (change)="UpdateRolesOptions(signer.roleSeq)">
                                </select>
                            </td>
                            <td style="width:250px;">
                                <input id="txtInvSignName_{{signer.roleSeq}}" type="text" placeholder="Signer's name"
                                    class="form-control w-100" maxlength="50" autocomplete="off">
                            </td>
                            <td style="width:250px;">
                                <input id="txtInvSignEmail_{{signer.roleSeq}}" type="text" placeholder="Signer's email"
                                    class="form-control w-100" maxlength="50" autocomplete="off">
                            </td>
                            <td class="text-center" id="tdInvSignNow_{{signer.roleSeq}}" style="width:100px;">
                                <input id="radioSignNow_{{signer.roleSeq}}"
                                    name="SignAndInviteChange_{{signer.roleSeq}}"
                                    (change)="getSignAndInviteValue($event, signer.roleSeq,1)"
                                    class="SignAndInviteChange" type="checkbox" checked>
                            </td>

                            <td class="text-center" id="tdInvSignEmail_{{signer.roleSeq}}" style="width:200px;">
                                <input id="radioInvitebyEmail_{{signer.roleSeq}}"
                                    (change)="getSignAndInviteValue($event, signer.roleSeq, 2)"
                                    name="SignAndInviteChange_{{signer.roleSeq}}" class="SignAndInviteChange ml-50"
                                    type="checkbox">
                            </td>

                            <td class="text-center" style="width:70px;">
                                <span id="tdInvSignReq_{{signer.roleSeq}}"
                                    style="padding-left:5px; font-size:24px;font-weight: 600;vertical-align:middle;color:red;display: none;">*</span>
                                <a class="font-iconColor deleteOptionalRow" title="Delete Row"
                                    id="btnInvSignDelete_{{signer.roleSeq}}"
                                    (click)="deleteOptionalInviteRow($event, signer.roleSeq)"
                                    style="padding-left:5px;vertical-align:middle; color:red"><i
                                        class="fa fa-trash fa-iconsSize" aria-hidden="true"></i></a>
                            </td>
                            <input type="hidden" id="tdCultureInfo_{{signer.roleSeq}}" value="{{signer.cultureInfo}}" />
                            
                        </tr>
                    </tbody>
                </table>
                <!-- end the table -->
            </div>

            <div class="d-sm-block d-md-none card-overflow style-scrollbar" *ngIf="webOrMobile === 'mobile'">
                <div class="uploadsDoc me-2" *ngFor="let signer of InviteSignerDataList; let i=index;"
                    class="trClsInvSign" id="trInvSign_{{signer.roleSeq}}">
                    <div class="card box-shadow-light mb-3">
                        <div class="card-body">
                            <div class="mb-3">
                                <label for="exampleFormControlInput1"
                                    class="form-label">{{getLanguageTranslationValue(resourceKeys.SignAs)}}</label>
                                <select id="{{signer.roleSeq}}_ddrRolesTypes"
                                    class="form-control form-select InviteRoles {{signer.roleSeq}}_ddrRolesTypes"
                                    (change)="UpdateRolesOptions(signer.roleSeq)">
                                </select>
                            </div>
                            <div class="mb-3">
                                <label for="exampleFormControlInput1"
                                    class="form-label">{{getLanguageTranslationValue(resourceKeys.Name)}}</label>
                                <input id="txtInvSignName_{{signer.roleSeq}}" type="text" placeholder="Signer's name"
                                    class="form-control w-100" maxlength="50" autocomplete="off">
                            </div>
                            <div class="mb-3">
                                <label for="exampleFormControlInput1"
                                    class="form-label">{{getLanguageTranslationValue(resourceKeys.Email)}}</label>
                                <input id="txtInvSignEmail_{{signer.roleSeq}}" type="text" placeholder="Signer's email"
                                    class="form-control w-100" maxlength="50" autocomplete="off">
                            </div>
                            <div *ngIf="this.EnableMessageToMobile" class="mb-3">
                                <label for="exampleFormControlInput1" class="form-label">Mobile</label>
                                <div class="input-group dropup divCountryCode_{{signer.roleSeq}}">
                                    <div ngbDropdown id="ngbDropdown_{{signer.roleSeq}}"
                                        class="dropdown ngbDropdown_{{signer.roleSeq}}"
                                        style="width: 125px;cursor: no-drop;" #myDrop="ngbDropdown">
                                        <a ngbDropdownToggle style="text-decoration-line: none; color: #000;">
                                            <span id="spnTitle_{{signer.roleSeq}}"
                                                style="display: inline;position: relative;">Select</span>
                                        </a>

                                        <ul ngbDropdownMenu class="dropdown-menu DialCodeUl"
                                            id="DialCodeUl_{{signer.roleSeq}}"
                                            (click)="selectedCountryDialCode($event,signer.roleSeq );myDrop.close();">
                                            <li *ngFor="let dialcodes of DialCodeDropdownList; let i = index;"
                                                id="bg-{{dialcodes.CountryCode.toLowerCase()}}"
                                                class="liCountryItem {{dialcodes.CountryCode.toLowerCase()}}">

                                                <a *ngIf="dialcodes.DialCode == 'Select'" [attr.data-maxlength]="0"
                                                    style="text-decoration-line: none;color: #000;padding-top: 15px;padding-bottom: 15px; background-color: #bc0001;">
                                                    <span id="spnCountryFlag"></span>Select</a>

                                                <a style="text-decoration-line: none;color: #000;"
                                                    *ngIf="dialcodes.DialCode != 'Select'"
                                                    [attr.data-countrycode]="dialcodes.CountryCode.toLowerCase()"
                                                    [attr.data-maxlength]="dialcodes.MobileMaxLength">
                                                    <span id="spnCountryFlag_{{signer.roleSeq}}"
                                                        class="bg-{{dialcodes.CountryCode.toLowerCase()}}"
                                                        style="top: -8px; display: inline-block; width: 25px;position: relative;"></span>{{dialcodes.DialCode}}</a>
                                            </li>
                                        </ul>

                                    </div>

                                    <input type="text"
                                        style="margin-left: 3px;padding-left: 5px;padding-right: 0px;height: 36px;"
                                        class="form-control txtInvSignMobile" id="txtInvSignMobile_{{signer.roleSeq}}"
                                        pattern="[0-9]*" ondrop="return false" inputmode="numeric" typeof="number"
                                        spellcheck="false" placeholder="Mobile number" name="mobilenumber"
                                        (input)="validateInputValue($event)" value="" autocomplete="off">
                                </div>
                            </div>
                            <div class="mb-3 d-flex align-items-center justify-content-between">
                                <div class="d-flex flex-column" id="tdInvSignNow_{{signer.roleSeq}}">
                                    <label for="exampleFormControlInput1"
                                        class="form-label res-font12">{{getLanguageTranslationValue(resourceKeys.SignNow)}}</label>
                                    <input id="radioSignNow_{{signer.roleSeq}}"
                                        name="SignAndInviteChange_{{signer.roleSeq}}"
                                        (change)="getSignAndInviteValue($event, signer.roleSeq,1)"
                                        class="SignAndInviteChange" type="checkbox" style="margin-left: 7px;" checked>
                                </div>
                                <div class="d-flex flex-column" id="tdInvSignEmail_{{signer.roleSeq}}">
                                    <label *ngIf="!this.EnableMessageToMobile" for="exampleFormControlInput1"
                                        class="form-label res-font12">{{getLanguageTranslationValue(resourceKeys.InviteByEmail)}}</label>
                                    <label *ngIf="this.EnableMessageToMobile" for="exampleFormControlInput1"
                                        class="form-label res-font12">Invite by email \ mobile</label>
                                    <input id="radioInvitebyEmail_{{signer.roleSeq}}"
                                        (change)="getSignAndInviteValue($event, signer.roleSeq, 2)"
                                        name="SignAndInviteChange_{{signer.roleSeq}}" class="SignAndInviteChange"
                                        style="margin-left: 13px;" type="checkbox">
                                </div>
                                <div *ngIf="this.EnableMessageToMobile" class="d-flex flex-column"
                                    id="tdInvSignMobile_{{signer.roleSeq}}">
                                   

                                        <div class="CouponcodeEnvelope" id="couponcodeEnvelope_{{signer.roleSeq}}" style="margin-top: 33px;" (click)="showInviteMessageCodePopOverMobile(signer.roleSeq)" (ontouchstart)="showInviteMessageCodePopOverMobile(signer.roleSeq)">
                                            <span style="color: #000; padding: 0px 0px !important;cursor: pointer;" class="fa fa-info-circle btn" aria-hidden="true" data-toggle="popover" data-trigger="hover" data-placement="right" data-container="body" data-content=""></span>
                                            <span *ngIf="!this.IsSendMessageCodetoAvailableEmailorMobile" class="Coupontooltip Coupontooltip_{{signer.roleSeq}}" id="Coupontooltip_{{signer.roleSeq}}" style="margin-left:-226px; margin-top:-51px;width: 225px;">
                                                <span>Message To: <span id="messageTo_{{signer.roleSeq}}"></span></span> <br />
                                                <span>Code To: <span id="codeTo_{{signer.roleSeq}}"></span></span>
                                            </span>                                         
                                            <span *ngIf="this.IsSendMessageCodetoAvailableEmailorMobile" class="Coupontooltip Coupontooltip_{{signer.roleSeq}}" id="Coupontooltip_{{signer.roleSeq}}" style="margin-left:-226px; margin-top:-144px;width: 225px;">
                                                <!-- <span>Message and code will be sent to either mobile or email, which ever is provided.</span>       -->
                                                <span>Message To/Code To is configured as <span id="messagecodeTo_{{signer.roleSeq}}"></span>. When Email or Mobile is missing, all communication will be sent to the available email or mobile.</span> 
                                            </span>
                                        </div>
                                </div>
                                <div *ngIf="this.EnableMessageToMobile" class="d-flex">
                                    <span id="tdInvSignReq_{{signer.roleSeq}}"
                                        style="padding-left:5px; font-size:24px;font-weight: 600;vertical-align:middle;color:red;display: none;margin-top: 45px;">*</span>
                                    <a class="font-iconColor deleteOptionalRow" title="Delete Row"
                                        id="btnInvSignDelete_{{signer.roleSeq}}"
                                        (click)="deleteOptionalInviteRow($event, signer.roleSeq)"
                                        style="vertical-align:middle; color:red;margin-top: 35px;"><i
                                            class="fa fa-trash fa-iconsSize" aria-hidden="true"></i>
                                    </a>
                                </div>
                                <div *ngIf="!this.EnableMessageToMobile" class="d-flex">
                                    <span id="tdInvSignReq_{{signer.roleSeq}}"
                                        style="padding-left:5px; font-size:24px;font-weight: 600;vertical-align:middle;color:red;display: none;margin-top: 40px;">*</span>
                                    <a class="font-iconColor deleteOptionalRow" title="Delete Row"
                                        id="btnInvSignDelete_{{signer.roleSeq}}"
                                        (click)="deleteOptionalInviteRow($event, signer.roleSeq)"
                                        style="vertical-align:middle; color:red;margin-top: 40px;"><i
                                            class="fa fa-trash fa-iconsSize" aria-hidden="true"></i>
                                    </a>
                                </div>
                            </div>
                            <input type="hidden" id="tdCultureInfo_{{signer.roleSeq}}" value="" />
                            <input type="hidden" id="DeliveryMode_{{signer.roleSeq}}" value="" />
                            
                        </div>
                    </div>
                </div>
            </div>

        </fieldset>

            <fieldset class="border mt-3 rounded-3 px-3 w-100 res-w100">
                <legend class="float-none w-auto px-3 mb-0 font-15 font-libre" style="font-weight:600;">
                    {{getLanguageTranslationValue(resourceKeys.MyDetails)}}</legend>

                <div class="row pb-3">
                    <div class="d-flex flex-column  col-sm-12 col-md-6 col-lg-2 pt-2">
                        <label for="sel1" class="form-label ps-2 ms-1">Sign as</label>
                        <input type="text" placeholder="Your name" class="form-control ms-2" id="txtMyDetailsSignAs"
                            autocomplete="off" disabled style="cursor: not-allowed;">
                    </div>

                    <div class="d-flex flex-column col-sm-12 col-md-6 col-lg-2 pt-2">
                        <label for="name"
                            class="form-label ms-2 ps-1">{{getLanguageTranslationValue(resourceKeys.Name)}}</label>
                        <input type="text" placeholder="Name" class="form-control ms-2" id="txtMyDetailsName"
                            autocomplete="off">
                    </div>

                    <div  class="d-flex flex-column col-sm-12 col-md-6 col-lg-3 pt-2"
                        *ngIf="returnShowEmailBasedOnDeliveryModeVal(currentRecipientDeliveryMode)">
                        <label for="email"
                            class="form-label ms-2 ps-1">{{getLanguageTranslationValue(resourceKeys.Email)}}</label>
                        <input type="text" placeholder="Email" class="form-control ms-2" id="txtMyDetailsEmail"
                            autocomplete="off">
                    </div>

                    <div class="d-flex flex-column col-sm-12 col-md-6 col-lg-4 pt-2"
                        *ngIf="returnShowMobileDeliveryModeVal(currentRecipientDeliveryMode)">
                        <label for="email" class="form-label ms-2 ">
                            <!-- Country / Mobile: -->
                            <span class="me-2">Country code</span>
                            <span class="ms-2">Mobile number</span>
                        </label>
                        <div class="input-group ms-2" >
                            <div ngbDropdown class="ngbDropdown dropdown" #myDrop="ngbDropdown" style="cursor:no-drop;">
                                <a ngbDropdownToggle style="text-decoration-line: none; color: #000;">
                                    <span id="spnMyTitle" disabled="disabled"
                                        style="display: inline; cursor:no-drop;">Select</span>
                                </a>
                                <ul ngbDropdownMenu class="dropdown-menu DialCodeUl" id="DialCodeUl"
                                    style="display: none;" disabled="true">
                                    <li id="bg-{{dialcodes.CountryCode.toLowerCase()}}"
                                        class="liCountryItem {{dialcodes.CountryCode.toLowerCase()}}"
                                        *ngFor="let dialcodes of DialCodeDropdownList; let i = index;">
                                        <a *ngIf="dialcodes.DialCode == 'Select'" [attr.data-maxlength]="0"
                                            style="text-decoration-line: none;color: white;padding-top: 15px;padding-bottom: 15px; background-color: #bc0001;">
                                            <span id="spnCountryFlag"></span>Select</a>
                                        <a style="text-decoration-line: none;color: white;"
                                            *ngIf="dialcodes.DialCode != 'Select'">
                                            <span id="spnMyCountryFlag"
                                                class="bg-{{dialcodes.CountryCode.toLowerCase()}}"
                                                style="top: -8px; display: inline-block; width: 25px;"></span>{{dialcodes.DialCode}}</a>
                                    </li>
                                </ul>
                            </div>

                            <input type="text" class="form-control txtMyPhone mx-10p"
                                id="txtMyPhone" name="mobile number" value="{{currentRecipientMobileNumber}}"
                                autocomplete="off">
                        </div>
                    </div>
                    
                    <div *ngIf="this.EnableMessageToMobile && webOrMobile === 'web'" class="MyCouponcodeEnvelope col-sm-12 col-md-6 col-lg-1" style="padding-top: 0px;width: 10px;margin-top: 42px;"  (mouseover) ="showMessageCodePopOver(1)" (mouseout) ="showMessageCodePopOver(2)" >
                        <span style="color: #000; padding: 0px 0px !important;cursor: pointer;" class="fa fa-info-circle btn" aria-hidden="true" data-toggle="popover" data-trigger="hover" data-placement="right" data-container="body" data-content=""></span>
                        <span *ngIf="!this.IsSendMessageCodetoAvailableEmailorMobile" class="MyCoupontooltip" id="MyCoupontooltipMsg">
                            <span>Message To: <span id="MyMessageTo"></span></span> <br />
                            <span>Code To: <span id="MyCodeTo"></span></span>
                        </span>
                        <span *ngIf="this.IsSendMessageCodetoAvailableEmailorMobile" id="MyCoupontooltip" class="MyCoupontooltip" style="width:416px;margin-top:-66px;">
                            <!-- <span>Message and code will be sent to either mobile or email, which ever is provided.</span> -->
                           <span>Message To/Code To is configured as <span id="MySpnRecpGenericMsgDeliveryMode"></span>. When Email or Mobile is missing, all communication will be sent to the available email or mobile.</span> 
                        </span>
                    </div>

                    <div *ngIf="this.EnableMessageToMobile && webOrMobile === 'mobile'" class="MyCouponcodeEnvelope col-sm-12 col-md-6 col-lg-1" style="padding-top: 15px;margin-left:8px;" (click)="showMessageCodePopOverMobile()" (ontouchstart)="showMessageCodePopOverMobile()">
                        <span style="color: #000; padding: 0px 0px !important;cursor: pointer;" class="fa fa-info-circle btn" aria-hidden="true" data-toggle="popover" data-trigger="hover" data-placement="right" data-container="body" data-content=""></span>
                        <span *ngIf="!this.IsSendMessageCodetoAvailableEmailorMobile" class="MyCoupontooltip" id="MyCoupontooltip" style="margin-left:17px; margin-top:-42px;width: 220px;">
                            <span>Message To: <span id="MyMessageTo"></span></span> <br />
                            <span>Code To: <span id="MyCodeTo"></span></span>
                        </span>
                        <span *ngIf="this.IsSendMessageCodetoAvailableEmailorMobile" class="MyCoupontooltip" id="MyCoupontooltip" style="margin-left:17px; margin-top:-42px;width: 300px;">
                            <!-- <span>Message and code will be sent to either mobile or email, which ever is provided.</span> -->
                            <span>Message To/Code To is configured as <span id="MySpnRecpGenericMsgDeliveryMode"></span>. When Email or Mobile is missing, all communication will be sent to the available email or mobile.</span> 
                        </span>
                    </div>

                    <input type="hidden" id="tdCultureInfo_" value="" />

                </div>

            </fieldset>

            <fieldset class="border mt-3 rounded-3 px-2 w-100 res-w100 resmt-20px">
                <legend class="float-none w-auto px-3 mb-0 font-15 font-libre" style="font-weight:600;">
                    {{getLanguageTranslationValue(resourceKeys.Message)}}</legend>

                <div class="d-flex flex-column mt-2">
                    <div class="mb-2">
                        <textarea class="form-control" placeholder="Enter message" id="txtComment" name="text"
                            maxlength="250" rows="2" style="resize: none; border: none;box-shadow: none; padding: .0rem .75rem;"></textarea>
                    </div>

                </div>

            </fieldset>

    </form>

</div>

<!-- Modal footer -->
<div class="modal-footer bg-modal modalfooter-padding">

    <button type="button" id="btnNoThanks" class="btn btncancel px-3 py-1" (click)="closeInviteSignerModal()"
        style="height: 23px;border-radius: 4px;">{{getLanguageTranslationValue(resourceKeys.Cancel)}}</button>
    <button type="button" class="btn btn-default btn_accept button-29 ms-3" id="btnSignInExistingUser"
        (click)="onContinueInviteSignerModal()">{{getLanguageTranslationValue(resourceKeys.Continue)}} </button>

</div>

<div *ngIf="showLoader" class="loader">
</div>