<nav class="navbar navbar-expand-md navbar-light fixed-top header">
    <!-- <div id="dvHeader" style="display:none;" [innerHtml]="htmlHeaderString"></div> -->
    <div id="dvHeader" style="display:none;">
        <div class="margin0">
            <div class="col-xs-3 navbar-brand rsignLeftlogo">
                <div class="left-logo rsign-leftlogo">
                    <img src="{{firstImgSrc}}" class="rsignLogo">
                </div>
            </div>
            <div class="col-xs-6 rsign-content" style="">
                <h4 class="HeaderText">
                 <span>{{headerTextValue}}</span>
                </h4>
            </div>
            <div class="col-xs-3 navbar-brand rsignRightlogo">
                <div class="pull-right right-logo rsign-rightlogo">
                    <img src="{{secondImgSrc}}" class="rsignLogo"></div>
            </div>
        </div>
    </div>
</nav>

<div *ngIf="showLoader" class="loader">
</div>

<div id="full-bannertext" class="full-banner" style="display:none;">
    <span class="material-icons pagearrow"> arrow_drop_up </span>
    <div class="d-flex flex-wrap justify-content-center p-10p" style="position: fixed;
    z-index: 9999;background: white;border: 1px solid grey;top: 60px;margin-left: 10px;
    margin-right: 10px;">{{fullHeaderText}}
    </div>
</div>

<!-- <div class="dropdown">
  <h4 class="HeaderText dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
    <span>RSign Rmail Ruser Rportal Linkdata</span>
  </h4>
<ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
<li style="padding: 0px 12px;">
  <p class="dropdown-item mb-0">RSign Rmail Ruser Rportal Linkdata</p>
</li>
</ul>
</div> -->