<div [ngStyle]="{'display':attachmentStyle}" class="loader" id="attachmentloader">
</div>
<div [ngStyle]="{'display':confirmationPopupStyle}" id="confirmationPopup" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content" style="box-shadow: rgb(14 30 37 / 60%) 0px 0px 10px;">
            

            <!-- Modal Header -->
            <div class="modal-header bg-modal modalhead-padding">
                <h4 class="modal-title"> Confirmation </h4>
                <div class="d-flex align-items-center">
                    <span class="material-icons modal-close" aria-label="Close" (click)="onCloseConfirmationPopup()">
                        close
                    </span>
                </div>
            </div>

            <div class="modal-body py-3">
                <div class="d-flex align-items-center justify-content-center">
                    <p class="font-14 d-flex flex-column font-w600 text-center mb-0">
                        <label for="" class="">Uploaded documents will not be saved.</label>

                        <label for="" class=""> Do you want to continue?</label>
                    </p>

                </div>
            </div>

            <!-- Modal footer -->
            <div class="modal-footer bg-modal modalfooter-padding">
                <button type="button" id="btnNoThanks" class="btn btncancel px-3 py-1"
                    (click)="onCloseConfirmationPopup()" style="height: 23px;border-radius: 4px;">Cancel</button>
                <button type="button" class="btn btn-default btn_accept button-29 ms-3 w-75p" id="rejectEnvelopeBtn"
                    (click)="onConfirmationPopup()">Ok </button>
            </div>


        </div>
    </div>
</div>

<!-- New Modal popup designs -->
<!------------------------------- Drives--------------------------------------- -->
<div [ngStyle]="{'display':uploadDivStyle}" style="margin-top: 25px;" id="divUploadPopup" class="modal" tabindex="-1"
    role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content res-w-auto" style="box-shadow: rgb(14 30 37 / 60%) 0px 0px 10px;">
            <!-- Modal Header -->
            <div class="modal-header bg-modal modalhead-padding h-23m">
                <h4 class="modal-title" style="font-size: 16px;">Select Upload Option</h4>
                <a href="javascript:void(0)" class="closeSignerPopup cancel btn pe-0" data-dismiss="modal"
                    (click)="closeDivUploadPopup()">
                    <span class="material-icons modal-close">
                        close
                    </span>
                </a>
            </div>

            <!-- Modal body -->
            <div class="modal-body modal-bodyb pt-2 pb-1">
                <div class="d-flex flex-column" style="width: 100%;">
                    <ul class="drivePanel">

                        <li class="localDriveIcon">
                            <div class="d-flex align-items-center">
                                <img src="../../../assets/images/File-system.png" alt="Local-System" class="drives-img">
                                <a href="javascript:void(0)" id="localDriveUpload" (click)="fileUpload.click()"
                                    class="ms-2 primarycolor">
                                    {{getLanguageTranslationValue(resourceKeys.Localmachine)}}
                                </a>
                            </div>

                            <input type="file" class="file-input" id="fileUploadInput"
                                style="visibility: hidden; position: absolute !important;" (change)="onChange($event)"
                                #fileUpload>
                        </li>
                        <li class="googleDriveIcon drivemargin">
                            <div class="d-flex align-items-center">
                                <img src="../../../assets/images/google-drive.png" alt="Local-System"
                                    class="drives-img">
                                <a id="GoogleDriveUpload" class="ms-2 primarycolor" (click)="loadGoogleDrive()"
                                    style="cursor: pointer;"> Google Drive</a>
                            </div>
                        </li>

                        <li class="dropboxDriveIcon">
                            <div class="d-flex align-items-center">
                                <img src="../../../assets/images/drop-box.png" alt="Local-System" class="drives-img">
                                <a href="javascript:void(0)" id="DropBoxUpload" style="cursor: pointer;"
                                    class="ms-2 primarycolor" (click)="loadDropBox()">Dropbox</a>
                            </div>

                        </li>
                        <li class="skyDriveIcon drivemargin">
                            <div class="d-flex align-items-center">
                                <img src="../../../assets/images/microsoft-drive.png" alt="Local-System"
                                    class="drives-img">
                                <a href="javascript:void(0)" id="SkyDriveUpload" style="cursor: pointer;"
                                    class="ms-2 primarycolor" (click)="loadOneDrive()">Microsoft OneDrive</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- ---------------------------Attachment---------------------------- -->
<!-- Modal Header -->
<div class="modal-header bg-modal modalhead-padding">
    <h4 class="modal-title"> Add Attachments </h4>
    <div class="d-flex align-items-center">
        <span class="material-icons modal-close" aria-label="Close" (click)="onCloseAttachment();">
            close
        </span>
    </div>
</div>

<!-- Modal body -->
<div class="modal-body py-3">
    <div class="d-flex flex-column" style="width: 100%;">
        <div class="d-flex align-items-center justify-content-between mb-3">
            <div class="d-sm-none d-md-block">
                <p class="mb-0 font-15 d-flex align-items-center">

                    <i class="material-icons me-1 font-20" style="color:#667988;" aria-hidden="true" placement="right"
                        ngbPopover=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .bmp, .gif, .ico, .jpg, .jpeg, .png, .tif, .tiff, .htm, .html, .txt"
                        popoverTitle="" triggers="mouseenter:mouseleave"> info_outline</i>
                    <span class="spnattachment-text"> {{getLanguageTranslationValue(resourceKeys.SupportedDoc)}}</span>
                </p>
            </div>

            <div class="d-sm-block d-md-none">
                <div class="dropdown">
                    <a class=" dropdown-toggle primarycolor" id="dropdownMenu2" data-bs-toggle="dropdown"
                        aria-expanded="false" title="PDF, Word, Excel,
                    PowerPoint, Image, HTML, Text Document of version 2010 or later.">
                        <i class="material-icons me-1 font-29" aria-hidden="true"> info_outline</i>
                    </a>
                    <ul class="dropdown-menu supportfile" aria-labelledby="dropdownMenu2">
                        <li>
                            <p class="d-flex flex-column mb-0">
                                <span class="font-w600 font-15">Supported Files: </span>
                                <label for="" class="line-txt">.pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .bmp, .gif,
                                    .ico, .jpg,
                                    .jpeg, .png, .tif, .tiff, .htm, .html, .txt</label>
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="res-ml-auto"
                *ngIf="(IsAdditionalAttmReq == 'true' || this.isFromGroupTemplateSignerAttachments == true ) && filesResponseList && filesResponseList.EnvelopeAdditionalUploadInfoList && filesResponseList.EnvelopeAdditionalUploadInfoList.length < 10">
                <a href="javascript:void(0)" id="addNewRowAttachment"
                    class="btn btn-outline-primary d-flex align-items-center" (click)="onAddNewRow()"> <span
                        class="material-icons me-2 font-18"> add_circle_outline
                    </span>{{getLanguageTranslationValue(resourceKeys.AddNew)}} </a>
            </div>
        </div>


        <div *ngIf="webOrMobile === 'web'" class="d-sm-none d-md-block">
            <form [formGroup]="form">

                <div class="table-responsive upload-patient-details dvtblattachfilesUpload" id="tableUploadAttachment">
                    <div class="table-fixed-header" style="background: #deecf89e;">

                        <div class="column" style="border-left: 1px solid #dee2e6;flex-basis: 19.5%;">
                            <label class="title font-14"> {{getLanguageTranslationValue(resourceKeys.Name)}} <span> <i
                                        class="fa fa-asterisk text-red" aria-hidden="true"
                                        style="font-size:10px;"></i></span></label>
                        </div>
                        <div class="column " style="flex-basis: 21%;">
                            <label class="title font-14"> {{getLanguageTranslationValue(resourceKeys.Description)}}
                                <span> <i class="fa fa-asterisk text-red" aria-hidden="true"
                                        style="font-size:10px;"></i></span></label>
                        </div>
                        <div class="column" style="flex-basis: 19.5%;">
                            <label class="title font-14">
                                {{getLanguageTranslationValue(resourceKeys.AdditionalInfo)}}</label>
                        </div>
                        <div class="column" style="flex-basis: 26%;border-bottom:2px solid #dee2e6;">
                            <span class="title" style="border-bottom: none;"></span>
                        </div>
                        <div class="column"
                            style="border-right: 1px solid #dee2e6;width:120px;border-bottom:2px solid #dee2e6;">
                            <span class="title" style="border-bottom: none;"></span>
                        </div>

                    </div>

                    <div class="table-fixed" id="tbodyAttachmentInfo">
                        <div class="d-flex justify-content-between uploadsDoc"
                            *ngFor="let file of filesResponseList.EnvelopeAdditionalUploadInfoList; let i=index;"
                            id="uploadsDoc{{file.ID}}" [attr.data-env-upload-id]="file.ID"
                            [attr.date-env-envelopeid]="file.MasterEnvelopeID" data-env-uploads-filename=" ">
                            <div class="column">
                                <div class="cell" style="width: 200px;height: 50px;">
                                    <i *ngIf="file.IsRequired" style="color: #d21513;font-size: 12px;margin-left: -7px;"
                                        class="fa fa-asterisk text-red fileRequired top-5px" aria-hidden="true"></i>
                                    <span *ngIf="file.IsRequired" class="txtNameAndDescChange top-5px"
                                        id="txtName{{file.ID}}" style="margin-left: 7px; ">{{file.Name}}</span>
                                    <input style="width: 100%;" *ngIf="!file.IsRequired" type="text" class="valuechange"
                                        (change)="UpdateInputValues($event,file.ID)" autocomplete="off"
                                        [attr.data-upload-id]="getDefaultMaxUploadId(i,file.ID)" id="txtName{{file.ID}}"
                                        [attr.date-env-envelopeid]="file.MasterEnvelopeID" value="{{file.Name}}"
                                        placeholder="Document Name" maxlength="50">

                                    <input type="hidden" id="rec_{{file.ID}}" value="">
                                </div>
                            </div>

                            <div class="column">
                                <div class="cell" style="width: 215px; height: 50px;">
                                    <input style="width: 100%;" *ngIf="!file.IsRequired" type="text" class="valuechange"
                                        (change)="UpdateInputValues($event,file.ID)" autocomplete="off"
                                        [attr.data-upload-id]="file.ID" id="txtDescription{{file.ID}}"
                                        value="{{file.Description}}" placeholder="Document Description" maxlength="100">
                                    <span *ngIf="file.IsRequired" class="top-5px"
                                        id="txtDescription{{file.ID}}">{{file.Description}}</span>
                                </div>
                            </div>

                            <div class="column">
                                <div class="cell" style="width: 200px;height: 50px;">
                                    <input style="width: 100%;" *ngIf="!file.IsRequired" type="text" class="valuechange"
                                        (change)="UpdateInputValues($event,file.ID)" autocomplete="off"
                                        [attr.data-upload-id]="file.ID" type="text" id="txtAdditionalInfo{{file.ID}}"
                                        placeholder="Additional Info" value="{{file.AdditionalInfo}}" maxlength="100">
                                    <span *ngIf="file.IsRequired" class="top-5px"
                                        id="txtAdditionalInfo{{file.ID}}">{{file.AdditionalInfo}}</span>
                                </div>

                            </div>

                            <div class="column">
                                <input type="hidden" id="uploadedFileName_{{file.ID}}" value="{{file.FileName}}">
                                <input type="hidden" id="FileName_{{file.ID}}" value="{{file.FileName}}">
                                <input type="hidden" id="OriginalFileName_{{file.ID}}"
                                    value="{{file.OriginalFileName}}">
                                <div class="cell" style="width:350px; height: 50px;">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <input type="text" value="{{file.OriginalFileName}}" class="attachmentFilePath"
                                            disabled="disabled" id="fileNameInput_{{file.ID}}" style="width:100%;">
                                        <button class="btn btn-outline-primary uploadAttachments ms-3"
                                            id="Upload_{{file.ID}}" (click)="onOpenAddAttachment(file)">Upload</button>
                                    </div>
                                </div>
                            </div>

                            <div class="column" style="width:13%;">
                                <div class="cell" style="height: 50px;  border-right: 1px solid #dee2e6;">
                                    <a href="javascript:void(0)" class="" id="lnkGreenTick_{{file.ID}}"
                                        [ngClass]="getfileCheckedColor(file.ID)"
                                        style="padding-top:2px;padding-left:5px;vertical-align:middle;cursor: no-drop;">
                                        <span title="Upload Successful"
                                            class="material-icons fa-iconsSize font-20 mt-2"> check_circle </span>
                                        <!-- <i class="fa fa-check-circle fa-iconsSize" aria-hidden="true"></i> -->
                                    </a>
                                    <a *ngIf="!file.IsRequired" href="javascript:void(0)" class="text-blue"
                                        title="Save Attachment Row" id="lnkSave_{{file.ID}}"
                                        [ngClass]="getSaveCheckedColor(file.ID)" (click)="onSaveAttachFile(file, true)"
                                        style="padding-left:7px;vertical-align:middle;">
                                        <!-- <i class="fa fa-save text-blue fa-iconsSize SaveAttachmentRow"
                                    aria-hidden="true"></i> -->
                                        <span
                                            class="material-icons fa-iconsSize SaveAttachmentRow primarycolor font-20 mt-2">
                                            save </span>
                                    </a>
                                    <a class="deleteAttachment" (click)="onFileRemove(file)" title="Delete Attachment"
                                        id="deleteUpload_{{file.ID}}" [ngClass]="getDeleteCheckedColor(file.ID)"
                                        style="padding-left:5px;vertical-align:middle;">
                                        <!-- <i class="fa fa-trash-o deleteUploadedAttachment fa-iconsSize"
                                    aria-hidden="true"></i> -->
                                        <span class="material-icons deleteUploadedAttachment fa-iconsSize font-22 mt-2">
                                            delete </span>
                                    </a>
                                    <a *ngIf="!file.IsRequired" href="javascript:void(0)"
                                        class="deleteAttachmentRow cursor-pointer text-red"
                                        title="Delete Attachment Row" id="lnkDeleteRow_{{file.ID}}"
                                        style="padding-top:2px;padding-left:5px;vertical-align:middle;"
                                        (click)="onRowDelete(file.ID)">
                                        <!-- <i class="fa fa-times fa-iconsSize deleteAttachmentRow" aria-hidden="true"></i> -->
                                        <span class="material-icons deleteAttachmentRow font-20 mt-2"> cancel </span>
                                    </a>
                                </div>
                            </div>
                        </div>




                    </div>
                </div>

                <div id="dvmandatoryrecCount">
                    <span> <i class="fa fa-asterisk text-red" aria-hidden="true" style="font-size:12px"></i> -
                        {{getLanguageTranslationValue(resourceKeys.Required)}}</span>
                </div>
            </form>
        </div>


        <div *ngIf="webOrMobile === 'mobile'" class="d-sm-block d-md-none card-overflow style-scrollbar">
            <div class="uploadsDoc me-2"
                *ngFor="let file of filesResponseList.EnvelopeAdditionalUploadInfoList; let i=index;"
                id="uploadsDoc{{file.ID}}" [attr.data-env-upload-id]="file.ID"
                [attr.date-env-envelopeid]="file.MasterEnvelopeID" data-env-uploads-filename=" ">
                <div class="card box-shadow-light mb-3">
                    <div class="card-body">
                        <div class="mb-3">
                            <label for="exampleFormControlInput1" class="form-label label-font14">
                                {{getLanguageTranslationValue(resourceKeys.Name)}} <span> <i
                                        class="fa fa-asterisk text-red" aria-hidden="true"
                                        style="font-size:10px;"></i></span></label>
                            <div class="border-boxsize">
                                <i *ngIf="file.IsRequired" style="color: #d21513;font-size: 12px;margin-left: -7px;"
                                    class="fa fa-asterisk text-red fileRequired" aria-hidden="true"></i>
                                <input *ngIf="!file.IsRequired" type="text" class="form-control"
                                    (change)="UpdateInputValues($event,file.ID)" autocomplete="off"
                                    [attr.data-upload-id]="getDefaultMaxUploadId(i,file.ID)" id="txtName{{file.ID}}"
                                    [attr.date-env-envelopeid]="file.MasterEnvelopeID" value="{{file.Name}}"
                                    placeholder="Document Name" maxlength="50">
                                <span *ngIf="file.IsRequired" class="txtNameAndDescChange" style="margin-left: 7px;"
                                    id="txtName{{file.ID}}">{{file.Name}}</span>
                                <input type="hidden" id="rec_{{file.ID}}" value="">
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="exampleFormControlInput1" class="form-label label-font14">
                                {{getLanguageTranslationValue(resourceKeys.Description)}} <span> <i
                                        class="fa fa-asterisk text-red" aria-hidden="true"
                                        style="font-size:10px;"></i></span></label>
                            <input type="hidden" id="uploadedFileName_{{file.ID}}" value="{{file.FileName}}">
                            <input type="hidden" id="FileName_{{file.ID}}" value="{{file.FileName}}">
                            <input type="hidden" id="OriginalFileName_{{file.ID}}" value="{{file.OriginalFileName}}">
                            <div class="border-boxsize">
                                <input *ngIf="!file.IsRequired" type="text" class="form-control"
                                    (change)="UpdateInputValues($event,file.ID)" autocomplete="off"
                                    [attr.data-upload-id]="file.ID" id="txtDescription{{file.ID}}"
                                    value="{{file.Description}}" placeholder="Document Description" maxlength="100">
                                <span *ngIf="file.IsRequired" id="txtDescription{{file.ID}}">{{file.Description}}</span>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="exampleFormControlInput1" class="form-label label-font14">
                                {{getLanguageTranslationValue(resourceKeys.AdditionalInfo)}}</label>
                            <div class="border-boxsize">
                                <input *ngIf="!file.IsRequired" type="text" class="form-control"
                                    (change)="UpdateInputValues($event,file.ID)" autocomplete="off"
                                    [attr.data-upload-id]="file.ID" type="text" id="txtAdditionalInfo{{file.ID}}"
                                    placeholder="Additional Info" value="{{file.AdditionalInfo}}" maxlength="100">
                                <span *ngIf="file.IsRequired"
                                    id="txtAdditionalInfo{{file.ID}}">{{file.AdditionalInfo}}</span>
                            </div>
                        </div>
                        <div class="d-flex ">
                            <input type="text" value="{{file.OriginalFileName}}" class="attachmentFilePath form-control"
                                disabled="disabled" id="fileNameInput_{{file.ID}}">
                        </div>
                        <div class="d-flex justify-content-between mt-3 mb-0">
                            <div class="d-flex align-items-center">
                                <a href="javascript:void(0)" class="" id="lnkGreenTick_{{file.ID}}"
                                    [ngClass]="getfileCheckedColor(file.ID)"
                                    style="padding-top:2px;padding-left:10px;vertical-align:middle; cursor: no-drop;">
                                    <span class="material-icons fa-iconsSize font-24 mt-2"> check_circle </span>
                                </a>
                                <a *ngIf="!file.IsRequired" href="javascript:void(0)" class="text-blue"
                                    title="Save Attachment Row" id="lnkSave_{{file.ID}}"
                                    [ngClass]="getSaveCheckedColor(file.ID)" (click)="onSaveAttachFile(file, true)"
                                    style="padding-left:12px;vertical-align:middle;">
                                    <span
                                        class="material-icons fa-iconsSize SaveAttachmentRow primarycolor font-24 mt-2">
                                        save </span>
                                </a>
                                <a class="deleteAttachment" (click)="onFileRemove(file)" title="Delete Attachment"
                                    id="deleteUpload_{{file.ID}}" [ngClass]="getDeleteCheckedColor(file.ID)"
                                    style="padding-left:10px;vertical-align:middle;">
                                    <span class="material-icons deleteUploadedAttachment fa-iconsSize font-24 mt-2">
                                        delete </span>
                                </a>
                                <a *ngIf="!file.IsRequired" href="javascript:void(0)"
                                    class="deleteAttachmentRow text-red cursor-pointer" title="Delete Attachment Row"
                                    id="lnkDeleteRow_{{file.ID}}"
                                    style="padding-top:2px;padding-left:10px;vertical-align:middle;"
                                    (click)="onRowDelete(file.ID)">
                                    <span class="material-icons deleteAttachmentRow font-24 mt-2"> cancel </span>
                                </a>
                            </div>
                            <button class="btn btn-outline-primary uploadAttachments uploadAttachments1"
                                id="Upload_{{file.ID}}" (click)="onOpenAddAttachment(file)">Upload</button>
                        </div>
                    </div>
                </div>
            </div>

            <div id="dvmandatoryrecCount">
                <span> <i class="fa fa-asterisk text-red" aria-hidden="true" style="font-size:12px"></i> -
                    {{getLanguageTranslationValue(resourceKeys.Required)}}</span>
            </div>
        </div>


    </div>
</div>

<!-- Modal footer -->
<div class="modal-footer bg-modal modalfooter-padding">
    <button type="button" id="btnNoThanks" class="btn btncancel px-3 py-1" (click)="onCloseAttachment();"
        style="height: 23px;border-radius: 4px;">Cancel</button>
    <button type="button" class="btn btn-default btn_accept button-29 ms-3" id="submitEnvelopeBtn"
        (click)="getFinalAttachmentValidation()">Submit </button>
</div>


<div *ngIf="showLoader" class="loader">
</div>