<app-header></app-header>
<main>
    <div class="main-container">
        <div class="container">
            <div class="row">
                <div class="wrapper fadeInDown">
                    <div *ngIf="isSuccessFlag" class="formContent">
                        <div class="front-arrow" style="text-align: center;">
                            <img src="../../../assets/images/success_info.png" style="width:60px;" />
                            <p class="my-3 ps-3 pe-2"> {{displayMessage}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>

<footer style="background: #eee;" class="default-footer mt-auto pt-2 pb-2">
    <div class="container1" style="font-size: 10px;">
        <div  class="fotertext" [innerHTML]="htmlFooterString">
        </div>
    </div>
</footer>

<div *ngIf="showLoader" class="loader">
</div>