import { Component, OnInit, Renderer2, ElementRef, ViewChild, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import SignaturePad from 'signature_pad';
import { CommonConstants, Controls, ControlsName, Defaults, ResourceKeys, SignatureType, UploadDriveType } from 'src/app/constants/common-constants';
import { SignerLandingService } from 'src/app/services/signer-landing.service';
import { TranslationsService } from 'src/app/services/translations.service';
import { environment } from 'src/environments/environment';
import { throttle } from 'lodash';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
declare let gapi: any;
declare let google: any
declare var Dropbox: Dropbox;
declare var OneDrive: OneDrive; 
interface Dropbox {
  choose(options: DropboxChooseOptions): void;
}
interface DropboxChooseOptions {
  success(files: DropboxFile[]): void;
  cancel?(): void;
  linkType: "direct";
  multiselect: boolean;
  extensions?: string[];
}
interface DropboxFile {
  name: string;
  link: string;
  bytes: number;
  icon: string;
  thumbnailLink?: string;
  isDir: boolean;
}

interface OneDriveResult {
  value: OneDriveFile[];
  webUrl: string | null;
}
interface OneDriveFile {
  "@microsoft.graph.downloadUrl": string;
  "thumbnails@odata.context": string;
  id: string;
  name: string;
  size: number;
  thumbnails: Thumbnails[];
  webUrl: string;
}
interface Thumbnails {
  id: string;
  large: Thumbnail;
  medium: Thumbnail;
  small: Thumbnail;
}

interface Thumbnail {
  height: number;
  width: number;
  url: string;
}
interface OneDriveOpenOptions {
  clientId: string;
  action: "download" | "share" | "query";
  multiSelect: boolean;
  openInNewWindow: boolean;
  advanced: {
    filter?: string;
    redirectUri: string
  }
  success(result: OneDriveResult): void;
  cancel(): void;
  error(e: any): void;
}
interface OneDrive {
  open(options: OneDriveOpenOptions): void;
}

@Component({
  selector: 'app-rclick-signature',
  templateUrl: './rclick-signature.component.html',
  styleUrls: ['./rclick-signature.component.scss']
})

export class RclickSignatureComponent implements OnInit {
  signaturePad: any;

  @ViewChild('canvas', { static: false })
  canvasEl!: ElementRef;

  @ViewChild('dvHandDrawn', { static: false })
  dvHandDrawn!: ElementRef;

  files: any[] = [];
  signatureFontObj: any;
  elementVar: any;
  dragdropImageSrc: any;
  signControlObj: any;
  signatureImg: string | undefined;
  signatureText: string = '';
  selectedRadioBtn: string = 'signerSignatureType';
  typeSignature: boolean = true;
  showLoader: boolean = true;
  processing: boolean = false;
  signatureContainer: boolean = false;
  uploadSignatureContainer: boolean = false;
  showFileSelectionOptions: boolean = false;
  documentControlsResponse: any;
  showPolishSignatureDiv: boolean = false;

  signatureCaptureTypeDisplay: boolean = false;
  SignatureCaptureType: boolean = false;
  SignatureCaptureHanddrawn: boolean = false;
  UploadSignature: boolean = false;

  signatureHandDrwanDisplay: boolean = false;
  signatureuploadDisplay: boolean = false;
  uploadSignatureDisplay: any;
  signatureInputText: string = '';
  signaturePoishFontObj: any;
  PolishCharsData: any;
  signFontColor: any;
  signatureCaptureTypeTextDisplay: any;
  webOrMobile: string = 'web';
  public filterCheckListSingature: EventEmitter<any> = new EventEmitter();
  googleDriveClientId = environment.googleDriveClientIdKey;
  oneDriveApplicationId = environment.oneDriveApplicationId;
  gooleDriveScope = ['https://www.googleapis.com/auth/drive.readonly'].join(' ');
  pickerApiLoaded = false;
  oauthToken: any;
  oauthData: any;
  extCheck: boolean = true;
  uploadStyle: string = 'none';
  resourceKeys = ResourceKeys;
  public isSignaturePopupClosed: EventEmitter<any> = new EventEmitter();
  confirmationPopupStyle: string = 'none';
  uploadType: string = "";
  dropBoxFileImageLink: string = "";
  dropBoxFile: any;
  oneDriveFile: any;
  oneDriveFileData: any = [];
  googleDrivePreparedObject: any;
  canvas: any;

  constructor(
    //public activeModal: NgbActiveModal,
    private renderer2: Renderer2,
    private elementRef: ElementRef,
    private toastr: ToastrService,
    private signerLandingService: SignerLandingService,
    private translationsService: TranslationsService
  ) {
    this.setupResizeListener();
  }

  ngOnInit(): void {
    if ((/android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
      this.webOrMobile = 'mobile';
    }
    this.signControlObj = this;
    this.showLoader = false;
    this.signatureFontObj = [
      { id: 1, 'fontFamily': 'HomemadeApple' }, { id: 2, 'fontFamily': 'DawningofaNewDay' }, { id: 3, 'fontFamily': 'Kristi' }, { id: 4, 'fontFamily': 'LaBelleAurore' },
      { id: 5, 'fontFamily': 'OvertheRainbow' }, { id: 6, 'fontFamily': 'Brush' }
    ]

    this.signaturePoishFontObj = [
      { id: 1, 'fontFamily': 'DancingScript' }, { id: 2, 'fontFamily': 'Parisienne' }, { id: 3, 'fontFamily': 'Allison' }, { id: 4, 'fontFamily': 'LaBelleAurorePolish' },
      { id: 5, 'fontFamily': 'OvertheRainbowPolish' }, { id: 6, 'fontFamily': 'GreatVibes' }
    ]

    this.PolishCharsData = ['ę', 'ą', 'ś', 'ć', 'ź', 'ż', 'ł'];

    // let documentControlsResp: any = localStorage.getItem("documentControlsResponse");
    // this.documentControlsResponse = JSON.parse(documentControlsResp);

    // this.SignatureCaptureType = this.documentControlsResponse.EnvelopeInfo.SignatureCaptureType;
    // this.SignatureCaptureHanddrawn = this.documentControlsResponse.EnvelopeInfo.SignatureCaptureHanddrawn;
    // this.UploadSignature = this.documentControlsResponse.EnvelopeInfo.UploadSignature;

    // this.signatureCaptureTypeDisplay = this.SignatureCaptureType ? true : false;
    // this.signatureHandDrwanDisplay = this.SignatureCaptureType ? false : true;
    // this.signatureuploadDisplay = this.SignatureCaptureType ? false : true;

    this.signatureContainer = true; //this.SignatureCaptureType;
    // this.typeSignature = this.signatureContainer;
    // this.signatureCaptureTypeTextDisplay = this.signatureCaptureTypeDisplay ? "block" : "none";
    // this.uploadSignatureContainer = this.signatureuploadDisplay;

    let strogaeSignatureInputText: any = localStorage.getItem("signatureInputText");
    let storageSignatureText: any = localStorage.getItem("signatureText");

    if (strogaeSignatureInputText != "" && strogaeSignatureInputText != null) {
      this.signatureInputText = strogaeSignatureInputText;
      this.signatureText = storageSignatureText;
    }
    else {
      // let signatureCtrlTxt = this.documentControlsResponse.EnvelopeInfo.ControlsData[0].SignatureText;

      // if (signatureCtrlTxt == null) {
      //     let sigResourceKeyArray: any = this.documentControlsResponse.Language.filter((c: any) => c.ResourceKeyID.toLowerCase() == Controls.Signature.toLowerCase());
      //     if (sigResourceKeyArray.length > 0) {
      //         let languageSignaturArr:any = ['Assinatura','Unterschrift','Semnătură','Parašas','Handtekening','Firma','Signature','Podpis','Paraksts','Aláírás','Signatur'];
      //         let checkLanguageSignaturArr: any = languageSignaturArr.filter((c: any) => c.toLowerCase() == sigResourceKeyArray[0].KeyValue.toLowerCase());
      //         if(checkLanguageSignaturArr.length > 0){
      //             this.signatureText = '';
      //         } else {
      //             this.signatureText = sigResourceKeyArray[0].KeyValue;
      //         }
      //     }
      // }
      // else {
      //     this.signatureText = this.documentControlsResponse.EnvelopeInfo.ControlsData[0].SignatureText;
      //     this.signatureInputText = this.signatureText;
      // }

      if (this.signatureText.toLowerCase() == ControlsName.Signature.toLowerCase()) {
        this.signatureInputText = "";
      }

      if (this.signatureInputText.toLowerCase() == ControlsName.Signature.toLowerCase()) {
        this.signatureInputText = "";
      }
    }
    // let txtsignerProfileName: any = this.signControlObj.ClickToSignsignatureText;
    // if (this.isNullOrEmptyCheck(txtsignerProfileName)) {
    //     this.signatureText = txtsignerProfileName;
    //     this.signatureInputText = txtsignerProfileName;
    // }

    // let filelistInfo: any = document.getElementById("filelistInfo");
    // filelistInfo.style["display"] = "none";
    // let typeSign: any = this.signControlObj.type;
    // if (typeSign == "upload") {
    //     this.signatureCaptureTypeDisplay = false;
    //     this.signatureContainer = false;
    //     this.typeSignature = false;
    //     this.signatureHandDrwanDisplay = false;
    //     this.uploadSignatureContainer = true;
    //     this.signatureuploadDisplay = true;
    //     this.signatureCaptureTypeTextDisplay = this.signatureCaptureTypeDisplay ? "block" : "none";
    //     this.selectedRadioBtn = "signerSignatureUpload";
    //     document.getElementById("select-type")!.classList.remove("active", "show");
    //     document.getElementById("select-upload")!.classList.add("active");
    //     document.getElementById("select-upload")!.classList.add("show");
    //     document.getElementById("select-type-tab")!.classList.remove("active");
    //     document.getElementById("select-upload-tab")!.classList.add("active");
    //     document.getElementById("select-upload-tab")!.focus();

    //     let userData: any = this.documentControlsResponse.userdata;
    //     let userInitialsImgSrc: any = userData.UserInitialsImgSrc;
    //     let imgUploadsignature: any = document.getElementById('imgUploadsignature');
    //     if (userInitialsImgSrc === null || userInitialsImgSrc === undefined) {
    //         if (imgUploadsignature != undefined && imgUploadsignature != null) {
    //             imgUploadsignature.setAttribute('src', '../../../assets/images/upload2.png');
    //         }
    //     }
    //     else {
    //         if (imgUploadsignature != undefined && imgUploadsignature != null) {
    //             imgUploadsignature.setAttribute("src", userData.UserInitialsImgSrc);
    //             localStorage.setItem('imgUploadsignatureUpload', userData.UserInitialsImgSrc);
    //         }
    //     }
    // }
    // else {
    //     this.onSignatureSubmit('isPreview');
    // }

    this.onSignatureSubmit('isPreview');
  }

  ngAfterViewInit() {
    this.renderer2.setStyle(this.dvHandDrawn.nativeElement, 'display', 'none');
    // this.signFontColor = this.signControlObj.dataFontColor;
    // if (this.signFontColor == null || this.signFontColor == ""){
    //     this.signFontColor = "#000000";
    // }

    this.signFontColor = "#000000";

    if (this.canvasEl) {
      this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
      this.signaturePad.penColor = this.signFontColor;
    }
    this.setFontTypeLabelChecked();
    this.canvas = document.querySelector("canvas");

    //by default show signFontColor
    let signatureTextClass: any = document.getElementsByClassName("signatureText");
    Array.from(signatureTextClass).forEach((item: any) => {
      item!.style.color = this.signFontColor;
    });
  }

  setupResizeListener() {
    const throttledResizeHandler = throttle(this.resizeCanvas, 100);
    window.addEventListener('resize', throttledResizeHandler);
  }

  resizeCanvas() {
    this.canvas = document.getElementById('signature-pad');
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    if (this.canvas) {
      this.canvas.width = this.canvas.offsetWidth * ratio;
      this.canvas.height = this.canvas.offsetHeight * ratio;
      this.canvas.getContext("2d").scale(ratio, ratio);
    }
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.resizeCanvas);
  }

  getCanvasWidth() {
    // let width: any = (this.webOrMobile === 'web') ? 528 : 370;
    let signatureCanvasWidth: any = document.getElementById('signatureCanvas');
    let width: any;
    if (this.webOrMobile === 'mobile') {
      if (signatureCanvasWidth.offsetWidth > 550) {
        width = window.innerWidth - 12;
      } else {
        width = signatureCanvasWidth.offsetWidth - 2;
      }
    } else {
      width = window.innerWidth - 12;
    }
    return width;
  }

  getCanvasHeight() {
    let height: any = (this.webOrMobile === 'web') ? 113 : 150;
    return height;
  }

  setFontTypeLabelChecked() {
    let slFont: any = localStorage.getItem('selectedFont');
    if (this.isNullOrEmptyCheck(slFont)) {
      let slFontTypeLabelInput: any = document.getElementById(slFont);
      if (slFontTypeLabelInput != null) {
        slFontTypeLabelInput.setAttribute("checked", "checked");
      }
    }
    else {
      if (!this.showPolishSignatureDiv) {
        let slFontTypeLabelInput: any = document.getElementById("HomemadeApple");
        if (slFontTypeLabelInput != null) {
          slFontTypeLabelInput.setAttribute("checked", "checked");
        }
        localStorage.setItem('selectedFont', "HomemadeApple");
      }
      else if (this.showPolishSignatureDiv) {
        let slFontTypeLabelInput: any = document.getElementById("DancingScript");
        if (slFontTypeLabelInput != null) {
          slFontTypeLabelInput.setAttribute("checked", "checked");
        }
        localStorage.setItem('selectedFont', "DancingScript");
      }
    }
  }

  startDrawing(event: Event) {
    //  console.log(event);
    // works in device not in browser
  }

  moved(event: Event) {
    // works in device not in browser
  }

  showToaster(msg: string, errorType: any) {
    if (errorType === "Error") {
      this.toastr.error(msg, errorType, {
        timeOut: 2000
      });
    }
    else if (errorType === "Warning") {
      this.toastr.warning(msg, errorType, {
        timeOut: 5000
      });
    }
  }

  clearSignautePadAndUpload() {
    this.files = [];
    if (this.signaturePad) {
      this.signaturePad.clear();

      this.signatureText = '';
      this.signatureInputText = '';

      let imgProfileSignaturePreview: any = document.getElementById('imgProfileSignaturePreview');
       imgProfileSignaturePreview.setAttribute('src', '');

      let imgProfileSignatureDrawPreview: any = document.getElementById('imgProfileSignatureDrawPreview');
      if (imgProfileSignatureDrawPreview != undefined && imgProfileSignatureDrawPreview != null) {
        imgProfileSignatureDrawPreview.setAttribute('src', '');
      }
    }

    this.signatureContainer = true;
    setTimeout(() => {
      this.setFontTypeLabelChecked();
      this.onSignatureSubmit('isPreview');
    }, 500);
  }

  onSignatureTypeAndUpload() {
    this.files = [];
   // let userData: any = this.documentControlsResponse.userdata;
    let userInitialsImgSrc: any = localStorage.getItem('imgProfileSignatureSrc');// userData.UserInitialsImgSrc;
    let imgUploadsignature: any = document.getElementById('imgUploadsignature');
    if (userInitialsImgSrc === null || userInitialsImgSrc === undefined) {
      if (imgUploadsignature != undefined && imgUploadsignature != null) {
        imgUploadsignature.setAttribute('src', '../../../assets/images/upload2.png');
      }
      this.onFileRemove();
    }
    else {
      if (imgUploadsignature != undefined && imgUploadsignature != null) {
        imgUploadsignature.setAttribute("src", localStorage.getItem('imgProfileSignatureSrc')); //userData.UserInitialsImgSrc 
        //localStorage.setItem('imgUploadsignatureUpload', userData.UserInitialsImgSrc);
      }
    }
  }

  onSignatureDraw() {
    this.files = [];
    this.signaturePad.clear();
    let imgProfileSignatureDrawPreview: any = document.getElementById('imgProfileSignatureDrawPreview');
    if (imgProfileSignatureDrawPreview != undefined && imgProfileSignatureDrawPreview != null) {
      imgProfileSignatureDrawPreview.setAttribute('src', '');
    }
    this.onFileRemove();
  }

  clearPad() {
    this.clearSignautePadAndUpload();
    this.files = [];
  }

  isNullOrEmptyCheck(stringValueFlag: any) {
    let boolFlag = false;
    if (typeof stringValueFlag != 'undefined' && stringValueFlag !== '' && stringValueFlag !== null && stringValueFlag !== 'false' && typeof stringValueFlag != undefined) {
      boolFlag = true;
    }
    return boolFlag;
  }

  getSelectedTab() {
    this.selectedRadioBtn = 'signerSignatureType';
    if (document.getElementById('select-draw-tab')!.classList.contains('active')) {
      this.selectedRadioBtn = 'signerSignatureHandDrawn';
    }
    // else if (document.getElementById('select-upload-tab')!.classList.contains('active')) {
    //     this.selectedRadioBtn = 'signerSignatureUpload';
    // }
    return this.selectedRadioBtn;
  }

  // validating the signature type or handDrawn.
  onSignatureSubmit(onSignatureSubmitOption: any) {
    this.getSelectedTab();
    let isSignControlIdExists: any = this.signControlObj.signControlId;
    if (this.isNullOrEmptyCheck(isSignControlIdExists)) {
      if (this.selectedRadioBtn === 'signerSignatureType') {
        if (this.signatureInputText.trim() !== '') {
          var count = this.signatureInputText.trim().length;
          if (count == 0) {
            this.showToaster('Name is required.', 'Warning');
          }
          else if (count > 50) {
            this.showToaster('Only 50 characters allowed for signature.', 'Warning');
          }
          else {
            localStorage.setItem("data-sign-text", this.signatureInputText.trim());
            localStorage.setItem('signatureTypeID', SignatureType.Auto);
            let userfont = localStorage.getItem('selectedFont');

            if (userfont == "OvertheRainbowPolish") {
              userfont = "OvertheRainbow";
            }
            else if (userfont == "LaBelleAurorePolish") {
              userfont = "LaBelleAurore";
            } else if (userfont == null) {
              if (this.showPolishSignatureDiv) {
                userfont = 'DancingScript';
              } else {
                userfont = 'HomemadeApple';
              }
            }

            let height = "13";
            let width = "13";
            let fontsize = "13px";

            //Call API ConvertTextToSignImage
            let requestObj = {
              text: this.signatureText.trim(),
              font: userfont,
              fontsize: fontsize,
              fontColor: this.signFontColor,
              height: height,
              width: width,
              envelopeCode: "",
              electronicSignIndicationId: "3",
              dateFormat: "mm-dd-yy",
              userTimezone: "India Standard Time",
              dateFormatID: "9fc73c2b-34d7-42a1-b2a6-702ed2fd312b"
            };

            let url = CommonConstants.ConvertTextToSignImage;
            this.signerLandingService.convertTextToSignImage(url, requestObj).subscribe(
              (resp: any) => {
                this.showLoader = false;
                if (resp && resp.StatusCode == 200) {
                   // let ctrlItem: any = document.getElementById(this.signControlObj.signControlId);
                  // if (this.signControlObj.signControlId != "FooterSignNav") {
                  //     let reuirdControls = ctrlItem.attributes["data-rq"].value;
                  //     let requiredLength = ctrlItem.getElementsByClassName("requiredControls").length;
                  //     let isStaticTemplate: any = localStorage.getItem("isStaticTemplate");
                  //     if (requiredLength > 0 && (this.documentControlsResponse.EnvelopeInfo.IsSingleSigning == false || isStaticTemplate)) {
                  //         let allRequiredSignatureControls: any = this.getAllDocumentSignatureControls();
                  //         Array.from(allRequiredSignatureControls).forEach((sigCtrl: any) => {
                  //             isStaticTemplate = Boolean(isStaticTemplate);
                  //             if (isStaticTemplate && this.documentControlsResponse.AllowStaticMultiSigner) {
                  //                 //This is for static template
                  //                 this.addSignatureTypeImageSrc(sigCtrl, resp, this.signatureText.trim(), onSignatureSubmitOption, true);
                  //             }
                  //             else {
                  //                 this.addSignatureTypeImageSrc(sigCtrl, resp, this.signatureText.trim(), onSignatureSubmitOption, false);
                  //             }
                  //         });
                  //         if (onSignatureSubmitOption === 'Ok') {
                  //             let footerSignNav: any = document.getElementById("FooterSignNav");
                  //             if (footerSignNav != undefined && footerSignNav != null) {
                  //                 let footerSignNavCol = footerSignNav.getElementsByTagName('img');
                  //                 if (footerSignNavCol != undefined && footerSignNavCol != null && footerSignNavCol.length > 0) {
                  //                     footerSignNavCol[0].setAttribute("src", resp.ResponseConvertTextToSignImage.imgsrc);
                  //                     footerSignNav.getElementsByClassName("controlIndicator")[0].classList.add("filled");
                  //                     footerSignNav.getElementsByClassName("controlIndicator")[0].classList.add("filledFooterSignatureControl");
                  //                     this.applyStyleFordefaultSignNavControlIndicator();
                  //                 }
                  //             }
                  //         }
                  //     }
                  //     else {
                  //         this.addSignatureTypeImageSrc(ctrlItem, resp, this.signatureText.trim(), onSignatureSubmitOption, false);
                  //         let footerSignNav: any = document.getElementById("FooterSignNav");
                  //         if (footerSignNav != undefined && footerSignNav != null) {
                  //             let launguageSignImageName: string = "";
                  //             let launguageSignImageNameArray = this.documentControlsResponse.Language.filter((c: any) => c.KeyName == "lang_yourNameImg");
                  //             if (launguageSignImageNameArray.length > 0) {
                  //                 launguageSignImageName = launguageSignImageNameArray[0].KeyValue;
                  //             }
                  //             let defaultImage = Defaults.DefaultImages + launguageSignImageName + ".png";
                  //             let footerSignNavCol = footerSignNav.getElementsByTagName('img');
                  //             if (footerSignNavCol != undefined && footerSignNavCol != null && footerSignNavCol.length > 0) {
                  //                 if (footerSignNavCol[0].attributes["src"].value == defaultImage) {
                  //                     footerSignNavCol[0].setAttribute("src", resp.ResponseConvertTextToSignImage.imgsrc);
                  //                     footerSignNav.getElementsByClassName("controlIndicator")[0].classList.add("filled");
                  //                     footerSignNav.getElementsByClassName("controlIndicator")[0].classList.add("filledFooterSignatureControl");
                  //                     this.applyStyleFordefaultSignNavControlIndicator();
                  //                 }
                  //             }
                  //         }
                  //     }
                  // }
                  // else {
                  //     if (onSignatureSubmitOption === 'Ok') {
                  //         let footerSignNav: any = document.getElementById("FooterSignNav");
                  //         if (footerSignNav != undefined && footerSignNav != null) {
                  //             let footerSignNavCol = footerSignNav.getElementsByTagName('img');
                  //             if (footerSignNavCol != undefined && footerSignNavCol != null && footerSignNavCol.length > 0) {
                  //                 footerSignNavCol[0].setAttribute("src", resp.ResponseConvertTextToSignImage.imgsrc);
                  //                 footerSignNav.getElementsByClassName("controlIndicator")[0].classList.add("filled");
                  //                 footerSignNav.getElementsByClassName("controlIndicator")[0].classList.add("filledFooterSignatureControl");
                  //                 this.applyStyleFordefaultSignNavControlIndicator();
                  //             }
                  //         }
                  //     }
                  // }

                  let imgProfileSignature: any = document.getElementById("imgProfileSignaturePreview");
                  if (imgProfileSignature != undefined && imgProfileSignature != null) {
                    imgProfileSignature.setAttribute("src", resp.ResponseConvertTextToSignImage.imgsrc);
                  }

                  if (onSignatureSubmitOption === 'Ok') {
                    this.filterCheckListSingature.next('true');
                    // this.activeModal.dismiss('Cross click');
                  }
                }
              });
          }
        } else {
          if (onSignatureSubmitOption === 'Ok') {
            this.showToaster('Type your name.', 'Warning');
          }
        }
      }
      else if (this.selectedRadioBtn === 'signerSignatureHandDrawn') {
        if (this.signaturePad && this.signaturePad.isEmpty()) {
          if (onSignatureSubmitOption === 'Ok') {
            this.showToaster('Signature is required.', 'Warning');
          }
        }
        else {
          if (this.signaturePad && onSignatureSubmitOption != 'isPreview') {
            const base64Data = this.signaturePad.toDataURL();
            this.signatureImg = base64Data;
            localStorage.setItem('signatureTypeID', SignatureType.Hand);
            let electronicSignIndication: number = this.documentControlsResponse.EnvelopeInfo.ElectronicSignIndication;
            if (electronicSignIndication > 1) {
              //Call API ConvertTextToSignImage
              let requestObj = {
                imageBytes: this.signatureImg,
                envelopeCode: "",
                electronicSignIndicationId: "3",
                dateFormat: "mm-dd-yy",
                userTimezone: "India Standard Time",
                dateFormatID: "9fc73c2b-34d7-42a1-b2a6-702ed2fd312b"
              };

              let url = CommonConstants.ConvertHandDrawnSignImage;
              this.showLoader = true;
              this.signerLandingService.ConvertHandDrawnSignImage(url, requestObj).subscribe(
                (resp: any) => {
                  if (resp && resp.StatusCode == 200) {
                    this.signatureImg = resp.ResponseConvertTextToSignImage.imgsrc;
                    setTimeout(() => {
                      this.applyHandDrawnImage(onSignatureSubmitOption);
                    }, 200);
                  }
                  else {
                    this.showLoader = false;
                  }
                });
            }
            else {
              setTimeout(() => {
                this.applyHandDrawnImage(onSignatureSubmitOption);
              }, 200);
            }
          }
        }
      }
      else if (this.selectedRadioBtn === 'signerSignatureUpload') {
        localStorage.setItem('signatureTypeID', SignatureType.UploadSignature);
        let uploadSigntaureImageSrc: any = document.getElementById("imgUploadsignature");
        let base64UploadSigntaureImageSrcString = uploadSigntaureImageSrc.attributes["src"].value;
        if (this.files.length || (base64UploadSigntaureImageSrcString !== undefined && base64UploadSigntaureImageSrcString !== null &&
          base64UploadSigntaureImageSrcString !== "")) {
          if (base64UploadSigntaureImageSrcString == undefined || base64UploadSigntaureImageSrcString == null ||
            base64UploadSigntaureImageSrcString == "") {
            this.toastr.warning('Upload signature is required.', 'Warning', { timeOut: 1000 });
          }
          else {
            let requestObj = {
              imageBytes: base64UploadSigntaureImageSrcString,
              envelopeCode: "",
              electronicSignIndicationId: "3",
              dateFormat: "mm-dd-yy",
              userTimezone: "India Standard Time",
              dateFormatID: "9fc73c2b-34d7-42a1-b2a6-702ed2fd312b"
            };

            let url = CommonConstants.ConvertHandDrawnSignImageUpload;
            this.showLoader = true;
            this.signerLandingService.convertHandDrawnSignImageUpload(url, requestObj).subscribe(
              (resp: any) => {
                this.showLoader = false;
                if (resp && resp.StatusCode == 200) {
                  this.signatureImg = resp.ResponseConvertTextToSignImage.imgsrc;
                  let ctrlItem: any = document.getElementById(this.signControlObj.signControlId);
                  if (this.signControlObj.signControlId != "FooterSignNav") {
                    let reuirdControls = ctrlItem.attributes["data-rq"].value;
                    let requiredLength = ctrlItem.getElementsByClassName("requiredControls").length;
                    let isStaticTemplate: any = localStorage.getItem("isStaticTemplate");
                    if (requiredLength > 0 && (this.documentControlsResponse.EnvelopeInfo.IsSingleSigning == false || isStaticTemplate)) {
                      let allRequiredSignatureControls: any = this.getAllDocumentSignatureControls();
                      Array.from(allRequiredSignatureControls).forEach((sigCtrl: any) => {
                        isStaticTemplate = Boolean(isStaticTemplate);
                        if (isStaticTemplate && this.documentControlsResponse.AllowStaticMultiSigner) {
                          //This is for static template
                          this.addSignatureTypeImageSrc(sigCtrl, resp, this.signatureText.trim(), onSignatureSubmitOption, true);
                        }
                        else {
                          this.addSignatureTypeImageSrc(sigCtrl, resp, this.signatureText.trim(), onSignatureSubmitOption, false);
                        }
                      });

                      let footerSignNav: any = document.getElementById("FooterSignNav");
                      if (footerSignNav != undefined && footerSignNav != null) {
                        let footerSignNavCol = footerSignNav.getElementsByTagName('img');
                        if (footerSignNavCol != undefined && footerSignNavCol != null && footerSignNavCol.length > 0) {
                          footerSignNavCol[0].setAttribute("src", resp.ResponseConvertTextToSignImage.imgsrc);
                          footerSignNav.getElementsByClassName("controlIndicator")[0].classList.add("filled");
                          footerSignNav.getElementsByClassName("controlIndicator")[0].classList.add("filledFooterSignatureControl");
                          this.applyStyleFordefaultSignNavControlIndicator();
                        }
                      }
                    }
                    else {
                      this.addSignatureTypeImageSrc(ctrlItem, resp, this.signatureText.trim(), onSignatureSubmitOption, true);
                      let footerSignNav: any = document.getElementById("FooterSignNav");
                      if (footerSignNav != undefined && footerSignNav != null) {
                        
                        let launguageSignImageName = this.translationsService.getLanguageValidationVal('lang_yourNameImg');
                        // if (launguageSignImageNameArray.length > 0) {
                        //   launguageSignImageName = launguageSignImageNameArray[0].KeyValue;
                        // }
                        let defaultImage = Defaults.DefaultImages + launguageSignImageName + ".png";
                        let footerSignNavCol = footerSignNav.getElementsByTagName('img');
                        if (footerSignNavCol != undefined && footerSignNavCol != null && footerSignNavCol.length > 0) {
                          if (footerSignNavCol[0].attributes["src"].value == defaultImage) {
                            footerSignNavCol[0].setAttribute("src", resp.ResponseConvertTextToSignImage.imgsrc);
                            footerSignNav.getElementsByClassName("controlIndicator")[0].classList.add("filled");
                            footerSignNav.getElementsByClassName("controlIndicator")[0].classList.add("filledFooterSignatureControl");
                            this.applyStyleFordefaultSignNavControlIndicator();
                          }
                        }
                      }
                    }
                  }
                  else {
                    let footerSignNav: any = document.getElementById("FooterSignNav");
                    if (footerSignNav != undefined && footerSignNav != null) {
                      let footerSignNavCol = footerSignNav.getElementsByTagName('img');
                      if (footerSignNavCol != undefined && footerSignNavCol != null && footerSignNavCol.length > 0) {
                        footerSignNavCol[0].setAttribute("src", resp.ResponseConvertTextToSignImage.imgsrc);
                        footerSignNav.getElementsByClassName("controlIndicator")[0].classList.add("filled");
                        footerSignNav.getElementsByClassName("controlIndicator")[0].classList.add("filledFooterSignatureControl");
                        this.applyStyleFordefaultSignNavControlIndicator();
                      }
                    }
                  }

                  if (onSignatureSubmitOption === 'Ok') {
                    this.filterCheckListSingature.next('true');
                    // this.activeModal.dismiss('Cross click');
                  }
                }
              });
          }

        }
        else {
          this.showToaster('Please upload signature.', 'Warning');
        }
      }
    }
    else{
      if (this.selectedRadioBtn === 'signerSignatureType') {
        if (this.signatureInputText.trim() !== '') {
          var count = this.signatureInputText.trim().length;
          if (count == 0) {
            this.showToaster('Name is required.', 'Warning');
          }
          else if (count > 50) {
            this.showToaster('Only 50 characters allowed for signature.', 'Warning');
          }
          else {
            localStorage.setItem("data-sign-text", this.signatureInputText.trim());
            localStorage.setItem('signatureTypeID', SignatureType.Auto);
            let userfont = localStorage.getItem('selectedFont');
            if (userfont == "OvertheRainbowPolish") {
              userfont = "OvertheRainbow";
            }
            else if (userfont == "LaBelleAurorePolish") {
              userfont = "LaBelleAurore";
            } else if (userfont == null) {
              if (this.showPolishSignatureDiv) {
                userfont = 'DancingScript';
              } else {
                userfont = 'HomemadeApple';
              }
            }

            let height = "13";
            let width = "13";
            let fontsize = "13px";

            //Call API ConvertTextToSignImage
            let requestObj = {
              text: this.signatureText.trim(),
              font: userfont,
              fontsize: fontsize,
              fontColor: "#000000",
              height: height,
              width: width,
              envelopeCode: "",
              electronicSignIndicationId: "3",
              dateFormat: "mm-dd-yy",
              userTimezone: "India Standard Time",
              dateFormatID: "9fc73c2b-34d7-42a1-b2a6-702ed2fd312b"
            };

            let url = CommonConstants.ConvertTextToSignImage;
            this.signerLandingService.convertTextToSignImage(url, requestObj).subscribe(
              (resp: any) => {
                this.showLoader = false;
                if (resp && resp.StatusCode == 200) {
                  let imgProfileSignature: any = document.getElementById("imgProfileSignaturePreview");
                  localStorage.setItem('imgProfileSignatureSrc', resp.ResponseConvertTextToSignImage.imgsrc);
                  if (imgProfileSignature != undefined && imgProfileSignature != null) {
                    imgProfileSignature.setAttribute("src", resp.ResponseConvertTextToSignImage.imgsrc);
                  }
                  // let txtsignerProfileName: any = document.getElementById("txtsignerProfileName");
                  // txtsignerProfileName.value = this.signatureText.trim();
                }
              });

            if (onSignatureSubmitOption === 'Ok') {
              let imgProfileSignature: any = document.getElementById("imgProfileSignature");
              if (localStorage.getItem('imgProfileSignatureSrc') == null || localStorage.getItem('imgProfileSignatureSrc') == undefined) {
                let url = CommonConstants.ConvertTextToSignImage;
                this.signerLandingService.convertTextToSignImage(url, requestObj).subscribe(
                  (resp: any) => {
                    this.showLoader = false;
                    if (resp && resp.StatusCode == 200) {
                      imgProfileSignature.setAttribute("src", resp.ResponseConvertTextToSignImage.imgsrc);
                      // let txtsignerProfileName: any = document.getElementById("txtsignerProfileName");
                      // txtsignerProfileName.value = this.signatureText.trim();
                    }
                  });
              }
              else {
                if (imgProfileSignature != undefined && imgProfileSignature != null) {
                  imgProfileSignature.setAttribute("src", localStorage.getItem('imgProfileSignatureSrc'));
                }
              }
              this.onPostMessage('Save',SignatureType.Auto,localStorage.getItem('imgProfileSignatureSrc'));
                 
              // this.activeModal.dismiss('Cross click');
            }
          }
        } else {
          if (onSignatureSubmitOption === 'Ok') {
            this.showToaster('Type your name.', 'Warning');
          }
        }
      }
      else if (this.selectedRadioBtn === 'signerSignatureHandDrawn') {
        if (this.signaturePad && this.signaturePad.isEmpty()) {
          if (onSignatureSubmitOption === 'Ok') {
            this.showToaster('Signature is required.', 'Warning');
          }
        }
        else {
          if (this.signaturePad && onSignatureSubmitOption != 'isPreview') {
            const base64Data = this.signaturePad.toDataURL();
            this.signatureImg = base64Data;
            localStorage.setItem('signatureTypeID', SignatureType.Hand);
            let electronicSignIndication: number = 3; //this.documentControlsResponse.EnvelopeInfo.ElectronicSignIndication;
            if (electronicSignIndication > 1) {
              //Call API ConvertTextToSignImage
              let requestObj = {
                imageBytes: this.signatureImg,
                envelopeCode: "",
                electronicSignIndicationId: "3",
                dateFormat: "mm-dd-yy",
                userTimezone: "India Standard Time",
                dateFormatID: "9fc73c2b-34d7-42a1-b2a6-702ed2fd312b"
              };
              let url = CommonConstants.ConvertHandDrawnSignImage;
              if (onSignatureSubmitOption !== 'Ok') {
                if (onSignatureSubmitOption === 'Save') {
                  this.signerLandingService.ConvertHandDrawnSignImage(url, requestObj).subscribe(
                    (resp: any) => {
                      this.showLoader = false;
                      if (resp && resp.StatusCode == 200) {
                        this.signatureImg = resp.ResponseConvertTextToSignImage.imgsrc;
                        let imgProfileSignature: any = document.getElementById("imgProfileSignatureDrawPreview");
                        localStorage.setItem('imgProfileSignatureDrawPreview', resp.ResponseConvertTextToSignImage.imgsrc);
                        if (imgProfileSignature != undefined && imgProfileSignature != null) {
                          imgProfileSignature.setAttribute("src", resp.ResponseConvertTextToSignImage.imgsrc);
                        }
                        if (onSignatureSubmitOption === 'Ok') {
                          this.onPostMessage('Save',SignatureType.Hand,localStorage.getItem("imgProfileSignatureDrawPreview"));
                        }
                      }
                    });
                }
              }
              else {
                this.signerLandingService.ConvertHandDrawnSignImage(url, requestObj).subscribe(
                  (resp: any) => {
                    this.showLoader = false;
                    if (resp && resp.StatusCode == 200) {
                      this.signatureImg = resp.ResponseConvertTextToSignImage.imgsrc;
                      localStorage.setItem('imgProfileSignatureDrawPreview', resp.ResponseConvertTextToSignImage.imgsrc);
                      let imgProfileSignature: any = document.getElementById("imgProfileSignature");
                      if (imgProfileSignature != undefined && imgProfileSignature != null) {
                        imgProfileSignature.setAttribute("src", resp.ResponseConvertTextToSignImage.imgsrc);
                      }
                      if (onSignatureSubmitOption === 'Ok') {
                        this.onPostMessage('Save',SignatureType.Hand,localStorage.getItem("imgProfileSignatureDrawPreview"));
                      }
                      // this.activeModal.dismiss('Cross click');
                    }
                  });

              }
            }
            else {
              if (onSignatureSubmitOption !== 'Ok') {
                let imgProfileSignature: any = document.getElementById("imgProfileSignatureDrawPreview");
                if (imgProfileSignature != undefined && imgProfileSignature != null) {
                  imgProfileSignature.setAttribute("src", this.signatureImg);
                }

              }
              else {
                let imgProfileSignature: any = document.getElementById("imgProfileSignature");
                if (imgProfileSignature != undefined && imgProfileSignature != null) {
                  imgProfileSignature.setAttribute("src", this.signatureImg);
                
                }
                //  this.activeModal.dismiss('Cross click');
              }
            }
           
        }
              
        }
      }
      else if (this.selectedRadioBtn === 'signerSignatureUpload') {
        localStorage.setItem('signatureTypeID', SignatureType.UploadSignature);
        let uploadSigntaureImageSrc: any = document.getElementById("imgUploadsignature");
        let base64UploadSigntaureImageSrcString = uploadSigntaureImageSrc.attributes["src"].value;
        if (this.files.length || (base64UploadSigntaureImageSrcString !== undefined && base64UploadSigntaureImageSrcString !== null &&
          base64UploadSigntaureImageSrcString !== "")) {
          if (base64UploadSigntaureImageSrcString == undefined || base64UploadSigntaureImageSrcString == null ||
            base64UploadSigntaureImageSrcString == "") {
            this.toastr.warning('Upload signature is required.', 'Warning', { timeOut: 1000 });
          }
          else {
            let requestObj = {
              imageBytes: base64UploadSigntaureImageSrcString,
              envelopeCode: "",
              electronicSignIndicationId: "3",
              dateFormat: "mm-dd-yy",
              userTimezone: "India Standard Time",
              dateFormatID: "9fc73c2b-34d7-42a1-b2a6-702ed2fd312b"
            };

            let url = CommonConstants.ConvertHandDrawnSignImageUpload;
            if (onSignatureSubmitOption !== 'Ok') {
              this.signerLandingService.convertHandDrawnSignImageUpload(url, requestObj).subscribe(
                (resp: any) => {
                  this.showLoader = false;
                  if (resp && resp.StatusCode == 200) {
                    let imgProfileSignature: any = document.getElementById("imgUploadsignature");
                    if (imgProfileSignature != undefined && imgProfileSignature != null) {
                      imgProfileSignature.setAttribute("src", resp.ResponseConvertTextToSignImage.imgsrc);
                    }
                  }
                });
            } else {
              let requestObj = {
                imageBytes: localStorage.getItem('imgUploadsignatureUpload'),
                envelopeCode: "",
                electronicSignIndicationId: "3",
                dateFormat: "mm-dd-yy", 
                userTimezone: "India Standard Time", 
                dateFormatID: "9fc73c2b-34d7-42a1-b2a6-702ed2fd312b" 
              };
              this.signerLandingService.convertHandDrawnSignImageUpload(url, requestObj).subscribe(
                (resp: any) => {
                  this.showLoader = false;
                  if (resp && resp.StatusCode == 200) {
                    let imgProfileSignature: any = document.getElementById("imgProfileSignature");
                    if (imgProfileSignature != undefined && imgProfileSignature != null) {
                      imgProfileSignature.setAttribute("src", resp.ResponseConvertTextToSignImage.imgsrc);
                      imgProfileSignature.style["width"] = "auto";
                      imgProfileSignature.style["height"] = "88px";

                      if (this.webOrMobile == 'mobile') {
                        imgProfileSignature.style['padding-bottom'] = "15px";
                      } else {
                        imgProfileSignature.style['padding-bottom'] = "10px";
                      }
                    }
                  }
                });
              // this.activeModal.dismiss('Cross click');
            }
          }
        }
        else {
          this.showToaster('Please upload signature.', 'Warning');
        }
      }
    }
  
  }
  applyHandDrawnImage(onSignatureSubmitOption: any) {
    this.showLoader = true;
    let ctrlItem: any = document.getElementById(this.signControlObj.signControlId);
    if (this.signControlObj.signControlId != "FooterSignNav") {
      let reuirdControls = ctrlItem.attributes["data-rq"].value;
      let requiredLength = ctrlItem.getElementsByClassName("requiredControls").length;
      let isStaticTemplate: any = localStorage.getItem("isStaticTemplate");
      if (requiredLength > 0 && (this.documentControlsResponse.EnvelopeInfo.IsSingleSigning == false || isStaticTemplate)) {
        let allRequiredSignatureControls: any = this.getAllDocumentSignatureControls();
        Array.from(allRequiredSignatureControls).forEach((sigCtrl: any) => {
          isStaticTemplate = Boolean(isStaticTemplate);
          if (isStaticTemplate && this.documentControlsResponse.AllowStaticMultiSigner) {
            //This is for static template
            this.addSignatureHandDrawnImageSrc(sigCtrl, this.signatureImg, this.signatureText.trim(), onSignatureSubmitOption, true);
          }
          else {
            this.addSignatureHandDrawnImageSrc(sigCtrl, this.signatureImg, this.signatureText.trim(), onSignatureSubmitOption, false);
          }
        });

        if (onSignatureSubmitOption === 'Ok') {
          let footerSignNav: any = document.getElementById("FooterSignNav");
          if (footerSignNav != undefined && footerSignNav != null) {
            let footerSignNavCol = footerSignNav.getElementsByTagName('img');
            if (footerSignNavCol != undefined && footerSignNavCol != null && footerSignNavCol.length > 0) {
              footerSignNavCol[0].setAttribute("src", this.signatureImg);
              footerSignNav.getElementsByClassName("controlIndicator")[0].classList.add("filled");
              footerSignNav.getElementsByClassName("controlIndicator")[0].classList.add("filledFooterSignatureControl");
              this.applyStyleFordefaultSignNavControlIndicator();
            }
          }
        }
      }
      else {
        if (onSignatureSubmitOption === 'Ok') {
          this.addSignatureHandDrawnImageSrc(ctrlItem, this.signatureImg, this.signatureText.trim(), onSignatureSubmitOption, false);
          let footerSignNav: any = document.getElementById("FooterSignNav");
          if (footerSignNav != undefined && footerSignNav != null) {           
            let launguageSignImageName = this.translationsService.getLanguageValidationVal('lang_yourNameImg');           
            let defaultImage = Defaults.DefaultImages + launguageSignImageName + ".png";
            let footerSignNavCol = footerSignNav.getElementsByTagName('img');
            if (footerSignNavCol != undefined && footerSignNavCol != null && footerSignNavCol.length > 0) {
              if (footerSignNavCol[0].attributes["src"].value == defaultImage) {
                footerSignNavCol[0].setAttribute("src", this.signatureImg);
                footerSignNav.getElementsByClassName("controlIndicator")[0].classList.add("filled");
                footerSignNav.getElementsByClassName("controlIndicator")[0].classList.add("filledFooterSignatureControl");
                this.applyStyleFordefaultSignNavControlIndicator();
              }
            }

          }
        }
      }
    }
    else {
      if (onSignatureSubmitOption === 'Ok') {
        let footerSignNav: any = document.getElementById("FooterSignNav");
        if (footerSignNav != undefined && footerSignNav != null) {
          let footerSignNavCol = footerSignNav.getElementsByTagName('img');
          if (footerSignNavCol != undefined && footerSignNavCol != null && footerSignNavCol.length > 0) {
            footerSignNavCol[0].setAttribute("src", this.signatureImg);
            footerSignNav.getElementsByClassName("controlIndicator")[0].classList.add("filled");
            footerSignNav.getElementsByClassName("controlIndicator")[0].classList.add("filledFooterSignatureControl");
            this.applyStyleFordefaultSignNavControlIndicator();
          }
        }
      }
    }

    let imgProfileSignature: any = document.getElementById("imgProfileSignatureDrawPreview");
    if (imgProfileSignature != undefined && imgProfileSignature != null) {
      imgProfileSignature.setAttribute("src", this.signatureImg);
    }
    this.showLoader = false;

    if (onSignatureSubmitOption === 'Ok') {
      this.filterCheckListSingature.next('true');
      // this.activeModal.dismiss('Cross click');
    }
  }

  applyStyleFordefaultSignNavControlIndicator() {
    let defaultSignNavControlIndicator: any = document.getElementsByClassName("defaultSignNavControlIndicator");
    if (defaultSignNavControlIndicator && defaultSignNavControlIndicator.length > 0) {
      defaultSignNavControlIndicator[0].classList.remove("requried-color");
      defaultSignNavControlIndicator[0].classList.add("success-color");
    }
    let defaultSignNavControlIndicatorIcon: any = document.getElementsByClassName("defaultSignNavControlIndicatorIcon");
    if (defaultSignNavControlIndicatorIcon && defaultSignNavControlIndicatorIcon.length > 0) {
      defaultSignNavControlIndicatorIcon[0].classList.remove("requried-icon");
      defaultSignNavControlIndicatorIcon[0].classList.add("success-icon");
    }
  }

  onItemChange(val: any) {
    //  console.log("onItemChange!", this.selectedRadioBtn);
  }

  onSignatureHandDrawn() {
    // console.log('onSignatureHandDrawn');
    this.signatureText = '';
    this.signatureContainer = false;
    this.renderer2.setStyle(this.dvHandDrawn.nativeElement, 'display', 'block');
    this.typeSignature = false;
    this.uploadSignatureContainer = false;
    this.showFileSelectionOptions = false;
    this.signatureCaptureTypeDisplay = false;
    this.signatureHandDrwanDisplay = true;
    this.signatureCaptureTypeTextDisplay = this.signatureCaptureTypeDisplay ? "block" : "none";
    this.selectedRadioBtn = "signerSignatureHandDrawn";
  }

  onSignatureUpload() {
    // console.log('onSignatureUpload');
    this.signatureText = '';
    this.signatureContainer = false;
    this.renderer2.setStyle(this.dvHandDrawn.nativeElement, 'display', 'none');
    this.typeSignature = false;
    this.uploadSignatureContainer = true;
    this.signatureHandDrwanDisplay = false;
    this.signatureCaptureTypeDisplay = false;
    this.signatureCaptureTypeTextDisplay = this.signatureCaptureTypeDisplay ? "block" : "none";
    this.selectedRadioBtn = "signerSignatureUpload";
  }

  onSignatureSelection(signSelect: any, event: any) {
    // console.log('onSignatureSelection', signSelect);

    let existsFont: any = localStorage.getItem('selectedFont');
    let slFontTypeLabelInput: any = document.getElementById(existsFont);
    if (slFontTypeLabelInput != null) {
      if (slFontTypeLabelInput) {
        slFontTypeLabelInput.removeAttribute('checked');
        slFontTypeLabelInput.removeAttribute('checked');
        let g: any = document.getElementsByClassName(existsFont);
        let lbl: any = g[0].getElementsByTagName("label")[0];
        let splabel: any = lbl.getElementsByTagName("span")[0];
        splabel.classList.remove("sigFontTypeSpanClass");
        lbl.classList.remove("sigFontTypeLabelClass");
      }
    }

    let selectedFont = this.signatureFontObj[signSelect].fontFamily;
    localStorage.setItem('selectedFont', selectedFont);
    let slFontTypeLabelInput1: any = document.getElementById(selectedFont);
    if (slFontTypeLabelInput1 != null) {
      if (slFontTypeLabelInput1) {
        slFontTypeLabelInput1.setAttribute('checked', 'checked');
        let g: any = document.getElementsByClassName(selectedFont);
        let lbl: any = g[0].getElementsByTagName("label")[0];
        lbl.classList.add("sigFontTypeLabelClass");
        let splabel: any = lbl.getElementsByTagName("span")[0];
        splabel.classList.add("sigFontTypeSpanClass");
      }
    }

    var count = this.signatureInputText.trim().length;
    if (count == 0) {
      // this.showToaster('Name is required.', 'Warning');
    }
    else if (count > 0 && count > 50) {
      this.showToaster('Only 50 characters allowed for signature.', 'Warning');
      return;
    }
    else {
      this.onSignatureSubmit('');
    }
    event.preventDefault();
  }

  onDragOver(event: any) {
    event.preventDefault();
  }

  onDropSuccess(event: any) {
    event.preventDefault();
    this.onFileChange(event.dataTransfer.files);
  }

  onChange(event: any) {
    this.onChooseImage();
    this.onFileChange(event.target.files);
  }

  onChooseImage() {
    this.showFileSelectionOptions = !this.showFileSelectionOptions;
  }

  onCloseDrives() {
    this.showFileSelectionOptions = false;
  }

  onFileChange(files: any) {
    this.files = [];
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    // console.log('Processed!', this.files);
    if (files.length) {
      let sizeVal = this.formatBytes(files[0].size, 2);
      let fileName = files[0].name;
      let isCheckfileExt: any = this.validateSignatureFileExtension(fileName);
      let ext = (fileName).substr(((fileName).lastIndexOf('.') + 1));
      if (isCheckfileExt) {
        let extType = (fileName).substr(((fileName).lastIndexOf('.') + 1));
        this.toastr.warning("File type ." + extType + " not supported. Supported file types: .png, .jpeg, .jpg, .bmp", 'Warning', { timeOut: 1500 });
        this.files = [];
        (<HTMLInputElement>document.getElementById('fileUploadInput')).value = '';
      }
      else if (files[0].size > 40960) {
        // this.toastr.warning('File size exceeds 40 KB.', 'Warning', { timeOut: 1000 });
        // this.files = [];
        //  (<HTMLInputElement>document.getElementById('fileUploadInput')).value = '';
        this.uploadType = "LocalMachine";
        this.confirmationPopupStyle = 'block';
        return;
      } else {
        this.processing = true;
        let fileName = files[0].name;
        let isCheckfileExt: any = this.validateSignatureFileExtension(fileName);
        let ext = (fileName).substr(((fileName).lastIndexOf('.') + 1));
        if (isCheckfileExt) {
          let extType = (fileName).substr(((fileName).lastIndexOf('.') + 1));
          this.toastr.warning("File type ." + extType + " not supported. Supported file types: .png, .jpeg, .jpg, .bmp", 'Warning', { timeOut: 1500 });
          this.files = [];
          (<HTMLInputElement>document.getElementById('fileUploadInput')).value = '';
        }
        // else if (files[0].size > 40960) {
        //     this.toastr.warning('File size exceeds 40 KB.', 'Warning', { timeOut: 1000 });
        //     this.files = [];
        //     (<HTMLInputElement>document.getElementById('fileUploadInput')).value = '';
        // }
        else {
          this.uploadSignerSignature(this.files);
        }

        setTimeout(() => {
          this.processing = false;
        }, 1000);
      }
    }
  }

  validateSignatureFileExtension(name: any) {
    let extCheck = true;
    let ext = (name).substr(((name).lastIndexOf('.') + 1));
    var extensions = ["png", "jpeg", "jpg", "bmp"];
    let extensionArray = extensions.filter((c: any) => c.toLowerCase() == ext.toLowerCase());
    if (extensionArray.length > 0) {
      extCheck = false;
    }
    return extCheck;
  }

  //Removing the files
  onFileRemove() {
    this.files = [];
    this.dragdropImageSrc = document.getElementById("imgUploadsignature");
    this.dragdropImageSrc.setAttribute("src", '../../../assets/images/upload2.png');
    this.dragdropImageSrc.style["width"] = "";
    localStorage.setItem('imgUploadsignatureUpload', '');

    let filelistInfo: any = document.getElementById("filelistInfo");
    filelistInfo.style["display"] = "none";
    let uploadedFileName: any = document.getElementById("uploadedFileName");
    uploadedFileName.innerHTML = '';
    let uploadedFileSize: any = document.getElementById("uploadedFileSize");
    uploadedFileSize.innerHTML = '';
  }

  //Converting bytes to 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'
  formatBytes(bytes: any, decimals = 2) {
    if (!+bytes) return '0 Bytes'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  UpdateSignatureText(event: any) {
    let inputTxtVal = event.target.value;
    this.signatureText = event.target.value;

    if (event.target.value == "") {
      this.signatureText = ControlsName.Signature;
    }
    else {
      this.signatureText = this.signatureText;
      this.signatureInputText = this.signatureInputText;
    }

    if (this.signatureText != "" && this.signatureText != 'Signature') {
      let signTypeId: any = localStorage.getItem('selectedFont');
      let polishcharcount = this.checkPolishCharchters('Signature', signTypeId);
      if (polishcharcount == 1) {
        this.showPolishSignatureDiv = true;
      }
      else {
        this.showPolishSignatureDiv = false;
      }
      this.updatePolishSignatureFont(signTypeId, polishcharcount, 'Signature');
    }
    else {
      let imgProfileSignature: any = document.getElementById("imgProfileSignaturePreview");
      if (imgProfileSignature != undefined && imgProfileSignature != null) {
        imgProfileSignature.setAttribute("src", '');
      }
    }

    this.signFontColor = this.signControlObj.dataFontColor;
    if (this.signFontColor == null || this.signFontColor == "") this.signFontColor = "#000000";
    let signatureTextClass: any = document.getElementsByClassName("signatureText");
    Array.from(signatureTextClass).forEach((item: any) => {
      item!.style.color = this.signFontColor;
    });

    this.onSignatureSubmit('');
  }

  checkPolishCharchters(Type: string, signTypeId: any) {
    let polishcharexist = 0;
    if (Type == "Signature") {
      if (signTypeId != "LaBelleAurore" || signTypeId != "OvertheRainbow") {
        let testdata = this.signatureText;
        let polishCharArray = this.PolishCharsData.filter((c: any) => testdata.indexOf(c) > -1);
        if (polishCharArray.length > 0) {
          polishcharexist = 1;
        }
      }
    }
    return polishcharexist;
  }

  updatePolishSignatureFont(SignerTypeId: any, polishcharcount: any, Type: any) {
    var signaturePolishFont = Type == "Signature" ? { "DancingScript": "DancingScript", "Allura": "Allura", "GreatVibes": "GreatVibes", "Allison": "Allison", "Parisienne": "Parisienne", "Sacramento": "Sacramento", "OvertheRainbowPolish": "OvertheRainbowPolish", "LaBelleAurorePolish": "LaBelleAurorePolish" } :
      { "DancingScript": "DancingScriptInitial", "GreatVibes": "GreatVibesInitial", "Allison": "AllisonInitial", "Parisienne": "ParisienneInitial", "OvertheRainbowPolish": "OvertheRainbowPolishInitial", "LaBelleAurorePolish": "LaBelleAurorePolishInitial" };

    var SignatureNormalFont = Type == "Signature" ? { "HomemadeApple": "HomemadeApple", "DawningofaNewDay": "DawningofaNewDay", "Kristi": "Kristi", "LaBelleAurore": "LaBelleAurore", "OvertheRainbow": "OvertheRainbow", "Brush": "Brush" } :
      { "HomemadeApple": "HomemadeApple1", "DawningofaNewDay": "DawningofaNewDay1", "Kristi": "Kristi1", "LaBelleAurore": "LaBelleAurore1", "OvertheRainbow": "OvertheRainbow1", "Brush": "Brush1" };

    if (Type == "Signature") {
      switch (SignerTypeId) {
        case SignatureNormalFont.HomemadeApple:
        case signaturePolishFont.DancingScript:
          let DancingScript: any = document.getElementById("DancingScript");
          let HomemadeApple: any = document.getElementById("HomemadeApple");
          polishcharcount == 1 ? DancingScript.checked = true : HomemadeApple.checked = true;
          break;
        case SignatureNormalFont.DawningofaNewDay:
        case signaturePolishFont.Parisienne:
          let DawningofaNewDay: any = document.getElementById("DawningofaNewDay");
          let Parisienne: any = document.getElementById("Parisienne");
          polishcharcount == 1 ? Parisienne.checked = true : DawningofaNewDay.checked = true;
          break;
        case SignatureNormalFont.Kristi:
        case signaturePolishFont.Allison:
          let Kristi: any = document.getElementById("Kristi");
          let Allison: any = document.getElementById("Allison");
          polishcharcount == 1 ? Allison.checked = true : Kristi.checked = true;
          break;
        case SignatureNormalFont.LaBelleAurore:
        case signaturePolishFont.LaBelleAurorePolish:
          let LaBelleAurore: any = document.getElementById("LaBelleAurore");
          let LaBelleAurorePolish: any = document.getElementById("LaBelleAurorePolish");
          polishcharcount == 1 ? LaBelleAurorePolish.checked = true : LaBelleAurore.checked = true;
          break;
        case SignatureNormalFont.OvertheRainbow:
        case signaturePolishFont.OvertheRainbowPolish:
          let OvertheRainbow: any = document.getElementById("OvertheRainbow");
          let OvertheRainbowPolish: any = document.getElementById("OvertheRainbowPolish");
          polishcharcount == 1 ? OvertheRainbowPolish.checked = true : OvertheRainbow.checked = true;
          break;
        case SignatureNormalFont.Brush:
        case signaturePolishFont.GreatVibes:
          let GreatVibes: any = document.getElementById("GreatVibes");
          let Brush: any = document.getElementById("Brush");
          polishcharcount == 1 ? GreatVibes.checked = true : Brush.checked = true;
          break;
      }
    }
  }

  getAllDocumentSignatureControls() {
    let controls: any = [];
    Array.from(document.getElementsByClassName("commonSignatureControl") as HTMLCollectionOf<HTMLElement>)
      .forEach((sigItem: any) => {
        if (sigItem.attributes["data-ctntype"].value == "Signature" && sigItem.attributes["data-rq"].value == "true") {
          controls.push(sigItem);
        }
      });
    return controls;
  }

  addSignatureTypeImageSrc(sigCtrl: any, resp: any, signatureText: any, previewStr: any, isStatic: boolean) {
    if (!isStatic) {
      if (previewStr !== 'Ok') {
        let imgProfileSignature: any = document.getElementById("imgProfileSignaturePreview");
        if (imgProfileSignature != undefined && imgProfileSignature != null) {
          imgProfileSignature.setAttribute("src", resp.ResponseConvertTextToSignImage.imgsrc);
        }
      }
      else {
        this.setTypeSignatureSrc(sigCtrl, resp, signatureText, previewStr);
      }
    }
    else if (isStatic) {
      if (previewStr !== 'Ok') {
        let imgProfileSignature: any = document.getElementById("imgProfileSignaturePreview");
        if (imgProfileSignature != undefined && imgProfileSignature != null) {
          imgProfileSignature.setAttribute("src", resp.ResponseConvertTextToSignImage.imgsrc);
        }
      }
      else {
        let signControlRoleName: any = this.signControlObj.dataRoleName;
        let dataRoleName: any = sigCtrl.attributes["data-role-name"];
        if (dataRoleName.value == signControlRoleName.value) {
          this.setTypeSignatureSrc(sigCtrl, resp, signatureText, previewStr);
        }
      }
    }
  }

  addSignatureHandDrawnImageSrc(sigCtrl: any, imageSrc: any, signatureText: any, previewStr: any, isStatic: boolean) {
    if (!isStatic) {
      if (previewStr !== 'Ok') {
        let imgProfileSignature: any = document.getElementById("imgProfileSignaturePreview");
        if (imgProfileSignature != undefined && imgProfileSignature != null) {
          imgProfileSignature.setAttribute("src", imageSrc);
        }
      }
      else {
        this.setHandDrawnSignatureSrc(sigCtrl, imageSrc, signatureText, previewStr);
      }
    }
    else if (isStatic) {
      if (previewStr !== 'Ok') {
        let imgProfileSignature: any = document.getElementById("imgProfileSignaturePreview");
        if (imgProfileSignature != undefined && imgProfileSignature != null) {
          imgProfileSignature.setAttribute("src", imageSrc);
        }
      }
      else {
        let signControlRoleName: any = this.signControlObj.dataRoleName;
        let dataRoleName: any = sigCtrl.attributes["data-role-name"];
        if (dataRoleName.value == signControlRoleName.value) {
          this.setHandDrawnSignatureSrc(sigCtrl, imageSrc, signatureText, previewStr);
        }
      }
    }
  }

  setTypeSignatureSrc(sigCtrl: any, resp: any, signatureText: any, previewStr: any) {
    let ctrlId = sigCtrl.attributes["id"].value;
    let width = window.getComputedStyle(sigCtrl).width;
    let height = window.getComputedStyle(sigCtrl).height;
    let heightForSignImage = parseInt(height) - 2;
    let sigImageWidth = resp.ResponseConvertTextToSignImage.width * (heightForSignImage / resp.ResponseConvertTextToSignImage.height);
    let sigControlWidth = parseInt(width);

    this.elementVar = document.getElementById("control_" + ctrlId);
    this.elementVar.setAttribute("src", resp.ResponseConvertTextToSignImage.imgsrc);
    this.elementVar.setAttribute("data-sign-text", signatureText);
    localStorage.setItem("data-sign-text", signatureText);

    if (sigImageWidth > sigControlWidth) {
      let ratio = sigControlWidth / sigImageWidth,
        newWidth = (sigImageWidth * ratio),
        newHeight = (heightForSignImage * ratio);

      this.elementVar.style['width'] = newWidth + "px";
      this.elementVar.style['height'] = newHeight + "px";
    }
    else {
      this.elementVar.style['width'] = "auto";
      this.elementVar.style['height'] = heightForSignImage + "px";
    }

    sigCtrl.style['background'] = "none";
    sigCtrl.getElementsByClassName("signature")[0].style['border'] = "1px solid #ddd";
    sigCtrl.getElementsByClassName("signature")[0].style['background'] = "none";
    sigCtrl.getElementsByClassName("additionalInfo")[0].classList.add("filledControls");

    if (sigCtrl.getElementsByClassName("stampicon").length > 0) {
      sigCtrl.getElementsByClassName("stampicon")[0].classList.remove("requiredControlsStamp", "optionalControlsStamp", "filledControlsStamp");
      sigCtrl.getElementsByClassName("stampicon")[0].classList.add("filledControlsStamp");
    }

    localStorage.setItem("signatureInputText", signatureText);
    localStorage.setItem("signatureText", signatureText);

    // this.signControlObj.checkListData.forEach((element: any) => {
    //     if (element.ControlsData != null && element.ControlsData.length > 0) {
    //         element.ControlsData.forEach((ele: any) => {
    //             if (ele.ControlName == "Signature") {
    //                 if (ele.ControlHtmlID === ctrlId) {
    //                     ele.isControlFiled = true;
    //                 }
    //             }
    //         })
    //     }
    // });

    let currentLblControl: any = document.getElementById("label_" + ctrlId);
    let currentLblIconControl: any = document.getElementById("iicon_" + ctrlId);
    currentLblControl.classList.remove("requried-color", "requried-color1", "requried-icon");
    currentLblControl.classList.add("success-color");
    currentLblControl.classList.add("success-icon");
    currentLblIconControl.classList.remove("requried-icon");
    currentLblIconControl.classList.add("success-icon");


    // this.activeModal.dismiss('Cross click');
  }

  setHandDrawnSignatureSrc(sigCtrl: any, imageSrc: any, signatureText: any, previewStr: any) {
    let ctrlId = sigCtrl.attributes["id"].value;
    let width = window.getComputedStyle(sigCtrl).width;
    let height = window.getComputedStyle(sigCtrl).height;
    let heightForSignImage = parseInt(height) - 2;
    let sigControlWidth = parseInt(width) - 2;

    this.elementVar = document.getElementById("control_" + ctrlId);
    this.elementVar.setAttribute("src", imageSrc);
    this.elementVar.setAttribute("data-sign-text", signatureText);
    localStorage.setItem("data-sign-text", signatureText);
    this.elementVar.style['width'] = sigControlWidth + "px";
    this.elementVar.style['height'] = heightForSignImage + "px";

    sigCtrl.style['background'] = "none";
    sigCtrl.getElementsByClassName("signature")[0].style['border'] = "1px solid #ddd";
    sigCtrl.getElementsByClassName("signature")[0].style['background'] = "none";
    sigCtrl.getElementsByClassName("additionalInfo")[0].classList.add("filledControls");

    if (sigCtrl.getElementsByClassName("stampicon").length > 0) {
      sigCtrl.getElementsByClassName("stampicon")[0].classList.remove("requiredControlsStamp", "optionalControlsStamp", "filledControlsStamp");
      sigCtrl.getElementsByClassName("stampicon")[0].classList.add("filledControlsStamp");
    }

    // this.signControlObj.checkListData.forEach((element: any) => {
    //     if (element.ControlsData != null && element.ControlsData.length > 0) {
    //         element.ControlsData.forEach((ele: any) => {
    //             if (ele.ControlName == "Signature") {
    //                 if (ele.ControlHtmlID === ctrlId) {
    //                     ele.isControlFiled = true;
    //                 }
    //             }
    //         })
    //     }
    // });

    let currentLblControl: any = document.getElementById("label_" + ctrlId);
    let currentLblIconControl: any = document.getElementById("iicon_" + ctrlId);
    currentLblControl.classList.remove("requried-color", "requried-color1", "requried-icon");
    currentLblControl.classList.add("success-color");
    currentLblControl.classList.add("success-icon");
    currentLblIconControl.classList.remove("requried-icon");
    currentLblIconControl.classList.add("success-icon");

    localStorage.setItem("signatureInputText", '');
    localStorage.setItem("signatureText", '');
    // this.activeModal.dismiss('Cross click');
  }

  loadGoogleDrive() {
    this.showFileSelectionOptions = !this.showFileSelectionOptions;
    gapi.load('auth2', { 'callback': this.onAuthApiLoad.bind(this) });
    gapi.load('picker', { 'callback': this.onPickerApiLoad.bind(this) });
  }

  onAuthApiLoad() {
    gapi.auth2.authorize(
      {
        client_id: this.googleDriveClientId,
        scope: this.gooleDriveScope,
        immediate: false,
        prompt: 'select_account',
        authuser: -1,
        plugin_name: 'NewSignersUI'
      },
      this.handleAuthResult.bind(this));
  }

  onPickerApiLoad() {
    this.pickerApiLoaded = true;
  }

  handleAuthResult(authResult: any) {
    let src;
    if (authResult && !authResult.error) {
      if (authResult.access_token) {
        this.oauthToken = authResult.access_token;
        this.oauthData = authResult;
        localStorage.setItem("authResultToken", this.oauthToken);
        this.createPicker();
      }
    }
  }

  createPicker() {
    if (this.pickerApiLoaded && this.oauthToken) {
      let view = new google.picker.DocsView(google.picker.ViewId.DOCS);
      view.setMimeTypes("image/png,image/jpeg,image/jpg");
      view.setMode(google.picker.DocsViewMode.LIST);
      view.setIncludeFolders(true);
      view.setOwnedByMe(true);
      let picker = new google.picker.PickerBuilder().
        addView(view).
        enableFeature(google.picker.Feature.NAV_HIDDEN).
        setOAuthToken(this.oauthToken).
        setCallback((e: any) => {
          if (e[google.picker.Response.ACTION] == google.picker.Action.PICKED) {
            let documents = e[google.picker.Response.DOCUMENTS];
            let isStatic: boolean = localStorage.getItem('isStatic') == "true" ? true : false;
            let driveFiles: any[] = []
            let preparedObjects = {
              GAuthToken: localStorage.getItem("authResultToken"), //gapi.auth2.getToken().access_token,
              EnvelopeID: localStorage.getItem('EnvelopeID')!,
              Stage: localStorage.getItem('RecipientID')!,
              IsStaticTemplate: isStatic,
              DriveFiles: driveFiles,
              recipientEmailSiA: localStorage.getItem('recipientEmail')!,
              UploadDocDriveType: UploadDriveType.Google
            };

            let uploaderloader: any = (<HTMLInputElement>document.getElementById("uploaderloader"));
            uploaderloader.style["display"] = "block";

            documents.forEach((doc: any) => {
              gapi.client.request({
                'path': 'https://www.googleapis.com/drive/v2/files/' + doc.id,
                'method': 'GET',
              }).then((resp: any) => {
                if (resp.status == 200) {
                  let responsejs = resp.result;
                  if (responsejs.selfLink != undefined && responsejs.selfLink != '') {
                    preparedObjects.DriveFiles.push({
                      FileSource: responsejs.selfLink + "?alt=media&source=downloadUrl",
                      FileName: responsejs.originalFilename,
                      FileSize: responsejs.fileSize,
                    });
                    this.files = [];
                    this.files.push({ name: responsejs.originalFilename, size: responsejs.fileSize });
                  }

                  if (preparedObjects.DriveFiles.length > 0) {
                    let driveFiles: any = preparedObjects.DriveFiles[0];
                    this.validateSignatureFileExtension(driveFiles.FileName);
                    if (this.extCheck === false) {
                      let extType = (driveFiles.FileName).substr(((driveFiles.FileName).lastIndexOf('.') + 1));
                      setTimeout(() => {
                        this.showToaster("File type ." + extType + " not supported. Supported file types: .png, .jpeg, .jpg, .bmp", 'Warning');
                        uploaderloader.style["display"] = "none";
                      }, 1500);
                      this.toastr.warning("Selected file type ." + extType + " not supported. Supported file types: .png, .jpeg, .jpg, .bmp", 'Warning', { timeOut: 2000 });
                      return;
                    }
                    else if (driveFiles.FileSize > 40960) {
                      // setTimeout(() => {
                      //     this.showToaster('File size exceeds 40 KB.', 'Warning');
                      //     uploaderloader.style["display"] = "none";
                      // }, 1500);

                      // this.toastr.warning('Selected file size exceeds 40 KB.', 'Warning', { timeOut: 2000 });
                      this.googleDrivePreparedObject = preparedObjects;
                      uploaderloader.style["display"] = "none";
                      this.uploadType = "GoogleDrive";
                      // this.confirmationPopupStyle = 'block';
                      let confirmationPopupInfo: any = document.getElementById("confirmationPopup");
                      confirmationPopupInfo.style["display"] = "block";
                      //  return;
                    }
                    else if (preparedObjects.DriveFiles.length > 0 && this.extCheck === true) {
                      this.showGoogleDriveImagePreview(preparedObjects);
                    }
                    else {
                      uploaderloader.style["display"] = "none";
                    }
                  }
                  else {
                    uploaderloader.style["display"] = "none";
                  }
                }
                else {
                  uploaderloader.style["display"] = "none";
                }
              })
            });

          }
          else if (e[google.picker.Response.ACTION] == google.picker.Action.CANCEL) {
            let signaturePadModalComponentClass: any = document.getElementsByClassName("signaturePadModalComponent-class");
            if (signaturePadModalComponentClass && signaturePadModalComponentClass.length > 0) {
              signaturePadModalComponentClass[0].style["z-index"] = "1055";
            }
            let clicktoSignClass: any = document.getElementsByClassName("clicktoSign-class");
            if (clicktoSignClass && clicktoSignClass.length > 0) {
              clicktoSignClass[0].style["z-index"] = "1055";
            }
            let modalBackdrop: any = document.getElementsByClassName("modal-backdrop");
            modalBackdrop[0].style["z-index"] = "1055";
          }
        }).
        build();
      picker.setVisible(true);

      let signaturePadModalComponentClass: any = document.getElementsByClassName("signaturePadModalComponent-class");
      if (signaturePadModalComponentClass && signaturePadModalComponentClass.length > 0) {
        signaturePadModalComponentClass[0].style["z-index"] = "1";
      }

      let clicktoSignClass: any = document.getElementsByClassName("clicktoSign-class");
      if (clicktoSignClass && clicktoSignClass.length > 0) {
        clicktoSignClass[0].style["z-index"] = "1";
      }

      let modalBackdrop: any = document.getElementsByClassName("modal-backdrop");
      modalBackdrop[0].style["z-index"] = "1";
    }
  }


  showGoogleDriveImagePreview(preparedObjects: any) {
    let uploaderloader: any = (<HTMLInputElement>document.getElementById("uploaderloader"));
    uploaderloader.style["display"] = "block";
    let downloadDriveImageForSigner: any = CommonConstants.DownloadDriveImageForSigner;
    this.signerLandingService.uploadDriveSignerAttachmentsUrl(downloadDriveImageForSigner, preparedObjects).subscribe(
      (resp: any) => {
        if (resp && resp.StatusCode == 200) {
          if (resp && resp.data && resp.success == true) {
            let base64ImgSrc = resp.data;
            this.dragdropImageSrc = document.getElementById("imgUploadsignature");
            localStorage.setItem('imgUploadsignatureUpload', 'data:image/png;base64,' + base64ImgSrc);
            this.dragdropImageSrc.setAttribute("src", 'data:image/png;base64,' + base64ImgSrc);
            this.dragdropImageSrc.style["width"] = "135px";
            let dragdroplabel: any = document.getElementById("dragdroplabel");
            if (dragdroplabel != null && dragdroplabel != undefined) {
              dragdroplabel.style["display"] = "none";
            }
            let filelistInfo: any = document.getElementById("filelistInfo");
            filelistInfo.style["display"] = "block";
            let driveFiles: any = preparedObjects.DriveFiles[0];
            let uploadedFileName: any = document.getElementById("uploadedFileName");
            uploadedFileName.innerHTML = driveFiles.FileName;
            let uploadedFileSize: any = document.getElementById("uploadedFileSize");
            uploadedFileSize.innerHTML = this.formatBytes((driveFiles.FileSize), 2);

            uploaderloader.style["display"] = "none";
            let signaturePadModalComponentClass: any = document.getElementsByClassName("signaturePadModalComponent-class");
            if (signaturePadModalComponentClass && signaturePadModalComponentClass.length > 0) {
              signaturePadModalComponentClass[0].style["z-index"] = "1055";
            }
            let clicktoSignClass: any = document.getElementsByClassName("clicktoSign-class");
            if (clicktoSignClass && clicktoSignClass.length > 0) {
              clicktoSignClass[0].style["z-index"] = "1055";
            }
            let modalBackdrop: any = document.getElementsByClassName("modal-backdrop")
            modalBackdrop[0].style["z-index"] = "1055";
          }
        }
        else {
          uploaderloader.style["display"] = "none";
          let signaturePadModalComponentClass: any = document.getElementsByClassName("signaturePadModalComponent-class");
          if (signaturePadModalComponentClass && signaturePadModalComponentClass.length > 0) {
            signaturePadModalComponentClass[0].style["z-index"] = "1055";
          }
          let clicktoSignClass: any = document.getElementsByClassName("clicktoSign-class");
          if (clicktoSignClass && clicktoSignClass.length > 0) {
            clicktoSignClass[0].style["z-index"] = "1055";
          }
          let modalBackdrop: any = document.getElementsByClassName("modal-backdrop")
          modalBackdrop[0].style["z-index"] = "1055";
        }
        this.showLoader = false;
        this.googleDrivePreparedObject = '';
        let confirmationPopupInfo: any = document.getElementById("confirmationPopup");
        confirmationPopupInfo.style["display"] = "none";
      });
  }

  loadDropBox() {
    this.showFileSelectionOptions = !this.showFileSelectionOptions;
    var options: DropboxChooseOptions = {
      success: (files) => {
        for (const file of files) {
          let fileSignatureimgLink: any;
          this.validateSignatureFileExtension(file.name);
          if (this.extCheck === false) {
            let extType = (file.name).substr(((file.name).lastIndexOf('.') + 1));
            this.toastr.warning("File type ." + extType + " not supported. Supported file types: .png, .jpeg, .jpg, .bmp", 'Warning', { timeOut: 1000 });
            return;
          }
          else if (file.bytes > 40960) {
            // this.toastr.warning('File size exceeds 40 KB.', 'Warning', { timeOut: 1000 });
            // return;

            fileSignatureimgLink = file.link;
            this.files = [];
            this.files.push({ name: file.name, size: file.bytes });

            this.dropBoxFileImageLink = file.link;
            this.dropBoxFile = file;
            this.uploadType = "DropBox";
            this.confirmationPopupStyle = 'block';
            return;

          }
          else if (this.extCheck === true) {
            fileSignatureimgLink = file.link;
            this.files = [];
            this.files.push({ name: file.name, size: file.bytes });
            this.showDropBoxImagePreview(fileSignatureimgLink, file);
          }
        }
      },
      cancel: () => { },
      linkType: "direct",
      multiselect: false,
      extensions: ['.png', '.jpg', '.jpeg'],
    };
    Dropbox.choose(options);
  }

  showDropBoxImagePreview(fileSignatureimgLink: any, file: any) {
    var formData: any = new FormData();
    formData.append('fileUploadInput', fileSignatureimgLink);
    formData.append('file', file);
    let uploaderloader: any = (<HTMLInputElement>document.getElementById("uploaderloader"));
    uploaderloader.style["display"] = "block";
    let uploadSignerSignatureFromOtherDirves: any = CommonConstants.UploadSignerSignatureFromOtherDirves;
    this.signerLandingService.uploadSignerSignature(uploadSignerSignatureFromOtherDirves, formData).subscribe(
      (resp: any) => {
        if (resp && resp.StatusCode == 200) {
          if (resp && resp.Base64FileData != "") {
            let base64ImgSrc = resp.Base64FileData;
            this.dragdropImageSrc = document.getElementById("imgUploadsignature");
            localStorage.setItem('imgUploadsignatureUpload', 'data:image/png;base64,' + base64ImgSrc);
            this.dragdropImageSrc.setAttribute("src", 'data:image/png;base64,' + base64ImgSrc);
            this.dragdropImageSrc.style["width"] = "135px";
            let dragdroplabel: any = document.getElementById("dragdroplabel");
            if (dragdroplabel != null && dragdroplabel != undefined) {
              dragdroplabel.style["display"] = "none";
            }
            let filelistInfo: any = document.getElementById("filelistInfo");
            filelistInfo.style["display"] = "block";
            let uploadedFileName: any = document.getElementById("uploadedFileName");
            uploadedFileName.innerHTML = file.name;
            let uploadedFileSize: any = document.getElementById("uploadedFileSize");
            uploadedFileSize.innerHTML = this.formatBytes((file.bytes), 2);

            uploaderloader.style["display"] = "none";
            let signaturePadModalComponentClass: any = document.getElementsByClassName("signaturePadModalComponent-class");
            if (signaturePadModalComponentClass && signaturePadModalComponentClass.length > 0) {
              signaturePadModalComponentClass[0].style["z-index"] = "1055";
            }
            let clicktoSignClass: any = document.getElementsByClassName("clicktoSign-class");
            if (clicktoSignClass && clicktoSignClass.length > 0) {
              clicktoSignClass[0].style["z-index"] = "1055";
            }
            let modalBackdrop: any = document.getElementsByClassName("modal-backdrop")
            modalBackdrop[0].style["z-index"] = "1055";
          }
        }
        else {
          uploaderloader.style["display"] = "none";
          let signaturePadModalComponentClass: any = document.getElementsByClassName("signaturePadModalComponent-class");
          if (signaturePadModalComponentClass && signaturePadModalComponentClass.length > 0) {
            signaturePadModalComponentClass[0].style["z-index"] = "1055";
          }
          let clicktoSignClass: any = document.getElementsByClassName("clicktoSign-class");
          if (clicktoSignClass && clicktoSignClass.length > 0) {
            clicktoSignClass[0].style["z-index"] = "1055";
          }
          let modalBackdrop: any = document.getElementsByClassName("modal-backdrop")
          modalBackdrop[0].style["z-index"] = "1055";
        }
        this.showLoader = false;
        this.dropBoxFile = '';
        this.dropBoxFileImageLink = '';
      });
  }

  loadOneDrive() {
    this.showFileSelectionOptions = !this.showFileSelectionOptions;
    this.launchOneDrivePicker()
      .then(result => {
        if (result) {
          for (const file of result.value) {
            let filesData = [];
            let isFileSizeExceed = false;

            this.validateSignatureFileExtension(file.name);
            if (this.extCheck === false) {
              let extType = (file.name).substr(((file.name).lastIndexOf('.') + 1));
              this.toastr.warning("File type ." + extType + " not supported. Supported file types: .png, .jpeg, .jpg, .bmp", 'Warning', { timeOut: 1000 });
              return;
            }
            else if (file.size > 40960) {
              // this.toastr.warning('File size exceeds 40 KB.', 'Warning', { timeOut: 1000 });
              // return;
              // filesData.push({ FileSource: file["@microsoft.graph.downloadUrl"], FileName: file.name, FileSize: file.size });
              this.oneDriveFileData.push({ FileSource: file["@microsoft.graph.downloadUrl"], FileName: file.name, FileSize: file.size });
              this.files = [];
              this.files.push({ name: file.name, size: file.size });
              this.oneDriveFile = file;
              this.uploadType = "OneDrive";
              this.confirmationPopupStyle = 'block';
              return;
            }
            else if (this.extCheck === true) {
              filesData.push({ FileSource: file["@microsoft.graph.downloadUrl"], FileName: file.name, FileSize: file.size });
              this.files = [];
              this.files.push({ name: file.name, size: file.size });
              this.showOneDriveImagePreview(filesData, file);
            }
          }
        }
      }).catch(reason => {
        console.error(reason);
      });
  }

  launchOneDrivePicker() {
    return new Promise<OneDriveResult | null>((resolve, reject) => {
      var odOptions: OneDriveOpenOptions = {
        clientId: this.oneDriveApplicationId,
        action: "download",
        multiSelect: false,
        openInNewWindow: true,
        advanced: {
          filter: ".png,.jpeg,.jpg", // Show only folders and png files
          redirectUri: environment.uiAngularBaseUrl
        },
        success: function (files) { resolve(files); },
        cancel: function () { resolve(null); },
        error: function (e) { reject(e); }
      };

      OneDrive.open(odOptions);
    });
  }

  showOneDriveImagePreview(filesData: any, file: any) {
    let uploaderloader: any = (<HTMLInputElement>document.getElementById("uploaderloader"));
    uploaderloader.style["display"] = "block";

    let isStatic: boolean = localStorage.getItem('isStatic') == "true" ? true : false;
    let preparedObjects = {
      EnvelopeID: localStorage.getItem('EnvelopeID')!,
      Stage: localStorage.getItem('RecipientID')!,
      IsStaticTemplate: isStatic,
      DriveFiles: filesData,
      UploadAttachmentID: 0,
      NameSiA: '',
      DescriptionSiA: '',
      AdditionalInfoSiA: '',
      recipientEmailSiA: '',
      UploadDocDriveType: UploadDriveType.Skydrive
    };

    let downloadOtherDriveDocumentsForSigner: any = CommonConstants.DownloadOtherDriveDocumentsForSigner;
    this.signerLandingService.uploadDriveSignerAttachmentsUrl(downloadOtherDriveDocumentsForSigner, preparedObjects).subscribe(
      (resp: any) => {
        if (resp && resp.StatusCode == 200) {
          if (resp && resp.data && resp.success == true) {
            let base64ImgSrc = resp.data;
            this.dragdropImageSrc = document.getElementById("imgUploadsignature");
            localStorage.setItem('imgUploadsignatureUpload', 'data:image/png;base64,' + base64ImgSrc);
            this.dragdropImageSrc.setAttribute("src", 'data:image/png;base64,' + base64ImgSrc);
            this.dragdropImageSrc.style["width"] = "135px";
            let dragdroplabel: any = document.getElementById("dragdroplabel");
            if (dragdroplabel != null && dragdroplabel != undefined) {
              dragdroplabel.style["display"] = "none";
            }

            let filelistInfo: any = document.getElementById("filelistInfo");
            filelistInfo.style["display"] = "block";
            let uploadedFileName: any = document.getElementById("uploadedFileName");
            uploadedFileName.innerHTML = file.name;
            let uploadedFileSize: any = document.getElementById("uploadedFileSize");
            uploadedFileSize.innerHTML = this.formatBytes((file.size), 2);

            uploaderloader.style["display"] = "none";
            let signaturePadModalComponentClass: any = document.getElementsByClassName("signaturePadModalComponent-class");
            if (signaturePadModalComponentClass && signaturePadModalComponentClass.length > 0) {
              signaturePadModalComponentClass[0].style["z-index"] = "1055";
            }
            let clicktoSignClass: any = document.getElementsByClassName("clicktoSign-class");
            if (clicktoSignClass && clicktoSignClass.length > 0) {
              clicktoSignClass[0].style["z-index"] = "1055";
            }
            let modalBackdrop: any = document.getElementsByClassName("modal-backdrop")
            modalBackdrop[0].style["z-index"] = "1055";
          }
        }
        else {
          uploaderloader.style["display"] = "none";
          let signaturePadModalComponentClass: any = document.getElementsByClassName("signaturePadModalComponent-class");
          if (signaturePadModalComponentClass && signaturePadModalComponentClass.length > 0) {
            signaturePadModalComponentClass[0].style["z-index"] = "1055";
          }
          let clicktoSignClass: any = document.getElementsByClassName("clicktoSign-class");
          if (clicktoSignClass && clicktoSignClass.length > 0) {
            clicktoSignClass[0].style["z-index"] = "1055";
          }
          let modalBackdrop: any = document.getElementsByClassName("modal-backdrop")
          modalBackdrop[0].style["z-index"] = "1055";
        }
        this.showLoader = false;
        this.oneDriveFileData = [];
        this.oneDriveFile = '';
      });
  }
  getLanguageTranslationValue(resourcekeyId: any) {
    return this.translationsService.getLanguageTranslationVal(resourcekeyId);
  }

  onClosePopup(eventType: any) {
    this.isSignaturePopupClosed.next('true');
    this.onPostMessage(eventType,'','');
     //this.activeModal.dismiss('Cross click');
  }
  onPostMessage(eventType: any,signatureTypeId:any,signatureData:any)
  {
    window.parent.postMessage(JSON.stringify({EventType:eventType,SignatureTypeId: localStorage.getItem("signatureTypeId") ,SignatureData:signatureData}), "*");
  }
  onCloseConfirmationPopup() {
    this.confirmationPopupStyle = 'none';
    this.files = [];
    this.googleDrivePreparedObject = '';
    this.oneDriveFileData = [];
    this.oneDriveFile = '';
    this.dropBoxFile = '';
    this.dropBoxFileImageLink = '';
  }

  onConfirmationPopup() {
    this.showLoader = true;
    this.confirmationPopupStyle = 'none';
    this.saveSignature(this.uploadType);
  }

  saveSignature(uploadSigType: any) {
    if (uploadSigType == "LocalMachine") {
      this.uploadSignerSignature(this.files);
    }
    else if (uploadSigType == "GoogleDrive") {
      this.showGoogleDriveImagePreview(this.googleDrivePreparedObject);
    }
    else if (uploadSigType == "DropBox") {
      this.showDropBoxImagePreview(this.dropBoxFileImageLink, this.dropBoxFile);
    }
    else if (uploadSigType == "OneDrive") {
      this.showOneDriveImagePreview(this.oneDriveFileData, this.oneDriveFile);
    }
  }

  uploadSignerSignature(uploadFile: any) {
    this.showLoader = true;
    const file = uploadFile[0];
    var formData: any = new FormData();
    formData.append('file', file);

    let url = CommonConstants.UploadSignerSignature;
    this.signerLandingService.uploadSignerSignature(url, formData).subscribe(
      (resp: any) => {
        this.showLoader = false;
        if (resp && resp.StatusCode == 200) {
          this.dragdropImageSrc = document.getElementById("imgUploadsignature");
          localStorage.setItem('imgUploadsignatureUpload', 'data:image/png;base64,' + resp.Base64FileData);
          this.dragdropImageSrc.setAttribute("src", 'data:image/png;base64,' + resp.Base64FileData);
          this.dragdropImageSrc.style["width"] = "135px";

          let filelistInfo: any = document.getElementById("filelistInfo");
          filelistInfo.style["display"] = "block";
          let uploadedFileName: any = document.getElementById("uploadedFileName");
          uploadedFileName.innerHTML = uploadFile[0].name;
          let uploadedFileSize: any = document.getElementById("uploadedFileSize");
          uploadedFileSize.innerHTML = this.formatBytes((uploadFile[0].size), 2);
        }

        (<HTMLInputElement>document.getElementById('fileUploadInput')).value = '';
      });
  }

}
