<app-header></app-header>

<div [ngStyle]="{'display':auditTrailPopupStyle}" id="auditTrail" class="modal audittrail-class" tabindex="-1"
  role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header bg-modal modalhead-padding">
        <h4 class="modal-title">Audit Trail</h4>
        <div class="d-flex align-items-center">
          <span class="material-icons modal-close" aria-label="Close" (click)="onAuditTrailClose()">
            close
          </span>
        </div>
      </div>

      <div class="modal-body modal-bodybg pb-1">
        <fieldset class="border mb-3 rounded-3 p-2 w-100 res-w100">
          <legend class="float-none w-auto px-3 font-15" style="font-weight:600;">
            {{getLanguageTranslationValue(resourceKeys.EnvelopeData)}}</legend>
          <table class="table audittable mb-2">
            <tbody>
              <tr class="sentListRow">
                <td class="w-25 td-font">{{getLanguageTranslationValue(resourceKeys.EnvelopeID)}}:</td>
                <td class="lefttd td-font">
                  <span id="spnAuditEnvelopeDisplayCode"></span>
                </td>
              </tr>
              <tr>
                <td class="w-25 td-font">{{getLanguageTranslationValue(resourceKeys.DocumentDetails)}}:</td>
                <td class="lefttd td-font">
                  <span *ngFor="let docItem of docList; let i=index;">
                    <a class="primarycolor" style="text-decoration: none;cursor: pointer;"
                      (click)="viewDocument(docItem.EnvelopeID, docItem.ID)"
                      title="View Document">{{docItem.DocumentName}} <i class="fa fa-download" aria-hidden="true"
                        style="margin-left:2px;"></i>{{(i == docList.length - 1 ? '' : ' ,')}} </a>
                  </span>
                </td>
              </tr>
              <tr>
                <td class="w-25 td-font">{{getLanguageTranslationValue(resourceKeys.Sender)}}:</td>
                <td class="lefttd td-font">
                  <span id="spnAuditSender"></span>
                </td>
              </tr>

              <tr>
                <td class="w-25 td-font">{{getLanguageTranslationValue(resourceKeys.Recipients)}}:</td>
                <td class="lefttd td-font">
                  <span id="spnAuditRecipient"></span>
                </td>
              </tr>

              <tr style="display:none" id="trAuditReferenceCode">
                <td class="w-25 td-font">{{getLanguageTranslationValue(resourceKeys.ReferenceCode)}}:</td>
                <td class="lefttd td-font">
                  <span id="spnReferenceCodeAuditTrail"></span>

                </td>
              </tr>

              <tr style="display:none" id="trAuditReferenceEmail">
                <td class="w-25 td-font">{{getLanguageTranslationValue(resourceKeys.ReferenceEmail)}}:</td>
                <td class="lefttd td-font">
                  <span id="spnReferenceEmailAuditTrail"></span>
                </td>
              </tr>

              <tr>
                <td class="w-25 td-font">{{getLanguageTranslationValue(resourceKeys.Sequence)}}:</td>
                <td class="lefttd td-font">
                  <span id="spnAuditSequential"></span>
                </td>
              </tr>

              <tr id="trStatusDisclaimer" style="display:none">
                <td class="w-25 td-font">{{getLanguageTranslationValue(resourceKeys.TermsOfService)}}:</td>
                <td class="lefttd td-font">{{getLanguageTranslationValue(resourceKeys.Accepted)}}</td>
              </tr>


            </tbody>
          </table>


          <div id="passwordsignopen" style="display:none">
            <h4 class="history-content-title font-18 ps-2">{{getLanguageTranslationValue(resourceKeys.Password)}}</h4>
          </div>
          <div class="w-100 d-flex res-flexcolumn1 align-items-center">
            <div id="passwordsign" style="display:none" class="w-25 res-w100 ps-1 res-ps2">
              <label class=" td-font">{{getLanguageTranslationValue(resourceKeys.Password)}}:</label>
              <label class="lefttd td-font">
                <font size="2"><span style="font-weight:700;" id="spnPasswordToSign"></span></font>
              </label>
            </div>
            <div id="passwordopen" style="display:none" class="ps-3 res-w100 res-ps2">
              <label
                class=" td-font">{{getLanguageTranslationValue(resourceKeys.AccessCodeToOpenSignedDocument)}}:</label>
              <label class="lefttd td-font">
                <font size="2"><span style="font-weight:700;" id="spnPasswordToOpen"></span></font>
              </label>
            </div>
          </div>

        </fieldset>

        <div class="table-responsive res-mh300" style=" margin-right: 26px;">
          <table class="documentHistoryTable table" id="documentHistoryTable">
            <thead class="term-history-table-head">
              <tr style="background: #deecf89e;">
                <th class="datetime font-14 font-libre">{{getLanguageTranslationValue(resourceKeys.DateTimeStamp)}}</th>
                <th class="status font-14 font-libre">{{getLanguageTranslationValue(resourceKeys.Status)}}</th>
                <th class="recipient font-14 font-libre">{{getLanguageTranslationValue(resourceKeys.Recipient)}}</th>
                <th class="original font-14 font-libre">{{getLanguageTranslationValue(resourceKeys.OldUser)}}</th>
                <th class="updated font-14 font-libre">{{getLanguageTranslationValue(resourceKeys.Updatedby)}}</th>
                <th class="ipaddress font-14 font-libre">{{getLanguageTranslationValue(resourceKeys.IPAddress)}}</th>
              </tr>
            </thead>
            <tbody id="tableTBody">
            </tbody>
          </table>
        </div>

      </div>

    </div>
  </div>
</div>

<main>
  <div class="main-container">
    <div class="section-title" data-aos="fade-up">
      <h2>{{getLanguageTranslationValue(resourceKeys.SignMultipleTempLabel)}}</h2>
      <p>{{getLanguageTranslationValue(resourceKeys.SignMultipleTempLabel2)}}</p>
    </div>

    <div class="w-100 mt-3 mb-2">
      <div class="card mx-3">
        <div class="card-body">
          <div class="d-flex align-items-center justify-content-between res-flexcolumn2">
            <div class="d-flex align-items-center col-sm-12 col-md-6">
              <a *ngIf="showAndHideDecline !== true" style="color: #c2282e; border: 0.1rem solid #dfdddceb; padding: 6px 14px;"
                (click)="openModal('Decline')" id="decline" class="btn btn-outline-primary d-flex align-items-center">
                <span class="fa fa fa-ban me-2 font-18"> </span>
                {{getLanguageTranslationValue(resourceKeys.Decline)}}
                {{getLanguageTranslationValue(resourceKeys.All)}}</a>
              <a *ngIf="showAndHideChangeSigner !== true" style=" border: 0.1rem solid #dfdddceb; padding: 6px 14px;" (click)="openModal('Change Signer')"
                id="delegate" class="btn btn-outline-primary d-flex align-items-center ms-3"> <span
                  class="fa fa-users me-2 font-18"> </span>
                {{getLanguageTranslationValue(resourceKeys.lang_delegate)}}
                {{getLanguageTranslationValue(resourceKeys.All)}}</a>
            </div>
            <div class="d-flex align-items-center col-sm-12 col-md-6 resmt-20px justify-content-end">
              <a *ngIf="EnvelopeAdditionalUploadInfoList != null && EnvelopeAdditionalUploadInfoList.length > 0"
                id="attachment" (click)="openModal('Attachments')"
                class="btn btn-outline-primary d-flex align-items-center">
                <span class="fa fa-paperclip me-2 font-18"> </span>
                Add Attachments</a>
              <a style="background: #0061d5; border: 0.1rem solid #0061d5; color: #fff;" (click)="finishEnvelope()"
                id="finish" class="btn btn-outline-primary d-flex align-items-center ms-3">
                <span class="fa fa-paper-plane me-2 font-18"> </span>
                {{getLanguageTranslationValue(resourceKeys.Finish)}} </a>
            </div>

          </div>

          <div class="section-title2 mt-3">
            <h5 class="font-w600"> {{getLanguageTranslationValue(resourceKeys.Sign)}}</h5>
          </div>

          <div class="d-flex align-items-center justify-content-between mb-3 res-flexcolumn1">

            <div class="icon-box justify-content-between">
              <div class="switch-button">
                <input class="switch-button-checkbox" type="checkbox" id="chkToggleEnvelope"
                  (change)="UpdateEnvelopeRecords($event)" />
                <label class="switch-button-label" for="">
                  <span class="switch-button-label-span"> {{getLanguageTranslationValue(resourceKeys.All)}}</span>
                </label>
              </div>
            </div>

            <div *ngIf="!divShowCompleteText" class="icon-box justify-content-between">
              <img class=" mb-3 h-45p" src="../../../assets/images/pending.png">
              <div class="d-flex align-items-center flex-column-reverse ms-2">
                <p class="mb-1 font-09r">Pending</p>
                <label id="lblPending" for="" class="count-text">1</label>
              </div>
            </div>

            <div *ngIf="!divShowCompleteText" class="icon-box justify-content-between">
              <img class=" mb-3 h-45p" src="../../../assets/images/agreement.png">
              <div class="d-flex align-items-center flex-column-reverse ms-2">
                <p class="mb-1 font-09r">Signed</p>
                <label id="lblSigned" for="" class="count-text">0</label>
              </div>
            </div>

            <div *ngIf="!divShowCompleteText" class="icon-box justify-content-between">
              <img class=" mb-3 h-55p" src="../../../assets/images/attachment.png" style="margin-left: -8px;">
              <div class="d-flex align-items-center flex-column-reverse ms-2">
                <p class="mb-1 font-09r">Pending Attachments</p>
                <label id="lblPendingAttachments" for="" class="count-text">0</label>
              </div>
            </div>

            <div *ngIf="divShowCompleteText" class="icon-box justify-content-between" style="width:325px">
              <img class="mb-3 h-55p" src="../../../assets/images/check.png">
              <div class="d-flex align-items-center flex-column-reverse ms-2">
                <p class="mb-1 font-09r" style="margin-top: 15px;">Click on Finish to Complete the Envelope</p>
              </div>
            </div>

          </div>

          <div class="table-responsive res-mh300">
            <table class="table table-striped1 templateListTable" id="tblSignMultipleDocument">
              <thead class="bg-blue">
                <tr>
                  <th *ngIf="IsAllSigned">
                    <div class="checkbox">
                      <label class="ps-0">
                        <input type="checkbox" value="" id="ckbox1" disabled>
                        <span class="cr"><i class="cr-icon material-icons">check</i></span>
                      </label>
                    </div>
                  </th>
                  <th *ngIf="!IsAllSigned">
                    <div class="checkbox">
                      <label class="ps-0">
                        <input type="checkbox" value="" id="ckbox1" (change)="chkSelectAll()">
                        <span class="cr"><i class="cr-icon material-icons">check</i></span>
                      </label>
                    </div>
                  </th>
                  <th class="res-font14 font-libre" id="templatename">Document Name (Role Name)</th>
                  <th class="res-font14 font-libre" id="prescriber">
                    {{getLanguageTranslationValue(resourceKeys.Prescriber)}}</th>
                  <th class="res-font14 font-libre" id="prescriberEmail">
                    {{getLanguageTranslationValue(resourceKeys.Prescriber)}}
                    {{getLanguageTranslationValue(resourceKeys.Email)}}</th>
                  <th class="res-font14 font-libre" id="Status">{{getLanguageTranslationValue(resourceKeys.Status)}}
                  </th>
                  <th class="res-font14 font-libre" id="updateDatetime">
                    {{getLanguageTranslationValue(resourceKeys.UpdatedStatusDate)}}</th>
                  <th class="res-font14 font-libre" id="sequence">Seq.</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let templateDetails of SignMultipleTemplateCollection; let i=index;" class="sentListRow"
                  id="{{templateDetails.EnvelopeID}}" [attr.data-env-recepient-id]="templateDetails.RecipientID"
                  [attr.data-env-recepient-email]="templateDetails.RecipientEmail" [attr.index]="i"
                  [attr.data-env-template-key]="templateDetails.TemplateKey">

                  <div *ngIf="templateDetails.RecipientEmail !=='' && templateDetails.RecipientEmail !== null ">
                    <div *ngIf="SignMultipleTemplate.CurrentRecipientEmail == templateDetails.RecipientEmail">
                      <td *ngIf="SignMultipleTemplate.IsAllSigned || templateDetails.IsSigned" class="actionableLinke"
                        scope="row"> </td>
                      <td *ngIf="!SignMultipleTemplate.IsAllSigned && !templateDetails.IsSigned">
                        <div class="checkbox">
                          <label class="ps-0">
                            <input type="checkbox" class="chkdata" id="inbox_{{i}}" name="documentGroup">
                            <span class="cr"><i class="cr-icon material-icons">check</i></span>
                          </label>
                        </div>
                      </td>
                    </div>
                    <div *ngIf="SignMultipleTemplate.CurrentRecipientEmail != templateDetails.RecipientEmail">
                      <td class="actionableLinke" scope="row"> </td>
                    </div>
                  </div>

                  <div *ngIf="templateDetails.RecipientEmail =='' && templateDetails.Mobile !=='' && templateDetails.Mobile !== null ">
                    <div
                      *ngIf="SignMultipleTemplate.DialCode + SignMultipleTemplate.MobileNumber == templateDetails.DialCode + templateDetails.Mobile">
                      <td *ngIf="SignMultipleTemplate.IsAllSigned || templateDetails.IsSigned" class="actionableLinke"
                        scope="row"> </td>
                      <td *ngIf="!SignMultipleTemplate.IsAllSigned && !templateDetails.IsSigned">
                        <div class="checkbox">
                          <label class="ps-0">
                            <input type="checkbox" class="chkdata" id="inbox_{{i}}" name="documentGroup">
                            <span class="cr"><i class="cr-icon material-icons">check</i></span>
                          </label>
                        </div>
                      </td>
                    </div>
                    <div
                      *ngIf="SignMultipleTemplate.DialCode + SignMultipleTemplate.MobileNumber != templateDetails.DialCode + templateDetails.Mobile">
                      <td class="actionableLinke" scope="row"> </td>
                    </div>
                  </div>

                  <td>{{templateDetails.TemplateName}}</td>
                  <td>{{templateDetails.RecipientName}}</td>

                  <td
                    *ngIf="templateDetails.Mobile != null && templateDetails.Mobile != '' && templateDetails.RecipientEmail != null && templateDetails.RecipientEmail != ''">
                    {{templateDetails.RecipientEmail}} {{ '('+ templateDetails.DialCode + templateDetails.Mobile + ')'}}
                  </td>
                  <td *ngIf="(templateDetails.Mobile == '' || templateDetails.Mobile == null) && templateDetails.RecipientEmail != null && templateDetails.RecipientEmail != ''">
                    {{templateDetails.RecipientEmail}}</td>
                  <td *ngIf="(templateDetails.RecipientEmail == '' || templateDetails.RecipientEmail == null) && templateDetails.Mobile != null && templateDetails.Mobile != ''">{{ templateDetails.DialCode
                    + templateDetails.Mobile}}</td>

                  <td *ngIf="templateDetails.IsSigned">Completed</td>
                  <td *ngIf="!templateDetails.IsSigned">{{templateDetails.Status}}</td>
                  <td>{{templateDetails.UpdatedStatusDateTimezoneStr}}</td>
                  <td>{{templateDetails.Order}}</td>

                  <div *ngIf="templateDetails.RecipientEmail !=='' && templateDetails.RecipientEmail !== null ">
                    <div class="w-100"
                      *ngIf="SignMultipleTemplate.CurrentRecipientEmail == templateDetails.RecipientEmail">
                      <div class="w-100" *ngIf="!templateDetails.IsSigned">
                        <td
                          *ngIf="(SignMultipleTemplate.IsSequenceCheck == true && SignMultipleTemplate.nextOrder == templateDetails.Order) || (SignMultipleTemplate.IsSequenceCheck == false && templateDetails.Order == 1)"
                          class="actionableLinke" scope="row">
                          <select id="sentActionsdropdowns_{{i}}" class="form-control form-select res-select"
                            (change)="actiondropdownChange($event,i,templateDetails.EnvelopeID,templateDetails.RecipientID,templateDetails.TemplateKey)">
                            <option value="0">Select Action</option>
                            <option value="88a6639f-ed04-40af-99ca-fad0e196f46d">Sign</option>
                            <option *ngIf="showAndHideChangeSigner !== true" value="f9dcfb52-2b8e-4d9f-96c7-6d02dcdddee3">Delegate</option>
                          </select>
                        </td>
                        <td
                          *ngIf="!(SignMultipleTemplate.IsSequenceCheck == true && SignMultipleTemplate.nextOrder == templateDetails.Order) && !(SignMultipleTemplate.IsSequenceCheck == false && templateDetails.Order == 1)"
                          class="actionableLinke " scope="row">
                          <select id="sentActionsdropdowns_{{i}}" class="form-control form-select res-select"
                            (change)="actiondropdownChange($event,i,templateDetails.EnvelopeID,templateDetails.RecipientID,templateDetails.TemplateKey)">
                            <option value="0">Select Action</option>
                            <option value="11f52f5c-ebf1-4507-aab2-6f9f1831385f">Audit Trail</option>
                          </select>
                        </td>
                      </div>
                      <div *ngIf="templateDetails.IsSigned">
                        <td scope="row"><a class="text-green" style="font-size:12px" id="lnkGreenTick_20544"><i
                              class="fa fa-check-circle fa-2x" aria-hidden="true"></i></a></td>
                      </div>
                    </div>

                    <div class="w-100"
                      *ngIf="SignMultipleTemplate.CurrentRecipientEmail != templateDetails.RecipientEmail">
                      <td *ngIf="!templateDetails.IsSigned" class="actionableLinke" scope="row">
                        <select id="sentActionsdropdowns_{{i}}" style="color: gray;cursor: no-drop;"
                          class="form-control form-select res-select" disabled="disabled">
                          <option value="0" class="select">Select Action</option>
                        </select>
                      </td>
                      <td *ngIf="templateDetails.IsSigned" class="actionableLinke" scope="row">
                        <a class="text-green" style="font-size:12px" id="lnkGreenTick_20544">
                          <i class="fa fa-check-circle fa-2x" aria-hidden="true"></i></a>
                      </td>
                    </div>
                  </div>

                  <div *ngIf="templateDetails.RecipientEmail =='' && templateDetails.Mobile !=='' && templateDetails.Mobile !== null ">
                    <div class="w-100"
                      *ngIf="SignMultipleTemplate.DialCode + SignMultipleTemplate.MobileNumber == templateDetails.DialCode + templateDetails.Mobile">
                      <div class="w-100" *ngIf="!templateDetails.IsSigned">
                        <td
                          *ngIf="(SignMultipleTemplate.IsSequenceCheck == true && SignMultipleTemplate.nextOrder == templateDetails.Order) || (SignMultipleTemplate.IsSequenceCheck == false && templateDetails.Order == 1)"
                          class="actionableLinke" scope="row">
                          <select id="sentActionsdropdowns_{{i}}" class="form-control form-select res-select"
                            (change)="actiondropdownChange($event,i,templateDetails.EnvelopeID,templateDetails.RecipientID,templateDetails.TemplateKey)">
                            <option value="0">Select Action</option>
                            <option value="88a6639f-ed04-40af-99ca-fad0e196f46d">Sign</option>
                            <option *ngIf="showAndHideChangeSigner !== true" value="f9dcfb52-2b8e-4d9f-96c7-6d02dcdddee3">Delegate</option>
                          </select>
                        </td>
                        <td
                          *ngIf="!(SignMultipleTemplate.IsSequenceCheck == true && SignMultipleTemplate.nextOrder == templateDetails.Order) && !(SignMultipleTemplate.IsSequenceCheck == false && templateDetails.Order == 1)"
                          class="actionableLinke " scope="row">
                          <select id="sentActionsdropdowns_{{i}}" class="form-control form-select res-select"
                            (change)="actiondropdownChange($event,i,templateDetails.EnvelopeID,templateDetails.RecipientID,templateDetails.TemplateKey)">
                            <option value="0">Select Action</option>
                            <option value="11f52f5c-ebf1-4507-aab2-6f9f1831385f">Audit Trail</option>
                          </select>
                        </td>
                      </div>
                      <div *ngIf="templateDetails.IsSigned">
                        <td scope="row"><a class="text-green" style="font-size:12px" id="lnkGreenTick_20544"><i
                              class="fa fa-check-circle fa-2x" aria-hidden="true"></i></a></td>
                      </div>
                    </div>

                    <div class="w-100"
                      *ngIf="SignMultipleTemplate.DialCode + SignMultipleTemplate.MobileNumber != templateDetails.DialCode + templateDetails.Mobile">
                      <td *ngIf="!templateDetails.IsSigned" class="actionableLinke" scope="row">
                        <select id="sentActionsdropdowns_{{i}}" style="color: gray;cursor: no-drop;"
                          class="form-control form-select res-select" disabled="disabled">
                          <option value="0" class="select">Select Action</option>
                        </select>
                      </td>
                      <td *ngIf="templateDetails.IsSigned" class="actionableLinke" scope="row">
                        <a class="text-green" style="font-size:12px" id="lnkGreenTick_20544">
                          <i class="fa fa-check-circle fa-2x" aria-hidden="true"></i></a>
                      </td>
                    </div>
                  </div>
                </tr>
              </tbody>

            </table>
          </div>

          <div class="my-4">
            <div class="section-title2">
              <h5 class="font-w600"> Message </h5>
            </div>
            <div class=" d-flex align-items-center justify-content-center">
              <div class="card message-card">
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-bordered mb-1">
                      <tr>
                        <td>
                          <label for=""
                            class="font-w600 font-libre">{{getLanguageTranslationValue(resourceKeys.Subject)}}:</label>
                        </td>
                        <td>
                          <label *ngIf="SignMultipleTemplate" class="res-nowrap" for=""
                            style="margin-left:-10px">{{SignMultipleTemplate.Subject}}</label>
                        </td>
                        <td style="border-left: 1px solid #c9d1d6;  padding-left: 10px;">
                          <label for="" class="font-w600 font-libre">Message:</label>
                        </td>
                        <td>
                          <label *ngIf="SignMultipleTemplate" class="res-nowrap" for=""
                            style="margin-left:-10px">{{SignMultipleTemplate.Subject}}</label>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</main>
<footer style="background: #eee;" class="default-footer mt-auto pt-2 pb-2">
  <div class="container1" style="font-size: 10px;">
    <div class="fotertext" [innerHTML]="htmlFooterString">
    </div>
  </div>
</footer>
<div *ngIf="showLoader" class="loader">
</div>