<!-- Modal Header -->
<div class="modal-header bg-modal modalhead-padding">
    <h4 class="modal-title"> {{getLanguageTranslationValue(resourceKeys.ChangeSigner)}}</h4>
    <div class="d-flex align-items-center">
        <span class="material-icons modal-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            close
        </span>
    </div>
    <!-- <button type="button" class="btn-close" data-bs-dismiss="modal"></button> -->
</div>

<!-- Modal body -->
<div class="modal-body modal-bodybg">
    <form [formGroup]="form" class="modal-height">
        <div class="form-group mb-3 border-boxsize">
            <input type="hidden" autofocus="autofocus" style="display:none" />
            <label for="name" class="label-font14">New signer name</label>
            <input formControlName="name" placeholder="Enter signer name" id="name" type="text" autocomplete="off"
                class="form-control" autoFocus="false">
        </div>

        <div *ngIf="!EnableMessageToMobile" class="form-group mb-3 border-boxsize">
            <label for="email" class="label-font14">New signer email</label>
            <input formControlName="email" placeholder="Enter signer email" id="email" type="text" autocomplete="off"
                class="form-control" autoFocus="false">
        </div>

        <div *ngIf="EnableMessageToMobile && webOrMobile == 'web'" class="form-group mb-3 border-boxsize">
            <label for="inviteby" class="label-font14">Invite by</label>
            <label for="email" class="label-font14" style="margin-left: 22px;">New signer email</label>

            <div class="" style="display: flex;">
                <div class="col-lg-1 col-md-1 col-sm-12 col-xs-1" style="width: 50px;display: flex;">
                    <div class="checkbox topleft">
                        <label class="ps-0">
                            <input type="checkbox" value="" id="chkEmail" class="chkEmail">
                            <span class="cr">
                                <i class="cr-icon material-icons">check</i></span>
                        </label>
                    </div>
                    <!-- <input class="chkEmail" id="chkEmail" type="checkbox"
                        style="width: 20px; height: 20px; margin-left: 0px;margin-top: 5px;" /> -->
                </div>
                <div class="" style="display: inline-block;">
                    <input formControlName="email" placeholder="Enter signer email" id="email" type="text"
                        autocomplete="off" style="margin-left: 25px;width: 392px;" class="form-control"
                        autoFocus="false">
                </div>
            </div>
        </div>

        <div *ngIf="EnableMessageToMobile && webOrMobile == 'web'" class="input-group form-group mb-3 border-boxsize">
            <label for="email" class="label-font14" style="margin-left: 75px;">Country code</label>
            <label for="mobile" class="label-font14" style="margin-left: 50px;">Mobile number</label>
            <br />

            <div class="" style="display: flex;">
                <div class="" style="width: 50px;display: flex;">
                    <div class="checkbox topleft">
                        <label class="ps-0">
                            <input type="checkbox" value="" id="chkMobile" class="chkMobile">
                            <span class="cr">
                                <i class="cr-icon material-icons">check</i></span>
                        </label>
                    </div>
                    <!-- <input class="chkMobile" id="chkMobile" type="checkbox"
                        style="width: 20px;height: 20px;margin-left: 2px;display: flex;margin-top: 5px;" /> -->
                </div>
                <div class="" style="display: flex;margin-left: 25px;">
                    <div ngbDropdown class="dropdown" #myDrop="ngbDropdown" style="width: 127px;display: inline-block;">
                        <a ngbDropdownToggle style="text-decoration-line: none;  color: rgb(33, 37, 41);">
                            <span id="spnTitle" style="display: inline;">Select</span>
                        </a>
                        <ul ngbDropdownMenu class="dropdown-menu" id="DialCodeUl">
                            <input class="search-countryinput" type="search" placeholder="Enter country name to search"
                                id="countrySearchinput" autocomplete="off" title="Enter country name to search"
                                style="border-color: white;border-style: double;height: 35px;width: 228px;margin-left: 2px;font-size: 14px;color: #000;"
                                (keyup)="getFilteredCountryNames($event)">
                            <li class="liCountryItem" *ngFor="let dialcodes of DialCodeDropdownList; let i = index;"
                                (click)="selectedCountryDialCode($event);myDrop.close();">
                                <a class="selecttext" *ngIf="dialcodes.DialCode == 'Select'" [attr.data-maxlength]="0"
                                    style="text-decoration-line: none;color: #fff;padding-top: 15px;padding-bottom: 15px;background: #272f3b;">
                                    <span id="spnCountryFlag"></span>Select</a>
                                <a style="text-decoration-line: none;color: rgb(33, 37, 41);"
                                    *ngIf="dialcodes.DialCode != 'Select'"
                                    [attr.data-countrycode]="dialcodes.CountryCode.toLowerCase()"
                                    [attr.data-maxlength]="dialcodes.MobileMaxLength">
                                    <span id="spnCountryFlag" class="bg-{{dialcodes.CountryCode.toLowerCase()}}"
                                        style="top: -8px; display: inline-block; width: 25px;"></span>{{dialcodes.CountryName}}{{"
                                    ("+dialcodes.DialCode + ")"}}</a>
                            </li>
                        </ul>
                    </div>

                    <div style="display: inline-block;"> 
                        <input type="text" style="margin-left: 10px;width:256px;"
                            class="form-control txtSignerMobileNumber" id="txtSignerMobileNumber"
                            placeholder="Enter mobile number" name="mobilenumber" (input)="validateInputValue($event)"
                            pattern="[0-9]*" ondrop="return false" inputmode="numeric" typeof="number"
                            spellcheck="false" autocomplete="off"></div>
                </div>
            </div>
            <input type="hidden" id="hdnDeliveryMode" value="" />
        </div>

        <div *ngIf="EnableMessageToMobile && webOrMobile == 'mobile'" class="form-group mb-3 border-boxsize">
            <label for="inviteby" class="label-font14">Invite by</label>
            <label for="email" class="label-font14" style="margin-left: 22px;">New signer email</label>

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="display: flex;">
                <div class="col-lg-1 col-md-1 col-sm-12 col-xs-1" style="width: 50px;display: flex;">
                    <!-- <input class="chkEmail" id="chkEmail" type="checkbox"
                        style="width: 24px; height: 24px; margin-left: 0px;margin-top:5px;" /> -->
                        <div class="checkbox topleft">
                            <label class="ps-0">
                                <input type="checkbox" value="" id="chkEmail" class="chkEmail">
                                <span class="cr">
                                    <i class="cr-icon material-icons">check</i></span>
                            </label>
                        </div>
                </div>
                <div class="col-lg-10 col-md-10 col-sm-12 col-xs-9" style="display: inline-block;">
                    <input formControlName="email" placeholder="Enter signer email" id="email" type="text"
                        autocomplete="off" style="margin-left: 25px;width:250px;" class="form-control"
                        autoFocus="false">
                </div>
            </div>
        </div>

        <div *ngIf="EnableMessageToMobile && webOrMobile == 'mobile'"
            class="input-group form-group mb-3 border-boxsize">
            <label for="email" class="label-font14" style="margin-left: 40px;">Country code</label>
            <label for="mobile" class="label-font14" style="margin-left: 45px;">Mobile number</label>
            <br />

            <div style="display: flex;">
                <div class="" style="width: 1px;display: block;">
                    <div class="checkbox topleft">
                        <label class="ps-0">
                            <input type="checkbox" value="" id="chkMobile" class="chkMobile">
                            <span class="cr">
                                <i class="cr-icon material-icons">check</i></span>
                        </label>
                    </div>
                    <!-- <input class="chkMobile" id="chkMobile" type="checkbox"
                        style="width: 24px;height: 24px;margin-left: 2px;display: block;margin-top: 4px;" /> -->
                </div>
                <div style="display: flex;margin-left: 40px;">
                    <div ngbDropdown class="dropdown" #myDrop="ngbDropdown" style="width: 149px;display: inline-block;">
                        <a ngbDropdownToggle style="text-decoration-line: none; color: rgb(33, 37, 41);">
                            <span id="spnTitle" style="display: inline;">Select</span>
                        </a>
                        <ul ngbDropdownMenu class="dropdown-menu" id="DialCodeUl">
                            <input class="search-countryinput" type="search" placeholder="Enter country name to search"
                                id="countrySearchinput" autocomplete="off" title="Enter country name to search"
                                style="color:#000;border-color: white;border-style: double;height: 35px;width: 228px;margin-left: 2px;font-size: 14px;"
                                (keyup)="getFilteredCountryNames($event)">
                            <li class="liCountryItem" *ngFor="let dialcodes of DialCodeDropdownList; let i = index;"
                                (click)="selectedCountryDialCode($event);myDrop.close();">
                                <a class="selecttext" *ngIf="dialcodes.DialCode == 'Select'" [attr.data-maxlength]="0"
                                    style="text-decoration-line: none;color: white;padding-top: 15px;padding-bottom: 15px;background: #272f3b;">
                                    <span id="spnCountryFlag"></span>Select</a>
                                <a style="text-decoration-line: none;color: rgb(33, 37, 41);"
                                    *ngIf="dialcodes.DialCode != 'Select'"
                                    [attr.data-countrycode]="dialcodes.CountryCode.toLowerCase()"
                                    [attr.data-maxlength]="dialcodes.MobileMaxLength">
                                    <span id="spnCountryFlag" class="bg-{{dialcodes.CountryCode.toLowerCase()}}"
                                        style="top: -8px; display: inline-block; width: 25px;"></span>{{dialcodes.CountryName}}{{"
                                    ("+dialcodes.DialCode + ")"}}</a>
                            </li>
                        </ul>
                    </div>

                    <div style="display: inline-block;">
                        <input type="text" style="width: 98%;margin-left: 2px;" pattern="[0-9]*" inputmode="numeric"
                            typeof="number" class="form-control txtSignerMobileNumber" id="txtSignerMobileNumber"
                            ondrop="return false" spellcheck="false" placeholder="Mobile number" name="mobilenumber"
                            autocomplete="off" (ontouchstart)="validateInputValue($event)"
                            (input)="validateInputValue($event)">
                    </div>
                </div>
            </div>
            <input type="hidden" id="hdnDeliveryMode" value="" />
        </div>

        <div class="form-group mb-2 border-boxsize">
            <label for="csComments" class="label-font14">Add a message for the new signer</label>
            <textarea d="csComments" placeholder="Maximum 250 characters" autoFocus="false"
                (change)="commentsTextareaInput($event)" type="text" class="form-control" maxlength="250"
                style="height: 80px;resize: none;" rows="6"></textarea>
        </div>

        <div *ngIf="attachSignedPdfID" class="form-group checkbox mb-4 res-mb65">
            <label class="ps-1">
                <input id="chkterms" type="checkbox" formControlName="sendFinalSignDocumentChangeSigner">
                <span class="cr" style="position: relative; top: 7px; margin-right: 6px;">
                    <i class="cr-icon material-icons">check</i>
                </span>
                <span style="margin-top: 3px;margin-bottom: 0px;position: absolute;">{{getLanguageTranslationValue(resourceKeys.SendFinalSignDocumentChangeSigner)}}</span>
                <!-- <span style="margin-top: 3px;margin-bottom: 0px;">Send me a copy of the email to download the final signed document.</span> -->
            </label>
        </div>
    </form>
</div>

<!-- Modal footer -->
<div class="modal-footer bg-modal modalfooter-padding">
    <button type="button" class="btn btncancel px-3 py-1" (click)="activeModal.dismiss('Cross click')"
        id="btnSignerCancel" style="height: 23px;border-radius: 4px;">
        {{getLanguageTranslationValue(resourceKeys.Cancel)}}</button>
    <button type="button" class="btn btn-default btn_accept button-29 ms-3" id="btnAccept"
        (click)="onChangeSignersubmit()"> {{getLanguageTranslationValue(resourceKeys.Continue)}}</button>
</div>

<div *ngIf="showLoader" class="loader">
</div>