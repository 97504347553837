import { Component, OnInit } from '@angular/core';
import { SignerLandingService } from '../../services/signer-landing.service';
import { CommonService } from '../../services/common.service';
import { Router } from '@angular/router';
import { CommonConstants, MessageTypes, RecipientType, RouteUrls } from '../../constants/common-constants';
import { TranslationsService } from '../../services/translations.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
    responseSigningUrlModel: any;
    documentControlsResponse: any;
    commonConstants = CommonConstants;
    isDirect: string = '';
    isFromInbox: boolean = false;
    isFromSignMultiTemplate: boolean = false;
    showLoader: boolean = false;
    ipAddress: string = '';
    network: string = '';
    domain: string = '';
    isFromPrepare: boolean = false;

    constructor(private router: Router, private signerLandingService: SignerLandingService, private commonService: CommonService,
        private translationsService: TranslationsService, private http: HttpClient,) { }

    ngOnInit(): void {
        this.showLoader = true;
        let IsFromBotClick: any = localStorage.getItem("IsFromBotClick");
        let actualSigningUrl: any = localStorage.getItem("actualSigningUrl");
        let isFromPrepare: any = localStorage.getItem("IsFromPrepare");

        if (isFromPrepare == "true") this.isFromPrepare = true;
        
        localStorage.clear();
        sessionStorage.removeItem("SignerProfileDetails");
        sessionStorage.removeItem("isSignerpopupCount");
        sessionStorage.removeItem("LaungaueTransalation");
        sessionStorage.removeItem("LanguageValidation");
        localStorage.setItem("documentControlsResponse", '');
        localStorage.setItem("responseSigningUrlModel", '');

        if (this.isFromPrepare == false && (IsFromBotClick == null || IsFromBotClick == "" || IsFromBotClick == undefined)) {
            actualSigningUrl = window.location.href;
        }
        localStorage.setItem("actualSigningUrl", actualSigningUrl);
        actualSigningUrl = actualSigningUrl.substring(0, actualSigningUrl.length - 1);
        let signingUrl: any = decodeURI(actualSigningUrl.split('?')[1]);
        localStorage.setItem("signingUrl", signingUrl);
        if (signingUrl == undefined || signingUrl == "undefined" || signingUrl == null) {           
            localStorage.setItem("DisplayMessage", CommonConstants.InvalidUrl);
            localStorage.setItem("MessageType", "Warning");
            this.commonService.getReturnUrlDirection(RouteUrls.Info);
        }
        else {
            let isValidBotClick: any = sessionStorage.getItem("isValidBotClick");
            if (IsFromBotClick == "1") {
                this.showLoader = true;
                localStorage.setItem("IsFromBotClick", "");
                sessionStorage.removeItem("isValidBotClick");
            }

            this.getInitializeSignerSignDocument(signingUrl, IsFromBotClick);
        }
    }

    getInitializeSignerSignDocument(signingUrl: string, IsFromBotClick: any) {
        this.showLoader = true;
        let isFromSignerPreLanding: any = sessionStorage.getItem("IsFromSignerPreLanding");
        if (isFromSignerPreLanding == null || isFromSignerPreLanding == undefined)
            isFromSignerPreLanding = true;
        else if (isFromSignerPreLanding == "false")
            isFromSignerPreLanding = false;
        else
            isFromSignerPreLanding = true;

        let isFromSignMultiTemplate: any = sessionStorage.getItem("isFromSignMultiTemplate");
        if (isFromSignMultiTemplate == "true") {
            isFromSignerPreLanding = false;
        }

        if(this.isFromPrepare == true){
            isFromSignerPreLanding = false;
        }

        sessionStorage.removeItem("IsFromSignerPreLanding");
        let paramsObj = { SigningUrl: signingUrl, IsFromSignerPreLanding: isFromSignerPreLanding, IsFromBotClick: IsFromBotClick};
        let signDocumentUrl = this.commonConstants.InitializeSignerSignDocument;

        this.signerLandingService.getSignerSignDocumentData(signDocumentUrl, paramsObj).subscribe(
            (resp: any) => {
                if (resp && resp.StatusCode == 200 && resp.StatusMessage.toLowerCase() == "ok") {
                    if (resp.IsEnvelopePurging == true || resp.IsEnvelopePurging) {
                        this.showLoader = false;
                        sessionStorage.removeItem("isFromSignMultiTemplate");                       
                        localStorage.setItem("MessageType", "ArchivedOrPurged");
                        localStorage.setItem("IsApplicationError", "false");
                        localStorage.setItem("DisplayMessage", resp.Message);
                        this.commonService.getReturnUrlDirection(RouteUrls.Info);
                    }
                    else {
                        this.responseSigningUrlModel = resp.ResponseSigningUrlModel;
                        localStorage.setItem("EnvelopeID", this.responseSigningUrlModel.EnvelopeID);
                        localStorage.setItem("RecipientID", this.responseSigningUrlModel.RecipientID);
                        localStorage.setItem("currentRecipientID", this.responseSigningUrlModel.RecipientID);
                        localStorage.setItem("EmailId", this.responseSigningUrlModel.EmailId);
                        localStorage.setItem("CopyMailId", this.responseSigningUrlModel.CopyEmailId);
                        localStorage.setItem("responseSigningUrlModel", JSON.stringify(this.responseSigningUrlModel));

                        let languageTranslations: any = resp.LanguageTranslationsModel;
                        sessionStorage.setItem("LaungaueTransalation", JSON.stringify(languageTranslations.Language));
                        sessionStorage.setItem("LanguageValidation", JSON.stringify(languageTranslations.LanguageValidation));

                        resp.LanguageTranslationsModel = null;
                        this.documentControlsResponse = resp;
                        let respEnvelopeInfo: any = this.documentControlsResponse.EnvelopeInfo;
                        let cultureInfo: string = '';
                        if (this.documentControlsResponse.EnvelopeInfo.CultureInfo == "") cultureInfo = "en-us";
                        else cultureInfo = this.documentControlsResponse.EnvelopeInfo.CultureInfo;
                        localStorage.setItem("cultureInfo", cultureInfo);
                        this.getLanguageTranslations();

                        let respEnvelopeImageControlData: any = this.documentControlsResponse.EnvelopeImageControlData;
                        let respCheckListData: any = this.documentControlsResponse.CheckListData;
                        let respUserdata: any = this.documentControlsResponse.userdata;
                        localStorage.setItem("EnvelopeImageControlData", JSON.stringify(respEnvelopeImageControlData));
                        localStorage.setItem("CheckListData", JSON.stringify(respCheckListData));
                        localStorage.setItem("Userdata", JSON.stringify(respUserdata));
                        localStorage.setItem("EnvelopeInfo", JSON.stringify(respEnvelopeInfo));

                        this.documentControlsResponse.EnvelopeImageControlData = null;
                        this.documentControlsResponse.Language = null;
                        this.documentControlsResponse.CheckListData = null;
                        this.documentControlsResponse.userdata = null;
                        this.documentControlsResponse.EnvelopeInfo = null;

                        localStorage.setItem("documentControlsResponse", JSON.stringify(this.documentControlsResponse));

                        this.documentControlsResponse.EnvelopeImageControlData = respEnvelopeImageControlData;
                        this.documentControlsResponse.CheckListData = respCheckListData;
                        this.documentControlsResponse.userdata = respUserdata;
                        this.documentControlsResponse.EnvelopeInfo = respEnvelopeInfo;

                        localStorage.setItem("senderEmail", this.documentControlsResponse.EnvelopeInfo.SenderEmail);
                        localStorage.setItem("isStatic", this.documentControlsResponse.EnvelopeInfo.IsStatic);
                        localStorage.setItem("isPasswordMailToSigner", this.documentControlsResponse.IsPasswordMailToSigner);
                        localStorage.setItem("isStaticPwd", this.documentControlsResponse.EnvelopeInfo.PasswordReqdtoSign);
                        localStorage.setItem("recipientEmail", this.documentControlsResponse.EnvelopeInfo.RecipientEmail);
                        localStorage.setItem("IsSMSAccessCode", this.documentControlsResponse.IsSMSAccessCode);
                        localStorage.setItem('IsAdditionalAttmReq', this.documentControlsResponse.EnvelopeInfo.IsAdditionalAttmReq);
                        if (this.responseSigningUrlModel.EmailId == "" || this.responseSigningUrlModel.EmailId == null) {
                            localStorage.setItem("EmailId", this.documentControlsResponse.EnvelopeInfo.RecipientEmail);
                        }

                        if (this.documentControlsResponse.TemplateRolesInfo && this.documentControlsResponse.TemplateRolesInfo.length > 0) {
                            //localStorage.setItem("statictRolesCount", this.documentControlsResponse.TemplateRolesInfo.length);
                            localStorage.setItem("rolesCount", this.documentControlsResponse.TemplateRolesInfo.length);
                            localStorage.setItem("rolsInfos", JSON.stringify(this.documentControlsResponse.TemplateRolesInfo));
                        }
                        else {
                            //localStorage.setItem("statictRolesCount", "0");
                            localStorage.setItem("rolsInfos", '');
                            localStorage.setItem("rolesCount", "0");
                        }

                        if ((this.documentControlsResponse.EnvelopeInfo.IsStatic == true && this.documentControlsResponse.IsDefaultSignatureForStaticTemplate == false) ||
                            (this.documentControlsResponse.IsSharedTemplateContentUnEditable == true && this.documentControlsResponse.IsDefaultSignatureForStaticTemplate == false)) {
                            let isStaticTemplate: any = true;
                            localStorage.setItem("isStaticTemplate", isStaticTemplate);
                        }
                        else {
                            localStorage.setItem("isStaticTemplate", "false");
                        }

                        if (isFromSignerPreLanding && this.documentControlsResponse.AllowMultipleSigner == true && this.documentControlsResponse.EnvelopeInfo.IsStatic) {
                            sessionStorage.removeItem("isFromSignMultiTemplate");
                            // localStorage.setItem("AllowMultipleSigner", "true");
                            localStorage.setItem("IsStatic", "true");
                            this.commonService.getReturnUrlDirection(RouteUrls.AllowMultipleSigner);
                        }
                        else if (isFromSignerPreLanding && this.documentControlsResponse != null && this.documentControlsResponse.InviteSignNowByEmail > 0) {
                            sessionStorage.removeItem("isFromSignMultiTemplate");
                            this.commonService.getReturnUrlDirection(RouteUrls.AllowMultipleSigner);
                        }
                        else if (isFromSignerPreLanding && this.documentControlsResponse.EnvelopeInfo.PasswordReqdtoSign &&
                            (isFromSignMultiTemplate == "false" || isFromSignMultiTemplate == null)) {
                            sessionStorage.removeItem("isFromSignMultiTemplate");
                            localStorage.setItem("passwordReqdtoOpen", this.documentControlsResponse.EnvelopeInfo.PasswordReqdtoOpen);
                            localStorage.setItem("senderEmail", this.documentControlsResponse.EnvelopeInfo.SenderEmail);
                            this.commonService.getReturnUrlDirection(RouteUrls.PasswordWindow);
                        }
                        else {
                            setTimeout(() => {
                                this.showLoader = true;
                                // localStorage.setItem("documentControlsResponse", JSON.stringify(this.documentControlsResponse));
                                localStorage.setItem("isStatic", this.documentControlsResponse.EnvelopeInfo.IsStatic);
                                localStorage.setItem("prefill", this.documentControlsResponse.Prefill);
                                localStorage.setItem("senderEmail", this.documentControlsResponse.EnvelopeInfo.SenderEmail);
                                // localStorage.setItem("encryptedGlobalEnvelopeID", this.documentControlsResponse.EncryptedGlobalEnvelopeID);
                                // localStorage.setItem("encryptedGlobalRecipientID", this.documentControlsResponse.EncryptedGlobalRecipientID);
                                // localStorage.setItem("encryptedSender", this.documentControlsResponse.EncryptedSender);
                                localStorage.setItem("isPasswordMailToSigner", this.documentControlsResponse.IsPasswordMailToSigner);
                                localStorage.setItem("isStaticPwd", this.documentControlsResponse.EnvelopeInfo.PasswordReqdtoSign);
                                localStorage.setItem("recipientEmail", this.documentControlsResponse.EnvelopeInfo.RecipientEmail);
                                localStorage.setItem("recipientTypeId", this.documentControlsResponse.EnvelopeInfo.RecipientTypeId);
                                localStorage.setItem("eDisplayCode", this.documentControlsResponse.EnvelopeInfo.EDisplayCode);
                                // localStorage.setItem("passwordReqdtoSign", this.documentControlsResponse.EnvelopeInfo.PasswordReqdtoSign);
                                localStorage.setItem("passwordReqdtoOpen", this.documentControlsResponse.EnvelopeInfo.PasswordReqdtoOpen);
                                // localStorage.setItem("maxUploadID", this.documentControlsResponse.MaxUploadID);
                                localStorage.setItem("EnvelopeID", this.documentControlsResponse.EnvelopeInfo.GlobalEnvelopeID);
                                localStorage.setItem("IsSMSAccessCode", this.documentControlsResponse.IsSMSAccessCode);

                                //Based on response,need to redirect to other pages
                                let isFrmMultitemplate: any = sessionStorage.getItem("isFromSignMultiTemplate");
                                if (isFrmMultitemplate == "true") {
                                    this.isFromSignMultiTemplate = true;
                                }
                                else {
                                    this.isFromSignMultiTemplate = false;
                                }
                                if (this.documentControlsResponse.EnvelopeInfo.IsTemplateShared == true && this.isFromSignMultiTemplate == false &&
                                    this.documentControlsResponse.EnvelopeInfo.RecipientTypeIDPrefill && this.documentControlsResponse.EnvelopeInfo.RecipientTypeIDPrefill.toLowerCase() != RecipientType.Prefill.toLowerCase() && !this.isNullOrEmptyCheck(this.isDirect)) {
                                    sessionStorage.removeItem("isFromSignMultiTemplate");
                                    this.commonService.getReturnUrlDirection(RouteUrls.SignMultiTemplate);
                                }
                                else if (this.documentControlsResponse.EnvelopeInfo.RecipientTypeIDPrefill && this.documentControlsResponse.EnvelopeInfo.RecipientTypeIDPrefill.toLowerCase() == RecipientType.Prefill.toLowerCase()) {
                                    sessionStorage.removeItem("isFromSignMultiTemplate");
                                    this.commonService.getReturnUrlDirection(RouteUrls.SignDocument);
                                }
                                else {
                                    sessionStorage.removeItem("isFromSignMultiTemplate");
                                    this.commonService.getReturnUrlDirection(RouteUrls.SignDocument);
                                }
                                this.showLoader = false;
                            }, 10);
                        }
                    }
                }
                else if (resp && resp.originalError && resp.originalError.error && (resp.originalError.error.StatusCode == 400 || resp.originalError.error.StatusCode == 204)) {
                    this.showLoader = false;
                    if (resp.originalError.error.IsBotClick == 2) {
                        // console.log("validateBotIp StatusCode is not 200 ");
                        sessionStorage.removeItem("isFromSignMultiTemplate");
                        // localStorage.setItem("encryptedSender", "");
                        localStorage.setItem("MessageType", "Warning");
                        localStorage.setItem("IsApplicationError", "false");
                        localStorage.setItem("PageName", "ValidatingUser");
                        localStorage.setItem("DisplayMessage", CommonConstants.RpostValidateBotFailure);
                        this.showLoader = false;
                        this.commonService.getReturnUrlDirection(RouteUrls.Info);
                    }
                    else if (resp.originalError.error.IsBotClick == 3) {
                        // console.log("This is bot click and navigating to validate user.");
                        localStorage.setItem("IsFromBotClick", "");
                        this.commonService.getReturnUrlDirection(RouteUrls.ValidateSigner);
                    }
                    else {
                        sessionStorage.removeItem("isFromSignMultiTemplate");
                        let encryptedSender: string = resp.originalError.error.EncryptedSender;
                        //localStorage.setItem("encryptedSender", encryptedSender);
                        localStorage.setItem("DisplayMessage", resp.originalError.error.Message);
                        localStorage.setItem("MessageType", "Warning");
                        if (resp.originalError.error.ErrorAction == "Resend") {
                            this.commonService.getReturnUrlDirection(RouteUrls.Info);
                        }
                        else if (resp.originalError.error.ErrorAction == "Expire") {
                            resp.originalError.error.Message == "" ? localStorage.setItem("DisplayMessage", "This envelope is expired.") : localStorage.setItem("DisplayMessage", resp.originalError.error.Message);
                            this.commonService.getReturnUrlDirection(RouteUrls.Info);
                        }
                        else if (resp.originalError.error.ErrorAction == "EnvelopeAccepted" || resp.originalError.error.ErrorAction == "ContactSender") {
                            resp.originalError.error.Message == "" ? localStorage.setItem("DisplayMessage", "This document has already been signed and returned to the sender.") : localStorage.setItem("DisplayMessage", resp.originalError.error.Message);
                            localStorage.setItem("DisplayMessage", resp.originalError.error.Message);
                            localStorage.setItem("MessageType", MessageTypes.Success);

                            localStorage.setItem("AllowSignersDownloadFinalContract", resp.originalError.error.IsAllowSignerstoDownloadFinalContract == true ? "true" : "false");
                            if (resp.originalError.error.ErrorAction == "ContactSender") {
                                if (resp.originalError.error.IsAllowSignerstoDownloadFinalContract == true) {
                                    if (resp.originalError.error.RecipientOrder == 1) { localStorage.setItem("DisplayLoginButton", "true"); localStorage.setItem("DisplaySignUpButton", ""); }
                                    if (resp.originalError.error.RecipientOrder == 0) { localStorage.setItem("DisplaySignUpButton", "true"); localStorage.setItem("DisplayLoginButton", ""); }
                                }
                                else {
                                    localStorage.setItem("DisplayLoginButton", ""); localStorage.setItem("DisplaySignUpButton", "");
                                }
                            }

                            localStorage.setItem("EnvelopeStatus", resp.originalError.error.ErrorAction);
                            this.commonService.getReturnUrlDirection(RouteUrls.Info);
                        }
                        else {
                            if (resp.originalError.error.Message == "") {
                                localStorage.setItem("MessageType", "Error");
                                localStorage.setItem("DisplayMessage", "An Application error occurred.")
                                localStorage.setItem("IsApplicationError", "true");
                                localStorage.setItem("PageName", "InitializeSignerSign");
                                if (resp && resp.originalError && resp.originalError.error && resp.originalError.error.ResponseSigningUrlModel
                                    && resp.originalError.error.ResponseSigningUrlModel.EnvelopeID && resp.originalError.error.ResponseSigningUrlModel.EmailId) {
                                    let envid = resp.originalError.error.ResponseSigningUrlModel.EnvelopeID;
                                    let emailid = resp.originalError.error.ResponseSigningUrlModel.EmailId;

                                    localStorage.setItem("EnvelopeID", envid);
                                    localStorage.setItem("EmailId", emailid);
                                }
                            }
                            else {
                                localStorage.setItem("DisplayMessage", resp.originalError.error.Message);
                            }
                            this.commonService.getReturnUrlDirection(RouteUrls.Info);
                        }
                    }
                    this.showLoader = false;
                }
                else {
                    this.showLoader = false;
                    sessionStorage.removeItem("isFromSignMultiTemplate");
                    //localStorage.setItem("encryptedSender", "");
                    localStorage.setItem("MessageType", "Error");
                    localStorage.setItem("IsApplicationError", "true");
                    localStorage.setItem("PageName", "InitializeSignerSign");
                    localStorage.setItem("DisplayMessage", "An Application error occurred.");

                    if (resp && resp.originalError && resp.originalError.error && resp.originalError.error.ResponseSigningUrlModel
                        && resp.originalError.error.ResponseSigningUrlModel.EnvelopeID && resp.originalError.error.ResponseSigningUrlModel.EmailId) {
                        let envid = resp.originalError.error.ResponseSigningUrlModel.EnvelopeID;
                        let emailid = resp.originalError.error.ResponseSigningUrlModel.EmailId;

                        localStorage.setItem("EnvelopeID", envid);
                        localStorage.setItem("EmailId", emailid);
                    }
                    this.commonService.getReturnUrlDirection(RouteUrls.Info);
                }
                this.showLoader = false;
            });
    }

    isNullOrEmptyCheck(stringValueFlag: any) {
        let boolFlag = false;
        if (typeof stringValueFlag != 'undefined' && stringValueFlag !== '' && stringValueFlag !== null && stringValueFlag !== 'false') {
            boolFlag = true;
        }
        return boolFlag;
    }

    getLanguageTranslations() {
        if (sessionStorage.getItem('LaungaueTransalation') == null || sessionStorage.getItem('LanguageValidation') == null
            || sessionStorage.getItem('LaungaueTransalation') == undefined || sessionStorage.getItem('LanguageValidation') == undefined) {
            this.translationsService.getLanguageTranslations(null, null);
        }
    }

    // splitArray(array: any, chunkSize: any) {
    //     let arrIndexLen: any = 0;
    //     for (let i = 0; i < array.length; i += chunkSize) {
    //         var partialArray: any = array.slice(i, i + chunkSize);
    //         arrIndexLen = arrIndexLen + 1;
    //         localStorage.setItem("AllDocumentControls_" + arrIndexLen, JSON.stringify(partialArray));
    //     }
    //     localStorage.setItem("AllDocumentControlsArrayCount", String(arrIndexLen));
    // }

}

