<app-header></app-header>
<main>
    <div class="main-container">
        <div class="container">
            <div class="row">
                <div class="wrapper fadeInDown">
                    <div class="formContent">
                        <div class="front-arrow">
                            <h4>Enter Code</h4>

                            <p *ngIf="EnableMessageToMobile != 1" class="my-3 ps-3 pe-2">An email
                                has been sent to <span style="color: #337ab7;" id="emailID">{{emailAddr}}</span> with an
                                access code.
                                Please locate the email, enter it below, and click continue to proceed.</p>

                            <p *ngIf="EnableMessageToMobile == 1 && (DeliveryMode == 1 || DeliveryMode == 5 || DeliveryMode == 9)" class="my-3 ps-3 pe-2">An email
                                has been sent to <span style="color: #337ab7;" id="emailID">{{emailAddr}}</span> with an
                                access code.
                                Please locate the email, enter it below, and click continue to proceed.</p>

                            <p *ngIf="EnableMessageToMobile == 1 &&  (DeliveryMode == 3 || DeliveryMode == 7 || DeliveryMode == 10)" class="my-3 ps-3 pe-2">An SMS
                                message has been sent to your mobile
                                number <span style="color: #337ab7;" id="mobilenumber">{{Mobile}}</span>, and an email
                                message has been sent to your email address
                                <span style="color: #337ab7;" id="emailID">{{emailAddr}}</span> with an access code.
                                Please enter the access code below and click continue to proceed.
                            </p>

                            <p *ngIf="EnableMessageToMobile == 1 &&  (DeliveryMode == 2|| DeliveryMode == 4|| DeliveryMode == 8)" class="my-3 ps-3 pe-2">An SMS
                                message has been sent to your mobile number <span style="color: #337ab7;"
                                    id="mobilenumber">{{Mobile}}</span> with an access code.
                                Please locate the message, enter it below, and click continue to proceed.</p>

                            <p *ngIf="EnableMessageToMobile == 1 &&  DeliveryMode == ''"
                                class="my-3 ps-3 pe-2">An email
                                has been sent to <span style="color: #337ab7;" id="emailID">{{emailAddr}}</span> with an
                                access code.
                                Please locate the email, enter it below, and click continue to proceed.</p>

                            <div class="form-field">
                                <label class="headerSpan mb-0"> Access code</label>
                                <input type="text" class="inp-full-width cursor headerSpan" pattern="[0-9]*"
                                    inputmode="numeric" placeholder="Enter the 6-digit code" typeof="number"
                                    maxlength="6" ondrop="return false" spellcheck="false" id="VerificationCode" autocomplete="off"
                                    (input)="enableSubmitButton($event)" (keyup.enter)="getCodeAndValidateSigner()">
                                <i class="fa fa-key keyicon" aria-hidden="true"></i>
                            </div>


                        </div>
                        <div class="form-buttons">
                            <button id="btnViewandSignDocument" class="btn btn-full-width cursorpoint"
                                data-key-config="ViewandSignDocument"
                                (click)="getCodeAndValidateSigner()">Continue</button>
                        </div>

                        <p class="my-3">Not received? <a id="SendAgainVerificationCode"
                                (click)="resendVerificationCode()" class="font-w600 resend">Resend</a></p>
                    </div>
                </div>


            </div>
        </div>
    </div>
</main>
<footer style="background: #eee;" class="default-footer mt-auto pt-2 pb-2">
    <div class="container1" style="font-size: 10px;">
        <div class="fotertext" [innerHTML]="htmlFooterString">
        </div>
    </div>
</footer>

<div *ngIf="showLoader" class="loader">
</div>