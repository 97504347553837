import { Component, LOCALE_ID, ViewChild, Renderer2, ElementRef, OnInit, HostListener, Output, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { SignerLandingService } from '../../services/signer-landing.service';
import { ToastrService } from 'ngx-toastr';
import { NgbDateStruct, NgbInputDatepicker, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { CommonConstants, Defaults, InitialType, RouteUrls, SignatureType, ResourceKeys, Signer, RecipientType, DateFormatString, DateFormatGuid, ActionTypes, EnvelopeStatus, EmailAddressFormat } from '../../constants/common-constants';
import { DeclineModalComponent } from 'src/app/modal/decline-modal/decline-modal.component';
import { ChangeSignerModalComponent } from 'src/app/modal/change-signer-modal/change-signer-modal.component';
import { SignaturePadModalComponent } from 'src/app/modal/signature-pad-modal/signature-pad-modal.component';
import { CommonService } from '../../services/common.service';
import { Observable } from 'rxjs';
import { AttachmentsComponent } from 'src/app/modal/attachments/attachments.component';
import { InviteSignersComponent } from 'src/app/modal/invite-signers/invite-signers.component';
import { Inject } from '@angular/core';
import { DOCUMENT, DatePipe } from '@angular/common';
import * as moment from 'moment';
import { parseInt } from 'lodash';
var cloneDeep = require('lodash.clonedeep');
const jstz = require("jstimezonedetect");
import 'moment-timezone';
import { TranslationsService } from '../../services/translations.service';
import { InitialsPadComponent } from 'src/app/modal/initials-pad/initials-pad.component';
import { Router } from '@angular/router';
import { SignerRegistrationComponent } from '../../modal/signer-registration/signer-registration.component';
import { SignerIntermediateComponent } from '../../modal/signer-intermediate/signer-intermediate.component';
import { SignerIntermediateContinueComponent } from '../../modal/signer-intermediate-continue/signer-intermediate-continue.component';
import { ChangeDetectorRef } from '@angular/core';
import * as $ from 'jquery';
import { StyleService } from '../../services/style.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
// import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
// import { Keepalive } from '@ng-idle/keepalive';
import { SignerIntermediateCloseComponent } from 'src/app/modal/signer-intermediate-close/signer-intermediate-close.component';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-signer-landing',
    templateUrl: './signer-landing.component.html',
    styleUrls: ['./signer-landing.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignerLandingComponent implements OnInit, OnDestroy {

    @ViewChild('customeDate', { static: true }) customDate: any;
    model: any;
    //$documentImg is subject and its updating value pagination changes.
    $documentImg: Observable<string> | undefined;

    @ViewChild('termsOfService', { static: false }) private termsOfService: any;
    @ViewChild('clickToSign', { static: false }) private clickToSign: any;
    @ViewChild('fileReview', { static: false }) private fileReview: any;
    @ViewChild('pinchZoom', { static: false }) private pinchZoom: any;
    @ViewChild('dateControl', { static: false }) private dateControl: any;
    @ViewChild('dateTextDate', { static: false }) private dateTextDate: any;
    @ViewChild('dControl', { static: false }) dControll: NgbInputDatepicker | undefined;
    //documentData passing to pagination component
    @Output() documentData: any;
    commonConstants = CommonConstants;
    resourceKeys = ResourceKeys;
    //documentPageContainer is all pages container
    @ViewChild('documentPageContainer', { static: false })
    documentPageContainer!: ElementRef;
    // model: Date = new Date();
    imageSize: any = 1;
    zoomingVal: any = 0.5;
    percentageValue: string = '';
    showLoader: boolean = true;
    isFavorite: boolean = true;
    documentControlsResponse: any;
    documentCheckListsResponse: any;
    hideFilterSection: boolean = true;
    hidePagesViewSection: boolean = false;
    showControlSignatureTooltip: boolean = false;
    showControlCompanyTooltip: boolean = false;
    showControlNameTooltip: boolean = false;
    showControlInitialsTooltip: boolean = false;
    showControleDateTimeStampTooltip: boolean = false;
    showControlEmailTooltip: boolean = false;
    showControlDateTooltip: boolean = false;
    showControlTextTooltip: boolean = false;
    showControlTitleTooltip: boolean = false;
    toDate: any = null;
    pageViewArr: any;
    documentWidth: any;
    doc1: any;
    doc2: any;
    id: any;
    popupWin: any;
    searchTerm!: string;
    searchTermDisplay!: string;
    currentIndex: any = 0;
    show: boolean = false;
    buttonName: any = 'Show';
    hideFilter: boolean = true;
    fieldsRemainingFlag: boolean = false;
    requiredFlag: boolean = false;
    filterControlInfo: string = '';
    showDefaultSignatureContol: any;
    defaultSignatureValue: any;
    defaultSignatureObj: any;
    cloneDocumentCheckListsResponse: any = [];
    validateFlag: boolean = false;
    EnvelopeID: any;
    element: any;
    baseUrl = environment.apiBaseUrl;
    ipAddress: string = '';
    requiredFields: string = 'Please enter required fields.';
    invalidEmail: string = "Invalid email address.";
    invalidDate: string = 'Invalid date.';
    invlaidSSN: string = 'Invalid ssn.';
    invlaidZIP: string = 'Invalid Zip.';
    fileReviewInfo: any = [];
    dvDGIntermediateDisplayStyle: string = 'none';
    dgIntermediateReturnURL: any;
    signAnotherDocumentUrl: any;
    backToMultiViewUrl: any;
    totalRemainigFilledControls: any = 0;
    remainingFieldsTotalCount: any = 0;
    remainingFieldsCount: any = 0;
    attachmentList: any;
    attachmentCount: any = 0;
    lastTabIndex: any;
    attachmentListCount: any = 0;
    pagePreviewTotalPages: any = 0;
    pinchZoomDisabled: any = true;
    webOrMobile: any = 'web';
    mobileControlArr: any = [];
    mobileControlArrReverse: any = [];
    tabIndex: any = 0;
    clicktoSignAgree: boolean = false;
    clickToSignNote: string = '';
    clickToSignAgrrement: string = '';
    checkboxCheckedImageSrc: string = "../../../assets/images/checked.jpg";
    checkboxNotCheckedImageSrc: string = "../../../assets/images/unchecked.jpg";
    radioCheckedImageSrc: string = "../../../assets/images/radio.jpg";
    radioNotCheckedImageSrc: string = "../../../assets/images/unradio.jpg";
    checkedCheckboxImagesrc: string = "";
    checkedRadioImagesrc: string = "";
    IsStaticTemplate: boolean = false;
    isSignerpopupCount: any = "0";
    responseSigningUrlModel: any;
    isFileReviewed: string = "";
    myArray: any = [];
    allSignControls: any = [];
    IsEnableClickToSign: boolean = false;
    languageValidation: any;
    defaultSignatureFlag: boolean = false;
    envelopeSignDocumentSubmitRequestInfo: any;
    IsSignerAttachFileReqNew: any;
    IsPrefill: any;
    DisableDownloadOptionOnSignersPage: any;
    IsAdditionAttamRequest: boolean = false;
    ShowAttachmentNavMenu: boolean = false;
    ShowFinishLaterNavMenu: boolean = false;
    ShowDeclineNavMenu: boolean = false;
    ShowAttachmentCountDiv: boolean = false;
    ShowFilesReviewCountDiv: boolean = false;
    isBrowserRefreshed: boolean = false;
    validationPopupStyle: string = 'none';
    clickToSignPopupStyle: string = 'none';
    EnvelopeAdditionalUploadInfoList: any;
    mobilePrevBtn: string = 'false';
    mobileNextBtn: string = 'true';
    showNoFieldsRemainingMsg: boolean = false;
    showMobilePopup: string = 'none';
    spnMaximizeControlTitle: string = '';
    defaultControlCheckListData: any = [];
    showDateControlPopup: string = 'false';
    showDateControlPopupDisplay: string = 'none';
    showNotDateControlPopupDisplay: string = 'none';
    showTextDateControlPopupDisplay: string = 'none';
    mouseClickedPositions: any;
    isDateSelected: string = 'false';
    signClickCount: any = 0;
    maxDate = { year: new Date().getUTCFullYear() + 100, month: 12, day: 31 };
    minDate = { year: new Date().getUTCFullYear() - 100, month: 12, day: 31 };
    byDefaultShowCtrlTooltip: boolean = false;
    isShowFooter: any = 'false';
    showControlsFlag: any = 'none';
    loadedImagesTemp: string[] = [];
    loadedImages: { [key: string]: string } = {};
    globalCtrlObj: any;
    showFinishModalDailog: any = 'none';
    popuptempArr: any = [];
    textAreaQueued: any = false;
    mobileDoneBtn: string = 'false';
    sendPasswordPopupStyle: string = 'none';
    EnableMessageToMobile: any;
    DeliveryMode: any;
    emailAddr: any;
    Mobile: any;
    verificationCode: any;
    userPasscodeOnSubmit: any;
    sendAccessCodeTo: number = 0;
    isDocumentSubmitted: any = "false";

    public getPinchZoomRef() {
        return this.pinchZoom;
    }
    private inputSignChangeSubject = new Subject<string>();
    private signDestroy$ = new Subject<void>();
    private inputInitChangeSubject = new Subject<string>();
    private initDestroy$ = new Subject<void>();
    scrollingTimeout: any;

    idleState = 'Not started.';
    timedOut = false;
    lastPing?: Date = undefined;
    showSessionExpireModalDailog: any = 'none';
    idleTimer: any;
    idleMaxTimerCount: any = environment.sessionTimeOut;
    idleCounter: any = environment.sessionTimeOut;
    sessionTimeoutVal: any = (Math.floor(this.idleMaxTimerCount / 60)).toString() + ' M';
    popupShowCounter: any = environment.sessionInActivity;
    showDefaultSessionTimeOut: boolean = false;
    AllowSignersDownloadFinalContract: boolean = false;
    sessionTimeOutMinutes: any = (Math.floor(this.idleMaxTimerCount / 60)).toString();
    idleTimerMinsValue: any = (Math.floor(this.idleMaxTimerCount / 60)).toString();
    idleTimerSecondsValue: any = '00';
    timer = 0;
    timerWorker: any = new Worker('../../../assets/workers/timer-worker.js');

    showAndHideDecline: boolean = false;
    showAndHideFinishLater: boolean = false;
    showAndHideChangeSigner: boolean = false;

    controlPositionDetailsArr: any = [];
    expandableCtrlNextTopValue: any;

    constructor(
        @Inject(DOCUMENT) private document: any,
        @Inject(LOCALE_ID) public locale: string,
        private renderer2: Renderer2,
        private elementRef: ElementRef,
        private modalService: NgbModal,
        private signerLandingService: SignerLandingService,
        private toastr: ToastrService,
        private commonService: CommonService,
        public datePipe: DatePipe,
        private http: HttpClient,
        private router: Router,
        private translationsService: TranslationsService,
        private cdRef: ChangeDetectorRef,
        private styleService: StyleService,
        // private idle: Idle,
        // private keepalive: Keepalive,
        private titleService: Title
    ) {
        this.commonService.userSource$.subscribe(data => {
            this.$documentImg = data;
        });
        this.addTabKeyEventListener();

        //check idle time
        this.sessionTimeoutVal = (Math.floor(this.idleMaxTimerCount / 60)).toString() + " M";
        this.handleIdleTime();

        // Reset the idle timer when the user interacts.
        document.addEventListener('mousemove', () => {
            if (this.showSessionExpireModalDailog === 'none') {
                if (this.showDefaultSessionTimeOut === true) {
                    //reset Timer
                    this.onContinue();
                }
            }
        });
    }

    @HostListener('mouseover', ['$event'])
    onMouseOver(event: MouseEvent) {
        this.resetSessionTimer();
    }

    @HostListener('click', ['$event.target']) onClick(e: any) {
        this.resetSessionTimer();
        this.isCheckDatePopupOpen();

        if (e.textContent != "" || e.title != "") {
            if (e.textContent.trim() == "zoom_in" || e.textContent.trim() == "zoom_out" || e.textContent.trim() == "zoomin" || e.textContent.trim() == "zoomout") {
                let btnmore_horiz: any = document.getElementById('more');
                btnmore_horiz.classList.remove("show");
                let btnattachement: any = document.getElementById('attachement');
                if (btnattachement) {
                    btnattachement.classList.remove("show");
                }
            }
            else if (e.textContent.trim() == "more_horiz" || e.title.trim() == "more_horiz") {
                let btnZoom: any = document.getElementById('zoom');
                btnZoom.classList.remove("show");
                let btnattachement: any = document.getElementById('attachement');
                if (btnattachement) {
                    btnattachement.classList.remove("show");
                }
            }
            else if (e.textContent.trim() == "View Attachments" || e.title.trim() == "View Attachments" || e.title.trim() == "Attachment") {
                let btnZoom: any = document.getElementById('zoom');
                btnZoom.classList.remove("show");
                let btnmore_horiz: any = document.getElementById('more');
                btnmore_horiz.classList.remove("show");
            }
            else if (e.textContent.trim() == "download" || e.title.trim() == "Click here to download files to review" || e.title.trim() == "Files for Review") {
                this.hideOpenedSection();
            }
            else if (e.textContent.trim() == "onPageSelection" || e.textContent.trim() == "navigate_next" || e.textContent.trim() == "chevron_left"
                || e.textContent.trim() == "first_page" || e.textContent.trim() == "last_page" || e.textContent.trim() == "format_list_bulleted"
                || e.textContent.trim() == "Preview" || e.title.trim() == "Filter") {
                this.hideOpenedSection();
            }
            else if (e.textContent.trim() == "Add/Edit Signature" || e.textContent.trim() == "Add Attachments" || e.textContent.trim() == "Attachments"
                || e.textContent.trim() == "Decline" || e.textContent.trim() == "Change Signer" ||
                e.textContent.trim() == "Signup-ROne" || e.textContent.trim() == "Finish Later") {
                let btnmore_horiz: any = document.getElementById('more');
                btnmore_horiz.classList.remove("show");
            }
            else if (e.textContent.trim() == 'filter_list') {
                if ((/android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
                    const offcanvas = document.getElementById('filter')!;
                    const computedStyles = window.getComputedStyle(offcanvas);

                    // Check the visibility of the offcanvas element by inspecting its 'visibility' property
                    const visibilityValue = computedStyles.getPropertyValue('visibility');

                    if (visibilityValue === 'visible') {
                        let bodyTag: any = document.getElementsByTagName('body');
                        if (bodyTag && bodyTag[0])
                            bodyTag[0].style.position = 'fixed';
                    }
                }
            }
        }
        else {
            this.hideOpenedSection();
            if ((/android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
                this.removeBodyTagPositionFixed();

                let otherActionOffcanvas: any = document.getElementById('demo')!;
                let eleRef: any;
                if (otherActionOffcanvas) {
                    eleRef = otherActionOffcanvas.classList.contains('showing');
                }
                if (eleRef) {
                    let bodyTag: any = document.getElementsByTagName('body');
                    if (bodyTag && bodyTag[0])
                        bodyTag[0].style.position = 'fixed';
                } else {
                    this.removeBodyTagPositionFixed();
                }
            }
        }
    }

    @HostListener('mouseenter', ['$event']) onEnter(e: MouseEvent) {
        this.resetSessionTimer();
    }

    @HostListener('mouseleave', ['$event']) onLeave(e: MouseEvent) {
        this.resetSessionTimer();
    }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll() {
        this.resetSessionTimer();
    }

    @HostListener('keydown', ['$event']) onKeyDown() {
        this.resetSessionTimer();
    }

    @HostListener('touchstart', ['$event'])
    @HostListener('mousedown', ['$event'])
    onStart(event: any) {
        if (event.touches) {
            this.resetSessionTimer();
        }
    }

    ngAfterViewInit() {
    }

    ngOnInit(): void {
        this.onPreviewPagesView();
        this.titleService.setTitle("RSign | Sign Document");
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if ((/android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
            this.pinchZoomDisabled = false;
            this.webOrMobile = 'mobile';
            // this.hidePagesViewSection = false;
        } else {
            this.pinchZoomDisabled = true;
        }
        this.showLoader = true;
        let zoomOutbtn: any = document.getElementById('zoomOutbtn');
        if (zoomOutbtn) {
            zoomOutbtn.setAttribute('disabled', 'disabled');
        }

        this.commonService.documentData.subscribe(data => {
            this.documentData = data;
        });
        this.searchTerm = '';
        this.searchTermDisplay = '';
        this.percentageValue = '100%';
        this.isFavorite = true;

        this.getInitializeSignDocument();

        this.inputSignChangeSubject
            .pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.signDestroy$))
            .subscribe(value => {
                this.UpdateSigAndInitialProfileText(1);
            });

        this.inputInitChangeSubject
            .pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.initDestroy$))
            .subscribe(value => {
                this.UpdateInitialsimageFromSignerProfileText();
            });
    }

    addTabKeyEventListener() {
        window.addEventListener('keyup', this.handleTooltip, { passive: true });
    }

    handleTooltip = (event: any) => {
        //isModalOpen to checking any popup modal is opened or not
        let isModalOpen: any = this.document.body.className.indexOf('modal-open');
        if (isModalOpen === -1) {
            if (event.key == "Tab" && (event.keyCode == 9 || event.which == 9)) {
                if (event.target.offsetParent && event.target.offsetParent.tagName !== 'NGB-DATEPICKER') {
                    if (this.dControll) {
                        this.dControll!.close();
                    }
                    this.hideAllControlTooltips();
                    this.getControlNextNav('fromtabevent');
                }
                else if (event.target.offsetParent && event.target.offsetParent.tagName == 'NGB-DATEPICKER') {

                }
            }
        }
    }

    addTextAreEventListener() {
        let textarea: any = document.getElementsByClassName('mycustomTextAreaClass');
        for (var i = 0; i < textarea.length; i++) {
            textarea[i].addEventListener('input', this.customTextArea, { passive: true });
        }
    }

    customTextArea = (event: any) => {
        if (!this.textAreaQueued) {
            this.textAreaQueued = true;
            requestIdleCallback(() => {
                let start = Date.now()
                while (Date.now() - start < 10) { };
                this.textAreaQueued = false;
            })
        }
    }

    hideOpenedSection() {
        let btnZoom: any = document.getElementById('zoom');
        btnZoom.classList.remove("show");
        let btnmore_horiz: any = document.getElementById('more');
        btnmore_horiz.classList.remove("show");
        let btnattachement: any = document.getElementById('attachement');
        if (btnattachement) {
            btnattachement.classList.remove("show");
        }
    }

    // getIP() {
    //     this.http.get<any>('https://ipapi.co/json/').subscribe(data => {
    //         this.ipAddress = data.ip;
    //        // localStorage.setItem("IPAddess", this.ipAddress);
    //     })
    // }

    getInitializeSignDocument() {
        let documentControlsResp: any = localStorage.getItem("documentControlsResponse");
        if (documentControlsResp == null || documentControlsResp == undefined) {
            let actualSigningUrl: any = localStorage.getItem("actualSigningUrl");
            if (actualSigningUrl == null || actualSigningUrl == undefined) {
                this.showLoader = false;
               // localStorage.setItem("encryptedSender", '');
                localStorage.setItem("DisplayMessage", CommonConstants.InvalidUrl);
                localStorage.setItem("MessageType", "Warning");
                this.commonService.getReturnUrlDirection(RouteUrls.Info);
            }
            else {
                window.location.href = actualSigningUrl;
            }
        }
        else {
            let respSigningUrlModel: any = localStorage.getItem("responseSigningUrlModel");
            this.responseSigningUrlModel = JSON.parse(respSigningUrlModel);
            this.documentControlsResponse = JSON.parse(documentControlsResp);

            let envelopeImageControlData: any = localStorage.getItem("EnvelopeImageControlData");
            let checkListData: any = localStorage.getItem("CheckListData");
            let userdata: any = localStorage.getItem("Userdata");
            let envelopeInfo: any = localStorage.getItem("EnvelopeInfo");
            this.documentControlsResponse.EnvelopeImageControlData = JSON.parse(envelopeImageControlData);
            this.documentControlsResponse.CheckListData = JSON.parse(checkListData);
            this.documentControlsResponse.userdata = JSON.parse(userdata);
            this.documentControlsResponse.EnvelopeInfo = JSON.parse(envelopeInfo);

            if (this.documentControlsResponse && this.documentControlsResponse.EnvelopeImageControlData) {
                this.isFileReviewed = this.documentControlsResponse.EnvelopeInfo.IsReviewed;
                this.EnvelopeID = this.documentControlsResponse.EnvelopeInfo.GlobalEnvelopeID;
                this.commonService.setDocumentData(this.documentControlsResponse.EnvelopeImageControlData);
                this.documentCheckListsResponse = this.documentControlsResponse.CheckListData;
                this.showDefaultSignatureContol = this.documentControlsResponse.ShowDefaultSignatureContol;
                this.defaultSignatureValue = this.translationsService.getLanguageValidationVal('DefaultSignature'); //  this.documentControlsResponse.Language.filter((c: any) => c.KeyName === 'DefaultSignature')[0].KeyValue;
                this.defaultSignatureObj = this.documentControlsResponse.EnvelopeInfo.ControlsData.filter((c: any) => c.ControlName === 'FooterSignature');
                this.IsEnableClickToSign = this.documentControlsResponse.EnableClickToSign;
                this.IsStaticTemplate = this.isStaticTemplate(this.documentControlsResponse);
                this.IsSignerAttachFileReqNew = this.documentControlsResponse.EnvelopeInfo.IsSignerAttachFileReqNew;
                this.IsPrefill = this.documentControlsResponse.Prefill;
                this.DisableDownloadOptionOnSignersPage = this.documentControlsResponse.DisableDownloadOptionOnSignersPage;
                this.EnableMessageToMobile = this.documentControlsResponse.EnableMessageToMobile == true ? 1 : 0;
                this.DeliveryMode = this.documentControlsResponse.DeliveryMode;
                localStorage.setItem("StaticEnvelopId", this.documentControlsResponse.EnvelopeInfo.GlobalEnvelopeID);
                localStorage.setItem("eDisplayCode", this.documentControlsResponse.EnvelopeInfo.EDisplayCode);
                localStorage.setItem('IsAdditionalAttmReq', this.documentControlsResponse.EnvelopeInfo.IsAdditionalAttmReq);
                localStorage.setItem("IsSMSAccessCode", this.documentControlsResponse.IsSMSAccessCode);
                localStorage.setItem("recipientTypeId", this.documentControlsResponse.EnvelopeInfo.RecipientTypeId);

                this.AllowSignersDownloadFinalContract = this.documentControlsResponse.IsAllowSignerstoDownloadFinalContract;
                localStorage.setItem("AllowSignersDownloadFinalContract", this.AllowSignersDownloadFinalContract == true ? "true" : "false");
                //localStorage.setItem("AttachSignedPdfID", this.documentControlsResponse.AttachSignedPdfID);

                let envelopeAdditionalUploadInfoList: any;
                envelopeAdditionalUploadInfoList = this.documentControlsResponse.EnvelopeAdditionalUploadInfoList;
                if (this.documentControlsResponse.EnvelopeInfo.IsAdditionalAttmReq || (envelopeAdditionalUploadInfoList != null && envelopeAdditionalUploadInfoList.length > 0)) {
                    this.IsAdditionAttamRequest = true;
                }
                if (this.IsSignerAttachFileReqNew > 0 && this.IsPrefill != "prefill" && this.IsAdditionAttamRequest) {
                    this.ShowAttachmentNavMenu = true;
                }
                if (this.documentControlsResponse.EnvelopeInfo.IsSingleSigning == false) {
                    this.ShowFinishLaterNavMenu = true;
                    localStorage.setItem("ShowFinishLaterNavMenu", "true");
                }
                else {
                    localStorage.setItem("ShowFinishLaterNavMenu", "false");
                }
                if (Boolean(this.documentControlsResponse.EnvelopeInfo.IsTemplateShared) == false) {
                    this.ShowDeclineNavMenu = true;
                }
                if (this.IsSignerAttachFileReqNew > 0 && this.IsPrefill != "prefill" && (this.IsAdditionAttamRequest == true || (Boolean(this.documentControlsResponse.EnvelopeInfo.IsTemplateShared) == true))) {
                    this.ShowAttachmentCountDiv = true;
                }
                if (this.documentControlsResponse.FileReviewCount > 0) {
                    this.ShowFilesReviewCountDiv = true;
                }

                setTimeout(() => {
                    if (this.webOrMobile === 'mobile') {
                        this.hidePagesViewSection = false;
                    }
                    this.getPreloadControls();
                    this.isTermsModelPopupExists();
                    this.getAttachmentList(this.documentControlsResponse);
                    // this.getMobileControlList();
                    //this.getLanguageTranslations();
                    this.updateDefaultValueOnDateControl();
                    // this.defaultControlCheckListData = cloneDeep(this.documentControlsResponse.CheckListData);
                    this.updateDefaultValueOnEmailControl();
                    //localStorage.setItem('selectedNextTabindex', '');
                    this.getTotalRemaingFieldsControls('initial');
                    // this.onPreviewPagesView();
                    this.onFilterApply();

                    let footerSigSrc = this.document.getElementById("footerSigSrc");
                    let launguageSignImageName: string = this.translationsService.getLanguageValidationVal('lang_yourNameImg'); //this.documentControlsResponse.Language.filter((c: any) => c.KeyName == "lang_yourNameImg");

                    let defaultImage = Defaults.DefaultImages + launguageSignImageName + ".png";
                    if (footerSigSrc != null && footerSigSrc != undefined) {
                        footerSigSrc.setAttribute("src", defaultImage);
                    }
                    //this.defaultControlShowTooltip();
                    if (this.documentControlsResponse.EnvelopeInfo.IsDisclaimerEnabled === false && localStorage.getItem('prefill') !== 'prefill' &&
                        this.byDefaultShowCtrlTooltip === false && this.IsEnableClickToSign === false) {
                        setTimeout(() => {
                            this.defaultControlShowTooltip();
                        }, 500);
                    }

                    this.showAndHideDecline = this.documentControlsResponse.DisableDeclineOption;
                    this.showAndHideFinishLater = this.documentControlsResponse.DisableFinishLaterOption;
                    this.showAndHideChangeSigner = this.documentControlsResponse.DisableChangeSigner;

                }, 1000);
            }
            else {
                let actualSigningUrl: any = localStorage.getItem("actualSigningUrl");
                if (actualSigningUrl == null || actualSigningUrl == undefined) {
                    this.showLoader = false;
                    //localStorage.setItem("encryptedSender", '');
                    localStorage.setItem("DisplayMessage", CommonConstants.InvalidUrl);
                    localStorage.setItem("MessageType", "Warning");
                    this.commonService.getReturnUrlDirection(RouteUrls.Info);
                }
                else {
                    window.location.href = actualSigningUrl;
                }
            }
            localStorage.setItem("signatureInputText", "");
            localStorage.setItem("signatureText", "");
            localStorage.setItem("initialInputText", "");
            localStorage.setItem("initialText", "");

            let beforeRefreshValFrmLocalStorage: any = localStorage.getItem("beforeRefreshVal");
            if (beforeRefreshValFrmLocalStorage == "1") {
                this.isBrowserRefreshed = true;
            }
            else {
                this.isBrowserRefreshed = false;
                localStorage.setItem("beforeRefreshVal", "1");
            }
            this.showLoader = false;
        }

        this.imageLoaded(this.documentControlsResponse.EnvelopeImageControlData);
        this.cdRef.detectChanges();
    }

    getAttachmentList(obj: any) {
        let envelopeID = localStorage.getItem("EnvelopeID");
        let recipientID = localStorage.getItem("RecipientID");
        let additionalRecipients: any = "";
        if (additionalRecipients == "") {
            additionalRecipients = "null"
        }
        let fileAttachmentInfoUrl: any = CommonConstants.GetAdditonalfileAttachmentInfo + '/' + envelopeID + '/' + recipientID + '/' + additionalRecipients;
        this.signerLandingService.getAdditonalfileAttachmentInfo(fileAttachmentInfoUrl).subscribe(
            (resp: any) => {
                if (resp && resp.EnvelopeAdditionalUploadInfoList && resp.EnvelopeAdditionalUploadInfoList.length > 0) {
                    let respAttachFiles: any = resp.EnvelopeAdditionalUploadInfoList;
                    this.EnvelopeAdditionalUploadInfoList = resp.EnvelopeAdditionalUploadInfoList;
                    this.attachmentList = [];
                    Array.from(respAttachFiles).forEach((item: any) => {
                        if (item.FileName != "" && item.FileName !== null) {
                            this.attachmentList.push(item.FileName);
                        }
                    });
                    this.attachmentListCount = this.attachmentList.length;
                }
                else {
                    this.attachmentList = [];
                    this.attachmentListCount = 0;
                }
                this.cdRef.detectChanges();
            })
    }

    onAttachmentDelete(file: any) {
        // console.log('onAttachmentDelete', file);
        let reqParams: any = {
            EnvelopeID: localStorage.getItem('EnvelopeID'),
            RecipientID: localStorage.getItem('RecipientID'),
            FileName: file
        }
        let deleteAndUpdateSignerDocUrl = CommonConstants.DeleteSignerDocFromDirectory;
        this.showLoader = true;
        this.signerLandingService.getDeleteSignerDocFromDirectory(deleteAndUpdateSignerDocUrl, reqParams).subscribe(
            (resp: any) => {
                this.showLoader = false;
                this.attachmentList = this.attachmentList.filter((item: any) => item !== file)
                this.attachmentListCount = this.attachmentList.length;
                if (resp && resp.StatusCode === 200 && resp.StatusMessage.toLowerCase() === 'ok') {
                    var files = resp.data;
                    // console.log('res', files);
                    this.toastr.success('Document deleted successfully.', 'Success', { timeOut: 2000 });
                }
                this.cdRef.detectChanges();
            })
    }

    getLanguageTranslations() {
        if (sessionStorage.getItem('LaungaueTransalation') == null || sessionStorage.getItem('LanguageValidation') == null
            || sessionStorage.getItem('LaungaueTransalation') == undefined || sessionStorage.getItem('LanguageValidation') == undefined) {
            this.translationsService.getLanguageTranslations(null, null);
        }
        else {
            let sessionTranslation: any = sessionStorage.getItem("LanguageValidation");
            this.languageValidation = JSON.parse(sessionTranslation);
        }
    }

    onClickSignClose() {
        this.modalService.dismissAll();
        this.clickToSignPopupStyle = 'none';
        this.removeModelPopupClassForBodyTag();
        this.getTotalRemaingFieldsControls('');
        if (this.byDefaultShowCtrlTooltip === false) { setTimeout(() => { this.defaultControlShowTooltip(); }, 500); this.byDefaultShowCtrlTooltip = true; }
    }

    openClickToSignModal(typeSign: any) {
        if (this.checkDocumentControlsResponselocal()) {
            this.commonService.checkIsUrlExistsInBrowserMemory();
            return;
        }
        else {
            if (typeSign == "signature" || typeSign == "upload") {
                const modalRef = this.modalService.open(SignaturePadModalComponent, { windowClass: 'signaturePadModalComponent-class', keyboard: false, backdrop: 'static' });
                modalRef.componentInstance.signControlId = "";
                modalRef.componentInstance.dataFontColor = "";
                modalRef.componentInstance.type = typeSign;
                let txtsignerProfileName: any = document.getElementById("txtsignerProfileName");
                modalRef.componentInstance.ClickToSignsignatureText = txtsignerProfileName.value;
            }
            if (typeSign == "initials") {
                const modalRef = this.modalService.open(InitialsPadComponent, { windowClass: 'initialsPadModalComponent-class', keyboard: false, backdrop: 'static' });
                modalRef.componentInstance.signControlId = "";
                modalRef.componentInstance.dataFontColor = "";
                modalRef.componentInstance.type = typeSign;
                let txtsignerProfileInitials: any = document.getElementById("txtsignerProfileInitials");
                modalRef.componentInstance.ClickToSignInitialText = txtsignerProfileInitials.value;
            }
        }
    }

    openFilesReview() {
        this.modalService.open(this.fileReview, { windowClass: 'fileReviewModalComponent-class', keyboard: false, backdrop: 'static' });
        this.fileReviewInfo = this.documentControlsResponse.FileReviewInfo
    }

    onConfirmFilesReviewed() {
        this.modalService.dismissAll(this.fileReview);
        this.showLoader = true;
        let envelopeSignDocumentSubmitInfo: any = this.prepareSubmitEnvelopeObject();
        this.commonSubmitClickevent(envelopeSignDocumentSubmitInfo);
    }

    // onFileReviewDownload pdf
    onFileReviewDownload() {
        this.showLoader = true;
        this.isFileReviewed = '1';
        let envelopeId = localStorage.getItem('EnvelopeID');
        let recipientId = localStorage.getItem('RecipientID');
        let isStaticTemplate: string = '';
        let isStatic: any = localStorage.getItem('isStatic');
        if (isStatic === true || isStatic === "true") {
            isStaticTemplate = 'Static';
        }

        let url: any = this.commonConstants.GetDownloadFileReview + '/' + envelopeId + '/' + recipientId + '/' + isStaticTemplate;
        this.signerLandingService.getDownloadFileReview(url).subscribe(
            (resp: any) => {
                this.showLoader = false;
                // this.modalService.dismissAll(this.fileReview);
                let btnConfirm: any = document.getElementById('btnConfirm');
                let btnFileReviewDownload: any = document.getElementById('btnFileReviewDownload');
                if (this.isFileReviewed == "" || this.isFileReviewed == null) {
                    btnConfirm?.setAttribute('disabled', 'disabled');
                    btnFileReviewDownload?.setAttribute('background-color', '');
                    btnFileReviewDownload?.removeAttribute('disabled');
                } else {
                    this.isFileReviewed = '1';
                    btnConfirm?.removeAttribute('disabled');
                }

                //console.log('res', resp)
                let base64FileData: any = resp.Base64FileData;
                if (base64FileData) {
                    this.toastr.success('Downloaded successfully.', 'Success', { timeOut: 2000 });
                    var blob = this.commonService.b64toBlob(base64FileData, 'application/pdf');
                    let a = document.createElement('a');
                    document.body.appendChild(a);
                    let bloburl: any = window.URL.createObjectURL(blob);
                    a.href = bloburl;
                    a.download = String(resp.FileName);
                    a.click();
                    window.URL.revokeObjectURL(bloburl);
                    a.remove();
                }
                this.cdRef.detectChanges();
            });
    }

    getPreloadControls() {
        //shows the dependencies controls if pre seelcted
        let drpControlArray: any = [];
        let txtControlArray: any = [];
        if (this.documentControlsResponse.EnvelopeImageControlData && this.documentControlsResponse.EnvelopeImageControlData.length) {
            this.documentControlsResponse.EnvelopeImageControlData.forEach((ele: any) => {
                ele.ControlsData.forEach((eleChild: any) => {
                    // let isChecked =  $("#control_"+eleChild.ControlHtmlID).prop('checked');

                    let isChecked: string = "true";
                    let curctrl: any = document.getElementById("control_" + eleChild.ControlHtmlID);
                    if (curctrl && curctrl != null && (eleChild.ControlName.toLowerCase() == "radio" || eleChild.ControlName.toLowerCase() == "checkbox")) {
                        isChecked = curctrl.checked ? "true" : "false";
                    }

                    if (eleChild.ControlValue === 'true' && eleChild.IsReadOnly != true && isChecked == "true") {
                        eleChild.ConditionDetails.DependentFields.forEach((eleChildDep: any) => {
                            ele.ControlsData.forEach((eleChildCtrl: any) => {
                                if (eleChildDep.ControlID === eleChildCtrl.Id) {
                                    let ctrlId: any = document.getElementById(eleChildCtrl.ControlHtmlID);
                                    if (ctrlId) {
                                        document.getElementById(eleChildCtrl.ControlHtmlID)!.style.display = 'block';
                                        if (document.getElementById('anchor_' + eleChildCtrl.ControlHtmlID)) {
                                            document.getElementById('anchor_' + eleChildCtrl.ControlHtmlID)!.style.display = 'block';
                                            document.getElementById('panel_' + eleChildCtrl.ControlHtmlID)!.style.display = 'block';
                                            document.getElementById('panel_' + eleChildCtrl.ControlHtmlID)!.removeAttribute('style');
                                            document.getElementById('panel_' + eleChildCtrl.ControlHtmlID)!.setAttribute('style', '');
                                            document.getElementById('panel_' + eleChildCtrl.ControlHtmlID)!.classList.add('visible');
                                        }
                                    }
                                }
                            })
                        })
                    }
                    else if (eleChild.ControlValue === 'false') {
                        eleChild.ConditionDetails.DependentFields.forEach((eleChildDep: any) => {
                            ele.ControlsData.forEach((eleChildCtrl: any) => {
                                if (eleChildDep.ControlID === eleChildCtrl.Id) {
                                    let ctrlId: any = document.getElementById(eleChildCtrl.ControlHtmlID);
                                    if (ctrlId) {
                                        document.getElementById(eleChildCtrl.ControlHtmlID)!.style.display = 'none';
                                        if (document.getElementById('anchor_' + eleChildCtrl.ControlHtmlID)) {
                                            document.getElementById('anchor_' + eleChildCtrl.ControlHtmlID)!.style.display = 'none';
                                            document.getElementById('panel_' + eleChildCtrl.ControlHtmlID)!.style.display = 'none';
                                        }
                                    }
                                }
                            })
                        })
                    }

                    if (eleChild.ControlName.toLowerCase() === 'dropdown') {
                        drpControlArray.push(eleChild);
                    }

                    if (eleChild.ControlName.toLowerCase() === 'text') {
                        txtControlArray.push(eleChild);
                    }
                    this.calculateTopLeftForAllControls(eleChild);
                });
            });
            this.getAllControlPositionDetail();
        }

        if (drpControlArray && drpControlArray.length > 0) {
            for (let index = 0; index < drpControlArray.length; index++) {
                const ctrlObj = drpControlArray[index];
                let cudrpctrl: any = document.getElementById("control_" + ctrlObj.ControlHtmlID);
                let selectedDropdownValue: any = "";
                if (cudrpctrl) {
                    selectedDropdownValue = cudrpctrl.getAttribute("data-selected-value");
                }
                if (this.documentControlsResponse.EnvelopeImageControlData && this.documentControlsResponse.EnvelopeImageControlData.length) {
                    this.documentControlsResponse.EnvelopeImageControlData.forEach((ele: any) => {
                        if (ele.ControlsData && ele.ControlsData.length) {
                            if (ctrlObj.ControlName.toLowerCase() === 'dropdown') {
                                ele.ControlsData.forEach((childEle: any) => {

                                    if (childEle.ConditionDetails.DependentFields && childEle.ConditionDetails.DependentFields.length) {
                                        childEle.ConditionDetails.DependentFields.forEach((childEleDep: any) => {
                                            if (selectedDropdownValue === childEleDep.ConditionID) {
                                                ele.ControlsData.forEach((childEleDepCtrl: any) => {
                                                    if (childEleDep.ControlID === childEleDepCtrl.Id) {
                                                        if (document.getElementById(childEleDepCtrl.ControlHtmlID)) {
                                                            document.getElementById(childEleDepCtrl.ControlHtmlID)!.style.display = 'block';
                                                        }
                                                        if (document.getElementById('anchor_' + childEleDepCtrl.ControlHtmlID)) {
                                                            document.getElementById('anchor_' + childEleDepCtrl.ControlHtmlID)!.style.display = 'block';
                                                            document.getElementById('panel_' + childEleDepCtrl.ControlHtmlID)!.style.display = 'block';
                                                            document.getElementById('panel_' + childEleDepCtrl.ControlHtmlID)!.removeAttribute('style');
                                                            document.getElementById('panel_' + childEleDepCtrl.ControlHtmlID)!.setAttribute('style', '');
                                                            document.getElementById('panel_' + childEleDepCtrl.ControlHtmlID)!.classList.add('visible');
                                                        }
                                                    }
                                                })
                                            } else {
                                                childEle.ControlOptions.forEach((ctrlOptions: any) => {
                                                    if (selectedDropdownValue !== ctrlOptions.ID) {
                                                        ele.ControlsData.forEach((childEleDepCtrl: any) => {
                                                            if (childEleDep.ControlID === childEleDepCtrl.Id && childEleDepCtrl.ConditionDetails.ControllingFieldID == ctrlObj.Id) {
                                                                if (document.getElementById(childEleDepCtrl.ControlHtmlID)) {
                                                                    document.getElementById(childEleDepCtrl.ControlHtmlID)!.style.display = 'none';
                                                                }
                                                                if (document.getElementById('anchor_' + childEleDepCtrl.ControlHtmlID)) {
                                                                    document.getElementById('anchor_' + childEleDepCtrl.ControlHtmlID)!.style.display = 'none';
                                                                    document.getElementById('panel_' + childEleDepCtrl.ControlHtmlID)!.style.display = 'none';
                                                                }
                                                            }
                                                        })
                                                    }
                                                })

                                            }
                                        })
                                    }

                                });
                            }
                        }
                    });
                }
            }
        }

        if (txtControlArray && txtControlArray.length > 0) {
            for (let index = 0; index < txtControlArray.length; index++) {
                const ctrlObj = txtControlArray[index];
                let textCtrlVal: any = ctrlObj.ControlValue;
                if (ctrlObj.ControlValue == '') {
                    let curCtrl: any = (<HTMLInputElement>document.getElementById("control_" + ctrlObj.ControlHtmlID));
                    if (curCtrl) {
                        let value = (<HTMLInputElement>document.getElementById("control_" + ctrlObj.ControlHtmlID)).value;
                        if (value !== '' && value !== ' ' && value !== null) {
                            textCtrlVal = value;
                        }
                    }
                }
                this.showTextDependenciesControl(ctrlObj, textCtrlVal);
            }
        }

        this.ShowDefaultSignature();

        //update checklist
        this.documentControlsResponse.CheckListData.forEach((element: any) => {
            if (element.ControlsData != null && element.ControlsData.length > 0) {
                element.ControlsData.forEach((eleChild: any) => {
                    if (eleChild.ControlName == "Signature" || eleChild.ControlName == "NewInitials") {
                        let sigImageCollection: any = document.getElementById('control_' + eleChild.ControlHtmlID);
                        let sigSignatureScr = sigImageCollection.attributes["src"] != undefined ? sigImageCollection.attributes["src"].value : '';
                        if (sigSignatureScr != null && sigSignatureScr != '' && sigSignatureScr != undefined) {
                            this.applyStylesToCheckList("add", eleChild.ControlHtmlID);
                        } else {
                            this.applyStylesToCheckList("remove", eleChild.ControlHtmlID);
                        }
                    }
                    else if (eleChild.ControlName.toLowerCase() == "dropdown") {
                        let currentdrpCtrl: any = (document.getElementById("control_" + eleChild.ControlHtmlID)) as HTMLSelectElement;
                        let sel = currentdrpCtrl.selectedIndex;
                        let opt = currentdrpCtrl.options[sel];
                        let value = (<HTMLSelectElement>opt).textContent;
                        if (value === '' || value === ' ') {
                            this.applyStylesToCheckList("remove", eleChild.ControlHtmlID);
                        }
                        else {
                            this.applyStylesToCheckList("add", eleChild.ControlHtmlID);
                        }
                    }
                    else if (eleChild.ControlName.toLowerCase() == "radio") {
                        let curCheckboxctrl: any = "control_" + eleChild.ControlHtmlID;
                        let eleCtrl: any = (<HTMLInputElement>document.getElementById("control_" + eleChild.ControlHtmlID));
                        if (eleCtrl != null && eleCtrl != undefined && eleCtrl != "") {
                            var name = (<HTMLInputElement>document.getElementById("control_" + eleChild.ControlHtmlID)).getAttribute("name");
                            if (name != "" && $('.signerControl input:radio[name="' + name + '"]:checked').length > 0) {
                                let cc: any = $('.signerControl input:radio[name="' + name + '"]:checked');
                                Array.from(cc as HTMLCollectionOf<HTMLElement>).forEach((ele: any) => {
                                    let ctrId: any = ele.getAttribute("id");
                                    this.applyStylesToCheckList("add", eleChild.ControlHtmlID);
                                });
                            }
                        }

                    }
                    else if (eleChild.ControlName.toLowerCase() == "checkbox") {
                        let curCheckboxctrl: any = "control_" + eleChild.ControlHtmlID;
                        let eleCtrl: any = (<HTMLInputElement>document.getElementById("control_" + eleChild.ControlHtmlID));
                        if (eleCtrl != null && eleCtrl != undefined && eleCtrl != "") {
                            var name = (<HTMLInputElement>document.getElementById("control_" + eleChild.ControlHtmlID)).getAttribute("name");
                            if (name != "" && $('.signerControl input:checkbox[name="' + name + '"]:checked').length > 0) {
                                let cc: any = $('.signerControl input:checkbox[name="' + name + '"]:checked');
                                Array.from(cc as HTMLCollectionOf<HTMLElement>).forEach((ele: any) => {
                                    let ctrId: any = ele.getAttribute("id");
                                    this.applyStylesToCheckList("add", eleChild.ControlHtmlID);
                                });
                            }
                            else {
                                let value = (<HTMLInputElement>document.getElementById(curCheckboxctrl)).checked;
                                if (value === true) {
                                    this.applyStylesToCheckList("add", eleChild.ControlHtmlID);
                                }
                                else if (value === false) {
                                    this.applyStylesToCheckList("remove", eleChild.ControlHtmlID);
                                }
                            }
                        }
                    }
                    else if (eleChild.ControlName.toLowerCase() == "date" || eleChild.ControlName.toLowerCase() == "email" || eleChild.ControlName.toLowerCase() == "name"
                        || eleChild.ControlName.toLowerCase() == "company" || eleChild.ControlName.toLowerCase() == "title" || eleChild.ControlName.toLowerCase() == "text") {
                        let curCtrl: any = (<HTMLInputElement>document.getElementById("control_" + eleChild.ControlHtmlID));
                        if (curCtrl) {
                            let value = (<HTMLInputElement>document.getElementById("control_" + eleChild.ControlHtmlID)).value;
                            if (value !== '' && value !== ' ' && value !== null) {
                                this.applyStylesToCheckList("add", eleChild.ControlHtmlID);
                            }
                            else {
                                this.applyStylesToCheckList("remove", eleChild.ControlHtmlID);
                            }
                        }
                    }
                    else {
                        if (eleChild.ControlValue !== '' && eleChild.ControlValue !== ' ' && eleChild.ControlValue !== null && eleChild.ControlValue.toLowerCase() !== 'false') {

                            this.applyStylesToCheckList("add", eleChild.ControlHtmlID);
                        } else {

                            this.applyStylesToCheckList("remove", eleChild.ControlHtmlID);
                        }

                    }
                });
            }
        });


    }

    getImagePath(record: any) {
        return this.styleService.getImagePath(record, this.documentControlsResponse);
    }

    //is signed == true
    getCtrlInlineStyle(ctrlObj: any) {
        let topPos: any;
        let leftPos: any;
        let modalWidth: any;
        if (ctrlObj.CalculatedTop) {
            topPos = this.controlCalculatedTopVal(ctrlObj);
        }

        if (ctrlObj.CalculatedLeft) {
            leftPos = ctrlObj.CalculatedLeft.split('left:')[1];
        }

        if (ctrlObj.IsSigned) {
            if (ctrlObj.ControlName.toLowerCase() === 'signature' || ctrlObj.ControlName.toLowerCase() === 'label'
                || ctrlObj.ControlName.toLowerCase() === 'dropdown' || ctrlObj.ControlName.toLowerCase() === 'newinitials') {
                modalWidth = ctrlObj.CalculatedModalWidth + 'px';
            }
            else if (ctrlObj.ControlName.toLowerCase() === 'text' || ctrlObj.ControlName.toLowerCase() === 'radio' || ctrlObj.ControlName.toLowerCase() === 'checkbox') {
                modalWidth = ctrlObj.Width + 'px';
            }
            else if (ctrlObj.ControlName.toLowerCase() === 'name' || ctrlObj.ControlName.toLowerCase() === 'title') {
                if (ctrlObj.IsFixedWidth === false) {
                    modalWidth = ctrlObj.CalculatedModalWidth + 'px';
                } else {
                    modalWidth = 'auto';
                }
            }
            else if (ctrlObj.ControlName.toLowerCase() === 'email'
                || ctrlObj.ControlName.toLowerCase() === 'datetimestamp' || ctrlObj.ControlName.toLowerCase() === 'company' || ctrlObj.ControlName.toLowerCase() === 'date'
                || ctrlObj.ControlName.toLowerCase() === 'initials') {
                modalWidth = 'auto';
            }
        }
        else {
            if (ctrlObj.ControlName.toLowerCase() === 'signature' || ctrlObj.ControlName.toLowerCase() === 'newinitials' || ctrlObj.ControlName.toLowerCase() === 'dropdown') {
                modalWidth = ctrlObj.CalculatedModalWidth + 'px';
            }
            else if (ctrlObj.ControlName.toLowerCase() === 'text' || ctrlObj.ControlName.toLowerCase() === 'name' ||
                ctrlObj.ControlName.toLowerCase() === 'email' || ctrlObj.ControlName.toLowerCase() === 'title' ||
                ctrlObj.ControlName.toLowerCase() === 'date' || ctrlObj.ControlName.toLowerCase() === 'company') {
                modalWidth = 'auto';
            }
            else if (ctrlObj.ControlName.toLowerCase() === 'label' || ctrlObj.ControlName.toLowerCase() === 'radio' ||
                ctrlObj.ControlName.toLowerCase() === 'checkbox') {
                modalWidth = ctrlObj.Width + 'px';
            }

        }
        return { 'left': leftPos, 'top': topPos, 'width': modalWidth, 'height': ctrlObj.Height + 'px', 'position': 'absolute' };

    }

    getImageInlineStyle(ctrlObj: any) {
        let modalWidth: any;
        if (ctrlObj.ControlName.toLowerCase() === 'signature' || ctrlObj.ControlName.toLowerCase() === 'newinitials') {
            modalWidth = ctrlObj.CalculatedModalWidth;
        }
        else {
            modalWidth = ctrlObj.Width;
        }
        return { 'max-width': modalWidth + 'px', 'max-height': ctrlObj.Height + 'px' };
    }

    getCtrlDisplayStyle(controlingFieldId: any) {
        return this.styleService.getCtrlDisplayStyle(controlingFieldId, '', 'signer');
    }

    controlCalculatedTopVal(ctrlObj: any) {
        let topPos: any;
        if (ctrlObj.CalculatedTop) {
            let ed = ctrlObj.CalculatedTop.split(';');
            if (ed && ed.length > 1) {
                for (let index = 0; index < ed.length; index++) {
                    const element = ed[index];
                    if (element.includes("top")) {
                        topPos = element.split('top:')[1].trim();
                        break;
                    }
                }
            }
            else {
                topPos = ctrlObj.CalculatedTop.split('top:')[1];
            }
            return topPos;
        }
    }

    getCtrlInlineStyleIfIsReadOnlyNull(ctrlObj: any) {
        return this.styleService.getCtrlInlineStyleIfIsReadOnlyNull(ctrlObj, 'signer');
    }

    getBackGroundBasedONSignature(control: any) {
        if (control.SignatureScr !== null || control.SignatureScr !== '') {
            return { 'background': 'none' };
        } else {
            return;
        }
    }

    isSelected(p1: any, p2: any) {
        return this.isNullOrEmptyCheck(p1) && this.isNullOrEmptyCheck(p2) && p1 === p2 ? 'selected' : '';
    }

    getRadioAndCheckboxDisplayCondition(control: any) {
        // if (control.IsReadOnly === true && this.isNullOrEmptyCheck(control.IsReadOnly) && ((this.isNullOrEmptyCheck(control.ControlValue) &&
        //     control.ControlValue.toLowerCase() === 'true') || (this.isNullOrEmptyCheck(control.SenderControlValue) && control.SenderControlValue.toLowerCase() == 'true'))) {
        //     return true;
        // } else if (control.IsReadOnly === true && this.isNullOrEmptyCheck(control.IsReadOnly) && ((this.isNullOrEmptyCheck(control.ControlValue) &&
        //     control.ControlValue.toLowerCase() === 'false') || (this.isNullOrEmptyCheck(control.SenderControlValue) && control.SenderControlValue.toLowerCase() == 'false'))) {
        //     return true;
        // } else {
        //     return false;
        // }

        if (control.IsReadOnly === true && ((control.ControlValue != "" && control.ControlValue != null && control.ControlValue.toLowerCase() === 'true') || (control.SenderControlValue != "" && control.SenderControlValue != null && control.SenderControlValue.toLowerCase() == 'true'))) { return true; }
        else if (control.IsReadOnly === true && ((control.ControlValue != "" && control.ControlValue != null && control.ControlValue.toLowerCase() === 'false') || (control.SenderControlValue != "" && control.SenderControlValue != null && control.SenderControlValue.toLowerCase() == 'false'))) { return false; }
        else { return false; }
    }

    isChecked(control: any) {
        if (this.isNullOrEmptyCheck(control.ControlValue) && control.ControlValue.toLowerCase() === 'true') {
            return 'checked';
        } else {
            return;
        }
    }

    getTextDateCtrlInlineStyle(ctrlObj: any) {
        return this.styleService.getTextDateCtrlInlineStyle(ctrlObj);
    }

    getTextInputCtrlInlineStyle(ctrlObj: any) {
        return this.styleService.getTextInputCtrlInlineStyle(ctrlObj);
    }

    getTextEmailCtrlInlineStyle(ctrlObj: any) {
        return this.styleService.getTextEmailCtrlInlineStyle(ctrlObj);
    }

    getCtrlLabelStyle(ctrlObj: any) {
        if (ctrlObj.IsSigned) {
            if (ctrlObj.ControlName.toLowerCase() === 'label' || ctrlObj.ControlName.toLowerCase() === 'dropdown') {
                return {
                    'width': ctrlObj.CalculatedModalWidth + 'px', 'height': ctrlObj.Height + 'px', 'font-size': ctrlObj.FontSize + 'px', 'font-family': ctrlObj.FontName,
                    'font-weight': ctrlObj.FontBold, 'font-style': ctrlObj.FontItalic, 'text-decoration': ctrlObj.FontUnderline,
                    'color': ctrlObj.FontColor, 'line-height': ctrlObj.FontSize + 'px'
                };
            }
            else if (ctrlObj.ControlName.toLowerCase() === 'name') {
                return {
                    'width': 'auto', 'height': ctrlObj.Height + 'px', 'font-size': ctrlObj.FontSize + 'px', 'font-family': ctrlObj.FontName,
                    'font-weight': ctrlObj.FontBold, 'font-style': ctrlObj.FontItalic, 'text-decoration': ctrlObj.FontUnderline,
                    'color': ctrlObj.FontColor, 'line-height': ctrlObj.FontSize + 'px'
                };
            }
            else {
                return {
                    'width': 'auto', 'height': ctrlObj.Height + 'px', 'font-size': ctrlObj.FontSize + 'px', 'font-family': ctrlObj.FontName,
                    'font-weight': ctrlObj.FontBold, 'font-style': ctrlObj.FontItalic, 'text-decoration': ctrlObj.FontUnderline,
                    'color': ctrlObj.FontColor, 'line-height': ctrlObj.FontSize + 'px'
                };
            }
        } else {
            if (ctrlObj.ControlName.toLowerCase() === 'label') {
                return {
                    'width': ctrlObj.Width + 'px', 'height': ctrlObj.Height + 'px', 'font-size': ctrlObj.FontSize + 'px', 'font-family': ctrlObj.FontName,
                    'font-weight': ctrlObj.FontBold, 'font-style': ctrlObj.FontItalic, 'text-decoration': ctrlObj.FontUnderline,
                    'color': ctrlObj.FontColor, 'line-height': ctrlObj.FontSize + 'px'
                };
            } else if (ctrlObj.ControlName.toLowerCase() === 'dropdown') {
                return {
                    'width': ctrlObj.CalculatedModalWidth + 'px', 'height': ctrlObj.Height + 'px', 'font-size': ctrlObj.FontSize + 'px', 'font-family': ctrlObj.FontName,
                    'font-weight': ctrlObj.FontBold, 'font-style': ctrlObj.FontItalic, 'text-decoration': ctrlObj.FontUnderline,
                    'color': ctrlObj.FontColor, 'line-height': ctrlObj.FontSize + 'px'
                };
            } else {
                return {
                    'width': 'auto', 'height': ctrlObj.Height + 'px', 'font-size': ctrlObj.FontSize + 'px', 'font-family': ctrlObj.FontName,
                    'font-weight': ctrlObj.FontBold, 'font-style': ctrlObj.FontItalic, 'text-decoration': ctrlObj.FontUnderline,
                    'color': ctrlObj.FontColor, 'line-height': ctrlObj.FontSize + 'px'
                };
            }
        }
    }

    getDefaultplaceHolder(defaultplaceHolder: string) {
        let pipe = new DatePipe('en-US');
        let date = new Date();
        return pipe.transform(date, defaultplaceHolder);
    }

    getCtrlImage(ctrlObj: any) {
        if (ctrlObj.ControlName === 'Checkbox') {
            if ((this.isNullOrEmptyCheck(ctrlObj.ControlValue) && ctrlObj.ControlValue.toLowerCase() == "true") || (!this.isNullOrEmptyCheck(ctrlObj.SenderControlValue) && ctrlObj.SenderControlValue.toLowerCase() == "true")) {
                return this.checkboxCheckedImageSrc;
            }
            else {
                return this.checkboxNotCheckedImageSrc;
            }
        } else if (ctrlObj.ControlName === 'Radio') {
            if ((this.isNullOrEmptyCheck(ctrlObj.ControlValue)) && ctrlObj.ControlValue.toLowerCase() == "true") {
                return this.radioCheckedImageSrc;
            }
            else {
                return this.radioNotCheckedImageSrc;
            }
        } else {
            return "";
        }
    }

    getTextCtrlInlineStyle(ctrlObj: any) {
        return {
            'width': ctrlObj.Width + 'px', 'white-space': 'inherit', 'height': ctrlObj.Height + 'px', 'font-size': ctrlObj.FontSize + 'px',
            'font-family': ctrlObj.FontName, 'font-weight': ctrlObj.FontBold, 'font-style': ctrlObj.FontItalic, 'text-decoration': ctrlObj.FontUnderline,
            'color': ctrlObj.FontColor, 'line-height': ctrlObj.FontSize + 'px'
        };
    }

    getCtrlLabelStyleIsReadOnlyNull(ctrlObj: any) {
        return this.styleService.getCtrlLabelStyleIsReadOnlyNull(ctrlObj);
    }

    getIphoneClass() {
        if (navigator.userAgent.includes('iPhone')) {
            return 'stampicon-iphone';
        }
        return;
    }

    getIphoneBoxSizingClass() {
        if (navigator.userAgent.includes('iPhone')) {
            return 'iphone-boxSizing';
        }
        return;
    }

    getIphoneTooltipLabelText() {
        if (navigator.userAgent.includes('iPhone')) {
            return 'iphone-tooltip-label-text';
        }
        return
    }

    getPencilClass(ctrlObj: any) {
        return this.styleService.getPencilClass(ctrlObj);
    }

    getRequiredControlClass(ctrlObj: any, ctrlValue: any) {
        return this.styleService.getRequiredControlClass(ctrlObj, ctrlValue);
    }

    getRequiredDropdownControlClass(ctrlObj: any) {
        return this.styleService.getRequiredDropdownControlClass(ctrlObj);
    }

    showToaster(msg: string, errorType: any) {
        if (errorType === "Error") {
            this.toastr.error(msg, errorType, {
                timeOut: 2000
            });
        }
        else if (errorType === "Warning") {
            this.toastr.warning(msg, errorType, {
                timeOut: 2000
            });
        }
    }

    isNumberKey(evt: any, ctr: any) {
        var regExp = /[a-zA-Z ]/;
        var regExpNumber = /[0-9]/;
        var regExpComma = /^[0-9,]+$/;
        var regExpPeriod = /^[0-9.]+$/;
        let controlId: any = document.getElementById('control_' + ctr)!;
        let cname: any = controlId.getAttribute('data-title');
        if (cname == "SSN" || cname == "Zip") {
            controlId.removeAttribute('maxlength');
            var charCode = String.fromCharCode(evt.charCode ? evt.which : evt.charCode);
            if (regExpNumber.test(charCode)) {
                return true;
            } else {
                evt.preventDefault();
                if (evt.charCode !== 13) {
                    this.showToaster('Please enter a number.', 'Warning');
                }
                return false;
            }
        }
        if (controlId.getAttribute('data-title') === 'Numeric' && controlId.getAttribute('name') === '') {
            var charCode = String.fromCharCode(evt.charCode ? evt.which : evt.charCode);
            if (regExpNumber.test(charCode)) {
                return true;
            } else {
                evt.preventDefault();
                //if user copy paste the text string
                //validating each chracter if it is text then reset to empty
                if (!this.isSingleKeyPress(evt)) {
                    if (controlId && controlId.value) {
                        let arrstr: any = controlId.value.split('');
                        for (var i = 0; i < arrstr.length; i++) {
                            if (!regExpNumber.test(arrstr[i])) {
                                controlId.value = '';
                                if (evt.charCode !== 13) {
                                    this.showToaster('Please enter a number.', 'Warning');
                                }
                                break;
                            }
                        }
                    }
                } else {
                    if (evt.charCode !== 13) {
                        this.showToaster('Please enter a number.', 'Warning');
                    }
                }
                return false;
            }
        } else if (controlId.getAttribute('data-title') === 'Numeric' && controlId.getAttribute('name') == 'Comma') {
            var charCode = String.fromCharCode(evt.charCode ? evt.which : evt.charCode);
            var asciiCode = (evt.which) ? evt.which : evt.keyCode;

            var number: any = (<HTMLInputElement>controlId).value ? (<HTMLInputElement>controlId).value.split(',') : '';
            if (number.length > 1 && asciiCode == 44) {
                evt.preventDefault();
                if (evt.charCode !== 13) {
                    this.showToaster('Only one comma allowed.', 'Warning');
                }
                return false;
            }
            if (regExpComma.test(charCode)) {
                return true;
            } else {
                evt.preventDefault();
                if (evt.charCode !== 13) {
                    this.showToaster('Numeric 0 to 9 only and comma.', 'Warning');
                }
                return false;
            }
        } else if (controlId.getAttribute('data-title') === 'Numeric' && controlId.getAttribute('name') == 'Both') {
            var charCodeValue = String.fromCharCode(evt.charCode ? evt.which : evt.charCode);
            let charCode = (evt.which) ? evt.which : evt.keyCode;
            var number: any = (<HTMLInputElement>controlId).value ? (<HTMLInputElement>controlId).value.split('.') : [];
            if (number.length > 1 && charCode == 46) {
                evt.preventDefault();
                if (evt.charCode !== 13) {
                    this.showToaster('Only one decimal allowed.', 'Warning');
                }
                return false;
            } else if (number[1] && number[1].length > 1) {
                evt.preventDefault();
                if (evt.charCode !== 13) {
                    this.showToaster('Only two digits allowed after decimal.', 'Warning');
                }
                return false;
            }
            if (regExpComma.test(charCodeValue) || regExpPeriod.test(charCodeValue)) {
                return true;
            } else {
                evt.preventDefault();
                if (evt.charCode !== 13) {
                    this.showToaster('Numeric 0 to 9 only with comma and decimal.', 'Warning');
                }
                return false;
            }
        } else if (controlId.getAttribute('data-title') === 'Numeric' && controlId.getAttribute('name') == 'Period') {
            let charCode = (evt.which) ? evt.which : evt.keyCode;
            var number: any = (<HTMLInputElement>controlId).value ? (<HTMLInputElement>controlId).value.split('.') : [];
            if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
                evt.preventDefault();
                if (evt.charCode !== 13) {
                    this.showToaster('Numeric 0 to 9 only and Period.', 'Warning');
                }
                return false;
            }
            if (number.length > 1 && charCode == 46) {
                evt.preventDefault();
                if (evt.charCode !== 13) {
                    this.showToaster('Only one decimal allowed.', 'Warning');
                }
                return false;
            }
            return;
        }
        else if (controlId.getAttribute('data-title') === 'Alphabet') {
            var charCode = String.fromCharCode(evt.charCode ? evt.which : evt.charCode);
            var controlHeight = document.getElementById(controlId.getAttribute('id')!.replace("control_", ""))!.style.height;
            if (evt.charCode == 13) {
                let calcontrolHeight = controlHeight.replace('px', '');
                if (parseInt(calcontrolHeight) <= 20) {
                    if (controlId.value == '' || controlId.value == null) {
                        evt.preventDefault();
                    }

                    if (evt.charCode !== 13) {
                        this.showToaster('Enter not allowed.', 'Warning');
                    }
                    return false;
                }
                return true;
            }
            else if (regExp.test(charCode) == false) {
                evt.preventDefault();
                //if user copy paste the number string
                //validating each characters if it is number then reset to empty
                if (!this.isSingleKeyPress(evt)) {
                    if (controlId && controlId.value) {
                        let arrstr: any = controlId.value.split('');
                        for (var i = 0; i < arrstr.length; i++) {
                            if (!regExp.test(arrstr[i])) {
                                controlId.value = '';
                                if (evt.charCode !== 13) {
                                    this.showToaster('Characters only.', 'Warning');
                                }
                                break;
                            }
                        }
                    }
                } else {
                    if (evt.charCode !== 13) {
                        this.showToaster('Characters only.', 'Warning');
                    }
                }
                return false;
            }
            return true;
        }
        else if (controlId.getAttribute('data-title') === 'Text') {
            var controlHeight = document.getElementById(controlId.getAttribute('id')!.replace("control_", ""))!.style.height;
            if (evt.charCode == 13) {
                let calcontrolHeight = controlHeight.replace('px', '');
                if (parseInt(calcontrolHeight) <= 20) {
                    if (controlId.value == '' || controlId.value == null) {
                        evt.preventDefault();
                    }
                    if (evt.charCode !== 13) {
                        this.showToaster('Enter not allowed.', 'Warning');
                    }
                    return false;
                }
            }
            return true;
        }
        else {
            return true;
        }
    }

    isMobileViewNumberKey(evt: any) {
        var regExp = /^[a-zA-Z ]*$/; // /^[a-zA-Z ]/;
        var regExpNumber = /^[0-9]*$/; // /^[0-9]/;
        var regExpComma = /^[0-9,]+$/;
        var regExpPeriod = /^[0-9.]+$/;

        let controlId: any = document.getElementById('txtMaximizeControl')!;
        let cname: any = controlId.getAttribute('data-title');
        let charCode: any = evt.data;
        if (cname == "SSN" || cname == "Zip") {
            controlId.removeAttribute('maxlength');
            if (regExpNumber.test(charCode)) {
                return true;
            } else {
                evt.preventDefault();
                this.showToaster('Please enter a number.', 'Warning');
                let enteredVal: any = document.getElementById('txtMaximizeControl');
                if (enteredVal && enteredVal.value) {
                    enteredVal.value = enteredVal.value.replace(/[^\d]/g, "");
                }
                return;
            }
        }
        if (controlId.getAttribute('data-title') === 'Numeric' && controlId.getAttribute('name') === '') {
            if (regExpNumber.test(charCode)) {
                return true;
            } else {
                evt.preventDefault();
                //if user copy paste the text string
                //validating each chracter if it is text then reset to empty
                if (controlId && controlId.value && controlId.value.length > 2) {
                    let arrstr: any = controlId.value.split('');
                    for (var i = 0; i < arrstr.length; i++) {
                        if (!regExpNumber.test(arrstr[i])) {
                            controlId.value = '';
                            let enteredVal: any = document.getElementById('txtMaximizeControl');
                            if (enteredVal && enteredVal.value) {
                                enteredVal.value = enteredVal.value.replace(/[^\d]+/g, '');
                            }
                            this.showToaster('Please enter a number.', 'Warning');
                            break;
                        }
                    }
                } else {
                    let enteredVal: any = document.getElementById('txtMaximizeControl');
                    if (enteredVal && enteredVal.value) {
                        enteredVal.value = enteredVal.value.replace(/[^\d]+/g, '');
                    }
                    this.showToaster('Please enter a number.', 'Warning');
                }
                return;
            }
        } else if (controlId.getAttribute('data-title') === 'Numeric' && controlId.getAttribute('name') == 'Comma') {
            var number: any = (<HTMLInputElement>controlId).value ? (<HTMLInputElement>controlId).value.split(',') : '';
            if (number.length > 2) {
                evt.preventDefault();
                this.showToaster('Only one comma allowed.', 'Warning');
                let enteredVal: any = document.getElementById('txtMaximizeControl');
                if (enteredVal && enteredVal.value) {
                    enteredVal.value = enteredVal.value.replace(/[^\d.,]|(?<=\..*)\.|(?<=,.*),/g, '');
                }
                return;
            }
            if (regExpComma.test(charCode)) {
                return true;
            } else {
                evt.preventDefault();
                this.showToaster('Numeric 0 to 9 only and comma.', 'Warning');
                let enteredVal: any = document.getElementById('txtMaximizeControl');
                if (enteredVal && enteredVal.value) {
                    enteredVal.value = enteredVal.value.replace(/[^\d,]|(?<=\,,*)\,/g, '');
                }
                return;
            }
        } else if (controlId.getAttribute('data-title') === 'Numeric' && controlId.getAttribute('name') == 'Both') {
            var number: any = (<HTMLInputElement>controlId).value ? (<HTMLInputElement>controlId).value.split('.') : [];
            if (charCode != null) {
                if (number.length > 2) {
                    evt.preventDefault();
                    this.showToaster('Only one decimal allowed.', 'Warning');
                    let enteredVal: any = document.getElementById('txtMaximizeControl');
                    if (enteredVal && enteredVal.value) {
                        enteredVal.value = enteredVal.value.replace(/(\..*?)\./g, '$1');
                    }
                    return;
                } else if (number[1] && number[1].length > 2) {
                    evt.preventDefault();
                    this.showToaster('Only two digits allowed after decimal.', 'Warning');
                    let enteredVal: any = document.getElementById('txtMaximizeControl');
                    if (enteredVal && enteredVal.value) {
                        const inputString = enteredVal.value;
                        const parts = inputString.split('.');
                        let decimalPart = parts[1] || '';
                        if (decimalPart.length > 2) {
                            decimalPart = decimalPart.substring(0, 2);
                        }
                        enteredVal.value = `${parts[0]}.${decimalPart}${inputString.slice(parts[0].length + parts[1]?.length + 1)}`;
                    }
                    return;
                }
                if (regExpComma.test(charCode) || regExpPeriod.test(charCode)) {
                    return true;
                } else {
                    evt.preventDefault();
                    this.showToaster('Numeric 0 to 9 only with comma and decimal.', 'Warning');
                    let enteredVal: any = document.getElementById('txtMaximizeControl');
                    if (enteredVal && enteredVal.value) {
                        enteredVal.value = enteredVal.value.replace(/[^\d,\.]/g, "");
                    }
                    return;
                }
            }
            return;
        } else if (controlId.getAttribute('data-title') === 'Numeric' && controlId.getAttribute('name') == 'Period') {
            var number: any = (<HTMLInputElement>controlId).value ? (<HTMLInputElement>controlId).value.split('.') : [];
            if (number.length > 2) {
                evt.preventDefault();
                this.showToaster('Only one decimal allowed.', 'Warning');
                let enteredVal: any = document.getElementById('txtMaximizeControl');
                if (enteredVal && enteredVal.value) {
                    enteredVal.value = enteredVal.value.replace(/[^\d.]|(?<=\..*)\./g, '');
                }
                return;
            }
            if (regExpComma.test(charCode) || regExpPeriod.test(charCode)) {
                return true;
            } else {
                evt.preventDefault();
                this.showToaster('Numeric 0 to 9 only and Period.', 'Warning');
                let enteredVal: any = document.getElementById('txtMaximizeControl');
                if (enteredVal && enteredVal.value) {
                    enteredVal.value = enteredVal.value.replace(/[^\d.]|(?<=\..*)\./g, '');
                }
                return;
            }
        } else if (controlId.getAttribute('data-title') === 'Alphabet') {

            if (regExp.test(charCode)) {
                //if user copy paste the number string
                //validating each characters if it is number then reset to empty
                if (controlId && controlId.value && controlId.value.length > 2) {
                    let arrstr: any = controlId.value.split('');
                    for (var i = 0; i < arrstr.length; i++) {
                        if (!regExp.test(arrstr[i])) {
                            controlId.value = '';
                            let enteredVal: any = document.getElementById('txtMaximizeControl');
                            if (enteredVal && enteredVal.value) {
                                enteredVal.value = enteredVal.value.replace(/[^a-zA-Z]+/g, '');
                            }
                            this.showToaster('Characters only.', 'Warning');
                            break;
                        }
                    }
                }
                return true;
            }
            else {
                evt.preventDefault();
                this.showToaster('Characters only.', 'Warning');
                let enteredVal: any = document.getElementById('txtMaximizeControl');
                if (enteredVal && enteredVal.value) {
                    enteredVal.value = enteredVal.value.replace(/[^a-zA-Z]+/g, '');
                }
                return;
            }
        }
        else if (controlId.getAttribute('data-title') === 'Text') {
            var controlHeight = document.getElementById(controlId.getAttribute('id')!.replace("control_", ""))!.style.height;
            if (evt.charCode == 13) {
                evt.preventDefault();
                if (evt.charCode !== 13) {
                    this.showToaster('Enter not allowed.', 'Warning');
                }
                return false;
            }
            return true;
        }
        else {
            return true;
        }
    }

    controlDependencyControllingField(controlObj: any) {
        return controlObj.ConditionDetails.ControllingFieldID === null ? 'null' : controlObj.ConditionDetails.ControllingFieldID;
    }

    getTextNotDateAndEmailCtrlStyle(ctrlObj: any) {
        return this.styleService.getTextNotDateAndEmailCtrlStyle(ctrlObj);
    }

    controlHeightWidth(controlObj: any) {
        return { 'width': controlObj.Width + 'px', 'height': controlObj.Height + 'px', 'background': 'none' };
    }

    controlHeightWidthAuto(controlObj: any) {
        return { 'width': 'auto', 'height': controlObj.Height + 'px' };
    }

    getSignatureImageStyle(controlObj: any, type: any) {
        return (type === 0) ? { 'width': 'auto', 'height': (controlObj.Height) + 'px', 'max-width': '100%' } : { 'width': 'auto', 'height': controlObj.Height + 'px', 'max-width': '100%' };
    }

    isNullOrEmptyCheck(stringValueFlag: any) {
        let boolFlag = false;
        if (typeof stringValueFlag != 'undefined' && stringValueFlag !== '' && stringValueFlag !== null && stringValueFlag !== 'false') {
            boolFlag = true;
        }
        return boolFlag;
    }

    getLanguageControlName(control: any) {
        let label;
        if (control.ControlName.toLowerCase() === 'text') {
            label = control.Label;
        }
        else if (control.ControlName.toLowerCase() === 'checkbox') {
            if (control.GroupName != '' && control.GroupName != null) {
                label = control.GroupName + "-" + control.Label + "-" + "P" + control.PageNo;
            }
            else {
                label = control.LanguageControlName;
            }
        }
        else if (control.ControlName.toLowerCase() === 'radio') {
            label = control.GroupName + "-" + control.Label + "-" + "P" + control.PageNo;
        }
        else {
            label = control.LanguageControlName;
        }
        return label;
    }

    getTooltipAlertposition(ctrlObj: any) {
        return this.styleService.getTooltipAlertposition(ctrlObj, this.webOrMobile);
    }

    getTooltipTrangleposition(ctrlObj: any) {
        return this.styleService.getTooltipTrangleposition(ctrlObj, this.webOrMobile);
    }

    getSelectedDocument() {
        let currentPagination: any = localStorage.getItem('currentPagination');
        let currentPageValue: any = parseInt(currentPagination);
        this.onPaginateChange(currentPageValue, '');
    }

    onPreviewImageSelection(event: any, id: any) {
        this.commonService.setUser(id);
        localStorage.setItem('currentPagination', id);
        //(<HTMLInputElement>document.getElementById('paginationInput')).value = id;
        let currentPagination: any = localStorage.getItem('currentPagination');
        let currentPageValue: any = parseInt(currentPagination);
        this.onPaginateChange(currentPageValue, '');
        if (this.webOrMobile === 'mobile') {
            let closeMoreActionsMenu: any = document.getElementById("closeMoreActionsMenu");
            closeMoreActionsMenu.click();
        }
        this.getPreviewSelectedColor(currentPageValue);
        event.preventDefault();
    }

    onUpdatePageScrollAndCount(id: any) {
        if (id !== undefined && (id <= this.documentData.length)) {
            let imgControl: any = $(".imgControl");
            imgControl.disabled = true;
            let pageNoAttr: any = $('div.imgControl[page-no="' + id + '"]').offset()?.top;
            let firstpageNoAttr: any = $('div.imgControl[page-no="1"]').offset()?.top;
            var scrollTo = pageNoAttr - firstpageNoAttr - 20;
            $('html, body').animate({ scrollTop: scrollTo }, 100);
            localStorage.setItem('currentPagination', id);
        }
    }

    onPaginateChange(id: any, ctrlObj: any) {
        this.onUpdatePageScrollAndCount(id);
    }

    expand(index: any) {
        if (this.currentIndex === index) {
            this.currentIndex = null;
            return;
        }
        this.currentIndex = index;
    }

    //Filter dropdown selection and updating searchterm filter
    onChangeDropdownSelection(event: any, val: any) {
        let selectedValue: any = event.currentTarget.value;
        if (selectedValue) {
            if (selectedValue === 'Select All') {
                this.searchTerm = '';
                this.searchTermDisplay = '';
            }
            else if (selectedValue === 'Initials') {
                this.searchTerm = 'NewInitials';
                this.searchTermDisplay = 'Initials';
            }
            else {
                this.searchTerm = selectedValue;
                this.searchTermDisplay = this.searchTerm;
            }
        }
        else {
            this.searchTerm = '';
            this.searchTermDisplay = '';
        }
    }

    onFilterControlClick(ctrlObj: any, pageIndex: any) {
        if (ctrlObj.ControlName === 'Signature' || ctrlObj.ControlName === 'NewInitials') {
            this.signClickCount = this.signClickCount + 1;
        }
        localStorage.setItem('currentPagination', ctrlObj.PageNo);
        if (this.webOrMobile === 'mobile') {
            this.closeMoreActionsMenu();
            if (ctrlObj.ControlName === 'Date') {
                this.scrollToElement(ctrlObj, ctrlObj.PageNo);
            } else if (ctrlObj.ControlName === 'Text' && ctrlObj.TextType === 'Date') {
                this.scrollToElement(ctrlObj, ctrlObj.PageNo);
            }
        } else {
            this.scrollToElement(ctrlObj, ctrlObj.PageNo);
        }
        if (ctrlObj.ControlName === 'Text' && ctrlObj.TextType === 'Date') {

        } else if (ctrlObj.ControlName !== 'Date') {
            this.onShowControlTooltip(ctrlObj, 'selectedFromFilter');
        }
    }

    scrollToElement(obj: any, pageNo: any) {
        if (obj.ControlName === 'Signature') {
            setTimeout(() => {
                document.getElementById('control_' + obj.ControlHtmlID)!.focus();
                this.onClickSignature(obj.ControlHtmlID);
            }, 500);
        } else if (obj.ControlName === 'NewInitials') {
            setTimeout(() => {
                document.getElementById('control_' + obj.ControlHtmlID)!.focus();
                this.onClickInitials(obj.ControlHtmlID);
            }, 500);
        } else if (obj.ControlName === 'Date') {
            // setTimeout(() => {
            document.getElementById('control_' + obj.ControlHtmlID)!.focus();
            // }, 300);
        } else if (obj.ControlName === 'Title' || obj.ControlName === 'Name' || obj.ControlName === 'Company' ||
            obj.ControlName === 'Email' || obj.ControlName === 'Text' || obj.ControlName === 'Checkbox' || obj.ControlName === 'Radio' ||
            obj.ControlName === 'DropDown') {
            let control = document.getElementById('control_' + obj.ControlHtmlID)!
            if (control) {
                if (this.webOrMobile === 'mobile') {
                    let controlEle: any = document.getElementById('control_' + obj.ControlHtmlID);
                    controlEle?.blur();
                }
                setTimeout(() => {
                    document.getElementById('control_' + obj.ControlHtmlID)!.focus();
                    document.getElementById('control_' + obj.ControlHtmlID)!.style.outline = 'none';
                }, 300);
            }
        }
    }

    //Terms of service print functionality
    onTermsOfServicePrint() {
        this.popupWin = window.open('', 'Terms of Service', 'menubar=0,location=0,height=700,width=700');
        this.popupWin.document.body.innerHTML = document.getElementById('disclaimerTermsAndServiceBody')!.outerHTML;
        this.popupWin.print();
    }

    onClickSignature(controleID: any) {
        if (controleID === "FooterSignNav") {
            let closeMoreActionsMenu: any = document.getElementById("closeMoreActionsMenu");
            closeMoreActionsMenu.click();
        }

        if (this.IsEnableClickToSign) {
            this.openClickToSignModalOrSignaturePadModal(controleID);
            this.signClickCount = 0;
        }
        else {
            this.openSignaturePadModel(controleID);
        }
    }

    openSignaturePadModel(controleID: any) {
        if (this.checkDocumentControlsResponselocal()) {
            this.commonService.checkIsUrlExistsInBrowserMemory();
            return;
        }
        else {
            let slControl = document.getElementById(controleID);
            let dataFontColor: any;
            if (slControl != undefined && slControl != null) {
                let slControlImg: any = slControl.getElementsByTagName('img');
                dataFontColor = slControlImg[0].attributes["data-font-color"] != undefined ? slControlImg[0].attributes["data-font-color"].value : ''
            }
            const modalRef = this.modalService.open(SignaturePadModalComponent, { windowClass: 'signaturePadModalComponent-class', keyboard: false, backdrop: 'static' });
            modalRef.componentInstance.signControlId = controleID;
            modalRef.componentInstance.dataFontColor = dataFontColor;
            modalRef.componentInstance.defaultSignature = this.defaultSignatureObj[0].ControlName;
            modalRef.componentInstance.checkListData = this.documentCheckListsResponse;
            modalRef.componentInstance.filterCheckListSingature.subscribe((isfilter: any) => {
                if (isfilter === 'true') {
                    this.getTotalRemaingFieldsControls('');
                    setTimeout(() => {
                        this.getControlNextNav('');
                    }, 100);
                }
            });
            modalRef.componentInstance.isSignaturePopupClosed.subscribe((isClosedPopup: any) => {
                if (isClosedPopup === 'true') {
                    this.signClickCount = 0;
                    this.checkTooltipIsApplicable(controleID);
                }
            });
        }
    }

    onClickInitials(controleID: any) {
        if (this.IsEnableClickToSign) {
            this.openClickToSignModalOrInitialPadModal(controleID);
            this.signClickCount = 0;
        }
        else {
            this.openInitialPadModel(controleID);
        }
    }

    openInitialPadModel(controleID: any) {
        if (this.checkDocumentControlsResponselocal()) {
            this.commonService.checkIsUrlExistsInBrowserMemory();
            return;
        }
        else {
            let slControl = document.getElementById(controleID);
            let dataFontColor: any;
            if (slControl != undefined && slControl != null) {
                let slControlImg: any = slControl.getElementsByTagName('img');
                dataFontColor = slControlImg[0].attributes["data-font-color"] != undefined ? slControlImg[0].attributes["data-font-color"].value : ''
            }

            const modalRef = this.modalService.open(InitialsPadComponent, { windowClass: 'initialsPadModalComponent-class', keyboard: false, backdrop: 'static' });
            modalRef.componentInstance.signControlId = controleID;
            modalRef.componentInstance.dataFontColor = dataFontColor;
            modalRef.componentInstance.checkListData = this.documentCheckListsResponse;
            modalRef.componentInstance.filterCheckListInitials.subscribe((isfilter: any) => {
                if (isfilter === 'true') {
                    this.getTotalRemaingFieldsControls('');
                    setTimeout(() => {
                        this.getControlNextNav('');
                    }, 100);
                }
            });
            modalRef.componentInstance.isInitialsPopupClosed.subscribe((isInitClosed: any) => {
                if (isInitClosed === 'true') {
                    this.signClickCount = 0;
                    this.checkTooltipIsApplicable(controleID);
                }
            });
        }
    }

    validateEmail(email: any) {
        var re = EmailAddressFormat.ValidEmail;
        return re.test(email);
    }

    isValidDateCustom(dateValue: any, dateFormat: any) {
        var dateFormat = dateFormat.toUpperCase();
        var isValid1 = moment(dateValue, dateFormat, true).isValid();
        var isValid2 = moment(dateValue, dateFormat.replace("YY", "YYYY"), true).isValid();
        return isValid1 || isValid2;
    }

    validateInputText(value: any, ctrlObj: any) {
        // console.log('validateInputText', value.target.value, ctrlObj);
        let targetValue: any;
        if (ctrlObj.ControlName.toLowerCase() === 'checkbox') {
            targetValue = value.target.checked;
            if (targetValue !== '' && targetValue !== undefined) {
                let chkItem: any = (<HTMLInputElement>document.getElementById(ctrlObj.ControlHtmlID));
                if (targetValue === false) {
                    this.applyStylesToCheckList("remove", ctrlObj.ControlHtmlID);
                    chkItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                } else {
                    this.applyStylesToCheckList("add", ctrlObj.ControlHtmlID);
                }
            }
            else {
                this.applyStylesToCheckList("remove", ctrlObj.ControlHtmlID)
            }
        }
        else if (ctrlObj.ControlName.toLowerCase() === 'radio') {
            targetValue = value.target.checked;
            if (targetValue !== '' && targetValue !== undefined) {
                if (targetValue === false) {
                    this.applyStylesToCheckList("remove", ctrlObj.ControlHtmlID)
                } else {
                    this.applyStylesToCheckList("add", ctrlObj.ControlHtmlID);
                }
            }
            else {
                this.applyStylesToCheckList("remove", ctrlObj.ControlHtmlID)
            }
        }
        else {
            targetValue = value.target.value.trim();
            value.target.value = targetValue;
            if (targetValue !== '' && targetValue !== undefined) {
                let contrl: any = document.getElementById('control_' + ctrlObj.ControlHtmlID);
                let isValid: boolean = false;
                if (contrl) {
                    let datatitle: any = contrl.getAttribute("data-title");
                    if (datatitle == "SSN" || datatitle == "Zip") {
                        if (contrl.value.indexOf('_') > 0 || contrl.value.indexOf('_') > -1 || contrl.value.indexOf('-') > 5) {
                            isValid = true;
                        }
                    }
                }

                if (isValid) { this.applyStylesToCheckList("remove", ctrlObj.ControlHtmlID) }
                else { this.applyStylesToCheckList("add", ctrlObj.ControlHtmlID); }
            }
            else {
                this.applyStylesToCheckList("remove", ctrlObj.ControlHtmlID)
            }
        }
        setTimeout(() => {
            this.getTotalRemaingFieldsControls('');
        }, 10);
    }

    applyStylesToCheckList(type: any, ControlHtmlID: any) {
        let currentLblControl: any = document.getElementById("label_" + ControlHtmlID);
        let currentLblIconControl: any = document.getElementById("iicon_" + ControlHtmlID);
        if (currentLblControl && currentLblIconControl && type == "remove") {
            currentLblControl.classList.remove("success-color", "success-icon");
            currentLblControl.classList.add("requried-color");
            currentLblControl.classList.add("requried-color1");
            currentLblControl.classList.add("requried-icon");
            currentLblIconControl.classList.remove("success-icon");
            currentLblIconControl.classList.add("requried-icon");
        }
        else if (currentLblControl && currentLblIconControl && type == "add") {
            currentLblControl.classList.remove("requried-color", "requried-color1", "requried-icon");
            currentLblControl.classList.add("success-color");
            currentLblControl.classList.add("success-icon");
            currentLblIconControl.classList.remove("requried-icon");
            currentLblIconControl.classList.add("success-icon");
        }
    }

    getDependencies(ctrlObj: any, eleArr: any, curRoleId: any) {
        let txRadioArray: string[] = [];
        eleArr.ControlsData.forEach((childEle: any) => {
            if (ctrlObj.ControlName.toLowerCase() === 'radio') {
                if (curRoleId && (childEle.RecipientId === curRoleId.toLowerCase() || (childEle.RecipientId == null))) {
                    if (ctrlObj.Id === childEle.ConditionDetails.ControllingFieldID) {
                        if (document.getElementById(childEle.ControlHtmlID)) {
                            document.getElementById(childEle.ControlHtmlID)!.style.display = 'block';
                        }

                        if (document.getElementById('anchor_' + childEle.ControlHtmlID)) {
                            document.getElementById('anchor_' + childEle.ControlHtmlID)!.style.display = 'block';
                            document.getElementById('panel_' + childEle.ControlHtmlID)!.style.display = 'block';
                            document.getElementById('panel_' + childEle.ControlHtmlID)!.removeAttribute('style');
                            document.getElementById('panel_' + childEle.ControlHtmlID)!.setAttribute('style', '');
                            document.getElementById('panel_' + childEle.ControlHtmlID)!.classList.add('visible');
                        }
                    }
                    else {
                        if (ctrlObj.GroupName === childEle.GroupName && ctrlObj.Id !== childEle.Id) {
                            eleArr.ControlsData.forEach((childEle11: any) => {
                                if (childEle11.RecipientId === curRoleId.toLowerCase() || (childEle11.RecipientId == null)) {
                                    if (childEle.Id === childEle11.ConditionDetails.ControllingFieldID) {

                                        if (document.getElementById(childEle11.ControlHtmlID)) {
                                            document.getElementById(childEle11.ControlHtmlID)!.style.display = 'none';
                                        }
                                        if (document.getElementById('anchor_' + childEle11.ControlHtmlID)) {
                                            document.getElementById('anchor_' + childEle11.ControlHtmlID)!.style.display = 'none';
                                            document.getElementById('panel_' + childEle11.ControlHtmlID)!.style.display = 'none';
                                        }
                                    }

                                    if (childEle11.ControlName.toLowerCase() === 'radio' && childEle11.GroupName != '' && ctrlObj.GroupName === childEle11.GroupName && ctrlObj.Id !== childEle11.Id) {
                                        if (!txRadioArray.includes(childEle11.ControlHtmlID)) {
                                            txRadioArray.push(childEle11.ControlHtmlID);
                                        }
                                    }
                                }
                            })
                        }
                    }
                }
            }
            else if (ctrlObj.ControlName.toLowerCase() === 'checkbox') {
                if (curRoleId && childEle.RecipientId === curRoleId.toLowerCase() || (childEle.RecipientId == null)) {
                    if (ctrlObj.Id === childEle.ConditionDetails.ControllingFieldID) {
                        let value: any = (<HTMLInputElement>document.getElementById('control_' + ctrlObj.ControlHtmlID)).checked;
                        if (value == true) {
                            document.getElementById(childEle.ControlHtmlID)!.style.display = 'block';
                            if (document.getElementById('anchor_' + childEle.ControlHtmlID)) {
                                document.getElementById('anchor_' + childEle.ControlHtmlID)!.style.display = 'block';
                                document.getElementById('panel_' + childEle.ControlHtmlID)!.style.display = 'block';
                                document.getElementById('panel_' + childEle.ControlHtmlID)!.removeAttribute('style');
                                document.getElementById('panel_' + childEle.ControlHtmlID)!.setAttribute('style', '');
                                document.getElementById('panel_' + childEle.ControlHtmlID)!.classList.add('visible');
                            }
                        }
                        else {
                            document.getElementById(childEle.ControlHtmlID)!.style.display = 'none';
                            if (document.getElementById('anchor_' + childEle.ControlHtmlID)) {
                                document.getElementById('anchor_' + childEle.ControlHtmlID)!.style.display = 'none';
                                document.getElementById('panel_' + childEle.ControlHtmlID)!.style.display = 'none';
                            }
                        }
                    }
                    else {
                        if (ctrlObj.GroupName === childEle.GroupName && ctrlObj.Id !== childEle.Id) {
                            eleArr.ControlsData.forEach((childEle11: any) => {
                                if (childEle11.RecipientId === curRoleId.toLowerCase() || (childEle11.RecipientId == null)) {
                                    if (childEle.Id == ctrlObj.Id && childEle.Id === childEle11.ConditionDetails.ControllingFieldID) {
                                        document.getElementById(childEle11.ControlHtmlID)!.style.display = 'none';
                                        if (document.getElementById('anchor_' + childEle11.ControlHtmlID)) {
                                            document.getElementById('anchor_' + childEle11.ControlHtmlID)!.style.display = 'none';
                                            document.getElementById('panel_' + childEle11.ControlHtmlID)!.style.display = 'none';
                                        }
                                    }

                                    if (childEle11.ControlName.toLowerCase() === 'checkbox' && childEle11.GroupName != '' && ctrlObj.GroupName === childEle11.GroupName && ctrlObj.Id !== childEle11.Id) {
                                        if (!txRadioArray.includes(childEle11.ControlHtmlID)) {
                                            txRadioArray.push(childEle11.ControlHtmlID);
                                        }
                                    }
                                }
                            })
                        }
                    }
                }
            }
        });

        if (txRadioArray && txRadioArray.length > 0) {
            for (let index = 0; index < txRadioArray.length; index++) {
                const controlHtmlId = txRadioArray[index];
                let currentCtrl: any = document.getElementById("control_" + controlHtmlId);
                if (currentCtrl) {
                    let groupName: any = document.getElementById("control_" + controlHtmlId)!.getAttribute("name");
                    if (groupName != '') {
                        let curCheckboxctrl: any = document.getElementById("control_" + ctrlObj.ControlHtmlID);
                        let isChecked: string = "false";
                        isChecked = curCheckboxctrl.checked ? "true" : "false";
                        if (isChecked == "true") {
                            this.applyStylesToCheckList("add", controlHtmlId);
                        }
                        else {
                            this.applyStylesToCheckList("remove", controlHtmlId)
                        }
                    }
                    else {
                        this.applyStylesToCheckList("add", controlHtmlId);
                    }
                }
            }
        }

        if (ctrlObj.ControlName.toLowerCase() === 'radio' && ctrlObj.GroupName != '') {
            let curCheckboxctrl: any = "control_" + ctrlObj.ControlHtmlID;
            var name = (<HTMLInputElement>document.getElementById("control_" + ctrlObj.ControlHtmlID)).getAttribute("name");
            if (name && name != '' && $('.signerControl input:radio[name="' + name + '"]:checked').length > 0) {
                let cc: any = $('.signerControl input:radio[name="' + name + '"]:checked');
                Array.from(cc as HTMLCollectionOf<HTMLElement>).forEach((ele: any) => {
                    let ctrId: any = ele.getAttribute("id");
                    if (ctrId !== curCheckboxctrl) {
                        (<HTMLInputElement>document.getElementById(ctrId)).checked = false;
                    }
                });
            }
        }

        if (ctrlObj.ControlName.toLowerCase() === 'checkbox' && ctrlObj.GroupName != '') {
            var name = (<HTMLInputElement>document.getElementById("control_" + ctrlObj.ControlHtmlID)).getAttribute("name");
            if (name && name != '' && $('.signerControl input:checkbox[name="' + name + '"]:checked').length > 0) {
                let cc: any = $('.signerControl input:checkbox[name="' + name + '"]');
                Array.from(cc as HTMLCollectionOf<HTMLElement>).forEach((ele: any) => {
                    let ctrId: any = ele.getAttribute("id");
                    let controlID: any = ctrId.replace('control_', '')
                    let ctrIdGroupName: any = ele.getAttribute("name");
                    if (ctrIdGroupName != '' && ctrIdGroupName == name) {
                        this.applyStylesToCheckList("add", controlID);
                    }
                })
            }

        }
    }

    //Checking the dependecies (Radio, Checkbox and Dropdown) and showing the dependecies controls and updating the checklist array.
    checkDependenciesControl(ctrlObj: any, selectedDropdownValue: any) {
        let curRoleId: any = localStorage.getItem("CurrentRecipientID");
        if (curRoleId == null) {
            curRoleId = localStorage.getItem("RecipientID");
        }
        if (this.documentControlsResponse.EnvelopeImageControlData && this.documentControlsResponse.EnvelopeImageControlData.length) {
            this.documentControlsResponse.EnvelopeImageControlData.forEach((ele: any) => {
                if (ele.ControlsData && ele.ControlsData.length) {
                    if (ctrlObj.ControlName.toLowerCase() === 'dropdown') {
                        ele.ControlsData.forEach((childEle: any) => {
                            if (curRoleId && (childEle.RecipientId === curRoleId.toLowerCase() || childEle.RecipientId == null)) {
                                if (childEle.ConditionDetails.DependentFields && childEle.ConditionDetails.DependentFields.length) {
                                    childEle.ConditionDetails.DependentFields.forEach((childEleDep: any) => {
                                        if (selectedDropdownValue === childEleDep.ConditionID) {
                                            ele.ControlsData.forEach((childEleDepCtrl: any) => {
                                                if (childEleDep.ControlID === childEleDepCtrl.Id) {
                                                    document.getElementById(childEleDepCtrl.ControlHtmlID)!.style.display = 'block';
                                                    if (document.getElementById('anchor_' + childEleDepCtrl.ControlHtmlID)) {
                                                        document.getElementById('anchor_' + childEleDepCtrl.ControlHtmlID)!.style.display = 'block';
                                                        document.getElementById('panel_' + childEleDepCtrl.ControlHtmlID)!.style.display = 'block';
                                                        document.getElementById('panel_' + childEleDepCtrl.ControlHtmlID)!.removeAttribute('style');
                                                        document.getElementById('panel_' + childEleDepCtrl.ControlHtmlID)!.setAttribute('style', '');
                                                        document.getElementById('panel_' + childEleDepCtrl.ControlHtmlID)!.classList.add('visible');
                                                    }
                                                }
                                            })
                                        } else {
                                            childEle.ControlOptions.forEach((ctrlOptions: any) => {
                                                if (selectedDropdownValue !== ctrlOptions.ID) {
                                                    ele.ControlsData.forEach((childEleDepCtrl: any) => {
                                                        if ((childEleDepCtrl.RecipientId === curRoleId.toLowerCase() || childEle.RecipientId == null) && childEleDep.ControlID === childEleDepCtrl.Id && childEleDepCtrl.ConditionDetails.ControllingFieldID == ctrlObj.Id) {
                                                            document.getElementById(childEleDepCtrl.ControlHtmlID)!.style.display = 'none';
                                                            if (document.getElementById('anchor_' + childEleDepCtrl.ControlHtmlID)) {
                                                                document.getElementById('anchor_' + childEleDepCtrl.ControlHtmlID)!.style.display = 'none';
                                                                document.getElementById('panel_' + childEleDepCtrl.ControlHtmlID)!.style.display = 'none';
                                                            }
                                                        }
                                                    })
                                                }
                                            })

                                        }
                                    })
                                }
                            }
                        });
                    }
                    else {
                        this.getDependencies(ctrlObj, ele, curRoleId);
                    }
                }
            });

            if (ctrlObj.ControlName.toLowerCase() === 'dropdown') {
                let controlIdEle: any = document.getElementById('control_' + ctrlObj.ControlHtmlID);
                let value: any = controlIdEle.selectedOptions[0].innerText.trim();
                if (value !== '' && value !== ' ' && value !== null) {
                    this.applyStylesToCheckList("add", ctrlObj.ControlHtmlID);
                } else {
                    this.applyStylesToCheckList("remove", ctrlObj.ControlHtmlID)
                }
            }

            this.ShowDefaultSignature();
        }

        if (this.searchTermDisplay != '') {
            this.searchTerm = '';
            this.searchTermDisplay = '';
            this.onFilterApply();
        }
        else {
            this.getTotalRemaingFieldsControls('');
        }
    }

    maskSSN(event: any, controlObj: any) {
        if (event.key != "Tab") {
            var myMask: any;
            let controlId: any = document.getElementById(controlObj)!;
            if (controlId.getAttribute('data-mask') == '___-__-____') {
                myMask = '___-__-____';
            } else if (controlId.getAttribute('data-mask') == "_____") {
                myMask = "_____";
            } else if (controlId.getAttribute('data-mask') == "_____-____") {
                myMask = "_____-____";
            }

            let myCaja = document.getElementById(controlObj)!;
            let myText: any = "";
            let myNumbers = [];
            let myOutPut = "";
            let theLastPos = 1;
            myText = (<HTMLInputElement>controlId).value; //myCaja.value;
            for (let i = 0; i < myText.length; i++) {
                if (!isNaN(myText.charAt(i)) && myText.charAt(i) != " ") {
                    myNumbers.push(myText.charAt(i));
                }
            }

            if (myMask != undefined) {
                for (let j = 0; j < myMask.length; j++) {
                    if (myMask.charAt(j) == "_") {
                        if (myNumbers.length == 0)
                            myOutPut = myOutPut + myMask.charAt(j);
                        else {
                            myOutPut = myOutPut + myNumbers.shift();
                            theLastPos = j + 1;
                        }
                    } else {
                        myOutPut = myOutPut + myMask.charAt(j);
                    }
                }
                (<HTMLInputElement>controlId).value = myOutPut;
                (<HTMLInputElement>controlId).setSelectionRange(theLastPos, theLastPos);
            }
        }
    }

    controlOnchangeEvent(event: Event, controlObj: any) {
        let controlId = document.getElementById('control_' + controlObj.ControlHtmlID)!;

        if (controlId.getAttribute('data-title') === 'Zip' || controlId.getAttribute('data-title') === 'SSN') {
            this.maskSSN(event, controlId.getAttribute('id'));
        }

        if (controlId.getAttribute('data-title') === 'Date') {
            this.updateChecklist(controlId.getAttribute('id'));
        }
    }

    mobileViewControlOnchangeEvent(event: Event) {
        let controlId: any = document.getElementById('txtMaximizeControl')!;

        if (controlId.getAttribute('data-title') === 'Zip' || controlId.getAttribute('data-title') === 'SSN') {
            this.maskSSN(event, controlId.getAttribute('id'));
        }

        if (controlId.getAttribute('data-title') === 'Date') {
            this.updateChecklist(controlId.getAttribute('id'));
        }
    }

    mobileViewDateControlOnchangeEvent(event: any, typeVal: any) {
        let controlId: any = document.getElementById(typeVal)!;
        if (controlId.getAttribute('data-title') === 'Date') {
            if (controlId.value) {
                var charCode = event.keyCode || event.which;
                if (charCode > 31 && ((charCode < 45 || charCode > 57) && (charCode < 189 || charCode > 192))) {
                    controlId.value = '';
                }
                else {
                    this.updateChecklist(controlId.getAttribute('id'));
                }
            }
        }
    }

    //Text Dependencies Control functionality.
    checkTextDependenciesControl(event: any, ctrlObj: any) {
        this.showTextDependenciesControl(ctrlObj, event.target.value);

        if (this.searchTermDisplay != '') {
            this.searchTerm = '';
            this.searchTermDisplay = '';
            this.onFilterApply();
        }
    }

    showTextDependenciesControl(ctrlObj: any, controlVal: any) {
        let curRoleId: any = localStorage.getItem("CurrentRecipientID");
        if (curRoleId == null) {
            curRoleId = localStorage.getItem("RecipientID");
        }
        if (ctrlObj && ctrlObj.ConditionDetails.DependentFields && ctrlObj.ConditionDetails.DependentFields.length) {
            ctrlObj.ConditionDetails.DependentFields.forEach((eleChildDep: any) => {
                if (this.documentControlsResponse.EnvelopeImageControlData && this.documentControlsResponse.EnvelopeImageControlData.length) {
                    this.documentControlsResponse.EnvelopeImageControlData.forEach((ele: any) => {
                        if (ele.ControlsData && ele.ControlsData.length) {
                            ele.ControlsData.forEach((eleChild: any) => {
                                if (curRoleId && (eleChild.RecipientId === curRoleId.toLowerCase() || eleChild.RecipientId == null)) {
                                    if (eleChildDep.ControlID === eleChild.Id && eleChildDep.ConditionID == '8E2B3EC5-6CD1-4F3E-8025-4FDC7D6E35E3'.toLowerCase() &&
                                        eleChildDep.SupportText != '' && controlVal != null &&
                                        eleChildDep.SupportText.toLowerCase() === controlVal.toLowerCase()) {
                                        document.getElementById(eleChild.ControlHtmlID)!.style.display = 'block';
                                        if (document.getElementById('anchor_' + eleChild.ControlHtmlID)) {
                                            document.getElementById('anchor_' + eleChild.ControlHtmlID)!.style.display = 'block';
                                            document.getElementById('panel_' + eleChild.ControlHtmlID)!.style.display = 'block';
                                            document.getElementById('panel_' + eleChild.ControlHtmlID)!.removeAttribute('style');
                                            document.getElementById('panel_' + eleChild.ControlHtmlID)!.setAttribute('style', '');
                                            document.getElementById('panel_' + eleChild.ControlHtmlID)!.classList.add('visible');
                                        }
                                        this.globalCtrlObj = eleChild;
                                    }
                                    else if (eleChildDep.ControlID === eleChild.Id && eleChildDep.ConditionID == '68569338-7AEA-481F-988C-B70D13607E81'.toLowerCase() &&
                                        eleChildDep.SupportText != '' && controlVal != null && controlVal.toLowerCase().indexOf(eleChildDep.SupportText.toLowerCase()) > -1) {
                                        document.getElementById(eleChild.ControlHtmlID)!.style.display = 'block';
                                        if (document.getElementById('anchor_' + eleChild.ControlHtmlID)) {
                                            document.getElementById('anchor_' + eleChild.ControlHtmlID)!.style.display = 'block';
                                            document.getElementById('panel_' + eleChild.ControlHtmlID)!.style.display = 'block';
                                            document.getElementById('panel_' + eleChild.ControlHtmlID)!.removeAttribute('style');
                                            document.getElementById('panel_' + eleChild.ControlHtmlID)!.setAttribute('style', '');
                                            document.getElementById('panel_' + eleChild.ControlHtmlID)!.classList.add('visible');
                                        }
                                        this.globalCtrlObj = eleChild;
                                    }
                                    else if (eleChildDep.ControlID === eleChild.Id && (eleChildDep.ConditionID == '0B069009-E271-4534-8C8B-21D876CC31DE'.toLowerCase() || eleChildDep.ConditionID == '7B108AA4-B5F9-489D-930B-2AEB90811518'.toLowerCase()) &&
                                        controlVal == '') {
                                        document.getElementById(eleChild.ControlHtmlID)!.style.display = 'block';
                                        if (document.getElementById('anchor_' + eleChild.ControlHtmlID)) {
                                            document.getElementById('anchor_' + eleChild.ControlHtmlID)!.style.display = 'block';
                                            document.getElementById('panel_' + eleChild.ControlHtmlID)!.style.display = 'block';
                                            document.getElementById('panel_' + eleChild.ControlHtmlID)!.removeAttribute('style');
                                            document.getElementById('panel_' + eleChild.ControlHtmlID)!.setAttribute('style', '');
                                            document.getElementById('panel_' + eleChild.ControlHtmlID)!.classList.add('visible');
                                        }
                                        this.globalCtrlObj = eleChild;
                                    }
                                    else if (eleChildDep.ControlID === eleChild.Id && (eleChildDep.ConditionID == 'E8351007-1A7E-449C-B3FD-AFE619CFFA8D'.toLowerCase() || eleChildDep.ConditionID == '2380C0B0-4A55-4B33-9E22-A93C55A78CC3'.toLowerCase()) &&
                                        controlVal !== '') {
                                        document.getElementById(eleChild.ControlHtmlID)!.style.display = 'block';
                                        if (document.getElementById('anchor_' + eleChild.ControlHtmlID)) {
                                            document.getElementById('anchor_' + eleChild.ControlHtmlID)!.style.display = 'block';
                                            document.getElementById('panel_' + eleChild.ControlHtmlID)!.style.display = 'block';
                                            document.getElementById('panel_' + eleChild.ControlHtmlID)!.removeAttribute('style');
                                            document.getElementById('panel_' + eleChild.ControlHtmlID)!.setAttribute('style', '');
                                            document.getElementById('panel_' + eleChild.ControlHtmlID)!.classList.add('visible');
                                        }
                                        this.globalCtrlObj = eleChild;
                                    }
                                    else if (eleChildDep.ControlID === eleChild.Id && eleChildDep.SupportText != '' && controlVal != null &&
                                        eleChildDep.SupportText.toLowerCase() !== controlVal.toLowerCase()) {
                                        document.getElementById(eleChild.ControlHtmlID)!.style.display = 'none';
                                        if (document.getElementById('anchor_' + eleChild.ControlHtmlID)) {
                                            document.getElementById('anchor_' + eleChild.ControlHtmlID)!.style.display = 'none';
                                            document.getElementById('panel_' + eleChild.ControlHtmlID)!.style.display = 'none';
                                        }
                                    }
                                    else if (eleChildDep.ControlID === eleChild.Id) {
                                        document.getElementById(eleChild.ControlHtmlID)!.style.display = 'none';
                                        if (document.getElementById('anchor_' + eleChild.ControlHtmlID)) {
                                            document.getElementById('anchor_' + eleChild.ControlHtmlID)!.style.display = 'none';
                                            document.getElementById('panel_' + eleChild.ControlHtmlID)!.style.display = 'none';
                                        }
                                    }
                                }
                            })
                        }
                    });

                }
            })
        } else {
            this.globalCtrlObj = null;
        }
        this.ShowDefaultSignature();
    }

    //on focus date picker, to show popup
    onShowControlDateTooltip(d: any) {
        d.toggle();
        this.isCheckDatePopupOpen();
    }

    getIsRequired(ctrlObj: any) {
        let classname: any = ''; //cr rsigncolor
        if (ctrlObj.Required === true && (ctrlObj.isControlFiled === false || ctrlObj.isControlFiled === undefined)) {
            classname = 'requried-icon';
        }
        if (ctrlObj.Required === true && (ctrlObj.isControlFiled === true)) {
            classname = 'success-icon';
        }
        return classname;

    }
    getIsRequiredLabel(ctrlObj: any) {
        let classname: any = 'requried-color requried-color1';
        if (ctrlObj.Required === true && (ctrlObj.isControlFiled === true)) {
            classname = 'success-color';
        }
        if (ctrlObj.isControlFiled === true) {
            classname = 'success-color';
        }
        return classname;
    }

    getInputIsRequired(ctrlObj: any) {
        let classname: any = '';
        if (ctrlObj.Required === true && ctrlObj.isControlFiled === false) {
            classname = '';
        }
        if (ctrlObj.Required === true && ctrlObj.isControlFiled === true) {
            classname = 'success-color success-icon';
        }
        return classname;
    }

    isCheckDatePopupOpen() {
        if (this.webOrMobile === 'mobile') {
            setTimeout(() => {
                // console.log('>>>>>>>.',document.querySelectorAll('ngb-datepicker'));
                let prevNextBtnAlignELe: any = document.getElementById('prevNextBtnAlignMobileContainer');
                if (document.querySelectorAll('ngb-datepicker') && document.querySelectorAll('ngb-datepicker')[0] && document.querySelectorAll('ngb-datepicker')[0].classList.value === 'dropdown-menu show') {
                    prevNextBtnAlignELe.classList.add('iphone-date-popup-align');
                } else {
                    prevNextBtnAlignELe.classList.remove('iphone-date-popup-align');
                }
            }, 50);
        }
    }

    onShowDatePicker(dateObj: any, ctrlTabindex: any, contrlObj: any) {
        for (var i = 0; i < this.mobileControlArr.length; ++i) {
            if (this.mobileControlArr[i].tabindex == ctrlTabindex) {
                this.tabIndex = i;
                break;
            }
        }
        this.hideAllControlTooltips();

        if (this.webOrMobile === 'mobile') {
            let closeMoreActionsMenu: any = document.getElementById("closeMoreActionsMenu");
            if (closeMoreActionsMenu) {
                closeMoreActionsMenu.click();
            }

            if (this.pinchZoom.pinchZoom.initialScale === 1) {
                this.pinchZoom.toggleZoom();
                setTimeout(() => {
                    this.updateControlPositions(contrlObj.ControlHtmlID);
                }, 300);
            } else {
                if (this.pinchZoom.pinchZoom.initialScale > 1) {
                    this.updateControlPositions(contrlObj.ControlHtmlID);
                }
            }

            document.getElementById('control_' + contrlObj.ControlHtmlID)!.style.border = '1px solid orange';
            setTimeout(() => {
                this.checkisLastControl(contrlObj.TabIndex);
                this.responsiveOpenModelPopup(contrlObj.ControlHtmlID);
            }, 300);
        } else {
            this.showMobilePopup = 'none';
            setTimeout(() => {
                dateObj.toggle();
            }, 50);
            document.getElementById('control_' + contrlObj.ControlHtmlID)?.blur();
        }
        document.getElementById('tooltip_' + contrlObj.ControlHtmlID)!.style.display = 'none';

        if (this.tabIndex == this.mobileControlArr.length - 1) {
            this.mobileNextBtn = 'false';
        } else {
            this.mobileNextBtn = 'true';
        }

        if (this.tabIndex == 0) {
            this.mobilePrevBtn = 'false';
        } else {
            this.mobilePrevBtn = 'true';
        }
    }

    onShowControlTooltip(contrlObj: any, selectedFromFilter: any) {
        this.hideAllControlTooltips();

        for (var i = 0; i < this.mobileControlArr.length; ++i) {
            if (this.mobileControlArr[i].tabindex == contrlObj.TabIndex) {
                this.tabIndex = i;
                break;
            }
        }

        if (this.mobileControlArr.length === 0 && this.tabIndex !== 0) {
            this.tabIndex = this.tabIndex - 1;
        }
        if (this.webOrMobile === 'web') {
            let controlEle: any = document.getElementById('control_' + contrlObj.ControlHtmlID);
            controlEle.style.outline = 'none';
            let tooltipControlEle: any = document.getElementById('tooltip_' + contrlObj.ControlHtmlID);
            if (contrlObj.ControlName === 'Signature' || contrlObj.ControlName === 'NewInitials') {
                if (controlEle.getAttribute('src') === '' || controlEle.getAttribute('src') === null || controlEle.getAttribute('src') === undefined) {
                    tooltipControlEle.style.display = 'block';
                    this.checkisLastControl(contrlObj.TabIndex);
                } else {
                    tooltipControlEle.style.display = 'none';
                }
            } else if (contrlObj.ControlName === 'Text' || contrlObj.ControlName === 'Title' ||
                contrlObj.ControlName === 'Name' || contrlObj.ControlName === 'Company' ||
                contrlObj.ControlName === 'Email') {
                let ele: any = document.getElementById('control_' + contrlObj.ControlHtmlID);
                let tooltiplEle: any = document.getElementById('tooltip_' + contrlObj.ControlHtmlID);
                if (ele.value === '' || ele.value === null || ele.value === undefined) {
                    tooltipControlEle.style.display = 'block';
                    this.checkisLastControl(contrlObj.TabIndex);
                } else {
                    let cname: any = ele.getAttribute('data-title');
                    if (contrlObj.ControlName === 'Text' && (cname === 'SSN' || cname === 'Zip')) {
                        if (ele.value.indexOf('_') > 0 || ele.value.indexOf('-') > 5) {
                            tooltipControlEle.style.display = 'block';
                            this.checkisLastControl(contrlObj.TabIndex);
                        } else {
                            tooltipControlEle.style.display = 'none';
                        }
                    } else {
                        tooltipControlEle.style.display = 'none';
                    }
                }
            } else if (contrlObj.ControlName === 'Radio' || contrlObj.ControlName === 'Checkbox') {
                if (controlEle.checked === false) {
                    tooltipControlEle.style.display = 'block';
                    this.checkisLastControl(contrlObj.TabIndex);
                } else {
                    tooltipControlEle.style.display = 'none';
                }
            } else if (contrlObj.ControlName === 'DropDown') {
                let value: any = controlEle.selectedOptions[0].innerText.trim();
                if (value !== '' && value !== undefined && value != null) {
                    tooltipControlEle.style.display = 'none';
                } else {
                    tooltipControlEle.style.display = 'block';
                    this.checkisLastControl(contrlObj.TabIndex);
                }
            }

        }

        if (this.webOrMobile === 'mobile') {
            let controlEle: any = document.getElementById('control_' + contrlObj.ControlHtmlID);
            let tooltipControlEle: any = document.getElementById('tooltip_' + contrlObj.ControlHtmlID);
            controlEle?.blur();
            this.hideAllOrangeBorder();
            localStorage.setItem('currentPagination', contrlObj.PageNo);
            let closeMoreActionsMenu: any = document.getElementById("closeMoreActionsMenu");
            if (closeMoreActionsMenu) {
                closeMoreActionsMenu.click();
            }

            if (contrlObj.ControlName === 'Signature') {
            } else if (contrlObj.ControlName === 'NewInitials') {
                let controlValue: any = document.getElementById('control_' + contrlObj.ControlHtmlID);
                let ele: any = document.getElementsByClassName('newInitialsignature') as HTMLCollectionOf<HTMLElement>;
                if (controlValue.getAttribute('src') !== null) {
                    ele[0].style.border = '1px solid #ddd';
                } else {
                    //ele[0].style.border = '1px solid orange';
                }
            } else if (contrlObj.ControlName === 'Text' || contrlObj.ControlName === 'Title' ||
                contrlObj.ControlName === 'Name' || contrlObj.ControlName === 'Company' ||
                contrlObj.ControlName === 'Email') {
                let ele: any = document.getElementById('control_' + contrlObj.ControlHtmlID);
                let tooltiplEle: any = document.getElementById('tooltip_' + contrlObj.ControlHtmlID);
                if (ele.value === '' || ele.value === null || ele.value === undefined) {
                    tooltipControlEle.style.display = 'block';
                    this.checkisLastControl(contrlObj.TabIndex);
                } else {
                    let cname: any = ele.getAttribute('data-title');
                    if (contrlObj.ControlName === 'Text' && (cname === 'SSN' || cname === 'Zip')) {
                        if (ele.value.indexOf('_') > 0 || ele.value.indexOf('-') > 5) {
                            tooltipControlEle.style.display = 'block';
                            this.checkisLastControl(contrlObj.TabIndex);
                        } else {
                            tooltipControlEle.style.display = 'none';
                        }
                    } else {
                        tooltipControlEle.style.display = 'none';
                    }
                }
            } else if (contrlObj.ControlName === 'Radio' || contrlObj.ControlName === 'Checkbox') {
                if (controlEle.checked === false) {
                    tooltipControlEle.style.display = 'block';
                    this.checkisLastControl(contrlObj.TabIndex);
                } else {
                    tooltipControlEle.style.display = 'none';
                }
            } else if (contrlObj.ControlName === 'DropDown') {
                let value: any = controlEle.selectedOptions[0].innerText.trim();
                if (value !== '' && value !== undefined && value != null) {
                    tooltipControlEle.style.display = 'none';
                } else {
                    tooltipControlEle.style.display = 'block';
                    this.checkisLastControl(contrlObj.TabIndex);
                }
            } else {
                if (contrlObj.ControlName !== 'Signature' && contrlObj.ControlName !== 'NewInitials') {
                    document.getElementById('control_' + contrlObj.ControlHtmlID)!.style.border = '1px solid orange';
                }
            }

            this.checkScrollCountAndUpdatePopup(contrlObj, selectedFromFilter);

        }

        if (this.remainingFieldsCount !== 0) {
            if (this.tabIndex == 0) {
                this.mobilePrevBtn = 'false';
                this.mobileNextBtn = 'true';
            }
            else if (this.tabIndex == this.mobileControlArr.length - 1) {
                this.mobilePrevBtn = 'true';
                this.mobileNextBtn = 'false';
            }
            else if (this.tabIndex != this.mobileControlArr.length - 1) {
                let arr: any = this.mobileControlArr[this.tabIndex];
                if (arr) {
                    if (this.mobileControlArr.length && document.getElementById(arr.controlId.replace('control_', ''))!.style.display == "none") {
                        this.mobilePrevBtn = 'true';
                        this.mobileNextBtn = 'false';
                    }
                    else {
                        this.mobilePrevBtn = 'true';
                        this.mobileNextBtn = 'true';
                    }
                }
            }
            else {
                this.mobilePrevBtn = 'true';
                this.mobileNextBtn = 'true';
            }
        } else {
            this.hideAllMobilePrevNextDoneBtns();
        }
    }

    //tooltip control positions
    updateControlPositions(ctrlId: any) {
        this.commonService.setPinchZoomScale(this.pinchZoom);
        const zoomStyles: any = getComputedStyle(document.getElementsByClassName('document-controls-zoom')[0]);
        // let zoom: any = zoomStyles.getPropertyValue('zoom');
        let zoom: any = zoomStyles.getPropertyValue('transform').match(/-?[\d\.]+/g)[0];
        let ele: any = document.getElementById(ctrlId.replace('control_', ''))!;
        let posControl: any = ele;
        let pageHeightVal: any = 0;
        let pageEle: any;
        let pageNum: any;
        let currentControlPageNoVal: any = ele.getAttribute('data-page');
        if (currentControlPageNoVal) {
            this.onUpdatePageScrollAndCount(parseInt(currentControlPageNoVal));
        }
        Array.from(document.getElementsByClassName("imgControl") as HTMLCollectionOf<HTMLElement>)
            .forEach((page: any, pageIndex: any) => {
                pageEle = document.getElementById(page.id)!;
                pageNum = pageEle.getAttribute('page-no');
                let currentControlPageNo: any = ele.getAttribute('data-page');
                if ((pageIndex + 1) < parseInt(currentControlPageNo)) {
                    pageHeightVal = pageHeightVal + pageEle.offsetHeight;
                }
            })

        let classTag: any = document.getElementsByClassName('pinch-zoom-content') as HTMLCollectionOf<HTMLElement>;
        let pinchZoomScaleVal: any = this.pinchZoom.pinchZoom.initialScale;
        let left: any = parseFloat(posControl.style.left.split('px')[0]) * parseFloat(zoom);
        //let top: any = pageHeightVal + (parseFloat(posControl.style.top.split('px')[0]) * parseFloat(zoom));
        let top: any = (parseFloat(posControl.style.top.split('px')[0]) * parseFloat(zoom));
        let finaltLeftVal: any;// = left; //(left / pinchZoomScaleVal) * parseFloat(zoom)
        if (left > 10) {
            finaltLeftVal = -left;
        } else {
            finaltLeftVal = 20;
        }

        this.pinchZoom.pinchZoom.moveX = finaltLeftVal;
        this.pinchZoom.pinchZoom.moveY = -top;
        this.pinchZoom.pinchZoom.initialMoveX = this.pinchZoom.pinchZoom.moveX;
        this.pinchZoom.pinchZoom.initialMoveY = this.pinchZoom.pinchZoom.moveY;
        this.pinchZoom.pinchZoom.startX = left;
        this.pinchZoom.pinchZoom.startY = top;

        //console.log('final left and top', left, top);
        //classTag[0].style.transform = "matrix(" + Number(pinchZoomScaleVal) + ", 0, 0, " + Number(pinchZoomScaleVal) + ", " + Number(-this.pinchZoom.pinchZoom.startX) + ", " + Number(-this.pinchZoom.pinchZoom.startY) + ")";
        classTag[0].style.transform = "matrix(" + Number(pinchZoomScaleVal) + ", 0, 0, " + Number(pinchZoomScaleVal) + ", " + finaltLeftVal + ", " + -top + ")";
        this.cdRef.detectChanges();
    }

    onCloseTooltip(contrlId: any) {
        document.getElementById('tooltip_' + contrlId)!.style.display = 'none';
    }

    onPreviewPagesView() {
        this.getPagesListAndTotalpages();
        this.hidePagesViewSection = !this.hidePagesViewSection;
    }

    closeMoreActionsMenu() {
        let closeMoreActionsMenu: any = document.getElementById("closeMoreActionsMenu");
        closeMoreActionsMenu.click();
        this.removeBodyTagPositionFixed();
    }

    onMobilePreviewPagesView() {
        this.getPagesListAndTotalpages();
        this.commonService.userSource$.subscribe(data => {
            this.$documentImg = data;
        })
        setTimeout(() => {
            this.getPreviewSelectedColor(this.$documentImg);
            this.getPreviewSelected(this.$documentImg);
        }, 500);
    }

    onWebPreviewPagesView() {
        this.commonService.userSource$.subscribe(data => {
            this.$documentImg = data;
        })
        setTimeout(() => {
            this.getPreviewSelectedColor(this.$documentImg);
            this.getPreviewSelected(this.$documentImg);
        }, 500);
    }

    getPagesListAndTotalpages() {
        if (this.documentControlsResponse && this.documentControlsResponse.EnvelopeImageControlData) {
            this.pageViewArr = this.documentControlsResponse.EnvelopeImageControlData;
            this.pagePreviewTotalPages = this.pageViewArr.length;
        }
    }

    //on zoom in
    onZoomIn() {
        if (this.imageSize < 2) {
            this.imageSize = this.imageSize + this.zoomingVal;
            this.imageSize = parseFloat(this.imageSize.toFixed(2));
            // console.log('zoomin', this.imageSize);
            this.percentageAndToastr(this.imageSize);
        }
    }

    //on zoom out
    onZoomOut() {
        if (this.imageSize > 1) {
            this.imageSize = this.imageSize - this.zoomingVal;
            this.imageSize = parseFloat(this.imageSize.toFixed(2));
            // console.log('zoomout', this.imageSize);
            this.percentageAndToastr(this.imageSize);
        }
    }

    getClass() {
        let className = 'col-lg-8 col-md-8 col-xs-8 col-sm-8';
        if (this.isFavorite === false) {
            className = '';
        }
        return className;
    }

    getLeftSideClass() {
        let className = 'col-lg-2 col-md-2 col-xs-2 col-sm-2';
        if (this.isFavorite === false) {
            className = '';
        }
        return className;
    }

    getRightSideClass() {
        let className = 'col-lg-2 col-md-2 col-xs-2 col-sm-2';
        if (this.isFavorite === false) {
            className = '';
        }
        return className;
    }

    //from dropdown to selcet particular percentage
    onPercentageChange(val: any) {
        // console.log('onPercentageChange', val)
        if (val === -1) {
            this.isFavorite = false;
            this.imageSize = 1;
            this.percentageAndToastr(this.imageSize);
            //this.hideFilterSection = true;
            // this.hidePagesViewSection = true;
            this.documentWidth = window.outerWidth - 20 + 'px';
        } else {
            this.isFavorite = true;
            //this.hideFilterSection = true;
            // this.hidePagesViewSection = true;
            this.imageSize = parseFloat(val);
            this.percentageAndToastr(this.imageSize);
        }
    }

    //common function for both percentage value and toastr message
    percentageAndToastr(imgSize: any) {
        // this.renderer2.setStyle(this.documentPageContainer.nativeElement, 'zoom', imgSize);
        if (imgSize == 1.1) {
            this.toastr.success('Zoom', 110 + '%');
            this.percentageValue = '110%';
        } else {
            this.toastr.success('Zoom', imgSize * 100 + '%');
            this.percentageValue = imgSize * 100 + '%';
        }

        let positionAbsolute: any = '';
        let paddingBottom: any = '';
        // let transformOrigin: any = 'top center';
        let footerClass: any = document.getElementsByClassName('footer');
        let documentPageClass = document.getElementsByClassName('documentPage');
        let subdocumentClass = document.getElementsByClassName('subdocument');
        if (footerClass && footerClass[0]) {
            footerClass[0].style.bottom = '-130px';
            footerClass[0].style.position = 'relative';
        }
        if (documentPageClass && documentPageClass[0]) {
            documentPageClass[0].classList.remove(
                // 'paddingBottom50',
                // 'paddingBottom75',
                // 'paddingBottom125',
                'paddingBottom150',
                // 'paddingBottom175',
                'paddingBottom200'
            );
        }
        if (subdocumentClass && subdocumentClass[0]) {
            subdocumentClass[0].classList.remove('marginLeft150', 'marginLeft200');
        }

        let zoomOutbtn: any = document.getElementById('zoomOutbtn');
        let zoomInbtn: any = document.getElementById('zoomInbtn');
        if (this.percentageValue === '150%') {
            if (footerClass && footerClass[0]) {
                footerClass[0].style.bottom = '0px';
                footerClass[0].style.position = 'fixed';
                footerClass[0].style.width = '100%';
            }
            if (subdocumentClass && subdocumentClass[0]) {
                subdocumentClass[0].classList.add('marginLeft150');
            }
            if (documentPageClass && documentPageClass[0]) {
                setTimeout(() => {
                    documentPageClass[0].classList.add('paddingBottom150');
                }, 300);
            }
            this.hidePagesViewSection = true;
            if (zoomOutbtn) {
                zoomOutbtn.removeAttribute('disabled');
            }
            if (zoomInbtn) {
                zoomInbtn.removeAttribute('disabled');
            }
        } else if (this.percentageValue === '200%') {
            if (footerClass && footerClass[0]) {
                footerClass[0].style.bottom = '0px';
                footerClass[0].style.position = 'fixed';
                footerClass[0].style.width = '100%';
            }
            if (subdocumentClass && subdocumentClass[0]) {
                subdocumentClass[0].classList.add('marginLeft200');
            }
            if (documentPageClass && documentPageClass[0]) {
                setTimeout(() => {
                    documentPageClass[0].classList.add('paddingBottom200');
                }, 300);
            }
            this.hidePagesViewSection = true;
            if (zoomOutbtn) {
                zoomOutbtn.removeAttribute('disabled');
            }
            if (zoomInbtn) {
                zoomInbtn.setAttribute('disabled', 'disabled');
            }
        } else {
            this.hidePagesViewSection = false;
            if (zoomOutbtn) {
                zoomOutbtn.setAttribute('disabled', 'disabled');
            }
            if (zoomInbtn) {
                zoomInbtn.removeAttribute('disabled');
            }
        }

        this.renderer2.setStyle(this.documentPageContainer.nativeElement, 'transform', 'scale(' + imgSize + ')');
        //this.renderer2.setStyle(this.documentPageContainer.nativeElement,'transform-origin',transformOrigin,RendererStyleFlags2.Important + RendererStyleFlags2.DashCase);
        this.renderer2.setStyle(this.documentPageContainer.nativeElement, 'position', positionAbsolute);
    }

    hideAndShowFilterSection() {
        this.hideFilterSection = !this.hideFilterSection;
    }

    //open modal popup
    openModal(modalVal: any) {
        this.closeCanvasMenu();
        if (this.checkDocumentControlsResponselocal()) {
            this.commonService.checkIsUrlExistsInBrowserMemory();
            return;
        }
        else {
            if (modalVal === 'Add Signature') {
                let userData: any = this.documentControlsResponse.userdata;
                let isAcceptAndSignClicked: any = localStorage.getItem("isAcceptAndSignClicked");
                if (isAcceptAndSignClicked && isAcceptAndSignClicked.toLowerCase() == "true") {
                    this.clickToSignPopupStyle = 'block';
                    this.addModelPopupClassForBodyTag();
                    let txtsignerProfileName: any = document.getElementById("txtsignerProfileName");
                    txtsignerProfileName.value = localStorage.getItem("txtsignerProfileNameValue");
                    let txtsignerProfileInitials: any = document.getElementById("txtsignerProfileInitials");
                    txtsignerProfileInitials.value = localStorage.getItem("txtsignerProfileInitialsValue");

                    let profileDetails: any;
                    profileDetails = this.getCurrentSessionUserDtls();

                    if (profileDetails != null && profileDetails.length > 0 && profileDetails[0].AcceptandSign != 0 && this.isNullOrEmptyCheck(profileDetails[0].imgProfileSignaturerc)) {
                        if (this.isNullOrEmptyCheck(profileDetails[0].imgProfileSignaturerc)) {
                            this.bindsignerPopupDtls();
                            return;
                        }
                    }
                    else {
                        this.UpdateSignimageFromSignerProfileText(0);
                        this.UpdateInitialsimageFromSignerProfileText();
                    }
                    this.updateSignerProfilePopupheight();
                }
                else if (this.isNullOrEmptyCheck(userData)) {
                    this.clickToSignPopupStyle = 'block';
                    this.addModelPopupClassForBodyTag();
                    this.updateSignerProfilePopupheight();

                    this.bindDataForSignerProfile(userData);
                }
                else {
                    this.clickToSignPopupStyle = 'block';
                    this.addModelPopupClassForBodyTag();
                    this.updateSignerProfilePopupheight();
                }

                let btnAccept: any = document.getElementById("btnAccept");
                let isTermsDisabled: any = localStorage.getItem("isDisabled");
                let chkterms: any = document.getElementById("chkterms");
                if (isTermsDisabled) {
                    chkterms?.setAttribute('disabled', 'disabled');
                    chkterms?.setAttribute('checked', 'checked');
                    this.clicktoSignAgree = true;
                    chkterms.style.cursor = "no-drop";
                    btnAccept?.removeAttribute('disabled');
                }
                else {
                    chkterms?.removeAttribute('disabled');
                    chkterms?.removeAttribute('checked');
                    this.clicktoSignAgree = false;
                    chkterms.style.cursor = "pointer";
                    btnAccept?.setAttribute('disabled', 'disabled');
                }
            }
            else if (modalVal === 'Attachments') {
                const modalRef = this.modalService.open(this.getModalPopup(modalVal), { windowClass: 'attachment-class', keyboard: false, backdrop: 'static', });
                modalRef.componentInstance.attachmentList = this.attachmentList;
                modalRef.componentInstance.attachmentListCount = this.attachmentListCount;
                localStorage.setItem("IsGroupTemplateSignerAttachments", "false");

                modalRef.componentInstance.event.subscribe((attachObj: any) => {
                    this.attachmentList = attachObj.attchlist;
                    this.attachmentListCount = attachObj.attchlistCount;
                    this.cdRef.detectChanges();
                });
            }
            else if (modalVal === 'Decline') {
                this.modalService.open(this.getModalPopup(modalVal), { windowClass: 'decline-class', keyboard: false, backdrop: 'static', });
            }
            else if (modalVal === 'Invite Signers') {
                const modalRef = this.modalService.open(this.getModalPopup(modalVal), { windowClass: 'invite-Signers', keyboard: false, backdrop: 'static', });
            }
            else if (modalVal === 'Change Signer') {
                let isToDisable: boolean = this.isDisabledChangeSigner();
                if (!isToDisable) {
                    let modalPopupObj = {
                        envelopeID: this.EnvelopeID,
                        ipAddress: this.ipAddress,
                        recipientID: localStorage.getItem("RecipientID"),
                        isMultiTemplate: false
                    }
                    const modalRef = this.modalService.open(this.getModalPopup(modalVal), { windowClass: 'changeSigner-modal', keyboard: false, backdrop: 'static', });
                    modalRef.componentInstance.modalPopupObj = modalPopupObj;
                }
            }
            else {
                let modalPopupObj = {
                    envelopeID: this.EnvelopeID,
                    ipAddress: this.ipAddress,
                    recipientID: ''
                }
                const modalRef = this.modalService.open(this.getModalPopup(modalVal));
                modalRef.componentInstance.modalPopupObj = modalPopupObj;
            }
        }
    }

    //Returns the modal component name based on modal string
    getModalPopup(val: any) {
        var retVal = null;
        switch (val) {
            case 'Decline':
                retVal = DeclineModalComponent;
                break;
            case 'Attachments':
                retVal = AttachmentsComponent;
                break;
            case 'Invite Signers':
                retVal = InviteSignersComponent;
                break;
            case 'Change Signer':
                retVal = ChangeSignerModalComponent;
                break;
        }
        return retVal;
    }

    getdefaultSelectedColor(isActiveFlag: any) {
        if (isActiveFlag === 1) {
            let prevPageEle: any = document.getElementById('pagePreview1');
            if (prevPageEle && prevPageEle.classList) {
                prevPageEle.classList.add('active-preview-card');
            }

            let prevPageCountEle: any = document.getElementById('pageCountColor1');
            if (prevPageCountEle && prevPageCountEle.classList) {
                prevPageCountEle.classList.add('active-preview');
            }

        }
    }

    getPreviewSelectedColor(p: any) {
        Array.from(document.getElementsByClassName("prevImg") as HTMLCollectionOf<HTMLElement>)
            .forEach((pcard: any, index: any) => {
                let ele: any = document.getElementById(pcard.id);
                if (ele.classList.contains('active-preview-card')) {
                    ele.classList.remove('active-preview-card')
                }
            })

        let prevPageEle: any = document.getElementById('pagePreview' + p);
        if (prevPageEle && prevPageEle.classList) {
            prevPageEle.classList.add('active-preview-card');
        }
        this.getPreviewSelected(p);
    }

    getPreviewSelected(p: any) {
        localStorage.setItem('currentPagination', p);
        Array.from(document.getElementsByClassName("thumbnails-page-btn") as HTMLCollectionOf<HTMLElement>)
            .forEach((pcard: any, index: any) => {
                let ele: any = document.getElementById(pcard.id);
                if (ele.classList.contains('active-preview')) {
                    ele.classList.remove('active-preview')
                }
            })

        let prevPageEle: any = document.getElementById('pageCountColor' + p);
        if (prevPageEle && prevPageEle.classList) {
            prevPageEle.classList.add('active-preview');
        }
    }

    // download pdf
    onDownload() {
        this.showLoader = true;
        let envelopeId = localStorage.getItem('EnvelopeID');
        let recipientId = localStorage.getItem('RecipientID')
        let url: any = this.commonConstants.GetDownloadPDFPreview + '/' + envelopeId + '/' + recipientId;
        this.signerLandingService.getDownloadPDFPreview(url).subscribe(
            (resp: any) => {
                this.showLoader = false;
                //console.log('res', resp)
                let base64FileData: any = resp.Base64FileData;
                if (base64FileData) {
                    this.toastr.success('Downloaded successfully.', 'Success', { timeOut: 2000 });
                    var blob = this.commonService.b64toBlob(base64FileData, 'application/pdf');
                    let a = document.createElement('a');
                    document.body.appendChild(a);
                    let bloburl: any = window.URL.createObjectURL(blob);
                    a.href = bloburl;
                    a.download = String(resp.FileName);
                    a.click();
                    window.URL.revokeObjectURL(bloburl);
                    a.remove();
                }
                this.cdRef.detectChanges();
            });
    }

    onHelp() {
        window.open(this.commonConstants.HelpAndSupport);
    }

    onSignupROneRedirection() {
        window.open(this.commonConstants.SignupROne);
    }

    onTermsOfServiceDownload() {
        let envelopeId = localStorage.getItem('EnvelopeID');
        let isStaticTemplate = false;

        let url: any = this.commonConstants.DownloadDisclaimerPDF + '/' + envelopeId + '/' + isStaticTemplate;
        this.showLoader = true;
        this.signerLandingService.getDownloadDisclaimerPDF(url).subscribe(
            (resp: any) => {
                this.showLoader = false;
                //console.log('res', resp)
                let termsOfServiceBase64: any = resp.byteData;
                if (termsOfServiceBase64) {
                    this.toastr.success('Downloaded successfully.', 'Success', { timeOut: 2000 });
                    let contentType: any = "application/pdf";
                    if ((/iPhone/i.test(navigator.userAgent))) {
                        contentType = "application/octet-stream";
                    }
                    var blob = this.commonService.b64toBlob(termsOfServiceBase64, contentType);
                    let a = document.createElement('a');
                    document.body.appendChild(a);
                    let bloburl: any = (window.URL || window.webkitURL).createObjectURL(blob);
                    a.href = bloburl;
                    a.download = String(resp.FileName);
                    a.click();
                    (window.URL || window.webkitURL).revokeObjectURL(bloburl);
                    a.remove();

                }
                this.cdRef.detectChanges();
            });
    }

    ngOnDestroy() {
        this.signDestroy$.next();
        this.signDestroy$.complete();
        this.initDestroy$.next();
        this.initDestroy$.complete();
    }

    //Toggle Filter
    toggle() {
        this.show = !this.show;
        // Change the name of the button.
        if (this.show) {
            this.buttonName = "Hide";
        } else {
            this.buttonName = "Show";
        }
    }

    onCloseFilter() {
        this.hideFilter = !this.hideFilter;
        if (this.hideFilter) {
            this.hideFilter = true;
        } else {
            this.hideFilter = false;

        }
    }

    onDateSelected(event: any, ctrlObj: any, formateType: any, dControl: any) {
        let dateVal = this.getDateFormat(event, formateType, this.locale);
        if (dateVal) {
            (<HTMLInputElement>document.getElementById('control_' + ctrlObj.ControlHtmlID)).value = dateVal;
            document.getElementById('control_' + ctrlObj.ControlHtmlID)!.style.outline = 'none';
            this.updateChecklist(ctrlObj.ControlHtmlID);
        }
        else {
            let controlId: any = document.getElementById('control_' + ctrlObj.ControlHtmlID)!.parentNode;
            controlId.classList.add('filledControls');
            this.updateChecklist(ctrlObj.ControlHtmlID);
        }
        dControl.close();
        setTimeout(() => {
            this.getControlNextNav('');
        }, 150);
    }

    onMobileDateSelected(event: any, dateControl: any) {
        let hdnDataCtnType: any = document.getElementById('hdnDataCtnType');
        let formateType: any = hdnDataCtnType.value;
        let hdnMaximizeControlId: any = document.getElementById('hdnMaximizeControlId');
        let controlHtmlID = hdnMaximizeControlId.value;
        let datetype = localStorage.getItem("datetype");

        let dateVal = this.getDateFormat(event, formateType, this.locale);
        if (dateVal) {
            if (datetype == "0") {
                (<HTMLInputElement>document.getElementById('txtMaximizeControlTextDate')).value = dateVal;
                document.getElementById('txtMaximizeControlTextDate')!.style.outline = 'none';
            }
            else if (datetype == "1") {
                (<HTMLInputElement>document.getElementById('txtMaximizeControlDate')).value = dateVal;
                document.getElementById('txtMaximizeControlDate')!.style.outline = 'none';
            }
            else {
                (<HTMLInputElement>document.getElementById('txtMaximizeControlTextDate')).value = dateVal;
                (<HTMLInputElement>document.getElementById('txtMaximizeControlDate')).value = dateVal;
                document.getElementById('txtMaximizeControlTextDate')!.style.outline = 'none';
                document.getElementById('txtMaximizeControlDate')!.style.outline = 'none';
            }
            this.updateChecklist(controlHtmlID);
        }
        else {

            if (datetype == "0") {
                let controlId: any = document.getElementById('txtMaximizeControlTextDate')!.parentNode;
                controlId.classList.add('filledControls');
            }
            else if (datetype == "1") {
                let controlId: any = document.getElementById('txtMaximizeControlDate')!.parentNode;
                controlId.classList.add('filledControls');
            }
            else {
                let controlId1: any = document.getElementById('txtMaximizeControlTextDate')!.parentNode;
                controlId1.classList.add('filledControls');
                let controlId2: any = document.getElementById('txtMaximizeControlDate')!.parentNode;
                controlId2.classList.add('filledControls');
            }
            this.updateChecklist(controlHtmlID);
        }
        dateControl.close();
        setTimeout(() => {
            this.getControlNextNav('');
        }, 150);
    }

    addFilledfilledControlsClass(ctrlObj: any) {
        let controlele: any = document.getElementById('control_' + ctrlObj.ControlHtmlID)!
        let className = 'filledControls';
        if (ctrlObj.ControlName.toLowerCase() === 'checkbox') {
            if (controlele.checked === true) {
                let controlId: any = controlele.parentNode.parentNode;
                controlId.classList.add(className);
            } else {
                let controlId: any = controlele.parentNode.parentNode;
                controlId.classList.remove(className);
            }
        }
        else {
            if (controlele.value.trim() != '' && controlele.value.length > 0) {
                let isValid: boolean = false;
                let datatitle: any = controlele.getAttribute("data-title");
                if (datatitle == "SSN" || datatitle == "Zip") {
                    if (controlele.value.indexOf('_') > 0 || controlele.value.indexOf('_') > -1 || controlele.value.indexOf('-') > 5) {
                        isValid = true;
                    }
                    if (controlele.value == "_____" || controlele.value == "___-__-____") {
                        controlele.value = "";
                    }
                }
                let controlId: any = controlele.parentNode;
                if (isValid) {
                    controlId.classList.remove(className);
                }
                else {
                    controlId.classList.add(className);
                }
            }
            else {
                let controlId: any = controlele.parentNode;
                controlId.classList.remove(className);
            }
        }
    }

    //closing date picker popup
    closeFix(event: any, datePicker: any, ctrlObj: any) {
        if (event.target.offsetParent == null) {
            datePicker.close();
        } else if (event.target.offsetParent.nodeName != "NGB-DATEPICKER") {
            datePicker.close();
            // this.validateInputText(true, control)
        }
    }

    updateChecklist(ControlHtmlID: any) {
        if ((<HTMLInputElement>document.getElementById('control_' + ControlHtmlID)).value != "") {
            this.applyStylesToCheckList("add", ControlHtmlID);
            document.getElementById('control_' + ControlHtmlID)!.style.border = '2px solid #38b16e';
        }
        else {
            this.applyStylesToCheckList("remove", ControlHtmlID)
        }

        this.getTotalRemaingFieldsControls('');
    }

    getDateFormat(d: NgbDateStruct, df: any, locale: any) {
        //console.log('getDateFormat', d, df, locale);
        var dateRetVal = null;
        let day: any = d.day
        let month: any = d.month;
        day = (d.day <= 9) ? '0' + d.day : day;
        month = (d.month <= 9) ? '0' + d.month : month;

        switch (df) {
            case 'dd/mm/yyyy':
                dateRetVal = '' + day + '/' + month + '/' + d.year; // '01/09/2023'
                break;
            case 'mm/dd/yyyy':
                dateRetVal = '' + month + '/' + day + '/' + d.year; // '01/09/2023'
                break;
            case 'mm-dd-yyyy':
                dateRetVal = '' + month + '-' + day + '-' + d.year; //'01-09-2023'
                break;
            case 'mm.dd.yyyy':
                dateRetVal = '' + month + '.' + day + '.' + d.year; //'01.09.2023'
                break;
            case 'dd-mmm-yyyy':
                let monthsArr = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
                dateRetVal = '' + day + '-' + monthsArr[d.month] + '-' + d.year;
                break;
            case 'dd-mm-yyyy':
                dateRetVal = '' + day + '-' + month + '-' + d.year;
                break;
            case 'dd.mm.yyyy':
                dateRetVal = '' + day + '.' + month + '.' + d.year;
                break;
            case 'yyyy.mm.dd.':
                dateRetVal = '' + d.year + '.' + month + '.' + day + '.';
                break;
        }
        return dateRetVal;
    }

    onClearAll() {
        this.requiredFlag = false;
        this.fieldsRemainingFlag = false;
        this.searchTerm = '';
        this.searchTermDisplay = '';
        this.onFilterApply();
    }

    getAllVisibleDocumentControls() {
        let controls: any = [];
        let popupCollection: any = [];
        this.mobileControlArr = [];
        this.mobileControlArrReverse = [];

        Array.from(document.getElementsByClassName("imgControl") as HTMLCollectionOf<HTMLElement>)
            .forEach((imgItem: any) => {
                popupCollection = imgItem.getElementsByClassName('popUp');
                Array.from(popupCollection).forEach((popUpItem: any) => {
                    let isreadonly = false;
                    let inputTag = popUpItem.getElementsByTagName("input");
                    if (inputTag[0] != undefined) {
                        isreadonly = inputTag[0].attributes["readonly"];
                        if (isreadonly == undefined)
                            isreadonly = false;
                        else
                            isreadonly = true;

                        if (this.webOrMobile === 'mobile' && this.showMobilePopup === 'block' && inputTag[0].id === 'control_' + this.popuptempArr[0]) {
                            isreadonly = false;
                        }
                    }

                    let idSplit: any = popUpItem.attributes["id"].value;
                    let ele: any = document.getElementById('control_' + idSplit);
                    let nameCtrl: any = document.getElementById(idSplit);

                    let cntType = popUpItem.attributes["data-ctntype"].value;
                    let cntRequired = popUpItem.attributes["data-rq"].value;
                    if (this.searchTerm != '') {
                        if (cntType == this.searchTerm && window.getComputedStyle(popUpItem).display !== 'none' && !isreadonly && cntType != "Label" && cntType != "Hyperlink" && cntType != "DateTimeStamp")
                            controls.push(popUpItem);
                    }
                    else {
                        if (window.getComputedStyle(popUpItem).display !== 'none' && !isreadonly && cntType != "Label" && cntType != "Hyperlink" && cntType != "DateTimeStamp") {
                            controls.push(popUpItem);
                        }
                    }
                    if (window.getComputedStyle(popUpItem).display !== 'none' && !isreadonly && cntType != "Label" && cntType != "Hyperlink" && cntType != "DateTimeStamp") {
                        this.mobileControlArr.push({ 'tabindex': ele.getAttribute('tabindex'), 'controlId': 'control_' + idSplit, 'controlName': nameCtrl.getAttribute('data-ctntype'), 'required': cntRequired });
                        this.mobileControlArrReverse.push({ 'tabindex': ele.getAttribute('tabindex'), 'controlId': 'control_' + idSplit, 'controlName': nameCtrl.getAttribute('data-ctntype'), 'required': cntRequired });
                    }
                });
            });
        if (this.mobileControlArrReverse.length > 0) {
            this.mobileControlArrReverse = this.mobileControlArrReverse.reverse();
        }
        return controls;
    }

    onFilterApply() {
        setTimeout(() => {
            let controls: any = this.getAllVisibleDocumentControls();
            let filterCount: any = 0;
            let controlCollection: any = [];
            Array.from(controls).forEach((ctrlItem: any) => {
                let ctrlId = ctrlItem.attributes["id"].value;
                let controlType = ctrlItem.attributes["data-ctntype"].value;
                let ctrlReq: any;
                let ctrlValue: any;
                switch (controlType) {
                    case "Signature":
                        let sigImageCollection = ctrlItem.getElementsByTagName('img');
                        ctrlReq = ctrlItem.attributes["data-rq"] != undefined ? Boolean(ctrlItem.attributes["data-rq"].value) : false;
                        ctrlValue = sigImageCollection[0].attributes["src"] != undefined ? sigImageCollection[0].attributes["src"].value : '';

                        if (this.requiredFlag === true && this.fieldsRemainingFlag === true) {
                            if (ctrlReq == true && ctrlValue == '') {
                                controlCollection.push(ctrlId);
                            }
                        }
                        else if (this.requiredFlag === true && this.fieldsRemainingFlag === false) {
                            if (ctrlReq == true) {
                                controlCollection.push(ctrlId);
                            }
                        }
                        else if (this.requiredFlag === false && this.fieldsRemainingFlag === true) {
                            if (ctrlValue == '') {
                                controlCollection.push(ctrlId);
                            }
                        }
                        else if (this.requiredFlag === false && this.fieldsRemainingFlag === false) {
                            controlCollection.push(ctrlId);
                        }
                        break;
                    case "NewInitials":
                        let initialImageCollection = ctrlItem.getElementsByTagName('img');
                        ctrlReq = ctrlItem.attributes["data-rq"] != undefined ? Boolean(ctrlItem.attributes["data-rq"].value) : false;
                        ctrlValue = initialImageCollection[0].attributes["src"] != undefined ? initialImageCollection[0].attributes["src"].value : '';

                        if (this.requiredFlag === true && this.fieldsRemainingFlag === true) {
                            if (ctrlReq == true && ctrlValue == '') {
                                controlCollection.push(ctrlId);
                            }
                        }
                        else if (this.requiredFlag === true && this.fieldsRemainingFlag === false) {
                            if (ctrlReq == true) {
                                controlCollection.push(ctrlId);
                            }
                        }
                        else if (this.requiredFlag === false && this.fieldsRemainingFlag === true) {
                            if (ctrlValue == '') {
                                controlCollection.push(ctrlId);
                            }
                        }
                        else if (this.requiredFlag === false && this.fieldsRemainingFlag === false) {
                            controlCollection.push(ctrlId);
                        }
                        break;
                    case "Text":
                        let currentTextCtrl: any = document.getElementById("control_" + ctrlId);
                        let currentTextCtrlTitle: any = currentTextCtrl.attributes['data-title'] != undefined ? currentTextCtrl.attributes['data-title'].value : '';
                        if (currentTextCtrlTitle === 'Date') {
                            ctrlValue = currentTextCtrl.value != undefined ? currentTextCtrl.value : '';
                        }
                        else if (currentTextCtrlTitle === 'Email') {
                            ctrlValue = currentTextCtrl.value != undefined ? currentTextCtrl.value : '';
                        }
                        else {
                            ctrlValue = ctrlItem.getElementsByTagName("textarea") != undefined ? ctrlItem.getElementsByTagName("textarea")[0].value : '';
                        }
                        ctrlReq = ctrlItem.attributes["data-rq"] != undefined ? Boolean(ctrlItem.attributes["data-rq"].value) : false;
                        ctrlValue = ctrlValue != undefined ? ctrlValue : '';

                        if (this.requiredFlag === true && this.fieldsRemainingFlag === true) {
                            if (ctrlReq == true && ctrlValue == '') {
                                controlCollection.push(ctrlId);
                            }
                        }
                        else if (this.requiredFlag === true && this.fieldsRemainingFlag === false) {
                            if (ctrlReq == true) {
                                controlCollection.push(ctrlId);
                            }
                        }
                        else if (this.requiredFlag === false && this.fieldsRemainingFlag === true) {
                            if (ctrlValue == '') {
                                controlCollection.push(ctrlId);
                            }
                        }
                        else if (this.requiredFlag === false && this.fieldsRemainingFlag === false) {
                            controlCollection.push(ctrlId);
                        }
                        break;
                    case "Email":
                        let currEmailCtrl: any = document.getElementById("control_" + ctrlId);
                        ctrlValue = currEmailCtrl.value != undefined ? currEmailCtrl.value : '';
                        ctrlReq = ctrlItem.attributes["data-rq"] != undefined ? Boolean(ctrlItem.attributes["data-rq"].value) : false;
                        ctrlValue = ctrlValue != undefined ? ctrlValue : '';

                        if (this.requiredFlag === true && this.fieldsRemainingFlag === true) {
                            if (ctrlReq == true && ctrlValue == '') {
                                controlCollection.push(ctrlId);
                            }
                        }
                        else if (this.requiredFlag === true && this.fieldsRemainingFlag === false) {
                            if (ctrlReq == true) {
                                controlCollection.push(ctrlId);
                            }
                        }
                        else if (this.requiredFlag === false && this.fieldsRemainingFlag === true) {
                            if (ctrlValue == '') {
                                controlCollection.push(ctrlId);
                            }
                        }
                        else if (this.requiredFlag === false && this.fieldsRemainingFlag === false) {
                            controlCollection.push(ctrlId);
                        }
                        break;
                    case "Name":
                    case "Date":
                    case "Initials":
                    case "Title":
                    case "Company":
                        let currCtrl: any = document.getElementById("control_" + ctrlId);
                        ctrlValue = currCtrl.value != undefined ? currCtrl.value : '';
                        ctrlReq = ctrlItem.attributes["data-rq"] != undefined ? Boolean(ctrlItem.attributes["data-rq"].value) : false;
                        ctrlValue = ctrlValue != undefined ? ctrlValue : ''

                        if (this.requiredFlag === true && this.fieldsRemainingFlag === true) {
                            if (ctrlReq == true && ctrlValue == '') {
                                controlCollection.push(ctrlId);
                            }
                        }
                        else if (this.requiredFlag === true && this.fieldsRemainingFlag === false) {
                            if (ctrlReq == true) {
                                controlCollection.push(ctrlId);
                            }
                        }
                        else if (this.requiredFlag === false && this.fieldsRemainingFlag === true) {
                            if (ctrlValue == '') {
                                controlCollection.push(ctrlId);
                            }
                        }
                        else if (this.requiredFlag === false && this.fieldsRemainingFlag === false) {
                            controlCollection.push(ctrlId);
                        }
                        break;
                    case "Checkbox":
                        let curCheckboxctrl: any = document.getElementById("control_" + ctrlId);
                        let isChecked: string = "false";
                        isChecked = curCheckboxctrl.checked ? "true" : "false";
                        ctrlReq = ctrlItem.attributes["data-rq"] != undefined ? Boolean(ctrlItem.attributes["data-rq"].value) : false;
                        ctrlValue = isChecked;

                        if (this.requiredFlag === true && this.fieldsRemainingFlag === true) {
                            if (ctrlReq == true && ctrlValue == 'false') {
                                controlCollection.push(ctrlId);
                            }
                        }
                        else if (this.requiredFlag === true && this.fieldsRemainingFlag === false) {
                            if (ctrlReq == true) {
                                controlCollection.push(ctrlId);
                            }
                        }
                        else if (this.requiredFlag === false && this.fieldsRemainingFlag === true) {
                            if (ctrlValue == 'false') {
                                controlCollection.push(ctrlId);
                            }
                        }
                        else if (this.requiredFlag === false && this.fieldsRemainingFlag === false) {
                            controlCollection.push(ctrlId);
                        }
                        break;
                    case "Radio":
                        let curRadioctrl: any = document.getElementById("control_" + ctrlId);
                        let isRadioChecked: string = "false";
                        isRadioChecked = curRadioctrl.checked ? "true" : "false";
                        ctrlValue = isRadioChecked;
                        ctrlReq = ctrlItem.attributes["data-rq"] != undefined ? Boolean(ctrlItem.attributes["data-rq"].value) : false;

                        if (this.requiredFlag === true && this.fieldsRemainingFlag === true) {
                            if (ctrlReq == true && ctrlValue == 'false') {
                                controlCollection.push(ctrlId);
                            }
                        }
                        else if (this.requiredFlag === true && this.fieldsRemainingFlag === false) {
                            if (ctrlReq == true) {
                                controlCollection.push(ctrlId);
                            }
                        }
                        else if (this.requiredFlag === false && this.fieldsRemainingFlag === true) {
                            if (ctrlValue == 'false') {
                                controlCollection.push(ctrlId);
                            }
                        }
                        else if (this.requiredFlag === false && this.fieldsRemainingFlag === false) {
                            controlCollection.push(ctrlId);
                        }
                        break;
                    case "DropDown":
                        let currentdrpCtrl: any = (document.getElementById("control_" + ctrlId)) as HTMLSelectElement;
                        var sel = currentdrpCtrl.selectedIndex;
                        var opt = currentdrpCtrl.options[sel];
                        var curValue = (<HTMLSelectElement>opt).value;
                        ctrlValue = curValue;
                        ctrlReq = ctrlItem.attributes["data-rq"] != undefined ? Boolean(ctrlItem.attributes["data-rq"].value) : false;
                        if (this.requiredFlag === true && this.fieldsRemainingFlag === true) {
                            if (ctrlReq == true && ctrlValue == '') {
                                controlCollection.push(ctrlId);
                            }
                        }
                        else if (this.requiredFlag === true && this.fieldsRemainingFlag === false) {
                            if (ctrlReq == true) {
                                controlCollection.push(ctrlId);
                            }
                        }
                        else if (this.requiredFlag === false && this.fieldsRemainingFlag === true) {
                            if (ctrlValue == '') {
                                controlCollection.push(ctrlId);
                            }
                        }
                        else if (this.requiredFlag === false && this.fieldsRemainingFlag === false) {
                            controlCollection.push(ctrlId);
                        }
                        break;
                }
            });

            if (controlCollection && controlCollection.length > 0) {
                filterCount = controlCollection.length;
            }

            this.getNoFieldsRemaining(filterCount);
            setTimeout(() => {
                this.getUpdateCheckList();
                this.documentControlsResponse.CheckListData.forEach((element: any, index: any) => {
                    if (element.ControlsData != null && element.ControlsData.length > 0) {
                        element.ControlsData.forEach((eleChild: any) => {
                            if (controlCollection.includes(eleChild.ControlHtmlID)) {
                                if (document.getElementById('anchor_' + eleChild.ControlHtmlID)) {
                                    document.getElementById('anchor_' + eleChild.ControlHtmlID)!.style.display = 'block';
                                    document.getElementById('panel_' + eleChild.ControlHtmlID)!.style.display = 'block';
                                    document.getElementById('panel_' + eleChild.ControlHtmlID)!.removeAttribute('style');
                                    document.getElementById('panel_' + eleChild.ControlHtmlID)!.setAttribute('style', '');
                                    document.getElementById('panel_' + eleChild.ControlHtmlID)!.classList.add('visible');
                                }
                            }
                            else {
                                if (document.getElementById('anchor_' + eleChild.ControlHtmlID)) {
                                    document.getElementById('anchor_' + eleChild.ControlHtmlID)!.style.display = 'none';
                                    document.getElementById('panel_' + eleChild.ControlHtmlID)!.style.display = 'none';
                                }
                            }
                        });
                    }
                });
                let chekListAccordainClass: any = this.document.getElementsByClassName("chekListAccordainClass");
                let chekListAccordainClassArray: any = [];
                if (chekListAccordainClass != null && chekListAccordainClass.length > 0) {
                    if (filterCount > 0) {
                        let isChekListAccordainClassDivVisible: boolean = false;
                        let isExpanded: boolean = false;
                        Array.from(chekListAccordainClass).forEach((item: any, chkIndex) => {
                            let panel: any = item.getElementsByClassName("panel");
                            if (panel != null && panel.length > 0) {
                                Array.from(panel).forEach((panelitem: any, panelIndex: any) => {
                                    let checkListDataItemClass: any = panelitem.getElementsByClassName("checkListDataItem");
                                    if (checkListDataItemClass != null && checkListDataItemClass.length > 0) {
                                        Array.from(item.getElementsByClassName("checkListDataItem") as HTMLCollectionOf<HTMLElement>)
                                            .forEach((checkListItem: any, index: any) => {
                                                let display: any = window.getComputedStyle(checkListItem).display;
                                                if (display == "block") {
                                                    item.style["display"] = "block";
                                                    isChekListAccordainClassDivVisible = true;
                                                    checkListItem.style["display"] = "block";
                                                    panel[index].removeAttribute("style");
                                                    if (chkIndex == 0 || controlCollection.length == 1) {
                                                        panel[index].classList.add("visible");
                                                    }
                                                    else {
                                                        panel[index].classList.remove("visible");
                                                    }
                                                    if (chekListAccordainClassArray.length == 0) {
                                                        let attRID = item.getAttribute("id");
                                                        chekListAccordainClassArray.push(attRID);
                                                        let accordion1Class: any = item.getElementsByClassName("accordion1");
                                                        accordion1Class[0].classList.add("active1");
                                                        panel[index].classList.add("visible");
                                                        this.currentIndex = Number(attRID.split('_')[1]);
                                                    }
                                                }
                                                else {
                                                    checkListItem.style["display"] = "none";
                                                    panel[index].style["display"] = "none";
                                                    panel[index].classList.remove("visible");
                                                }
                                            });
                                    }
                                    else {
                                        item.style["display"] = "none";
                                    }
                                });

                                if (!isChekListAccordainClassDivVisible) {
                                    item.style["display"] = "none";
                                    let accordion1Class: any = item.getElementsByClassName("accordion1");
                                    accordion1Class[0].classList.remove("active1");
                                    isChekListAccordainClassDivVisible = false;
                                }
                                else {
                                    isChekListAccordainClassDivVisible = false;
                                }
                            }
                            else {
                                item.style["display"] = "none";
                            }
                        });
                    }
                }
                this.cdRef.detectChanges();
            }, 100);
            this.cdRef.detectChanges();
        }, 10);
        this.cdRef.detectChanges();
    }

    getNoFieldsRemaining(filterCount: any) {
        if (filterCount === 0) { //&& this.fieldsRemainingFlag === true
            this.showNoFieldsRemainingMsg = true;
        } else {
            this.showNoFieldsRemainingMsg = false;
        }
    }


    hideValidationErrormessages() {
        if (this.documentControlsResponse && this.documentControlsResponse.EnvelopeImageControlData &&
            this.documentControlsResponse.EnvelopeImageControlData.length) {
            this.documentControlsResponse.EnvelopeImageControlData.forEach((ctrl: any) => {
                ctrl.ControlsData.forEach((ctrlItem: any) => {
                    if (ctrlItem.Required === true && ctrlItem.ControlName !== 'Hyperlink' && ctrlItem.ControlName !== 'Label') {
                        if (ctrlItem.ControlName === 'Checkbox' || ctrlItem.ControlName === 'Radio') {
                            if ((<HTMLInputElement>document.getElementById('control_' + ctrlItem.ControlHtmlID))) {
                                let checkboxParentCtrl: any = (<HTMLInputElement>document.getElementById('control_' + ctrlItem.ControlHtmlID)).parentNode;
                                checkboxParentCtrl.style.border = 'none';
                            }
                        } else if (ctrlItem.ControlName === 'Signature' || ctrlItem.ControlName === 'NewInitials') {
                            let IsAnySignatureExists: boolean = this.documentControlsResponse.IsAnySignatureExists;
                            if (ctrlItem.ControlName === 'Signature' && IsAnySignatureExists) {
                                if ((<HTMLInputElement>document.getElementById(ctrlItem.ControlHtmlID))) {
                                    (<HTMLInputElement>document.getElementById(ctrlItem.ControlHtmlID)).style.border = 'none';
                                    let sigItem: any = (<HTMLInputElement>document.getElementById(ctrlItem.ControlHtmlID));
                                    sigItem.getElementsByClassName("signature")[0].classList.remove("btn_sign_validation");
                                    // sigItem.getElementsByClassName("signature")[0].classList.add("btn_sign");
                                }
                            }
                            else if (ctrlItem.ControlName === 'NewInitials') {
                                if ((<HTMLInputElement>document.getElementById(ctrlItem.ControlHtmlID))) {
                                    (<HTMLInputElement>document.getElementById(ctrlItem.ControlHtmlID)).style.border = 'none';
                                }
                            }
                        } else {
                            if ((<HTMLInputElement>document.getElementById('control_' + ctrlItem.ControlHtmlID))) {
                                (<HTMLInputElement>document.getElementById('control_' + ctrlItem.ControlHtmlID)).style.border = '1px solid ';
                            }
                        }
                    }
                })
            })
        }
    }

    getEachControlValidations(ctrlResp: any, isFinishLater: boolean) {
        let showDefaultSigContol: any = document.getElementById("showDefaultSignatureContol");
        if (showDefaultSigContol != undefined && showDefaultSigContol != null && window.getComputedStyle(showDefaultSigContol).display != 'none') {
            if (this.defaultSignatureObj[0].ControlName === 'FooterSignature') {
                this.defaultSignatureFlag = false;
                let launguageSignImageName: string = this.translationsService.getLanguageValidationVal('lang_yourNameImg');
                let defaultImage = Defaults.DefaultImages + launguageSignImageName + ".png";
                let defaultUpdatedImage: any = '';
                let footerSignNav: any = document.getElementById("FooterSignNav");
                if (footerSignNav != undefined && footerSignNav != null) {
                    let footerSignNavCol = footerSignNav.getElementsByTagName('img');
                    defaultUpdatedImage = footerSignNavCol[0].attributes["src"] != undefined ? footerSignNavCol[0].attributes["src"].value : ''
                }

                if (!isFinishLater) {
                    if (this.showDefaultSignatureContol == "block") {
                        if (footerSignNav != undefined && footerSignNav != null && defaultUpdatedImage != defaultImage) {
                            this.defaultSignatureFlag = false;
                        }
                        else {
                            this.showToaster('Please sign default signature.', 'Warning');
                            this.defaultSignatureFlag = true;
                            this.hideAndShowFilterSection();
                        }
                    }
                }
            }
        }

        this.validateFlag = false;
        let currentRecpId = localStorage.getItem("RecipientID");
        this.mobileControlArr.forEach((ctrlItem: any) => {
            if (ctrlItem.controlName !== 'Hyperlink' && ctrlItem.controlName !== 'Label' && ctrlItem.controlName !== 'DateTimeStamp') {
                let value: any;
                let chkItem: any = (<HTMLInputElement>document.getElementById(ctrlItem.controlId.replace('control_', '')));
                if (ctrlItem.required === 'true' || ctrlItem.required === true) {
                    if (ctrlItem.controlName === 'Checkbox') {
                        value = (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).checked;
                        var groupName = (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).getAttribute("name");
                        if (!isFinishLater && groupName != undefined && groupName != null && groupName != "") {
                            if ($('.signerControl input:checkbox[name="' + groupName + '"]:checked').length == 0) {
                                let checkboxCtrlid: any;
                                chkItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                                chkItem.style.border = '';
                                checkboxCtrlid = (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).parentNode;
                                checkboxCtrlid.style.border = '2px solid red';
                                this.validateFlag = true;
                                this.showToaster(this.requiredFields, 'Warning');
                            }
                        }
                        else if (!isFinishLater && value === false) {
                            let checkboxCtrlid: any;
                            chkItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                            chkItem.style.border = '';
                            checkboxCtrlid = (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).parentNode;
                            checkboxCtrlid.style.border = '2px solid red';
                            this.validateFlag = true;
                            this.showToaster(this.requiredFields, 'Warning');
                        }
                    }
                    else if (ctrlItem.controlName === 'Radio') {
                        var name = (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).getAttribute("name");
                        if (!isFinishLater && $('.signerControl input:radio[name="' + name + '"]:checked').length == 0) {
                            chkItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                            chkItem.style.border = '';
                            let radioCtrllid: any;
                            radioCtrllid = (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).parentNode;
                            radioCtrllid.style.border = '2px solid red';
                            this.validateFlag = true;
                            this.showToaster(this.requiredFields, 'Warning');
                        }
                    }
                    else if (ctrlItem.controlName === 'Signature' || ctrlItem.controlName === 'NewInitials') {
                        let sigSignatureScr: any = "";
                        let IsAnySignatureExists: boolean = this.documentControlsResponse.IsAnySignatureExists;
                        if (!isFinishLater && ctrlItem.controlName === 'Signature' && IsAnySignatureExists) {
                            let sigImageCollection: any = document.getElementById(ctrlItem.controlId);
                            sigSignatureScr = sigImageCollection.attributes["src"] != undefined ? sigImageCollection.attributes["src"].value : '';
                        }

                        if (!isFinishLater && ctrlItem.controlName === 'NewInitials') {
                            let initialImageCollection: any = document.getElementById(ctrlItem.controlId);
                            sigSignatureScr = initialImageCollection.attributes["src"] != undefined ? initialImageCollection.attributes["src"].value : '';
                        }

                        if (!isFinishLater && (sigSignatureScr === '' || sigSignatureScr === null)) {
                            if (ctrlItem.controlName === 'Signature' && IsAnySignatureExists) {
                                let sigItem: any = (<HTMLInputElement>document.getElementById(ctrlItem.controlId.replace('control_', '')));
                                sigItem.getElementsByClassName("signature")[0].classList.remove("btn_sign");
                                sigItem.getElementsByClassName("signature")[0].classList.add("btn_sign_validation");
                                this.validateFlag = true;
                                this.showToaster(this.requiredFields, 'Warning');
                            }

                            if (ctrlItem.controlName === 'NewInitials') {
                                (<HTMLInputElement>document.getElementById(ctrlItem.controlId.replace('control_', ''))).style.border = '2px solid red';
                                (<HTMLInputElement>document.getElementById(ctrlItem.controlId.replace('control_', ''))).style["height"] = '';
                                this.validateFlag = true;
                                this.showToaster(this.requiredFields, 'Warning');
                            }

                        }
                    }
                    else if (ctrlItem.controlName === 'DropDown') {
                        let currentdrpCtrl: any = (document.getElementById(ctrlItem.controlId)) as HTMLSelectElement;
                        var sel = currentdrpCtrl.selectedIndex;
                        var opt = currentdrpCtrl.options[sel];
                        value = (<HTMLSelectElement>opt).textContent;
                        if (!isFinishLater && (value === '' || value === ' ')) {
                            chkItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                            chkItem.style.border = '';
                            (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).style.border = '2px solid red';
                            this.validateFlag = true;
                            this.showToaster(this.requiredFields, 'Warning');
                        }
                    }
                    else if (ctrlItem.controlName === 'Email') {
                        value = (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).value;
                        value = value.trim();
                        if (!isFinishLater && value === '') {
                            chkItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                            chkItem.style.border = '';
                            (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).style.border = '2px solid red';
                            this.validateFlag = true;
                            this.showToaster(this.requiredFields, 'Warning');
                        }
                        else if (value != '' && !this.validateEmail(value)) {
                            chkItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                            chkItem.style.border = '';
                            (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).style.border = '2px solid red';
                            this.validateFlag = true;
                            this.showToaster(this.invalidEmail, 'Warning');
                        }
                    }
                    else if (ctrlItem.controlName === 'Date') {
                        let txtCtrl: any = (<HTMLInputElement>document.getElementById(ctrlItem.controlId));
                        let dateFormatToPass: any = txtCtrl.attributes['dateformat'].value;
                        value = (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).value;
                        if (!isFinishLater && value === '') {
                            chkItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                            chkItem.style.border = '';
                            (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).style.border = '2px solid red';
                            this.validateFlag = true;
                            this.showToaster(this.requiredFields, 'Warning');
                        }
                        else if (value != '' && !this.isValidDateCustom(value, dateFormatToPass)) {
                            chkItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                            chkItem.style.border = '';
                            (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).style.border = '2px solid red';
                            this.validateFlag = true;
                            this.showToaster(this.invalidDate, 'Warning');
                        }
                    }
                    else if (ctrlItem.controlName === 'Text') {
                        let txtCtrl: any = (<HTMLInputElement>document.getElementById(ctrlItem.controlId));
                        let ctrlType: any = txtCtrl.attributes["data-title"].value;
                        value = (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).value;
                        if (ctrlType === 'Zip' || (ctrlType === 'SSN')) {
                            if (!isFinishLater && value === '') {
                                chkItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                                chkItem.style.border = '';
                                (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).style.border = '2px solid red';
                                this.validateFlag = true;
                                this.showToaster(this.requiredFields, 'Warning');
                            }
                            else if (value != '') {
                                if (value.indexOf('_') > 0 || value.indexOf('_') > -1 || value.indexOf('-') > 5) {
                                    chkItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                                    chkItem.style.border = '';
                                    (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).style.border = '2px solid red';
                                    this.validateFlag = true;
                                    if (ctrlType === 'Zip') {
                                        this.showToaster(this.invlaidZIP, 'Warning');
                                    }
                                    if (ctrlType === 'SSN') {
                                        this.showToaster(this.invlaidSSN, 'Warning');
                                    }
                                }
                            }
                        }
                        else if (ctrlType === 'Date') {
                            let dateFormatToPass: any = txtCtrl.attributes['data-ctntype'].value;
                            value = (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).value;
                            if (!isFinishLater && value === '') {
                                chkItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                                chkItem.style.border = '';
                                (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).style.border = '2px solid red';
                                this.validateFlag = true;
                                this.showToaster(this.requiredFields, 'Warning');
                            }
                            else if (value != '' && !this.isValidDateCustom(value, dateFormatToPass)) {
                                chkItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                                chkItem.style.border = '';
                                (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).style.border = '2px solid red';
                                this.validateFlag = true;
                                this.showToaster(this.invalidDate, 'Warning');
                            }
                        }
                        else if (ctrlType === 'Email') {
                            value = (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).value;
                            value = value.trim();
                            if (!isFinishLater && value === '') {
                                chkItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                                chkItem.style.border = '';
                                (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).style.border = '2px solid red';
                                this.validateFlag = true;
                                this.showToaster(this.requiredFields, 'Warning');
                            }
                            else if (value != '' && !this.validateEmail(value)) {
                                chkItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                                chkItem.style.border = '';
                                (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).style.border = '2px solid red';
                                this.validateFlag = true;
                                this.showToaster(this.invalidEmail, 'Warning');
                            }
                        }
                        else {
                            value = (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).value;
                            value = value.trim();
                            (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).value = value;
                            if (!isFinishLater && value === '') {
                                chkItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                                chkItem.style.border = '';
                                (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).style.border = '2px solid red';
                                this.validateFlag = true;
                                this.showToaster(this.requiredFields, 'Warning');
                            }
                        }
                    }
                    else {
                        value = (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).value;
                        value = value.trim();
                        (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).value = value;
                        if (!isFinishLater && value === '') {
                            chkItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                            chkItem.style.border = '';
                            (<HTMLInputElement>document.getElementById(ctrlItem.controlId)).style.border = '2px solid red';
                            this.validateFlag = true;
                            this.showToaster(this.requiredFields, 'Warning');
                        }
                    }
                    setTimeout(() => {
                        this.hideValidationErrormessages();
                    }, 5000);
                }
            }
        })

        // console.log(' this.validateFlag', this.validateFlag);
        let SuccessFlag: boolean = true;
        if (this.validateFlag || this.defaultSignatureFlag) {
            SuccessFlag = false;
        }
        return SuccessFlag;
    }

    //submit
    prepareSubmitEnvelopeObject() {
        let ctrlResp = this.documentControlsResponse;
        let ctrlSigningResp = this.responseSigningUrlModel;
        let envelopeSignDocumentSubmitInfo = {
            EnvelopeID: ctrlSigningResp.EnvelopeID,
            RecipientID: ctrlSigningResp.RecipientID,
            IsFromInbox: ctrlSigningResp.IsFromInbox,
            CopyEmail: ctrlSigningResp.CopyEmailId,
            ControlCollection: this.getControlCollection(ctrlResp, false),
            CertificateSignature: this.getCertificateSignature(),
            IsReviewed: this.isFileReviewed,
            IsConfirmationEmailReq: this.getConfirmationEmailReq(ctrlResp),
            IpAddress: this.ipAddress
        };
        return envelopeSignDocumentSubmitInfo;
    }

    submitEnvelope() {
        if (this.checkDocumentControlsResponselocal()) {
            this.commonService.checkIsUrlExistsInBrowserMemory();
            return;
        }
        else {
            let ctrlResp = this.documentControlsResponse;
            let successFlag: boolean = true;
            successFlag = this.getEachControlValidations(ctrlResp, false);
            if (successFlag) {
                let envelopeSignDocumentSubmitInfo: any = this.prepareSubmitEnvelopeObject();
                //  console.log(envelopeSignDocumentSubmitInfo);
                this.envelopeSignDocumentSubmitRequestInfo = envelopeSignDocumentSubmitInfo;

                //Mandatory Attachment files Validation
                let mandatoryCount: any;
                if (this.ShowAttachmentNavMenu) {
                    mandatoryCount = localStorage.getItem("MandatoryAttachmentFileCount");
                    if (mandatoryCount > 0) { //when attachment model popup opened.
                        this.validationPopupStyle = 'block';
                    }
                    else if (mandatoryCount != "0" && this.EnvelopeAdditionalUploadInfoList && this.EnvelopeAdditionalUploadInfoList.length > 0) { ////when attachment model popup not opened.
                        Array.from(this.EnvelopeAdditionalUploadInfoList).forEach((item: any) => {
                            if (item.IsRequired) {
                                if (item.FileName == "" || item.FileName == null) {
                                    mandatoryCount = mandatoryCount + 1;
                                }
                            }
                        });
                        if (mandatoryCount > 0) {
                            this.validationPopupStyle = 'block';
                        }
                        else {
                            this.filesReviewCheckAndSubmit(ctrlResp);
                        }
                    }
                    else {
                        this.filesReviewCheckAndSubmit(ctrlResp);
                    }
                }
                else {
                    this.filesReviewCheckAndSubmit(ctrlResp);
                }
            }
            // this.showFinishModalDailog="none";
        }
    }

    filesReviewCheckAndSubmit(ctrlResp: any) {
        if (ctrlResp.FileReviewCount > 0) {
            this.openFilesReview();
            let btnConfirm: any = document.getElementById('btnConfirm');
            let btnFileReviewDownload: any = document.getElementById('btnFileReviewDownload');
            if (this.isFileReviewed == "" || this.isFileReviewed == null) {
                btnConfirm?.setAttribute('disabled', 'disabled');
                btnFileReviewDownload?.setAttribute('background-color', '');
                btnFileReviewDownload?.removeAttribute('disabled');
            } else {
                this.isFileReviewed = '2';
                btnConfirm?.removeAttribute('disabled');
            }
        }
        else {
            this.envelopeSignDocumentSubmitRequestInfo.IsReviewed = this.isFileReviewed;
            //Call common submit method
            this.commonSubmitClickevent(this.envelopeSignDocumentSubmitRequestInfo);
        }
    }

    finishLater(val: any) {
        if (this.checkDocumentControlsResponselocal()) {
            this.commonService.checkIsUrlExistsInBrowserMemory();
            return;
        }
        else {
            let ctrlResp = this.documentControlsResponse;
            let ctrlSigningResp = this.responseSigningUrlModel;
            let successFlag: boolean = this.getEachControlValidations(ctrlResp, true);
            let isSendEmailOnFinishLater: boolean = false;
            if (val === '') {
                localStorage.setItem('IsFromSessionExpired', '0');
                isSendEmailOnFinishLater = false;
            } else if (val === 'sessionExpired') {
                localStorage.setItem('IsFromSessionExpired', '1');
                isSendEmailOnFinishLater = true;
            } else if (val === 'onUserSelectedFinishLater') {
                localStorage.setItem('IsFromSessionExpired', '0');
                isSendEmailOnFinishLater = true;
            }
            if (successFlag) {
                let envelopeFinishLaterSubmitInfo = {
                    EnvelopeID: ctrlSigningResp.EnvelopeID,
                    RecipientID: ctrlSigningResp.RecipientID,
                    ControlCollection: this.getControlCollection(ctrlResp, true),
                    LanguageCode: localStorage.getItem("cultureInfo"),
                    IsBack: false,
                    IsSaveControl: false,
                    UrlForFinishLater: localStorage.getItem("actualSigningUrl"),
                    InviteSignerModels: [],
                    IpAddress: this.ipAddress,
                    IsSendEmailOnFinishLater: isSendEmailOnFinishLater
                };

                //Call envelope submit api
                let finishLaterUrl = this.commonConstants.FinishLater;
                this.showLoader = true;
                this.signerLandingService.finishLater(finishLaterUrl, envelopeFinishLaterSubmitInfo).subscribe(
                    (responseDetails: any) => {
                        this.showLoader = false;
                        let returnUrl: string = "";
                        if (responseDetails && responseDetails.StatusCode == 200 && responseDetails.StatusMessage.toLowerCase() == "ok") {
                            localStorage.setItem("DisplayMessage", responseDetails.Message);
                            localStorage.setItem("MessageType", "Success");
                            localStorage.setItem("IsFromFinishLater", "true");
                            returnUrl = RouteUrls.Info;

                            let lang_envelopeDetailsSuccessArray = this.translationsService.getLanguageValidationVal("lang_envelopeDetailsSuccess"); //jsonLanguageValidationArray.filter((c: any) => c.KeyName == "lang_envelopeDetailsSuccess");
                            this.toastr.success(lang_envelopeDetailsSuccessArray, 'Success', { timeOut: 1000 });
                        }
                        else if (responseDetails && responseDetails.originalError && responseDetails.originalError.error && responseDetails.originalError.error.StatusCode == 400) {
                            localStorage.setItem("DisplayMessage", responseDetails.originalError.error.Message);
                            localStorage.setItem("MessageType", "Warning");
                            returnUrl = RouteUrls.Info;
                            this.toastr.warning(responseDetails.originalError.error.Message, 'Warning', { timeOut: 1000 })
                        }
                        this.commonService.getReturnUrlDirection(returnUrl);
                        this.cdRef.detectChanges();
                    });
            }
        }
    }

    getControlCollection(ctrlResp: any, isFinishLater: boolean) {
        let controls: any = this.getAllDocumentControls();
        // console.log(controls);
        let controlCollection: any = this.addControlsToCollection(controls, isFinishLater);
        controlCollection = this.addDefaultSignatureInitialControls(controlCollection, ctrlResp, isFinishLater);
        //console.log(controlCollection);
        return controlCollection;
    }

    addDefaultSignatureInitialControls(controlCollection: any, ctrlResp: any, isFinishLater: boolean) {
        //Default Signature

        let launguageSignImageName: string = this.translationsService.getLanguageValidationVal('lang_yourNameImg');
        let defaultImage = Defaults.DefaultImages + launguageSignImageName + ".png";
        let defaultUpdatedImage: any = '';
        let footerSignNav: any = document.getElementById("FooterSignNav");
        if (footerSignNav != undefined && footerSignNav != null) {
            let footerSignNavCol = footerSignNav.getElementsByTagName('img');
            defaultUpdatedImage = footerSignNavCol[0].attributes["src"] != undefined ? footerSignNavCol[0].attributes["src"].value : null
        }
        let footerSignControlData: any;

        let signatureText: any = (localStorage.getItem("data-sign-text") == undefined || localStorage.getItem("data-sign-text") == null) ? null : localStorage.getItem("data-sign-text");
        let signImageCol = controlCollection.filter((c: any) => c.ControlName == "Signature");
        let signaturePresent: boolean = false;
        let signatureFont: boolean = false;
        if (isFinishLater || (signatureText != null && signatureFont) || localStorage.getItem('signatureTypeID') == "AEB3D4A0-9DCF-4441-98A8-8ECDB7169936" || ((signaturePresent && (signImageCol != null && signImageCol.length > 0 && signImageCol[0].ControlValue) != '')) || (localStorage.getItem('prefill') == 'prefill' && !signaturePresent)) {
            if (signImageCol != null && signImageCol.length == 0 && footerSignNav != undefined && footerSignNav != null && window.getComputedStyle(footerSignNav).display !== 'none' && defaultUpdatedImage != defaultImage) {
                footerSignControlData = {
                    ControlHtmlID: localStorage.getItem('signatureTypeID'), // for only sign
                    ControlValue: defaultUpdatedImage,
                    SignatureString: (localStorage.getItem("data-sign-text") == undefined || localStorage.getItem("data-sign-text") == null) ? "" : localStorage.getItem("data-sign-text"),
                    SignatureFont: '',
                    ControlName: "Signature"
                };
                controlCollection.push(footerSignControlData);
            }
            else {
                let signCtrlVal: any = '';

                if (signImageCol != null && signImageCol.length > 0) {
                    signCtrlVal = signImageCol[0].ControlValue;
                    footerSignControlData = {
                        ControlHtmlID: localStorage.getItem('signatureTypeID'), // for only sign
                        ControlValue: signCtrlVal,
                        SignatureString: (localStorage.getItem("data-sign-text") == undefined || localStorage.getItem("data-sign-text") == null) ? "" : localStorage.getItem("data-sign-text"),
                        SignatureFont: '',
                        ControlName: "Signature"
                    };
                    controlCollection.push(footerSignControlData);
                }
            }
        }

        if (signImageCol != null && signImageCol.length == 0 && footerSignNav != undefined && footerSignNav != null && window.getComputedStyle(footerSignNav).display !== 'none' && defaultUpdatedImage != defaultImage) {
            footerSignControlData = {
                ControlHtmlID: localStorage.getItem('signatureTypeID'), // for only sign
                ControlValue: defaultUpdatedImage,
                SignatureString: (localStorage.getItem("data-sign-text") == undefined || localStorage.getItem("data-sign-text") == null) ? "" : localStorage.getItem("data-sign-text"),
                SignatureFont: '',
                ControlName: "Signature"
            };
            controlCollection.push(footerSignControlData);
        }

        //Default Initial
        //Frst check initial control exists or not
        let defaultInitialControlData: any;
        let initialCtrlVal: any = '';
        let initialImageCol = controlCollection.filter((c: any) => c.ControlName == "NewInitials");
        if (initialImageCol != null && initialImageCol.length > 0) {
            initialCtrlVal = initialImageCol[0].ControlValue;

            defaultInitialControlData = {
                ControlHtmlID: localStorage.getItem('InitialTypeID'), // for only sign
                ControlValue: initialCtrlVal,
                SignatureString: (localStorage.getItem("data-initials-text") == undefined || localStorage.getItem("data-initials-text") == null) ? "" : localStorage.getItem("data-initials-text"),
                SignatureFont: '',
                ControlName: "NewInitials"
            };
            controlCollection.push(defaultInitialControlData);
        }
        return controlCollection;
    }

    getAllDocumentControls() {
        let controls: any = [];
        let popupCollection: any = [];
        Array.from(document.getElementsByClassName("imgControl") as HTMLCollectionOf<HTMLElement>)
            .forEach((imgItem: any) => {
                popupCollection = imgItem.getElementsByClassName('popUp');
                Array.from(popupCollection).forEach((popUpItem: any) => {
                    let isreadonly = false;
                    let inputTag = popUpItem.getElementsByTagName("input");
                    if (inputTag[0] != undefined) {
                        isreadonly = inputTag[0].attributes["readonly"];
                        if (isreadonly == undefined)
                            isreadonly = false;
                        else
                            isreadonly = true;
                    }

                    let cntType = popUpItem.attributes["data-ctntype"].value;
                    if (cntType != "DateTimeStamp" && window.getComputedStyle(popUpItem).display !== 'none' && !isreadonly) {
                        controls.push(popUpItem);
                    }
                    else if (cntType == "DateTimeStamp") {
                        controls.push(popUpItem);
                    }
                });
            });
        return controls;
    }

    addControlsToCollection(controls: any, isFinishLater: boolean) {
        let controlCollection: any = [];
        if (localStorage.getItem('signatureTypeID') == null || localStorage.getItem('signatureTypeID') == undefined ||
            localStorage.getItem('signatureTypeID') == "") {
            localStorage.setItem('signatureTypeID', SignatureType.Auto);
        }

        if (localStorage.getItem('InitialTypeID') == null || localStorage.getItem('InitialTypeID') == undefined ||
            localStorage.getItem('InitialTypeID') == "") {
            localStorage.setItem('InitialTypeID', InitialType.Auto);
        }
        Array.from(controls).forEach((ctrlItem: any) => {
            let ctrlId = ctrlItem.attributes["id"].value;
            let controlType = ctrlItem.attributes["data-ctntype"].value;
            let ctrlIsFixedwidth: any = null;
            if (ctrlItem && ctrlItem.attributes["data-isfixed-width"] && ctrlItem.attributes["data-isfixed-width"].value) {
                ctrlIsFixedwidth = ctrlItem.attributes["data-isfixed-width"].value;
            }
            let ctrlData: any;
            let ctrlValue: any;
            switch (controlType) {
                case "Signature":
                    let sigImageCollection = ctrlItem.getElementsByTagName('img');
                    ctrlData = {
                        ID: ctrlItem.attributes["data-unique"] != undefined ? ctrlItem.attributes["data-unique"].value : '',
                        ControlHtmlID: localStorage.getItem('signatureTypeID'),
                        PageNo: ctrlItem.attributes["data-page"] != undefined ? ctrlItem.attributes["data-page"].value : '',
                        Required: ctrlItem.attributes["data-rq"] != undefined ? Boolean(ctrlItem.attributes["data-rq"].value) : false,
                        ControlName: controlType,
                        ControlValue: sigImageCollection[0].attributes["src"] != undefined ? sigImageCollection[0].attributes["src"].value : null,
                        Width: 0,
                        Height: 0,
                        IsFixedwidth: null,
                        ZCoordinate: 0
                    };
                    controlCollection.push(ctrlData);
                    break;
                case "NewInitials":
                    let initialImageCollection = ctrlItem.getElementsByTagName('img');
                    ctrlData = {
                        ID: ctrlItem.attributes["data-unique"] != undefined ? ctrlItem.attributes["data-unique"].value : '',
                        ControlHtmlID: localStorage.getItem('InitialTypeID'),
                        PageNo: ctrlItem.attributes["data-page"] != undefined ? ctrlItem.attributes["data-page"].value : '',
                        Required: ctrlItem.attributes["data-rq"] != undefined ? Boolean(ctrlItem.attributes["data-rq"].value) : false,
                        ControlName: controlType,
                        ControlValue: initialImageCollection[0].attributes["src"] != undefined ? initialImageCollection[0].attributes["src"].value : null,
                        Width: 0,
                        Height: 0,
                        IsFixedwidth: null,
                        ZCoordinate: 0
                    };
                    controlCollection.push(ctrlData);
                    break;
                case "Text":
                    let currentTextCtrl: any = document.getElementById("control_" + ctrlId);
                    let currentTextCtrlTitle: any = currentTextCtrl.attributes['data-title'] != undefined ? currentTextCtrl.attributes['data-title'].value : '';
                    if (currentTextCtrlTitle === 'Date') {
                        ctrlValue = currentTextCtrl.value != undefined ? currentTextCtrl.value : '';
                    }
                    else if (currentTextCtrlTitle === 'Email') {
                        ctrlValue = currentTextCtrl.value != undefined ? currentTextCtrl.value : '';
                    }
                    else {
                        ctrlValue = ctrlItem.getElementsByTagName("textarea") != undefined ? ctrlItem.getElementsByTagName("textarea")[0].value : '';
                    }

                    ctrlData = {
                        ID: ctrlItem.attributes["data-unique"] != undefined ? ctrlItem.attributes["data-unique"].value : '',
                        ControlHtmlID: ctrlId,
                        PageNo: ctrlItem.attributes["data-page"] != undefined ? ctrlItem.attributes["data-page"].value : '',
                        Required: ctrlItem.attributes["data-rq"] != undefined ? Boolean(ctrlItem.attributes["data-rq"].value) : false,
                        ControlName: controlType,
                        ControlValue: ctrlValue != undefined ? ctrlValue : '',
                        Width: parseInt(currentTextCtrl.style.width.split('px')[0]),
                        Height: parseInt(currentTextCtrl.style.height.split('px')[0]),
                        IsFixedwidth: JSON.parse(ctrlIsFixedwidth),
                        ZCoordinate: currentTextCtrl.attributes["data-zcoordinate"] ? parseInt(currentTextCtrl.attributes["data-zcoordinate"].value) : 0
                    };
                    controlCollection.push(ctrlData);
                    break;
                case "Email":
                    let currEmailCtrl: any = document.getElementById("control_" + ctrlId);
                    ctrlValue = currEmailCtrl.value != undefined ? currEmailCtrl.value : '';
                    ctrlData = {
                        ID: ctrlItem.attributes["data-unique"] != undefined ? ctrlItem.attributes["data-unique"].value : '',
                        ControlHtmlID: ctrlId,
                        PageNo: ctrlItem.attributes["data-page"] != undefined ? ctrlItem.attributes["data-page"].value : '',
                        Required: ctrlItem.attributes["data-rq"] != undefined ? Boolean(ctrlItem.attributes["data-rq"].value) : false,
                        ControlName: controlType,
                        ControlValue: ctrlValue != undefined ? ctrlValue : '',
                        Width: 0,
                        Height: 0,
                        IsFixedwidth: null,
                        ZCoordinate: 0
                    };
                    controlCollection.push(ctrlData);
                    break;
                case "Name":
                    let currNameCtrl: any = document.getElementById("control_" + ctrlId);
                    ctrlValue = currNameCtrl.value != undefined ? currNameCtrl.value : '';
                    ctrlData = {
                        ID: ctrlItem.attributes["data-unique"] != undefined ? ctrlItem.attributes["data-unique"].value : '',
                        ControlHtmlID: ctrlId,
                        PageNo: ctrlItem.attributes["data-page"] != undefined ? ctrlItem.attributes["data-page"].value : '',
                        Required: ctrlItem.attributes["data-rq"] != undefined ? Boolean(ctrlItem.attributes["data-rq"].value) : false,
                        ControlName: controlType,
                        ControlValue: ctrlValue != undefined ? ctrlValue : '',
                        Width: parseInt(currNameCtrl.style.width.split('px')[0]),
                        Height: parseInt(currNameCtrl.style.height.split('px')[0]),
                        IsFixedwidth: JSON.parse(ctrlIsFixedwidth),
                        ZCoordinate: currNameCtrl.attributes["data-zcoordinate"] ? parseInt(currNameCtrl.attributes["data-zcoordinate"].value) : 0
                    };
                    controlCollection.push(ctrlData);
                    break;
                case "Date":
                case "Initials":
                case "Title":
                case "Company":
                    let currCtrl: any = document.getElementById("control_" + ctrlId);
                    ctrlValue = currCtrl.value != undefined ? currCtrl.value : '';
                    ctrlData = {
                        ID: ctrlItem.attributes["data-unique"] != undefined ? ctrlItem.attributes["data-unique"].value : '',
                        ControlHtmlID: ctrlId,
                        PageNo: ctrlItem.attributes["data-page"] != undefined ? ctrlItem.attributes["data-page"].value : '',
                        Required: ctrlItem.attributes["data-rq"] != undefined ? Boolean(ctrlItem.attributes["data-rq"].value) : false,
                        ControlName: controlType,
                        ControlValue: ctrlValue != undefined ? ctrlValue : '',
                        Width: 0,
                        Height: 0,
                        IsFixedwidth: null,
                        ZCoordinate: 0
                    };
                    controlCollection.push(ctrlData);
                    break;
                case "Checkbox":
                    let curCheckboxctrl: any = document.getElementById("control_" + ctrlId);
                    let isChecked: string = "false";

                    isChecked = curCheckboxctrl.checked ? "true" : "false";

                    ctrlData = {
                        ID: ctrlItem.attributes["data-unique"] != undefined ? ctrlItem.attributes["data-unique"].value : '',
                        ControlHtmlID: ctrlId,
                        PageNo: ctrlItem.attributes["data-page"] != undefined ? ctrlItem.attributes["data-page"].value : '',
                        Required: ctrlItem.attributes["data-rq"] != undefined ? Boolean(ctrlItem.attributes["data-rq"].value) : false,
                        ControlName: controlType,
                        GroupName: curCheckboxctrl.attributes["name"] != undefined ? curCheckboxctrl.attributes["name"].value : '',
                        ControlValue: isChecked,
                        Width: 0,
                        Height: 0,
                        IsFixedwidth: null,
                        ZCoordinate: 0
                    };
                    controlCollection.push(ctrlData);
                    break;
                case "Radio":
                    let curRadioctrl: any = document.getElementById("control_" + ctrlId);
                    let isRadioChecked: string = "false";


                    isRadioChecked = curRadioctrl.checked ? "true" : "false";

                    ctrlData = {
                        ID: ctrlItem.attributes["data-unique"] != undefined ? ctrlItem.attributes["data-unique"].value : '',
                        ControlHtmlID: ctrlId,
                        PageNo: ctrlItem.attributes["data-page"] != undefined ? ctrlItem.attributes["data-page"].value : '',
                        Required: ctrlItem.attributes["data-rq"] != undefined ? Boolean(ctrlItem.attributes["data-rq"].value) : false,
                        ControlName: controlType,
                        ControlValue: isRadioChecked,
                        Width: 0,
                        Height: 0,
                        IsFixedwidth: null,
                        ZCoordinate: 0
                    };
                    controlCollection.push(ctrlData);
                    break;
                case "DropDown":
                    let currentdrpCtrl: any = (document.getElementById("control_" + ctrlId)) as HTMLSelectElement;
                    var sel = currentdrpCtrl.selectedIndex;
                    var opt = currentdrpCtrl.options[sel];
                    var curValue = (<HTMLSelectElement>opt).value;

                    ctrlData = {
                        ID: ctrlItem.attributes["data-unique"] != undefined ? ctrlItem.attributes["data-unique"].value : '',
                        ControlHtmlID: ctrlId,
                        PageNo: ctrlItem.attributes["data-page"] != undefined ? ctrlItem.attributes["data-page"].value : '',
                        Required: ctrlItem.attributes["data-rq"] != undefined ? Boolean(ctrlItem.attributes["data-rq"].value) : false,
                        ControlName: controlType,
                        ControlValue: curValue,
                        Width: 0,
                        Height: 0,
                        IsFixedwidth: null,
                        ZCoordinate: 0
                    };
                    controlCollection.push(ctrlData);
                    break;
                case "DateTimeStamp":
                    if (!isFinishLater) {
                        let currentdtsCtrl: any = (document.getElementById("control_" + ctrlId)) as HTMLSelectElement;
                        let dateTimeStampValue: any = this.getDateTimeStampValue();
                        ctrlData = {
                            ID: ctrlItem.attributes["data-unique"] != undefined ? ctrlItem.attributes["data-unique"].value : '',
                            ControlHtmlID: ctrlId,
                            PageNo: ctrlItem.attributes["data-page"] != undefined ? ctrlItem.attributes["data-page"].value : '',
                            Required: ctrlItem.attributes["data-rq"] != undefined ? Boolean(ctrlItem.attributes["data-rq"].value) : false,
                            ControlName: controlType,
                            ControlValue: dateTimeStampValue,
                            Width: 0,
                            Height: 0,
                            IsFixedwidth: null,
                            ZCoordinate: 0
                        };
                        controlCollection.push(ctrlData);
                    }
                    break;
            }
        });
        return controlCollection;
    }

    getCertificateSignature() {
        let allReqCommonSignatureControl: any = [];
        Array.from(document.getElementsByClassName("commonSignatureControl") as HTMLCollectionOf<HTMLElement>)
            .forEach((item: any) => {
                if (window.getComputedStyle(item).display !== 'none') {
                    let dataReq = item.attributes["data-rq"].value;
                    if (dataReq) {
                        allReqCommonSignatureControl.push(item);
                    }
                }
            });

        let allNonReqCommonSignatureControl: any = [];
        Array.from(document.getElementsByClassName("commonSignatureControl") as HTMLCollectionOf<HTMLElement>)
            .forEach((item: any) => {
                if (window.getComputedStyle(item).display !== 'none') {
                    let dataReq = item.attributes["data-rq"].value;
                    if (!dataReq && item.getElementsByClassName("filledControls").length > 0) {
                        allNonReqCommonSignatureControl.push(item);
                    }
                }
            });

        if (allReqCommonSignatureControl.length > 0) {
            allReqCommonSignatureControl.forEach((item: any) => {
                let imageCollection = item.getElementsByTagName('img');
                this.myArray.push({
                    ID: item.attributes["data-unique"].value,
                    Tabindex: imageCollection[0].attributes["tabindex"].value
                });
            });
        }
        else if (allNonReqCommonSignatureControl.length > 0) {
            allNonReqCommonSignatureControl.forEach((item: any) => {
                let imageCollection = item.getElementsByTagName('img');
                this.myArray.push({
                    ID: item.attributes["data-unique"].value,
                    Tabindex: imageCollection[0].attributes["tabindex"].value
                });
            });
            this.myArray.sort(function (a: { Tabindex: number; }, b: { Tabindex: number; }) {
                return a.Tabindex - b.Tabindex
            });
            this.myArray.reverse();
        }

        return this.myArray.length > 0 ? this.myArray[0].ID : '';
    }

    getConfirmationEmailReq(ctrlResp: any) {
        let isStaticTemplate = false;
        let isConfirmationReq: boolean = false;

        this.allSignControls = Array.from(document.getElementsByClassName("emailControlsInput") as HTMLCollectionOf<HTMLElement>)
            .forEach((item: any) => {
                if (window.getComputedStyle(item).display !== 'none') {
                    if (!isConfirmationReq) {
                        isConfirmationReq = item.attributes["data-submit-required"].value == 1 ? true : false;
                    }
                }
            });

        let isSendConfirmationEmail: boolean = ctrlResp.EnvelopeInfo.IsSendConfirmationEmail;
        if ((ctrlResp.EnvelopeInfo.IsStatic == true && ctrlResp.IsDefaultSignatureForStaticTemplate == false) || (ctrlResp.IsSharedTemplateContentUnEditable == true && ctrlResp.IsDefaultSignatureForStaticTemplate == false)) {
            isStaticTemplate = true;
        }

        let AllowStaticMultiSigner: string = String(ctrlResp.AllowStaticMultiSigner);
        let isConfirmationReqValue: any = isStaticTemplate && AllowStaticMultiSigner.toLowerCase() == 'true' ? isSendConfirmationEmail : (isStaticTemplate && isConfirmationReq ? isConfirmationReq : isSendConfirmationEmail)
        if (isConfirmationReqValue == 'true' || isConfirmationReqValue == true || isConfirmationReqValue == 'TRUE')
            isConfirmationReq = true;
        else
            isConfirmationReq = false;

        return isConfirmationReq;
    }

    isStaticTemplate(ctrlResp: any) {
        var isStaticTemplate = false;
        if ((ctrlResp.EnvelopeInfo.IsStatic == true && ctrlResp.IsDefaultSignatureForStaticTemplate == false) || (ctrlResp.IsSharedTemplateContentUnEditable == true && ctrlResp.IsDefaultSignatureForStaticTemplate == false)) {
            isStaticTemplate = true;
        }

        if (isStaticTemplate) {
            localStorage.setItem("isStaticTemplate", "true");
        }
        else {
            localStorage.setItem("isStaticTemplate", "false");
        }
        return isStaticTemplate;
    }

    getDateTimeStampValue() {
        let dateFormat: string;
        dateFormat = this.documentControlsResponse.DateFormat;
        if (dateFormat == "dd-mmm-yyyy")
            dateFormat = dateFormat.replace("mmm", "MMM");
        else
            dateFormat = dateFormat.replace("mm", "MM");

        let currentDate: any = new Date();
        let date: any = this.getDefaultplaceHolder(dateFormat); // $.datepicker.formatDate(dateFormat, new Date());
        var time = currentDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });
        var timeZone = this.getTimezoneFromMoment();
        return date.concat(" " + time).concat(" " + timeZone);
    }

    getTimezoneFromMoment() {
        let timezone = jstz.determine();
        var m = moment();
        var abbr = m.tz(timezone.name()).zoneAbbr();
        return abbr;
    }

    commonSubmitClickevent(envelopeSignDocumentSubmitInfo: any) {
        this.showLoader = true;
        if (this.checkDocumentControlsResponselocal()) {
            this.commonService.checkIsUrlExistsInBrowserMemory();
            return;
        }
        else {
            let ReVerifySignerDocumentSubmit: any = this.documentControlsResponse.ReVerifySignerDocumentSubmit;
            if (ReVerifySignerDocumentSubmit && ReVerifySignerDocumentSubmit == true) {
                this.envelopeSignDocumentSubmitRequestInfo = envelopeSignDocumentSubmitInfo;
                this.resendVerificationCode();
            }
            else {
                this.callFinalSubmitEnvelopeDocument(envelopeSignDocumentSubmitInfo);
            }
        }
    }

    resendVerificationCode() {
        this.showLoader = true;
        this.userPasscodeOnSubmit = '';
        let envelopeID = localStorage.getItem('EnvelopeID');
        let recipientID = localStorage.getItem('RecipientID');
        let EmailId = localStorage.getItem('EmailId');
        let envelopeSignDocumentSubmitInfo: any = this.envelopeSignDocumentSubmitRequestInfo;
        let ctrlSigningResp: any = this.responseSigningUrlModel;
        let copyEmailAddress: any = '';

        if (ctrlSigningResp && ctrlSigningResp.CopyEmailId) {
            copyEmailAddress = ctrlSigningResp.CopyEmailId;
        }

        let paramsObj = {
            EnvelopeID: envelopeID,
            RecipientID: recipientID,
            EmailAddress: (copyEmailAddress == '') ? EmailId : copyEmailAddress,
            DialCode: (this.documentControlsResponse.MobileNumber != null && this.documentControlsResponse.MobileNumber != "") ? this.documentControlsResponse.DialCode : "",
            Mobile: this.documentControlsResponse.MobileNumber != null ? this.documentControlsResponse.MobileNumber : "",
            IsCopyEmail: (copyEmailAddress == '') ? false : true
        };

        let confirmUserOnFinalSubmitUrl = this.commonConstants.ConfirmUserOnFinalSubmit;
        this.signerLandingService.postcall(confirmUserOnFinalSubmitUrl, paramsObj).subscribe(
            (resp: any) => {
                this.showLoader = false;
                if (resp && resp.StatusCode == 200 && resp.StatusMessage.toLowerCase() == "ok") {
                    this.userPasscodeOnSubmit = resp.VerificationCode;
                    this.emailAddr = (copyEmailAddress == '') ? EmailId : copyEmailAddress;
                    this.Mobile = this.documentControlsResponse.DialCode + this.documentControlsResponse.MobileNumber;
                    let btnViewandSignDocument: any = document.getElementById("btnViewandSignDocument");
                    btnViewandSignDocument?.setAttribute('disabled', 'disabled');
                    this.sendPasswordPopupStyle = 'block';
                    this.sendAccessCodeTo = resp.SendAccessCodeTo;
                    let VerificationCode: any = document.getElementById("VerificationCode");
                    VerificationCode.value = '';
                    this.toastr.success("Access code sent.", 'Success', { timeOut: 1500 });
                    if (this.webOrMobile == 'mobile') {
                        let divFontSendPasswordPopup: any = document.getElementById("divFontSendPasswordPopup");
                        divFontSendPasswordPopup.style["width"] = "358px";
                    }
                    this.cdRef.detectChanges();
                }
                else {
                    this.showLoader = false;
                    this.toastr.warning("Access code not sent.", 'Warning', { timeOut: 1500 });
                    this.cdRef.detectChanges();
                }
            });
    }

    callFinalSubmitEnvelopeDocument(envelopeSignDocumentSubmitInfo: any) {
        this.showLoader = true;
        envelopeSignDocumentSubmitInfo.IsReviewed = this.isFileReviewed == undefined ? "0" : String(this.isFileReviewed);
        // this.isDocumentSubmitted = "true";
        this.timerWorker.postMessage({ action: 'stopTimer' });
        // this.idle.stop();
        this.cdRef.detectChanges();
        let submitDocumentUrl = this.commonConstants.SignDocument;
        this.signerLandingService.submitEnvelope(submitDocumentUrl, envelopeSignDocumentSubmitInfo).subscribe(
            (responseDetails: any) => {
                let encryptedSender: string = "";
                let returnUrl: string = "";
                this.showSessionExpireModalDailog = 'none';
                this.isDocumentSubmitted = "true";
                if (responseDetails && responseDetails.StatusCode == 200 && responseDetails.StatusMessage.toLowerCase() == "ok") {
                    this.showLoader = false;
                    returnUrl = responseDetails.returnUrl;
                    localStorage.setItem("DisplayMessage", responseDetails.message);
                    localStorage.setItem("MessageType", "Success");
                    if (responseDetails.returnUrl == "Info/Index") {
                        if (responseDetails.InfoSenderEmail != "" && responseDetails.InfoSenderEmail != null) {
                            encryptedSender = responseDetails.EncryptSender == null ? "" : responseDetails.EncryptSender;
                            returnUrl = RouteUrls.Info;
                        }
                        else {
                            returnUrl = RouteUrls.Info;
                        }
                        localStorage.setItem("EncryptedSender", encryptedSender);
                    }
                    else {
                        if (responseDetails.returnUrl == "DocumentPackage/DocumentPackageIndex" || responseDetails.returnUrl == "Home/Index") {
                            if (responseDetails.returnUrl != "DocumentPackage/DocumentPackageIndex" && responseDetails.returnUrl != "Home/Index") {
                                localStorage.setItem("DisplayMessage", "Envelope has been sent");
                                localStorage.setItem("MessageType", "Success");
                                returnUrl = RouteUrls.Info;
                            }
                            else
                                returnUrl = environment.uiBaseUrl + responseDetails.returnUrl;
                        }
                        else if (responseDetails.returnUrl == "/SignDocument/SignMultiTemplate") {
                            //localStorage.setItem("EncryptEnvelopeId", responseDetails.EncryptEnvelopeId);
                           // localStorage.setItem("EncryptRecipientId", responseDetails.EncryptRecipientId);
                            //localStorage.setItem("EncryptedQueryString", responseDetails.EncryptedQueryString);
                           // localStorage.setItem("isSignedDocumentService", responseDetails.isSignedDocumentService);
                            returnUrl = RouteUrls.SignMultiTemplate;
                        }

                        if (responseDetails.DGReturnURL != "" && responseDetails.DGReturnURL != null) {
                           // localStorage.setItem("EncryptEnvelopeId", responseDetails.EncryptEnvelopeId);
                            //localStorage.setItem("EncryptRecipientId", responseDetails.EncryptRecipientId);
                           // localStorage.setItem("EncryptedQueryString", responseDetails.EncryptedQueryString);
                           // localStorage.setItem("isSignedDocumentService", responseDetails.isSignedDocumentService);
                            responseDetails.DGReturnURL = RouteUrls.SignMultiTemplate;
                        }

                       // localStorage.setItem("EncryptedQueryString", responseDetails.EncryptedQueryString);
                    }

                    let lang_documentSent: string = "";
                    let lang_DocumentSentInProcess: string = "";
                    let CopyEmailDownloadFinalDoc: string = "";
                    let lang_DocumentSentInProcessArray = this.translationsService.getLanguageValidationVal("lang_DocumentSentInProcess"); //  this.languageValidation.filter((c: any) => c.KeyName == "lang_DocumentSentInProcess");
                    let CopyEmailDownloadFinalDocArray = this.translationsService.getLanguageValidationVal("CopyEmailDownloadFinalDoc");
                    lang_DocumentSentInProcess = lang_DocumentSentInProcessArray;
                    let lang_documentSentArray = this.translationsService.getLanguageValidationVal("lang_documentSent"); //this.languageValidation.filter((c: any) => c.KeyName == "lang_documentSent");
                    lang_documentSent = lang_documentSentArray;


                    if (responseDetails.success == true) {
                        if (responseDetails.EnableMessageToMobile == "1") {

                            var rdeliverymode: any = responseDetails.data.DeliveryMode;
                            if (rdeliverymode == "1" || rdeliverymode == "2" || rdeliverymode == "3" || rdeliverymode == "11") {
                                lang_documentSent = this.translationsService.getLanguageValidationVal("lang_documentSent");
                            }
                            else if (rdeliverymode == "8" || rdeliverymode == "9" || rdeliverymode == "10" || rdeliverymode == "12") {
                                lang_documentSent = this.translationsService.getLanguageValidationVal("lang_documentEmailMobileSent");;
                            }
                            else if (rdeliverymode == "4" || rdeliverymode == "5" || rdeliverymode == "6" || rdeliverymode == "7") {
                                lang_documentSent = this.translationsService.getLanguageValidationVal("lang_documentMobileSent");;
                            }
                            else {
                                lang_documentSent = this.translationsService.getLanguageValidationVal("lang_documentSent");;
                            }
                        }
                        else {
                            lang_documentSent = this.translationsService.getLanguageValidationVal("lang_documentSent");;
                        }
                    }
                    else {
                        lang_documentSent = this.translationsService.getLanguageValidationVal("lang_documentSent");;
                    }

                    if (responseDetails.success == false) {
                        this.showLoader = false;
                        if (responseDetails.success == false && responseDetails.field == 'Transferred') {
                            this.toastr.warning(responseDetails.message, 'Warning', { timeOut: 2000 });
                            if (returnUrl != undefined && returnUrl != null && returnUrl != '') {
                                setTimeout(() => { this.commonService.getReturnUrlDirection(returnUrl); }, 1500);
                            }
                        }
                        else {
                            this.showLoader = false;
                            let launguageValidationArray = this.translationsService.getLanguageValidationVal("ConatctSender"); //this.languageValidation.filter((c: any) => c.KeyName == "ConatctSender");
                            this.toastr.warning(launguageValidationArray, 'Warning', { timeOut: 2000 });
                        }
                    }
                    else if (this.documentControlsResponse.Prefill.toLowerCase() == 'prefill') {
                        this.showLoader = false;
                        let launguageValidationArray = this.translationsService.getLanguageValidationVal("lang_documentSent"); // this.languageValidation.filter((c: any) => c.KeyName == "lang_documentSent");
                        this.toastr.success(launguageValidationArray, 'Success', { timeOut: 2000 });
                        setTimeout(() => { this.commonService.getReturnUrlDirection(returnUrl); }, 2000);
                    }
                    else if (responseDetails.isDGFlow == true) {
                        this.showLoader = false;
                        if (responseDetails.showDGPopup == true) {
                            localStorage.setItem("EnvelopeID", envelopeSignDocumentSubmitInfo.EnvelopeID);
                            localStorage.setItem("RecipientID", envelopeSignDocumentSubmitInfo.RecipientID);
                            this.signAnotherDocumentUrl = responseDetails.returnUrl;
                            this.backToMultiViewUrl = RouteUrls.SignMultiTemplate;
                            this.opendvDGIntermediatePopup();
                        }
                        else {
                            this.showLoader = false;
                            this.toastr.success(lang_documentSent, 'Success', { timeOut: 2000 });
                            setTimeout(() => { this.commonService.getReturnUrlDirection(returnUrl); }, 2000);
                        }
                    }
                    else if (responseDetails.postSigningLogin == false) {
                        (responseDetails.isSignedDocumentService == true) ? this.toastr.success(lang_DocumentSentInProcess, 'Success', { timeOut: 2000 }) :
                            this.toastr.success(lang_documentSent, 'Success', { timeOut: 2000 });
                        setTimeout(() => { this.commonService.getReturnUrlDirection(returnUrl); }, 2000);
                    }
                    else {
                        let recipientsData: any = responseDetails.data;

                        let modalPopupObj = {
                            returnUrl: returnUrl,
                            MEmail: recipientsData.EmailAddress,
                            spEmail: recipientsData.EmailAddress,
                            isSignedDocumentService: responseDetails.isSignedDocumentService,
                            type: "submit envelope",
                            toastrMessage: (responseDetails.isSignedDocumentService == true) ? lang_DocumentSentInProcess : lang_documentSent,
                            attachSignedPdfID: responseDetails.AttachSignedPdfID,
                            isCopyEmail: recipientsData.IsCopyEmail
                        };
                        localStorage.setItem('EmailId', recipientsData.EmailAddress);
                        localStorage.setItem('RecpMobileNumber', this.documentControlsResponse.DialCode + this.documentControlsResponse.MobileNumber);
                        localStorage.setItem('RecpDeliveryMode', this.documentControlsResponse.DeliveryMode);

                        if (this.AllowSignersDownloadFinalContract == true) {
                            if (recipientsData.Order == 0) {
                                if (responseDetails.EnvelopeStatus.toUpperCase() == EnvelopeStatus.Completed || responseDetails.EnvelopeStatus.toUpperCase() == EnvelopeStatus.Terminated) {
                                    const modalRef = this.modalService.open(SignerRegistrationComponent, { size: 'md', keyboard: false, backdrop: 'static', windowClass: 'signerRegistration-class' });
                                    modalRef.componentInstance.modalPopupObj = modalPopupObj;
                                }
                                else {
                                    const modalRef = this.modalService.open(SignerIntermediateCloseComponent, { size: 'md', keyboard: false, backdrop: 'static', windowClass: 'signerIntermediate-class' });
                                    modalRef.componentInstance.modalPopupObj = modalPopupObj;
                                }
                            }
                            else if (recipientsData.Order == 1) {
                                if (responseDetails.EnvelopeStatus.toUpperCase() == EnvelopeStatus.Completed || responseDetails.EnvelopeStatus.toUpperCase() == EnvelopeStatus.Terminated) {
                                    const modalRef = this.modalService.open(SignerIntermediateComponent, { size: 'md', keyboard: false, backdrop: 'static', windowClass: 'signerIntermediate-class' });
                                    modalRef.componentInstance.modalPopupObj = modalPopupObj;
                                }
                                else {
                                    const modalRef = this.modalService.open(SignerIntermediateCloseComponent, { size: 'md', keyboard: false, backdrop: 'static', windowClass: 'signerIntermediate-class' });
                                    modalRef.componentInstance.modalPopupObj = modalPopupObj;
                                }
                            }
                            else if (recipientsData.Order == 2) {
                                const modalRef = this.modalService.open(SignerIntermediateContinueComponent, { size: 'lg', keyboard: false, backdrop: 'static', windowClass: 'signerIntermediateContinue-class' });
                                modalRef.componentInstance.modalPopupObj = modalPopupObj;
                            }
                            else {
                                (responseDetails.isSignedDocumentService == true) ? this.toastr.success(lang_DocumentSentInProcess, 'Success', { timeOut: 2000 }) :
                                    this.toastr.success(lang_documentSent, 'Success', { timeOut: 2000 });
                                setTimeout(() => { this.commonService.getReturnUrlDirection(returnUrl); }, 2000);
                            }
                        }
                        else {
                            (responseDetails.isSignedDocumentService == true) ? this.toastr.success(lang_DocumentSentInProcess, 'Success', { timeOut: 2000 }) :
                                this.toastr.success(lang_documentSent, 'Success', { timeOut: 2000 });
                            setTimeout(() => { this.commonService.getReturnUrlDirection(returnUrl); }, 2000);
                        }
                    }
                }
                else if (responseDetails.originalError.error.StatusCode == 400) {
                    this.showLoader = false;
                    // localStorage.setItem("DisplayMessage", responseDetails.originalError.error.message);
                    localStorage.setItem("DisplayMessage", "Unable to submit the document.");
                    localStorage.setItem("MessageType", "Warning");
                    if (responseDetails.originalError.error.returnUrl == "Info/Index") {
                        returnUrl = RouteUrls.Info;
                        this.commonService.getReturnUrlDirection(returnUrl);
                    }
                }
                this.cdRef.detectChanges();
            });
    }


    isTermsModelPopupExists() {
        if (this.documentControlsResponse.EnvelopeInfo.IsDisclaimerEnabled === true && localStorage.getItem('prefill') !== 'prefill') {
            this.modalService.open(this.termsOfService, { windowClass: 'termofService-class', keyboard: false, backdrop: 'static' });

        } else {
            if (this.IsEnableClickToSign) {
                this.enableClickToSign();
            }
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    onAgree() {
        this.modalService.dismissAll(this.termsOfService);
        this.enableClickToSign();
        if (this.byDefaultShowCtrlTooltip === false && this.IsEnableClickToSign === false) {
            this.defaultControlShowTooltip();
            this.byDefaultShowCtrlTooltip = true;
        } else if (this.byDefaultShowCtrlTooltip === false && this.IsEnableClickToSign !== true) {
            this.defaultControlShowTooltip();
            this.byDefaultShowCtrlTooltip = true;
        }
    }

    enableClickToSign() {
        this.showLoader = true;
        if (this.IsEnableClickToSign) {
            this.showLoader = true;
            let profileDetails: any;
            profileDetails = this.getCurrentSessionUserDtls();
            if (profileDetails && profileDetails.length > 0) {
            }
            else if (!profileDetails || profileDetails.length == 0) {
                let signerTimeStamp: any = this.commonService.getCookie("signerProfileTimeStamp");
                let signerProfileLocal: any = localStorage.getItem("SignerProfileDetails");

                if (!this.commonService.isNumber(signerTimeStamp)) {
                    signerTimeStamp = new Date().valueOf();
                    this.commonService.setCookie("signerProfileTimeStamp", signerTimeStamp, 14);
                }

                if (signerProfileLocal && signerProfileLocal != "[object Object]" && signerProfileLocal != "null" && signerProfileLocal != "undefined") {
                    signerProfileLocal = JSON.parse(signerProfileLocal);
                    if (signerProfileLocal && signerProfileLocal.length > 0) {
                        let signerProfileObj: any = {};
                        signerProfileObj = signerProfileLocal.filter((element: any) => element.recipientEmailId == "Static" && element.signerProfileTimeStamp == signerTimeStamp);
                        signerProfileObj.signerProfileTimeStamp = signerTimeStamp;
                        sessionStorage.setItem("SignerProfileDetails", JSON.stringify([signerProfileObj]));
                    }
                }
                profileDetails = this.getCurrentSessionUserDtls();
            }

            let ctrluserData: any = this.documentControlsResponse.userdata;
            if (this.isNullOrEmptyCheck(ctrluserData) && (!profileDetails || profileDetails.length == 0)) {
                let userData: any = this.documentControlsResponse.userdata;
                this.clickToSignPopupStyle = 'block';
                this.addModelPopupClassForBodyTag();

                this.bindDataForSignerProfile(userData);
                let btnAccept: any = document.getElementById("btnAccept");
                btnAccept?.setAttribute('disabled', 'disabled');
                this.updateSignerProfilePopupheight();
            }
            else if (profileDetails != null && profileDetails.length > 0 && profileDetails[0].AcceptandSign != 0 && this.isNullOrEmptyCheck(profileDetails[0].imgProfileSignaturerc)) {
                if (this.isNullOrEmptyCheck(profileDetails[0].imgProfileSignaturerc)) {
                    this.bindsignerPopupDtls();

                    this.clickToSignPopupStyle = 'none';
                    this.removeModelPopupClassForBodyTag();
                    this.removeSrcForSignatureControls();
                    this.removeSrcForInitialControls();
                    this.getTotalRemaingFieldsControls('');
                }
                else {
                    this.clickToSignPopupStyle = 'block';
                    this.addModelPopupClassForBodyTag();

                    let btnAccept: any = document.getElementById("btnAccept");
                    btnAccept?.setAttribute('disabled', 'disabled');
                    this.updateSignerProfilePopupheight();
                }
            }
            else if (profileDetails != null && profileDetails.length > 0 && profileDetails[0].decline == 1 && profileDetails[0].AcceptandSign != 1) {
                if (profileDetails[0].decline == 1) {

                }
                else if (this.isNullOrEmptyCheck(profileDetails[0].imgProfileSignaturerc)) {
                    this.bindsignerPopupDtls();
                    this.clickToSignPopupStyle = 'none';
                    this.removeModelPopupClassForBodyTag();
                    this.removeSrcForSignatureControls();
                    this.removeSrcForInitialControls();
                    this.getTotalRemaingFieldsControls('');
                }
                else {
                    this.clickToSignPopupStyle = 'block';
                    this.addModelPopupClassForBodyTag();

                    let btnAccept: any = document.getElementById("btnAccept");
                    btnAccept?.setAttribute('disabled', 'disabled');
                    this.updateSignerProfilePopupheight();
                }
            }
            else {
                this.bindsignerPopupDtls();
            }
            this.showLoader = false;
        }
        this.showLoader = false;
    }

    removeSrcForSignatureControls() {
        let signatureControlsArray: string[] = [];
        Array.from(document.getElementsByClassName("commonSignatureControl") as HTMLCollectionOf<HTMLElement>)
            .forEach((popUpItem: any) => {
                let controlHtmlID: any = popUpItem.attributes["Id"].value;
                let signatureCtrl: any = document.getElementById("control_" + controlHtmlID);
                let cntType = popUpItem.attributes["data-ctntype"].value;
                if (cntType == "Signature") {
                    let signatureImageSrcAttr: any = signatureCtrl.attributes["src"];
                    if (this.isNullOrEmptyCheck(signatureImageSrcAttr)) {
                        signatureCtrl.removeAttribute('src');
                        signatureCtrl.style['height'] = "";
                        signatureCtrl.style['width'] = "";

                        popUpItem.getElementsByClassName("signature")[0].classList.remove("after_sign");
                        popUpItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                        popUpItem.getElementsByClassName("signature")[0].removeAttribute('background');
                        popUpItem.getElementsByClassName("signature")[0].style["background"] = "";
                        popUpItem.getElementsByClassName("signature")[0].classList.add("updatesignature");
                        popUpItem.getElementsByClassName("signature")[0].classList.add("btn_sign");
                        popUpItem.getElementsByClassName("btn_sign")[0].style["background-color"] = "#eef2f5 !important";

                        if (popUpItem.getElementsByClassName("stampicon").length > 0) {
                            popUpItem.getElementsByClassName("stampicon")[0].classList.remove("filledControlsStamp");
                        }
                    }
                    else {
                        popUpItem.getElementsByClassName("signature")[0].classList.remove("after_sign");
                        popUpItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                        popUpItem.getElementsByClassName("signature")[0].removeAttribute('background');
                        popUpItem.getElementsByClassName("signature")[0].style["background"] = "";
                        popUpItem.getElementsByClassName("signature")[0].classList.add("updatesignature");
                        popUpItem.getElementsByClassName("signature")[0].classList.add("btn_sign");
                        popUpItem.getElementsByClassName("btn_sign")[0].style["background-color"] = "#eef2f5 !important";
                    }
                    signatureControlsArray.push(controlHtmlID);
                }
            });


        if (signatureControlsArray && signatureControlsArray.length > 0) {
            for (let index = 0; index < signatureControlsArray.length; index++) {
                const element = signatureControlsArray[index];
                this.applyStylesToCheckList("remove", element);
            }
        }
    }

    removeSrcForInitialControls() {
        let initialControlsArray: string[] = [];
        Array.from(document.getElementsByClassName("newInitialcommonSignatureControl") as HTMLCollectionOf<HTMLElement>)
            .forEach((popUpItem: any) => {
                let controlHtmlID: any = popUpItem.attributes["Id"].value;
                let initialCtrl: any = document.getElementById("control_" + controlHtmlID);
                let cntType = popUpItem.attributes["data-ctntype"].value;
                if (cntType == "NewInitials") {
                    let initialImageSrcAttr: any = initialCtrl.attributes["src"];
                    if (this.isNullOrEmptyCheck(initialImageSrcAttr)) {
                        initialCtrl.removeAttribute('src');
                        popUpItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                        popUpItem.getElementsByClassName("newInitialsignature")[0].classList.add("updatenewInitial");
                        popUpItem.getElementsByClassName("updatenewInitial")[0].style["background"] = "none";
                    }
                    else {
                        popUpItem.getElementsByClassName("newInitialsignature")[0].classList.add("updatenewInitial");
                        if (popUpItem.getElementsByClassName("signerControl").length > 0) {
                            popUpItem.getElementsByClassName("signerControl")[0].style["background"] = "none";
                            popUpItem.getElementsByClassName("signerControl")[0].style["background"] = "#eef2f5";
                        }
                    }
                    initialControlsArray.push(controlHtmlID);
                }
            });


        if (initialControlsArray && initialControlsArray.length > 0) {
            for (let index = 0; index < initialControlsArray.length; index++) {
                const element = initialControlsArray[index];
                this.applyStylesToCheckList("remove", element);
            }
        }
    }

    bindDataForSignerProfile(userData: any) {
        let txtsignerProfileName: any = document.getElementById("txtsignerProfileName");
        txtsignerProfileName.value = userData.UserName;

        let imgProfileSignature: any = document.getElementById("imgProfileSignature");
        if (imgProfileSignature != undefined && imgProfileSignature != null) {
            if (userData.UserSignatureImgSrc != undefined && userData.UserSignatureImgSrc != null) {
                imgProfileSignature.setAttribute("src", userData.UserSignatureImgSrc);
            }
        }

        let initialData: any = "";
        if (this.isNullOrEmptyCheck(userData.UserInitials)) {
            initialData = userData.UserInitials;
        }
        else {
            if (this.isNullOrEmptyCheck(userData.UserName) && this.isNullOrEmptyCheck(userData.UserName.trim())) {
                initialData = ((userData.UserName).match(/\b(\w)/g).join('')).substring(0, 4);
            }
        }
        let txtsignerProfileInitials: any = document.getElementById("txtsignerProfileInitials");
        txtsignerProfileInitials.value = initialData.trim();

        this.UpdateSignimageFromSignerProfileText(0);
        this.UpdateInitialsimageFromSignerProfileText();
    }

    UpdateSignimageFromSignerProfileText(val: any) {
        setTimeout(() => {
            if (val == 0) {
                this.showLoader = true;
            }
            let imgProfileSignature: any = document.getElementById("imgProfileSignature");
            let txtsignerProfileName: any = document.getElementById("txtsignerProfileName");
            let txtSignVal = txtsignerProfileName.value;
            let userData: any = this.documentControlsResponse.userdata;
            let userInitialsImgSrc: any = userData.UserInitialsImgSrc;
            if (this.isNullOrEmptyCheck(txtSignVal.trim()) && txtSignVal.trim().length >= 1 && txtSignVal.trim().length < 51) {
                //Call API ConvertTextToSignImage
                let requestObj = {
                    text: txtSignVal.trim(),
                    font: "HomemadeApple",
                    fontsize: "13px",
                    fontColor: "#000000",
                    height: "13",
                    width: "13",
                    envelopeCode: localStorage.getItem("eDisplayCode"),
                    electronicSignIndicationId: this.documentControlsResponse.EnvelopeInfo.ElectronicSignIndication,
                    dateFormat: this.documentControlsResponse.DateFormat,
                    userTimezone: this.documentControlsResponse.EnvelopeInfo.TimeZoneSettingOptionValue,
                    dateFormatID: this.documentControlsResponse.EnvelopeInfo.DateFormatID
                };

                let url = CommonConstants.ConvertTextToSignImage;
                this.signerLandingService.convertTextToSignImage(url, requestObj).subscribe(
                    (resp: any) => {
                        if (resp && resp.StatusCode == 200) {
                            if (imgProfileSignature != undefined && imgProfileSignature != null) {
                                if (val === 0) {
                                    if (userInitialsImgSrc === null || userInitialsImgSrc === undefined) {
                                        imgProfileSignature.setAttribute("src", resp.ResponseConvertTextToSignImage.imgsrc);
                                    } else {
                                        imgProfileSignature.setAttribute("src", userInitialsImgSrc);
                                    }
                                } else {
                                    imgProfileSignature.setAttribute("src", resp.ResponseConvertTextToSignImage.imgsrc);
                                }

                                // imgProfileSignature.setAttribute("src", resp.ResponseConvertTextToSignImage.imgsrc);

                                imgProfileSignature.style['height'] = "98px";
                                if (this.webOrMobile == 'mobile') {
                                    imgProfileSignature.style['padding-bottom'] = "15px";
                                    imgProfileSignature.style['height'] = "88px";
                                } else {
                                    imgProfileSignature.style['padding-bottom'] = "10px";
                                    imgProfileSignature.style['height'] = "98px";
                                }
                            }
                        }
                        this.showLoader = false;
                        this.cdRef.detectChanges();
                    });
            }
            else {
                if (imgProfileSignature != undefined && imgProfileSignature != null) {
                    imgProfileSignature.setAttribute("src", "../../../assets/images/lisa.png");
                    imgProfileSignature.style['height'] = "98px";
                }
                this.showLoader = false;
                this.cdRef.detectChanges();
            }
            this.cdRef.detectChanges();
        }, 0);
    }

    UpdateInitialsimageFromSignerProfileText() {
        setTimeout(() => {
            let imgProfNewInitials: any = document.getElementById("imgProfNewInitials");
            let txtsignerProfileInitials: any = document.getElementById("txtsignerProfileInitials");
            let txtInitialVal = txtsignerProfileInitials.value;
            if (this.isNullOrEmptyCheck(txtInitialVal.trim()) && txtInitialVal.trim().length >= 1 && txtInitialVal.trim().length < 5) {
                let requestObj = {
                    text: txtInitialVal.trim(),
                    font: "DancingScript",
                    fontsize: "13px",
                    fontColor: "#000000",
                    height: "13",
                    width: "13"
                };

                let url = CommonConstants.ConvertTextToSignImage;
                this.signerLandingService.convertTextToSignImage(url, requestObj).subscribe(
                    (resp: any) => {
                        if (resp && resp.StatusCode == 200) {
                            if (imgProfNewInitials != undefined && imgProfNewInitials != null) {
                                imgProfNewInitials.setAttribute("src", resp.ResponseConvertTextToSignImage.imgsrc);
                                imgProfNewInitials.style['height'] = "98px";
                            }
                        }
                        this.showLoader = false;
                        this.cdRef.detectChanges();
                    });
            }
            else {
                if (imgProfNewInitials != undefined && imgProfNewInitials != null) {
                    imgProfNewInitials.setAttribute("src", "");
                    imgProfNewInitials.style['height'] = "98px";
                }
                this.showLoader = false;
                this.cdRef.detectChanges();
            }
            this.cdRef.detectChanges();
        }, 0);
    }

    UpdateSigAndInitialProfileText(val: any) {
        localStorage.setItem('isUploadImgFlag', '0');
        this.UpdateSignimageFromSignerProfileText(val);
        let txtsignerProfileName: any = document.getElementById("txtsignerProfileName");
        let txtProfileName = txtsignerProfileName.value.trim();
        let initialData = '';
        if (txtProfileName && txtProfileName != '') {
            initialData = ((txtProfileName).match(/\b(\w)/g).join('')).substring(0, 4);
            let txtsignerProfileInitials: any = document.getElementById("txtsignerProfileInitials");
            txtsignerProfileInitials.value = initialData.trim();
            this.UpdateInitialsimageFromSignerProfileText();
        }
        this.cdRef.detectChanges();
    }

    onEnableAcceptAndSign(event: any) {
        let btnAccept: any = document.getElementById("btnAccept");
        if (event.target.checked) {
            btnAccept?.removeAttribute('disabled');
            // btnAccept.style.cursor = "";
        }
        else if (!event.target.checked) {
            btnAccept?.setAttribute('disabled', 'disabled');
            // btnAccept.style.cursor = "no-drop";
        }
    }

    onAcceptAndSign() {
        let chkterms: any = document.getElementById("chkterms");
        chkterms?.setAttribute('disabled', 'disabled');
        chkterms.style.cursor = "no-drop";
        localStorage.setItem("isDisabled", "true");
        localStorage.setItem("isAcceptAndSignClicked", "true");
        let txtsignerProfileName: any = document.getElementById("txtsignerProfileName");
        localStorage.setItem("txtsignerProfileNameValue", txtsignerProfileName.value);
        let txtsignerProfileInitials: any = document.getElementById("txtsignerProfileInitials");
        localStorage.setItem("txtsignerProfileInitialsValue", txtsignerProfileInitials.value);

        this.setSessionUserProfileDetails();
        this.clickToSignPopupStyle = 'none';
        this.removeModelPopupClassForBodyTag();
        this.modalService.dismissAll();
        this.getTotalRemaingFieldsControls('');

        if (this.byDefaultShowCtrlTooltip === false) {
            setTimeout(() => {
                this.defaultControlShowTooltip();
            }, 500);
            this.byDefaultShowCtrlTooltip = true;
        }
    }

    setSessionUserProfileDetails() {
        let chkterms: any = document.getElementById("chkterms");
        let imgProfileSignature: any = document.getElementById("imgProfileSignature");
        let imgProfNewInitials: any = document.getElementById("imgProfNewInitials");
        let txtsignerProfileName: any = localStorage.getItem("txtsignerProfileNameValue");
        let txtsignerProfileInitials: any = localStorage.getItem("txtsignerProfileInitialsValue");

        let isTermsChecked = chkterms.attributes["disabled"].value;
        let signSrc = imgProfileSignature.attributes["src"].value;
        let initialSrc = imgProfNewInitials.attributes["src"].value;
        let isTermsAndConditionsChecked: boolean = false;

        if (isTermsChecked != "disabled") {
            this.showToaster('Please select terms and conditions.', 'Warning'); return;
        }
        else {
            isTermsAndConditionsChecked = true;
        }

        if (signSrc == "" || signSrc == undefined || signSrc == null || signSrc.indexOf("lisa.png") != -1) {
            this.showToaster('Please enter Signature.', 'Warning'); return;
        }

        if (initialSrc == "" || initialSrc == undefined || initialSrc == null) {
            this.showToaster('Please enter Initials.', 'Warning'); return;
        }

        let valueToPush: any = {};
        let StorageValues = [];
        let signerTimeStamp: any = this.commonService.getCookie("signerProfileTimeStamp");
        if (!signerTimeStamp || !this.commonService.isNumber(signerTimeStamp)) {
            signerTimeStamp = new Date().valueOf();
            sessionStorage.setItem("signerProfileTimeStamp", signerTimeStamp);
            this.commonService.setCookie("signerProfileTimeStamp", signerTimeStamp, 14);
        }

        valueToPush.signerProfileName = txtsignerProfileName.trim();
        valueToPush.signerProfileInitials = txtsignerProfileInitials.trim();
        valueToPush.imgProfileSignaturerc = signSrc;
        valueToPush.imgProfNewInitialssrc = initialSrc;
        valueToPush.checkTermsAndCond = isTermsAndConditionsChecked;
        valueToPush.signerProfileTimeStamp = signerTimeStamp;
        valueToPush.decline = 0;
        valueToPush.AcceptandSign = 1;

        let isStatic: any = localStorage.getItem("isStatic");
        if (isStatic.length == 1 || isStatic.toLowerCase() == "false") {
            valueToPush.recipientEmailId = localStorage.getItem("recipientEmail");
        }
        else if (this.IsStaticTemplate == true || isStatic == "true") {
            valueToPush.recipientEmailId = "Static";
        }

        StorageValues.push(valueToPush);
        sessionStorage.setItem("SignerProfileDetails", JSON.stringify(StorageValues));
        localStorage.setItem("SignerProfileDetails", JSON.stringify(StorageValues));


        //localStorage.setItem("txtsignerProfileName", txtsignerProfileName);

        if (sessionStorage.getItem('isSignerpopupCount') == null || sessionStorage.getItem('isSignerpopupCount') == undefined) {
            sessionStorage.setItem("isSignerpopupCount", this.isSignerpopupCount);
        }

        if (sessionStorage.getItem('isSignerpopupCount') != "0" && this.isSignerpopupCount != 0) {
            sessionStorage.setItem("isSignerpopupCount", this.isSignerpopupCount);
        }

        this.updateAllNameControlsValue(txtsignerProfileName);
        this.changeImageSrcForSignatureControls(StorageValues);
        this.changeImageSrcForInitialControls(StorageValues);

        let issignerpopupCount: any = sessionStorage.getItem('isSignerpopupCount');
        if (parseInt(issignerpopupCount) > 0) {
            this.getControlNextNav('');
        }
        issignerpopupCount = parseInt(issignerpopupCount) + 1;
        sessionStorage.setItem('isSignerpopupCount', issignerpopupCount);
    }

    updateAllNameControlsValue(profileName: any) {
        let nameControlsArray: string[] = [];
        Array.from(document.getElementsByClassName("nameControlClass") as HTMLCollectionOf<HTMLElement>)
            .forEach((popUpItem: any) => {
                let controlHtmlID: any = popUpItem.attributes["Id"].value;
                let inputNameCtrl: any = document.getElementById("control_" + controlHtmlID);
                let cntType = popUpItem.attributes["data-ctntype"].value;
                if (cntType == "Name") {
                    inputNameCtrl.value = profileName;
                    inputNameCtrl.style['background'] = "none";
                    nameControlsArray.push(controlHtmlID);
                    popUpItem.getElementsByClassName("additionalInfo")[0].classList.add("filledControls");
                }
            });


        if (nameControlsArray && nameControlsArray.length > 0) {
            for (let index = 0; index < nameControlsArray.length; index++) {
                const element = nameControlsArray[index];
                this.applyStylesToCheckList("add", element);
            }
        }
    }

    changeImageSrcForInitialControls(StorageValues: any) {
        if (sessionStorage.length > 0 && sessionStorage.getItem('isSignerpopupCount') == "0") {
            let storevalues = StorageValues;
            let initialControlsArray: string[] = [];
            Array.from(document.getElementsByClassName("newInitialcommonSignatureControl") as HTMLCollectionOf<HTMLElement>)
                .forEach((popUpItem: any) => {
                    let controlHtmlID: any = popUpItem.attributes["Id"].value;
                    let initialCtrl: any = document.getElementById("control_" + controlHtmlID);
                    let cntType = popUpItem.attributes["data-ctntype"].value;
                    if (cntType == "NewInitials") {
                        let initialImageSrcAttr: any = initialCtrl.attributes["src"];
                        if (this.isNullOrEmptyCheck(initialImageSrcAttr)) {
                            initialCtrl.removeAttribute('src');

                            if (popUpItem && popUpItem.getElementsByClassName("additionalInfo").length > 0) {
                                popUpItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                            }

                            if (popUpItem && popUpItem.getElementsByClassName("newInitialsignature").length > 0) {
                                popUpItem.getElementsByClassName("newInitialsignature")[0].classList.add("updatenewInitial");

                                if (popUpItem.getElementsByClassName("signerControl").length > 0) {
                                    popUpItem.getElementsByClassName("signerControl")[0].style["background"] = "none";
                                    popUpItem.getElementsByClassName("signerControl")[0].style["background"] = "#eef2f5";
                                }
                            }

                            if (popUpItem && popUpItem.getElementsByClassName("updatenewInitial").length > 0) {
                                popUpItem.getElementsByClassName("updatenewInitial")[0].style["background"] = "none";
                            }
                        }
                        else {
                            if (popUpItem && popUpItem.getElementsByClassName("newInitialsignature").length > 0) {
                                popUpItem.getElementsByClassName("newInitialsignature")[0].classList.add("updatenewInitial");
                                if (popUpItem.getElementsByClassName("signerControl").length > 0) {
                                    popUpItem.getElementsByClassName("signerControl")[0].style["background"] = "none";
                                    popUpItem.getElementsByClassName("signerControl")[0].style["background"] = "#eef2f5";
                                }
                            }
                        }
                        initialControlsArray.push(controlHtmlID);
                    }
                });

            if (initialControlsArray && initialControlsArray.length > 0) {
                for (let index = 0; index < initialControlsArray.length; index++) {
                    const element = initialControlsArray[index];
                    this.applyStylesToCheckList("remove", element);
                }
            }
        }

        let issignerpopupCount: any = sessionStorage.getItem('isSignerpopupCount');
        if (this.isNullOrEmptyCheck(issignerpopupCount)) {
            let issignerpopupCountVal: number = parseInt(issignerpopupCount);
            if (issignerpopupCountVal > 0) {
                let storevalues = StorageValues;
                let initialControlsArray: string[] = [];
                Array.from(document.getElementsByClassName("newInitialcommonSignatureControl") as HTMLCollectionOf<HTMLElement>)
                    .forEach((popUpItem: any) => {
                        let controlHtmlID: any = popUpItem.attributes["Id"].value;
                        let initialCtrl: any = document.getElementById("control_" + controlHtmlID);
                        let cntType = popUpItem.attributes["data-ctntype"].value;
                        if (cntType == "NewInitials") {
                            let initialImageSrcAttr: any = initialCtrl.attributes["src"];
                            if (this.isNullOrEmptyCheck(initialImageSrcAttr)) {
                                if (popUpItem && popUpItem.getElementsByClassName("updatenewInitial").length > 0) {
                                    popUpItem.getElementsByClassName("updatenewInitial")[0].style["background"] = "none";
                                    popUpItem.getElementsByClassName("updatenewInitial")[0].style["background"] = "none";
                                }

                                popUpItem.style['background'] = 'none';
                                initialCtrl.setAttribute("src", storevalues[0].imgProfNewInitialssrc);
                                initialCtrl.style['width'] = 'auto';
                                initialCtrl.style['height'] = "22px";
                                popUpItem.style['background'] = 'none';

                                if (popUpItem && popUpItem.getElementsByClassName("additionalInfo").length > 0) {
                                    popUpItem.getElementsByClassName("additionalInfo")[0].classList.add("filledControls");
                                }

                                if (popUpItem && popUpItem.getElementsByClassName("newInitialsignature").length > 0) {
                                    popUpItem.getElementsByClassName("newInitialsignature")[0].style["background"] = "none";
                                    popUpItem.getElementsByClassName("newInitialsignature")[0].classList.remove("updatenewInitial");
                                }

                                if (popUpItem && popUpItem.getElementsByClassName("signerControl").length > 0) {
                                    popUpItem.getElementsByClassName("signerControl")[0].style["background"] = "none";
                                }
                            }
                            initialControlsArray.push(controlHtmlID);
                        }
                    });

                if (initialControlsArray && initialControlsArray.length > 0) {
                    for (let index = 0; index < initialControlsArray.length; index++) {
                        const element = initialControlsArray[index];
                        this.applyStylesToCheckList("add", element);
                    }
                }
            }
        }
    }

    changeImageSrcForSignatureControls(StorageValues: any) {
        if (sessionStorage.length > 0 && sessionStorage.getItem('isSignerpopupCount') == "0") {
            let signatureControlsArray: string[] = [];
            Array.from(document.getElementsByClassName("commonSignatureControl") as HTMLCollectionOf<HTMLElement>)
                .forEach((popUpItem: any) => {
                    let controlHtmlID: any = popUpItem.attributes["Id"].value;
                    let signatureCtrl: any = document.getElementById("control_" + controlHtmlID);
                    let cntType = popUpItem.attributes["data-ctntype"].value;
                    if (cntType == "Signature") {
                        let signatureImageSrcAttr: any = signatureCtrl.attributes["src"];
                        if (this.isNullOrEmptyCheck(signatureImageSrcAttr)) {
                            signatureCtrl.removeAttribute('src');
                            signatureCtrl.style['height'] = "";
                            signatureCtrl.style['width'] = "";

                            if (popUpItem && popUpItem.getElementsByClassName("signature").length > 0) {
                                popUpItem.getElementsByClassName("signature")[0].classList.remove("after_sign");
                                popUpItem.getElementsByClassName("signature")[0].removeAttribute('background');
                                popUpItem.getElementsByClassName("signature")[0].style["background"] = "";
                                popUpItem.getElementsByClassName("signature")[0].classList.add("updatesignature");
                                popUpItem.getElementsByClassName("signature")[0].classList.add("btn_sign");
                            }

                            if (popUpItem && popUpItem.getElementsByClassName("additionalInfo").length > 0) {
                                popUpItem.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                            }

                            if (popUpItem && popUpItem.getElementsByClassName("btn_sign").length > 0) {
                                popUpItem.getElementsByClassName("btn_sign")[0].style["background-color"] = "#eef2f5 !important";
                            }
                        }
                        else {
                            if (popUpItem && popUpItem.getElementsByClassName("signature").length > 0) {
                                popUpItem.getElementsByClassName("signature")[0].classList.add("updatesignature");
                                popUpItem.getElementsByClassName("signature")[0].classList.add("btn_sign");
                            }
                        }

                        if (popUpItem.getElementsByClassName("stampicon").length > 0) {
                            popUpItem.getElementsByClassName("stampicon")[0].classList.remove("filledControlsStamp");
                        }

                        signatureControlsArray.push(controlHtmlID);
                    }
                });

            if (signatureControlsArray && signatureControlsArray.length > 0) {
                for (let index = 0; index < signatureControlsArray.length; index++) {
                    const element = signatureControlsArray[index];
                    this.applyStylesToCheckList("remove", element);
                }
            }
        }

        let issignerpopupCount: any = sessionStorage.getItem('isSignerpopupCount');
        if (this.isNullOrEmptyCheck(issignerpopupCount)) {
            let issignerpopupCountVal: number = parseInt(issignerpopupCount);
            if (issignerpopupCountVal > 0) {
                let storevalues = StorageValues;
                let signatureControlsArray: string[] = [];
                Array.from(document.getElementsByClassName("commonSignatureControl") as HTMLCollectionOf<HTMLElement>)
                    .forEach((popUpItem: any) => {
                        let controlHtmlID: any = popUpItem.attributes["Id"].value;
                        let signatureCtrl: any = document.getElementById("control_" + controlHtmlID);
                        let cntType = popUpItem.attributes["data-ctntype"].value;
                        if (cntType == "Signature") {
                            let signatureImageSrcAttr: any = signatureCtrl.attributes["src"];
                            if (this.isNullOrEmptyCheck(signatureImageSrcAttr)) {
                                let height = window.getComputedStyle(signatureCtrl).height;
                                let heightForSignImage = parseInt(height) - 2;
                                let imgProfileSignature: any = document.getElementById("imgProfileSignature");
                                let signSrc = imgProfileSignature.attributes["src"].value;
                                signatureCtrl.setAttribute("src", signSrc);
                                signatureCtrl.style['width'] = 'auto';
                                signatureCtrl.style['height'] = heightForSignImage + "px";

                                if (popUpItem && popUpItem.getElementsByClassName("btn_sign").length > 0) {
                                    popUpItem.getElementsByClassName("btn_sign")[0].removeAttribute('padding-left');
                                }

                                signatureCtrl.setAttribute("src", storevalues[0].imgProfileSignaturerc);
                                signatureCtrl.style['width'] = 'auto';
                                signatureCtrl.style['height'] = "34px";

                                if (popUpItem && popUpItem.getElementsByClassName("commonSignatureControl").length > 0) {
                                    popUpItem.getElementsByClassName("commonSignatureControl")[0].style["background"] = "none";
                                }

                                if (popUpItem && popUpItem.getElementsByClassName("signature").length > 0) {
                                    popUpItem.getElementsByClassName("signature")[0].style["background"] = "none";
                                    popUpItem.getElementsByClassName("signature")[0].style["padding-left"] = "0px !important";
                                    popUpItem.getElementsByClassName("signature")[0].classList.add("after_sign");
                                }

                                if (popUpItem && popUpItem.getElementsByClassName("additionalInfo").length > 0) {
                                    popUpItem.getElementsByClassName("additionalInfo")[0].classList.add("filledControls");
                                }

                                if (popUpItem.getElementsByClassName("requiredControlsStamp").length > 0) {
                                    popUpItem.getElementsByClassName("requiredControlsStamp")[0].classList.add("filledControlsStamp");
                                }
                            }
                            signatureControlsArray.push(controlHtmlID);
                        }
                    });

                if (signatureControlsArray && signatureControlsArray.length > 0) {
                    for (let index = 0; index < signatureControlsArray.length; index++) {
                        const element = signatureControlsArray[index];
                        this.applyStylesToCheckList("add", element);
                    }
                }
            }
        }

        let footerSignNav: any = document.getElementById("FooterSignNav");
        if (footerSignNav != undefined && footerSignNav != null) {
            let footerSignNavCol = footerSignNav.getElementsByTagName('img');
            if (footerSignNavCol && footerSignNavCol.length > 0) {
                footerSignNavCol[0].setAttribute("src", StorageValues[0].imgProfileSignaturerc);
                footerSignNav.getElementsByClassName("controlIndicator")[0].classList.add("filled");
                footerSignNav.getElementsByClassName("controlIndicator")[0].classList.add("filledFooterSignatureControl");
                this.applyStyleFordefaultSignNavControlIndicator();
            }
        }
    }

    openClickToSignModalOrSignaturePadModal(controlId: any) {
        var profileDetails = this.getCurrentSessionUserDtls();
        if (profileDetails && profileDetails.length > 0 && profileDetails[0].AcceptandSign != 0) {
            if (controlId == "FooterSignNav") {
                let footerSignNav: any = document.getElementById("FooterSignNav");
                if (footerSignNav != undefined && footerSignNav != null) {
                    let footerSignNavCol = footerSignNav.getElementsByTagName('img');
                    footerSignNavCol[0].setAttribute("src", profileDetails[0].imgProfileSignaturerc);
                    footerSignNav.getElementsByClassName("controlIndicator")[0].classList.add("filled");
                    footerSignNav.getElementsByClassName("controlIndicator")[0].classList.add("filledFooterSignatureControl");
                    this.applyStyleFordefaultSignNavControlIndicator();
                }
            }
            else {
                let ctrlImageAttr: any = document.getElementById("control_" + controlId);
                let ctrlImageAttrSrc: any = ctrlImageAttr.attributes["src"];
                if (this.isNullOrEmptyCheck(ctrlImageAttrSrc)) {
                    if (profileDetails[0].checkTermsAndCond == true) {
                        this.bindsignerPopupDtls();
                    }
                }
                else if (!this.isNullOrEmptyCheck(ctrlImageAttrSrc) && this.isNullOrEmptyCheck(profileDetails[0].imgProfileSignaturerc)) {
                    // console.log("set image");
                    ctrlImageAttr.setAttribute("src", profileDetails[0].imgProfileSignaturerc);
                    ctrlImageAttr.style['height'] = "34px";
                    ctrlImageAttr.style['width'] = "auto";
                    let ctrlItem: any = document.getElementById(controlId);
                    if (ctrlItem.getElementsByClassName("stampicon").length > 0) {
                        ctrlItem.getElementsByClassName("stampicon")[0].classList.add("filledControlsStamp");
                    }
                    ctrlItem.getElementsByClassName("signature")[0].style["background"] = "none";
                    ctrlItem.getElementsByClassName("signature")[0].style["padding-left"] = "0px !important";
                    ctrlItem.getElementsByClassName("signature")[0].classList.add("after_sign");

                    ctrlItem.getElementsByClassName("signature")[0].classList.remove("btn_sign");
                    ctrlItem.getElementsByClassName("signature")[0].classList.remove("updatesignature");
                    ctrlItem.getElementsByClassName("signerControl")[0].style["background"] = "none";

                    ctrlItem.style['background'] = "none";
                    ctrlItem.getElementsByClassName("additionalInfo")[0].classList.add("filledControls");
                    let ctrlImageTooltipId: any = document.getElementById('tooltip_' + controlId);
                    let srcValue: any = ctrlImageAttr.getAttribute("src");
                    if (srcValue != '' && srcValue != null && srcValue != undefined) {
                        ctrlImageTooltipId.style.display = 'none';
                        this.getControlNextNav('headerControls');
                    }

                    this.applyStylesToCheckList("add", controlId);

                }
            }
            this.getTotalRemaingFieldsControls('');
        }
        else {
            this.openSignaturePadModel(controlId);
        }
    }

    openClickToSignModalOrInitialPadModal(controlId: any) {
        var profileDetails = this.getCurrentSessionUserDtls();
        if (profileDetails && profileDetails.length > 0 && profileDetails[0].AcceptandSign != 0) {
            let ctrlImageAttr: any = document.getElementById("control_" + controlId);
            let ctrlImageAttrSrc: any = ctrlImageAttr.attributes["src"];
            if (this.isNullOrEmptyCheck(ctrlImageAttrSrc)) {
                if (profileDetails[0].checkTermsAndCond == true) {
                    this.bindsignerPopupDtls();
                }
            }
            else if (!this.isNullOrEmptyCheck(ctrlImageAttrSrc) && this.isNullOrEmptyCheck(profileDetails[0].imgProfNewInitialssrc)) {
                //  console.log("set image");
                ctrlImageAttr.setAttribute("src", profileDetails[0].imgProfNewInitialssrc);
                ctrlImageAttr.style['height'] = "22px";
                ctrlImageAttr.style['width'] = "auto";
                ctrlImageAttr.style['max-width'] = "100%";

                let ctrlItem: any = document.getElementById(controlId);
                ctrlItem.getElementsByClassName("updatenewInitial")[0].style["background"] = "none";
                ctrlItem.style['background'] = 'none';
                ctrlItem.getElementsByClassName("additionalInfo")[0].classList.add("filledControls");
                ctrlItem.getElementsByClassName("newInitialsignature")[0].style["background"] = "none";
                ctrlItem.getElementsByClassName("signerControl")[0].style["background"] = "none";
                ctrlItem.getElementsByClassName("newInitialsignature")[0].classList.remove("updatenewInitial");
                let ctrlImageTooltipId: any = document.getElementById('tooltip_' + controlId);
                let srcValue: any = ctrlImageAttr.getAttribute("src");
                if (srcValue != '' && srcValue != null && srcValue != undefined) {
                    ctrlImageTooltipId.style.display = 'none';
                    this.getControlNextNav('documentControls');
                }

                this.applyStylesToCheckList("add", controlId);
            }
            this.getTotalRemaingFieldsControls('');
        }
        else {
            this.openInitialPadModel(controlId);
        }
    }

    getCurrentSessionUserDtls() {
        let profileDetails = [];
        let signerProfileDetails: any = sessionStorage.getItem("SignerProfileDetails");
        if (signerProfileDetails && signerProfileDetails != "[object Object]" && signerProfileDetails != "null" && signerProfileDetails != "undefined" && signerProfileDetails != "[]") {
            profileDetails = JSON.parse(signerProfileDetails);
            if (profileDetails != null && profileDetails.length > 0 && !profileDetails[0].hasOwnProperty("signerProfileName")) {
                profileDetails = [];
            }
        }
        return profileDetails;
    }

    bindsignerPopupDtls() {
        var profileDetails = this.getCurrentSessionUserDtls();
        if (profileDetails && profileDetails.length > 0) {
            // console.log(profileDetails);
            profileDetails = profileDetails.filter((element: any) => element.recipientEmailId == "Static" || element.recipientEmailId == localStorage.getItem("recipientEmail"));
            if (profileDetails && profileDetails.length > 0) {
                this.clickToSignPopupStyle = 'block';
                this.addModelPopupClassForBodyTag();

                let txtsignerProfileName: any = document.getElementById("txtsignerProfileName");
                txtsignerProfileName.value = profileDetails[0].signerProfileName.trim();
                let txtsignerProfileInitials: any = document.getElementById("txtsignerProfileInitials");
                txtsignerProfileInitials.value = profileDetails[0].signerProfileInitials.trim();

                let imgProfileSignature: any = document.getElementById("imgProfileSignature");
                if (imgProfileSignature != undefined && imgProfileSignature != null) {
                    imgProfileSignature.setAttribute("src", profileDetails[0].imgProfileSignaturerc);
                    if (this.webOrMobile == 'mobile') {
                        imgProfileSignature.style['padding-bottom'] = "15px";
                    } else {
                        imgProfileSignature.style['padding-bottom'] = "10px";
                    }

                }

                let imgProfNewInitials: any = document.getElementById("imgProfNewInitials");
                if (imgProfNewInitials != undefined && imgProfNewInitials != null) {
                    imgProfNewInitials.setAttribute("src", profileDetails[0].imgProfNewInitialssrc);
                    if (this.webOrMobile == 'mobile') {
                        imgProfNewInitials.style['padding-bottom'] = "15px";
                    } else {
                        imgProfNewInitials.style['padding-bottom'] = "10px";
                    }
                }

                let btnAccept: any = document.getElementById("btnAccept");
                let isTermsDisabled: any = localStorage.getItem("isDisabled");
                let chkterms: any = document.getElementById("chkterms");
                if (profileDetails[0].AcceptandSign == true) {
                    chkterms?.setAttribute('disabled', 'disabled');
                    chkterms?.setAttribute('checked', 'checked');
                    this.clicktoSignAgree = true;
                    chkterms.style.cursor = "no-drop";
                    btnAccept?.removeAttribute('disabled');
                }
                else {
                    chkterms?.removeAttribute('disabled');
                    chkterms?.removeAttribute('checked');
                    this.clicktoSignAgree = false;
                    chkterms.style.cursor = "pointer";
                    btnAccept?.setAttribute('disabled', 'disabled');
                }
                this.updateSignerProfilePopupheight();
            }
        }
    }

    //dvDGIntermediate popup starts
    opendvDGIntermediatePopup() {
        this.dvDGIntermediateDisplayStyle = 'block';
    }

    closedvDGIntermediatePopup() {
        this.dvDGIntermediateDisplayStyle = 'none';
    }

    onBtnSignAnotherDocument() {
        this.showLoader = true;
        let signAnotherDocUrl = this.signAnotherDocumentUrl;
        let signingUrl = decodeURI(signAnotherDocUrl.split('?')[1]);
        let signerLandingUrl = environment.uiAngularBaseUrl + RouteUrls.SignerLanding + "?";
        signerLandingUrl = signerLandingUrl + signingUrl;
        sessionStorage.setItem("isFromSignMultiTemplate", "true");
        window.location.href = signerLandingUrl;
    }

    onConfirmBackToMultiViewDocuments() {
        this.showLoader = true;
        this.commonService.getReturnUrlDirection(RouteUrls.SignMultiTemplate);
    }
    //dvDGIntermediate popup ends


    getTotalRemaingFieldsControls(type: any) {
        this.remainingFieldsTotalCount = 0;
        this.remainingFieldsCount = 0;

        let visibleControls: any = this.getAllVisibleDocumentControls();
        this.remainingFieldsTotalCount = visibleControls.length;
        this.remainingFieldsCount = this.getOnlyControlsPendingCount(visibleControls);

        // this.showFinishModalPopup();

        if (this.remainingFieldsCount == 0) {
            if (type === '') {
                this.hideAllMobilePrevNextDoneBtns();
            }
           // localStorage.setItem('selectedNextTabindex', '');
            this.hideToolTip(null);
        }
    }

    getOnlyControlsPendingCount(visibleControls: any) {
        let controls: any = visibleControls; //this.getAllVisibleDocumentControls();
        let controlCollection: any = [];
        Array.from(controls).forEach((ctrlItem: any) => {
            let ctrlId = ctrlItem.attributes["id"].value;
            let controlType = ctrlItem.attributes["data-ctntype"].value;
            let ctrlReq: any;
            let ctrlValue: any;
            switch (controlType) {
                case "Signature":
                    let sigImageCollection = ctrlItem.getElementsByTagName('img');
                    ctrlValue = sigImageCollection[0].attributes["src"] != undefined ? sigImageCollection[0].attributes["src"].value : '';

                    if (ctrlValue == '' || ctrlValue == null || ctrlValue == undefined) {
                        controlCollection.push(ctrlId);
                    }
                    break;
                case "NewInitials":
                    let initialImageCollection = ctrlItem.getElementsByTagName('img');
                    ctrlValue = initialImageCollection[0].attributes["src"] != undefined ? initialImageCollection[0].attributes["src"].value : '';
                    if (ctrlValue == '' || ctrlValue == null || ctrlValue == undefined) {
                        controlCollection.push(ctrlId);
                    }
                    break;
                case "Text":
                    let currentTextCtrl: any = document.getElementById("control_" + ctrlId);
                    let currentTextCtrlTitle: any = currentTextCtrl.attributes['data-title'] != undefined ? currentTextCtrl.attributes['data-title'].value : '';
                    if (currentTextCtrlTitle === 'Date') {
                        ctrlValue = currentTextCtrl.value != undefined ? currentTextCtrl.value : '';
                    }
                    else if (currentTextCtrlTitle === 'Email') {
                        ctrlValue = currentTextCtrl.value != undefined ? currentTextCtrl.value : '';
                    }
                    else {
                        ctrlValue = ctrlItem.getElementsByTagName("textarea") != undefined ? ctrlItem.getElementsByTagName("textarea")[0].value : '';
                    }

                    ctrlValue = ctrlValue != undefined ? ctrlValue : '';
                    if (ctrlValue == '' || ctrlValue == null || ctrlValue == undefined) {
                        controlCollection.push(ctrlId);
                    }

                    break;
                case "Email":
                    let currEmailCtrl: any = document.getElementById("control_" + ctrlId);
                    ctrlValue = currEmailCtrl.value != undefined ? currEmailCtrl.value : '';
                    ctrlValue = ctrlValue != undefined ? ctrlValue : '';

                    if (ctrlValue == '' || ctrlValue == null || ctrlValue == undefined) {
                        controlCollection.push(ctrlId);
                    }
                    break;
                case "Name":
                case "Date":
                case "Initials":
                case "Title":
                case "Company":
                    let currCtrl: any = document.getElementById("control_" + ctrlId);
                    ctrlValue = currCtrl.value != undefined ? currCtrl.value : '';
                    ctrlValue = ctrlValue != undefined ? ctrlValue : ''
                    if (ctrlValue == '' || ctrlValue == null || ctrlValue == undefined) {
                        controlCollection.push(ctrlId);
                    }
                    break;
                case "Checkbox":
                    let curCheckboxctrl: any = document.getElementById("control_" + ctrlId);
                    let isChecked: string = "false";
                    isChecked = curCheckboxctrl.checked ? "true" : "false";
                    ctrlValue = isChecked;

                    if (ctrlValue == '' || ctrlValue == null || ctrlValue == undefined || ctrlValue == 'false') {
                        var name = (<HTMLInputElement>document.getElementById("control_" + ctrlId)).getAttribute("name");
                        if (name != "") {
                            if ($('.signerControl input:checkbox[name="' + name + '"]:checked').length == 0) {
                                controlCollection.push(ctrlId);
                            }
                        }
                        else {
                            controlCollection.push(ctrlId);
                        }
                    }
                    break;
                case "Radio":
                    let curRadioctrl: any = document.getElementById("control_" + ctrlId);
                    let isRadioChecked: string = "false";
                    isRadioChecked = curRadioctrl.checked ? "true" : "false";
                    ctrlValue = isRadioChecked;
                    if (ctrlValue == '' || ctrlValue == null || ctrlValue == undefined || ctrlValue == 'false') {
                        var name = (<HTMLInputElement>document.getElementById("control_" + ctrlId)).getAttribute("name");
                        if (name != "" && $('.signerControl input:radio[name="' + name + '"]:checked').length == 0) {
                            controlCollection.push(ctrlId);
                        }
                    }
                    break;
                case "DropDown":
                    let currentdrpCtrl: any = (document.getElementById("control_" + ctrlId)) as HTMLSelectElement;
                    var sel = currentdrpCtrl.selectedIndex;
                    var opt = currentdrpCtrl.options[sel];
                    var curValue = (<HTMLSelectElement>opt).value;
                    ctrlValue = curValue;
                    if (ctrlValue == '' || ctrlValue == null || ctrlValue == undefined) {
                        controlCollection.push(ctrlId);
                    }
                    break;
            }
        });
        return controlCollection.length;
    }

    getFileNameIconFromExtension(fileName: any) {
        let returnExtn: any = '';
        if (fileName != null && fileName != "" && fileName != undefined) {
            var extn = fileName.substr((fileName.lastIndexOf('.') + 1));
            extn = (extn != null && extn != undefined) ? extn.toLowerCase() : '';
            switch (extn) {
                case "pdf":
                    returnExtn = "fa-file-pdf-o";
                    break;
                case "doc":
                case "docx":
                    returnExtn = "fa-file-word-o";
                    break;
                case "ppt":
                case "pptx":
                    returnExtn = "fa-file-powerpoint-o";
                    break;
                case "xls":
                case "xlsx":
                    returnExtn = "fa-file-excel-o";
                    break;
                case "ico":
                case "jpg":
                case "jpeg":
                case "png":
                case "tif":
                case "tiff":
                case "gif":
                case "bpg":
                    returnExtn = "fa-file-image-o";
                    break;
                case "txt":
                    returnExtn = "fa-file-text-o";
                    break;
                default:
                    returnExtn = "fa-file-o";
                    break;
            }
        }
        return returnExtn;
    }

    onCloseValidationPopup() {
        this.validationPopupStyle = 'none';
    }

    getControlNextNav(typeOfNavControl: any) {
        if (this.webOrMobile === 'mobile' && this.showMobilePopup === 'block') {
            let controlStringIdVal = document.getElementById(this.mobileControlArr[this.tabIndex].controlId)!;
            controlStringIdVal.removeAttribute('readOnly');
            this.popuptempArr = [];
        }
        this.hideAllControlTooltips();
        this.getTotalRemaingFieldsControls('');
        this.showMobilePopup = 'none';
        localStorage.setItem("datetype", '');
        let tabPos: any = this.checkAllControlsAreFilled();
        this.isCheckDatePopupOpen();
        if (this.webOrMobile === 'mobile') {
            let prevSelectedCtrlIndex: any = this.mobileControlArr[this.tabIndex];
            if (prevSelectedCtrlIndex && prevSelectedCtrlIndex.controlId) {
                let eleId = prevSelectedCtrlIndex.controlId.split('_')[1];
                let controlName: any = document.getElementById(eleId)!.getAttribute('data-ctntype');
                if (controlName != 'Checkbox' && controlName != 'Radio' && controlName != 'DropDown' && controlName != 'Signature' && controlName != 'NewInitials') {
                    this.btnMaximizeControlOk();
                }
            }
        }
        let prevSelectedCtrl: any;
        this.tabIndex = tabPos;
        let initialTabPos = this.tabIndex;
        prevSelectedCtrl = this.mobileControlArr[this.tabIndex];
        if (this.remainingFieldsCount != 0) {
            if (prevSelectedCtrl && prevSelectedCtrl) {
                let eleId = prevSelectedCtrl.controlId.split('_')[1];
                let controlName: any = document.getElementById(eleId)!.getAttribute('data-ctntype');
                let controlEle: any = document.getElementById(prevSelectedCtrl.controlId);
                let cname: any = controlEle.getAttribute('data-title');
                if (controlName === 'Text' && (cname === 'SSN' || cname === 'Zip' || cname === 'Email')) {
                    let inputValue: any = (<HTMLInputElement>controlEle).value;
                    if (cname === 'Email' && inputValue != '' && !this.validateEmail(inputValue)) {
                        if (this.webOrMobile === 'web' && eleId) {
                            document.getElementById('tooltip_' + eleId)!.style.display = 'block';
                            document.getElementById('control_' + eleId)!.focus();
                        }
                        this.showToaster(this.invalidEmail, 'Warning');
                        if (this.webOrMobile === 'mobile') {
                            this.showMobilePopup = 'block';
                            if (this.showDateControlPopupDisplay === 'block') {
                                (<HTMLInputElement>document.getElementById('txtMaximizeControlDate')).value = ''
                            }
                        }
                        if (eleId) {
                            document.getElementById(eleId)!.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                        }
                        return;
                    }
                    else if (inputValue.indexOf('_') > 0 || inputValue.indexOf('_') > -1 || inputValue.indexOf('-') > 5) {
                        if (this.webOrMobile === 'web' && eleId) {
                            document.getElementById('tooltip_' + eleId)!.style.display = 'block';
                            document.getElementById('control_' + eleId)!.focus();
                        }
                        if (cname === 'Zip') {
                            this.showToaster(this.invlaidZIP, 'Warning');
                            if (this.webOrMobile === 'mobile') {
                                this.showMobilePopup = 'block';
                                if (this.showDateControlPopupDisplay === 'block') {
                                    (<HTMLInputElement>document.getElementById('txtMaximizeControlDate')).value = ''
                                }
                            }
                            if (eleId) {
                                document.getElementById(eleId)!.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                            }
                            (<HTMLInputElement>document.getElementById(prevSelectedCtrl.controlId)).value = '';
                        }
                        else if (cname === 'SSN') {
                            this.showToaster(this.invlaidSSN, 'Warning');
                            if (this.webOrMobile === 'mobile') {
                                this.showMobilePopup = 'block';
                                if (this.showDateControlPopupDisplay === 'block') {
                                    (<HTMLInputElement>document.getElementById('txtMaximizeControlDate')).value = ''
                                }
                            }
                            if (eleId) {
                                document.getElementById(eleId)!.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                            }
                            (<HTMLInputElement>document.getElementById(prevSelectedCtrl.controlId)).value = '';
                        }
                        return;
                    }
                }
                else if (controlName == "Email") {
                    let inputValue: any = (<HTMLInputElement>controlEle).value;
                    if (inputValue != '' && !this.validateEmail(inputValue)) {
                        if (this.webOrMobile === 'web' && eleId) {
                            document.getElementById('tooltip_' + eleId)!.style.display = 'block';
                            document.getElementById('control_' + eleId)!.focus();
                        }
                        this.showToaster(this.invalidEmail, 'Warning');
                        if (this.webOrMobile === 'mobile') {
                            this.showMobilePopup = 'block';
                            if (this.showDateControlPopupDisplay === 'block') {
                                (<HTMLInputElement>document.getElementById('txtMaximizeControlDate')).value = ''
                            }
                        }
                        if (eleId) {
                            document.getElementById(eleId)!.getElementsByClassName("additionalInfo")[0].classList.remove("filledControls");
                        }
                        return;
                    }
                }
                if (this.mobileControlArr.length == 1) {
                    return;
                }
                else {
                    if (eleId) {
                        document.getElementById('tooltip_' + eleId)!.style.display = 'none';
                    }
                    let controlStringIdVal = document.getElementById(prevSelectedCtrl.controlId)!;
                    (<HTMLInputElement>controlStringIdVal).readOnly = false;
                }
            }

            this.mobilePrevBtn = 'true';
            this.tabIndex = this.tabIndex + 1;
            this.tabIndex = this.returnNextTabIndex(Number(this.tabIndex));

            let selectedNextTabindex: any = this.tabIndex;
           // localStorage.setItem('selectedNextTabindex', selectedNextTabindex);
            let arr: any = this.mobileControlArr[this.tabIndex];

            this.mobileControlArr.forEach((ele: any, eleIndex: any) => {
                if (this.tabIndex === eleIndex) {
                    let controlValue: any = document.getElementById(ele.controlId)!;
                    if (ele.controlName === 'Signature') {
                        if (controlValue !== null) {
                            if (controlValue.getAttribute('src') !== null) {
                                if (this.tabIndex !== this.mobileControlArr.length - 1) {
                                    this.tabIndex = this.tabIndex + 1;
                                }
                                else {
                                    this.tabIndex = initialTabPos;
                                }
                                this.checkTabindexNextControlIsFilledOrNot();
                            } else {
                                let controlEle: any = document.getElementById(ele.controlId);
                                controlEle.focus();
                                let tooltiplEle: any = document.getElementById(ele.controlId.replace('control_', 'tooltip_'))!;
                                tooltiplEle.style.display = 'block';
                            }
                        }
                    }
                    else if (ele.controlName === 'NewInitials') {
                        if (controlValue !== null) {
                            if (controlValue.getAttribute('src') !== null) {
                                if (this.tabIndex !== this.mobileControlArr.length - 1) {
                                    this.tabIndex = this.tabIndex + 1;
                                }
                                else {
                                    this.tabIndex = initialTabPos;
                                }
                                this.checkTabindexNextControlIsFilledOrNot();
                            } else {
                                let controlEle: any = document.getElementById(ele.controlId);
                                controlEle.focus();
                                let tooltiplEle: any = document.getElementById(ele.controlId.replace('control_', 'tooltip_'))!;
                                tooltiplEle.style.display = 'block';
                            }
                        }
                    }
                    else if (ele.controlName === 'DropDown') {
                        let controlIdEle: any = document.getElementById(ele.controlId);
                        let value: any = controlIdEle.selectedOptions[0].innerText.trim();
                        if (value !== '' && value !== undefined && value != null) {
                            if (this.tabIndex !== this.mobileControlArr.length - 1) {
                                this.tabIndex = this.tabIndex + 1;
                            } else {
                                this.tabIndex = initialTabPos;
                            }
                            this.checkTabindexNextControlIsFilledOrNot();
                        } else {
                            this.validateAndApplyControlShowTooltip(ele, 'next');
                            this.checkTabindexNextControlIsFilledOrNot();
                            let controlEle: any = document.getElementById(ele.controlId);
                            controlEle.focus();
                            let tooltiplEle: any = document.getElementById(ele.controlId.replace('control_', 'tooltip_'))!;
                            tooltiplEle.style.display = 'block';
                        }
                    }
                    else {
                        if (ele.controlName === 'Radio' || ele.controlName === 'Checkbox') {
                            if (controlValue && controlValue.checked) {
                                if (this.tabIndex !== this.mobileControlArr.length - 1) {
                                    this.tabIndex = this.tabIndex + 1;
                                } else {
                                    this.tabIndex = initialTabPos;
                                }
                                this.checkTabindexNextControlIsFilledOrNot();
                            } else {
                                this.validateAndApplyControlShowTooltip(ele, 'next');
                                this.checkTabindexNextControlIsFilledOrNot();
                                let controlEle: any = document.getElementById(ele.controlId);
                                controlEle.focus();
                                let tooltiplEle: any = document.getElementById(ele.controlId.replace('control_', 'tooltip_'))!;
                                tooltiplEle.style.display = 'block';
                            }
                        }
                        else {
                            if (controlValue !== null) {
                                let inputValue: any = (<HTMLInputElement>controlValue).value;
                                if (inputValue !== '' && inputValue !== undefined && inputValue !== null) {
                                    let controlEle: any = document.getElementById(ele.controlId);
                                    let cname: any = controlEle.getAttribute('data-title');
                                    if (ele.controlName === 'Text' && (cname === 'SSN' || cname === 'Zip')) {
                                        if (inputValue != '___-__-____' && inputValue != '_____-____' && inputValue != '_____') {
                                            if (inputValue.indexOf('_') > 0 || inputValue.indexOf('_') > -1 || inputValue.indexOf('-') > 5) {
                                                if (cname === 'SSN' || cname === 'Zip') {
                                                    let tooltiplEle: any = document.getElementById(ele.controlId.replace('control_', 'tooltip_'))!;
                                                    tooltiplEle.style.display = 'block';
                                                }
                                            } else {
                                                let maxArrLength: any = this.mobileControlArr.length - 1;
                                                if (this.tabIndex !== this.mobileControlArr.length - 1) {
                                                    this.tabIndex = this.tabIndex + 1;
                                                } else {
                                                    this.tabIndex = initialTabPos;
                                                }
                                                this.checkTabindexNextControlIsFilledOrNot();
                                            }
                                        } else {
                                            this.validateAndApplyControlShowTooltip(ele, 'next');
                                            this.checkTabindexNextControlIsFilledOrNot();
                                        }
                                    }
                                    else {
                                        let maxArrLength: any = this.mobileControlArr.length - 1;
                                        if (this.tabIndex !== this.mobileControlArr.length - 1) {
                                            this.tabIndex = this.tabIndex + 1;
                                        } else {
                                            this.tabIndex = initialTabPos;
                                        }
                                        this.checkTabindexNextControlIsFilledOrNot();
                                    }
                                } else {
                                    this.validateAndApplyControlShowTooltip(ele, 'next');
                                    this.checkTabindexNextControlIsFilledOrNot();
                                }
                            }
                        }
                    }
                }
            })

            if (prevSelectedCtrl && this.tabIndex === initialTabPos) {
                let eleId = prevSelectedCtrl.controlId.split('_')[1];
                if (this.webOrMobile !== 'mobile') {
                    document.getElementById('tooltip_' + eleId)!.style.display = 'block';
                }
                if (prevSelectedCtrl.controlName == "Signature" || prevSelectedCtrl.controlName == "NewInitials") {
                    let controlValue: any = document.getElementById(prevSelectedCtrl.controlId)!;
                    if (controlValue !== null) {
                        if (controlValue.getAttribute('src') !== null) {
                            document.getElementById('tooltip_' + eleId)!.style.display = 'none';
                        }
                        else {
                            if (this.webOrMobile === 'mobile') {
                                // this.showMobilePopup = 'block';
                            }
                        }
                    }
                }
                else if (prevSelectedCtrl.controlName == "Date") {
                    // let controlValue: any = document.getElementById(prevSelectedCtrl.controlId)!;
                    document.getElementById('tooltip_' + eleId)!.style.display = 'none';
                }
                else if (prevSelectedCtrl.controlName === 'Radio' || prevSelectedCtrl.controlName === 'Checkbox') {
                    let controlEle: any = document.getElementById(prevSelectedCtrl.controlId);
                    if (controlEle.checked === true) {
                        document.getElementById('tooltip_' + eleId)!.style.display = 'none';
                    }
                }
                else if (prevSelectedCtrl.controlName === 'DropDown') {
                    let controlEle: any = document.getElementById(prevSelectedCtrl.controlId);
                    let value: any = controlEle.selectedOptions[0].innerText.trim();
                    if (value !== '' && value !== undefined && value != null) {
                        document.getElementById('tooltip_' + eleId)!.style.display = 'none';
                    }
                }
                else if (prevSelectedCtrl.controlName === 'Text' || prevSelectedCtrl.controlName === 'Title' ||
                    prevSelectedCtrl.controlName === 'Name' || prevSelectedCtrl.controlName === 'Company' ||
                    prevSelectedCtrl.controlName === 'Email') {
                    let ele: any = document.getElementById(prevSelectedCtrl.controlId);
                    if (ele && ele.value != '' && ele.value != null && ele.value != undefined) {
                        document.getElementById('tooltip_' + eleId)!.style.display = 'none';
                    }
                }
                else {
                    if (this.webOrMobile === 'mobile') {
                        // this.showMobilePopup = 'block';
                    }
                }
            }

            if (this.tabIndex == 0) {
                this.mobilePrevBtn = 'false';
                this.mobileNextBtn = 'true';
            }
            else if (this.tabIndex == this.mobileControlArr.length - 1) {
                this.mobilePrevBtn = 'true';
                this.mobileNextBtn = 'false';
            }
            else if (this.tabIndex != initialTabPos) {
                for (let index = this.tabIndex - 1; index >= 0; index--) {
                    const element = this.mobileControlArr[index];
                    if (element) {
                        if (document.getElementById(element.controlId.replace('control_', ''))!.style.display == "none") {
                            this.mobilePrevBtn = 'false';
                            this.mobileNextBtn = 'true';
                        }
                        else {
                            this.mobilePrevBtn = 'true';
                            this.mobileNextBtn = 'true';
                            break;
                        }
                    }
                }
            }

            else {
                this.mobilePrevBtn = 'true';
                this.mobileNextBtn = 'true';

            }

            this.mobileControlArr.forEach((ele: any, eleIndex: any) => {
                if (this.webOrMobile === 'mobile') {
                    if (ele.controlName === 'Radio' || ele.controlName === 'Checkbox') {
                        let radioEle: any = document.getElementById(ele.controlId);
                        radioEle.parentNode.style.border = '1px solid #999';
                    } else if (ele.controlName === 'NewInitials') {
                        let ele: any = document.getElementsByClassName('newInitialsignature') as HTMLCollectionOf<HTMLElement>;
                        ele[0].style.border = '1px solid #ddd';
                    } else {
                        if (ele.controlName !== 'Signature' && ele.controlName !== 'NewInitials') {
                            document.getElementById(ele.controlId)!.style.border = '1px solid grey';
                        }
                    }
                }
                if (eleIndex === this.tabIndex) {
                    if (this.webOrMobile === 'mobile') {
                        let control = document.getElementById(ele.controlId)!
                        if (control) {
                            let cntrl: any = ele.controlId.split('_')[1];
                            let cntrEle = document.getElementById(cntrl)!;
                            let cntrlDataType: any = cntrEle.getAttribute('data-ctntype');
                            let cntrlDataId: any = cntrEle.getAttribute('id');
                            let isTextDate: any = document.getElementById(control.id)!.getAttribute('data-title');

                            let controlValue: any = document.getElementById(ele.controlId)!
                            if (ele.controlName === 'Signature') {
                                if (controlValue !== null) {
                                    if (controlValue.getAttribute('src') === null || controlValue.getAttribute('src') === undefined) {
                                        if (this.pinchZoom.pinchZoom.initialScale === 1) {
                                            this.pinchZoom.toggleZoom();
                                            setTimeout(() => {
                                                this.updateControlPositions(ele.controlId);
                                            }, 300);
                                        } else {
                                            if (this.pinchZoom.pinchZoom.initialScale > 1) {
                                                this.updateControlPositions(ele.controlId);
                                            }
                                        }

                                        setTimeout(() => {
                                            this.showMobilePopup = 'none';
                                            this.onClickSignature(cntrl);
                                        }, 500);
                                    }
                                    control.style.border = '0px solid orange';
                                }
                            } else if (ele.controlName === 'NewInitials') {
                                if (controlValue !== null) {
                                    if (controlValue.getAttribute('src') === null || controlValue.getAttribute('src') === undefined) {
                                        if (this.pinchZoom.pinchZoom.initialScale === 1) {
                                            this.pinchZoom.toggleZoom();
                                            setTimeout(() => {
                                                this.updateControlPositions(ele.controlId);
                                            }, 300);
                                        } else {
                                            if (this.pinchZoom.pinchZoom.initialScale > 1) {
                                                this.updateControlPositions(ele.controlId);
                                            }
                                        }
                                        setTimeout(() => {
                                            this.showMobilePopup = 'none';
                                            this.onClickInitials(cntrl);
                                        }, 500);
                                    }
                                    control.style.border = '0px solid orange';
                                }
                            }
                        }
                    }
                }
            })
        } else {
            this.hideAllMobilePrevNextDoneBtns();
        }
    }

    returnNextTabIndex(tabIndex: number): any {
        let controlPos = this.mobileControlArr.length - 1;
        if (controlPos == tabIndex) {
            let arr: any = this.mobileControlArr[tabIndex];
            if (arr && document.getElementById(arr.controlId.replace('control_', ''))!.style.display == "none") {
                return tabIndex - 1;
            }
            return tabIndex;
        }
        else {
            let arr: any = this.mobileControlArr[tabIndex];
            if (arr && document.getElementById(arr.controlId.replace('control_', ''))!.style.display == "none") {
                this.tabIndex = tabIndex + 1;
                return this.returnNextTabIndex(this.tabIndex);
            }
            else if (arr && arr.length == 0) {
                this.tabIndex = tabIndex + 1;
                return this.returnNextTabIndex(this.tabIndex);
            }
            else {
                return tabIndex;
            }
        }
    }

    returnPrevTabIndex(tabIndex: any): any {
        let controlPos = 0;
        if (controlPos == tabIndex) {
            let arr: any = this.mobileControlArr[tabIndex];
            if (arr && document.getElementById(arr.controlId.replace('control_', ''))!.style.display == "none") {
                return tabIndex + 1;
            }
            return tabIndex;
        }
        else if (tabIndex == -1) {
            return tabIndex;
        }
        else {
            let arr: any = this.mobileControlArr[tabIndex];
            if (arr && document.getElementById(arr.controlId.replace('control_', ''))!.style.display == "none") {
                this.tabIndex = tabIndex - 1;
                return this.returnPrevTabIndex(this.tabIndex);
            }
            else if (arr && arr.length == 0) {
                this.tabIndex = tabIndex - 1;
                return this.returnPrevTabIndex(this.tabIndex);
            }
            else {
                return tabIndex;
            }
        }
    }

    getControlPrevNav(typeOfNavControl: any) {
        if (this.webOrMobile === 'mobile' && this.showMobilePopup === 'block') {
            let controlStringIdVal = document.getElementById(this.mobileControlArr[this.tabIndex].controlId)!;
            controlStringIdVal.removeAttribute('readOnly');
            this.popuptempArr = [];
        }
        this.hideAllControlTooltips();
        this.getTotalRemaingFieldsControls('');
        this.showMobilePopup = 'none';
        localStorage.setItem("datetype", '');
        let currentTabIndexPosition: any;
        this.isCheckDatePopupOpen();
        if (this.webOrMobile === 'mobile') {
            let prevSelectedCtrlIndex: any = this.mobileControlArr[this.tabIndex];
            if (prevSelectedCtrlIndex && prevSelectedCtrlIndex.controlId) {
                let eleId = prevSelectedCtrlIndex.controlId.split('_')[1];
                let controlName: any = document.getElementById(eleId)!.getAttribute('data-ctntype');
                if (controlName != 'Checkbox' && controlName != 'Radio' && controlName != 'DropDown' && controlName != 'Signature' && controlName != 'NewInitials') {
                    this.btnMaximizeControlOk();
                }
            }
        }
        let initialTabPos = this.tabIndex;
        let prevSelectedCtrl: any = this.mobileControlArr[this.tabIndex];
        if (this.remainingFieldsCount != 0) {
            if (prevSelectedCtrl) {
                let eleId = prevSelectedCtrl.controlId.split('_')[1];
                let controlName: any = document.getElementById(eleId)!.getAttribute('data-ctntype');
                let controlEle: any = document.getElementById(prevSelectedCtrl.controlId);
                let cname: any = controlEle.getAttribute('data-title');
                if (controlName === 'Text' && (cname === 'SSN' || cname === 'Zip')) {
                    let inputValue: any = (<HTMLInputElement>controlEle).value;
                    if (inputValue.indexOf('_') > 0 || inputValue.indexOf('_') > -1 || inputValue.indexOf('-') > 5) {
                        if (this.webOrMobile === 'web') {
                            document.getElementById('tooltip_' + eleId)!.style.display = 'block';
                        }
                        if (cname === 'Zip') {
                            this.showToaster(this.invlaidZIP, 'Warning');
                            if (this.webOrMobile === 'mobile') {
                                this.showMobilePopup = 'block';
                                if (this.showDateControlPopupDisplay === 'block') {
                                    (<HTMLInputElement>document.getElementById('txtMaximizeControlDate')).value = ''
                                }
                            }
                        }
                        if (cname === 'SSN') {
                            this.showToaster(this.invlaidSSN, 'Warning');
                            if (this.webOrMobile === 'mobile') {
                                this.showMobilePopup = 'block';
                                if (this.showDateControlPopupDisplay === 'block') {
                                    (<HTMLInputElement>document.getElementById('txtMaximizeControlDate')).value = ''
                                }
                            }
                        }
                        return;
                    }
                }
                document.getElementById('tooltip_' + eleId)!.style.display = 'none';
                let controlStringIdVal = document.getElementById(prevSelectedCtrl.controlId)!;
                (<HTMLInputElement>controlStringIdVal).readOnly = false;
            }

            if (this.globalCtrlObj && this.globalCtrlObj.ControlHtmlID) {
                let index: any = this.mobileControlArr.findIndex((ele: { controlId: string; }) => ele.controlId.replace('control_', '') === this.globalCtrlObj.ControlHtmlID);
                this.tabIndex = index;
                this.globalCtrlObj = null;
            } else {
                this.tabIndex = this.tabIndex - 1;
            }
            this.tabIndex = this.returnPrevTabIndex(this.tabIndex);
           // localStorage.setItem('selectedNextTabindex', this.tabIndex);

            let selectedTabindex: any = this.tabIndex;
            this.findPrevControl();
            if (this.tabIndex <= 0) {
                if (prevSelectedCtrl) {
                    let eleId = prevSelectedCtrl.controlId.split('_')[1];
                    if (this.tabIndex == -1) {
                        document.getElementById('tooltip_' + eleId)!.style.display = 'block';
                        this.tabIndex = initialTabPos;
                        if (this.webOrMobile === 'mobile') {
                            this.hideAllControlTooltips();
                            document.getElementById('control_' + eleId)!.style.border = '1px solid orange';
                            //  this.showMobilePopup = 'block';
                        }
                    }
                    else {
                        document.getElementById('tooltip_' + eleId)!.style.display = 'none';
                        if (this.webOrMobile === 'mobile') {
                            // this.showMobilePopup = 'none';
                        }
                    }
                }
            }

            if (this.tabIndex == this.mobileControlArr.length - 1) {
                this.mobilePrevBtn = 'true';
                this.mobileNextBtn = 'false';
            }
            else if (this.tabIndex == 0) {
                this.mobilePrevBtn = 'false';
                this.mobileNextBtn = 'true';
            }

            else if (this.tabIndex != initialTabPos) {
                for (let index = this.tabIndex - 1; index >= 0; index--) {
                    const element = this.mobileControlArr[index];
                    if (element) {
                        if (document.getElementById(element.controlId.replace('control_', ''))!.style.display == "none") {
                            this.mobilePrevBtn = 'false';
                            this.mobileNextBtn = 'true';
                        }
                        else {
                            this.mobilePrevBtn = 'true';
                            this.mobileNextBtn = 'true';
                            break;
                        }
                    }
                }
            }

            this.mobileControlArr.forEach((ele: any, eleIndex: any) => {
                if (this.webOrMobile === 'mobile') {
                    if (ele.controlName === 'Radio' || ele.controlName === 'Checkbox') {
                        let radioEle: any = document.getElementById(ele.controlId);
                        radioEle.parentNode.style.border = '1px solid #999';
                    } else if (ele.controlName === 'NewInitials') {
                        let ele: any = document.getElementsByClassName('newInitialsignature') as HTMLCollectionOf<HTMLElement>;
                        ele[0].style.border = '1px solid grey';
                    } else {
                        if (ele.controlName !== 'Signature' && ele.controlName !== 'NewInitials') {
                            document.getElementById(ele.controlId)!.style.border = '1px solid grey';
                        }
                    }
                }
                if (eleIndex === this.tabIndex) {
                    if (this.webOrMobile === 'mobile') {
                        let control = document.getElementById(ele.controlId)!
                        // setTimeout(() => {
                        let cntrl: any = ele.controlId.split('_')[1];
                        let cntrEle = document.getElementById(cntrl)!;
                        let cntrlDataType: any = cntrEle.getAttribute('data-ctntype');
                        let cntrlDataId: any = cntrEle.getAttribute('id');
                        let isTextDate: any = document.getElementById(control.id)!.getAttribute('data-title');

                        let controlValue: any = document.getElementById(ele.controlId)!
                        if (ele.controlName === 'Signature') {
                            if (controlValue.getAttribute('src') === null || controlValue.getAttribute('src') === undefined) {
                                if (this.pinchZoom.pinchZoom.initialScale === 1) {
                                    this.pinchZoom.toggleZoom();
                                    setTimeout(() => {
                                        this.updateControlPositions(ele.controlId);
                                    }, 300);
                                } else {
                                    if (this.pinchZoom.pinchZoom.initialScale > 1) {
                                        this.updateControlPositions(ele.controlId);
                                    }
                                }

                                setTimeout(() => {
                                    this.showMobilePopup = 'none';
                                    this.onClickSignature(cntrl);
                                }, 500);
                            }
                            control.style.border = '0px solid orange';

                        } else if (ele.controlName === 'NewInitials') {
                            if (controlValue.getAttribute('src') === null || controlValue.getAttribute('src') === undefined) {
                                if (this.pinchZoom.pinchZoom.initialScale === 1) {
                                    this.pinchZoom.toggleZoom();
                                    setTimeout(() => {
                                        this.updateControlPositions(ele.controlId);
                                    }, 300);
                                } else {
                                    if (this.pinchZoom.pinchZoom.initialScale > 1) {
                                        this.updateControlPositions(ele.controlId);
                                    }
                                }

                                setTimeout(() => {
                                    this.showMobilePopup = 'none';
                                    this.onClickInitials(cntrl);
                                }, 500);
                            }
                            control.style.border = '0px solid orange';
                        }
                    }
                }
            })
        } else {
            this.hideAllMobilePrevNextDoneBtns();
        }
    }

    findPrevControl() {
        if (this.tabIndex == -1) {
            return;
        }
        else {
            let ele: any = this.mobileControlArr[this.tabIndex];
            if (ele) {
                let controlValue: any = document.getElementById(ele.controlId)!;
                if (ele.controlName === 'Signature') {
                    if (controlValue !== null) {
                        if (controlValue.getAttribute('src') !== null) {
                            this.tabIndex = this.tabIndex - 1;
                            this.checkTabindexPrevControlIsFilledOrNot();
                            this.findPrevControl();
                        } else {
                            let controlEle: any = document.getElementById(ele.controlId);
                            controlEle.focus();
                            let tooltiplEle: any = document.getElementById(ele.controlId.replace('control_', 'tooltip_'))!;
                            tooltiplEle.style.display = 'block';
                        }
                    }
                }
                else if (ele.controlName === 'NewInitials') {
                    if (controlValue !== null) {
                        if (controlValue.getAttribute('src') !== null) {
                            this.tabIndex = this.tabIndex - 1;
                            this.checkTabindexPrevControlIsFilledOrNot();
                            this.findPrevControl();
                        } else {
                            let controlEle: any = document.getElementById(ele.controlId);
                            controlEle.focus();
                            let tooltiplEle: any = document.getElementById(ele.controlId.replace('control_', 'tooltip_'))!;
                            tooltiplEle.style.display = 'block';

                        }
                    }
                }
                else if (ele.controlName === 'DropDown') {
                    let controlIdEle: any = document.getElementById(ele.controlId);
                    let value: any = controlIdEle.selectedOptions[0].innerText.trim();
                    if (value !== '' && value !== undefined && value != null) {
                        this.tabIndex = this.tabIndex - 1;
                        this.checkTabindexPrevControlIsFilledOrNot();
                        this.findPrevControl();
                    } else {
                        this.validateAndApplyControlShowTooltip(ele, 'prev');
                        this.checkTabindexPrevControlIsFilledOrNot();
                        let controlEle: any = document.getElementById(ele.controlId);
                        controlEle.focus();
                        let tooltiplEle: any = document.getElementById(ele.controlId.replace('control_', 'tooltip_'))!;
                        tooltiplEle.style.display = 'block';
                    }
                }
                else {
                    if (ele.controlName === 'Radio' || ele.controlName === 'Checkbox') {
                        if (controlValue && controlValue.checked) {
                            this.tabIndex = this.tabIndex - 1;
                            this.checkTabindexPrevControlIsFilledOrNot();
                            this.findPrevControl();
                        } else {
                            this.validateAndApplyControlShowTooltip(ele, 'prev');
                            this.checkTabindexPrevControlIsFilledOrNot();
                            let controlEle: any = document.getElementById(ele.controlId);
                            controlEle.focus();
                            let tooltiplEle: any = document.getElementById(ele.controlId.replace('control_', 'tooltip_'))!;
                            tooltiplEle.style.display = 'block';
                        }
                    } else {
                        if (controlValue !== null) {
                            let inputValue: any = (<HTMLInputElement>controlValue).value;
                            if (inputValue !== '' && inputValue !== undefined && inputValue !== null) {
                                let controlEle: any = document.getElementById(ele.controlId);
                                let cname: any = controlEle.getAttribute('data-title');
                                if (ele.controlName === 'Text' && (cname === 'SSN' || cname === 'Zip')) {
                                    if (inputValue != '___-__-____' && inputValue != '_____-____' && inputValue != '_____') {
                                        if (inputValue.indexOf('_') > 0 || inputValue.indexOf('_') > -1 || inputValue.indexOf('-') > 5) {
                                            if (cname === 'SSN' || cname === 'Zip') {
                                                let tooltiplEle: any = document.getElementById(ele.controlId.replace('control_', 'tooltip_'))!;
                                                tooltiplEle.style.display = 'block';
                                            }
                                        } else {
                                            this.tabIndex = this.tabIndex - 1;
                                            this.checkTabindexPrevControlIsFilledOrNot();
                                            this.findPrevControl();
                                        }
                                    } else {
                                        this.validateAndApplyControlShowTooltip(ele, 'prev');
                                        this.checkTabindexPrevControlIsFilledOrNot();
                                    }
                                } else {
                                    this.tabIndex = this.tabIndex - 1;
                                    this.checkTabindexPrevControlIsFilledOrNot();
                                    this.findPrevControl();
                                }
                            } else {
                                this.validateAndApplyControlShowTooltip(ele, 'prev');
                                this.checkTabindexPrevControlIsFilledOrNot();

                            }
                        }
                    }
                }
            }
        }
    }

    isPinchZoomApply(ele: any) {
        let textDateDataTitle: any = document.getElementById(ele.ControlHtmlID);
        if (ele.ControlName !== 'Date' || (ele.ControlName !== 'Text' && textDateDataTitle.getAttribute('data-title') !== 'Date')) {
            if (this.pinchZoom.pinchZoom.initialScale === 1) {
                this.pinchZoom.toggleZoom();
                setTimeout(() => {
                    this.updateControlPositions(ele.controlId);
                }, 300);
            } else {
                if (this.pinchZoom.pinchZoom.initialScale > 1) {
                    this.updateControlPositions(ele.controlId);
                }
            }
        }
    }

    validateAndApplyControlShowTooltip(ele: any, prevOrNext: string) {
        if (this.webOrMobile === 'mobile') {
            for (let i = 0; i < this.mobileControlArr.length; i++) {
                let tooltipControlEle: any = document.getElementById('tooltip_' + this.mobileControlArr[i].controlId.replace('control_', ''));
                tooltipControlEle.style.display = 'none';
                if (this.mobileControlArr[i].controlName !== 'Signature' && this.mobileControlArr[i].controlName !== 'NewInitials') {
                    document.getElementById(this.mobileControlArr[i].controlId)!.style.border = '1px solid grey';
                }
            }
            this.hideAllControlTooltips();
            if (ele.controlName === 'NewInitials') {
                let controlValue: any = document.getElementById(ele.controlId)!;
                let eleRef: any = document.getElementsByClassName('newInitialsignature') as HTMLCollectionOf<HTMLElement>;
                if (controlValue.getAttribute('src') !== null) {
                    eleRef[0].style.border = '1px solid #ddd';
                }
            } else {
                if (ele.controlName !== 'Checkbox') {
                    if (ele.controlName !== 'Signature') {
                        setTimeout(() => {
                            document.getElementById(ele.controlId)!.style.border = '1px solid orange';
                        }, 200);
                    }
                }
            }

            this.isPinchZoomApply(ele);

            setTimeout(() => {
                let controlEle: any = document.getElementById(ele.controlId);
                let attrDataTitle: any = controlEle.getAttribute('data-title');
                if (ele.controlName !== 'Signature' && ele.controlName !== 'NewInitials' &&
                    ele.controlName !== 'Radio' && ele.controlName !== 'Checkbox' &&
                    ele.controlName !== 'DropDown') {
                    setTimeout(() => {
                        this.checkisLastControl(parseInt(ele.tabindex));
                        this.responsiveOpenModelPopup(ele.controlId.replace('control_', ''));
                    }, 300);
                    this.isDateSelected = 'false';
                } else {
                    if (ele.controlName !== 'Date' && attrDataTitle !== 'Date') {
                        this.showMobilePopup = 'none';
                        this.isDateSelected = 'false';
                    }
                    this.updatedmobileControlPosition(ele.controlId.replace('control_', ''));
                }
            }, 300);
        } else {
            let controlEle: any = document.getElementById(ele.controlId);
            let attrDataTitle: any = controlEle.getAttribute('data-title');
            if (ele.controlName !== 'Date' && attrDataTitle !== 'Date') {
                controlEle.style.outline = 'none';
                controlEle.focus();
                let tooltiplEle: any = document.getElementById(ele.controlId.replace('control_', 'tooltip_'))!;
                tooltiplEle.style.display = 'block';
            }
            else if (ele.controlName == 'Date' || attrDataTitle == 'Date') {
                controlEle.style.outline = 'none';
                if (prevOrNext == 'prev' || prevOrNext == 'next') {
                    controlEle.focus();
                }
                let tooltiplEle: any = document.getElementById(ele.controlId.replace('control_', 'tooltip_'))!;
                tooltiplEle.style.display = 'none';
            }
        }
    }

    closeCanvasMenu() {
        let closeCanvasMenu: any = document.getElementById("closeCanvasMenu");
        closeCanvasMenu.click();
        this.removeBodyTagPositionFixed();
    }

    applyStyleFordefaultSignNavControlIndicator() {
        let defaultSignNavControlIndicator: any = document.getElementsByClassName("defaultSignNavControlIndicator");
        if (defaultSignNavControlIndicator != null && defaultSignNavControlIndicator.length > 0) {
            defaultSignNavControlIndicator[0].classList.remove("requried-color");
            defaultSignNavControlIndicator[0].classList.add("success-color");
        }

        let defaultSignNavControlIndicatorIcon: any = document.getElementsByClassName("defaultSignNavControlIndicatorIcon");
        if (defaultSignNavControlIndicatorIcon != null && defaultSignNavControlIndicatorIcon.length > 0) {
            defaultSignNavControlIndicatorIcon[0].classList.remove("requried-icon");
            defaultSignNavControlIndicatorIcon[0].classList.add("success-icon");
        }
    }

    closeMobilePopup(event: any) {
        this.showMobilePopup = 'none';
        let dvMaximizeControlModalPopupEle: any = document.getElementById('dvMaximizeControlModalPopup');
        if (dvMaximizeControlModalPopupEle && dvMaximizeControlModalPopupEle.style) {
            dvMaximizeControlModalPopupEle.style.display = 'none';
        }
        this.hideAllOrangeBorder();
        let hdnMaximizeControlId: any = document.getElementById('hdnMaximizeControlId');
        let ctrlId: any = hdnMaximizeControlId.value;
        let controlStringIdVal: any = document.getElementById('control_' + ctrlId)!;
        let value: any = controlStringIdVal.value;
        // controlStringIdVal.value = '';
        if (controlStringIdVal.value !== '') {
            (<HTMLInputElement>document.getElementById('control_' + ctrlId)).style.border = '1px solid grey';
            this.updateControlsValue(ctrlId, controlStringIdVal.value);
        } else {
            if (controlStringIdVal.value === '') {
                this.mobileCheckTextDependenciesControl(ctrlId, controlStringIdVal.value);
                controlStringIdVal.parentNode.classList.remove("filledControls");
            }
            (<HTMLInputElement>document.getElementById('control_' + ctrlId)).style.border = '1px solid orange';
        }
        (<HTMLInputElement>controlStringIdVal).readOnly = false;
        this.popuptempArr = [];
        localStorage.setItem("datetype", '');
        this.getTotalRemaingFieldsControls('');
    }

    responsiveOpenModelPopup(controlId: any) {
        this.updatedmobileControlPosition(controlId);
        if (this.remainingFieldsCount === 0) {
            this.hideAllMobilePrevNextDoneBtns();
        }
        setTimeout(() => {
            this.showMobilePopup = 'block';
            this.cdRef.detectChanges();
        }, 10);
        let dialogControl: any = controlId.replace('control_', '');
        let controlIdVal = document.getElementById(controlId)!;
        let dialogControlId: any = document.getElementById(dialogControl);
        let checkTextDatePicker: any = document.getElementById('control_' + controlId);
        let checkTextDatePickerVal: any = checkTextDatePicker.getAttribute('data-title');
        if (dialogControlId.getAttribute('data-ctntype') === 'Date' || (dialogControlId.getAttribute('data-ctntype') === 'Text' && checkTextDatePickerVal === 'Date')) {
            let controlStringIdVal = document.getElementById('control_' + controlId)!;
            if (dialogControlId.getAttribute('data-ctntype') === 'Date') {
                this.showDateControlPopup = 'true';
                this.showDateControlPopupDisplay = 'block';
                this.showNotDateControlPopupDisplay = 'none';
                this.showTextDateControlPopupDisplay = 'none';
            } else {
                this.showDateControlPopup = 'true';
                this.showDateControlPopupDisplay = 'none';
                this.showNotDateControlPopupDisplay = 'none';
                this.showTextDateControlPopupDisplay = 'block';
            }
            var dataCntType = dialogControlId.getAttribute('data-ctntype');
            var dataplaceholderDate = controlStringIdVal.getAttribute('placeholder');
            var dataTitle = controlStringIdVal.getAttribute('title');
            var dataName = controlStringIdVal.getAttribute('name');

            var datavalue = controlStringIdVal.getAttribute('value');
            var datamaskValue = controlStringIdVal.getAttribute('data-mask');
            var dataName = controlStringIdVal.getAttribute('name');

            let txtDataTitle: any = '';

            let spnMaximizeControlModalErrorMessage: any = document.getElementById('spnMaximizeControlModalErrorMessage');
            spnMaximizeControlModalErrorMessage.text = '';

            if (dialogControlId.getAttribute('data-ctntype') == 'Text') {
                txtDataTitle = controlStringIdVal.getAttribute('data-title');
                this.showMobilePopup = 'block';
                document.getElementById(dialogControl)?.focus();
            }
            else {
                txtDataTitle = controlStringIdVal.getAttribute('data-title');
                this.showMobilePopup = 'block';
            }

            let spnMaximizeControlTitle: any = document.getElementById('spnMaximizeControlTitle');
            spnMaximizeControlTitle.innerText = dataCntType;
            this.spnMaximizeControlTitle = dataCntType;
            let hdnDataCtnType: any = document.getElementById('hdnDataCtnType');
            hdnDataCtnType.value = checkTextDatePicker.getAttribute('data-ctntype');

            var existingValue = (<HTMLInputElement>controlStringIdVal).value ? (<HTMLInputElement>controlStringIdVal).value : '';
            let txtMaximizeControlDate: any;
            if (dialogControlId.getAttribute('data-ctntype') === 'Date') {
                txtMaximizeControlDate = document.getElementById('txtMaximizeControlDate');
                txtMaximizeControlDate.setAttribute('data-ctntype', controlStringIdVal.getAttribute('dateformat'));
                hdnDataCtnType.value = controlStringIdVal.getAttribute('dateformat');
                localStorage.setItem("datetype", "1");
            } else {
                txtMaximizeControlDate = document.getElementById('txtMaximizeControlTextDate');
                txtMaximizeControlDate.setAttribute('data-ctntype', checkTextDatePicker.getAttribute('data-ctntype'));
                hdnDataCtnType.value = checkTextDatePicker.getAttribute('data-ctntype');
                localStorage.setItem("datetype", "0");
            }
            if (txtMaximizeControlDate) {
                txtMaximizeControlDate.value = existingValue;
                txtMaximizeControlDate.setAttribute('placeholder', dataplaceholderDate);
                txtMaximizeControlDate.setAttribute('title', dataTitle);
                txtMaximizeControlDate.setAttribute('name', dataName);
                txtMaximizeControlDate.setAttribute('data-title', txtDataTitle);
                txtMaximizeControlDate.setAttribute('value', datavalue);
                txtMaximizeControlDate.setAttribute('data-contrl-value', controlStringIdVal.getAttribute('data-contrl-value'));
                txtMaximizeControlDate.setAttribute('data-document-content-id', controlStringIdVal.getAttribute('data-document-content-id'));
                txtMaximizeControlDate.setAttribute('data-parent-id', controlStringIdVal.getAttribute('data-parent-id'));
                txtMaximizeControlDate.setAttribute('dateformat', controlStringIdVal.getAttribute('dateformat'));
                txtMaximizeControlDate.setAttribute('data-defaultrequired', controlStringIdVal.getAttribute('data-defaultrequired'));
                txtMaximizeControlDate.setAttribute('data-default-date', controlStringIdVal.getAttribute('data-default-date'));
            }


            setTimeout(() => {
                if (dialogControlId.getAttribute('data-ctntype') === 'Date') {
                    document.getElementById('txtMaximizeControlDate')!.focus();
                    document.getElementById('txtMaximizeControlDate')!.style.outline = 'none';
                    this.dateControl.toggle();
                    setTimeout(() => {
                        let idELe: any = document.getElementById('prevNextBtnAlignMobileContainer');
                        idELe.classList.add('iphone-date-popup-align');
                    }, 300);
                } else {
                    document.getElementById('txtMaximizeControlTextDate')!.focus();
                    document.getElementById('txtMaximizeControlTextDate')!.style.outline = 'none';
                    this.dateTextDate.toggle();
                    setTimeout(() => {
                        let idELe: any = document.getElementById('prevNextBtnAlignMobileContainer');
                        idELe.classList.add('iphone-date-popup-align');
                    }, 300);
                }
            }, 300);

            let hdnMaximizeControlId: any = document.getElementById('hdnMaximizeControlId');
            hdnMaximizeControlId.value = controlId;
            (<HTMLInputElement>controlStringIdVal).readOnly = true;
            (<HTMLInputElement>controlStringIdVal).blur();
            this.popuptempArr.push(controlId);

            let existingEle: any = document.getElementById('control_' + controlId);
            if (existingEle && existingEle.value === '' || existingEle.value === undefined || existingEle.value === null) {
                this.updateMobileDatePickerValues(existingEle.value, dialogControlId);
            } else {
                this.updateMobileDatePickerValues(existingEle.value, dialogControlId);
            }
        }
        else if (dialogControlId.getAttribute('data-ctntype') === 'Name' || dialogControlId.getAttribute('data-ctntype') === 'Title' ||
            dialogControlId.getAttribute('data-ctntype') === 'Email' || dialogControlId.getAttribute('data-ctntype') === 'Company' ||
            dialogControlId.getAttribute('data-ctntype') === 'Text') {
            this.showDateControlPopup = 'false';
            this.showDateControlPopupDisplay = 'none';
            this.showNotDateControlPopupDisplay = 'block';
            this.showTextDateControlPopupDisplay = 'none';
            let controlStringIdVal = document.getElementById('control_' + controlId)!;
            var dataCntType = dialogControlId.getAttribute('data-ctntype');
            var dataMaxlength = controlStringIdVal.getAttribute('maxLength');
            var dataplaceholder = controlStringIdVal.getAttribute('placeholder');
            var dataTitle = controlStringIdVal.getAttribute('title');
            var dataName = controlStringIdVal.getAttribute('name');

            var datavalue = controlStringIdVal.getAttribute('value');
            var datamaskValue = controlStringIdVal.getAttribute('data-mask');
            var dataName = controlStringIdVal.getAttribute('name');

            let txtDataTitle: any = '';

            let spnMaximizeControlModalErrorMessage: any = document.getElementById('spnMaximizeControlModalErrorMessage');
            spnMaximizeControlModalErrorMessage.text = '';

            if (dialogControlId.getAttribute('data-ctntype') == 'Text') {
                txtDataTitle = controlStringIdVal.getAttribute('data-title');
                if (txtDataTitle != 'Date') {
                    this.showMobilePopup = 'block';
                }
                else {
                    this.showMobilePopup = 'none';
                    document.getElementById(dialogControl)?.focus();
                }
            }
            else {
                this.showMobilePopup = 'block';
            }

            let spnMaximizeControlTitle: any = document.getElementById('spnMaximizeControlTitle');
            spnMaximizeControlTitle.innerText = dataCntType;
            this.spnMaximizeControlTitle = dataCntType;

            var existingValue = (<HTMLInputElement>controlStringIdVal).value ? (<HTMLInputElement>controlStringIdVal).value : '';
            let txtMaximizeControl: any = document.getElementById('txtMaximizeControl');
            txtMaximizeControl.value = existingValue;

            if (dialogControlId.getAttribute('data-ctntype') == 'Text') {
                txtDataTitle = controlStringIdVal.getAttribute('data-title');
                if (txtDataTitle != "SSN" && txtDataTitle != "Zip" && txtDataTitle != "Email" && txtDataTitle != "Date") {
                    txtMaximizeControl.setAttribute('maxlength', dataMaxlength);
                }
                else if (txtDataTitle == "Email") {
                    txtMaximizeControl.setAttribute('maxlength', dataMaxlength);
                }
            } else if (dialogControlId.getAttribute('data-ctntype') == 'Email') {
                txtMaximizeControl.setAttribute('maxlength', dataMaxlength);
            } else if (dialogControlId.getAttribute('data-ctntype') == 'Name' || dialogControlId.getAttribute('data-ctntype') == 'Title') {
                txtMaximizeControl.setAttribute('maxlength', dataMaxlength);
            }

            if (txtMaximizeControl) {
                txtMaximizeControl.value = existingValue;
                txtMaximizeControl.setAttribute('placeholder', dataplaceholder);
                txtMaximizeControl.setAttribute('title', dataTitle);
                txtMaximizeControl.setAttribute('name', dataName);
                txtMaximizeControl.setAttribute('data-title', txtDataTitle);
                txtMaximizeControl.setAttribute('value', datavalue);
                txtMaximizeControl.setAttribute('data-mask', datamaskValue);
            }

            setTimeout(() => {
                document.getElementById('txtMaximizeControl')!.focus();
            }, 300);

            let hdnMaximizeControlId: any = document.getElementById('hdnMaximizeControlId');
            hdnMaximizeControlId.value = controlId;
            (<HTMLInputElement>controlStringIdVal).readOnly = true;
            (<HTMLInputElement>controlStringIdVal).blur();
            localStorage.setItem("datetype", "");
            this.popuptempArr.push(controlId);
        }
        else {
            controlIdVal.style.outline = '-webkit-focus-ring-color auto 1px';
        }
    }

    updateControlsValue(hdnMaximizeControlId: any, txtMaximizeControl: any) {
        (<HTMLInputElement>document.getElementById('control_' + hdnMaximizeControlId)).value = txtMaximizeControl;
        let existingEle: any = document.getElementById('control_' + hdnMaximizeControlId);
        existingEle.style.outline = '';
        if (this.showDateControlPopup === 'true') {
            (<HTMLInputElement>document.getElementById('txtMaximizeControlDate')).value = '';
        }

        this.showMobilePopup = 'none';
        this.hideAllOrangeBorder();

        if (txtMaximizeControl !== '' && txtMaximizeControl !== undefined) {
            this.applyStylesToCheckList("add", hdnMaximizeControlId);
        }
        else {
            this.applyStylesToCheckList("remove", hdnMaximizeControlId);
            (<HTMLInputElement>document.getElementById('control_' + hdnMaximizeControlId)).style.border = '1px solid grey';
        }
        this.getTotalRemaingFieldsControls('');

        //expand controls
        this.updateExpandControlIfApplicable(hdnMaximizeControlId, txtMaximizeControl);
    }

    btnMaximizeControlOk() {
        if (this.webOrMobile === 'mobile') {
            let txtMaximizeControl: any;
            if (this.showDateControlPopup === 'true') {
                if (this.showDateControlPopupDisplay === 'block') {
                    txtMaximizeControl = (<HTMLInputElement>document.getElementById('txtMaximizeControlDate')).value;
                } else {
                    txtMaximizeControl = (<HTMLInputElement>document.getElementById('txtMaximizeControlTextDate')).value;
                }
            }
            else {
                txtMaximizeControl = (<HTMLInputElement>document.getElementById('txtMaximizeControl')).value;
            }
            let hdnMaximizeControlIdEle: any = document.getElementById('hdnMaximizeControlId')!;
            if (hdnMaximizeControlIdEle && hdnMaximizeControlIdEle.value !== '') {
                var hdnMaximizeControlId = hdnMaximizeControlIdEle.value;
                let controlId: any = document.getElementById('control_' + hdnMaximizeControlId)!;
                let conTitle = controlId.getAttribute("data-title");
                if (txtMaximizeControl !== '') {
                    if (conTitle === 'SSN' || conTitle === 'Zip') {
                        if (txtMaximizeControl.indexOf('_') > 0 || txtMaximizeControl.indexOf('-') > 5) {
                            controlId.parentNode.classList.remove("filledControls");
                        } else {
                            controlId.parentNode.classList.add("filledControls");
                        }
                    } else {
                        controlId.parentNode.classList.add("filledControls");
                    }
                }
                (<HTMLInputElement>controlId).readOnly = false;
                if (conTitle == "Email") {
                    let checkEmail: any = this.validateEmail(txtMaximizeControl);
                    if (checkEmail) {
                        this.updateControlsValue(hdnMaximizeControlId, txtMaximizeControl);
                        this.updateSimilarTextFields(hdnMaximizeControlId, txtMaximizeControl);
                        this.mobileCheckTextDependenciesControl(hdnMaximizeControlId, txtMaximizeControl);

                    } else {
                        this.toastr.warning('Please enter valid email.', 'Warning', { timeOut: 2000 });
                        this.showMobilePopup = 'block';
                    }
                } else {
                    if (txtMaximizeControl !== '') {
                        if (conTitle === 'SSN' || conTitle === 'Zip' || conTitle == "Text" || conTitle == "Numeric" || conTitle == "Alphabet" || conTitle == "Email") {
                            this.updateSimilarTextFields(hdnMaximizeControlId, txtMaximizeControl);
                        }
                        this.updateControlsValue(hdnMaximizeControlId, txtMaximizeControl);
                        this.mobileCheckTextDependenciesControl(hdnMaximizeControlId, txtMaximizeControl);
                    } else {
                        this.updateControlsValue(hdnMaximizeControlId, txtMaximizeControl);
                        if (txtMaximizeControl === '') {
                            if (conTitle === 'SSN' || conTitle === 'Zip' || conTitle == "Text" || conTitle == "Numeric" || conTitle == "Alphabet" || conTitle == "Email") {
                                this.updateSimilarTextFields(hdnMaximizeControlId, txtMaximizeControl);
                            }
                            this.mobileCheckTextDependenciesControl(hdnMaximizeControlId, txtMaximizeControl);
                            controlId.parentNode.classList.remove("filledControls");
                        }
                    }

                }
                localStorage.setItem("datetype", '');
            }

        }
    }

    updateDefaultValueOnDateControl() {
        let isToUpdateCheckList: boolean = false;
        let inpDateControlCollection: any = this.document.getElementsByClassName("inpDateControl");
        if (inpDateControlCollection != null && inpDateControlCollection.length > 0) {
            Array.from(inpDateControlCollection).forEach((inpDateControl: any) => {
                let isdefaultrequired: any = inpDateControl.getAttribute('data-defaultrequired');
                if (isdefaultrequired == "true") {
                    let dataDefaultDate: any = inpDateControl.getAttribute('data-default-date');
                    let dateCtrlId: any = inpDateControl.getAttribute('id');
                    this.document.getElementById(dateCtrlId)!.value = dataDefaultDate;
                    this.document.getElementById(dateCtrlId)!.style.border = '2px solid #38b16e';
                    isToUpdateCheckList = true;
                    this.applyStylesToCheckList("add", dateCtrlId.replace('control_', ''));
                }
            });
        }
    }

    onFocusOutEvent(event: any, control: any) {
        if (control.ControlName == "Text" && this.searchTermDisplay != '') {
            this.searchTerm = '';
            this.searchTermDisplay = '';
            this.onFilterApply();
        }
        let currentCtrl: any = document.getElementById("control_" + control.ControlHtmlID)!;
        if (this.documentControlsResponse.EnableAutoFillTextControls == true || this.documentControlsResponse.EnableAutoFillTextControls == "true" || this.documentControlsResponse.EnableAutoFillTextControls == "True") {
            this.updateSimilarTextFields(control.ControlHtmlID, currentCtrl.value);
        }
    }

    updateSimilarTextFields(controlId: any, controlValue: any) {
        let currentCtrl: any = document.getElementById("control_" + controlId)!;
        let currentCtrlId: any = currentCtrl.getAttribute('id');
        let conTitle = currentCtrl.getAttribute("data-title");
        let currentFocusedControl = document.getElementById(controlId)!.getAttribute("data-recipientid");
        if (conTitle != undefined) {
            let textcontolsCollection: any = this.document.getElementsByClassName("textsignerControl");
            let currentPlaceHoder = currentCtrl.getAttribute("placeholder");

            let txControlArray: string[] = [];
            txControlArray.push(controlId);
            if (textcontolsCollection != null && textcontolsCollection.length > 0) {
                Array.from(textcontolsCollection).forEach((txtControl: any) => {
                    var placeholder = txtControl.getAttribute('placeholder');
                    var arrControlId: any = txtControl.getAttribute('id');
                    let arrConTitle = txtControl.getAttribute("data-title");
                    if (arrControlId) {
                        let arrFocuedctrl: any = arrControlId.replace("control_", "");
                        let arrFocusedControl = document.getElementById(arrFocuedctrl)!.getAttribute("data-recipientid");
                        if (currentFocusedControl == arrFocusedControl) {
                            if (currentCtrlId != arrControlId && arrConTitle == conTitle && currentPlaceHoder.toLowerCase() == placeholder.toLowerCase()) {
                                if (txtControl.value == "") {
                                    if (conTitle == "Zip" || conTitle == "SSN") {
                                        if (txtControl.getAttribute('data-mask') == currentCtrl.getAttribute('data-mask')) {
                                            txtControl.value = controlValue;
                                            if (controlValue != "") {
                                                txControlArray.push(arrFocuedctrl);
                                            }
                                        }
                                    }
                                    else if (conTitle != "Zip" && conTitle != "SSN" && conTitle != "Date") {
                                        txtControl.value = controlValue;
                                        if (controlValue != "") {
                                            txControlArray.push(arrFocuedctrl);
                                            if(this.webOrMobile === 'mobile'){
                                                let myTextArea: any = document.getElementById('control_' + arrFocuedctrl);
                                                this.updateExpandControlIfApplicable(arrFocuedctrl, myTextArea.value);
                                            } else {
                                                this.updateAutoExpand(arrFocuedctrl);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                });

                if (txControlArray && txControlArray.length > 0) {
                    for (let index = 0; index < txControlArray.length; index++) {
                        const element = txControlArray[index];
                        let contrl: any = document.getElementById('control_' + element);
                        let isValid: boolean = false;
                        if (contrl) {
                            let datatitle: any = contrl.getAttribute("data-title");
                            if (datatitle == "SSN" || datatitle == "Zip") {
                                if (contrl.value.indexOf('_') > 0 || contrl.value.indexOf('_') > -1 || contrl.value.indexOf('-') > 5) {
                                    isValid = true;
                                }
                            }
                        }
                        if (contrl.value !== '' && contrl.value !== ' ' && contrl.value !== null && !isValid) {
                            this.applyStylesToCheckList("add", element);
                            let controlele: any = document.getElementById('control_' + element)!
                            let controlId: any = controlele.parentNode;
                            controlId.classList.add('filledControls');
                        }
                    }
                }

                this.getTotalRemaingFieldsControls('');
            }
        }
    }

    updateSignerProfilePopupheight() {
        if (this.webOrMobile == 'mobile') {
            setTimeout(() => {
                let clickToSign: any = this.document.getElementById("clickToSign");
                clickToSign.scrollTop = clickToSign.scrollHeight - clickToSign.clientHeight;
            }, 100);
        }
    }

    // Adding for class name for mobile popUp nav buttons
    getPrevNextBtnAlignmentsMobile() {
        let className: any = (this.mobilePrevBtn !== 'true') ? 'justify-content-end' : 'justify-content-between';
        return className;
    }

    getPreviewImageStyle() {
        let className: any = '';
        let isUploadImgFlag: any = localStorage.getItem('isUploadImgFlag');
        if (isUploadImgFlag === '1') {
            className = 'mainPreviewImageStyle';
        }
        return className;
    }

    getPencilIconStyle() {
        let electronicSignIndication = this.documentControlsResponse.EnvelopeInfo.ElectronicSignIndication;
        if (electronicSignIndication != null && electronicSignIndication != undefined && electronicSignIndication != "") {
            electronicSignIndication = parseInt(electronicSignIndication);
        }
        else {
            electronicSignIndication = 1;
        }

        if (electronicSignIndication > 1) {
            return { 'display': 'block' };
        }
        else {
            return { 'display': 'none' };
        }
    }
    ShowDefaultSignature() {
        let count = 0;
        Array.from(document.getElementsByClassName("commonSignatureControl") as HTMLCollectionOf<HTMLElement>)
            .forEach((sigItem: any) => {
                let cntType = sigItem.attributes["data-ctntype"].value;
                let display: any = window.getComputedStyle(sigItem).display;
                if (cntType == "Signature" && window.getComputedStyle(sigItem).display !== 'none') {
                    count = count + 1;
                }
            });

        if (count > 0) {
            this.showDefaultSignatureContol = "none";
        }
        else {
            this.showDefaultSignatureContol = "block";
        }
    }

    ShowOrDisabledChangeSigner() {
        //(ViewBag.Prefill == "prefill" || Model.IsSingleSigning) ? "disabledThisLink"
        let classname: any = "";
        if (this.IsPrefill == "prefill" || this.documentControlsResponse.EnvelopeInfo.IsSingleSigning) {
            classname = "disabledThisLink";
        }
        else {
            classname = "cursor-pointer";
        }
        return classname;
    }

    isDisabledChangeSigner() {
        //(ViewBag.Prefill == "prefill" || Model.IsSingleSigning) ? "disabledThisLink"
        let classname: any = "";
        if (this.IsPrefill == "prefill" || (this.documentControlsResponse != null && this.documentControlsResponse.EnvelopeInfo.IsSingleSigning)) {
            return true;
        }
        else {
            return false;
        }
    }

    getLanguageTranslationValue(resourcekeyId: any) {
        return this.translationsService.getLanguageTranslationVal(resourcekeyId);
    }

    handlePinchZoomEvents(e: any) {
        //console.log('pinchzoom', e);
    }

    updateDefaultValueOnEmailControl() {
        let ProvidedUserEmail: any = localStorage.getItem("ProvidedUserEmail");

        let curRoleId: any = localStorage.getItem("CurrentRecipientID");
        if (curRoleId == null) {
            curRoleId = localStorage.getItem("RecipientID");
        }
        if (ProvidedUserEmail != null && ProvidedUserEmail != "" && ProvidedUserEmail != undefined && ProvidedUserEmail != 'null') {
            let currentEmail = ProvidedUserEmail;
            let currentRoleID = curRoleId;
            let controlsArray: string[] = [];
            let emailControlClassList: any = document.getElementsByClassName("emailControlClass");
            Array.from(emailControlClassList).forEach((ctrl: any) => {
                let datarecipientid: any = ctrl.getAttribute("data-recipientid");
                if (datarecipientid.toLowerCase() == currentRoleID.toLowerCase()) {
                    let emailCtrl: any = document.getElementById("control_" + ctrl.getAttribute("id"));
                    emailCtrl.value = currentEmail;
                    controlsArray.push(ctrl.getAttribute("id"));
                    let controlele: any = document.getElementById('control_' + ctrl.getAttribute("id"))!
                    let controlId: any = controlele.parentNode;
                    controlId.classList.add('filledControls');
                }
            });

            if (controlsArray && controlsArray.length > 0) {
                for (let index = 0; index < controlsArray.length; index++) {
                    const element = controlsArray[index];
                    this.applyStylesToCheckList("add", element);
                }
            }
        }
    }

    getSelectTagStyle(ctrlObj: any) {
        return { 'width': '100%', 'font-size': ctrlObj.FontSize + 'px', 'padding': '1px' };
    }

    getPageSectionClass(type: any) {
        if ((this.webOrMobile === 'mobile' && type == 1) || (this.webOrMobile === 'web' && type == 2))
            return "";
        else if ((this.webOrMobile === 'mobile' && type == 2) || (this.webOrMobile === 'web' && type == 1))
            return 'page-section';
        else
            return 'page-section';
    }

    getDocumentPagStyle() {
        if (this.webOrMobile === 'mobile') {
            return { 'overflow-y': 'visible', 'overflow-x': 'visible' };
        }
        return;
    }

    checkSignAndInitialsDisabled() {
        let className: any = 'enabledCtrl'
        if (this.signClickCount > 0) {
            className = 'disabledCtrl'
        }
        return className;
    }
    getFooterAlignment() {
        let className: any = 'col-md-12';
        if (this.hidePagesViewSection == false) {
            className = 'col-md-10 offset-md-2'
        }
        return className;
    }

    defaultControlShowTooltip() {
        let currentCtrlIndexPos: any;
        for (let i = 0; i <= this.mobileControlArr.length; i++) {
            let mobileCtrl: any = this.mobileControlArr[i];
            if (mobileCtrl == undefined || mobileCtrl == null) {
                break;
            }
            let controlId = document.getElementById(mobileCtrl.controlId)!;
            currentCtrlIndexPos = i;
            let cTabIndex: any = currentCtrlIndexPos;
            this.tabIndex = parseInt(cTabIndex);
            this.tabIndex = this.returnNextTabIndex(this.tabIndex);
            mobileCtrl = this.mobileControlArr[this.tabIndex];

            if (mobileCtrl.controlName === 'Signature') {
                let controlIdEle: any = document.getElementById(this.mobileControlArr[i].controlId);
                let splitControlId: any = mobileCtrl.controlId.split('_')[1];
                let signControlValue: any = controlIdEle.getAttribute('src');
                if (splitControlId) {
                    if (signControlValue !== '' && signControlValue !== undefined && signControlValue != null) {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'none';
                    } else {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'block';
                        this.assignTimeOutForCtrlFocus(splitControlId);
                        break;
                    }
                }
            } else if (mobileCtrl.controlName === 'NewInitials') {
                let controlIdEle: any = document.getElementById(this.mobileControlArr[i].controlId);
                let splitControlId: any = mobileCtrl.controlId.split('_')[1];
                let signControlValue: any = controlIdEle.getAttribute('src');
                if (splitControlId) {
                    if (signControlValue !== '' && signControlValue !== undefined && signControlValue != null) {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'none';
                    } else {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'block';
                        this.assignTimeOutForCtrlFocus(splitControlId);
                        break;
                    }
                }
            } else if (mobileCtrl.controlName === 'Name') {
                let controlIdEle: any = document.getElementById(this.mobileControlArr[i].controlId);
                let splitControlId: any = mobileCtrl.controlId.split('_')[1];
                let value: any = controlIdEle.value;
                if (splitControlId) {
                    if (value !== '' && value !== undefined && value != null) {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'none';
                    } else {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'block';
                        this.assignTimeOutForCtrlFocus(splitControlId);
                        break;
                    }
                }
            } else if (mobileCtrl.controlName === 'Title') {
                let controlIdEle: any = document.getElementById(this.mobileControlArr[i].controlId);
                let splitControlId: any = mobileCtrl.controlId.split('_')[1];
                let value: any = controlIdEle.value;
                if (splitControlId) {
                    if (value !== '' && value !== undefined && value != null) {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'none';
                    } else {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'block';
                        this.assignTimeOutForCtrlFocus(splitControlId);
                        break;
                    }
                }
            } else if (mobileCtrl.controlName === 'Company') {
                let controlIdEle: any = document.getElementById(this.mobileControlArr[i].controlId);
                let splitControlId: any = mobileCtrl.controlId.split('_')[1];
                let value: any = controlIdEle.value;
                if (splitControlId) {
                    if (value !== '' && value !== undefined && value != null) {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'none';
                    } else {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'block';
                        this.assignTimeOutForCtrlFocus(splitControlId);
                        break;
                    }
                }
            } else if (mobileCtrl.controlName === 'Email') {
                let controlIdEle: any = document.getElementById(this.mobileControlArr[i].controlId);
                let splitControlId: any = mobileCtrl.controlId.split('_')[1];
                let value: any = controlIdEle.value;
                if (splitControlId) {
                    if (value !== '' && value !== undefined && value != null) {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'none';
                    } else {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'block';
                        this.assignTimeOutForCtrlFocus(splitControlId);
                        break;
                    }
                }
            } else if (mobileCtrl.controlName === 'Date') {
                let controlIdEle: any = document.getElementById(this.mobileControlArr[i].controlId);
                let splitControlId: any = mobileCtrl.controlId.split('_')[1];
                let value: any = controlIdEle.value;
                if (splitControlId) {
                    if (value !== '' && value !== undefined && value != null) {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'none';
                    } else {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'none';
                        this.assignTimeOutForCtrlFocus(splitControlId);
                        break;
                    }
                }
            } else if (mobileCtrl.controlName === 'DropDown') {
                let controlIdEle: any = document.getElementById(this.mobileControlArr[i].controlId);
                let splitControlId: any = mobileCtrl.controlId.split('_')[1];
                let value: any = controlIdEle[0].innerText.trim();
                if (splitControlId) {
                    if (value !== '' && value !== undefined && value != null) {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'none';
                    } else {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'block';
                        this.assignTimeOutForCtrlFocus(splitControlId);
                        break;
                    }
                }
            } else if (mobileCtrl.controlName === 'Radio' || mobileCtrl.controlName === 'Checkbox') {
                let controlIdEle: any = document.getElementById(this.mobileControlArr[i].controlId);
                let splitControlId: any = mobileCtrl.controlId.split('_')[1];
                let value: any = controlIdEle.checked;
                if (splitControlId) {
                    if (value && value === true) {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'none';
                    } else {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'block';
                        this.assignTimeOutForCtrlFocus(splitControlId);
                        break;
                    }
                }
            } else if (mobileCtrl.controlName === 'Text') {
                let controlIdEle: any = document.getElementById(this.mobileControlArr[i].controlId);
                let splitControlId: any = mobileCtrl.controlId.split('_')[1];
                let value: any = controlIdEle.value;
                if (splitControlId) {
                    if (value !== '' && value !== undefined && value != null) {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'none';
                    } else {
                        document.getElementById('tooltip_' + splitControlId)!.style.display = 'block';
                        this.assignTimeOutForCtrlFocus(splitControlId);
                        break;
                    }
                }
            }
        }
        if (this.tabIndex == 0) {
            this.mobilePrevBtn = 'false';
            this.mobileNextBtn = 'true';
        }
        else if (this.tabIndex == this.mobileControlArr.length - 1) {
            this.mobilePrevBtn = 'true';
            this.mobileNextBtn = 'false';
        }

        else if (this.tabIndex != currentCtrlIndexPos) {
            for (let index = 0; index < this.tabIndex; index++) {
                const element = this.mobileControlArr[index];
                if (element) {
                    if (document.getElementById(element.controlId.replace('control_', ''))!.style.display == "none") {
                        this.mobilePrevBtn = 'false';
                        this.mobileNextBtn = 'true';
                    }
                }
            }
        }
        else {
            this.mobilePrevBtn = 'true';
            this.mobileNextBtn = 'true';
        }

    }

    isControlFilledOrNot(event: any, ctrlObj: any) {
        this.hideAllControlTooltips();

        let value: any = event.target.value
        let controlId = document.getElementById('control_' + ctrlObj.ControlHtmlID)!;
        let controlTooltipId = document.getElementById('tooltip_' + ctrlObj.ControlHtmlID)!;
        if (controlId) {
            controlId.blur();
        }
        let cname: any = controlId.getAttribute('data-title');
        for (var i = 0; i < this.mobileControlArr.length; ++i) {
            if (this.mobileControlArr[i].ControlId == controlId) {
                this.tabIndex = i;
                break;
            }
        }

        this.tabIndex = parseInt(this.tabIndex);

        if (ctrlObj.ControlName.toLowerCase() === 'text') {
            if (cname === 'SSN' || cname === 'Zip') {
                if (value != '___-__-____' && value != '_____-____' && value != '_____') {
                    if (value.indexOf('_') > 0 || value.indexOf('_') > -1 || value.indexOf('-') > 5) {
                        if (cname === 'SSN' || cname === 'Zip') {
                            controlTooltipId.style.display = 'block';
                            if (cname === 'Zip') {
                                this.showToaster(this.invlaidZIP, 'Warning');
                            }
                            if (cname === 'SSN') {
                                this.showToaster(this.invlaidSSN, 'Warning');
                            }
                        }
                    } else {
                        controlTooltipId.style.display = 'none';
                        this.getControlNextNav('');
                    }
                } else {
                    if (cname === 'Zip') {
                        this.showToaster(this.invlaidZIP, 'Warning');
                    }
                    if (cname === 'SSN') {
                        this.showToaster(this.invlaidSSN, 'Warning');
                    }
                }
            } else if (cname === 'Text') {
                value = value.replace(/[\n\r]/g, '');
                var controlHeight = document.getElementById(ctrlObj.ControlHtmlID)!.style.height;
                let calcontrolHeight = controlHeight.replace('px', '');

                if (parseInt(calcontrolHeight) <= 20) {
                    controlTooltipId.style.display = 'none';
                    this.getControlNextNav('');
                }
                else {
                    if (value != '' && value != null && value != undefined) {
                        controlTooltipId.style.display = 'none';
                        return;
                    }
                    else {
                        controlTooltipId.style.display = 'block';
                        // this.showToaster('Please enter text', 'Warning');
                        return;
                    }
                }
            } else if (cname === 'Email') {
                if (value != '' && value != null && value != undefined) {
                    if (this.validateEmail(value)) {
                        controlTooltipId.style.display = 'none';
                        this.getControlNextNav('');
                    } else {
                        controlTooltipId.style.display = 'block';
                        this.showToaster(this.invalidEmail, 'Warning');
                    }
                }
            } else if (cname === 'Date') {
                controlTooltipId.style.display = 'none';
                this.getControlNextNav('');
            } else {
                controlTooltipId.style.display = 'none';
                this.getControlNextNav('');
            }
        } else if (ctrlObj.ControlName.toLowerCase() === 'signature' || ctrlObj.ControlName.toLowerCase() === 'newinitials') {
            this.getControlNextNav('headerControls');
        } else if (ctrlObj.ControlName.toLowerCase() === 'dropdown') {
            let srcValue: any = document.getElementById('control_' + ctrlObj.ControlHtmlID)!;
            let value: any = srcValue.selectedOptions[0].innerText.trim();
            controlTooltipId.style.display = 'none';
            this.getControlNextNav('');
        } else if (ctrlObj.ControlName.toLowerCase() === 'date') {
            controlTooltipId.style.display = 'none';
            this.getControlNextNav('');
        } else {
            controlTooltipId.style.display = 'none';
            this.getControlNextNav('');
        }

    }


    checkAllControlsAreFilled() {
        let arrayLastElementTabIndex = this.mobileControlArr.length - 1;

        let tabPos: any;
        if (this.tabIndex === arrayLastElementTabIndex && this.remainingFieldsCount !== 0) {
            tabPos = -1;
        }
        else if (this.tabIndex === arrayLastElementTabIndex && this.remainingFieldsCount == 0) {
            tabPos = arrayLastElementTabIndex;
            this.hideAllMobilePrevNextDoneBtns();
        }
        else {
            tabPos = this.tabIndex;
        }

        return tabPos;
    }

    checkisLastControl(contrlTabIndex: any) {
        let arrLength = this.mobileControlArr.length;
        let arrayLastElementTabIndex = this.mobileControlArr[arrLength - 1].tabindex;
        if (this.mobileControlArr && this.mobileControlArr.length) {
            if (contrlTabIndex === parseInt(arrayLastElementTabIndex)) {
                this.mobileNextBtn = 'false';
                if (this.remainingFieldsCount === 1) {
                    this.mobileDoneBtn = 'true';
                } else {
                    this.mobileDoneBtn = 'false';
                }
            } else {
                this.mobileDoneBtn = 'false';
            }
        } else {
            this.mobileDoneBtn = 'false';
        }
    }

    hideToolTip(contrlObj: any) {
        if (contrlObj != null) {
            for (var i = 0; i < this.mobileControlArr.length; ++i) {
                if (this.mobileControlArr[i].tabindex == contrlObj.TabIndex) {
                    this.tabIndex = i;
                    break;
                }
            }
        }
        this.hideAllControlTooltips();
    }

    hideAllOrangeBorder() {
        for (let i = 0; i < this.mobileControlArr.length; i++) {
            if (this.mobileControlArr[i].controlName.toLowerCase() !== 'signature' && this.mobileControlArr[i].controlName.toLowerCase() !== 'newinitials') {
                let tooltipControlEle: any = document.getElementById(this.mobileControlArr[i].controlId);
                tooltipControlEle.style.border = '1px solid grey';
            }
        }
    }

    checkTabindexNextControlIsFilledOrNot() {
        if (this.tabIndex <= this.mobileControlArr.length - 1) {
            let tindex: any = this.returnNextTabIndex(this.tabIndex);
            if (tindex != this.tabIndex) {
                this.tabIndex = this.tabIndex + 1;
            }
        }
        return;
    }

    checkTabindexPrevControlIsFilledOrNot() {
        if (this.tabIndex <= this.mobileControlArr.length - 1) {
            let tindex: any = this.returnPrevTabIndex(this.tabIndex);
            if (tindex != this.tabIndex) {
                this.tabIndex = this.tabIndex - 1;
            }
        }
        return;
    }

    checkTooltipIsApplicable(controleID: any) {
        let ele: any = document.getElementById(controleID);
        let controlEle: any = document.getElementById('control_' + controleID);
        let controlTooltipEle: any = document.getElementById('tooltip_' + controleID);
        let ctrlELeName: any = ele.getAttribute('data-ctntype');
        if (ctrlELeName === 'Signature' || ctrlELeName === 'NewInitials') {
            if (controlEle.getAttribute('src') === '' || controlEle.getAttribute('src') === null || controlEle.getAttribute('src') === undefined) {
                controlTooltipEle.style.display = 'block';
            }
        }

        if (this.tabIndex == 0) {
            this.mobilePrevBtn = 'false';
            this.mobileNextBtn = 'true';
        }
        else if (this.tabIndex == this.mobileControlArr.length - 1) {
            this.mobilePrevBtn = 'true';
            this.mobileNextBtn = 'false';
        }
        else if (this.tabIndex != this.mobileControlArr.length - 1) {
            let arr: any = this.mobileControlArr[this.tabIndex];
            if (arr) {
                if (this.mobileControlArr.length && document.getElementById(arr.controlId.replace('control_', ''))!.style.display == "none") {
                    this.mobilePrevBtn = 'true';
                    this.mobileNextBtn = 'false';
                }
                else {
                    this.mobilePrevBtn = 'true';
                    this.mobileNextBtn = 'true';
                }
            }
        }
        else {
            this.mobilePrevBtn = 'true';
            this.mobileNextBtn = 'true';
        }
    }

    //Hide all control tooltips
    hideAllControlTooltips() {
        for (let i = 0; i < this.mobileControlArr.length; i++) {
            let tooltipControlEle: any = document.getElementById('tooltip_' + this.mobileControlArr[i].controlId.replace('control_', ''));
            if (tooltipControlEle && tooltipControlEle.style) {
                tooltipControlEle.style.display = 'none';
            }
        }
    }

    imageLoaded(arr: any): void {
        let apiEnvelopGetImages: string = "";
        if (this.documentControlsResponse.EnvelopeInfo.SubEnvelopeId !== null && this.documentControlsResponse.EnvelopeInfo.SubEnvelopeId != undefined &&
            this.documentControlsResponse.EnvelopeInfo.SubEnvelopeId !== '00000000-0000-0000-0000-000000000000') {
            apiEnvelopGetImages = this.commonConstants.GetImagesForSubEnvelopePrefill;
        } else if (this.documentControlsResponse.EnvelopeInfo.SubEnvelopeId === null &&
            this.documentControlsResponse.IsTemplateShared === true && this.documentControlsResponse.Prefill === 'prefill') {
            apiEnvelopGetImages = this.commonConstants.GetImagesForSubEnvelopePrefill;
        }
        else {
            apiEnvelopGetImages = this.commonConstants.GetEnvelopeImages;
        }
        if (this.documentControlsResponse && this.documentControlsResponse.EnvelopeImageControlData && this.documentControlsResponse.EnvelopeImageControlData.length) {
            if (this.documentControlsResponse.EnvelopeImageControlData.length > 1) {
                let length: any = 1;
                this.showLoader = true;

                this.checkImagesLoaded(arr, length, apiEnvelopGetImages);
            } else {
                let length: any = this.documentControlsResponse.EnvelopeImageControlData.length;
                this.showLoader = true;
                this.checkImagesLoaded(arr, length, apiEnvelopGetImages);
            }
        }
    }

    checkImagesLoaded(arr: any, length: any, apiGetImagesPath: any) {
        for (var i = 0; i < length; i++) {
            let image: any = this.baseUrl + apiGetImagesPath + arr[i].ImagePath;
            this.loadedImagesTemp.push(image);
            let loadedCount = 0;
            for (const url of this.loadedImagesTemp) {
                const img: any = new Image();
                img.onload = () => {
                    this.loadedImages[url] = img.src;
                    loadedCount++;
                    if (loadedCount === this.loadedImagesTemp.length) {
                        this.showControlsFlag = 'block';
                        this.isShowFooter = 'true';
                        this.showLoader = false;
                        let imagePathArr: any = [];
                        if (this.documentControlsResponse.EnvelopeImageControlData && this.documentControlsResponse.EnvelopeImageControlData.length) {
                            for (var i = 0; i < this.documentControlsResponse.EnvelopeImageControlData.length; i++) {
                                imagePathArr.push(this.baseUrl + apiGetImagesPath + this.documentControlsResponse.EnvelopeImageControlData[i].ImagePath);
                            }
                        } else {
                            this.removepaginationDisabledClass();
                        }

                        if (imagePathArr && imagePathArr.length) {
                            this.loadImagesAndCheckAllLoaded(imagePathArr);
                        } else {
                            this.removepaginationDisabledClass();
                        }
                        this.addTextAreEventListener();
                    }
                    else {
                        this.showLoader = true;
                    }
                    this.cdRef.detectChanges();
                };
                img.src = url;
            }
        }
        this.showLoader = false;

    }

    assignTimeOutForCtrlFocus(id: any) {
        setTimeout(() => {
            document.getElementById('control_' + id)!.focus();
        }, 500);
    }

    showFinishModalPopup() {
        setTimeout(() => {
            let optionalCtrlsArr: any = [];
            let mandetoryCtrlsArr: any = [];
            let successColorCtrlCount: number = 0;
            let lblControlField: any = document.getElementsByClassName('lblControlField');
            if (lblControlField && lblControlField.length > 0) {
                Array.from(lblControlField as HTMLCollectionOf<HTMLElement>)
                    .forEach((eleChild: any) => {
                        let eleId: any = eleChild.getAttribute('id');
                        let lblItem: any = document.getElementById(eleId.replace('label_', 'anchor_'));
                        let ctrlDisplay: any = window.getComputedStyle(lblItem).display !== 'none';
                        if (ctrlDisplay == true || ctrlDisplay == "true") {
                            let successColorCtrl: any = eleChild.classList.contains('success-color');
                            let requriedColorCtrl: any = eleChild.classList.contains('requried-color');
                            let isRequired: any = eleChild.getAttribute('data-rq');
                            let isDataForCtrl: any = eleChild.getAttribute('data-for');
                            if (isRequired === 'true') {
                                mandetoryCtrlsArr.push(isDataForCtrl);
                            } else {
                                optionalCtrlsArr.push(isDataForCtrl);
                            }
                            if (successColorCtrl === true) {
                                successColorCtrlCount = successColorCtrlCount + 1;
                            }
                        }
                    });

                if (successColorCtrlCount != 0 && successColorCtrlCount == this.remainingFieldsTotalCount) {
                    this.remainingFieldsCount = 0;
                    this.hideAllMobilePrevNextDoneBtns();
                    this.hideToolTip(null);
                    // this.showFinishModalDailog = 'block';
                }
                else {
                    this.remainingFieldsCount = this.remainingFieldsTotalCount - successColorCtrlCount;
                }
            } else {
                // this.showFinishModalDailog = 'none';
            }
        }, 200);
    }

    onShowFinishModalClose() {
        this.modalService.dismissAll();
        // this.showFinishModalDailog = 'none';
    }

    mobileCheckTextDependenciesControl(ctlid: any, txtMaximizeControlVal: any) {
        let controlObj: any = {};
        let tempcontrolObj: any;
        let isFound: boolean = false;
        if (this.documentControlsResponse.EnvelopeImageControlData && this.documentControlsResponse.EnvelopeImageControlData.length) {
            for (var i = 0; i < this.documentControlsResponse.EnvelopeImageControlData.length; i++) {
                if (!isFound && this.documentControlsResponse.EnvelopeImageControlData[i].ControlsData && this.documentControlsResponse.EnvelopeImageControlData[i].ControlsData.length) {
                    for (var j = 0; j < this.documentControlsResponse.EnvelopeImageControlData[i].ControlsData.length; i++) {
                        controlObj = this.documentControlsResponse.EnvelopeImageControlData[i].ControlsData.filter((c: any) => c.ControlHtmlID === ctlid);
                        if (controlObj && controlObj.length) {
                            tempcontrolObj = controlObj[0];
                            isFound = true;
                            break;
                        }
                    }
                }
            }
        }
        if (tempcontrolObj !== undefined) {
            this.showTextDependenciesControl(tempcontrolObj, txtMaximizeControlVal);
            if (this.searchTermDisplay != '') {
                this.searchTerm = '';
                this.searchTermDisplay = '';
                this.onFilterApply();
            }
        }
    }

    checkDocumentControlsResponselocal() {
        let documentControlsRespp: any = localStorage.getItem("documentControlsResponse");
        if (documentControlsRespp == null || documentControlsRespp == undefined || documentControlsRespp == "") {
            return true;
        }
        else {
            let documentControlsResponselocal: any = JSON.parse(documentControlsRespp);
            if (documentControlsResponselocal == null || documentControlsResponselocal == undefined || documentControlsResponselocal == "") {
                return true;
            }
            else {
                return false;
            }
        }
    }

    loadImagesAndCheckAllLoaded(arr: any) {
        this.commonService.loadImages(arr)
            .then((allLoaded) => {
                if (allLoaded) {
                    console.log('All images have been loaded.');
                    this.removepaginationDisabledClass();
                    setTimeout(() => {
                        if (this.documentControlsResponse.EnvelopeInfo.IsDisclaimerEnabled === false && localStorage.getItem('prefill') !== 'prefill' &&
                            this.byDefaultShowCtrlTooltip === false && this.IsEnableClickToSign === false) {
                            this.defaultControlShowTooltip();
                        }
                    }, 200);
                } else {
                    console.log('Some images failed to load.');
                    this.removepaginationDisabledClass();
                }
            });
        let fotertextClass: any = document.getElementsByClassName('fotertext');
        if (fotertextClass && fotertextClass[0]) {
            fotertextClass[0].style.border = 'left';
        }
    }

    removepaginationDisabledClass() {
        let paginationLoader: any = document.getElementsByClassName('spinner-border');
        if (paginationLoader && paginationLoader.length > 0) {
            Array.from(paginationLoader as HTMLCollectionOf<HTMLElement>)
                .forEach((eleChild: any) => {
                    eleChild.style.display = 'none';
                })
        }
        let paginationContainer: any = document.getElementsByClassName('paginationContainer');
        if (paginationContainer && paginationContainer.length > 0) {
            Array.from(paginationContainer as HTMLCollectionOf<HTMLElement>)
                .forEach((eleChild: any) => {
                    eleChild.classList.remove('pagination-disabled');
                })
        }
    }

    addModelPopupClassForBodyTag() {
        let bodyEle: any = document.getElementsByTagName('body');
        if (bodyEle && bodyEle[0].classList) {
            bodyEle[0].classList.add('model-popup', 'overflowHidden');
        }
    }

    removeModelPopupClassForBodyTag() {
        let bodyEle: any = document.getElementsByTagName('body');
        if (bodyEle && bodyEle[0].classList) {
            bodyEle[0].classList.remove('model-popup', 'overflowHidden');
        }
    }

    updatedmobileControlPosition(id: any) {
        const zoomStyles: any = getComputedStyle(document.getElementsByClassName('document-controls-zoom')[0]);
        let zoomScale: any = zoomStyles.getPropertyValue('transform').match(/-?[\d\.]+/g)[0];
        let ele: any = document.getElementById(id.replace('control_', ''))!;
        let pageEle: any;
        let posControl: any = ele;

        let top: any = parseFloat(posControl.style.top.split('px')[0]) * parseFloat(zoomScale);
        let left: any = parseFloat(posControl.style.left.split('px')[0]) * parseFloat(zoomScale);

        $('html, body').animate({ scrollTop: top, scrollLeft: left }, 100);

    }

    hideAllMobilePrevNextDoneBtns() {
        this.mobilePrevBtn = 'false';
        this.mobileNextBtn = 'false';
        this.mobileDoneBtn = 'true';
        this.cdRef.detectChanges();
    }

    getUpdateCheckList() {
        this.documentControlsResponse.CheckListData.forEach((element: any) => {
            if (element.ControlsData != null && element.ControlsData.length > 0) {
                element.ControlsData.forEach((eleChild: any) => {
                    if (eleChild.ControlName == "Signature" || eleChild.ControlName == "NewInitials") {


                        let sigImageCollection: any = document.getElementById('control_' + eleChild.ControlHtmlID);
                        let sigSignatureScr = sigImageCollection.attributes["src"] != undefined ? sigImageCollection.attributes["src"].value : '';
                        if (sigSignatureScr != null && sigSignatureScr != '' && sigSignatureScr != undefined) {
                            this.applyStylesToCheckList("add", eleChild.ControlHtmlID);
                        } else {
                            this.applyStylesToCheckList("remove", eleChild.ControlHtmlID);
                        }
                    }
                    else if (eleChild.ControlName.toLowerCase() == "dropdown") {
                        let currentdrpCtrl: any = (document.getElementById("control_" + eleChild.ControlHtmlID)) as HTMLSelectElement;
                        let sel = currentdrpCtrl.selectedIndex;
                        let opt = currentdrpCtrl.options[sel];
                        let value = (<HTMLSelectElement>opt).textContent;
                        if (value === '' || value === ' ') {
                            this.applyStylesToCheckList("remove", eleChild.ControlHtmlID);
                        }
                        else {
                            this.applyStylesToCheckList("add", eleChild.ControlHtmlID);
                        }
                    }
                    else if (eleChild.ControlName.toLowerCase() == "radio") {
                        let curCheckboxctrl: any = "control_" + eleChild.ControlHtmlID;
                        let eleCtrl: any = (<HTMLInputElement>document.getElementById("control_" + eleChild.ControlHtmlID));
                        if (eleCtrl != null && eleCtrl != undefined && eleCtrl != "") {
                            var name = (<HTMLInputElement>document.getElementById("control_" + eleChild.ControlHtmlID)).getAttribute("name");
                            if (name != "" && $('.signerControl input:radio[name="' + name + '"]:checked').length > 0) {
                                let cc: any = $('.signerControl input:radio[name="' + name + '"]:checked');
                                Array.from(cc as HTMLCollectionOf<HTMLElement>).forEach((ele: any) => {
                                    let ctrId: any = ele.getAttribute("id");
                                    this.applyStylesToCheckList("add", eleChild.ControlHtmlID);
                                });
                            }
                        }
                    }
                    else if (eleChild.ControlName.toLowerCase() == "checkbox") {
                        let curCheckboxctrl: any = "control_" + eleChild.ControlHtmlID;
                        let eleCtrl: any = (<HTMLInputElement>document.getElementById("control_" + eleChild.ControlHtmlID));
                        if (eleCtrl != null && eleCtrl != undefined && eleCtrl != "") {
                            var name = (<HTMLInputElement>document.getElementById("control_" + eleChild.ControlHtmlID)).getAttribute("name");
                            if (name != "" && $('.signerControl input:checkbox[name="' + name + '"]:checked').length > 0) {
                                let cc: any = $('.signerControl input:checkbox[name="' + name + '"]:checked');
                                Array.from(cc as HTMLCollectionOf<HTMLElement>).forEach((ele: any) => {
                                    let ctrId: any = ele.getAttribute("id");
                                    this.applyStylesToCheckList("add", eleChild.ControlHtmlID);
                                });
                            }
                            else {
                                let value = (<HTMLInputElement>document.getElementById(curCheckboxctrl)).checked;
                                if (value === true) {
                                    this.applyStylesToCheckList("add", eleChild.ControlHtmlID);
                                }
                                else if (value === false) {
                                    this.applyStylesToCheckList("remove", eleChild.ControlHtmlID);
                                }
                            }
                        }
                    }
                    else if (eleChild.ControlName.toLowerCase() == "date" || eleChild.ControlName.toLowerCase() == "email" || eleChild.ControlName.toLowerCase() == "name"
                        || eleChild.ControlName.toLowerCase() == "company" || eleChild.ControlName.toLowerCase() == "title" || eleChild.ControlName.toLowerCase() == "text") {
                        let curCtrl: any = (<HTMLInputElement>document.getElementById("control_" + eleChild.ControlHtmlID));
                        if (curCtrl) {
                            let value = (<HTMLInputElement>document.getElementById("control_" + eleChild.ControlHtmlID)).value;
                            if (value !== '' && value !== ' ' && value !== null) {
                                this.applyStylesToCheckList("add", eleChild.ControlHtmlID);
                            }
                            else {
                                this.applyStylesToCheckList("remove", eleChild.ControlHtmlID);
                            }
                        }
                    }
                    else {
                        if (eleChild.ControlValue !== '' && eleChild.ControlValue !== ' ' && eleChild.ControlValue !== null && eleChild.ControlValue.toLowerCase() !== 'false') {
                            //eleChild.isControlFiled = true;
                            this.applyStylesToCheckList("add", eleChild.ControlHtmlID);
                        } else {
                            // eleChild.isControlFiled = false;
                            this.applyStylesToCheckList("remove", eleChild.ControlHtmlID);
                        }

                    }
                });
            }
        });
    }

    handleUpdateSigAndInitialProfileText(event: Event): void {
        const value = (event.target as HTMLInputElement).value;
        this.inputSignChangeSubject.next(value);
    }

    handleUpdateInitialsimageFromSignerProfileText(event: Event): void {
        const value = (event.target as HTMLInputElement).value;
        this.inputInitChangeSubject.next(value);
    }

    checkPinchZoomIsApplicable(ctrlId: any) {
        if (this.pinchZoom.pinchZoom.initialScale === 1) {
            this.pinchZoom.toggleZoom();
            setTimeout(() => {
                this.updateControlPositions(ctrlId);
            }, 300);
        } else {
            if (this.pinchZoom.pinchZoom.initialScale > 1) {
                this.updateControlPositions(ctrlId);
            }
        }
    }

    checkScrollCountAndUpdatePopup(contrlObj: any, selectedFromFilter: any) {
        // Clear any existing scrollingTimeout
        clearTimeout(this.scrollingTimeout);

        // Set a new timeout to detect the end of scrolling
        this.scrollingTimeout = setTimeout(() => {
            // Scrolling has ended, do something here
            console.log('Scrolling has ended.');
            if (contrlObj.ControlName !== 'Date' && contrlObj.ControlName !== 'Radio' && contrlObj.ControlName !== 'Checkbox' &&
                contrlObj.ControlName !== 'Signature' && contrlObj.ControlName !== 'NewInitials' && contrlObj.ControlName !== 'DropDown') {
                this.checkPinchZoomIsApplicable(contrlObj.ControlHtmlID);

                setTimeout(() => {
                    if (contrlObj.ControlName !== 'Signature' && contrlObj.ControlName !== 'NewInitials' &&
                        contrlObj.ControlName !== 'Radio' && contrlObj.ControlName !== 'Checkbox' &&
                        contrlObj.ControlName !== 'DropDown' && contrlObj.ControlName !== 'Date') {
                        let controlStringIdVal = document.getElementById('' + contrlObj.ControlHtmlID)!;
                        controlStringIdVal.removeAttribute('readOnly');
                        setTimeout(() => {
                            this.checkisLastControl(contrlObj.TabIndex);
                            this.responsiveOpenModelPopup(contrlObj.ControlHtmlID);
                        }, 300);
                        this.isDateSelected = 'false';
                    } else {
                        this.showMobilePopup = 'none';
                        this.isDateSelected = 'false';
                    }
                }, 500);
            } else {
                //user clicked from side filter section
                if (selectedFromFilter === 'selectedFromFilter') {
                    this.checkPinchZoomIsApplicable(contrlObj.ControlHtmlID);
                    if (contrlObj.ControlName === 'Signature') {
                        setTimeout(() => {
                            this.onClickSignature(contrlObj.ControlHtmlID);
                        }, 500);
                    } else if (contrlObj.ControlName === 'NewInitials') {
                        this.checkPinchZoomIsApplicable(contrlObj.ControlHtmlID);
                        setTimeout(() => {
                            this.onClickInitials(contrlObj.ControlHtmlID);
                        }, 500);
                    } else if (contrlObj.ControlName === 'Radio' || contrlObj.ControlName === 'Checkbox' ||
                        contrlObj.ControlName === 'DropDown' || contrlObj.ControlName === 'Date') {
                        this.checkPinchZoomIsApplicable(contrlObj.ControlHtmlID);
                    }
                }
            }
            this.cdRef.detectChanges();
        }, 200); // Adjust the timeout value as per your preference
    }

    removeBodyTagPositionFixed() {
        //for mobile, if user toggle the filter section and other actions
        let bodyTag: any = document.getElementsByTagName('body');
        if (bodyTag && bodyTag[0]) {
            bodyTag[0].style.position = '';
        }
    }

    updateMobileDatePickerValues(dateVal: any, dialogControlId: any) {
        let today: any = new Date();
        if (dateVal != '') {
            today = new Date(dateVal);
        }
        let currentDateVal: any = {
            year: today.getFullYear(),
            month: today.getMonth() + 1, // Note: Months are zero-indexed, so add 1
            day: today.getDate(),
        };
        if (dialogControlId.getAttribute('data-ctntype') === 'Date') {
            if (this.dateControl && this.dateControl._model) {
                this.dateControl._model.day = currentDateVal.day;
                this.dateControl._model.month = currentDateVal.month;
                this.dateControl._model.year = currentDateVal.year;
            }
        } else {
            if (this.dateTextDate && this.dateTextDate._model) {
                this.dateTextDate._model.day = currentDateVal.day;
                this.dateTextDate._model.month = currentDateVal.month;
                this.dateTextDate._model.year = currentDateVal.year;
            }
        }
        this.cdRef.detectChanges();
    }

    closeSendPasswordPopupModal() {
        this.sendPasswordPopupStyle = "none";
    }

    enableSubmitButton(event: any) {
        this.verificationCode = event.target.value;
        let btnViewandSignDocument: any = document.getElementById('btnViewandSignDocument');

        if (!this.commonService.isNumberExists(this.verificationCode)) {
            event.target.value = '';
            if (this.verificationCode.length > 0) {
                this.toastr.warning('Please enter only a number.', 'Warning', { timeOut: 1000 });
            }
        }
        else if (this.verificationCode.length == 6) {
            btnViewandSignDocument?.removeAttribute('disabled');
        }
        else {
            btnViewandSignDocument?.setAttribute('disabled', 'disabled');
        }
    }

    getCodeAndValidateSigner() {
        if (this.verificationCode != "" || this.verificationCode != null) {
            this.showLoader = true;

            let envelopeID = localStorage.getItem('EnvelopeID');
            let recipientID = localStorage.getItem('RecipientID');

            let validateFinalSubmitOTP = this.commonConstants.ValidateFinalSubmitOTP + "/" + envelopeID + "/" + recipientID + "/" + this.verificationCode;
            this.signerLandingService.getcall(validateFinalSubmitOTP).subscribe(
                (resp: any) => {
                    this.showLoader = false;
                    if (resp && resp.StatusCode == 200 && resp.StatusMessage.toLowerCase() == "ok") {
                        if (this.checkDocumentControlsResponselocal()) {
                            this.commonService.checkIsUrlExistsInBrowserMemory();
                            return;
                        }
                        else {
                            this.sendPasswordPopupStyle = 'none';
                            let envelopeSignDocumentSubmitInfo: any = this.prepareSubmitEnvelopeObject();
                            this.callFinalSubmitEnvelopeDocument(envelopeSignDocumentSubmitInfo);
                        }
                    }
                    else if (resp && resp.StatusMessage && resp.StatusMessage.toLowerCase() == "failed") {
                        this.showLoader = false;
                        this.toastr.warning("Please enter valid code.", 'Warning', { timeOut: 1000 });
                        this.cdRef.detectChanges();
                    }
                    else {
                        this.showLoader = false;
                        this.toastr.warning("Please enter valid code.", 'Warning', { timeOut: 1000 });
                        this.cdRef.detectChanges();
                    }
                });
        }
        else {
            this.showLoader = false;
            this.toastr.warning("Please enter valid code.", 'Warning', { timeOut: 1000 });
            this.cdRef.detectChanges();
        }
    }

    addBodyTagPositionFixed() {
        let bodyTag: any = document.getElementsByTagName('body');
        if (bodyTag && bodyTag[0]) {
            bodyTag[0].style.position = 'fixed';
        }
    }

    handleIdleTime() {
        if (this.isDocumentSubmitted == "false") {
            let sessionInActivity: any = environment.sessionInActivity;
            if (this.router && this.router.routerState && this.router.routerState.snapshot &&
                this.router.routerState.snapshot.url && this.router.routerState.snapshot.url === '/' + RouteUrls.SignDocument) {
                // this.sessionTimer();

                this.timerWorker.postMessage({ action: 'startTimer', value: (this.idleMaxTimerCount + sessionInActivity) });
                // Listen for messages from the Web Worker
                if (this.timerWorker) {
                    this.timerWorker.onmessage = (e: any) => {
                        // console.log('IsDocumentSubmitted:', this.isDocumentSubmitted + " and "+ e.data.value);
                        if (this.isDocumentSubmitted == "false") {
                            this.timer = e.data.value;
                            // console.log('11111111', this.timer);
                            if (this.timer && this.isDocumentSubmitted == "false") {
                                if (this.timer === -1) {
                                    this.showSessionExpireModalDailog = 'none';
                                    this.removeBodyTagPositionFixed();
                                    this.modalService.dismissAll(this.termsOfService);
                                    this.clickToSignPopupStyle = 'none';
                                    this.timerWorker.postMessage({ action: 'stopTimer' });
                                    this.finishLater('sessionExpired');
                                    this.sessionTimeoutVal = '';
                                    this.cdRef.detectChanges();
                                } else if (this.timer <= this.popupShowCounter) {
                                    this.showSessionExpireModalDailog = 'block';
                                    this.cdRef.detectChanges();
                                }
                                if (this.timer < 0) {
                                    this.showSessionExpireModalDailog = 'none';
                                } else if (this.timer >= this.idleMaxTimerCount) {
                                    this.sessionTimeoutVal = (Math.floor(this.idleMaxTimerCount / 60)).toString() + ' M';
                                } else {
                                    this.showDefaultSessionTimeOut = true;
                                    this.idleTimerMinsValue = (Math.floor(this.timer / 60)).toString();
                                    this.idleTimerSecondsValue = this.addZero(Math.floor((this.timer % 60))).toString();
                                    this.sessionTimeoutVal = (Math.floor(this.timer / 60)).toString() + ':' + this.addZero(Math.floor((this.timer % 60))).toString() + " M";
                                    this.cdRef.detectChanges();
                                }
                            }
                        }
                    };
                }
            }
        }
    }

    //reset Timer
    onContinue() {
        this.showSessionExpireModalDailog = 'none';
        this.removeBodyTagPositionFixed();
        // clearTimeout(this.idleTimer);
        this.idleTimerMinsValue = (Math.floor(this.idleMaxTimerCount / 60)).toString();
        this.idleTimerSecondsValue = '00';
        this.sessionTimeReset();
        this.resumeTimer();
        this.cdRef.detectChanges();
    }

    onSaveAndFinishLater(sessionKeepOut: any) {
        this.showSessionExpireModalDailog = 'none';
        this.removeBodyTagPositionFixed();
        // clearTimeout(this.idleTimer);
        this.sessionTimeReset();
        this.modalService.dismissAll(this.termsOfService);
        this.clickToSignPopupStyle = 'none';
        this.timerWorker.postMessage({ action: 'stopTimer' });
        this.finishLater('onUserSelectedFinishLater');
        this.sessionTimeoutVal = '';
    }

    sessionTimer() {
        this.idleCounter--;
        if (this.idleCounter === -1) {
            this.showSessionExpireModalDailog = 'none';
            this.removeBodyTagPositionFixed();
            this.modalService.dismissAll(this.termsOfService);
            this.clickToSignPopupStyle = 'none';
            this.finishLater('sessionExpired');
            this.sessionTimeoutVal = '';
        } else if (this.idleCounter <= this.popupShowCounter) {
            this.showSessionExpireModalDailog = 'block';
        }
        if (this.idleCounter < 0) {
            clearTimeout(this.idleTimer);
        } else {
            this.showDefaultSessionTimeOut = true;
            this.idleTimerMinsValue = (Math.floor(this.idleCounter / 60)).toString();
            this.idleTimerSecondsValue = this.addZero(Math.floor((this.idleCounter % 60))).toString();
            if (this.showSessionExpireModalDailog === 'none') {
                this.sessionTimeoutVal = (Math.floor(this.idleCounter / 60)).toString() + ':' + this.addZero(Math.floor((this.idleCounter % 60))).toString() + " M";
            } else {
                this.sessionTimeoutVal = '';
            }
            this.idleTimer = setTimeout(() => { this.sessionTimer() }, 1000);
        }
        this.cdRef.detectChanges();
    }

    addZero(i: any) {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    }

    sessionTimeReset() {
        this.showDefaultSessionTimeOut = false;
        this.sessionTimeoutVal = '';
        this.sessionTimeoutVal = (Math.floor(this.idleMaxTimerCount / 60)).toString() + ' M';
    }

    resetSessionTimer() {
        if (this.showSessionExpireModalDailog === 'none') {
            if (this.showDefaultSessionTimeOut === true) {
                //reset Timer
                this.onContinue();
            } else {
                this.idleTimerMinsValue = (Math.floor(this.idleMaxTimerCount / 60)).toString();
                this.idleTimerSecondsValue = this.addZero(Math.floor((this.idleMaxTimerCount % 60))).toString();
                this.sessionTimeoutVal = (Math.floor(this.idleMaxTimerCount / 60)).toString() + ' M';
                this.resumeTimer();
                this.cdRef.detectChanges();
            }
        }
        this.cdRef.detectChanges();
    }

    resumeTimer() {
        // Send a message to the Web Worker to reset the timer
        this.timerWorker.postMessage({ action: 'resetTimer' });
        // Start the timer again
        this.handleIdleTime();
        // this.timerWorker.postMessage({ action: 'startTimer' });
    }

    getAllControlPositionDetail() {
       // this.controlPositionDetailsArr = [];

        //call RetriveAllDocumentControlData for getting other signer's controls
        let IsFixedWidthControlExists: any = this.documentControlsResponse.EnvelopeInfo.IsFixedWidthControlExists;
        if (IsFixedWidthControlExists == false) {
            let envelopeID = localStorage.getItem('EnvelopeID');
            let recipientID = localStorage.getItem('RecipientID');
            let url: any = this.commonConstants.RetriveAllDocumentControlData + "/" + envelopeID + "/" + recipientID;

            this.signerLandingService.getcall(url).subscribe(
                (resp: any) => {
                    this.showLoader = false;
                    if (resp && resp.StatusCode == 200 && resp.StatusMessage.toLowerCase() == "ok") {
                        if (resp.AllDocumentControls && resp.AllDocumentControls.length > 0) {
                            resp.AllDocumentControls.forEach((ctrlObj: any) => {
                                this.calculateTopLeftForAllControls(ctrlObj);
                            });
                        }
                    }
            });
        }
    }

    getImagePageHeight(imageUrl: any, myTextArea: any, currentCtrDetailsStyleTop: any, myTextHeight: any, textAreaDefaultHeight: any) {
        const img = new Image();
        img.onload = function () {
            //console.log('img.height',img.height);
            let imagePageHeight: any = img.height - 48;
            if ((parseFloat(currentCtrDetailsStyleTop) + myTextArea.scrollHeight) >= (imagePageHeight - 10)) {
                myTextArea.style.maxHeight = (imagePageHeight - 15) - (parseFloat(currentCtrDetailsStyleTop)) + 'px';
            }
            if ((parseFloat(currentCtrDetailsStyleTop) + myTextHeight) <= (imagePageHeight - 10)) {
                myTextArea.style.height = textAreaDefaultHeight;
                if (myTextArea.scrollHeight > textAreaDefaultHeight.split('px')[0]) {
                    myTextArea.style.height = (myTextArea.scrollHeight) + 'px';
                }
            }
        };
        img.src = imageUrl;
    }

    getTopPosition(ctrlId: any, docPage: any, currentCtrDetailsStyleLeft: any, currentCtrDetailsStyleTop: any, myTextAreaVal: any) {
        let topPositionsArr: any = [];
        let myTextArea: any = document.getElementById('control_' + ctrlId);
        let myTextAreaParent: any = document.getElementById(ctrlId);
        let textAreaDefaultHeight: any = myTextAreaParent.style.height;
        let myTextHeight: any = parseInt(window.getComputedStyle(myTextArea).height);
        let myTextWidth: any = parseInt(window.getComputedStyle(myTextArea).width);

        for (var j = 0; j < this.controlPositionDetailsArr.length; j++) {
            if (this.controlPositionDetailsArr[j].docPage === docPage) {
                let ele: any = document.getElementById(this.controlPositionDetailsArr[j].controlId);
                let height: any = 0;
                let width: any = 0;
                if (ele) {
                    if (ele && ele.style && (ele.style.width === 'auto' || ele.style.width === '')) {
                        width = this.controlPositionDetailsArr[j].width;
                    } else if (ele && ele.style && (ele.style.width === '100%')) {
                        let parentEle: any = document.getElementById(this.controlPositionDetailsArr[j].controlId.replace('control_', ''));
                        if (parentEle && parentEle.style && parentEle.style.width) {
                            width = parseFloat(parentEle.style.width.split('px')[0]);
                        } else {
                            width = this.controlPositionDetailsArr[j].width;
                        }
                    } else {
                        width = parseFloat(ele.style.width.split('px')[0]);
                    }

                    if (ele.style.height === '100%' || ele.style.height === 'auto' || ele.style.height === '') {
                        height = this.controlPositionDetailsArr[j].height;
                    } else {
                        height = parseFloat(ele.style.height.split('px')[0]);
                    }
                }
                else if (ele && ele.style && (ele.style.height === 'auto' || ele.style.height === '')) {
                    let parentEle: any = document.getElementById(this.controlPositionDetailsArr[j].controlId.replace('control_', ''));
                    if (parentEle && parentEle.style && parentEle.style.height) {
                        height = parseFloat(parentEle.style.height.split('px')[0]);
                    } else {
                        height = this.controlPositionDetailsArr[j].height;
                    }
                }
                else if (ele && ele.style && (ele.style.height === '100%')) {
                    let parentEle: any = document.getElementById(this.controlPositionDetailsArr[j].controlId.replace('control_', ''));
                    if (parentEle && parentEle.style && parentEle.style.height) {
                        height = parseFloat(parentEle.style.height.split('px')[0]);
                    } else {
                        height = this.controlPositionDetailsArr[j].height;
                    }
                } else {
                    width = this.controlPositionDetailsArr[j].width;
                    height = this.controlPositionDetailsArr[j].height;
                }
                if (this.controlPositionDetailsArr[j].controlName === 'Signature' || this.controlPositionDetailsArr[j].controlName === 'NewInitials') {
                    height = this.controlPositionDetailsArr[j].height;
                }
                if (currentCtrDetailsStyleLeft < (this.controlPositionDetailsArr[j].left + 1) + (width - 4) &&
                    (currentCtrDetailsStyleLeft + myTextWidth) > this.controlPositionDetailsArr[j].left) {
                    if (parseFloat(currentCtrDetailsStyleTop) !== this.controlPositionDetailsArr[j].top) {
                        if (((this.controlPositionDetailsArr[j].top + (height - 4))) > parseFloat(currentCtrDetailsStyleTop)) {
                            //console.log('topArr',this.controlPositionDetailsArr[j]);
                            topPositionsArr.push(this.controlPositionDetailsArr[j]);
                        }
                    }
                }

            }
        }

        // console.log(' mobile topPositionsArr----->>>>', topPositionsArr);
        if (topPositionsArr && topPositionsArr.length > 0) {
            this.mobileUpdateControlAutoHeight(ctrlId, currentCtrDetailsStyleTop, topPositionsArr, myTextAreaVal);
        } else {
            let imagePage: any = document.querySelector("#imgControl_" + docPage)!;
            let imagePageHeight: any;
            // imagePageHeight = 1186;
            let imgId: any = document.getElementById(imagePage.id);
            let imageUrl: any = imgId && imgId.children[0] && imgId.children[0].currentSrc;
            this.getImagePageHeight(imageUrl, myTextArea, currentCtrDetailsStyleTop, myTextHeight, textAreaDefaultHeight);
        }
    }

    getLeftPosition(ctrlId: any, docPage: any, currentCtrDetailsStyleLeft: any, currentCtrDetailsStyleTop: any) {
        let leftPositionsArr: any = [];
        let myTextArea: any = document.getElementById('control_' + ctrlId);
        let myTextHeight: any = parseInt(window.getComputedStyle(myTextArea).height);

        for (var j = 0; j < this.controlPositionDetailsArr.length; j++) {
            if (this.controlPositionDetailsArr[j].docPage === docPage) {
                let ele: any = document.getElementById(this.controlPositionDetailsArr[j].controlId);
                let height: any = 0;
                let width: any = 0;
                if (ele) {
                    if (ele && ele.style && (ele.style.width === 'auto' || ele.style.width === '')) {
                        width = this.controlPositionDetailsArr[j].width;
                    } else if (ele && ele.style && (ele.style.width === '100%')) {
                        let parentEle: any = document.getElementById(this.controlPositionDetailsArr[j].controlId.replace('control_', ''));
                        if (parentEle && parentEle.style && parentEle.style.width) {
                            width = parseFloat(parentEle.style.width.split('px')[0]);
                        } else {
                            width = this.controlPositionDetailsArr[j].width;
                        }
                    } else {
                        width = parseFloat(ele.style.width.split('px')[0]);
                    }

                    if (ele.style.height === '100%' || ele.style.height === 'auto' || ele.style.height === '') {
                        height = this.controlPositionDetailsArr[j].height;
                    } else {
                        height = parseFloat(ele.style.height.split('px')[0]);
                    }
                }
                else if (ele && ele.style && (ele.style.height === 'auto' || ele.style.height === '')) {
                    let parentEle: any = document.getElementById(this.controlPositionDetailsArr[j].controlId.replace('control_', ''));
                    if (parentEle && parentEle.style && parentEle.style.height) {
                        height = parseFloat(parentEle.style.height.split('px')[0]);
                    } else {
                        height = this.controlPositionDetailsArr[j].height;
                    }
                }
                else if (ele && ele.style && ele.style.height === '100%') {
                    let parentEle: any = document.getElementById(this.controlPositionDetailsArr[j].controlId.replace('control_', ''));
                    if (parentEle && parentEle.style && parentEle.style.height) {
                        height = parseFloat(parentEle.style.height.split('px')[0]);
                    } else {
                        height = this.controlPositionDetailsArr[j].height;
                    }
                } else {
                    width = this.controlPositionDetailsArr[j].width;
                    height = this.controlPositionDetailsArr[j].height;
                }
                if (this.controlPositionDetailsArr[j].controlName === 'Signature' || this.controlPositionDetailsArr[j].controlName === 'NewInitials') {
                    height = this.controlPositionDetailsArr[j].height;
                }

                if (currentCtrDetailsStyleTop < (this.controlPositionDetailsArr[j].top + (height - 4)) &&
                    (currentCtrDetailsStyleTop + myTextHeight) > this.controlPositionDetailsArr[j].top) {
                    if ((parseFloat(currentCtrDetailsStyleLeft) !== this.controlPositionDetailsArr[j].left &&
                        parseFloat(currentCtrDetailsStyleTop) !== this.controlPositionDetailsArr[j].top) || ((parseFloat(currentCtrDetailsStyleLeft) !== this.controlPositionDetailsArr[j].left) && parseFloat(currentCtrDetailsStyleTop) === this.controlPositionDetailsArr[j].top)) {
                        if (this.controlPositionDetailsArr[j].left + width > parseFloat(currentCtrDetailsStyleLeft)) {
                            //console.log('leftArr',this.controlPositionDetailsArr[j]);
                            leftPositionsArr.push(this.controlPositionDetailsArr[j]);
                        }
                    }
                }
            }
        }

        console.log('mobile leftPositionsArr----->>>>', leftPositionsArr);
        this.mobileUpdateTextWidth(ctrlId, leftPositionsArr);
    }

    updateExpandControlIfApplicable(ctrlId: any, text: any) {
        let myTextArea: any = document.getElementById('control_' + ctrlId);
        let myTextAreaParent: any = document.getElementById(ctrlId);
        let popupTextValue: any = myTextArea.value;
        let myTextLength: any = myTextArea.value.length;
        let myTextMinLength: any = myTextArea.getAttribute('data-maxlength');
        let docPage: any = parseInt(myTextAreaParent.getAttribute('data-page'));
        let imagePage: any = document.querySelector("#imgControl_" + docPage)!;
        let imagePageWidth: any = imagePage.style.width.split('px')[0];
        let isFixedWidth: any = null;
        if (myTextArea.getAttribute('data-isfixed-width') && myTextArea.getAttribute('data-isfixed-width')) {
            isFixedWidth = myTextArea.getAttribute('data-isfixed-width');
        }

        let currentCtrDetails: any = document.getElementById(ctrlId);
        let currentCtrDetailsStyleLeft: any = parseFloat(currentCtrDetails.style.left.split('px')[0]);
        let currentCtrDetailsStyleTop: any = parseFloat(currentCtrDetails.style.top.split('px')[0]);

        if (text === '' || (myTextLength <= myTextMinLength)) {
            if (isFixedWidth !== true || isFixedWidth !== 'true') {
                this.mobileResetControlAutoHeightAndWidth(ctrlId);
            }
        }

        if (isFixedWidth !== true || isFixedWidth !== 'true') {
            this.getLeftPosition(ctrlId, docPage, currentCtrDetailsStyleLeft, currentCtrDetailsStyleTop);
            this.getTopPosition(ctrlId, docPage, currentCtrDetailsStyleLeft, currentCtrDetailsStyleTop, popupTextValue);

            let parentHeight: any;
            if (this.documentControlsResponse && this.documentControlsResponse.EnvelopeInfo && this.documentControlsResponse.EnvelopeInfo.EnvelopeImageCollection) {
                parentHeight = imagePage.clientHeight;//this.documentControlsResponse.EnvelopeInfo.EnvelopeImageCollection[docPage - 1].Dimension.Height - 48;
            }
            let outerHeight: any = myTextArea.style.height.split('px')[0];
            let controlBottom: any = parseInt(parentHeight) - (currentCtrDetails.offsetTop + parseInt(outerHeight));

            let image: any = document.querySelector("#imgControl_" + docPage)!;

            const img = new Image();
            let imgId: any = document.getElementById(image.id);
            let imageUrl: any = imgId && imgId.children[0] && imgId.children[0].currentSrc;
            img.onload = function () {
                let originalWidth: any = img.naturalWidth;
                let originalHeight: any = img.naturalHeight;
                let imageWidth: any = parseInt(imagePageWidth);
                let imageHeight: any = img.naturalHeight - 48;
                let ratioWidth: any = 1 / (imageWidth / originalWidth);
                let ratioHeight: any = 1 / (imageHeight / originalHeight);
                let ZCoordinate: any;
                if ((controlBottom * ratioHeight) <= 0) {
                    ZCoordinate = 1;
                } else {
                    ZCoordinate = (controlBottom * ratioHeight);
                }
                // console.log('ZCoordinate',ZCoordinate );
                myTextArea.setAttribute('data-ZCoordinate', ZCoordinate);

            };
            img.src = imageUrl;
        }

    }

    checkAutoExpand(event: any, ctrl: any) {
        //console.log('checkAutoExpand', event)
        if (event && (event.which !== 37 || event.key !== 'ArrowRight') && (event.which !== 38 || event.key !== 'ArrowUp') &&
            (event.which !== 39 || event.key !== 'ArrowLeft') && (event.which !== 40 || event.key !== 'ArrowDown')) {
            if (ctrl.ControlName === 'Name' || ctrl.ControlName === 'Title' || (ctrl.ControlName === 'Text' && (ctrl.TextType === 'Text' || ctrl.TextType === 'Alphabet' ||
                ctrl.TextType === 'Numeric' || ctrl.TextType === 'Email'))) {
                if (event && (event.which === 8 || event.key === 'Backspace')) {
                    this.resetControlAutoHeightAndWidth(ctrl);
                } else {
                    let myTextArea: any = document.getElementById('control_' + ctrl.ControlHtmlID);
                    let myTextAreaParent: any = document.getElementById(ctrl.ControlHtmlID);
                    let textAreaDefaultHeight: any = myTextAreaParent.style.height;
                    let textAreaDefaultWidth: any = myTextAreaParent.style.width;
                    let myTextWidth: any = parseInt(window.getComputedStyle(myTextArea).width);
                    let myTextHeight: any = parseInt(window.getComputedStyle(myTextArea).height);
                    let currentCtrDetails: any = document.getElementById(ctrl.ControlHtmlID);
                    let currentCtrDetailsStyleLeft: any = parseFloat(currentCtrDetails.style.left.split('px')[0]);
                    let currentCtrDetailsStyleTop: any = parseFloat(currentCtrDetails.style.top.split('px')[0]);
                    let docPage: any = parseInt(myTextAreaParent.getAttribute('data-page'));

                    let topPositionsArr: any = this.getWebTopPositions(docPage, currentCtrDetailsStyleLeft, currentCtrDetailsStyleTop, myTextWidth);
                    let leftPositionsArr: any = this.getWebLeftPositions(docPage, currentCtrDetailsStyleLeft, currentCtrDetailsStyleTop, myTextHeight);

                    // console.log('topPositionsArr----->>>>', topPositionsArr);
                    // console.log('leftPositionsArr----->>>>', leftPositionsArr);

                    //validating after paste the text ot number string
                    if (!this.isSingleKeyPress(event)) {
                        this.isNumberKey(event, ctrl.ControlHtmlID);
                    }

                    if (ctrl.IsFixedWidth === false) {
                        this.updateTextWidth(ctrl, leftPositionsArr, event);

                        if (topPositionsArr && topPositionsArr.length > 0) {
                            this.updateControlAutoHeight(ctrl, currentCtrDetailsStyleTop, topPositionsArr, event);
                        } else {
                            this.expandableCtrlNextTopValue = 0;
                            let imagePage: any = document.querySelector("#imgControl_" + ctrl.PageNo)!;
                            let imagePageHeight: any = imagePage.offsetHeight;
                            if ((parseFloat(currentCtrDetailsStyleTop) + myTextArea.scrollHeight) >= (imagePageHeight - 10)) {
                                if (!this.isSingleKeyPress(event)) {
                                    // myTextArea.value = '';
                                    // myTextArea.style.height = textAreaDefaultHeight;
                                    // myTextArea.style.width = textAreaDefaultWidth;
                                    this.updateCopyAndPasteText(myTextArea, textAreaDefaultHeight, currentCtrDetailsStyleTop, (imagePageHeight - 10));
                                } else {
                                    myTextArea.style.height = (imagePageHeight - 15) - (parseFloat(currentCtrDetailsStyleTop)) + 'px';
                                    myTextArea.value = myTextArea.value.substring(0, myTextArea.value.length - 1);
                                }
                            } else {
                                if ((parseFloat(currentCtrDetailsStyleTop) + myTextHeight) <= (imagePageHeight - 10)) {
                                    myTextArea.style.height = textAreaDefaultHeight;
                                    if (myTextArea.scrollHeight > textAreaDefaultHeight.split('px')[0]) {
                                        myTextArea.style.height = (myTextArea.scrollHeight) + 'px';
                                    }
                                }
                            }
                        }

                        let currentImageHeight: any = document.getElementById('imgControl_' + ctrl.PageNo)!
                        let parentHeight: any;
                        if (this.documentControlsResponse && this.documentControlsResponse.EnvelopeInfo && this.documentControlsResponse.EnvelopeInfo.EnvelopeImageCollection) {
                            parentHeight = currentImageHeight.clientHeight; //this.documentControlsResponse.EnvelopeInfo.EnvelopeImageCollection[ctrl.PageNo - 1].Dimension.Height - 48;
                        }
                        let outerHeight: any = myTextArea.style.height.split('px')[0];
                        let controlBottom: any = parseInt(parentHeight) - (currentCtrDetails.offsetTop + parseInt(outerHeight));

                        let image: any = document.querySelector("#imgControl_" + ctrl.PageNo)!;

                        const img = new Image();
                        let imgId: any = document.getElementById(image.id);
                        let imageUrl: any = imgId && imgId.children[0] && imgId.children[0].currentSrc;
                        img.onload = function () {
                            let originalWidth: any = img.naturalWidth;
                            let originalHeight: any = img.naturalHeight;
                            let imageWidth: any = currentImageHeight.children[0].width - 2;
                            let imageHeight: any = currentImageHeight.children[0].height - 3;
                            let ratioWidth: any = 1 / (imageWidth / originalWidth);
                            let ratioHeight: any = 1 / (imageHeight / originalHeight);
                            let ZCoordinate: any;
                            if ((controlBottom * ratioHeight) <= 0) {
                                ZCoordinate = 1;
                            } else {
                                ZCoordinate = (controlBottom * ratioHeight);
                            }
                            //console.log('ZCoordinate',ZCoordinate );
                            myTextArea.setAttribute('data-ZCoordinate', ZCoordinate);
                        };
                        img.src = imageUrl;
                    }
                }
            }
        }
    }

    updateControlAutoHeight(ctrl: any, currentCtrDetailsStyleTop: any, topPositions: any, event: any) {
        let myTextArea: any = document.getElementById('control_' + ctrl.ControlHtmlID);
        let myTextAreaParent: any = document.getElementById(ctrl.ControlHtmlID);
        let textAreaDefaultHeight: any = myTextAreaParent.style.height;
        let textAreaDefaultWidth: any = myTextAreaParent.style.width;
        let myTextHeight: any = parseInt(window.getComputedStyle(myTextArea).height);

        let nextTopValue: any;
        if (topPositions.length > 0) {
            topPositions = topPositions.sort(function (a: any, b: any) {
                return a.top - b.top;
            });
            nextTopValue = topPositions[0].top;
        }
        this.expandableCtrlNextTopValue = nextTopValue;
        if (myTextArea.value.length === 0 || myTextArea.value === '') {
            myTextArea.style.height = textAreaDefaultHeight;
        }
        if (myTextArea.value.length && (parseFloat(currentCtrDetailsStyleTop) + myTextArea.scrollHeight) >= (nextTopValue)) {
            if (!this.isSingleKeyPress(event)) {
                // myTextArea.value = '';
                // myTextArea.style.height = textAreaDefaultHeight;
                // myTextArea.style.width = textAreaDefaultWidth;

                this.updateCopyAndPasteText(myTextArea, textAreaDefaultHeight, currentCtrDetailsStyleTop, nextTopValue);
            } else {
                let getDataTextVal: any = myTextArea.getAttribute('data-textVal');
                if (getDataTextVal) {
                    myTextArea.value = myTextArea.getAttribute('data-textVal');
                } else {
                    myTextArea.value = myTextArea.value;
                }

            }
        } else {
            if (myTextArea.scrollHeight > textAreaDefaultHeight.split('px')[0]) {
                if (parseFloat(currentCtrDetailsStyleTop) + myTextHeight < nextTopValue - 4) {
                    myTextArea.style.height = (myTextArea.scrollHeight) + 'px';
                }
            }
            myTextArea.setAttribute('data-textVal', myTextArea.value);
        }
    }

    updateTextWidth(ctrlObj: any, leftPositionsArr: any, event: any) {
        let myTextArea: any = document.getElementById('control_' + ctrlObj.ControlHtmlID);
        let myTextAreaParent: any = document.getElementById(ctrlObj.ControlHtmlID);
        let textAreaDefaultWidth: any = myTextAreaParent.style.width;
        let myTextWidth: any = parseInt(window.getComputedStyle(myTextArea).width);
        let myTextLength: any = myTextArea.value.length;
        let myTextMinLength: any = parseInt(myTextArea.getAttribute('data-maxlength')); //20;
        let currentCtrDetails: any = document.getElementById(ctrlObj.ControlHtmlID);
        let currentCtrDetailsStyleLeft: any = currentCtrDetails.style.left.split('px')[0];
        let imagePage: any = document.querySelector("#imgControl_" + ctrlObj.PageNo)!;
        let imagePageWidth: any = imagePage.offsetWidth;
        let nearestLeftValue: any;
        let nearestLeftMinusValue: any = 10;
        let imagePageWidthMinusValue: any = 15;
        let maxCharacterLimit: any = myTextArea.getAttribute('maxlength');
        if (maxCharacterLimit != null && maxCharacterLimit != '' && maxCharacterLimit != undefined) {
            maxCharacterLimit = parseInt(myTextArea.getAttribute('maxlength'));
            if (isNaN(parseInt(maxCharacterLimit))) {
                maxCharacterLimit = null;
            }
        }
        if (leftPositionsArr.length > 0) {
            leftPositionsArr = leftPositionsArr.sort(function (a: any, b: any) {
                return a.left - b.left;
            });
            nearestLeftValue = leftPositionsArr[0].left;
        }

        if (!this.isSingleKeyPress(event)) {
            if (myTextLength === 0 || myTextLength < myTextMinLength) {
                myTextWidth = textAreaDefaultWidth.split('px')[0];
            }
            if (myTextLength > myTextMinLength && leftPositionsArr.length > 0) {
                if ((parseFloat(currentCtrDetailsStyleLeft) + myTextWidth) <= (nearestLeftValue - nearestLeftMinusValue)) {
                    if (myTextArea && myTextArea.value && myTextLength > myTextMinLength) {
                        let widthArr: any = (myTextLength - myTextMinLength);
                        myTextWidth = parseInt(textAreaDefaultWidth.replace('px', ''));
                        for (var j = 0; j < widthArr; j++) {
                            if ((parseFloat(currentCtrDetailsStyleLeft) + myTextWidth) <= (nearestLeftValue - nearestLeftMinusValue)) {
                                myTextWidth += 8;
                            }
                        }
                    }
                }
            } else if (leftPositionsArr.length === 0 && (myTextLength > myTextMinLength) && (parseFloat(currentCtrDetailsStyleLeft) + myTextWidth) <= (imagePageWidth - imagePageWidthMinusValue)) {
                if (myTextArea && myTextArea.value && myTextLength > myTextMinLength) {
                    let widthArr: any = (myTextLength - myTextMinLength);
                    myTextWidth = parseInt(textAreaDefaultWidth.replace('px', ''));
                    for (var j = 0; j < widthArr; j++) {
                        if ((parseFloat(currentCtrDetailsStyleLeft) + myTextWidth) <= (imagePageWidth - imagePageWidthMinusValue)) {
                            myTextWidth += 8;
                        }
                    }
                }
            }
        } else {
            if (myTextLength > myTextMinLength && leftPositionsArr.length > 0) {
                if ((parseFloat(currentCtrDetailsStyleLeft) + myTextWidth) <= (nearestLeftValue - nearestLeftMinusValue)) {
                    myTextWidth += 8;
                }
            } else if (maxCharacterLimit === null && leftPositionsArr.length === 0 && (myTextLength > myTextMinLength) && (parseFloat(currentCtrDetailsStyleLeft) + myTextWidth) <= (imagePageWidth - imagePageWidthMinusValue)) {
                myTextWidth += 8;
            } else if (leftPositionsArr.length === 0 && (myTextLength > myTextMinLength) && myTextLength <= maxCharacterLimit && (parseFloat(currentCtrDetailsStyleLeft) + myTextWidth) <= (imagePageWidth - imagePageWidthMinusValue)) {
                if (myTextLength != maxCharacterLimit) {
                    myTextWidth += 8;
                }
            }
        }

        myTextArea.style.width = myTextWidth + 'px';
    }

    mobileUpdateControlAutoHeight(ctrlId: any, currentCtrDetailsStyleTop: any, sortArrayFirstTopVal: any, myTextAreaVal: any) {
        let myTextArea: any = document.getElementById('control_' + ctrlId);
        let myTextAreaParent: any = document.getElementById(ctrlId);
        let textAreaDefaultHeight: any = myTextAreaParent.style.height;
        let textAreaDefaultWidth: any = myTextAreaParent.style.width;
        let myTextHeight: any = parseInt(window.getComputedStyle(myTextArea).height);

        setTimeout(() => {
            let nextTopValue: any;
            if (sortArrayFirstTopVal.length > 0) {
                sortArrayFirstTopVal = sortArrayFirstTopVal.sort(function (a: any, b: any) {
                    return a.top - b.top;
                });
                nextTopValue = sortArrayFirstTopVal[0].top;
            }

            if (myTextAreaVal.length === 0 || myTextAreaVal === '') {
                myTextArea.style.height = textAreaDefaultHeight;
                myTextArea.style.width = textAreaDefaultWidth;
            } else {
                //creating temporary textarea ele and adding same style properties for current control
                // using for loop to assign each character to temporary textarea and removing the temporary string once updating to current control
                let myTempStr: any = myTextAreaVal;
                let myTempStrArray: any = myTempStr.split('');
                let measurementTextAreaEle: any = document.createElement('textarea');
                measurementTextAreaEle.style.visibility = 'hidden';
                measurementTextAreaEle.style.width = myTextArea.style.width;
                measurementTextAreaEle.style.height = myTextArea.style.height;
                measurementTextAreaEle.style.position = 'absolute';
                measurementTextAreaEle.setAttribute('id', 'tempMeasurementCtrl');
                measurementTextAreaEle.style.overflow = myTextArea.style.overflow;
                measurementTextAreaEle.style.resize = myTextArea.style.resize;
                measurementTextAreaEle.style.fontSize = myTextArea.style.fontSize;
                measurementTextAreaEle.style.fontFamily = myTextArea.style.fontFamily;
                measurementTextAreaEle.style.fontWeight = myTextArea.style.fontWeight;
                measurementTextAreaEle.style.fontStyle = myTextArea.style.fontStyle;
                measurementTextAreaEle.style.textDecoration = myTextArea.style.textDecoration;
                measurementTextAreaEle.style.color = myTextArea.style.color;
                measurementTextAreaEle.style.padding = myTextArea.style.padding;
                measurementTextAreaEle.style.boxSizing = myTextArea.style.boxSizing;
                measurementTextAreaEle.style.border = myTextArea.style.border;
                measurementTextAreaEle.style.lineHeight = myTextArea.style.lineHeight;
                document.body.appendChild(measurementTextAreaEle);
                let measurementId: any = document.getElementById('tempMeasurementCtrl');
                if (myTextArea && (parseFloat(currentCtrDetailsStyleTop) + myTextArea.scrollHeight) >= (nextTopValue)) {
                    myTextArea.style.height = (nextTopValue - 1) - (parseFloat(currentCtrDetailsStyleTop)) + 'px';
                } else {
                    if (myTextArea && myTextArea.scrollHeight > textAreaDefaultHeight.split('px')[0]) {
                        if (parseFloat(currentCtrDetailsStyleTop) + myTextHeight < nextTopValue - 10) {
                            myTextArea.style.height = (myTextArea.scrollHeight) + 'px';
                        }
                    }
                }
                if (myTempStrArray && myTempStrArray.length && measurementId && myTextArea && myTextArea.style && myTextArea.style.height) {
                    for (var j = 0; j < myTempStrArray.length; j++) {
                        if ((measurementId.scrollHeight) <= myTextArea.style.height.split('px')[0]) {
                            measurementId.textContent += myTempStrArray[j];
                        } else {
                            if (measurementId && measurementId.textContent) {
                                measurementId.textContent = measurementId.textContent.substring(0, measurementId.textContent.length - 1);
                            } else {
                                measurementId.textContent = myTextAreaVal;
                            }
                            break;
                        }
                    }
                }
                myTextArea.value = measurementId.textContent;
                document.body.removeChild(measurementTextAreaEle);
            }
        }, 500);
    }

    mobileUpdateTextWidth(ctrlId: any, leftPositionsArr: any) {
        let myTextArea: any = document.getElementById('control_' + ctrlId);
        let myTextAreaParent: any = document.getElementById(ctrlId);
        let myTextWidth: any = parseInt(window.getComputedStyle(myTextArea).width);
        let textAreaDefaultWidth: any = myTextAreaParent.style.width;
        let myTextLength: any = myTextArea.value.length;
        let myTextMinLength: any = myTextArea.getAttribute('data-maxlength');
        let currentCtrDetails: any = document.getElementById(ctrlId);
        let currentCtrDetailsStyleLeft: any = currentCtrDetails.style.left.split('px')[0];
        let docPage: any = parseInt(myTextAreaParent.getAttribute('data-page'));
        let checkMaxLength: any = myTextArea.getAttribute('maxlength');
        let imagePage: any = document.querySelector("#imgControl_" + docPage)!;
        let imagePageWidth: any = imagePage.style.width.split('px')[0];
        let nearestLeftValue: any;
        let nearestLeftMinusValue: any = 12;
        let imagePageWidthMinusValue: any = 10;
        if (leftPositionsArr.length > 0) {
            leftPositionsArr = leftPositionsArr.sort(function (a: any, b: any) {
                return a.left - b.left;
            });
            nearestLeftValue = leftPositionsArr[0].left;
        }
        if (checkMaxLength === '' || checkMaxLength === null) {
            myTextWidth = 0;
            if (myTextLength > myTextMinLength && leftPositionsArr.length > 0) {
                if ((parseFloat(currentCtrDetailsStyleLeft) + myTextWidth) <= (nearestLeftValue - nearestLeftMinusValue)) {
                    if (myTextArea && myTextArea.value && myTextLength > myTextMinLength) {
                        let widthArr: any = myTextLength;
                        for (var j = 0; j < widthArr; j++) {
                            if ((parseFloat(currentCtrDetailsStyleLeft) + myTextWidth) <= (nearestLeftValue - nearestLeftMinusValue)) {
                                myTextWidth += 8;
                            }
                        }
                    }
                }
            } else if (leftPositionsArr.length === 0 && (myTextLength > myTextMinLength) && (parseFloat(currentCtrDetailsStyleLeft) + myTextWidth) <= (imagePageWidth - imagePageWidthMinusValue)) {
                if (myTextArea && myTextArea.value && myTextLength > myTextMinLength) {
                    let widthArr: any = myTextLength;
                    for (var j = 0; j < widthArr; j++) {
                        if ((parseFloat(currentCtrDetailsStyleLeft) + myTextWidth) <= (imagePageWidth - imagePageWidthMinusValue)) {
                            myTextWidth += 8;
                        }
                    }
                }
            } else {
                myTextWidth = textAreaDefaultWidth.split('px')[0];
            }
        }

        myTextArea.style.width = myTextWidth + 'px';
    }

    mobileResetControlAutoHeightAndWidth(ctrlId: any) {
        let myTextArea: any = document.getElementById('control_' + ctrlId);
        let myTextAreaParent: any = document.getElementById(ctrlId);
        let textAreaDefaultHeight: any = myTextAreaParent.style.height;
        let textAreaDefaultWidth: any = myTextAreaParent.style.width;
        let myTextMinLength: any = myTextArea.getAttribute('data-maxlength');//20;

        if (myTextArea.style.height !== 'auto') {
            myTextArea.style.height = textAreaDefaultHeight;
            if (myTextArea.scrollHeight > textAreaDefaultHeight.split('px')[0]) {
                myTextArea.style.height = (myTextArea.scrollHeight) + 'px';
            }
        }

        if (myTextArea && (myTextArea.value === '' || myTextArea.value.length === 0)) {
            myTextArea.style.width = textAreaDefaultWidth;
        } else if (myTextArea && myTextArea.value.length < parseInt(myTextMinLength)) {
            myTextArea.style.width = textAreaDefaultWidth;
            myTextArea.style.height = textAreaDefaultHeight;
        }
    }

    resetControlAutoHeightAndWidth(ctrl: any) {
        let myTextArea: any = document.getElementById('control_' + ctrl.ControlHtmlID);
        let myTextAreaParent: any = document.getElementById(ctrl.ControlHtmlID);
        let textAreaDefaultHeight: any = myTextAreaParent.style.height;
        let textAreaDefaultWidth: any = myTextAreaParent.style.width;
        let myTextMinLength: any = myTextArea.getAttribute('data-maxlength'); //20;

        if (myTextArea.style.height !== 'auto') {
            myTextArea.style.height = textAreaDefaultHeight;
            if (myTextArea.scrollHeight > textAreaDefaultHeight.split('px')[0]) {
                myTextArea.style.height = (myTextArea.scrollHeight) + 'px';
            }
        }

        if (myTextArea && (myTextArea.value === '' || myTextArea.value.length === 0)) {
            myTextArea.style.width = textAreaDefaultWidth;
        } else if (myTextArea && myTextArea.value.length < parseInt(myTextMinLength)) {
            myTextArea.style.width = textAreaDefaultWidth;
            myTextArea.style.height = textAreaDefaultHeight;
        }

        myTextArea.setAttribute('data-textVal', myTextArea.value);
    }

    isSingleKeyPress(event: any) {
        return (
            event.key.length === 1 && // A single character key
            !event.ctrlKey &&          // Control key isn't pressed
            !event.metaKey &&          // Command key isn't pressed (for Mac)
            !event.altKey              // Alt key isn't pressed
        );
    }

    updateCopyAndPasteText(myTextArea: any, textAreaDefaultHeight: any, currentCtrDetailsStyleTop: any, nextTopValue: any) {
        let charactersArr: any = myTextArea.value.split('');
        const dummyTextarea = document.createElement('textarea');
        dummyTextarea.value = '';
        dummyTextarea.classList.add('textsignerControl');
        document.body.appendChild(dummyTextarea);

        let scrollHeight: any = parseInt(textAreaDefaultHeight.split('px')[0]);
        for (let i = 0; i < charactersArr.length - 1; i++) {
            // Add a character
            dummyTextarea.style.width = myTextArea.style.width;
            dummyTextarea.style.height = myTextArea.style.height;
            dummyTextarea.style.fontFamily = myTextArea.style.fontFamily;
            dummyTextarea.style.fontSize = myTextArea.style.fontSize;
            dummyTextarea.style.fontWeight = myTextArea.style.fontWeight;
            dummyTextarea.style.fontStyle = myTextArea.style.fontStyle;
            dummyTextarea.style.overflow = myTextArea.style.overflow;
            dummyTextarea.style.textDecoration = myTextArea.style.textDecoration;
            dummyTextarea.style.boxSizing = myTextArea.style.boxSizing;
            dummyTextarea.style.resize = myTextArea.style.resize;
            dummyTextarea.style.lineHeight = myTextArea.style.lineHeight;
            dummyTextarea.style.visibility = 'hidden';
            dummyTextarea.style.border = myTextArea.style.border;
            dummyTextarea.style.padding = myTextArea.style.padding;
            dummyTextarea.style.outline = myTextArea.style.outline;

            if (dummyTextarea.value !== '') {
                scrollHeight = dummyTextarea.scrollHeight;
            }
            if ((parseFloat(currentCtrDetailsStyleTop) + scrollHeight) < nextTopValue) {
                dummyTextarea.value += charactersArr[i];
                myTextArea.setAttribute('data-textVal', dummyTextarea.value);
                myTextArea.value = dummyTextarea.value;
                myTextArea.style.height = (scrollHeight) + 'px';
            } else {
                let getDataTextVal: any = myTextArea.getAttribute('data-textVal');
                if (getDataTextVal) {
                    let str: any = myTextArea.getAttribute('data-textVal');
                    myTextArea.value = str.substring(0, str.length - 1);
                } else {
                    myTextArea.value = myTextArea.value;
                }
            }
        }

        document.body.removeChild(dummyTextarea);
    }

    getWebLeftPositions(docPage: any, currentCtrDetailsStyleLeft: any, currentCtrDetailsStyleTop: any, myTextHeight: any) {
        let leftTempPositionsArr: any = [];
        for (var j = 0; j < this.controlPositionDetailsArr.length; j++) {
            if (this.controlPositionDetailsArr[j].docPage === docPage) {
                let ele: any = document.getElementById(this.controlPositionDetailsArr[j].controlId);
                let height: any = 0;
                let width: any = 0;
                if (ele) {
                    if (ele && ele.style && (ele.style.width === 'auto' || ele.style.width === '')) {
                        width = this.controlPositionDetailsArr[j].width;
                    } else if (ele && ele.style && (ele.style.width === '100%')) {
                        let parentEle: any = document.getElementById(this.controlPositionDetailsArr[j].controlId.replace('control_', ''));
                        if (parentEle && parentEle.style && parentEle.style.width) {
                            width = parseFloat(parentEle.style.width.split('px')[0]);
                        } else {
                            width = this.controlPositionDetailsArr[j].width;
                        }
                    } else {
                        width = parseFloat(ele.style.width.split('px')[0]);
                    }
                    if (ele.style.height === '100%' || ele.style.height === 'auto' || ele.style.height === '') {
                        height = this.controlPositionDetailsArr[j].height;
                    } else {
                        height = parseFloat(ele.style.height.split('px')[0]);
                    }
                }
                else if (ele && ele.style && (ele.style.height === 'auto' || ele.style.height === '')) {
                    let parentEle: any = document.getElementById(this.controlPositionDetailsArr[j].controlId.replace('control_', ''));
                    if (parentEle && parentEle.style && parentEle.style.height) {
                        height = parseFloat(parentEle.style.height.split('px')[0]);
                    } else {
                        height = this.controlPositionDetailsArr[j].height;
                    }
                } else if (ele && ele.style && ele.style.height === '100%') {
                    let parentEle: any = document.getElementById(this.controlPositionDetailsArr[j].controlId.replace('control_', ''));
                    if (parentEle && parentEle.style && parentEle.style.height) {
                        height = parseFloat(parentEle.style.height.split('px')[0]);
                    } else {
                        height = this.controlPositionDetailsArr[j].height;
                    }
                } else {
                    width = this.controlPositionDetailsArr[j].width;
                    height = this.controlPositionDetailsArr[j].height;
                }
                if (this.controlPositionDetailsArr[j].controlName === 'Signature' || this.controlPositionDetailsArr[j].controlName === 'NewInitials') {
                    height = this.controlPositionDetailsArr[j].height;
                }

                if (currentCtrDetailsStyleTop < (this.controlPositionDetailsArr[j].top + (height - 4)) &&
                    (currentCtrDetailsStyleTop + myTextHeight) > this.controlPositionDetailsArr[j].top) {
                    if ((parseFloat(currentCtrDetailsStyleLeft.toFixed(3)) !== parseFloat(this.controlPositionDetailsArr[j].left.toFixed(3)) &&
                        parseFloat(currentCtrDetailsStyleTop) !== this.controlPositionDetailsArr[j].top) || ((parseFloat(currentCtrDetailsStyleLeft) !== this.controlPositionDetailsArr[j].left) && parseFloat(currentCtrDetailsStyleTop) === this.controlPositionDetailsArr[j].top)) {
                        if (parseFloat(this.controlPositionDetailsArr[j].left) + width > parseFloat(currentCtrDetailsStyleLeft)) {
                            //console.log('leftArr',this.controlPositionDetailsArr[j]);
                            leftTempPositionsArr.push(this.controlPositionDetailsArr[j]);
                        }
                    }
                }
            }
        }
        return leftTempPositionsArr;
    }

    getWebTopPositions(docPage: any, currentCtrDetailsStyleLeft: any, currentCtrDetailsStyleTop: any, myTextWidth: any) {
        let topTempPositionsArr: any = [];
        for (var j = 0; j < this.controlPositionDetailsArr.length; j++) {
            if (this.controlPositionDetailsArr[j].docPage === docPage) {
                let ele: any = document.getElementById(this.controlPositionDetailsArr[j].controlId);
                let height: any = 0;
                let width: any = 0;
                if (ele) {
                    if (ele && ele.style && (ele.style.width === 'auto' || ele.style.width === '')) {
                        width = this.controlPositionDetailsArr[j].width;
                    } else if (ele && ele.style && (ele.style.width === '100%')) {
                        let parentEle: any = document.getElementById(this.controlPositionDetailsArr[j].controlId.replace('control_', ''));
                        if (parentEle && parentEle.style && parentEle.style.width) {
                            width = parseFloat(parentEle.style.width.split('px')[0]);
                        } else {
                            width = this.controlPositionDetailsArr[j].width;
                        }
                    } else {
                        width = parseFloat(ele.style.width.split('px')[0]);
                    }

                    if (ele.style.height === '100%' || ele.style.height === 'auto' || ele.style.height === '') {
                        height = this.controlPositionDetailsArr[j].height;
                    } else {
                        height = parseFloat(ele.style.height.split('px')[0]);
                    }
                }
                else if (ele && ele.style && (ele.style.height === 'auto' || ele.style.height === '')) {
                    let parentEle: any = document.getElementById(this.controlPositionDetailsArr[j].controlId.replace('control_', ''));
                    if (parentEle && parentEle.style && parentEle.style.height) {
                        height = parseFloat(parentEle.style.height.split('px')[0]);
                    } else {
                        height = this.controlPositionDetailsArr[j].height;
                    }
                } else if (ele && ele.style && (ele.style.height === '100%')) {
                    let parentEle: any = document.getElementById(this.controlPositionDetailsArr[j].controlId.replace('control_', ''));
                    if (parentEle && parentEle.style && parentEle.style.height) {
                        height = parseFloat(parentEle.style.height.split('px')[0]);
                    } else {
                        height = this.controlPositionDetailsArr[j].height;
                    }
                } else {
                    width = this.controlPositionDetailsArr[j].width;
                    height = this.controlPositionDetailsArr[j].height;
                }
                if (this.controlPositionDetailsArr[j].controlName === 'Signature' || this.controlPositionDetailsArr[j].controlName === 'NewInitials') {
                    height = this.controlPositionDetailsArr[j].height;
                }
                //if comparing current control left and all array controls left value is same so that we added plus 1 for left value controls
                // add 4px, both controls placed near side by side and comparing the prev control left and width we reduce the some with of previous controls
                if (currentCtrDetailsStyleLeft < (this.controlPositionDetailsArr[j].left + 1) + (width - 4) &&
                    (currentCtrDetailsStyleLeft + myTextWidth) > this.controlPositionDetailsArr[j].left) {
                    if (parseFloat(currentCtrDetailsStyleTop.toFixed(3)) !== parseFloat(this.controlPositionDetailsArr[j].top.toFixed(3))) {
                        if (((this.controlPositionDetailsArr[j].top + (height - 4))) > parseFloat(currentCtrDetailsStyleTop)) {
                            //console.log('topArr',this.controlPositionDetailsArr[j]);
                            topTempPositionsArr.push(this.controlPositionDetailsArr[j]);
                        }
                    }
                }

            }
        }
        return topTempPositionsArr;
    }

    //function for to get ZCoordinate after expand control
    updateZCoordinate(docPage: any, myTextArea: any, currentCtrDetails: any) {
        let currentImageHeight: any = document.getElementById('imgControl_' + docPage)!
        let parentHeight: any;
        if (this.documentControlsResponse && this.documentControlsResponse.EnvelopeInfo && this.documentControlsResponse.EnvelopeInfo.EnvelopeImageCollection) {
            parentHeight = currentImageHeight.clientHeight; //this.documentControlsResponse.EnvelopeInfo.EnvelopeImageCollection[ctrl.PageNo - 1].Dimension.Height - 48;
        }
        let outerHeight: any = myTextArea.style.height.split('px')[0];
        let controlBottom: any = parseInt(parentHeight) - (currentCtrDetails.offsetTop + parseInt(outerHeight));

        let image: any = document.querySelector("#imgControl_" + docPage)!;

        const img = new Image();
        let imgId: any = document.getElementById(image.id);
        let imageUrl: any = imgId && imgId.children[0] && imgId.children[0].currentSrc;
        img.onload = function () {
            let originalWidth: any = img.naturalWidth;
            let originalHeight: any = img.naturalHeight;
            let imageWidth: any = currentImageHeight.children[0].width - 2;
            let imageHeight: any = currentImageHeight.children[0].height - 3;
            let ratioWidth: any = 1 / (imageWidth / originalWidth);
            let ratioHeight: any = 1 / (imageHeight / originalHeight);
            let ZCoordinate: any;
            if ((controlBottom * ratioHeight) <= 0) {
                ZCoordinate = 1;
            } else {
                ZCoordinate = (controlBottom * ratioHeight);
            }
            //console.log('ZCoordinate',ZCoordinate );
            myTextArea.setAttribute('data-ZCoordinate', ZCoordinate);
        };
        img.src = imageUrl;
    }

    //checking expandable is applicable or not for similar text fields
    updateAutoExpand(controlId: any) {
        let myTextArea: any = document.getElementById('control_' + controlId);
        let myTextAreaParent: any = document.getElementById(controlId);
        let textAreaDefaultHeight: any = myTextAreaParent.style.height;
        let textAreaDefaultWidth: any = myTextAreaParent.style.width;
        let myTextWidth: any = parseInt(window.getComputedStyle(myTextArea).width);
        let myTextHeight: any = parseInt(window.getComputedStyle(myTextArea).height);
        let currentCtrDetails: any = document.getElementById(controlId);
        let docPage: any = parseInt(myTextAreaParent.getAttribute('data-page'));
        let currentCtrDetailsStyleLeft: any = parseFloat(currentCtrDetails.style.left.split('px')[0]);
        let currentCtrDetailsStyleTop: any = parseFloat(currentCtrDetails.style.top.split('px')[0]);

        let topPositionsArr: any = this.getWebTopPositions(docPage, currentCtrDetailsStyleLeft, currentCtrDetailsStyleTop, myTextWidth);
        let leftPositionsArr: any = this.getWebLeftPositions(docPage, currentCtrDetailsStyleLeft, currentCtrDetailsStyleTop, myTextHeight);

        let isFixedWidth: any = myTextArea.getAttribute('data-isfixed-width');
        if (isFixedWidth === false || isFixedWidth === 'false') {
            let myTextLength: any = myTextArea.value.length;
            let myTextMinLength: any = parseInt(myTextArea.getAttribute('data-maxlength')); //20;
            let imagePage: any = document.querySelector("#imgControl_" + docPage)!;
            let imagePageWidth: any = imagePage.offsetWidth;
            let nearestLeftValue: any;
            let nearestLeftMinusValue: any = 10;
            let imagePageWidthMinusValue: any = 15;
            if (leftPositionsArr.length > 0) {
                leftPositionsArr = leftPositionsArr.sort(function (a: any, b: any) {
                    return a.left - b.left;
                });
                nearestLeftValue = leftPositionsArr[0].left;
            }

            if (myTextLength > myTextMinLength && leftPositionsArr.length > 0) {
                if ((parseFloat(currentCtrDetailsStyleLeft) + myTextWidth) <= (nearestLeftValue - nearestLeftMinusValue)) {
                    if (myTextArea && myTextArea.value && myTextLength > myTextMinLength) {
                        let widthArr: any = (myTextLength - myTextMinLength);
                        myTextWidth = parseInt(textAreaDefaultWidth.replace('px', ''));
                        for (var j = 0; j < widthArr; j++) {
                            if ((parseFloat(currentCtrDetailsStyleLeft) + myTextWidth) <= (nearestLeftValue - nearestLeftMinusValue)) {
                                myTextWidth += 8;
                            }
                        }
                    }
                }
            } else if (leftPositionsArr.length === 0 && (myTextLength > myTextMinLength) && (parseFloat(currentCtrDetailsStyleLeft) + myTextWidth) <= (imagePageWidth - imagePageWidthMinusValue)) {
                if (myTextArea && myTextArea.value && myTextLength > myTextMinLength) {
                    let widthArr: any = (myTextLength - myTextMinLength);
                    myTextWidth = parseInt(textAreaDefaultWidth.replace('px', ''));
                    for (var j = 0; j < widthArr; j++) {
                        if ((parseFloat(currentCtrDetailsStyleLeft) + myTextWidth) <= (imagePageWidth - imagePageWidthMinusValue)) {
                            myTextWidth += 8;
                        }
                    }
                }
            }

            myTextArea.style.width = myTextWidth + 'px';

            //checking height
            if (topPositionsArr && topPositionsArr.length > 0) {
                setTimeout(() => {
                    let nextTopValue: any;
                    if (topPositionsArr.length > 0) {
                        topPositionsArr = topPositionsArr.sort(function (a: any, b: any) {
                            return a.top - b.top;
                        });
                        nextTopValue = topPositionsArr[0].top;
                    }
                    this.expandableCtrlNextTopValue = nextTopValue;
                    if (myTextArea.value.length === 0 || myTextArea.value === '') {
                        myTextArea.style.height = textAreaDefaultHeight;
                    }
                    if (myTextArea.value.length && (parseFloat(currentCtrDetailsStyleTop) + myTextArea.scrollHeight) >= (nextTopValue)) {
                        this.updateCopyAndPasteText(myTextArea, textAreaDefaultHeight, currentCtrDetailsStyleTop, nextTopValue);
                    } else {
                        if (myTextArea.scrollHeight > textAreaDefaultHeight.split('px')[0]) {
                            if (parseFloat(currentCtrDetailsStyleTop) + myTextHeight < nextTopValue - 4) {
                                myTextArea.style.height = (myTextArea.scrollHeight) + 'px';
                            }
                        }
                        myTextArea.setAttribute('data-textVal', myTextArea.value);
                    }
                }, 100);
            } else {
                this.expandableCtrlNextTopValue = 0;
                let imagePage: any = document.querySelector("#imgControl_" + docPage)!;
                let imagePageHeight: any = imagePage.offsetHeight;
                if ((parseFloat(currentCtrDetailsStyleTop) + myTextArea.scrollHeight) >= (imagePageHeight - 10)) {
                    this.updateCopyAndPasteText(myTextArea, textAreaDefaultHeight, currentCtrDetailsStyleTop, (imagePageHeight - 10));
                } else {
                    if ((parseFloat(currentCtrDetailsStyleTop) + myTextHeight) <= (imagePageHeight - 10)) {
                        myTextArea.style.height = textAreaDefaultHeight;
                        if (myTextArea.scrollHeight > textAreaDefaultHeight.split('px')[0]) {
                            myTextArea.style.height = (myTextArea.scrollHeight) + 'px';
                        }
                    }
                }
            }

            this.updateZCoordinate(docPage, myTextArea, currentCtrDetails);

        }
    }

    calculateTopLeftForAllControls(ctrlObj:any){
        let left: any = 0;
        let top: any = 0;
        let calculatedLeftSplitVal: any;
        if (ctrlObj.CalculatedLeft) {
            calculatedLeftSplitVal = ctrlObj.CalculatedLeft.split(':')[1].trim();
            let tempLeft: any = calculatedLeftSplitVal;
            left = tempLeft.split('px')[0];
        }
        let calculatedTopSplitVal: any;
        if (ctrlObj.CalculatedTop) {
            calculatedTopSplitVal = ctrlObj.CalculatedTop.split(':')[1].trim();
            let tempTop: any = calculatedTopSplitVal;
            top = tempTop.split('px')[0];
        }
        this.controlPositionDetailsArr.push({
            'left': parseFloat(left), 'top': parseFloat(top),
            'width': parseFloat(ctrlObj.Width),
            'height': parseFloat(ctrlObj.Height),
            'tabindex': ctrlObj.TabIndex,
            'controlId': 'control_' + ctrlObj.ControlHtmlID,
            'controlName': ctrlObj.ControlName,
            'required': ctrlObj.Required,
            'docPage': ctrlObj.PageNo
        });

        this.removeDuplicateObjects();
    }

    //Removing duplicate objects based on controlId
    removeDuplicateObjects(){
        let objectsArray:any = this.controlPositionDetailsArr;
        let uniqueIds:any = {};
        let uniqueObjects:any = [];

        objectsArray.forEach((obj: any) => {
            if (!uniqueIds[obj.controlId]) {
                uniqueIds[obj.controlId] = true;
                uniqueObjects.push(obj);
            }
        });

        this.controlPositionDetailsArr = uniqueObjects;
    }
}
