<div [ngStyle]="{'display':declinePopupStyle}">
    <!-- Modal Header -->
    <div class="modal-header bg-modal modalhead-padding">
        <h4 class="modal-title">{{getLanguageTranslationValue(resourceKeys.Decline)}}</h4>
        <div class="d-flex align-items-center">
            <span class="material-icons modal-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                close
            </span>
        </div>
    </div>

    <!-- Modal body -->
    <div class="modal-body modal-bodybg">
        <div id="declineModalBody" class="modal-height">
            <input type="hidden" id="GlobalEnvelopeID" value="f925f2ac-5949-4747-9c74-1425a05bd5eb">
            <div class="declineTemplate">
                <form id="rejectForm" class="rejectForm1">
                    <div class="ReasonRadioButtons">
                        <div class="d-flex align-items-start res-flexstart">
                            <div>
                                <p style="margin-bottom: 5px;">
                                    <span style="font-weight: 600;">Caution:</span>
                                    If you select <span style="font-weight: 600;">Decline</span>
                                    this document will be voided. This action cannot be undone.
                                </p>
                                <p style="margin-bottom: 5px;">
                                    To request changes to this document, please select
                                    <span style="font-weight: 600;">close</span>
                                    and contact the sender.
                                </p>

                                <p *ngIf="!enableMessageToMobile" style="margin-bottom: 5px;">
                                    To continue, please enter the email address.
                                </p>
                                <p *ngIf="enableMessageToMobile && (deliverymode == '1' || deliverymode == '11')" style="margin-bottom: 5px;">
                                    To continue, please enter the email address.
                                </p>
                                <p *ngIf="enableMessageToMobile && returnEmailAndMobileDeliveryModeVal(deliverymode)" style="margin-bottom: 5px;">
                                    To continue, please enter the email address or the mobile number below.
                                </p>
                                <p *ngIf="enableMessageToMobile && (deliverymode == '4' || deliverymode == '6')" style="margin-bottom: 5px;">
                                    To continue, please enter the mobile number below.
                                </p>

                            </div>
                        </div>

                        <p style="font-size: 14px;font-weight: 700;" *ngIf="!enableMessageToMobile">
                            {{getLanguageTranslationValue(resourceKeys.EmailAddress)}} </p>

                        <input style="width: 99%;" *ngIf="!enableMessageToMobile" type="text" class="form-control" maxlength="45"
                            id="changedSignerEmail" autocomplete="off" />

                        <!-- <p style="font-size: 14px;font-weight: 700;"
                            *ngIf="enableMessageToMobile && deliverymode =='1'">
                            {{getLanguageTranslationValue(resourceKeys.EmailAddress)}}</p> -->

                        <!-- <input *ngIf="enableMessageToMobile && deliverymode =='1'" type="text" class="form-control"
                            maxlength="45" id="changedSignerEmail" autocomplete="off" /> -->

                        <div *ngIf="enableMessageToMobile && returnShowEmailBasedOnDeliveryModeVal(deliverymode)"
                            class="form-group mb-1 border-boxsize">
                            <label for="email" class="label-font14" style="margin-left: 2px;font-weight: 700;">Email
                                Address</label>
                            <div class="w-100" >
                                <div class="" style="display: inline-block;margin-top: 15px;width: 99%;">
                                    <input formControlName="email" placeholder="Enter signer email"
                                        id="changedSignerEmail" type="text" autocomplete="off" style="margin-left: 2px;"
                                        maxlength="45" class="form-control" autoFocus="false">
                                </div>
                            </div>
                        </div>

                        <div *ngIf="enableMessageToMobile && returnShowMobileDeliveryModeVal(deliverymode)"
                            class="input-group form-group mb-2 border-boxsize">
                            <label for="email" class="label-font14" style="margin-left: 3px;font-weight: 700;">{{getLanguageTranslationValue(resourceKeys.CountryCode)}}</label>
                            <label for="mobile" class="label-font14" style="margin-left: 80px;font-weight: 700;">{{getLanguageTranslationValue(resourceKeys.Mobile)}}</label>
                            <br />
                            <div class="w-100" style="display: flex;">
                                <div class="" style="display: flex;margin-left: 4.5px;width: 100%;">
                                    <div id="ddrCountryFlag" ngbDropdown class="dropdown" #myDrop="ngbDropdown"
                                        style="width: 25%px;display: inline-block;">
                                        <a ngbDropdownToggle style="text-decoration-line: none; color: #000;">
                                            <span id="spnDeclineTitle" style="display: inline;">Select</span>
                                        </a>
                                        <ul ngbDropdownMenu class="dropdown-menu" id="DeclineDialCodeUl"
                                            (click)="selectedCountryDialCode($event);myDrop.close();">
                                            <li class="liCountryItem"
                                                *ngFor="let dialcodes of DialCodeDropdownList; let i = index;">
                                                <a *ngIf="dialcodes.DialCode == 'Select'" [attr.data-maxlength]="0"
                                                    style="text-decoration-line: none;color: #000;padding-top: 15px;padding-bottom: 15px; background-color: #bc0001;">
                                                    <span id="spnCountryFlag"></span>Select</a>
                                                <a style="text-decoration-line: none;color: #000;"
                                                    *ngIf="dialcodes.DialCode != 'Select'"
                                                    [attr.data-countrycode]="dialcodes.CountryCode.toLowerCase()"
                                                    [attr.data-maxlength]="dialcodes.MobileMaxLength">
                                                    <span id="spnCountryFlag"
                                                        class="bg-{{dialcodes.CountryCode.toLowerCase()}}"
                                                        style="top: -8px; display: inline-block; width: 25px;"></span>{{dialcodes.DialCode}}</a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div style="display: inline-block;width: 75%;"> <input type="text"
                                            class="form-control txtDeclineSignerMobileNumber"
                                            id="txtDeclineSignerMobileNumber" placeholder="Enter mobile number"
                                            pattern="[0-9]*" ondrop="return false" inputmode="numeric" typeof="number"
                                            (input)="validateInputValue($event)" spellcheck="false"
                                            autocomplete="off" name="mobilenumber" 
                                            value=""></div>
                                </div>
                            </div>
                            <input type="hidden" id="hdnDeliveryMode" value="" />
                        </div>

                        <label *ngIf="title" class="declineTitle font-16" style="margin-left:2px;">{{title}}</label>
                        <div *ngIf="controlType !== 3">
                            <div *ngFor="let reason of reasonList">
                                <div class="radio" *ngIf="controlType === 1">
                                    <input id="radio_{{reason.ID}}" type="radio"
                                        (click)="onOtherCLick(otherTextArea, reason, 'radio')"
                                        name="DeclineReasonRadioGroup1" value="{{reason.ResponseText}}">
                                    <label for="radio_{{reason.ID}}" class="radio-label">{{reason.ResponseText}}</label>
                                </div>

                                <div class="checkbox" *ngIf="controlType === 2">
                                    <label class="ps-0" for="checkbox_{{reason.ID}}">
                                        <input type="checkbox" type="checkbox" id="checkbox_{{reason.ID}}"
                                            (click)="onOtherCLick(otherTextArea, reason, 'checkbox')"
                                            name="DeclineReasonRadioGroup1" value="{{reason.ResponseText}}">
                                        <span class="cr">
                                            <i class="cr-icon material-icons">check</i></span>
                                        <span class="font-14">{{reason.ResponseText}}</span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div #otherOptionRequired style="display: none;">
                            <div class="formErrorContent">This field is required*</div>
                            <div class="down-arrow"></div>
                        </div>
                        <div *ngIf="controlType === 3" class=" border-boxsize">
                            <textarea (input)="textAreaInput($event)" class="rejectText form-control" maxlength="250"
                                placeholder="Please explain the reason for declining this request"></textarea>
                        </div>
                        <div class="border-boxsize">
                            <textarea #otherTextArea (input)="textAreaInput($event)" class="rejectText form-control"
                                maxlength="250" placeholder="Please explain the reason for declining this request"
                                style="display: none;"></textarea>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    </div>

    <!-- Modal footer -->
    <div class="modal-footer bg-modal modalfooter-padding">
        <button type="button" class="btn btncancel px-3 py-1" (click)="activeModal.dismiss('Cross click')"
            id="btnSignerCancel"
            style="height: 23px;border-radius: 4px;">{{getLanguageTranslationValue(resourceKeys.Cancel)}}</button>
        <button type="button" class="btn btn-default btn_accept button-29 ms-3" id="rejectEnvelopeBtn"
            (click)="getFinalValidation()">{{getLanguageTranslationValue(resourceKeys.Decline)}}</button>
    </div>
</div>


<div [ngStyle]="{'display':waitModalPopupStyle}" id="waitModalPopup" class="modal waitModalPopup" tabindex="-1"
    role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content" style="box-shadow: rgb(14 30 37 / 60%) 0px 0px 10px;">
            <div class="modal-header bg-modal modalhead-padding">
                <h4 class="modal-title">Attention</h4>
                <div class="d-flex align-items-center">
                    <span class="material-icons modal-close" aria-label="Close"
                        (click)="activeModal.dismiss('Cross click')">
                        close
                    </span>
                </div>
            </div>

            <!-- Modal body -->
            <div id="disclaimerTermsAndServiceBody" class="modal-body modal-bodybg">
                <div class="py-2" style="font-size: 16px;">
                    <div class="d-flex justify-content-center">
                        <img class=" mb-3 h-64" src="../../../assets/images/stopwatch.png">
                    </div>
                    <!-- <p id="changedSignerEmailtxt" style="margin-bottom: 0.25rem;" class="text-center"> This address
                        {{changedSignerEmail}} was recently used to update this document. </p> -->

                    <p *ngIf="!enableMessageToMobile" id="changedSignerEmailtxt" style="margin-bottom: 0.25rem;"
                        class="text-center">
                        {{getLanguageTranslationValue(resourceKeys.waitingMsgAddressStatic)}} <b
                            style="font-weight:600"> {{changedSignerEmail}} </b>
                        {{getLanguageTranslationValue(resourceKeys.waitingMsgSignStatic)}} </p>

                    <p *ngIf="enableMessageToMobile && (deliverymode == '1' || deliverymode == '11')" id="changedSignerEmailtxt"
                        style="margin-bottom: 0.25rem;" class="text-center">
                        {{getLanguageTranslationValue(resourceKeys.waitingMsgAddressStatic)}} <b
                            style="font-weight:600"> {{changedSignerEmail}} </b>
                        {{getLanguageTranslationValue(resourceKeys.waitingMsgSignStatic)}} </p>

                    <p *ngIf="enableMessageToMobile && returnEmailAndMobileDeliveryModeVal(deliverymode)" id="changedSignerEmailtxt"
                        style="margin-bottom: 0.25rem;" class="text-center">
                        {{getLanguageTranslationValue(resourceKeys.waitingMsgAddressStatic)}} <b
                            style="font-weight:600"> {{changedSignerEmail}} </b>
                            <span *ngIf="isToShowchangedSignerMobileText"> {{changedSignerMobileText}} </span>
                            <b *ngIf="isToShowchangedSignerMobileText" style="font-weight:600"> {{changedSignerMobile}} </b>
                        {{getLanguageTranslationValue(resourceKeys.waitingMsgSignStatic)}} </p>

                    <p *ngIf="enableMessageToMobile && (deliverymode == '4' || deliverymode == '6')" id="changedSignerEmailtxt"
                        style="margin-bottom: 0.25rem;" class="text-center">
                        {{getLanguageTranslationValue(resourceKeys.lang_MobileNumber)}} <b style="font-weight:600">
                            {{changedSignerEmail}} </b>
                        {{getLanguageTranslationValue(resourceKeys.waitingMsgSignStatic)}} </p>

                    <!-- <p id="timerValuetxt" style="margin-bottom: 0.25rem;" class="text-center"> Please try again in <b
                            style="color:#49d26d; font-weight:600">{{timerValue}}</b> minutes.</p>
                    <p id="proceddNowtxt" style="margin-bottom: 0.25rem; display: none;" class="text-center">Now you can
                        proceed and click on the Submit button</p> -->

                    <p id="timerValuetxt" style="margin-bottom: 0.25rem;" class="text-center">
                        {{getLanguageTranslationValue(resourceKeys.waitingMsgTryAgainStatic)}} <b
                            style="color:#49d26d; font-weight:600">{{timerValue}}</b>
                        {{getLanguageTranslationValue(resourceKeys.Sessionpopupminutes)}}</p>
                    <p id="proceddNowtxt" style="margin-bottom: 0.25rem; display: none;" class="text-center">
                        {{getLanguageTranslationValue(resourceKeys.waitingMsgProceedStatic)}}</p>
                </div>

            </div>

            <!-- Modal footer -->
            <div class="modal-footer bg-modal modalfooter-padding">
                <button class="button-29 me-2 px-3" type="button" style="border: none;outline: none; width: 100px"
                    (click)="activeModal.dismiss('Cross click')">Ok</button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="showLoader" class="loader">
</div>