  <!-- Modal Header -->
  <div class="modal-header bg-modal modalhead-padding">
    <h4 class="modal-title">  You're Done Signing</h4>
    <div class="d-flex align-items-center">
        <span class="material-icons modal-close" aria-label="Close"  (click)="onNoThanks()">
            close
            </span>
    </div>
  </div>

  <!-- Modal body -->
  <div class="modal-body modal-bodyb py-3">
    <div class="d-flex flex-column" style="width: 100%;">
           <div  class="d-flex flex-wrap" style="justify-content: center;
           text-align: left;">
              <p class="mb-3 font-15">
                You can download the final signed document by clicking the download icon or login into your RSign
                account to view a copy of the final signed document in the "Completed" folder.
             </p>
           </div>
    
           <div class="d-flex align-items-center justify-content-center mt-2 pt-1">

            <button type="button" class="btn btn-default btn_accept button-29 ms-3" id="downloadFinalContractBtn" 
            (click)="onDownload()"><i class="fa fa-download me-2" aria-hidden="true"></i>Download </button>  
            </div>
        </div>
     </div>
  
    <!-- Modal footer -->
<div class="modal-footer bg-modal modalfooter-padding">

<button type="button" id="btnNoThanks" class="btn btncancel px-3 py-1"  (click)="onNoThanks()" style="height: 23px;
border-radius: 4px;">No Thanks</button>
<button type="button" class="btn btn-default btn_accept button-29 ms-3" id="btnSignInExistingUser"  (click)="onContinue()">Continue </button>

</div>



<div *ngIf="showLoader" class="loader">
</div>