import { Component, OnInit, ViewEncapsulation } from '@angular/core';
// import { Observable } from 'rxjs';
import { SignerLandingService } from '../../services/signer-landing.service'
import { CommonConstants } from '../../constants/common-constants';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
    headerText: string = '';
    companyLogo: string = '';
    appLogo: string = '';
    htmlHeaderString: any;
    htmlFooterString: any;
    showLoader: boolean = false;
    activatedRouteName: any = '';
    showheadertext: string = 'none';
    fullHeaderText: any;
    webOrMobile: string = 'web';
    firstImgSrc:any;
    headerTextValue:any = '';
    secondImgSrc:any;

    constructor(
        private activatedRoute: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private signerLandingService: SignerLandingService,
    ) { }

    ngOnInit(): void {
        if ((/android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
            this.webOrMobile = 'mobile';
        }
        let urlPath: any = this.activatedRoute.pathFromRoot[1].snapshot.url;
        if (urlPath != null && urlPath != undefined && urlPath.length > 0) {
            this.activatedRouteName = this.activatedRoute.pathFromRoot[1].snapshot.url[0].path;
            this.getHeaderLogosAndtext();
        }
    }

    showHeadertext() {
        let headerTextval: any = document.getElementById('headerText');
        if (headerTextval) {
            if (headerTextval.textContent.length > 16) {
                let headerFulltext: any = document.getElementById('full-bannertext');
                if (headerFulltext) {
                    if (headerFulltext.style.display === 'inline-flex') {
                        headerFulltext.style.display = 'none';
                    } else {
                        headerFulltext.style.display = 'inline-flex';
                    }
                }
            }
        }
    }

    // To get company, app logos and header text
    getHeaderLogosAndtext() {
        this.showLoader = true;
        let url = environment.brandUrl;
        let senderEmailId: any = localStorage.getItem("senderEmail");
        if (senderEmailId === null || senderEmailId === '' || senderEmailId === undefined) {
            //this.htmlHeaderString = this.sanitizer.bypassSecurityTrustHtml('<div class="margin0 header-container"><div class="col-xs-3 navbar-brand rsign-leftlogo"><img src="../../../assets/images/RSign by RPost.svg" class="rsignLogo left-logo"></div><div class="col-xs-6" style=""><h4 class="HeaderText"><span></span></h4></div><div class="col-xs-3 navbar-brand"><div class="pull-right right-logo rsign-rightlogo"><img src="../../../assets/images/RPost-Button-White.svg" class="rsignLogo"></div></div></div>');
            this.setDefaultLogos();
            this.htmlFooterString = '<p> ' +
                '<a href="https://rpost.com/legal-notices/terms-and-conditions" target="_blank" class="footerLinks">General Terms & Legal Notices</a>: Click to view service level terms, conditions, privacy, ' +
                '<a class="footerLinks" href="https://rpost.com/legal-notices/legal-and-patent-notice" target="_blank"> patent, trademark</a>, and other service and legal notices. ' +
                '</p>';
            this.showLoader = false;
            this.hideLoader();
            document.getElementById('dvHeader')!.style.display = 'block';
            setTimeout(() => {
                this.addCssClasses();
            }, 500);
            localStorage.setItem('htmlFooterString', this.htmlFooterString);
        }
        else {
            let bkey: any = CommonConstants.bkey;
            url = url + '?emailaddress=' + senderEmailId + '&bkey=' + bkey;
            this.signerLandingService.getHeaderLogosAndtext(url).subscribe(
                (result: any) => {
                    // console.log('result');

                    this.showLoader = false;
                    this.hideLoader();
                    let prefill = localStorage.getItem('prefill');
                    if (result != null && result.ResultContent != null) {
                        if (prefill != 'prefill' && result.ResultContent.HeaderHtml != null && result.ResultContent.HeaderHtml != '') {
                            // this.htmlHeaderString = this.sanitizer.bypassSecurityTrustHtml(result.ResultContent.HeaderHtml);
                            const inputHtml = result.ResultContent.HeaderHtml;
                            // Remove backslashes
                            //const sanitizedHtml = inputHtml.replace(/\\\"/g, '"');
                            // Create a DOMParser instance
                            const parser = new DOMParser();

                            // Parse the HTML string
                            const doc = parser.parseFromString(inputHtml, 'text/html');

                            // Get the first and second img src
                            this.firstImgSrc = doc.querySelector('.col-xs-3 img:first-child')!.getAttribute('src');
                            this.secondImgSrc = doc.querySelector('.col-xs-3 .pull-right img:last-child')!.getAttribute('src');

                            // Get h4 tag value
                            this.headerTextValue = doc.querySelector('.col-xs-6 h4')!.textContent;
                            setTimeout(() => {
                                this.addCssClasses();
                            }, 500);
                        } else {
                            //this.htmlHeaderString = this.sanitizer.bypassSecurityTrustHtml('<div class="margin0 header-container"><div class="col-xs-3 navbar-brand rsign-leftlogo"><img src="../../../assets/images/RSign by RPost.svg" class="rsignLogo"></div><div class="col-xs-6" style=""><h4 class="HeaderText"><span></span></h4></div><div class="col-xs-3  navbar-brand"><div class="pull-right right-logo rsign-rightlogo"><img src="../../../assets/images/RPost-Button-White.svg" class="rsignLogo"></div></div></div>');
                            this.setDefaultLogos();
                            setTimeout(() => {
                                this.addCssClasses();
                            }, 500);
                            document.getElementById('dvHeader')!.style.display = 'block';
                        }
                        if (prefill != 'prefill' && result.ResultContent.FooterHtml != null && result.ResultContent.FooterHtml != '') {
                            this.htmlFooterString = result.ResultContent.FooterHtml;
                        } else {
                            this.htmlFooterString = '<p> ' +
                                '<a href="https://rpost.com/legal-notices/terms-and-conditions" target="_blank" class="footerLinks">General Terms & Legal Notices</a>: Click to view service level terms, conditions, privacy, ' +
                                '<a class="footerLinks" href="https://rpost.com/legal-notices/legal-and-patent-notice" target="_blank"> patent, trademark</a>, and other service and legal notices. ' +
                                '</p>';
                        }

                        localStorage.setItem('htmlFooterString', this.htmlFooterString);
                    }
                    else if(result && result.originalError){
                        //this.htmlHeaderString = this.sanitizer.bypassSecurityTrustHtml('<div class="margin0 header-container"><div class="col-xs-3 navbar-brand rsign-leftlogo"><img src="../../../assets/images/RSign by RPost.svg" class="rsignLogo left-logo"></div><div class="col-xs-6" style=""><h4 class="HeaderText"><span></span></h4></div><div class="col-xs-3 navbar-brand"><div class="pull-right right-logo rsign-rightlogo"><img src="../../../assets/images/RPost-Button-White.svg" class="rsignLogo"></div></div></div>');
                        this.setDefaultLogos();
                        this.htmlFooterString = '<p> ' +
                        '<a href="https://rpost.com/legal-notices/terms-and-conditions" target="_blank" class="footerLinks">General Terms & Legal Notices</a>: Click to view service level terms, conditions, privacy, ' +
                        '<a class="footerLinks" href="https://rpost.com/legal-notices/legal-and-patent-notice" target="_blank"> patent, trademark</a>, and other service and legal notices. ' +
                        '</p>';
                        setTimeout(() => {
                            this.addCssClasses();
                        }, 500);
                        localStorage.setItem('htmlFooterString', this.htmlFooterString);
                    }
                });
        }

        // this.headerText = 'Signer Mobile View';
        // this.companyLogo = '../../../assets/images/RSign by RPost.svg';
        // this.appLogo = '../../../assets/images/rsign-black.png';
    }

    addCssClasses(){
        let elementArray = document.getElementsByClassName('margin0');
        if (this.activatedRouteName === 'sign-document') {
            if(elementArray && elementArray[0]){
                elementArray[0].classList.add('signerheader-container');
            }
        }
        else {
            if(elementArray && elementArray[0]){
                elementArray[0].classList.add('header-container');
            }
        }
        // if(elementArray && elementArray[0]){
        //     elementArray[0].classList.remove('row');
        // }
        // let leftlogo: any = document.getElementsByClassName('col-xs-3');
        // let rightlogo: any = document.getElementsByClassName('pull-right');
        // if(leftlogo && leftlogo[0]){
        //     leftlogo[0].classList.add('navbar-brand', 'rsign-leftlogo');
        // }
        // if(rightlogo && rightlogo[0]){
        //     rightlogo[0].classList.add('rsign-rightlogo');
        // }

        //let TitleText: any = document.getElementsByClassName('col-xs-6');
        //TitleText[0].parentElement.classList.add('col-xs-6', 'col-md-6');
        //let rightlogo: any = document.getElementsByClassName('pull-right');
        // intro.setAttribute('data-bs-target', '#full-bannertext');
        // intro.setAttribute('data-bs-toggle', 'collapse');
        if (this.webOrMobile === 'mobile') {
            let intro = document.getElementsByClassName('HeaderText')[0];
            if(intro){
                intro.setAttribute('id', 'headerText');
            }
            let headerTextClick: any = document.getElementById('headerText');
            if(headerTextClick){
                headerTextClick.addEventListener('click', this.showHeadertext);
            }
            //rightlogo[0].parentElement.classList.add('col-xs-3', 'col-md-3');
            let headerText:any = document.getElementById('headerText');
            if(headerText){
                this.fullHeaderText = headerText.textContent;
            }
        }
        document.getElementById('dvHeader')!.style.display = 'block';
    }

    hideLoader(){
        let loaderEle: any = document.getElementsByClassName('loader');
        if(loaderEle && loaderEle[0] && loaderEle[0].style){
            loaderEle[0].style.display = 'none';
        }
    }

    setDefaultLogos(){
        this.firstImgSrc = '../../../assets/images/RSign.svg';
        this.headerTextValue = '';
        this.secondImgSrc = '../../../assets/images/RPost-Button-White.svg';
    }

}