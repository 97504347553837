import { Component, OnInit, Renderer2, ElementRef, ViewChild, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import SignaturePad from 'signature_pad';
import { ToastrService } from 'ngx-toastr';
import { CommonConstants, Controls, ControlsName, ResourceKeys, SignatureType } from 'src/app/constants/common-constants';
import { SignerLandingService } from 'src/app/services/signer-landing.service';
import { TranslationsService } from 'src/app/services/translations.service';
import { throttle } from 'lodash';
@Component({
    selector: 'app-initials-pad',
    templateUrl: './initials-pad.component.html',
    styleUrls: ['./initials-pad.component.scss']
})
export class InitialsPadComponent implements OnInit {

    signaturePad: any;

    @ViewChild('canvas', { static: false })
    canvasEl!: ElementRef;

    @ViewChild('dvHandDrawn', { static: false })
    dvHandDrawn!: ElementRef;

    signatureFontObj: any;
    elementVar: any;
    signControlObj: any;

    initialsText: string = '';
    selectedRadioBtn: string = 'signerSignatureType';
    signatureImg: string | undefined;

    initialsContainer: boolean = false;
    typeSignature: boolean = true;
    showLoader: boolean = true;

    documentControlsResponse: any;
    signatureTypeDisplay: any;
    SignatureCaptureType: boolean = false;
    SignatureCaptureHanddrawn: boolean = false;
    UploadSignature: boolean = false;
    newInitsignatureTypeDisplay: any;
    initialsInputText: string = '';
    signaturePoishFontObj: any;
    PolishCharsData: any;
    signFontColor: any;
    showPolishInitialDiv: boolean = false;
    public filterCheckListInitials: EventEmitter<any> = new EventEmitter();
    resourceKeys = ResourceKeys;
    public isInitialsPopupClosed: EventEmitter<any> = new EventEmitter();
    webOrMobile:any = 'web';
    canvas:any;

    constructor(
        private renderer2: Renderer2,
        private elementRef: ElementRef,
        private toastr: ToastrService,
        public activeModal: NgbActiveModal,
        private translationsService: TranslationsService,
        private signerLandingService: SignerLandingService,
    ) {
        this.setupResizeListener();
    }

    ngOnInit(): void {
        if ((/android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
            this.webOrMobile = 'mobile';
        }
        this.signControlObj = this;
        this.showLoader = false;
        this.signatureFontObj = [
            { id: 1, 'fontFamily': 'HomemadeApple' }, { id: 2, 'fontFamily': 'DawningofaNewDay' }, { id: 3, 'fontFamily': 'Kristi' }, { id: 4, 'fontFamily': 'LaBelleAurore' },
            { id: 5, 'fontFamily': 'OvertheRainbow' }, { id: 6, 'fontFamily': 'Brush' }
        ]

        this.signaturePoishFontObj = [
            { id: 1, 'fontFamily': 'DancingScript' }, { id: 2, 'fontFamily': 'Parisienne' }, { id: 3, 'fontFamily': 'Allison' }, { id: 4, 'fontFamily': 'LaBelleAurorePolish' },
            { id: 5, 'fontFamily': 'OvertheRainbowPolish' }, { id: 6, 'fontFamily': 'GreatVibes' }
        ]

        this.PolishCharsData = ['ę', 'ą', 'ś', 'ć', 'ź', 'ż', 'ł'];
        this.initialsContainer = true;

        let documentControlsResp: any = localStorage.getItem("documentControlsResponse");
        this.documentControlsResponse = JSON.parse(documentControlsResp);

        let envelopeInfoResp: any = localStorage.getItem("EnvelopeInfo");
        this.documentControlsResponse.EnvelopeInfo = JSON.parse(envelopeInfoResp);
        //let respLanguageInfo: any = localStorage.getItem("Language");
        //this.documentControlsResponse.Language = JSON.parse(respLanguageInfo);

        this.SignatureCaptureType = this.documentControlsResponse.EnvelopeInfo.SignatureCaptureType;
        this.SignatureCaptureHanddrawn = this.documentControlsResponse.EnvelopeInfo.SignatureCaptureHanddrawn;
        this.newInitsignatureTypeDisplay = "block";
        this.initialsContainer = this.SignatureCaptureType;

        let strogaeinitialInputText: any = localStorage.getItem("initialInputText");
        let storageinitialText: any = localStorage.getItem("initialText");

        if (strogaeinitialInputText != "" && strogaeinitialInputText != null) {
            this.initialsInputText = strogaeinitialInputText;
            this.initialsText = storageinitialText;
        }
        else {
            let initResourceKeyArray: any = this.translationsService.getLanguageTranslationVal(Controls.Initial.toLowerCase()); //this.documentControlsResponse.Language.filter((c: any) => c.ResourceKeyID.toLowerCase() == Controls.Initial.toLowerCase());
            //if (initResourceKeyArray.length > 0) {
                this.initialsText = initResourceKeyArray;
            //}

            if (this.initialsText.toLowerCase() == ControlsName.Initial.toLowerCase()) {
                this.initialsInputText = "";
            }
        }

        let txtsignerProfileInitials: any = this.signControlObj.ClickToSignInitialText;
        if (this.isNullOrEmptyCheck(txtsignerProfileInitials)) {
            this.initialsText = txtsignerProfileInitials;
            this.initialsInputText = txtsignerProfileInitials;
        }

        this.onInitialsSubmit('preview');
    }

    ngAfterViewInit() {
        this.renderer2.setStyle(this.dvHandDrawn.nativeElement, 'display', 'none');
        this.signFontColor = this.signControlObj.dataFontColor;
        if (this.signFontColor == null || this.signFontColor == ""){
            this.signFontColor = "#000000";
        }

        if (this.canvasEl) {
            this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
            this.signaturePad.penColor = this.signFontColor;
            this.signaturePad.clear();
        }

        this.setFontTypeLabelChecked();
        //by default show signFontColor
        let signatureTextClass: any = document.getElementsByClassName("signatureText");
        Array.from(signatureTextClass).forEach((item: any) => {
            item!.style.color = this.signFontColor;
        });
    }

    setupResizeListener() {
        const throttledResizeHandler = throttle(this.resizeCanvas, 100);
        window.addEventListener('resize', throttledResizeHandler);
    }

    resizeCanvas() {
        this.canvas = document.getElementById('signature-pad');
        const ratio =  Math.max(window.devicePixelRatio || 1, 1);
        if(this.canvas){
            this.canvas.width = this.canvas.offsetWidth * ratio;
            this.canvas.height = this.canvas.offsetHeight * ratio;
            this.canvas.getContext("2d").scale(ratio, ratio);
        }
    }

    ngOnDestroy() {
        window.removeEventListener('resize', this.resizeCanvas);
    }

    getCanvasWidth() {
        let initialCanvasWidth:any = document.getElementById('initialCanvas');
        let width: any;
        if(this.webOrMobile === 'mobile'){
            width = initialCanvasWidth.offsetWidth - 2;
        } else {
            width = 465;
        }
        return width;
    }

    setFontTypeLabelChecked() {
        let slFont: any = localStorage.getItem('selectedInitialFont');
        if (this.isNullOrEmptyCheck(slFont)) {
            let slFontTypeLabelInput: any = document.getElementById(slFont);
            if (slFontTypeLabelInput != null) {
                slFontTypeLabelInput.setAttribute("checked", "checked");
            }
        }
        else {
            if (!this.showPolishInitialDiv) {
                let slFontTypeLabelInput: any = document.getElementById("HomemadeApple");
                if (slFontTypeLabelInput != null) {
                    slFontTypeLabelInput.setAttribute("checked", "checked");
                }
                localStorage.setItem('selectedInitialFont', "HomemadeApple");
            }
            else if (this.showPolishInitialDiv) {
                let slFontTypeLabelInput: any = document.getElementById("DancingScript");
                if (slFontTypeLabelInput != null) {
                    slFontTypeLabelInput.setAttribute("checked", "checked");
                }
                localStorage.setItem('selectedInitialFont', "DancingScript");
            }
        }
    }

    startDrawing(event: Event) {
        // console.log(event);
        // works in device not in browser
    }

    moved(event: Event) {
        // works in device not in browser
    }

    clearPad() {
        if (this.signaturePad) {
            this.signaturePad.clear();
            let imgProfileInitialsDrawPreview: any = document.getElementById('imgProfileInitialsDrawPreview');
            if (imgProfileInitialsDrawPreview != undefined && imgProfileInitialsDrawPreview != null) {
                imgProfileInitialsDrawPreview.setAttribute('src', '');
            }
        }
        this.initialsText = '';
        this.initialsInputText = '';
    }

    showToaster(msg: string, errorType: any) {
        if (errorType === "Error") {
            this.toastr.error(msg, errorType, {
                timeOut: 2000
            });
        }
        else if (errorType === "Warning") {
            this.toastr.warning(msg, errorType, {
                timeOut: 2000
            });
        }
    }

    isNullOrEmptyCheck(stringValueFlag: any) {
        let boolFlag = false;
        if (typeof stringValueFlag != 'undefined' && stringValueFlag !== '' && stringValueFlag !== null && stringValueFlag !== 'false' && typeof stringValueFlag != undefined) {
            boolFlag = true;
        }
        return boolFlag;
    }

    getSelectedTab() {
        this.selectedRadioBtn = 'signerSignatureType';
        if (document.getElementById('select-type-tab')!.classList.contains('active')) {
            this.selectedRadioBtn = 'signerSignatureType';
        } else if (document.getElementById('select-draw-tab')!.classList.contains('active')) {
            this.selectedRadioBtn = 'signerInitialsHandDrawn';
        }
        return this.selectedRadioBtn;
    }

    // validating the signature type or handDrawn.
    onInitialsSubmit(onInitialsSubmitOption: any) {
        this.getSelectedTab();
        let isSignControlIdExists: any = this.signControlObj.signControlId;
        if (this.isNullOrEmptyCheck(isSignControlIdExists)) {
            if (this.selectedRadioBtn === 'signerSignatureType') {
                if (this.initialsInputText.trim() !== '') {
                    var count = this.initialsInputText.trim().length;
                    if (count == 0) {
                        this.showToaster('Initials is required.', 'Warning');
                    }
                    else if (count > 4) {
                        this.showToaster('Only 4 characters allowed for initial.', 'Warning');
                    }
                    else {
                        localStorage.setItem('InitialTypeID', SignatureType.Auto);
                        let userfont = localStorage.getItem('selectedInitialFont');
                        this.setFontTypeLabelChecked();
                        if(userfont == null){
                            if(this.showPolishInitialDiv){
                                userfont = 'DancingScript';
                            } else {
                                userfont = 'HomemadeApple';
                            }
                        }
                        if ((userfont == 'HomemadeApple1' || userfont == 'DawningofaNewDay1' || userfont == 'Kristi1' || userfont == 'LaBelleAurore1' || userfont == 'OvertheRainbow1' || userfont == 'Brush1')) {
                            userfont = userfont.substring(0, userfont.length - 1);
                        }
                        else if (userfont == "DancingScriptInitial") {
                            userfont = "DancingScript";
                        } else if (userfont == "ParisienneInitial") {
                            userfont = "Parisienne";
                        } else if (userfont == "AllisonInitial") {
                            userfont = "Allison";
                        } else if (userfont == "GreatVibesInitial") {
                            userfont = "GreatVibes";
                        } else if (userfont == "LaBelleAurorePolishInitial") {
                            userfont = "LaBelleAurore";
                        } else if (userfont == "LaBelleAurorePolishInitial") {
                            userfont = "LaBelleAurore";
                        } else if (userfont == "OvertheRainbowPolishInitial") {
                            userfont = "OvertheRainbow";
                        }

                        let height = "0";// $(".signatureControl").height();
                        let width = "0"; // $(".signatureControl").width();
                        let fontsize = "13px"; // $('.signatureType :checked').css("font-size");

                        //Call API ConvertTextToSignImage
                        let requestObj = {
                            text: this.initialsText.trim(),
                            font: userfont,
                            fontsize: fontsize,
                            fontColor: this.signFontColor ? this.signFontColor : "#000000",
                            height: height,
                            width: width
                        };

                        let url = CommonConstants.ConvertTextToSignImage;
                        this.signerLandingService.convertTextToSignImage(url, requestObj).subscribe(
                            (resp: any) => {
                                if (resp && resp.StatusCode == 200) {
                                    let ctrlItem: any = document.getElementById(this.signControlObj.signControlId);
                                    let requiredLength = ctrlItem.getElementsByClassName("requiredControls").length;
                                    if (requiredLength > 0) {
                                        // let allRequiredInitialsControls: any = this.getAllDocumentInitialsControls();
                                        this.addInitialTypeImageSrc(ctrlItem, resp, this.initialsText.trim(), onInitialsSubmitOption);
                                    }
                                    else {
                                        this.addInitialTypeImageSrc(ctrlItem, resp, this.initialsText.trim(), onInitialsSubmitOption);
                                    }
                                    if (onInitialsSubmitOption === 'Ok') {
                                        this.filterCheckListInitials.next('true');
                                        this.activeModal.dismiss('Cross click');
                                    }
                                }
                            });
                    }
                }
                else {
                    if (onInitialsSubmitOption === 'Ok') {
                        this.showToaster('Type your initials.', 'Warning');
                    }
                }
            }
            else if (this.selectedRadioBtn === 'signerInitialsHandDrawn') {
                if (this.signaturePad && this.signaturePad.isEmpty()) {
                    if (onInitialsSubmitOption === 'Ok') {
                        this.showToaster('Initials is required.', 'Warning');
                    }
                }
                else {
                    if (this.signaturePad) {
                        localStorage.setItem('InitialTypeID', SignatureType.Hand);
                        const base64Data = this.signaturePad.toDataURL();
                        this.signatureImg = base64Data;
                        let ctrlItem: any = document.getElementById(this.signControlObj.signControlId);
                        this.addInitialHandDrawnImageSrc(ctrlItem, this.signatureImg, this.initialsText.trim(), onInitialsSubmitOption);
                        if (onInitialsSubmitOption === 'Ok') {
                            this.filterCheckListInitials.next('true');
                            this.activeModal.dismiss('Cross click');
                        }
                    }
                }
            }
        }
        else {
            if (this.selectedRadioBtn === 'signerSignatureType') {
                if (this.initialsInputText.trim() !== '') {
                    var count = this.initialsInputText.trim().length;
                    if (count == 0) {
                        this.showToaster('Initials is required.', 'Warning');
                    }
                    else if (count > 4) {
                        this.showToaster('Only 4 characters allowed for initial.', 'Warning');
                    }
                    else {
                        localStorage.setItem('InitialTypeID', SignatureType.Auto);

                        let userfont = localStorage.getItem('selectedInitialFont');
                        this.setFontTypeLabelChecked();
                        if(userfont == null){
                            if(this.showPolishInitialDiv){
                                userfont = 'DancingScript';
                            } else {
                                userfont = 'HomemadeApple';
                            }
                        }
                        if ((userfont == 'HomemadeApple1' || userfont == 'DawningofaNewDay1' || userfont == 'Kristi1' || userfont == 'LaBelleAurore1' || userfont == 'OvertheRainbow1' || userfont == 'Brush1')) {
                            userfont = userfont.substring(0, userfont.length - 1);
                        }
                        else if (userfont == "DancingScriptInitial") {
                            userfont = "DancingScript";
                        } else if (userfont == "ParisienneInitial") {
                            userfont = "Parisienne";
                        } else if (userfont == "AllisonInitial") {
                            userfont = "Allison";
                        } else if (userfont == "GreatVibesInitial") {
                            userfont = "GreatVibes";
                        } else if (userfont == "LaBelleAurorePolishInitial") {
                            userfont = "LaBelleAurore";
                        } else if (userfont == "LaBelleAurorePolishInitial") {
                            userfont = "LaBelleAurore";
                        } else if (userfont == "OvertheRainbowPolishInitial") {
                            userfont = "OvertheRainbow";
                        }

                        let height = "0";// $(".signatureControl").height();
                        let width = "0"; // $(".signatureControl").width();
                        let fontsize = "13px"; // $('.signatureType :checked').css("font-size");

                        //Call API ConvertTextToSignImage
                        let requestObj = {
                            text: this.initialsText.trim(),
                            font: userfont,
                            fontsize: fontsize,
                            fontColor: this.signFontColor ? this.signFontColor : "#000000",
                            height: height,
                            width: width
                        };

                        let url = CommonConstants.ConvertTextToSignImage;
                        this.signerLandingService.convertTextToSignImage(url, requestObj).subscribe(
                            (resp: any) => {
                                if (resp && resp.StatusCode == 200) {
                                    //imgProfNewInitials
                                    let imgProfNewInitials: any = document.getElementById("imgProfileInitialsPreview");
                                    localStorage.setItem('imgProfileInitialsSrc', resp.ResponseConvertTextToSignImage.imgsrc);
                                    if (imgProfNewInitials != undefined && imgProfNewInitials != null) {
                                        imgProfNewInitials.setAttribute("src", resp.ResponseConvertTextToSignImage.imgsrc);
                                    }
                                    let txtsignerProfileInitials: any = document.getElementById("txtsignerProfileInitials");
                                    txtsignerProfileInitials.value = this.initialsText.trim();

                                    if (onInitialsSubmitOption === 'Ok') {
                                        let imgProfileSignature: any = document.getElementById("imgProfNewInitials");
                                        if (localStorage.getItem('imgProfileInitialsSrc') == null || localStorage.getItem('imgProfileInitialsSrc') == undefined) {
                                            let url = CommonConstants.ConvertTextToSignImage;
                                            this.signerLandingService.convertTextToSignImage(url, requestObj).subscribe(
                                                (resp: any) => {
                                                    if (resp && resp.StatusCode == 200) {
                                                        imgProfileSignature.setAttribute("src", resp.ResponseConvertTextToSignImage.imgsrc);
                                                        let txtsignerProfileInitials: any = document.getElementById("txtsignerProfileInitials");
                                                        txtsignerProfileInitials.value = this.initialsText.trim();
                                                    }
                                                });
                                        }
                                        else{
                                            if (imgProfileSignature != undefined && imgProfileSignature != null) {
                                                imgProfileSignature.setAttribute("src", localStorage.getItem('imgProfileInitialsSrc'));
                                            }
                                        }
                                        this.activeModal.dismiss('Cross click');
                                    }
                                }
                            });
                    }
                }
                else {
                    if (onInitialsSubmitOption === 'Ok') {
                        this.showToaster('Type your initials.', 'Warning');
                    }
                }
            }
            else if (this.selectedRadioBtn === 'signerInitialsHandDrawn') {
                if (this.signaturePad && this.signaturePad.isEmpty()) {
                    if (onInitialsSubmitOption === 'Ok') {
                        this.showToaster('Initials is required.', 'Warning');
                    }
                } else {
                    if (this.signaturePad) {
                        localStorage.setItem('InitialTypeID', SignatureType.Hand);
                        const base64Data = this.signaturePad.toDataURL();
                        this.signatureImg = base64Data;
                        if (onInitialsSubmitOption !== 'Ok') {
                            let imgProfNewInitials: any = document.getElementById("imgProfileInitialsDrawPreview");
                            if (imgProfNewInitials != undefined && imgProfNewInitials != null) {
                                imgProfNewInitials.setAttribute("src", this.signatureImg);
                            }
                        } else {
                            let imgProfNewInitials: any = document.getElementById("imgProfNewInitials");
                            if (imgProfNewInitials != undefined && imgProfNewInitials != null) {
                                imgProfNewInitials.setAttribute("src", this.signatureImg);
                            }
                            this.activeModal.dismiss('Cross click');
                        }
                    }
                }
            }
        }
    }

    onItemChange(val: any) {
        this.selectedRadioBtn = val.target.value
        // console.log("onItemChange!", this.selectedRadioBtn);
    }

    onSignatureType() {
        if (this.signaturePad) {
            this.signaturePad.clear();
            document.getElementById('imgProfileInitialsDrawPreview')!.setAttribute('src', '');
        }

        // this.initialsText = '';
        // this.initialsInputText = '';

        let imgProfileInitialsPreview: any = document.getElementById('imgProfileInitialsPreview');
        if (imgProfileInitialsPreview != undefined && imgProfileInitialsPreview != null) {
            imgProfileInitialsPreview.setAttribute('src', '');
        }
    }

    onSignatureHandDrawn() {
        //  console.log('onSignatureHandDrawn');
        let imgProfileInitialsDrawPreview: any = document.getElementById('imgProfileInitialsDrawPreview');
        if (imgProfileInitialsDrawPreview != undefined && imgProfileInitialsDrawPreview != null) {
            imgProfileInitialsDrawPreview.setAttribute('src', '');
        }
        // this.initialsText = '';
        // this.initialsInputText = '';

        let imgProfileInitialsPreview: any = document.getElementById('imgProfileInitialsPreview');
        if (imgProfileInitialsPreview != undefined && imgProfileInitialsPreview != null) {
            imgProfileInitialsPreview.setAttribute('src', '');
        }
    }

    onSignatureSelection(signSelect: any, event: any) {
        //  console.log('onSignatureSelection', signSelect);

        let selectedFont = this.signatureFontObj[signSelect].fontFamily;
        let existsFont: any = localStorage.getItem('selectedInitialFont');
        let slFontTypeLabelInput: any = document.getElementsByClassName(existsFont);
        if (slFontTypeLabelInput != null) {
            if (slFontTypeLabelInput[0]) {
                slFontTypeLabelInput[0].children[0].removeAttribute('checked');
            }
        }

        localStorage.setItem('selectedInitialFont', selectedFont);
        let checkInputValue: any = (<HTMLInputElement>document.getElementById('defaultInitialsName')).value;
        if (checkInputValue !== '') {
            this.onInitialsSubmit('preview');
        } else {
            this.setFontTypeLabelChecked();
            //this.showToaster('Type your initials.', 'Warning');
        }
        event.preventDefault();
    }

    UpdateInitialText(event: any) {
        let inputTxtVal = event.target.value;
        this.initialsText = event.target.value;

        if (event.target.value == "") {
            this.initialsText = ControlsName.Initial;
        }
        else {
            this.initialsText = this.initialsText;
            this.initialsInputText = this.initialsInputText;
        }

        if (this.initialsText != "" && this.initialsText != "Int.") {
            let signTypeId: any = localStorage.getItem('selectedInitialFont');
            let polishcharcount = this.checkPolishCharchters('Initial', signTypeId);
            if (polishcharcount == 1) {
                this.showPolishInitialDiv = true;
            }
            else {
                this.showPolishInitialDiv = false;
            }
            this.updatePolishSignatureFont(signTypeId, polishcharcount, 'Initial');
        }
        else {
            let imgProfileSignature: any = document.getElementById("imgProfileInitialsPreview");
            if (imgProfileSignature != undefined && imgProfileSignature != null) {
                imgProfileSignature.setAttribute("src", '');
            }
        }

        this.signFontColor = this.signControlObj.dataFontColor;
        if (this.signFontColor == null || this.signFontColor == "") this.signFontColor = "#000000";
        let signatureTextClass: any = document.getElementsByClassName("signatureText");
        Array.from(signatureTextClass).forEach((item: any) => {
            item!.style.color = this.signFontColor;
        });

        this.onInitialsSubmit('preview');
    }

    checkPolishCharchters(Type: string, signTypeId: any) {
        let polishcharexist = 0;
        if (Type == "Initial") {
            if (signTypeId != "LaBelleAurore1" || signTypeId != "OvertheRainbow1") {
                let testdata = this.initialsText;
                let polishCharArray = this.PolishCharsData.filter((c: any) => testdata.indexOf(c) > -1);
                if (polishCharArray.length > 0) {
                    polishcharexist = 1;
                }
            }
        }
        return polishcharexist;
    }

    updatePolishSignatureFont(SignerTypeId: any, polishcharcount: any, Type: any) {
        var signaturePolishFont = Type == "Signature" ? { "DancingScript": "DancingScript", "Allura": "Allura", "GreatVibes": "GreatVibes", "Allison": "Allison", "Parisienne": "Parisienne", "Sacramento": "Sacramento", "OvertheRainbowPolish": "OvertheRainbowPolish", "LaBelleAurorePolish": "LaBelleAurorePolish" } :
            { "DancingScript": "DancingScriptInitial", "GreatVibes": "GreatVibesInitial", "Allison": "AllisonInitial", "Parisienne": "ParisienneInitial", "OvertheRainbowPolish": "OvertheRainbowPolishInitial", "LaBelleAurorePolish": "LaBelleAurorePolishInitial" };

        var SignatureNormalFont = Type == "Signature" ? { "HomemadeApple": "HomemadeApple", "DawningofaNewDay": "DawningofaNewDay", "Kristi": "Kristi", "LaBelleAurore": "LaBelleAurore", "OvertheRainbow": "OvertheRainbow", "Brush": "Brush" } :
            { "HomemadeApple": "HomemadeApple1", "DawningofaNewDay": "DawningofaNewDay1", "Kristi": "Kristi1", "LaBelleAurore": "LaBelleAurore1", "OvertheRainbow": "OvertheRainbow1", "Brush": "Brush1" };

        if (Type == "Initial") {
            switch (SignerTypeId) {
                case SignatureNormalFont.HomemadeApple:
                case signaturePolishFont.DancingScript:
                    let DancingScript: any = document.getElementById("DancingScript");
                    let HomemadeApple: any = document.getElementById("HomemadeApple");
                    polishcharcount == 1 ? DancingScript.checked = true : HomemadeApple.checked = true;
                    break;
                case SignatureNormalFont.DawningofaNewDay:
                case signaturePolishFont.Parisienne:
                    let DawningofaNewDay: any = document.getElementById("DawningofaNewDay");
                    let Parisienne: any = document.getElementById("Parisienne");
                    polishcharcount == 1 ? Parisienne.checked = true : DawningofaNewDay.checked = true;
                    break;
                case SignatureNormalFont.Kristi:
                case signaturePolishFont.Allison:
                    let Kristi: any = document.getElementById("Kristi");
                    let Allison: any = document.getElementById("Allison");
                    polishcharcount == 1 ? Allison.checked = true : Kristi.checked = true;
                    break;
                case SignatureNormalFont.LaBelleAurore:
                case signaturePolishFont.LaBelleAurorePolish:
                    let LaBelleAurore: any = document.getElementById("LaBelleAurore");
                    let LaBelleAurorePolish: any = document.getElementById("LaBelleAurorePolish");
                    polishcharcount == 1 ? LaBelleAurorePolish.checked = true : LaBelleAurore.checked = true;
                    break;
                case SignatureNormalFont.OvertheRainbow:
                case signaturePolishFont.OvertheRainbowPolish:
                    let OvertheRainbow: any = document.getElementById("OvertheRainbow");
                    let OvertheRainbowPolish: any = document.getElementById("OvertheRainbowPolish");
                    polishcharcount == 1 ? OvertheRainbowPolish.checked = true : OvertheRainbow.checked = true;
                    break;
                case SignatureNormalFont.Brush:
                case signaturePolishFont.GreatVibes:
                    let GreatVibes: any = document.getElementById("GreatVibes");
                    let Brush: any = document.getElementById("Brush");
                    polishcharcount == 1 ? GreatVibes.checked = true : Brush.checked = true;
                    break;
            }
        }
    }

    getAllDocumentInitialsControls() {
        let controls: any = [];
        Array.from(document.getElementsByClassName("newInitialcommonSignatureControl") as HTMLCollectionOf<HTMLElement>)
            .forEach((item: any) => {
                if (item.attributes["data-ctntype"].value == "NewInitials" && item.attributes["data-rq"].value == "true") {
                    controls.push(item);
                }
            });
        return controls;
    }

    addInitialTypeImageSrc(initialCtrl: any, resp: any, initialsText: any, previewStr: any) {
        if (previewStr !== 'Ok') {
            let imgProfileSignature: any = document.getElementById("imgProfileInitialsPreview");
            if (imgProfileSignature != undefined && imgProfileSignature != null) {
                imgProfileSignature.setAttribute("src", resp.ResponseConvertTextToSignImage.imgsrc);
            }
        }
        else {
            let ctrlId = initialCtrl.attributes["id"].value;
            let width = window.getComputedStyle(initialCtrl).width;
            let height = window.getComputedStyle(initialCtrl).height;
            let heightForSignImage = parseInt(height) - 2;
            let sigImageWidth = resp.ResponseConvertTextToSignImage.width * (heightForSignImage / resp.ResponseConvertTextToSignImage.height);
            let sigControlWidth = parseInt(width);

            this.elementVar = document.getElementById("control_" + ctrlId);
            this.elementVar.setAttribute("src", resp.ResponseConvertTextToSignImage.imgsrc);
            this.elementVar.setAttribute("data-sign-text", initialsText);
            localStorage.setItem("data-initials-text", initialsText);
            this.elementVar.style['background'] = "none";
            if (sigImageWidth > sigControlWidth) {
                let ratio = sigControlWidth / sigImageWidth,
                    newWidth = (sigImageWidth * ratio),
                    newHeight = (heightForSignImage * ratio);

                this.elementVar.style['width'] = newWidth + "px";
                this.elementVar.style['height'] = newHeight + "px";
            }
            else {
                this.elementVar.style['width'] = "auto";
                this.elementVar.style['height'] = heightForSignImage + "px";
            }

            initialCtrl.style['background'] = "none";

            initialCtrl.getElementsByClassName("newInitialsignature")[0].style['border'] = "1px solid #ddd";
            initialCtrl.getElementsByClassName("newInitialsignature")[0].style['background'] = "none";
            initialCtrl.getElementsByClassName("additionalInfo")[0].classList.add("filledControls");
            initialCtrl.getElementsByClassName("newInitialsignature")[0].classList.add("initialfilledControls");
            // initialCtrl.getElementsByClassName("newInitialsignature")[0].style['border'] = "none !important";

            localStorage.setItem("initialInputText", initialsText);
            localStorage.setItem("initialText", initialsText);

            // this.signControlObj.checkListData.forEach((element: any) => {
            //     if (element.ControlsData != null && element.ControlsData.length > 0) {
            //         element.ControlsData.forEach((ele: any) => {
            //             if (ele.ControlName == "NewInitials") {
            //                 if (ele.ControlHtmlID === ctrlId) {
            //                     ele.isControlFiled = true;
            //                 }
            //             }
            //         })
            //     }
            // });

            let currentLblControl: any = document.getElementById("label_" + ctrlId);
            let currentLblIconControl: any = document.getElementById("iicon_" + ctrlId);
            currentLblControl.classList.remove("requried-color", "requried-color1", "requried-icon");
            currentLblControl.classList.add("success-color");
            currentLblControl.classList.add("success-icon");
            currentLblIconControl.classList.remove("requried-icon");
            currentLblIconControl.classList.add("success-icon");
            this.activeModal.dismiss('Cross click');
        }
    }

    addInitialHandDrawnImageSrc(initialCtrl: any, imageSrc: any, initialsText: any, previewStr: any) {
        if (previewStr !== 'Ok') {
            let imgProfileInitialsDrawPreview: any = document.getElementById("imgProfileInitialsDrawPreview");
            if (imgProfileInitialsDrawPreview != undefined && imgProfileInitialsDrawPreview != null) {
                imgProfileInitialsDrawPreview.setAttribute("src", imageSrc);
            }
        }
        else {
            let ctrlId = initialCtrl.attributes["id"].value;
            let width = window.getComputedStyle(initialCtrl).width;
            let height = window.getComputedStyle(initialCtrl).height;
            let heightForSignImage = parseInt(height) - 2;
            let sigControlWidth = parseInt(width) - 2;

            this.elementVar = document.getElementById("control_" + ctrlId);
            this.elementVar.setAttribute("src", imageSrc);
            this.elementVar.setAttribute("data-sign-text", initialsText);
            localStorage.setItem("data-initials-text", initialsText);
            this.elementVar.style['width'] = sigControlWidth + "px";
            this.elementVar.style['height'] = heightForSignImage + "px";

            initialCtrl.style['background'] = "none";
            initialCtrl.getElementsByClassName("newInitialsignature")[0].style['border'] = "1px solid #ddd";
            initialCtrl.getElementsByClassName("newInitialsignature")[0].style['background'] = "none";
            initialCtrl.getElementsByClassName("additionalInfo")[0].classList.add("filledControls");
            initialCtrl.getElementsByClassName("newInitialsignature")[0].classList.add("initialfilledControls");
            // this.signControlObj.checkListData.forEach((element: any) => {
            //     if (element.ControlsData != null && element.ControlsData.length > 0) {
            //         element.ControlsData.forEach((ele: any) => {
            //             if (ele.ControlName == "NewInitials") {
            //                 if (ele.ControlHtmlID === ctrlId) {
            //                     ele.isControlFiled = true;
            //                 }
            //             }
            //         })
            //     }
            // });
            let currentLblControl: any = document.getElementById("label_" + ctrlId);
            let currentLblIconControl: any = document.getElementById("iicon_" + ctrlId);
            currentLblControl.classList.remove("requried-color", "requried-color1", "requried-icon");
            currentLblControl.classList.add("success-color");
            currentLblControl.classList.add("success-icon");
            currentLblIconControl.classList.remove("requried-icon");
            currentLblIconControl.classList.add("success-icon");
            localStorage.setItem("initialInputText", '');
            localStorage.setItem("initialText", '');
            this.activeModal.dismiss('Cross click');
        }
    }
    getLanguageTranslationValue(resourcekeyId: any) {
        return this.translationsService.getLanguageTranslationVal(resourcekeyId);
    }

    onClosePopup(){
        this.isInitialsPopupClosed.next('true');
        this.activeModal.dismiss('Cross click');
    }
}
