import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonConstants, MessageTypes, ResourceKeys, RouteUrls } from 'src/app/constants/common-constants';
import { SignerLandingService } from 'src/app/services/signer-landing.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { AuthenticateService } from '../../services/authenticate-signer.service';
import { TranslationsService } from 'src/app/services/translations.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-static-password-window',
  templateUrl: './static-password-window.component.html',
  styleUrls: ['./static-password-window.component.scss']
})
export class StaticPasswordWindowComponent implements OnInit {
  showLoader: boolean = false;
  documentControlsResponse: any;
  showPassword: boolean = false;
  htmlFooterString: string = '';
  IsPasswordMailToSigner: boolean = false;
  isStaticPasswordWindow: Boolean = false;
  ePasswordModalObject: any;
  resourceKeys = ResourceKeys;

  constructor(private router: Router, private signerLandingService: SignerLandingService, private translationsService: TranslationsService,
    private toastr: ToastrService, private commonService: CommonService, private authenticateService: AuthenticateService, private titleService:Title) { }

  ngOnInit(): void {
    this.titleService.setTitle("RSign | Password");
    this.htmlFooterString = '<p> ' +
      '<a href="https://rpost.com/legal-notices/terms-and-conditions" target="_blank" class="footerLinks">General Terms & Legal Notices</a>: Click to view service level terms, conditions, privacy, ' +
      '<a class="footerLinks" href="https://rpost.com/legal-notices/legal-and-patent-notice" target="_blank"> patent, trademark</a>, and other service and legal notices. ' +
      '</p>';

    let isPasswordMailToSigner = localStorage.getItem("isPasswordMailToSigner");
    this.IsPasswordMailToSigner = Boolean(isPasswordMailToSigner);
    let ePasswordModal: any = localStorage.getItem("ePasswordModal");
    this.ePasswordModalObject = JSON.parse(ePasswordModal);
    console.log(this.ePasswordModalObject);
   localStorage.setItem("IsFromBotClick", "");
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  ValidatePassword(): void {
    let documentControlsResp: any = localStorage.getItem("documentControlsResponse");
    this.documentControlsResponse = JSON.parse(documentControlsResp);

    let envelopeInfoResp: any = localStorage.getItem("EnvelopeInfo");
    this.documentControlsResponse.EnvelopeInfo = JSON.parse(envelopeInfoResp);

    let isPwdRqdToOpen: any = this.ePasswordModalObject.IsPasswordRequiredToOpen; //false;
    let isFirstSigner: any = this.ePasswordModalObject.IsFirstSigner; //false;
    let senderEmail: string = this.documentControlsResponse.EnvelopeInfo.SenderEmail;
    localStorage.setItem("senderEmail", senderEmail);
    let prefill: string = this.documentControlsResponse.Prefill;
    localStorage.setItem("prefill", prefill);
    let CopyEmailId = localStorage.getItem("CopyMailId");
    let cultureInfo: string = '';
    if (this.documentControlsResponse.EnvelopeInfo.CultureInfo == "") cultureInfo = "en-us";
    else cultureInfo = this.documentControlsResponse.EnvelopeInfo.CultureInfo;

    let currentStatus = '';
    let IsStatic: boolean = this.ePasswordModalObject.IsStatic;

    let txtDocPasswordInput: any = document.getElementById("txtDocPassword");
    let txtDocPassword = txtDocPasswordInput.value.trim();

    if (txtDocPassword.length > 0 && IsStatic) {
      this.showLoader = true;
      let envelopeId = this.ePasswordModalObject.EnvelopeID;
      let recipientId = this.ePasswordModalObject.CurrentRecipientID;
      let currentRecEmailId: any = this.ePasswordModalObject.CurrentRecipientEmail;      
      let validatePwdUrl = CommonConstants.GetEnvelopeOrTemplateFields + "/" + envelopeId + "/" + recipientId;
      this.signerLandingService.getValidatePassword(validatePwdUrl).subscribe(
        (resp: any) => {
         // console.log(resp);
          if (resp && resp.StatusCode == 200 && resp.StatusMessage.toLowerCase() == "ok") {
            let strURLWithData: string = '';
            if (isPwdRqdToOpen) {
              let strURL = "view-document";
              if (currentStatus != null && currentStatus != '') {
                let encryptURL = CommonConstants.EncryptQueryParamString + '/TemplateID=' + envelopeId + '&CurrentStatus=' + currentStatus;
                this.signerLandingService.getEncryptQueryString(encryptURL).subscribe(
                  (encryptresp: any) => {
                  //  console.log('getEncryptQueryString', encryptresp);
                    strURLWithData = encryptresp.EnvryptedEncodedText;

                    this.comparePassword(resp.DecryptPassword, txtDocPassword, strURL, strURLWithData);
                  });
              }
              else {
                let encryptURL = CommonConstants.EncryptQueryParamString + '/TemplateID=' + envelopeId;
                this.signerLandingService.getEncryptQueryString(encryptURL).subscribe(
                  (encryptresp: any) => {
                  //  console.log('getEncryptQueryString', encryptresp);
                    strURLWithData = encryptresp.EnvryptedEncodedText;
                    this.comparePassword(resp.DecryptPassword, txtDocPassword, strURL, strURLWithData);
                  })
              }
            }
            else if (!isFirstSigner) {
              let strURL = "SignerLandingStaticTemplate";
              let encryptURL = CommonConstants.EncryptQueryParamString + '/EnvelopeId=' + envelopeId + '&RecipientId=' + recipientId + '&FirstSignerEmail=' + currentRecEmailId;
              this.signerLandingService.getEncryptQueryString(encryptURL).subscribe(
                (encryptresp: any) => {
                //  console.log('getEncryptQueryString', encryptresp);
                  strURLWithData = encryptresp.EnvryptedEncodedText;
                  this.comparePassword(resp.DecryptPassword, txtDocPassword, strURL, strURLWithData);
                });

            }
            else {
              let strURL = "static-template";
              let encryptURL = CommonConstants.EncryptQueryParamString + '/TemplateID=' + envelopeId + '&recipientID=' + recipientId + "&CurrentRecipientEmail=" + currentRecEmailId;
              this.signerLandingService.getEncryptQueryString(encryptURL).subscribe(
                (encryptresp: any) => {
                //  console.log('getEncryptQueryString', encryptresp);
                  strURLWithData = encryptresp.EnvryptedEncodedText;
                  this.comparePassword(resp.DecryptPassword, txtDocPassword, strURL, strURLWithData);
                })
            }
            this.showLoader = false;
          }
          else {
            this.showLoader = false;
            this.commonService.getReturnUrlDirection(RouteUrls.Info);
          }
        })
    }
    else {
      this.toastr.warning('Please enter the valid password.', 'Warning', { timeOut: 1500 });
      this.showLoader = false;
    }
  }

  comparePassword(decryptPassword: any, txtDocPassword: any, routeUrl: any, strURLWithData: any) {
    if (decryptPassword == txtDocPassword) {     
      sessionStorage.setItem("IsFromSignerPreLanding", "false");
      document.getElementById('formContent')!.style.display = 'none';
      document.getElementById('divSuccess')!.style.display = 'block';
      this.showLoader = true;
      setTimeout(() => {     
        if (routeUrl == "view-document") {
          this.commonService.getReturnUrlDirection(RouteUrls.ViewDocumentLanding);
          this.showLoader = false;
        }
        else if (routeUrl == "SignerLandingStaticTemplate") {
          sessionStorage.setItem("IsFromSignerPreLanding", "false");
          this.commonService.getReturnUrlDirection(RouteUrls.SignerLandingStaticTemplate);
          this.showLoader = false;
        }
        else if (routeUrl == "static-template" || routeUrl == "GetAllowMultipleSigner") {        
         this.showLoader = true;         
          let initializeMultiSignerStaticDocumentUrl = CommonConstants.InitializeMultiSignerStaticDocument;
          let paramsObj = { SigningUrl: strURLWithData };
          this.signerLandingService.getSignerSignDocumentData(initializeMultiSignerStaticDocumentUrl, paramsObj).subscribe(
            (resp: any) => {            
             this.showLoader = true;           
              if (resp && resp.StatusCode == 200 && resp.StatusMessage.toLowerCase() == "ok") {
                this.documentControlsResponse = resp;  
                
                localStorage.setItem("EnvelopeImageControlData", JSON.stringify(this.documentControlsResponse.EnvelopeImageControlData));                
                localStorage.setItem("CheckListData", JSON.stringify(this.documentControlsResponse.CheckListData));
                localStorage.setItem("Userdata", JSON.stringify(this.documentControlsResponse.userdata));     
                localStorage.setItem("EnvelopeInfo", JSON.stringify(this.documentControlsResponse.EnvelopeInfo));
                this.documentControlsResponse.EnvelopeImageControlData = null;
                this.documentControlsResponse.Language = null;
                this.documentControlsResponse.CheckListData = null;
                this.documentControlsResponse.userdata = null;
                this.documentControlsResponse.EnvelopeInfo = null;
                localStorage.setItem("documentControlsResponse", JSON.stringify(this.documentControlsResponse)); 
               
                this.commonService.getReturnUrlDirection(RouteUrls.StaticTemplate);       
                this.showLoader = false;              
              }
              else if (resp && resp.originalError && resp.originalError.error && (resp.originalError.error.StatusCode == 400 || resp.originalError.error.StatusCode == 204)) {
               // this.showLoader = false;
                let encryptedSender: string = resp.originalError.error.EncryptedSender;
               // localStorage.setItem("encryptedSender", encryptedSender);
                localStorage.setItem("DisplayMessage", resp.originalError.error.StatusMessage);
                localStorage.setItem("MessageType", "Warning");
                if (resp.originalError.error.ErrorAction == "Resend") {
                  this.commonService.getReturnUrlDirection(RouteUrls.Info);
                }
                else if (resp.originalError.error.ErrorAction == "Expire") {
                  resp.originalError.error.StatusMessage == "" ? localStorage.setItem("DisplayMessage", "This envelope is expired.") : localStorage.setItem("DisplayMessage", resp.originalError.error.StatusMessage);
                  this.commonService.getReturnUrlDirection(RouteUrls.Info);
                }
                else if (resp.originalError.error.ErrorAction == "EnvelopeAccepted" || resp.originalError.error.ErrorAction == "ContactSender") {
                  //resp.originalError.error.StatusMessage == "" ? localStorage.setItem("DisplayMessage", "This document has already been signed and returned to the sender.") : localStorage.setItem("DisplayMessage", resp.originalError.error.StatusMessage);
                  localStorage.setItem("DisplayMessage", "This document has already been signed and returned to the sender.");
                  localStorage.setItem("MessageType", MessageTypes.Success);
                  this.commonService.getReturnUrlDirection(RouteUrls.Info);
                }
                else {
                    if( resp.originalError.error.StatusMessage == "" ){
                      localStorage.setItem("MessageType", "Error");
                      localStorage.setItem("DisplayMessage", "An Application error occurred.")
                      localStorage.setItem("IsApplicationError", "true");
                      localStorage.setItem("PageName", "InitializeMultiSigner");
                      localStorage.setItem("EnvelopeID", this.ePasswordModalObject.EnvelopeID);
                      localStorage.setItem("EmailId", this.ePasswordModalObject.CurrentRecipientEmail);
                    }
                    else{
                      localStorage.setItem("DisplayMessage", resp.originalError.error.StatusMessage);
                    } 

                  this.commonService.getReturnUrlDirection(RouteUrls.Info);
                }
      
                this.showLoader = false;
              }
              else {
                this.showLoader = false;
               // localStorage.setItem("encryptedSender", "");
                localStorage.setItem("MessageType", "Error");
                localStorage.setItem("IsApplicationError", "true");
                localStorage.setItem("PageName", "InitializeMultiSigner");
                localStorage.setItem("DisplayMessage", "An Application error occurred.");
                localStorage.setItem("EnvelopeID", this.ePasswordModalObject.EnvelopeID);
                localStorage.setItem("EmailId", this.ePasswordModalObject.CurrentRecipientEmail);
                this.commonService.getReturnUrlDirection(RouteUrls.Info);
              }
            });          
        }
      }, 50);
    }
    else {
      this.showLoader = false;
      this.toastr.warning('Password is invalid or not matched.', 'Warning', { timeOut: 1000 });
      let txtDocPasswordInput: any = document.getElementById("txtDocPassword");
      txtDocPasswordInput.value = '';
    }
  }

  resendPassword() {
    let currentRecipientEmail = localStorage.getItem("ProvidedUserEmail");
    let templateId = localStorage.getItem("EnvelopeID");
    let paramsObj = {
      EmailId: currentRecipientEmail,
      envelopeID: templateId,
      DeliveryMode: this.ePasswordModalObject.DeliveryMode, 
      DialCode: this.ePasswordModalObject.DialCode, 
      MobileNumber: this.ePasswordModalObject.MobileNumber,
      CountryCode: this.ePasswordModalObject.CountryCode
    };

    this.showLoader = true;
    let reSendPasswordEmailUrl = CommonConstants.ReSendPasswordEmail;
    this.authenticateService.reSendPasswordEmail(reSendPasswordEmailUrl, paramsObj).subscribe(
      (resp: any) => {
        if (resp && resp.StatusCode == 200 && resp.StatusMessage.toLowerCase() == "ok") {
          this.showLoader = false;
          this.toastr.success(resp.Message, 'Success', { timeOut: 1000 });
        }
        else if (resp.originalError.error.StatusCode == 400) {
          this.showLoader = false;
          this.toastr.warning(resp.originalError.error.Message, 'Warning', { timeOut: 1000 });
        }
      })
  }
  getLanguageTranslationValue(resourcekeyId: any) {
    return this.translationsService.getLanguageTranslationVal(resourcekeyId);
  }
}
