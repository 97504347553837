<div>
    <!-- Modal Header -->
    <div class="modal-header bg-modal modalhead-padding">
        <h4 class="modal-title">{{getLanguageTranslationValue(resourceKeys.Decline)}}</h4>
        <div class="d-flex align-items-center">
            <span class="material-icons modal-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
             close
            </span>
        </div>
    </div>

    <!-- Modal body -->
    <div class="modal-body modal-bodybg modal-height">
        <div id="declineModalBody" class="">
            <input type="hidden" id="GlobalEnvelopeID" value="f925f2ac-5949-4747-9c74-1425a05bd5eb">
            <div class="declineTemplate">
                <form id="rejectForm" class="rejectForm1">
                    <div class="ReasonRadioButtons">
                        <div class="d-flex align-items-start res-flexstart">                           
                            <div>
                                <p>
                                    <span class="font-libre" style="font-weight: 600;">Caution:</span>
                                    If you select <span class="font-libre" style="font-weight: 600;">Decline</span>
                                    this document will be voided. This action cannot be undone.
                                </p>
                                <P>
                                To request changes to this document, please select <span class="font-libre" style="font-weight: 600;">close  </span>
                                and contact the sender.

                                <span *ngIf="ShowFinishLaterNavMenu">
                                You can exit signing and save the information you have entered by selecting the
                                <span class="font-libre" style="font-weight: 600;">Finish Later</span></span>
                                option in the menu.</P>
                            </div>
                        </div>
                        <label *ngIf="title" class="declineTitle font-16">{{title}}</label>
                        <div *ngIf="controlType !== 3">
                            <div *ngFor="let reason of reasonList">                              

                                <div class="radio" *ngIf="controlType === 1">
                                    <input id="radio_{{reason.ID}}" type="radio"
                                        (click)="onOtherCLick(otherTextArea, reason, 'radio')"
                                        name="DeclineReasonRadioGroup1" value="{{reason.ResponseText}}">
                                    <label for="radio_{{reason.ID}}" class="radio-label">{{reason.ResponseText}}</label>
                                </div>

                                <div class="checkbox" *ngIf="controlType === 2">
                                    <label class="ps-0" for="checkbox_{{reason.ID}}">
                                        <input type="checkbox" type="checkbox" id="checkbox_{{reason.ID}}"
                                            (click)="onOtherCLick(otherTextArea, reason, 'checkbox')"
                                            name="DeclineReasonRadioGroup1" value="{{reason.ResponseText}}">
                                        <span class="cr">
                                            <i class="cr-icon material-icons">check</i></span>
                                        <span class="font-14">{{reason.ResponseText}}</span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div #otherOptionRequired style="display: none;">
                            <div class="formErrorContent">This field is required*</div>
                            <div class="down-arrow"></div>
                        </div>
                        <div *ngIf="controlType === 3" class=" border-boxsize">
                            <textarea (input)="textAreaInput($event)" class="rejectText form-control" maxlength="250"
                                placeholder="Please explain the reason for declining this request"></textarea>
                        </div>
                        <div class="border-boxsize">
                            <textarea #otherTextArea (input)="textAreaInput($event)" class="rejectText form-control"
                                maxlength="250" placeholder="Please explain the reason for declining this request" style="display: none;"></textarea>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    </div>

    <!-- Modal footer -->
    <div class="modal-footer bg-modal modalfooter-padding">
        <button type="button" class="btn btncancel px-3 py-1" (click)="activeModal.dismiss('Cross click')"
            id="btnSignerCancel" style="height: 23px;border-radius: 4px;">{{getLanguageTranslationValue(resourceKeys.Cancel)}}</button>
        <button type="button" class="btn btn-default btn_accept button-29 ms-3" id="rejectEnvelopeBtn"
            (click)="getFinalValidation()">{{getLanguageTranslationValue(resourceKeys.Decline)}}</button>
    </div>
</div>

<div *ngIf="showLoader" class="loader">
</div>
