
<div>
    <!-- Modal Header -->
    <div class="modal-header bg-modal modalhead-padding">
        <h4 class="modal-title">Add Signature</h4>
        <div class="d-flex align-items-center">
            <span class="material-icons modal-close" aria-label="Close" (click)="onClosePopup('Close')">
                close
            </span>
        </div>
    </div>

    <!-- Modal body -->
    <div class="modal-body modal-bodybg">
        <div>
            <nav>
                <div class="nav nav-tabs modal-tabs mb-3 d-flex justify-content-between" id="nav-tab" role="tablist">
                    <div class="d-flex align-items-center">
                        <button class="nav-link active d-flex align-items-center" id="select-type-tab"
                            data-bs-toggle="tab" data-bs-target="#select-type" type="button" role="tab"
                            aria-controls="select-type" aria-selected="false" style="font-size: 14px;" title="Filter"
                            (click)="clearSignautePadAndUpload()">
                            Type
                        </button>

                        <button class="nav-link d-flex align-items-center" id="select-draw-tab" data-bs-toggle="tab"
                            data-bs-target="#select-draw" type="button" role="tab" aria-controls="select-draw"
                            aria-selected="true" style="font-size: 14px;" (click)="onSignatureDraw()">
                            Draw
                        </button>

                         <!-- <button class="nav-link d-flex align-items-center" id="select-upload-tab" data-bs-toggle="tab"
                            data-bs-target="#select-upload" type="button" role="tab" aria-controls="select-upload"
                            aria-selected="true" style="font-size: 14px;" (click)="onSignatureTypeAndUpload()">
                            Upload
                        </button> -->
                    </div>
                </div>
            </nav>
            <div class="tab-content border-top-0 border-bottom-0 bg-white w-100 h-100" id="nav-tabContent">
                <div style="overflow: hidden;padding-bottom: 7px;" class="tab-pane fade active show" id="select-type" role="tabpanel"
                    aria-labelledby="select-type-tab">
                    <div *ngIf="signatureContainer && !showPolishSignatureDiv" id="dvDefaultSignatureFonts">
                        <div style="margin-left: 5px;margin-right: 5px;" class="mb-3 mt-2 border-boxsize">
                            <label for="defaultSignerName" class="form-label">Type Name</label>
                            <!--  [ngStyle]="{'display':signatureCaptureTypeTextDisplay}" -->
                            <input type="text" [(ngModel)]="signatureInputText" class="form-control typeSignature"
                                placeholder="Type your name" id="defaultSignerName" value="" maxlength="50"
                                autocomplete="off" (keyup)="UpdateSignatureText($event)">
                        </div>
                        <p style="margin-bottom: 2px; margin-left: 5px;"> <label for="txtsignerProfileName" class="form-label">Select
                                Signature</label></p>
                        <ul class="signatureType" style="display :block;padding:0px;margin-right: 5px;">
                            <div class="d-flex align-items-center res-selfstart">
                                <li class="lifontchange HomemadeApple" (click)="onSignatureSelection(0, $event)">
                                    <input type="radio" id="HomemadeApple" name="signSelect" class="signatureControl">
                                    <label for="HomemadeApple" class="HomemadeApple"><span></span>
                                        <i *ngIf="signatureText.length === 0" class="signatureText">Signature</i>
                                        <i *ngIf="signatureText.length !== 0"
                                            class="signatureText">{{signatureText}}</i>
                                    </label>
                                </li>
                                <li class="lifontchange DawningofaNewDay" (click)="onSignatureSelection(1,$event)">
                                    <input type="radio" id="DawningofaNewDay" name="signSelect"
                                        class="signatureControl">
                                    <label for="DawningofaNewDay" class="DawningofaNewDay"><span></span>
                                        <i *ngIf="signatureText.length === 0" class="signatureText">Signature</i>
                                        <i *ngIf="signatureText.length !== 0"
                                            class="signatureText">{{signatureText}}</i>
                                    </label>
                                </li>
                                <li class="lifontchange Kristi" (click)="onSignatureSelection(2,$event)">
                                    <input type="radio" id="Kristi" name="signSelect" class="signatureControl">
                                    <label for="Kristi" class="Kristi"><span></span>
                                        <i *ngIf="signatureText.length === 0" class="signatureText">Signature</i>
                                        <i *ngIf="signatureText.length !== 0"
                                            class="signatureText">{{signatureText}}</i>
                                    </label>
                                </li>
                            </div>
                            <div class="d-flex align-items-center res-selfstart">
                                <li class="lifontchange LaBelleAurore" (click)="onSignatureSelection(3,$event)">
                                    <input type="radio" id="LaBelleAurore" name="signSelect" class="signatureControl">
                                    <label for="LaBelleAurore" class="LaBelleAurore"><span></span>
                                        <i *ngIf="signatureText.length === 0" class="signatureText">Signature</i>
                                        <i *ngIf="signatureText.length !== 0"
                                            class="signatureText">{{signatureText}}</i>
                                    </label>
                                </li>
                                <li class="lifontchange OvertheRainbow" (click)="onSignatureSelection(4,$event)">
                                    <input type="radio" id="OvertheRainbow" name="signSelect" class="signatureControl">
                                    <label for="OvertheRainbow" class="OvertheRainbow"><span></span>
                                        <i *ngIf="signatureText.length === 0" class="signatureText">Signature</i>
                                        <i *ngIf="signatureText.length !== 0"
                                            class="signatureText">{{signatureText}}</i>
                                    </label>
                                </li>
                                <li class="lifontchange Brush" (click)="onSignatureSelection(5,$event)">
                                    <input type="radio" id="Brush" name="signSelect" class="signatureControl">
                                    <label for="Brush" class="Brush"><span></span>
                                        <i *ngIf="signatureText.length === 0" class="signatureText">Signature</i>
                                        <i *ngIf="signatureText.length !== 0"
                                            class="signatureText">{{signatureText}}</i>
                                    </label>
                                </li>
                            </div>
                        </ul>
                        <div style="margin-left: 5px;margin-right: 5px;" class="form-group mt-3 res-mb10 border-boxsize">
                            <label for="txtsignerProfileName" class="form-label">Preview Signature</label>
                            <div class="img-frame p-2 ">
                                <div id="userSignControl" class="signaturewidget">
                                    <img class="img-responsive negative userSignature settingsPropertyOnChange h-60px"
                                        id="imgProfileSignaturePreview" data-is-change="False" src="">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="signatureContainer && showPolishSignatureDiv " id="dvPolishSignatureFonts">
                        <div class="mb-3 mt-3 border-boxsize">
                            <label for="defaultSignerName" class="form-label">Full Name</label>
                            <!--  [ngStyle]="{'display':signatureCaptureTypeTextDisplay}" -->
                            <input type="text" [(ngModel)]="signatureInputText" class="form-control typeSignature"
                                placeholder="Type your name" id="defaultSignerName" value="" maxlength="50"
                                (keyup)="UpdateSignatureText($event)">
                        </div>
                        <p style="margin-bottom: 2px;"> <label for="txtsignerProfileName" class="form-label">Select
                                Signature</label></p>
                        <ul class="signatureType" style="display :block;padding:0px;margin-right: 5px;">
                            <div class="d-flex align-items-center res-selfstart">
                                <li class="lifontchange DancingScript" (click)="onSignatureSelection(0,$event)">
                                    <input type="radio" id="DancingScript" name="signSelect" class="signatureControl">
                                    <label for="DancingScript" class="DancingScript"><span></span>
                                        <i *ngIf="signatureText.length === 0" class="signatureText">Signature</i>
                                        <i *ngIf="signatureText.length !== 0"
                                            class="signatureText">{{signatureText}}</i>
                                    </label>
                                </li>

                                <li class="lifontchange DawningofaNewDay" (click)="onSignatureSelection(1,$event)">
                                    <input type="radio" id="Parisienne" name="signSelect" class="signatureControl">
                                    <label for="Parisienne" class="Parisienne"><span></span>
                                        <i *ngIf="signatureText.length === 0" class="signatureText">Signature</i>
                                        <i *ngIf="signatureText.length !== 0"
                                            class="signatureText">{{signatureText}}</i>
                                    </label>
                                </li>
                                <li class="lifontchange Kristi" (click)="onSignatureSelection(2,$event)">
                                    <input type="radio" id="Allison" name="signSelect" class="signatureControl">
                                    <label for="Allison" class="Allison"><span></span>
                                        <i *ngIf="signatureText.length === 0" class="signatureText">Signature</i>
                                        <i *ngIf="signatureText.length !== 0"
                                            class="signatureText">{{signatureText}}</i>
                                    </label>
                                </li>
                            </div>
                            <div class="d-flex align-items-center res-selfstart">
                                <li class="lifontchange LaBelleAurore" (click)="onSignatureSelection(3,$event)">
                                    <input type="radio" id="LaBelleAurorePolish" name="signSelect"
                                        class="signatureControl">
                                    <label for="LaBelleAurorePolish" class="LaBelleAurorePolish"><span></span>
                                        <i *ngIf="signatureText.length === 0" class="signatureText">Signature</i>
                                        <i *ngIf="signatureText.length !== 0"
                                            class="signatureText">{{signatureText}}</i>
                                    </label>
                                </li>
                                <li class="lifontchange OvertheRainbow" (click)="onSignatureSelection(4,$event)">
                                    <input type="radio" id="OvertheRainbowPolish" name="signSelect"
                                        class="signatureControl">
                                    <label for="OvertheRainbowPolish" class="OvertheRainbowPolish"><span></span>
                                        <i *ngIf="signatureText.length === 0" class="signatureText">Signature</i>
                                        <i *ngIf="signatureText.length !== 0"
                                            class="signatureText">{{signatureText}}</i>
                                    </label>
                                </li>
                                <li class="lifontchange Brush" (click)="onSignatureSelection(5,$event)">
                                    <input type="radio" id="GreatVibes" name="signSelect" class="signatureControl">
                                    <label for="GreatVibes" class="GreatVibes"><span></span>
                                        <i *ngIf="signatureText.length === 0" class="signatureText">Signature</i>
                                        <i *ngIf="signatureText.length !== 0"
                                            class="signatureText">{{signatureText}}</i>
                                    </label>
                                </li>
                            </div>
                        </ul>
                        <!-- <div class="form-group mt-3 res-mb10 border-boxsize">
                            <label for="txtsignerProfileName" class="form-label">Preview Signature</label>
                            <div class="img-frame p-2  signaturewidget"> 
                                <div  class="signaturewidget">
                                    <img class="img-responsive negative userSignature settingsPropertyOnChange h-60px"
                                        id="imgProfileSignaturePreview" data-is-change="False" src="">
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>

                <div style="overflow: hidden;" class="tab-pane fade" id="select-draw" role="tabpanel" style="padding-bottom: 7px;"
                    aria-labelledby="select-draw-tab">
                    <!-- Right vside menu [hidden]="hidePagesViewSection"-->
                    <div style="margin-left: 5px; margin-right: 5px;">
                        <div class="d-flex justify-content-center  flex-column w-100">
                            <div class="d-flex flex-column justify-content-center" #dvHandDrawn>
                                <div class="d-flex align-items-center justify-content-between border-boxsize">
                                    <label class="form-label">Draw Signature</label>
                                    <div class="d-flex align-items-center">
                                        <a class="clearlink me-3" id="ankSignProfpopUploadSignature"
                                            (click)="clearPad()" style="color: #c3272e;">Clear</a>
                                        <a class="clearlink" id="ankSignProfpopUploadSignature"
                                            (click)="onSignatureSubmit('Save')">Preview</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div #dvHandDrawn id="signatureCanvas" class="d-flex justify-content-center">
                            <canvas #canvas width="{{getCanvasWidth()}}" height="115"></canvas>
                        </div>
                        <div class="form-group mt-3 res-mb10 border-boxsize">
                            <label for="txtsignerProfileName" class="form-label">Preview Signature</label>
                            <div class="img-frame p-2">
                                <div id="userSignControl" class="signaturewidget">
                                    <img class="img-responsive negative userSignature settingsPropertyOnChange"
                                        id="imgProfileSignatureDrawPreview" data-is-change="False" src=""
                                        style="height:72px !important;">
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div style="overflow: hidden; display: none;" class="tab-pane fade" id="select-upload" role="tabpanel"
                    aria-labelledby="select-upload-tab">
                    <div id="uploadSig" class="d-flex flex-column justify-content-center">
                        <div class="d-flex flex-column justify-content-center align-items-center">
                            <div class="d-flex justify-content-end  w-90">
                                <!-- <label class="font-14">Drag and drop signature file here</label> -->
                                <label class="font-14">Max file size 40 KB</label>
                            </div>
                            <div id="dvdragAndDrop"
                                class="dropdiv d-flex flex-column align-items-center justify-content-center"
                                [class.processing]="processing" (dragover)="onDragOver($event)"
                                (drop)="onDropSuccess($event)">
                                <!-- <img *ngIf="!files.length" src="../../../assets/images/upload2.png" alt="img"
                                    class="upload-img"  style="height: 70px; margin-left: -3px;"> -->

                                <img id="imgUploadsignature" src="../../../assets/images/upload2.png"
                                    style="height: 70px; margin-left: -3px;" />
                                <p *ngIf="!files.length" class="font-14" style="color: #707070;" id="dragdroplabel">Drag
                                    and drop signature
                                    file here</p>
                            </div>
                            <div class="filelist p-2 border mb-3" id="filelistInfo" style="display: none;">
                                <div class="w-100 d-flex align-items-center justify-content-between">
                                    <div class="d-flex align-items-center">
                                        <span class="material-icons iconstyle me-1 top-5">task</span>
                                    </div>
                                    <div class="d-flex flex-column w-100">
                                        <div class="d-flex align-items-start">
                                            <span id="uploadedFileName" class="font-13 truncate"></span>
                                        </div>
                                        <div class="progress progress-success progress-sm">
                                            <div class="progress-bar " role="progressbar"
                                                style="width: 100%; height: 5px;" aria-valuenow="25" aria-valuemin="0"
                                                aria-valuemax="100"></div>
                                        </div>
                                        <div class="d-flex justify-content-end">
                                            <span id="uploadedFileSize" class="font-12"></span>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center ">
                                        <a class="ms-2 upload-delete">
                                            <i class="material-icons font-size-20 cursor-pointer"
                                                (click)="onFileRemove()">
                                                disabled_by_default</i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-column align-items-center justify-content-center mb-3"
                            style="overflow: hidden;">
                            <p class="text-center font-15">Upload a picture of your signature(PNG,JPEG,JPG,BMP)</p>
                            <div class="d-flex align-items-center justify-content-center">
                                <div class=" d-flex align-items-center px-3 upload-btn" (click)="fileUpload.click()">
                                    <span class="material-icons me-1 font-20 res-font18 cursor-pointer"> desktop_windows
                                    </span>
                                    <label class="text-center font-14 res-font13 cursor-pointer">Local Machine</label>
                                </div>
                                <button type="button" class="btn btncancel drives-btn py-1 ms-3" id="btnSignerCancel"
                                    (click)="loadGoogleDrive()">
                                    <img src="../../../assets/images/google-drive.png" alt="Google Drive"
                                        title="Google Drive" class="drives-img">
                                </button>
                                <button type="button" class="btn btncancel drives-btn py-1 ms-3" id="btnSignerCancel"
                                    (click)="loadDropBox()">
                                    <img src="../../../assets/images/drop-box.png" alt="Dropbox" title="Dropbox"
                                        class="drives-img">
                                </button>
                                <button type="button" class="btn btncancel drives-btn py-1 ms-3" id="btnSignerCancel"
                                    (click)="loadOneDrive()">
                                    <img src="../../../assets/images/microsoft-drive.png" alt="Onedrive"
                                        title="Microsoft OneDrive" class="drives-img">
                                </button>
                            </div>
                            <div>
                                <input type="file" id="fileUploadInput" class="file-input"
                                    style="visibility: hidden;position: absolute" (change)="onChange($event)"
                                    #fileUpload>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal footer -->
    <div style="padding: 7px;" class="modal-footer bg-modal modalfooter-padding mt-70">
        <button type="button" class="btn btncancel px-3 py-1" id="btnSignerCancel" (click)="onClosePopup('Cancel')"
        style="height: 23px;border-radius: 4px;">Cancel</button>
        <button type="button" class="btn  btn_accept button-29 ms-3" id="btnAccept"
            (click)="onSignatureSubmit('Ok')">Ok</button>
    </div>
</div>

<div [ngStyle]="{'display':confirmationPopupStyle}" id="confirmationPopup" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document" style="max-width: 452px;">
        <div class="modal-content" style="box-shadow: rgb(14 30 37 / 60%) 0px 0px 10px;">

            <!-- Modal Header -->
            <div class="modal-header bg-modal modalhead-padding">
                <h4 class="modal-title"> Confirmation </h4>
                <div class="d-flex align-items-center">
                    <span class="material-icons modal-close" aria-label="Close" (click)="onCloseConfirmationPopup()">
                        close
                    </span>
                </div>
            </div>

            <div class="modal-body py-3">
                <div class="d-flex align-items-center justify-content-center">
                    <p class="font-14 d-flex flex-column font-w600 text-center mb-0">
                        <label class="">Uploaded file size is larger than maximum (40KB) supported size. Signature will
                            be compressed. Do you want to continue?</label>

                        <!-- <label class=""> Do you want to continue?</label> -->
                    </p>

                </div>
            </div>

            <!-- Modal footer -->
            <div class="modal-footer bg-modal modalfooter-padding">
                <button type="button" id="btnNoThanks" class="btn btncancel px-3 py-1"
                    (click)="onCloseConfirmationPopup()" style="height: 23px;border-radius: 4px;">Cancel</button>
                <button type="button" class="btn btn-default btn_accept button-29 ms-3 w-75p" id="rejectEnvelopeBtn"
                    (click)="onConfirmationPopup()">Ok </button>
            </div>


        </div>
    </div>
</div>

<div *ngIf="showLoader" class="loader">
</div>
<div [ngStyle]="{'display':uploadStyle}" class="loader" id="uploaderloader">
</div>