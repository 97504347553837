<!-- Modal Header -->
<div class="modal-header bg-modal modalhead-padding">
    <h4 class="modal-title"> {{getLanguageTranslationValue(resourceKeys.LoginInto)}} </h4>
    <div class="d-flex align-items-center">

        <span class="material-icons modal-close" aria-label="Close" (click)="onNoThanks()">
         close
        </span>
    </div>
    <!-- <button type="button" class="btn-close" data-bs-dismiss="modal"></button> -->
</div>

<!-- Modal body -->
<div class="modal-body modal-bodyb py-3">
    <div class="d-flex flex-column" style="width: 100%;">
        <div class="d-flex flex-wrap" style=" justify-content: center;
                   text-align: left;">
             <p *ngIf="downloadFinalContractBtn && !isDeclined" class="mb-3 font-15">
                {{getLanguageTranslationValue(resourceKeys.Lang_DownloadFinalSignedDoc)}}
            </p>
            <p *ngIf="downloadFinalContractBtn && isDeclined" class="mb-3 font-15">
               {{getLanguageTranslationValue(resourceKeys.Lang_DeclineDownloadFinalSignedDoc)}}
            </p>
            <p *ngIf="!downloadFinalContractBtn" class="mb-3 font-15">
                {{getLanguageTranslationValue(resourceKeys.DownloadFinalSignedDocText)}}
            </p>
        </div>
        <div id="divRegisterEmail" class="d-flex align-items-center res-column justify-content-center mb-2">
            <div class="d-flex align-items-center">
                <span class="material-icons me-2 mailicon-color"> mark_email_read </span>
                <span class="font-w600" id="spnEmailText">Email:</span>
            </div>
            <label class="text-black p-1">{{emailAddress}}</label>
        </div>

        <div *ngIf="downloadFinalContractBtn" class="d-flex align-items-center justify-content-center mt-2 pt-1">
            <button type="button" class="btn btn-default btn_accept button-29 ms-3" id="downloadFinalContractBtn"
                (click)="onDownload()"><i class="fa fa-download me-2" aria-hidden="true"></i>Download </button>
        </div>
    </div>
</div>

<!-- Modal footer -->
<div class="modal-footer bg-modal modalfooter-padding">

    <button type="button" id="btnNoThanks" class="btn btncancel px-3 py-1" (click)="onNoThanks()" style="height: 23px;
        border-radius: 4px;"> {{getLanguageTranslationValue(resourceKeys.lang_NOTHANKSUpperCase)}}</button>

    <button type="button" class="btn btn-default btn_accept button-29 ms-3" id="btnSignInExistingUser"
        (click)="onLogin()">{{getLanguageTranslationValue(resourceKeys.lang_LOGINUpperCase)}}</button>

</div>


<div *ngIf="showLoader" class="loader">
</div>