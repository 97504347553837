<div class="d-flex">
	<ul class="paginationContainer pagination d-flex align-items-center justify-content-center pagination-disabled" style="margin: 0px;">
		<!-- disabled -->
		<li id="first_page" class="page-item " (click)="onPageSelection($event, 'first')">
			<div class="first_page page-link br-red2 d-flex align-items-center bx-shadow neu-light activepage res-h cursor-pointer">
				<span class="first_page material-icons left-3p">first_page</span>
			</div>
		</li>
		<li id="chevron_left" class="page-item ms-1" (click)="onPageSelection($event, 'prev')">
			<div class="chevron_left page-link br-red2 d-flex align-items-center bx-shadow neu-light activepage res-h cursor-pointer">
				<span class="chevron_left material-icons left-3p">chevron_left</span>
			</div>
		</li>
		<li class="page-item">
				<div class="d-flex align-items-center m-auto justify-content-center mx-2">
				
				<input id="paginationInput" [value]="pageVal" autocomplete="off"
					(keyup.enter)="onPageSelection($event, 'paginationInput')"
					(ontouchstart)="onPageSelection($event, 'paginationInput')"
					(keypress)="forRestrictNumbers($event)" type="text"
					[ngClass]="getPaginationInputWidth()"
					class="form-control bx-shadow neu-light1 res-hw" style="cursor: default;"/>
					<p class="ms-2 me-1 mb-0 font17 font-w600 text-color">/ {{documentDataLength}}</p>
				</div>
		</li>
		<li class="page-item d-flex"></li>
		<li id="navigate_next" class="page-item me-1" (click)="onPageSelection($event, 'next')">
			<div class="navigate_next page-link br-red2 d-flex align-items-center bx-shadow neu-light activepage res-h cursor-pointer">
				<span class="navigate_next material-icons left-3p"> navigate_next </span>
			</div>
		</li>
		<li id="last_page" class="page-item" (click)="onPageSelection($event, 'last')">
			<div class="last_page page-link bx-shadow br-red2 neu-light activepage res-h cursor-pointer">
				<span class="last_page material-icons top-n4">last_page</span>
			</div>
		</li>
    </ul>
	<div class="paginationLoader spinner-border spinner-border-sm"
		[ngStyle]="{'color': webOrMobile === 'web' ? '#fff' : '',
					'top': webOrMobile === 'web' ? '5px' : '8px',
					'left': webOrMobile === 'web' ? '-120px' : '-95px'
				}"
		style="position: relative;z-index:99;">
	</div>
</div>
