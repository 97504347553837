import { Component, OnInit } from '@angular/core';
import { CommonConstants, EmailAddressFormat, MessageTypes, ResourceKeys, RouteUrls } from 'src/app/constants/common-constants';
import { TranslationsService } from 'src/app/services/translations.service';
import { ToastrService } from 'ngx-toastr';
import { SignerLandingService } from '../../services/signer-landing.service';
import { CommonService } from 'src/app/services/common.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-confirm-to-begain',
  templateUrl: './confirm-to-begain.component.html',
  styleUrls: ['./confirm-to-begain.component.scss']
})
export class ConfirmToBegainComponent implements OnInit {
  title = 'RSign | Confirm';
  agreeFlag: boolean = false;
  agreement: string = '';
  signerPageNote: string = '';
  email: any;
  dropdownSelection: any;
  htmlFooterString: string = '';
  commonConstants = CommonConstants;
  showLoader: boolean = false;
  senderEmail: any;
  isStaticPwd: any;
  isPasswordMailToSigner: any;
  isSMSAccessCode: any;
  cultureInfo: any;
  rolsTypesInfo: any;
  rolesCount: number = 0;
  envelopeID: any;
  recipientEmail: any;
  isStaticTemplate: any;
  copyMail: any;
  SignerTermsCond: any;
  Acceptingthetermsandconditions: any;
  resourceKeys = ResourceKeys;
  divDeliveryModeEmail: boolean = false;
  divDeliveryModeMobile: boolean = false;
  hdnDeliveryMode: any;
  deliveryMode: any = '';
  dialcode: any = '';
  mobileNumber: any = '';
  slRecipientId: any = '';
  documentControlsResponse: any;
  DialCodeDropdownList: any;
  EnableMessageToMobile: boolean = false;
  slCountryMaxMobileLength: any;
  displayText: any;
  webOrMobile: any = 'web';
  IsSendMessageCodetoAvailableEmailorMobile: boolean = true;
  CopyMailId: any;

  constructor(private translationsService: TranslationsService, private toastr: ToastrService,
    private signerLandingService: SignerLandingService, private commonService: CommonService, private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    if ((/android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
      this.webOrMobile = 'mobile';
    }
    this.htmlFooterString = '<p> ' +
      '<a href="https://rpost.com/legal-notices/terms-and-conditions" target="_blank" class="footerLinks">General Terms & Legal Notices</a>: Click to view service level terms, conditions, privacy, ' +
      '<a class="footerLinks" href="https://rpost.com/legal-notices/legal-and-patent-notice" target="_blank"> patent, trademark</a>, and other service and legal notices. ' +
      '</p>';

    this.showLoader = true;
    this.getConformToBeginData();
    localStorage.setItem("IsFromBotClick", "");
    if (this.webOrMobile === 'mobile') {
      let setHeight: any = document.getElementById('mainContainer');
      if (setHeight) {
        setHeight.style.height = 'auto';
      }
    }
  }

  getLanguageTranslations() {
    if (sessionStorage.getItem('LaungaueTransalation') == null || sessionStorage.getItem('LanguageValidation') == null
      || sessionStorage.getItem('LaungaueTransalation') == undefined || sessionStorage.getItem('LanguageValidation') == undefined) {
      this.translationsService.getLanguageTranslations(null, null);
    }
  }

  ngAfterViewInit() {
    let btnFirstAccept: any = document.getElementById("btnFirstAccept");
    btnFirstAccept?.setAttribute('disabled', 'disabled');
  }

  getConformToBeginData() {
    this.senderEmail = localStorage.getItem("senderEmail");
    this.envelopeID = localStorage.getItem("EnvelopeID");
    this.isPasswordMailToSigner = localStorage.getItem("isPasswordMailToSigner");
    this.isSMSAccessCode = localStorage.getItem("IsSMSAccessCode");
    this.isStaticPwd = localStorage.getItem("isStaticPwd");
    this.recipientEmail = localStorage.getItem("recipientEmail");
    this.CopyMailId = localStorage.getItem("CopyMailId");

    this.getLanguageTranslations();
    if (this.CopyMailId != null && this.CopyMailId != undefined && this.CopyMailId != "" && this.CopyMailId != this.recipientEmail) {
      this.recipientEmail = this.CopyMailId;
      this.copyMail = this.CopyMailId;
    }
    else {
      this.copyMail = '';
    }
    if (this.senderEmail == null || this.envelopeID == null) {
      let actualStaticSigningUrl: any = localStorage.getItem("actualStaticSigningUrl");
      if (actualStaticSigningUrl == null || actualStaticSigningUrl == undefined) {
        this.showLoader = false;
        //localStorage.setItem("encryptedSender", '');
        localStorage.setItem("DisplayMessage", CommonConstants.InvalidUrl);
        localStorage.setItem("MessageType", "Warning");
        this.commonService.getReturnUrlDirection(RouteUrls.Info);
      }
      else {
        window.location.href = actualStaticSigningUrl;
      }
    }

    setTimeout(() => {
      if (!this.isNullOrEmptyCheck(this.recipientEmail)) {
        this.recipientEmail = '';
      }
      this.rolsTypesInfo = localStorage.getItem("rolsInfos");
      if (this.rolsTypesInfo != null && this.rolsTypesInfo != '') {
        this.rolsTypesInfo = JSON.parse(this.rolsTypesInfo);
      }
      let staticRolesCount: any = localStorage.getItem("rolesCount");
      if (staticRolesCount != null && staticRolesCount != undefined && staticRolesCount != '') {
        this.rolesCount = parseInt(staticRolesCount);
      }
      else {
        this.rolesCount = 0;
      }
      this.isStaticTemplate = localStorage.getItem("isStaticTemplate");
      this.cultureInfo = localStorage.getItem("cultureInfo");


      this.Acceptingthetermsandconditions = this.translationsService.getLanguageTranslationVal(ResourceKeys.Acceptingthetermsandconditions);

      let documentControlsResp: any = localStorage.getItem("documentControlsResponse");
      this.documentControlsResponse = JSON.parse(documentControlsResp);
      this.DialCodeDropdownList = this.documentControlsResponse.DialCodeDropdownList;
      this.EnableMessageToMobile = (this.documentControlsResponse.EnableMessageToMobile == null || this.documentControlsResponse.EnableMessageToMobile == false) ? false : true;
      this.IsSendMessageCodetoAvailableEmailorMobile = (this.documentControlsResponse.SendMessageCodetoAvailableEmailorMobile == false) ? false : true;

      if (this.rolesCount == 1) {
        this.GetDeliveryMode(this.rolesCount);
      }
      else if (this.rolesCount == 0) {
        var deliveryMode = this.documentControlsResponse.DeliveryMode;
        if (deliveryMode == "0" || deliveryMode == null) deliveryMode = "1";
        this.SetDeliveryMode(deliveryMode);
      }
      this.disableFileds();

      if (this.EnableMessageToMobile) {
        if (this.rolesCount == 1 || this.rolesCount == 0) {
          let deliverymode: any;
          if (this.rolesCount == 1) {
            deliverymode = this.rolsTypesInfo[0].DeliveryMode;
          }
          else if (this.rolesCount == 0) {
            deliverymode = this.documentControlsResponse.DeliveryMode;
          }
          if (deliverymode == "1" || deliverymode == "11") {
            this.displayText = this.translationsService.getLanguageTranslationVal(ResourceKeys.ConfirmEmailOnly);
            this.SignerTermsCond = this.translationsService.getLanguageTranslationVal(ResourceKeys.SignerTermsCond);
          }
          else if (this.commonService.returnEmailAndMobileDeliveryModeVal(deliverymode)) {
            this.displayText = this.translationsService.getLanguageTranslationVal(ResourceKeys.ConfirmEmailMobileText);
            this.SignerTermsCond = this.translationsService.getLanguageTranslationVal(ResourceKeys.SignerTermsCondEmailOrMobile);
          }
          else if (deliverymode == "4" || deliverymode == "6") {
            this.displayText = this.translationsService.getLanguageTranslationVal(ResourceKeys.ConfirmMobileText);
            this.SignerTermsCond = this.translationsService.getLanguageTranslationVal(ResourceKeys.SignerTermsCondMobile);
          }
          else {
            this.displayText = this.translationsService.getLanguageTranslationVal(ResourceKeys.ConfirmEmailOnly);
            this.SignerTermsCond = this.translationsService.getLanguageTranslationVal(ResourceKeys.SignerTermsCond);
          }
        }
        else {
          this.displayText = this.translationsService.getLanguageTranslationVal(ResourceKeys.MultiSignerConfirmEmailMobileText);
          this.SignerTermsCond = this.translationsService.getLanguageTranslationVal(ResourceKeys.SignerTermsCondEmailOrMobile);
        }
      }
      else {
        this.displayText = this.translationsService.getLanguageTranslationVal(ResourceKeys.ConfirmSingleSignerEmailOnly);
        this.SignerTermsCond = this.translationsService.getLanguageTranslationVal(ResourceKeys.SignerTermsCondEmailOrMobile);
      }
    }, 10);
    this.showLoader = false;
  }

  isNullOrEmptyCheck(recipientEmail: any) {
    let boolFlag = false;
    if (typeof recipientEmail != 'undefined' && recipientEmail !== '' && recipientEmail !== null && recipientEmail !== 'false' && recipientEmail !== 'null') {
      boolFlag = true;
    }
    return boolFlag;
  }

  showToaster(msg: string, errorType: any) {
    if (errorType === "Error") {
      this.toastr.error(msg, errorType, { timeOut: 2000 });
    }
    else if (errorType === "Warning") {
      this.toastr.warning(msg, errorType, { timeOut: 2000 });
    }
  }

  validateEmail(email: any) {
    var re = EmailAddressFormat.ValidEmail;
    return re.test(email);
  }

  onEnableContinue(event: any) {
    let chkterms: any = document.getElementById("chkterms");
    let btnFirstAccept: any = document.getElementById("btnFirstAccept");
    if (event.target.checked) {
      btnFirstAccept?.removeAttribute('disabled');
    }
    else if (!event.target.checked) {
      btnFirstAccept?.setAttribute('disabled', 'disabled');
    }
  }

  continue(event: any) {
    var deliveryMode = this.hdnDeliveryMode;
    let isValid = true;

    if (this.slRecipientId == "" && this.rolesCount != 0) {
      isValid = false;
      this.showToaster(this.translationsService.getLanguageValidationVal("SelectSignAs"), "Warning"); return;
    }

    let txtFirstSignerEmail: any = document.getElementById("txtFirstSignerEmail");
    let txtConfirmSignerMobileNumber: any = document.getElementById("txtConfirmSignerMobileNumber");
    let signerCountryCodeVal: any = '';
    let DialCodeUlVal: any = '';
    let txtConfirmSignerMobileNumberValue = ''

    if (deliveryMode == 1 || deliveryMode == 11) {
      if (txtFirstSignerEmail && !this.isNullOrEmptyCheck(txtFirstSignerEmail.value)) {
        isValid = false;
        this.showToaster("Please enter email address.", "Warning"); return;
      }
      else if (txtFirstSignerEmail && !this.validateEmail(txtFirstSignerEmail.value)) {
        isValid = false;
        this.showToaster(this.translationsService.getLanguageValidationVal("lang_InvalidEmailAddress"), "Warning"); return;
      }
    }
    else if (this.commonService.returnEmailAndMobileDeliveryModeVal(deliveryMode)) {
      var isEmailEmpty = false;
      var isMobileEmpty = false;

      if (txtFirstSignerEmail && !this.isNullOrEmptyCheck(txtFirstSignerEmail.value)) {
        isEmailEmpty = true;
      }
      if (txtConfirmSignerMobileNumber.value == "") {
        isMobileEmpty = true;
      }

      if (this.IsSendMessageCodetoAvailableEmailorMobile && this.IsSendMessageCodetoAvailableEmailorMobile == true) {
        if (isEmailEmpty && isMobileEmpty) {
          isValid = false;
          this.showToaster("Please enter email or mobile number.", "Warning"); return;
        }
      }
      else {
        if (isEmailEmpty) {
          isValid = false;
          this.showToaster("Please enter email address.", "Warning"); return;
        }
        if (isMobileEmpty) {
          isValid = false;
          this.showToaster("Please enter mobile number.", "Warning"); return;
        }
      }

      if (!isEmailEmpty) {
        if (txtFirstSignerEmail && !this.isNullOrEmptyCheck(txtFirstSignerEmail.value)) {
          isValid = false;
          this.showToaster("Please enter email address.", "Warning"); return;
        }
        else if (txtFirstSignerEmail && !this.validateEmail(txtFirstSignerEmail.value)) {
          isValid = false;
          this.showToaster(this.translationsService.getLanguageValidationVal("lang_InvalidEmailAddress"), "Warning"); return;
        }
      }

      if (!isMobileEmpty) {
        txtConfirmSignerMobileNumberValue = txtConfirmSignerMobileNumber.value;
        if ($("#spnTitle") && $("#spnTitle").text() != '') {
          DialCodeUlVal = $("#spnTitle").text();
        }
        if (DialCodeUlVal && (DialCodeUlVal == "" || DialCodeUlVal == "Select")) {
          isValid = false;
          this.showToaster("Please select country code.", "Warning"); return;
        }
        if (txtConfirmSignerMobileNumberValue == "") {
          isValid = false;
          this.showToaster("Please provide mobile number.", "Warning"); return;
        }
        else {
          var mobileNumber = txtConfirmSignerMobileNumberValue;
          mobileNumber = DialCodeUlVal + mobileNumber;
          if (!this.isValidPhone(txtConfirmSignerMobileNumberValue)) {
            isValid = false;
            this.showToaster("Invalid mobile number.", "Warning"); return;
          }
        }
      }

      let spnClassList: any = $("#spnTitle").find('span')[0].classList;
      if (spnClassList && spnClassList.length > 0) {
        signerCountryCodeVal = spnClassList[0].split('-')[1];
      }
      DialCodeUlVal = $("#spnTitle").text();
    }
    else if (deliveryMode == 4 || deliveryMode == 6) {
      txtConfirmSignerMobileNumberValue = txtConfirmSignerMobileNumber.value;
      if ($("#spnTitle") && $("#spnTitle").text() != '') {
        DialCodeUlVal = $("#spnTitle").text();
      }
      if (DialCodeUlVal && (DialCodeUlVal == "" || DialCodeUlVal == "Select")) {
        isValid = false;
        this.showToaster("Please select country code.", "Warning"); return;
      }
      if (txtConfirmSignerMobileNumberValue == "") {
        isValid = false;
        this.showToaster("Please provide mobile number.", "Warning"); return;
      }
      else {
        var mobileNumber = txtConfirmSignerMobileNumberValue;
        mobileNumber = DialCodeUlVal + mobileNumber;
        if (!this.isValidPhone(txtConfirmSignerMobileNumberValue)) {
          isValid = false;
          this.showToaster("Invalid mobile number.", "Warning"); return;
        }
      }
      let spnClassList: any = $("#spnTitle").find('span')[0].classList;
      if (spnClassList && spnClassList.length > 0) {
        signerCountryCodeVal = spnClassList[0].split('-')[1];
      }
    }

    let confirmDetailsVal = {
      deliverymode: deliveryMode,
      dialcode: DialCodeUlVal,
      mobilenumber: txtConfirmSignerMobileNumberValue,
      countrycode: signerCountryCodeVal
    };
    localStorage.setItem("ConfirmDetailsVal", JSON.stringify(confirmDetailsVal));

    if (isValid) {
      if (this.rolesCount > 0) {
        this.showLoader = true;
        let currentdrpCtrl: any = (document.getElementById("slRoles")) as HTMLSelectElement;
        var sel = currentdrpCtrl.selectedIndex;
        var opt = currentdrpCtrl.options[sel];
        let drpValue = (<HTMLSelectElement>opt).value;

        if (drpValue == "" || drpValue == null || drpValue == undefined) {
          this.showToaster(this.translationsService.getLanguageValidationVal("SelectSignAs"), "Warning");
          this.showLoader = false;
        }
        let objSignParam = {
          RecipientId: drpValue,
          FirstSignerEmail: !this.commonService.returnMobileDeliveryModeVal(deliveryMode) ? txtFirstSignerEmail.value : '',
          EnvelopeId: this.envelopeID,
          IsStaticPwd: this.isStaticPwd == "true" ? true : false,
          IsPasswordMailToSigner: this.isPasswordMailToSigner == "true" ? true : false,
          IsSMSAccessCode: this.isSMSAccessCode == "true" ? true : false,
          SenderEmail: this.senderEmail,
          CultureInfo: this.cultureInfo,
          DeliveryMode: String(this.hdnDeliveryMode),
          DialCode: !this.commonService.returnEmailBasedOnDeliveryModeVal(deliveryMode) ? DialCodeUlVal : '',
          MobileNumber: !this.commonService.returnEmailBasedOnDeliveryModeVal(deliveryMode) ? txtConfirmSignerMobileNumberValue : '',
          CountryCode: signerCountryCodeVal
        };

        let CurrentRecitDeliverDetails = {
          DeliveryMode: parseInt(objSignParam.DeliveryMode),
          DialCode: objSignParam.DialCode,
          MobileNumber: objSignParam.MobileNumber,
          SignerCode: signerCountryCodeVal
        };
        localStorage.setItem("CurrentRecitDeliverDetails", JSON.stringify(CurrentRecitDeliverDetails));

        // console.log(objSignParam);
        let url = CommonConstants.AllowFirstSigner;

        this.signerLandingService.postcall(url, objSignParam).subscribe(
          (resp: any) => {
            if (resp && resp.StatusCode == 200 && resp.StatusMessage.toLowerCase() == "ok") {
              if (txtFirstSignerEmail != null) {
                localStorage.setItem("ProvidedUserEmail", txtFirstSignerEmail.value);
              }
              localStorage.setItem("CurrentRecipientID", drpValue);
              let ePasswordModal: any = resp.EPasswordModal;
              if (ePasswordModal != null) {
                localStorage.setItem("ePasswordModal", JSON.stringify(ePasswordModal));
              }
              this.showLoader = false;
              if (resp.strURL == "static-password-window")
                window.location.href = resp.strURLWithData;
              else
                this.getInitializeMultiSignerStaticDocumentData(resp.strURLWithData);
            }
          });

      }
      else {
        let currentRecipientID: any = localStorage.getItem("currentRecipientID");
        let recipientEmail = this.recipientEmail; //localStorage.getItem("recipientEmail");
        this.showLoader = true;
        let objSignParam = {
          RecipientId: currentRecipientID,
          templateKey: "",
          EnvelopeId: this.envelopeID,
          IPAddress: "",
          isFromSignerLanding: false,
          CopyEmailId: recipientEmail,
          IsStaticPwd: this.isStaticPwd == "true" ? true : false,
          IsPasswordMailToSigner: this.isPasswordMailToSigner == "true" ? true : false,
          IsSMSAccessCode: this.isSMSAccessCode == "true" ? true : false,
          EmailId: this.senderEmail,
          DeliveryMode: String(this.hdnDeliveryMode),
          DialCode: !this.commonService.returnEmailBasedOnDeliveryModeVal(deliveryMode) ? DialCodeUlVal : '',
          MobileNumber: !this.commonService.returnEmailBasedOnDeliveryModeVal(deliveryMode) ? txtConfirmSignerMobileNumberValue : '',
          CountryCode: signerCountryCodeVal
        };

        let CurrentRecitDeliverDetails = {
          DeliveryMode: parseInt(objSignParam.DeliveryMode),
          DialCode: objSignParam.DialCode,
          MobileNumber: objSignParam.MobileNumber,
          SignerCode: signerCountryCodeVal
        };
        localStorage.setItem("CurrentRecitDeliverDetails", JSON.stringify(CurrentRecitDeliverDetails));
        // console.log(objSignParam);
        let url = CommonConstants.AllowStaticSigner;

        this.signerLandingService.postcall(url, objSignParam).subscribe(
          (resp: any) => {
            this.showLoader = false;
            if (resp && resp.StatusCode == 200 && resp.StatusMessage.toLowerCase() == "ok") {
              if (txtFirstSignerEmail != null) {
                localStorage.setItem("ProvidedUserEmail", txtFirstSignerEmail.value);
              }
              localStorage.setItem("CurrentRecipientID", currentRecipientID);
              let ePasswordModal: any = resp.EPasswordModal;
              if (ePasswordModal != null) {
                localStorage.setItem("ePasswordModal", JSON.stringify(ePasswordModal));
              }
              this.showLoader = false;
              if (resp.strURL == "static-password-window")
                window.location.href = resp.strURLWithData;
              else
                this.getInitializeSignerStaticSignDocument(resp.strURLWithData);
            }
          });
      }
    }
  }

  isValidPhone(phonenumber: any) {
    var isvalidPhoneNumber = true;
    if (phonenumber == "" || phonenumber.length != this.slCountryMaxMobileLength) {
      isvalidPhoneNumber = false;
    }
    return isvalidPhoneNumber;
  }

  getInitializeMultiSignerStaticDocumentData(signingStaticUrl: any) {
    this.showLoader = true;
    let actualSigningUrl: any = signingStaticUrl;
    // actualSigningUrl = actualSigningUrl.substring(0, actualSigningUrl.length - 1);
    localStorage.setItem("actualSigningUrl", actualSigningUrl);
    let signingUrl = decodeURI(actualSigningUrl.split('?')[1]);
    // console.log('SigningUrl', signingUrl);
    if (signingUrl == undefined || signingUrl == "undefined" || signingUrl == null) {
      this.showLoader = false;
      //localStorage.setItem("encryptedSender", '');
      localStorage.setItem("DisplayMessage", CommonConstants.InvalidUrl);
      localStorage.setItem("MessageType", "Warning");
      this.commonService.getReturnUrlDirection(RouteUrls.Info);
    }
    else {
      this.showLoader = true;
      let initializeMultiSignerStaticDocumentUrl = CommonConstants.InitializeMultiSignerStaticDocument;
      let paramsObj = { SigningUrl: signingUrl };
      this.signerLandingService.getSignerSignDocumentData(initializeMultiSignerStaticDocumentUrl, paramsObj).subscribe(
        (resp: any) => {
          this.showLoader = false;
          if (resp && resp.StatusCode == 200 && resp.StatusMessage.toLowerCase() == "ok") {
            localStorage.setItem("EnvelopeImageControlData", JSON.stringify(resp.EnvelopeImageControlData));           
            localStorage.setItem("CheckListData", JSON.stringify(resp.CheckListData));
            localStorage.setItem("Userdata", JSON.stringify(resp.userdata)); 
            localStorage.setItem("EnvelopeInfo", JSON.stringify(resp.EnvelopeInfo));          
            resp.EnvelopeImageControlData = null;
            resp.Language = null;
            resp.CheckListData = null;
            resp.userdata = null;
            resp.EnvelopeInfo = null;
            localStorage.setItem("documentControlsResponse", JSON.stringify(resp));
            this.commonService.getReturnUrlDirection(RouteUrls.StaticTemplate);
          }
          else if (resp && resp.originalError && resp.originalError.error && (resp.originalError.error.StatusCode == 400 || resp.originalError.error.StatusCode == 204)) {
            let encryptedSender: string = resp.originalError.error.EncryptedSender;
           // localStorage.setItem("encryptedSender", encryptedSender);
            localStorage.setItem("DisplayMessage", resp.originalError.error.StatusMessage);
            localStorage.setItem("MessageType", "Warning");
            if (resp.originalError.error.ErrorAction == "Resend") {
              this.commonService.getReturnUrlDirection(RouteUrls.Info);
            }
            else if (resp.originalError.error.ErrorAction == "Expire") {
              resp.originalError.error.StatusMessage == "" ? localStorage.setItem("DisplayMessage", "This envelope is expired.") : localStorage.setItem("DisplayMessage", resp.originalError.error.StatusMessage);
              this.commonService.getReturnUrlDirection(RouteUrls.Info);
            }
            else if (resp.originalError.error.ErrorAction == "EnvelopeAccepted" || resp.originalError.error.ErrorAction == "ContactSender") {
              // resp.originalError.error.StatusMessage == "" ? localStorage.setItem("DisplayMessage", "This document has already been signed and returned to the sender.") : localStorage.setItem("DisplayMessage", resp.originalError.error.StatusMessage);
              localStorage.setItem("DisplayMessage", "This document has already been signed and returned to the sender.")
              localStorage.setItem("MessageType", MessageTypes.Success);
              this.commonService.getReturnUrlDirection(RouteUrls.Info);
            }
            else {
              if (resp.originalError.error.StatusMessage == "") {
                localStorage.setItem("MessageType", "Error");
                localStorage.setItem("DisplayMessage", "An Application error occurred.")
                localStorage.setItem("IsApplicationError", "true");
                localStorage.setItem("PageName", "MultiSignerStatic");

                if (resp && resp.originalError && resp.originalError.error && resp.originalError.error.EnvelopeId && resp.originalError.error.CurrentEmail) {
                  let envid = resp.originalError.error.EnvelopeId;
                  let emailid = resp.originalError.error.CurrentEmail;

                  localStorage.setItem("EnvelopeID", envid);
                  localStorage.setItem("EmailId", emailid);
                }
              }
              else {
                localStorage.setItem("DisplayMessage", resp.originalError.error.StatusMessage);
              }
              this.commonService.getReturnUrlDirection(RouteUrls.Info);
            }

            this.showLoader = false;
          }
          else {
            this.showLoader = false;
            //localStorage.setItem("encryptedSender", "");
            localStorage.setItem("MessageType", "Error");
            localStorage.setItem("IsApplicationError", "true");
            localStorage.setItem("PageName", "MultiSignerStatic");
            localStorage.setItem("DisplayMessage", "An Application error occurred.");

            if (resp && resp.originalError && resp.originalError.error && resp.originalError.error.EnvelopeId && resp.originalError.error.CurrentEmail) {
              let envid = resp.originalError.error.EnvelopeId;
              let emailid = resp.originalError.error.CurrentEmail;

              localStorage.setItem("EnvelopeID", envid);
              localStorage.setItem("EmailId", emailid);
            }

            this.commonService.getReturnUrlDirection(RouteUrls.Info);
          }
        });
    }
  }

  getInitializeSignerStaticSignDocument(signingStaticUrl: any) {
    this.showLoader = true;
    let actualSigningUrl: any = signingStaticUrl;
    localStorage.setItem("actualSigningUrl", actualSigningUrl);
    let signingUrl = decodeURI(actualSigningUrl.split('?')[1]);
    // console.log('SigningUrl', signingUrl);
    if (signingUrl == undefined || signingUrl == "undefined" || signingUrl == null) {
      this.showLoader = false;
     // localStorage.setItem("encryptedSender", '');
      localStorage.setItem("DisplayMessage", CommonConstants.InvalidUrl);
      localStorage.setItem("MessageType", "Warning");
      this.commonService.getReturnUrlDirection(RouteUrls.Info);
    }
    else {
      this.showLoader = true;
      let initializeSignerStaticSignDocument = CommonConstants.InitializeSignerStaticSignDocument;
      let paramsObj = { SigningUrl: signingUrl };
      this.signerLandingService.getSignerSignDocumentData(initializeSignerStaticSignDocument, paramsObj).subscribe(
        (resp: any) => {
          this.showLoader = false;
          if (resp && resp.StatusCode == 200 && resp.StatusMessage.toLowerCase() == "ok") {
            localStorage.setItem("EnvelopeImageControlData", JSON.stringify(resp.EnvelopeImageControlData));          
            localStorage.setItem("CheckListData", JSON.stringify(resp.CheckListData));
            localStorage.setItem("Userdata", JSON.stringify(resp.userdata));  
            localStorage.setItem("EnvelopeInfo", JSON.stringify(resp.EnvelopeInfo));          
            resp.EnvelopeImageControlData = null;
            resp.Language = null;
            resp.CheckListData = null;
            resp.userdata = null;
            resp.EnvelopeInfo = null;
            localStorage.setItem("documentControlsResponse", JSON.stringify(resp));
            this.commonService.getReturnUrlDirection(RouteUrls.SignerLandingStaticTemplate);
          }
          else if (resp && resp.originalError && resp.originalError.error && (resp.originalError.error.StatusCode == 400 || resp.originalError.error.StatusCode == 204)) {
            let encryptedSender: string = resp.originalError.error.EncryptedSender;
           // localStorage.setItem("encryptedSender", encryptedSender);
            localStorage.setItem("DisplayMessage", resp.originalError.error.StatusMessage);
            localStorage.setItem("MessageType", "Warning");
            if (resp.originalError.error.ErrorAction == "Resend") {
              this.commonService.getReturnUrlDirection(RouteUrls.Info);
            }
            else if (resp.originalError.error.ErrorAction == "Expire") {
              resp.originalError.error.StatusMessage == "" ? localStorage.setItem("DisplayMessage", "This envelope is expired.") : localStorage.setItem("DisplayMessage", resp.originalError.error.StatusMessage);
              this.commonService.getReturnUrlDirection(RouteUrls.Info);
            }
            else if (resp.originalError.error.ErrorAction == "EnvelopeAccepted" || resp.originalError.error.ErrorAction == "ContactSender") {
              resp.originalError.error.Message == "" ? localStorage.setItem("DisplayMessage", "This document has already been signed and returned to the sender.") : localStorage.setItem("DisplayMessage", resp.originalError.error.Message);
              localStorage.setItem("DisplayMessage", resp.originalError.error.Message);
              localStorage.setItem("MessageType", MessageTypes.Success);
              if (resp.originalError.error.ErrorAction == "ContactSender") {
                if (resp.originalError.error.IsAllowSignerstoDownloadFinalContract == true) {
                  if (resp.originalError.error.RecipientOrder == 1) { localStorage.setItem("DisplayLoginButton", "true"); localStorage.setItem("DisplaySignUpButton", ""); }
                  if (resp.originalError.error.RecipientOrder == 0) { localStorage.setItem("DisplaySignUpButton", "true"); localStorage.setItem("DisplayLoginButton", ""); }
                }
                else {
                  localStorage.setItem("DisplayLoginButton", ""); localStorage.setItem("DisplaySignUpButton", "");
                }
              }
              localStorage.setItem("EnvelopeStatus", resp.originalError.error.ErrorAction);
              this.commonService.getReturnUrlDirection(RouteUrls.Info);
            }
            else {
              if (resp.originalError.error.StatusMessage == "") {
                localStorage.setItem("MessageType", "Error");
                localStorage.setItem("DisplayMessage", "An Application error occurred.")
                localStorage.setItem("IsApplicationError", "true");
                localStorage.setItem("PageName", "SignerStaticSign");
                if (resp && resp.originalError && resp.originalError.error && resp.originalError.error.EnvelopeId && resp.originalError.error.CurrentEmail) {
                  let envid = resp.originalError.error.EnvelopeId;
                  let emailid = resp.originalError.error.CurrentEmail;

                  localStorage.setItem("EnvelopeID", envid);
                  localStorage.setItem("EmailId", emailid);
                }
              }
              else {
                localStorage.setItem("DisplayMessage", resp.originalError.error.StatusMessage);
              }

              this.commonService.getReturnUrlDirection(RouteUrls.Info);
            }
            this.showLoader = false;
          }
          else {
            this.showLoader = false;
            //localStorage.setItem("encryptedSender", "");
            localStorage.setItem("MessageType", "Error");
            localStorage.setItem("IsApplicationError", "true");
            localStorage.setItem("PageName", "SignerStaticSign");
            localStorage.setItem("DisplayMessage", "An Application error occurred.");
            if (resp && resp.originalError && resp.originalError.error && resp.originalError.error.EnvelopeId && resp.originalError.error.CurrentEmail) {
              let envid = resp.originalError.error.EnvelopeId;
              let emailid = resp.originalError.error.CurrentEmail;

              localStorage.setItem("EnvelopeID", envid);
              localStorage.setItem("EmailId", emailid);
            }
            this.commonService.getReturnUrlDirection(RouteUrls.Info);
          }
        });
    }
  }

  getLanguageTranslationValue(resourcekeyId: any) {
    return this.translationsService.getLanguageTranslationVal(resourcekeyId);
  }

  onChangeRoleDropdownSelection(event: any) {
    this.GetDeliveryMode(this.rolesCount);
  }

  GetDeliveryMode(rolesCount: any) {
    let slRecipientId: any;
    if (rolesCount == 1) {
      this.slRecipientId = this.rolsTypesInfo[0].RecipientId;
      slRecipientId = this.slRecipientId;
    }
    else {
      let currentdrpCtrl: any = (document.getElementById("slRoles")) as HTMLSelectElement;
      var sel = currentdrpCtrl.selectedIndex;
      var opt = currentdrpCtrl.options[sel];
      this.slRecipientId = (<HTMLSelectElement>opt).value;
      slRecipientId = this.slRecipientId;
    }

    if (this.EnableMessageToMobile) {
      let currentRecipientDetails: any;
      if (this.rolsTypesInfo != null && this.rolsTypesInfo != undefined && this.rolsTypesInfo != '' && this.rolsTypesInfo.length > 0) {
        let matchRecipientArray = this.rolsTypesInfo.filter(function (value: any) { return value.RecipientId == slRecipientId; });
        if (matchRecipientArray && matchRecipientArray.length > 0) {
          currentRecipientDetails = matchRecipientArray[0];
        }
      }

      if (currentRecipientDetails && currentRecipientDetails != "") {
        let deliveryMode: any = currentRecipientDetails.DeliveryMode;
        let dialCode = currentRecipientDetails.DialCode;
        let countryCode = (currentRecipientDetails.CountryCode != null && currentRecipientDetails.CountryCode != "") ? currentRecipientDetails.CountryCode.toLowerCase() : "";
        let signerCode = "bg-" + countryCode;
        if (deliveryMode == "0" || deliveryMode == null) deliveryMode = "1";
        this.SetDeliveryMode(deliveryMode);
        setTimeout(() => {
          let spnTitle: any = document.getElementById("spnTitle");
          if (spnTitle != null) {
            spnTitle.innerHTML = '';
            spnTitle.innerHTML = '<a style="text-decoration-line: none; color: #000;"><span id="spnCountryFlag" style="top: 4px;display: inline-block;width: 25px;position: relative;" class="' + signerCode + '"></span>' + dialCode + '</a>';
            spnTitle?.setAttribute('disabled', 'disabled');
            spnTitle.style.cursor = "no-drop";
            let DialCodeUl: any = document.getElementById("DialCodeUl");
            let ddrCountryFlag: any = document.getElementById("ddrCountryFlag");
            DialCodeUl.style.display = 'none';
            ddrCountryFlag.style.cursor = "no-drop";

            if (this.documentControlsResponse.MobileNumber != null && this.documentControlsResponse.MobileNumber != "" && this.documentControlsResponse.MobileNumber != undefined) {
              let txtSignerMobileNumber: any = document.getElementById("txtConfirmSignerMobileNumber");
              if (txtSignerMobileNumber != null && txtSignerMobileNumber != undefined) {
                txtSignerMobileNumber.setAttribute('data-maxlength', this.documentControlsResponse.MobileNumber.length);
                this.slCountryMaxMobileLength = this.documentControlsResponse.MobileNumber.length;
              }
            }
            else {
              for (let index = 0; index < this.DialCodeDropdownList.length; index++) {
                const element = this.DialCodeDropdownList[index];
                if (element.CountryCode.toLowerCase() == countryCode) {
                  this.slCountryMaxMobileLength = element.MobileMaxLength;
                  $('#txtConfirmSignerMobileNumber').attr('maxlength', this.slCountryMaxMobileLength);
                  break;
                }
              }
            }
          }
        }, 20);
      }
      else {
        this.divDeliveryModeEmail = true;
        this.divDeliveryModeMobile = false;
      }
    }
    else {
      this.hdnDeliveryMode = 1;
      this.divDeliveryModeEmail = true;
    }
  }

  SetDeliveryMode(deliveryMode: any) {
    if (this.EnableMessageToMobile) {
      if (deliveryMode == 1 || deliveryMode == 11) {
        this.divDeliveryModeEmail = true;
        this.divDeliveryModeMobile = false;
        setTimeout(() => {
          document.getElementById("spnFirstSignerEmail")!.style.display = 'inline-block';
        }, 10);
      }
      else if (this.commonService.returnEmailAndMobileDeliveryModeVal(deliveryMode)) {
        this.divDeliveryModeEmail = true;
        this.divDeliveryModeMobile = true;
        setTimeout(() => {
          document.getElementById("spnConfirmSignerDialCode")!.style.display = 'none';

          if (this.IsSendMessageCodetoAvailableEmailorMobile && this.IsSendMessageCodetoAvailableEmailorMobile == true) {
            document.getElementById("spnFirstSignerEmail")!.style.display = 'none';
            document.getElementById("spnConfirmSignerMobileNumber")!.style.display = 'none';
          }
          else {
            document.getElementById("spnFirstSignerEmail")!.style.display = 'inline-block;';
            document.getElementById("spnConfirmSignerMobileNumber")!.style.display = 'inline-block;';
          }
        }, 10);
      }
      else if (deliveryMode == 4 || deliveryMode == 6) {
        this.divDeliveryModeEmail = false;
        this.divDeliveryModeMobile = true;
        setTimeout(() => {
          document.getElementById("spnConfirmSignerDialCode")!.style.display = 'inline-block';
          document.getElementById("spnConfirmSignerMobileNumber")!.style.display = 'inline-block';

        }, 10);
      }
      else {
        this.divDeliveryModeEmail = true;
        this.divDeliveryModeMobile = false;
      }

      this.hdnDeliveryMode = deliveryMode;

      let hdnAcceptingthetermsandconditions: any;
      let divAcceptingthetermsandconditions: any = document.getElementById("hdnAcceptingthetermsandconditions");
      if (divAcceptingthetermsandconditions) {
        hdnAcceptingthetermsandconditions = divAcceptingthetermsandconditions.ariaValueMax;
      }

      if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        let divSignerTermsAndConditions: any = document.getElementById("divSignerTermsAndConditions");
        if (this.commonService.returnEmailAndMobileDeliveryModeVal(deliveryMode) && hdnAcceptingthetermsandconditions == "1") {
          divSignerTermsAndConditions.style["height"] = "100px";
          divSignerTermsAndConditions.style["overflow-y"] = "scroll";
          divSignerTermsAndConditions.style["overflow-x"] = "hidden";
          divSignerTermsAndConditions.style["display"] = "inline-block";
        }
        else if (this.commonService.returnEmailAndMobileDeliveryModeVal(deliveryMode) && this.slRecipientId != "" && this.slRecipientId != undefined &&
          (hdnAcceptingthetermsandconditions == "" || hdnAcceptingthetermsandconditions == undefined)) {
          divSignerTermsAndConditions.style["height"] = "100px";
          divSignerTermsAndConditions.style["overflow-y"] = "scroll";
          divSignerTermsAndConditions.style["overflow-x"] = "hidden";
          divSignerTermsAndConditions.style["display"] = "inline-block";
        }
        else if (this.commonService.returnEmailAndMobileDeliveryModeVal(deliveryMode) && (hdnAcceptingthetermsandconditions == "" || hdnAcceptingthetermsandconditions == undefined)) {
          divSignerTermsAndConditions.style["height"] = "145px"; //"207px";
        }
        else if (this.commonService.returnEmailAndMobileDeliveryModeVal(deliveryMode)) {
          divSignerTermsAndConditions.style["height"] = "100px";
          divSignerTermsAndConditions.style["overflow-y"] = "scroll";
          divSignerTermsAndConditions.style["overflow-x"] = "hidden";
          divSignerTermsAndConditions.style["display"] = "inline-block";
        }

        if ((deliveryMode == 4 || deliveryMode == 6) && hdnAcceptingthetermsandconditions == "1") {
          divSignerTermsAndConditions.style["height"] = "145px";
          divSignerTermsAndConditions.style["overflow-y"] = "scroll";
          divSignerTermsAndConditions.style["overflow-x"] = "hidden";
          divSignerTermsAndConditions.style["display"] = "inline-block";
        }
        else if ((deliveryMode == 4 || deliveryMode == 6) && this.slRecipientId != "" && this.slRecipientId != undefined &&
          (hdnAcceptingthetermsandconditions == "" || hdnAcceptingthetermsandconditions == undefined)) {
          divSignerTermsAndConditions.style["height"] = "100px";
          divSignerTermsAndConditions.style["overflow-y"] = "scroll";
          divSignerTermsAndConditions.style["overflow-x"] = "hidden";
          divSignerTermsAndConditions.style["display"] = "inline-block";
        }
        else if ((deliveryMode == 4 || deliveryMode == 6) && (hdnAcceptingthetermsandconditions == "" || hdnAcceptingthetermsandconditions == undefined)) {
          divSignerTermsAndConditions.style["height"] = "155px";
        }
        else if (deliveryMode == 4 || deliveryMode == 6) {
          divSignerTermsAndConditions.style["height"] = "100px";
          divSignerTermsAndConditions.style["overflow-y"] = "scroll";
          divSignerTermsAndConditions.style["overflow-x"] = "hidden";
          divSignerTermsAndConditions.style["display"] = "inline-block";
        }
        if (this.commonService.returnEmailBasedOnDeliveryModeVal(deliveryMode) && hdnAcceptingthetermsandconditions == "1") {
          divSignerTermsAndConditions.style["height"] = "145px";
          divSignerTermsAndConditions.style["overflow-y"] = "scroll";
          divSignerTermsAndConditions.style["overflow-x"] = "hidden";
          divSignerTermsAndConditions.style["display"] = "inline-block";
        }
        else if (this.commonService.returnEmailBasedOnDeliveryModeVal(deliveryMode) && (hdnAcceptingthetermsandconditions == "" || hdnAcceptingthetermsandconditions == undefined)) {
          divSignerTermsAndConditions.style["height"] = "170px"; //"100px";
          divSignerTermsAndConditions.style["overflow-y"] = "auto";
          divSignerTermsAndConditions.style["overflow-x"] = "hidden";
          divSignerTermsAndConditions.style["display"] = "inline-block";
        }
      }
    }
    else {
      this.hdnDeliveryMode = 1;
      this.divDeliveryModeEmail = true;
      this.divDeliveryModeMobile = false;
    }
  }

  selectedCountryDialCode(event: any) {
    if (this.EnableMessageToMobile) {
      $($('#DialCodeUl li a')[0]).css('background-color', '');
      let ed: any = event.target;
      let edd = event.target.parentElement.innerHTML;
      let spnTitle: any = document.getElementById("spnTitle");
      let dataCountryCodeMaxLength: any;
      let txtSignerMobileNumber: any = document.getElementById("txtConfirmSignerMobileNumber");
      if (spnTitle != null) {
        spnTitle.innerHTML = '';
        spnTitle.innerHTML = edd;

        let spnCountryFlagCol: any = document.getElementById("spnTitle")!.getElementsByTagName("span");
        if (spnCountryFlagCol && spnCountryFlagCol.length > 0) {
          document.getElementById(spnCountryFlagCol[0].id)!.style.top = "4px";
          $('#DialCodeUl li a').removeClass('selected');
          $('#DialCodeUl li ').removeClass('selected');
          if (event.target.parentElement.getElementsByTagName('a').length > 0) {
            dataCountryCodeMaxLength = event.target.parentElement.getElementsByTagName('a')[0].getAttribute("data-maxlength");
            event.target.parentElement.getElementsByTagName('a')[0].classList.add('selected');
          }
          else {
            dataCountryCodeMaxLength = event.target.parentElement.getAttribute("data-maxlength");
            event.target.parentElement.classList.add('selected');
          }
          txtSignerMobileNumber.value = '';
          if (dataCountryCodeMaxLength != null && dataCountryCodeMaxLength != undefined) {
            txtSignerMobileNumber.setAttribute('maxlength', dataCountryCodeMaxLength);
            this.slCountryMaxMobileLength = dataCountryCodeMaxLength;
            $('#txtConfirmSignerMobileNumber').attr('maxlength', this.slCountryMaxMobileLength);
          }
        }
      }
    }
  }

  disableFileds() {
    setTimeout(() => {
      if (this.EnableMessageToMobile) {
        if (this.documentControlsResponse.MobileNumber != null && this.documentControlsResponse.MobileNumber != "" && this.documentControlsResponse.MobileNumber != undefined) {
          this.mobileNumber = this.documentControlsResponse.MobileNumber;
        }

        if (this.documentControlsResponse.DialCode != null && this.documentControlsResponse.DialCode != "" && this.documentControlsResponse.DialCode != undefined) {
          this.dialcode = this.documentControlsResponse.DialCode;
          let signerCode = "bg-" + this.documentControlsResponse.CountryCode.toLowerCase();

          let spnTitle: any = document.getElementById("spnTitle");
          if (spnTitle != null) {
            spnTitle.innerHTML = '';
            spnTitle.innerHTML = '<a style="text-decoration-line: none;"><span id="spnCountryFlag" style="top: 4px;display: inline-block;width: 25px;position: relative;" class="' + signerCode + '"></span>' + this.dialcode + '</a>';

            spnTitle?.setAttribute('disabled', 'disabled');
            spnTitle.style.cursor = "no-drop";
            let DialCodeUl: any = document.getElementById("DialCodeUl");
            DialCodeUl.style.display = 'none';
          }
        }

        if (this.documentControlsResponse.MobileNumber != null && this.documentControlsResponse.MobileNumber != "" && this.documentControlsResponse.MobileNumber != undefined) {
          let txtSignerMobileNumber: any = document.getElementById("txtConfirmSignerMobileNumber");
          if (txtSignerMobileNumber != null && txtSignerMobileNumber != undefined) {
            txtSignerMobileNumber.setAttribute('maxlength', this.documentControlsResponse.MobileNumber.length);
            $('#txtConfirmSignerMobileNumber').attr('maxlength', this.documentControlsResponse.MobileNumber.length);
            this.slCountryMaxMobileLength = this.documentControlsResponse.MobileNumber.length;
            let ddrCountryFlag: any = document.getElementById("ddrCountryFlag");
            ddrCountryFlag.style.cursor = "no-drop";
          }
        }
        else {
          for (let index = 0; index < this.DialCodeDropdownList.length; index++) {
            const element = this.DialCodeDropdownList[index];
            if (this.documentControlsResponse.CountryCode != null && element.CountryCode.toLowerCase() == this.documentControlsResponse.CountryCode.toLowerCase()) {
              this.slCountryMaxMobileLength = element.MobileMaxLength;
              $('#txtConfirmSignerMobileNumber').attr('maxlength', this.slCountryMaxMobileLength);
              break;
            }
          }
          let ddrCountryFlag: any = document.getElementById("ddrCountryFlag");
          if (ddrCountryFlag != null)
            ddrCountryFlag.style.cursor = "no-drop";
        }
      }
    }, 50);
  }

  validateInputValue(event: any) {
    let txtSignerMobileNumber: any = event.target.value;
    if (!this.commonService.isNumberExists(txtSignerMobileNumber)) {
      event.target.value = '';
      this.toastr.warning('Please enter only a number.', 'Warning', { timeOut: 1000 });
    }
  }

}
