export const environment = {
    production: false,
    uiAngularBaseUrl: 'http://sign22new.d1.rpost.info/',
    uiBaseUrl: 'http://app22.d1.rpost.info/',
    apiBaseUrl: 'http://api22.d1.rpost.info/',
    //uiAngularBaseUrl: 'http://sign22new.d1.rpost.info/',
    // uiBaseUrl: 'http://sign22new.d1.rpost.info/',
    // apiBaseUrl: 'http://signapi22new.d1.rpost.info',
    brandUrl: 'https://webapi.s1.rpost.info/api/v1/brand',
    environmentName: 'dev',
    googleDriveClientIdKey: '405467567046-dlltjn2bpsu9buum0039t8m240gl90v7.apps.googleusercontent.com',
    oneDriveApplicationId:'44285d9e-8fa4-406d-b32f-bc1222417c7e',
    dropBoxKey:'3wt6zxhdc8sx843',
    validateBotUrl: 'https://webapi.s1.rpost.info',
    recaptchaSiteKey: '6LcdRhcjAAAAAEpUeGoCKukyhOXpAAv0BjAXh35h',
    sessionTimeOut: 1200,
    sessionInActivity: 300
};
