import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    htmlFooterString: any = '';
    webOrMobile: any = 'web';

    constructor(private sanitizer: DomSanitizer) { }

    ngOnInit(): void {
        if ((/android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
            this.webOrMobile = 'mobile';
        }
        if(localStorage.getItem('htmlFooterString') != null && localStorage.getItem('htmlFooterString') != undefined &&
            localStorage.getItem('htmlFooterString') != '' && localStorage.getItem('htmlFooterString') != 'undefined'){
                let footerHtml: any = localStorage.getItem('htmlFooterString');
                this.htmlFooterString = this.sanitizer.bypassSecurityTrustHtml(footerHtml);
        } else {
            this.htmlFooterString = '<p> '+
            '<a href="https://rpost.com/legal-notices/terms-and-conditions" target="_blank" class="footerLinks">General Terms & Legal Notices</a>: Click to view service level terms, conditions, privacy, '+ 
            '<a class="footerLinks" href="https://rpost.com/legal-notices/legal-and-patent-notice" target="_blank"> patent, trademark</a>, and other service and legal notices. '+
            '</p>';
        }
    }

}
