<!-- Modal Header -->
<div class="modal-header bg-modal modalhead-padding">
    <h4 class="modal-title"> {{getLanguageTranslationValue(resourceKeys.LoginInto)}} </h4>
    <div class="d-flex align-items-center">
        <span class="material-icons modal-close" aria-label="Close" (click)="close()">
            close
        </span>
    </div>
</div>

<!-- Modal body -->
<div class="modal-body modal-bodyb py-3">
    <div class="d-flex flex-column" style="width: 100%;">
        <div class="d-flex flex-wrap" style=" justify-content: center;
                   text-align: left;">
            <p class="mb-3 font-15">
                {{getLanguageTranslationValue(resourceKeys.DownloadDocAfterSignedText)}}
            </p>
        </div>
        <!-- <div id="divRegisterEmail" class="d-flex align-items-center res-column justify-content-center mb-2">
            <div class="d-flex align-items-center">
                <span class="material-icons me-2 mailicon-color"> mark_email_read </span>
                <label class="font-w600">Email:</label>
            </div>
            <label class="text-black p-1">{{emailAddress}}</label>
        </div> -->
    </div>
</div>

<!-- Modal footer -->
<div class="modal-footer bg-modal modalfooter-padding">
    <button type="button" id="btnClose" class="btn btncancel px-3 py-1" (click)="close()" style="height: 23px;
        border-radius: 4px;"> {{getLanguageTranslationValue(resourceKeys.title_Close)}}</button>
</div>


<div *ngIf="showLoader" class="loader">
</div>