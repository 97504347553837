<!-- Modal Header -->
<div class="modal-header bg-modal modalhead-padding">
    <h4 class="modal-title"> {{getLanguageTranslationValue(resourceKeys.LoginInto)}} </h4>
    <div class="d-flex align-items-center">
        <span class="material-icons modal-close" aria-label="Close" (click)="onNoThanks()">
         close
        </span>
    </div>
</div>

<!-- Modal body -->
<div class="modal-body modal-bodyb py-3">
    <div class="d-flex flex-column" style="width: 100%;">
        <div class="d-flex flex-wrap" style=" justify-content: center;
                   text-align: left;">
             <p *ngIf="downloadFinalContractBtn && !isDeclined && isCopyEmail == false" class="mb-3 font-15">
                {{getLanguageTranslationValue(resourceKeys.Lang_DownloadFinalSignedDoc)}}
            </p>
            <p *ngIf="downloadFinalContractBtn && !isDeclined && isCopyEmail == true" class="mb-3 font-15">
                {{getLanguageTranslationValue(resourceKeys.CopyEmailDownloadFinalDoc)}}
            </p>
            <p *ngIf="downloadFinalContractBtn && isDeclined" class="mb-3 font-15">
                {{getLanguageTranslationValue(resourceKeys.Lang_DeclineDownloadFinalSignedDoc)}}
            </p>
            <p *ngIf="!downloadFinalContractBtn" class="mb-3 font-15">
                {{getLanguageTranslationValue(resourceKeys.DownloadFinalSignedDocText)}}
            </p>
        </div>
        <div *ngIf="isCopyEmail == false" id="divRegisterEmail" class="d-flex align-items-center res-column justify-content-center mb-2">
            <div class="d-flex align-items-center">
                <span class="material-icons me-2 mailicon-color"> mark_email_read </span>
                <span class="font-w600" id="spnEmailText">Email:</span>
            </div>
            <label class="text-black p-1">{{emailAddress}}</label>
        </div>

        <div *ngIf="downloadFinalContractBtn" class="d-flex align-items-center justify-content-center mt-2 pt-1">
            <button type="button" class="btn btn-default btn_accept button-29 ms-3" id="downloadFinalContractBtn"
                (click)="onDownload()"><i class="fa fa-download me-2" aria-hidden="true"></i>Download </button>
        </div>
    </div>
</div>

<!-- Modal footer -->
<div class="modal-footer bg-modal modalfooter-padding">
    <button type="button" id="btnNoThanks" class="btn btncancel px-3 py-1" (click)="onNoThanks()" style="height: 23px;
        border-radius: 4px;"> {{getLanguageTranslationValue(resourceKeys.lang_NOTHANKSUpperCase)}}</button>
    <button type="button" class="btn btn-default btn_accept button-29 ms-3" id="btnSignNewuserUser"
        (click)="onGetStartedHere()">{{getLanguageTranslationValue(resourceKeys.Lang_SignUpUpperCase)}}</button>
</div>
<div *ngIf="showLoader" class="loader">
</div>

<!-- <div class="modal-header bg-modal modalhead-padding">
    <h4 class="modal-title"> We’ve made it easy for you to access your signed record anytime, anywhere.</h4>
    <div class="d-flex align-items-center">
        <span class="material-icons modal-close" aria-label="Close" (click)="onNoThanks()">
            close
        </span>
    </div>
</div>
<div class="modal-body modal-bodyb py-1">
    <div class="d-flex align-items-start res-columnreverse" style="width: 100%;">
        <div class="col-sm-6 w-45 pe-1 pt-4 pb-3">
            <ul class="signer-registration-popup-list ps-0">
                <li class="mb-2 d-flex align-items-center"><span class="material-icons me-2 font-18 primarycolor">done
                    </span><span class="font-14"> {{getLanguageTranslationValue(resourceKeys.SendDocuments)}}</span>
                </li>
                <li class="mb-2 d-flex align-items-center"><span class="material-icons me-2 font-18 primarycolor">done
                    </span><span class="font-14"> {{getLanguageTranslationValue(resourceKeys.ManageDocuments)}}</span>
                </li>
                <li class="mb-2 d-flex align-items-center"><span class="material-icons me-2 font-18 primarycolor">done
                    </span><span class="font-14">
                        {{getLanguageTranslationValue(resourceKeys.CreateAutoReminders)}}</span></li>
                <li class="mb-2 d-flex align-items-center"><span class="material-icons me-2 font-18 primarycolor">done
                    </span><span class="font-14">{{getLanguageTranslationValue(resourceKeys.CreateRules)}}</span></li>
                <li class="mb-2 d-flex align-items-center"><span class="material-icons me-2 font-18 primarycolor">done
                    </span><span class="font-14">
                        {{getLanguageTranslationValue(resourceKeys.AutomateNotifications)}}</span></li>
            </ul>
        </div>
        <div class="col-sm-6 w-55 ps-3 pe-2 pt-4 pb-3">
            <div class="d-flex flex-wrap pe-3">
                <p class="mb-2 font-15"> {{getLanguageTranslationValue(resourceKeys.SignupForFree)}}
                </p>
            </div>
            <div id="divRegisterEmail" class="d-flex align-items-center res-column justify-content-start mb-2">
                <div class="d-flex align-items-center">
                    <span class="material-icons me-2 mailicon-color"> mark_email_read </span>
                    <label class="font-w600">{{getLanguageTranslationValue(resourceKeys.EmailAddress)}}:</label>
                </div>
                <label class="text-black p-1">{{MEmail}}</label>
            </div>
            <div class="d-flex align-items-center justify-content-start mt-2 pt-1">
                <label class="font-14">RSign
                    <a (click)="TermAndService()"
                        class="primarycolor font-w600">{{getLanguageTranslationValue(resourceKeys.TermsAndConditions)}}
                    </a> and
                    <a (click)="PrivacyPolicy()"
                        class="primarycolor font-w600">{{getLanguageTranslationValue(resourceKeys.PrivacyPolicy)}} </a>
                </label>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer bg-modal modalfooter-padding">
    <button type="button" class="btn btncancel px-3 py-1" (click)="onNoThanks()" id="btnNoThanks" style="height: 23px;
        border-radius: 4px;">{{getLanguageTranslationValue(resourceKeys.Lang_NoThanksCamelCase)}} </button>
    <button type="button" class="btn btn-default btn_accept button-29 ms-3" id="btnAccept" (click)="onGetStartedHere()">
        {{getLanguageTranslationValue(resourceKeys.GetStartedHere)}} </button>
</div> -->