import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AttachSignedPDF, CommonConstants, RouteUrls } from '../../constants/common-constants';
import { SignerLandingService } from '../../services/signer-landing.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { ImageService } from 'src/app/services/image.service';
import { CommonService } from 'src/app/services/common.service';
import { ResourceKeys } from 'src/app/constants/common-constants';
import { TranslationsService } from 'src/app/services/translations.service';

@Component({
    selector: 'app-signer-intermediate',
    templateUrl: './signer-intermediate.component.html',
    styleUrls: ['./signer-intermediate.component.scss']
})
export class SignerIntermediateComponent implements OnInit {
    emailAddress: any;
    signerRegObject: any;
    returnUrl: any;
    uiRedirectUrl: any = environment.uiBaseUrl;
    domainUrl: any;
    showLoader: boolean = false;
    MEmail: any;
    spEmail: any;
    isSignedDocumentService: boolean = false
    toastrMessage: any;
    resourceKeys = ResourceKeys;
    attachSignedPdfID: any;
    downloadFinalContractBtn: boolean = false;
    isDeclined: boolean = false;
    mobileNumber: any;
    deliveryMode: any;

    constructor(
        public activeModal: NgbActiveModal,
        private router: Router,
        private commonService: CommonService,
        private toastr: ToastrService,
        private signerLandingService: SignerLandingService,
        private imageService: ImageService,
        private translationsService: TranslationsService
    ) { }

    ngOnInit(): void {
        this.emailAddress = localStorage.getItem('EmailId');
        this.signerRegObject = this;
        this.returnUrl = this.signerRegObject.modalPopupObj.returnUrl;
        this.MEmail = this.signerRegObject.modalPopupObj.MEmail;
        this.spEmail = this.signerRegObject.modalPopupObj.spEmail;
        this.attachSignedPdfID = this.signerRegObject.modalPopupObj.attachSignedPdfID;
        this.isSignedDocumentService = this.signerRegObject.modalPopupObj.isSignedDocumentService;
        this.toastrMessage = this.signerRegObject.modalPopupObj.toastrMessage;
        if (this.emailAddress == null || this.emailAddress == "null" || this.emailAddress == "" || this.emailAddress == undefined)
            this.emailAddress = this.MEmail;

        let isDeclinedText: any = localStorage.getItem('EnvelopeStatus');
        if (isDeclinedText && isDeclinedText == "Declined") {
            this.isDeclined = true;
        }

        this.mobileNumber = localStorage.getItem('RecpMobileNumber');
        this.deliveryMode = localStorage.getItem('RecpDeliveryMode');

        if (this.deliveryMode != "0" && this.deliveryMode != null && this.deliveryMode != "null" && this.deliveryMode != "" && this.deliveryMode != undefined) {
            if (this.deliveryMode == "1" || this.deliveryMode == "11") this.emailAddress = this.emailAddress;
            else if (this.deliveryMode == "4" || this.deliveryMode == "6") {
                this.emailAddress = this.mobileNumber;
                let spnEmailText: any = document.getElementById("spnEmailText")!;
                if (spnEmailText) {
                    spnEmailText.innerHTML = "Mobile:";
                }
            }
            else {
                if (this.mobileNumber != null && this.mobileNumber != "null" && this.mobileNumber != "" && this.mobileNumber != undefined && this.mobileNumber != ' '
                    && this.emailAddress != null && this.emailAddress != "null" && this.emailAddress != "" && this.emailAddress != undefined && this.emailAddress != ' ') {
                    let mobileStr: string = this.mobileNumber;
                    if (mobileStr.length > 7)
                        this.emailAddress = this.emailAddress + " (" + this.mobileNumber + ")";
                    else this.emailAddress = this.emailAddress;
                }
                else if (this.emailAddress != null && this.emailAddress != "null" && this.emailAddress != "" && this.emailAddress != undefined && this.emailAddress != ' ') {
                    this.emailAddress = this.emailAddress;
                }
                else if (this.mobileNumber != null && this.mobileNumber != "null" && this.mobileNumber != "" && this.mobileNumber != undefined && this.mobileNumber != ' ') {
                    this.emailAddress = this.mobileNumber;
                }

                let spnEmailText: any = document.getElementById("spnEmailText")!;
                if (spnEmailText) {
                    spnEmailText.innerHTML = "Email & Mobile:";
                }
            }
        }

        if (this.emailAddress == null || this.emailAddress == "null" || this.emailAddress == "" || this.emailAddress == undefined) {
            let divRegisterEmail: any = document.getElementById("divRegisterEmail")!;
            divRegisterEmail.style.visibility = 'collapse';
        }

        if (this.attachSignedPdfID != null && this.attachSignedPdfID != "" && this.attachSignedPdfID != undefined) {
            if (this.attachSignedPdfID.toUpperCase() == AttachSignedPDF.SenderSigner || this.attachSignedPdfID.toUpperCase() == AttachSignedPDF.RetrievalLink) {
                //show the download button
                this.downloadFinalContractBtn = true;
            }
            else {
                //hide the download button and change text
                this.downloadFinalContractBtn = false;
            }
        }
    }

    onNoThanks() {
        this.activeModal.dismiss('Cross click');
        if (this.toastrMessage != null && this.toastrMessage != undefined) {
            this.toastr.success(this.toastrMessage, 'Success', { timeOut: 2000 });
        }
        if (this.returnUrl != RouteUrls.Info) {
            localStorage.clear();
            sessionStorage.clear();
        }
        this.commonService.getReturnUrlDirection(this.returnUrl);
    }

    onLogin() {
        this.activeModal.dismiss('Cross click');
        localStorage.clear();
        sessionStorage.clear();
        this.domainUrl = CommonConstants.RSignSignIn;
        this.commonService.getReturnUrlDirection(this.domainUrl);
        // window.location.href = this.domainUrl;
    }

    // download pdf
    onDownload() {
        let envelopeID = localStorage.getItem('EnvelopeID');
        let recipientTypeId = localStorage.getItem('recipientTypeId')
        let url: any = CommonConstants.DownloadFinalContract + '/' + envelopeID + '/0/' + recipientTypeId;
        //console.log('aaaa', url);
        this.showLoader = true;
        this.signerLandingService.getDownloadFinalContract(url).subscribe(
            (resp: any) => {
                //console.log('res', resp)
                this.showLoader = false;
                let base64data: any = resp.Base64FileData;
                if (base64data) {
                    var blob = this.commonService.b64toBlob(base64data, resp.FileType);
                    let a = document.createElement('a');
                    document.body.appendChild(a);
                    let bloburl: any = window.URL.createObjectURL(blob);
                    a.href = bloburl;
                    a.download = String(resp.FileName);
                    a.click();
                    window.URL.revokeObjectURL(bloburl);
                    a.remove();
                }
                else if (resp.originalError && resp.originalError.error && resp.originalError.error.Message
                    && resp.originalError.error.Message == CommonConstants.DeleteFinalContractDisplayMsg || this.isSignedDocumentService) {
                    let documentGenerationMessage: any = this.translationsService.getLanguageTranslationVal(ResourceKeys.DocumentGenerationMessage);
                    if (documentGenerationMessage == null || documentGenerationMessage == undefined)
                        documentGenerationMessage = CommonConstants.DocumentGenerationMessage;
                    this.toastr.info(documentGenerationMessage, 'Attention', { timeOut: 2500 });
                    return;
                }
            });
    }
    getLanguageTranslationValue(resourcekeyId: any) {
        return this.translationsService.getLanguageTranslationVal(resourcekeyId);
    }
}
