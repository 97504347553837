import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MessageTypes } from 'src/app/constants/common-constants';

@Component({
    selector: 'app-result',
    templateUrl: './result.component.html',
    styleUrls: ['./result.component.scss']
})
export class ResultComponent implements OnInit {
    showLoader: boolean = false;
    displayMessage: any;
    isErrorFlag: boolean = false;
    isSuccessFlag: boolean = false;
    htmlFooterString: string = '';

    constructor(private titleService:Title) { }

    ngOnInit(): void {
        this.titleService.setTitle("RSign | Result");
        this.htmlFooterString = '<p> '+
        '<a href="https://rpost.com/legal-notices/terms-and-conditions" target="_blank" class="footerLinks">General Terms & Legal Notices</a>: Click to view service level terms, conditions, privacy, '+ 
        '<a class="footerLinks" href="https://rpost.com/legal-notices/legal-and-patent-notice" target="_blank"> patent, trademark</a>, and other service and legal notices. '+
        '</p>';
        this.showLoader = false;        
        this.displayMessage = localStorage.getItem("DisplayMessage");
        let MessageType = localStorage.getItem("MessageType");
        if(this.displayMessage  == null || this.displayMessage == "")
        {
            this.isSuccessFlag = false;
        }
        else{
            if (MessageType == MessageTypes.Error) {
                this.isErrorFlag = true;
            }
            else if (MessageType == MessageTypes.Success) {
                this.isSuccessFlag = true;
            }
        }        
        
        localStorage.clear();
        sessionStorage.clear();
    }

}
