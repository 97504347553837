import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RouteUrls } from 'src/app/constants/common-constants';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-prefill-landing',
  templateUrl: './prefill-landing.component.html',
  styleUrls: ['./prefill-landing.component.scss']
})

export class PrefillLandingComponent implements OnInit { 
  showLoader: boolean = false;  
  IsFromPrepare:boolean = true;
  IsFromSignerLanding:boolean = true;
  IsFromSignerPreLanding: boolean = false;  

  constructor(private router: Router, private commonService: CommonService) { }

  ngOnInit(): void {
    this.showLoader = true;
    localStorage.clear();
    sessionStorage.clear();   
    localStorage.setItem("IsFromPrepare", "true"); 
    localStorage.setItem("actualSigningUrl", window.location.href);
    this.commonService.getReturnUrlDirection(RouteUrls.SignerLanding);
    this.showLoader = false;
  }
}


