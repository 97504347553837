import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SignerLandingService } from '../services/signer-landing.service';
import { CommonConstants, ResourceKeys } from '../constants/common-constants';

@Injectable({
  providedIn: 'root'
})
export class TranslationsService {
  laungaueTransalation = [];
  laungaueValidation = [];
  constructor(private router: Router, private signerLandingService: SignerLandingService) { }

  getLanguageTranslations(type: any, key: any) {
    if (sessionStorage.getItem('LaungaueTransalation') == null || sessionStorage.getItem('LanguageValidation') == null
      || sessionStorage.getItem('LaungaueTransalation') == undefined || sessionStorage.getItem('LanguageValidation') == undefined) {

      let envId: any = localStorage.getItem("EnvelopeID");
      if (envId == "null") { envId = null; }
      let rectId: any = localStorage.getItem("RecipientID");
      if (rectId == "null") { rectId = null; }
      let requestObj = {
        EnvelopeId: envId,
        RecipientId: rectId,
        CultureInfo: ""
      };

      let culture: any = localStorage.getItem("cultureInfo");
      if (requestObj.EnvelopeId == null || requestObj.RecipientId == null) {
        requestObj.CultureInfo = culture != null ? culture : "en-us";
      }
      let getLanguageTranslationsUrl = CommonConstants.getLanguageTranslationsUrl;
      this.signerLandingService.getLanguageTranslations(getLanguageTranslationsUrl, requestObj).subscribe(
        (resp: any) => {
          if (resp && resp.StatusCode == 200) {
            let languageTranslations: any = resp.LanguageTranslationsModel;
            sessionStorage.setItem("LaungaueTransalation", JSON.stringify(languageTranslations.Language));
            sessionStorage.setItem("LanguageValidation", JSON.stringify(languageTranslations.LanguageValidation));          
          }
        })
    }
  }

  getLanguageTranslationVal(resourceKeyId: any) {
    let sessionTranslation: any = sessionStorage.getItem("LaungaueTransalation");
    this.laungaueTransalation = JSON.parse(sessionTranslation);
    if (this.laungaueTransalation != null) {
      return this.laungaueTransalation[resourceKeyId.toLowerCase()];
    }
    else {
      if (resourceKeyId == ResourceKeys.LearnMoreTextOnly) {
        return CommonConstants.LearnMoreTextOnly;
      }
      else if (resourceKeyId == ResourceKeys.freeTrial) {
        return CommonConstants.FreeTrialText;
      }
      else if (resourceKeyId == ResourceKeys.RSignMakesESignatureText) {
        return CommonConstants.RSignMakesESignatureText;
      }
      else
        return "";
    }
  }

  // getLanguageValidationVal(keyName: any) {
  //   let languageValidationArray: any = sessionStorage.getItem("LanguageValidation");
  //   let jsonLanguageValidationArray: any = JSON.parse(languageValidationArray);
  //   if (jsonLanguageValidationArray && jsonLanguageValidationArray.length) {
  //     let lang_ValidationSuccessArray = jsonLanguageValidationArray.filter((c: any) => c.KeyName.toLowerCase() == keyName.toLowerCase());
  //     if (lang_ValidationSuccessArray.length > 0) {
  //       return lang_ValidationSuccessArray[0].KeyValue;
  //     }
  //   }
  //   else {
  //     return "";
  //   }
  // }
  
  getLanguageValidationVal(keyName: any) {
    let languageValidationArray: any = sessionStorage.getItem("LanguageValidation");
    this.laungaueValidation = JSON.parse(languageValidationArray);
    if (this.laungaueValidation != null) {
      return this.laungaueValidation[keyName];
    }
    else {
      return "";
    }
  }

}
