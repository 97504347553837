import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonConstants, ResourceKeys, RouteUrls, AttachSignedPDF, EmailAddressFormat } from 'src/app/constants/common-constants';
import { SignerLandingService } from 'src/app/services/signer-landing.service';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import { TranslationsService } from 'src/app/services/translations.service';

@Component({
    selector: 'app-change-signer-modal',
    templateUrl: './change-signer-modal.component.html',
    styleUrls: ['./change-signer-modal.component.scss']
})
export class ChangeSignerModalComponent implements OnInit {

    commonConstants = CommonConstants;
    showLoader: boolean = false;
    changeSigerObj: any;
    commentsText: string = '';
    IsMultiSigner: boolean = false;
    resourceKeys = ResourceKeys;
    attachSignedPdfID: boolean = false;
    documentControlsResponse: any;
    DialCodeDropdownList: any;
    EnableMessageToMobile: any;
    webOrMobile: any = 'web';
    slCountryMaxMobileLength: any;

    constructor(
        public activeModal: NgbActiveModal,
        private toastr: ToastrService,
        private signerLandingService: SignerLandingService,
        private router: Router,
        private commonService: CommonService,
        private translationsService: TranslationsService
    ) { }

    ngOnInit(): void {
        this.changeSigerObj = this;
        this.IsMultiSigner = this.changeSigerObj.modalPopupObj.isMultiTemplate;
        let documentControlsResp: any = localStorage.getItem("documentControlsResponse");
        this.documentControlsResponse = JSON.parse(documentControlsResp);
        if (this.documentControlsResponse && this.documentControlsResponse.AttachSignedPdfID.toLowerCase() != AttachSignedPDF.SenderOnlyandNoemailtoRecipient.toLowerCase() && this.documentControlsResponse.AttachSignedPdfID.toLowerCase() != AttachSignedPDF.SenderRetrievallinkandNoemailtoRecipient.toLowerCase()) {
            this.attachSignedPdfID = true;
        }
        else {
            this.attachSignedPdfID = false;
        }

        this.DialCodeDropdownList = this.documentControlsResponse.DialCodeDropdownList;
        this.EnableMessageToMobile = (this.documentControlsResponse.EnableMessageToMobile == null || this.documentControlsResponse.EnableMessageToMobile == false) ? false : true;

        if ((/android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
            this.webOrMobile = 'mobile';
        }

        setTimeout(() => {
            let chkEmail: any = document.getElementById("chkEmail");
            if (chkEmail) {
                chkEmail?.setAttribute('checked', 'checked');
            }
            $($('#DialCodeUl li a')[0]).css('background-color', '#bc0001')
        }, 50);
    }

    form = new FormGroup({
        name: new FormControl('', [Validators.required, Validators.minLength(3)]),
        email: new FormControl('', [Validators.required, Validators.email]),
        body: new FormControl('', Validators.required),
        sendFinalSignDocumentChangeSigner: new FormControl(false)
    });

    get f() {
        return this.form.controls;
    }

    validateEmail(email: any) {
        // var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
        let re = EmailAddressFormat.ValidEmail;
        let status = re.test(email);
        return re.test(email);
    }

    onChangeSignersubmit() {
        let deliveryMode = "";
        let isChecked = false;
        let isValid = true;
        let dialCode = "";
        let mobileNumber = "";
        let spnClassListVal: any = '';
        let countrycode: any = '';

        if (this.form.value.name == '') {
            isValid = false;
            this.toastr.warning('Name is required.', 'Warning', { timeOut: 1000 });
            return;
        }

        if (this.EnableMessageToMobile) {
            let chkEmail: any = <HTMLSelectElement>document.getElementById("chkEmail");
            let isEmailChecked: boolean = chkEmail.checked ? true : false;

            if (isEmailChecked) {
                isChecked = true;
                let signerMail = this.form.value.email;
                if (signerMail == "") {
                    isValid = false;
                    this.toastr.warning('Please provide email address.', 'Warning', { timeOut: 1000 });
                    return;
                }
                else if (!this.validateEmail(this.form.value.email)) {
                    isValid = false;
                    this.toastr.warning('Invalid email.', 'Warning', { timeOut: 1000 });
                    return;
                }
            }
            else {
                isChecked = false;
            }

            let chkMobile: any = <HTMLSelectElement>document.getElementById("chkMobile");
            let isMobileChecked: boolean = chkMobile.checked ? true : false;
            if (isMobileChecked) {
                isChecked = true;
                let txtSignerMobileNumber: any = <HTMLSelectElement>document.getElementById("txtSignerMobileNumber");
                dialCode = $("#spnTitle").text();

                if (dialCode == "" || dialCode == "Select") {
                    isValid = false;
                    this.toastr.warning('Please select country code.', 'Warning', { timeOut: 1000 });
                    return;
                }
                if (txtSignerMobileNumber.value == "" || txtSignerMobileNumber.value == undefined) {
                    isValid = false;
                    this.toastr.warning('Please provide mobile number.', 'Warning', { timeOut: 1000 });
                    return;
                }
                else {
                    mobileNumber = txtSignerMobileNumber.value;
                    if (!this.isValidPhone(txtSignerMobileNumber.value)) {
                        isValid = false;
                        this.toastr.warning('Please provide valid mobile number.', 'Warning', { timeOut: 1000 });
                        return;
                    }
                }

                let spnClassList: any = $("#spnTitle").find('span')[0].classList;
                if (spnClassList && spnClassList.length > 0) {
                    spnClassListVal = spnClassList[0];
                    countrycode = spnClassListVal.split('-')[1];
                }

            }
            else {
                if (!isChecked) {
                    isChecked = false;
                }
            }

            if (isChecked == false) {
                isValid = false;
                this.toastr.warning('Please select invite by email or mobile.', 'Warning', { timeOut: 1000 });
                return;
            }

            if (isEmailChecked && isMobileChecked) {
                deliveryMode = "10";
            } else if (isEmailChecked) {
                deliveryMode = "1";
            }
            else if (isMobileChecked) {
                deliveryMode = "4";
            }
        }
        else {
            deliveryMode = "1";
            let signerMail = this.form.value.email;
            if (signerMail == "") {
                isValid = false;
                this.toastr.warning('Please provide email address.', 'Warning', { timeOut: 1000 });
                return;
            }
            else if (!this.validateEmail(this.form.value.email)) {
                isValid = false;
                this.toastr.warning('Invalid email.', 'Warning', { timeOut: 1000 });
                return;
            }
        }

        if (isValid) {
            this.showLoader = true;
            if (this.IsMultiSigner) {
                let url = this.commonConstants.DelegateMultiSigner;
                let paramsObj = {
                    'SignerName': this.form.value.name,
                    'SignerEmail': this.form.value.email,
                    'EnvelopeID': localStorage.getItem("EnvelopeID"),
                    'RecipientID': this.changeSigerObj.modalPopupObj.recipientID,
                    'SignerComments': this.commentsText,
                    'UserEmailAddress': localStorage.getItem("CurrentRecipientEmail"),
                    'CultureInfo': localStorage.getItem("cultureInfo"),
                    'IPAddress': '',
                    'DeliveryMode': deliveryMode,
                    'DialCode': dialCode,
                    'MobileNumber': mobileNumber,
                    'CountryCode': countrycode,
                    'SendFinalSignDocumentChangeSigner': this.form.value.sendFinalSignDocumentChangeSigner
                }
                this.signerLandingService.changeSigner(url, paramsObj).subscribe(
                    (resp: any) => {
                        this.showLoader = false;
                        this.activeModal.dismiss('Cross click');
                        if (resp && resp.StatusCode == 200) {
                            let delegateDisplayMessage: any = "";
                            if (deliveryMode == "10" && this.form.value.email != "" && this.form.value.email != null && this.form.value.email != undefined
                                && dialCode != "" && dialCode != null && dialCode != undefined && mobileNumber != "" && mobileNumber != null && mobileNumber != undefined) {
                                delegateDisplayMessage = resp.Message + " " + this.form.value.email + " (" + dialCode + mobileNumber + ")";
                            }
                            else if (deliveryMode == "1" && this.form.value.email != "" && this.form.value.email != null && this.form.value.email != undefined) {
                                delegateDisplayMessage = resp.Message + " " + this.form.value.email;
                            }
                            else if (deliveryMode == "4" && dialCode != "" && dialCode != null && dialCode != undefined && mobileNumber != "" && mobileNumber != null && mobileNumber != undefined) {
                                delegateDisplayMessage = resp.Message + " " + (dialCode + mobileNumber);
                            }

                            this.toastr.success(delegateDisplayMessage, 'Success', { timeOut: 2000 });
                        }
                        else if (resp.originalError != null && resp.originalError.error.StatusCode == 400) {
                            this.toastr.warning(resp.originalError.error.Message, 'Warning', { timeOut: 2000 });
                        }
                        else if (resp.StatusCode == 403) {
                            this.toastr.warning(resp.Message, 'Warning', { timeOut: 2000 });
                        }
                        else {
                            this.toastr.warning(resp.Message, 'Warning', { timeOut: 2000 });
                        }
                    },
                    error => {
                        this.showLoader = false;
                        this.toastr.warning('Error occurred while delegating envelope.', 'Warning', { timeOut: 2000 });
                    })
            }
            else {
                let url = this.commonConstants.ChangeSigner;
                let paramsObj = {
                    'SignerName': this.form.value.name,
                    'SignerEmail': this.form.value.email,
                    'EnvelopeID': localStorage.getItem("EnvelopeID"),
                    'CurrentRecipientID': localStorage.getItem("RecipientID"),
                    'SignerComments': this.commentsText,
                    'CultureInfo': localStorage.getItem("cultureInfo"),
                    'IPAddress': '',
                    'DeliveryMode': deliveryMode,
                    'DialCode': dialCode,
                    'MobileNumber': mobileNumber,
                    'CountryCode': countrycode,
                    'SendFinalSignDocumentChangeSigner': this.form.value.sendFinalSignDocumentChangeSigner
                }
                this.signerLandingService.changeSigner(url, paramsObj).subscribe(
                    (resp: any) => {
                        this.showLoader = false;
                        if (resp && resp.StatusCode == 200) {
                            this.activeModal.dismiss('Cross click');
                            let delegateDisplayMessage: any = "";
                            if (deliveryMode == "10" && this.form.value.email != "" && this.form.value.email != null && this.form.value.email != undefined
                                && dialCode != "" && dialCode != null && dialCode != undefined && mobileNumber != "" && mobileNumber != null && mobileNumber != undefined) {
                                delegateDisplayMessage = resp.Message + " " + this.form.value.email + " (" + dialCode + mobileNumber + ")";
                            }
                            else if (deliveryMode == "1" && this.form.value.email != "" && this.form.value.email != null && this.form.value.email != undefined) {
                                delegateDisplayMessage = resp.Message + " " + this.form.value.email;
                            }
                            else if (deliveryMode == "4" && dialCode != "" && dialCode != null && dialCode != undefined && mobileNumber != "" && mobileNumber != null && mobileNumber != undefined) {
                                delegateDisplayMessage = resp.Message + " " + (dialCode + mobileNumber);
                            }

                            this.toastr.success(delegateDisplayMessage, 'Success', { timeOut: 2000 });
                          
                            localStorage.setItem("DisplayMessage", delegateDisplayMessage);
                            localStorage.setItem("MessageType", 'Success');
                            localStorage.setItem("IsDelegated", 'true');
                            if (resp.EncryptedSender != '' && resp.EncryptedSender != null) {
                                localStorage.setItem("EncryptedSender", resp.EncryptedSender);
                                this.commonService.getReturnUrlDirection(RouteUrls.Info);
                            }
                            else {
                                localStorage.setItem("EncryptedSender", "");
                                this.commonService.getReturnUrlDirection(RouteUrls.Info);
                            }
                        }
                        else if (resp.originalError.error.StatusCode == 400) {
                            this.activeModal.dismiss('Cross click');
                            this.toastr.warning(resp.originalError.error.Message, 'Warning', {
                                timeOut: 2000
                            });
                            localStorage.setItem("DisplayMessage", resp.originalError.error.Message);
                            localStorage.setItem("MessageType", 'Warning');
                            this.commonService.getReturnUrlDirection(RouteUrls.Info);
                        }
                    },
                    error => {
                        this.showLoader = false;
                        this.toastr.warning('Error occurred while delegating envelope.', 'Warning', { timeOut: 2000 });
                    })
            }
        }
    }

    isValidPhone(phonenumber: any) {
        var isvalidPhoneNumber = true;
        if (phonenumber == "" || phonenumber.length != this.slCountryMaxMobileLength) {
            isvalidPhoneNumber = false;
        }
        return isvalidPhoneNumber;

        //var regex1 = new RegExp(/^\+(?:[0-9] ?){6,14}[0-9]$/);
        //if (phonenumber == null || phonenumber == "")
        //    return false;       
        //if (regex1.test(phonenumber) == true)
        //    return true;       
        //else
        //    return false;
    }

    commentsTextareaInput(e: any) {
        this.commentsText = e.target.value.trim();
        this.form.value.body = this.commentsText;
    }

    getLanguageTranslationValue(resourcekeyId: any) {
        return this.translationsService.getLanguageTranslationVal(resourcekeyId);
    }

    selectedCountryDialCode(event: any) {
        if (this.EnableMessageToMobile) {
            this.DialCodeDropdownList = this.documentControlsResponse.DialCodeDropdownList;
            $($('#DialCodeUl li a')[0]).css('background-color', '');
            let ed: any = event.target;
            let edd = event.target.parentElement.innerHTML;
            let spnTitle: any = document.getElementById("spnTitle");
            let dataCountryCodeMaxLength: any;
            let txtSignerMobileNumber: any = document.getElementById("txtSignerMobileNumber");
            if (spnTitle != null) {
                spnTitle.innerHTML = '';
                spnTitle.innerHTML = edd;

                if (spnTitle.textContent != "" && spnTitle.textContent != null && spnTitle.textContent != undefined && spnTitle.textContent != "Select") {
                    let spnTitleText: string = "+" + spnTitle.textContent.split('+')[1].replace("(", '').replace(")", '');
                    spnTitle.innerHTML = edd.replace(spnTitle.textContent, spnTitleText);

                    let spnCountryFlagCol: any = document.getElementById("spnTitle")!.getElementsByTagName("span");
                    if (spnCountryFlagCol && spnCountryFlagCol.length > 0) {
                        document.getElementById(spnCountryFlagCol[0].id)!.style.top = "4px";
                        $('#DialCodeUl li a').removeClass('selected');
                        $('#DialCodeUl li ').removeClass('selected');
                        $('#spnTitle a').removeClass('selected');
                        if (event.target.parentElement.getElementsByTagName('a').length > 0) {
                            dataCountryCodeMaxLength = event.target.parentElement.getElementsByTagName('a')[0].getAttribute("data-maxlength");
                            event.target.parentElement.getElementsByTagName('a')[0].classList.add('selected');
                        }
                        else {
                            dataCountryCodeMaxLength = event.target.parentElement.getAttribute("data-maxlength");
                            event.target.parentElement.classList.add('selected');
                        }
                        txtSignerMobileNumber.value = '';
                        if (dataCountryCodeMaxLength != null && dataCountryCodeMaxLength != undefined) {
                            txtSignerMobileNumber.setAttribute('maxlength', dataCountryCodeMaxLength);
                            this.slCountryMaxMobileLength = dataCountryCodeMaxLength;
                        }
                    }
                    let countrySearchinput: any = document.getElementById("countrySearchinput");
                    countrySearchinput.value = '';
                }
                else {
                    $('#DialCodeUl li a').removeClass('selected');
                    $('#DialCodeUl li ').removeClass('selected');
                    $($('#DialCodeUl li a')[0]).css('background-color', '#bc0001')
                }

            }
        }
    }

    // isNumberKey(evt: any) {
    //     var regExpNumber = /[0-9]/;
    //     var charCode = String.fromCharCode(evt.charCode ? evt.which : evt.charCode);
    //     if (regExpNumber.test(charCode)) {
    //         return true;
    //     } else {
    //         evt.preventDefault();
    //         this.toastr.warning('Please enter only a number.', 'Warning', { timeOut: 1000 });
    //         return false;
    //     }       
    // }

    validateInputValue(event: any) {
        let txtSignerMobileNumber: any = event.target.value;
        if (!this.commonService.isNumberExists(txtSignerMobileNumber)) {
            event.target.value = '';
            this.toastr.warning('Please enter only a number.', 'Warning', { timeOut: 1000 });
        }
    }

    getFilteredCountryNames(event: any) {
        $($('#DialCodeUl li a')[0]).css('background-color', '');
        let countryArray: any[] = [];
        let countrySearchinput: any = document.getElementById("countrySearchinput");
        if (countrySearchinput != null && countrySearchinput.value != "") {
            countrySearchinput = countrySearchinput.value.toLowerCase();
            Array.from(this.documentControlsResponse.DialCodeDropdownList).forEach((c: any) => {
                if (c.CountryName.toLowerCase().indexOf(countrySearchinput) == 0) {
                    countryArray.push(c);
                }
            });
            this.DialCodeDropdownList = countryArray;
        }
        else {
            this.DialCodeDropdownList = this.documentControlsResponse.DialCodeDropdownList;
        }

    }
}
