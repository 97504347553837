import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonConstants, RouteUrls } from 'src/app/constants/common-constants';
import { SignerLandingService } from 'src/app/services/signer-landing.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-security-code',
  templateUrl: './security-code.component.html',
  styleUrls: ['./security-code.component.scss']
})
export class SecurityCodeComponent implements OnInit {
  showLoader: boolean = false;
  copyEmailId: string = "";
  htmlFooterString: string = '';

  constructor(private router: Router, private signerLandingService: SignerLandingService,
    private toastr: ToastrService, private commonService: CommonService, private titleService:Title) { }

  ngOnInit(): void {
    this.titleService.setTitle("RSign | Security Code");
    this.htmlFooterString = '<p> ' +
      '<a href="https://rpost.com/legal-notices/terms-and-conditions" target="_blank" class="footerLinks">General Terms & Legal Notices</a>: Click to view service level terms, conditions, privacy, ' +
      '<a class="footerLinks" href="https://rpost.com/legal-notices/legal-and-patent-notice" target="_blank"> patent, trademark</a>, and other service and legal notices. ' +
      '</p>';
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem("documentControlsResponse", '');
    localStorage.setItem("responseSigningUrlModel", '');
    let securityCodeUrl: any = window.location.href;
    securityCodeUrl = securityCodeUrl.substring(0, securityCodeUrl.length - 1);
    localStorage.setItem("securityCodeUrl", securityCodeUrl);
    let securityCodeValidUrl = decodeURI(securityCodeUrl.split('?')[1]);
   
    if (securityCodeValidUrl != undefined && securityCodeValidUrl != "undefined" && securityCodeValidUrl != null && securityCodeValidUrl != "") {
      this.getDecryptSecurityCodeUrl(securityCodeValidUrl);
    }
  }

  getDecryptSecurityCodeUrl(securityCodeUrl: string) {
    let decryptSecurityCodeUrl = CommonConstants.DecryptSecurityCodeUrl;
    let paramsObj = { SecurityCodeUrl: securityCodeUrl };
    this.signerLandingService.getDecryptSigningData(decryptSecurityCodeUrl, paramsObj).subscribe(
      (resp: any) => {
        if (resp && resp.StatusCode == 200 && resp.StatusMessage.toLowerCase() == "ok") {
          this.showLoader = false;
          this.copyEmailId = resp.CopyMailId;
          localStorage.setItem("CopyMailId", resp.CopyMailId);
        }
        else if (resp && resp.originalError && resp.originalError.error && resp.originalError.error.StatusCode == 400) {
          this.showLoader = false;
          localStorage.setItem("DisplayMessage", resp.originalError.error.StatusMessage);
          localStorage.setItem("MessageType", "Warning");
          this.commonService.getReturnUrlDirection(RouteUrls.Info);
        }
      })
  }

  submitSecurityCode() {
    let txtSecurityCodeInput: any = document.getElementById("txtSecurityCode");
    let txtSecurityCode = txtSecurityCodeInput.value.trim();
    if (txtSecurityCode.length > 0) {
      let copyEmail: any = this.copyEmailId == "" ? "false" : this.copyEmailId;
      let validateRecipientBySecurityCode: any = CommonConstants.ValidateRecipientBySecurityCode + '/' + txtSecurityCode + '/' + copyEmail;
      this.showLoader = true;
      this.signerLandingService.validateRecipientBySecurityCode(validateRecipientBySecurityCode).subscribe(
        (resp: any) => {
          if (resp && resp.StatusCode == 200 && resp.StatusMessage.toLowerCase() == "ok") {
            this.showLoader = false;
            let message: any;
            if (resp.IsEnvelopePurging == true || resp.IsEnvelopePurging) {
              this.toastr.warning(resp.message, 'Warning', { timeOut: 2500 });
            }
            else if (resp.success) {
              message = resp.message;
              window.location.href = resp.returnUrl;
            }
            else {
              message = resp.message;
              this.toastr.warning('Please enter the security code.', 'Warning', { timeOut: 1500 });
            }
          }
          else if (resp.originalError.error.StatusCode == 400) {
            this.showLoader = false;
            this.toastr.warning(resp.originalError.error.message, 'Warning', { timeOut: 1500 });
          }
        })
    }
    else {
      this.toastr.warning('Please enter the security code.', 'Warning', { timeOut: 1500 });
      this.showLoader = false;
    }
  }
}
