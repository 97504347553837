import { Component, HostListener, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'eSign';
  
  @HostListener('window:popstate', ['$event'])
  onPopState(event:any) {
    console.log('Back button pressed');
    window.location.reload();
  }  

  ngOnInit() {
    this.loadDropBoxScript();
  }

  private loadDropBoxScript() {   
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://www.dropbox.com/static/api/2/dropins.js';
    script.id = 'dropboxjs';
    script.setAttribute("data-app-key", environment.dropBoxKey);
    const head = document.getElementsByTagName('head')[0];
    if (head !== null && head !== undefined) {
      document.head.appendChild(script);
    }
  }
}
