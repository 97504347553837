import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonConstants, EnvelopeStatus, ResourceKeys, RouteUrls } from '../../constants/common-constants';
import { SignerLandingService } from '../../services/signer-landing.service';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { SignerRegistrationComponent } from '../signer-registration/signer-registration.component';
import { SignerIntermediateComponent } from '../signer-intermediate/signer-intermediate.component';
import { SignerIntermediateContinueComponent } from '../signer-intermediate-continue/signer-intermediate-continue.component';
import { TranslationsService } from 'src/app/services/translations.service';
import { SignerIntermediateCloseComponent } from '../signer-intermediate-close/signer-intermediate-close.component';
@Component({
    selector: 'app-decline-modal',
    templateUrl: './decline-modal.component.html',
    styleUrls: ['./decline-modal.component.scss']
})
export class DeclineModalComponent implements OnInit {
    commonConstants = CommonConstants;

    showLoader: boolean = false;
    declinedObj: any = {
        id: 0,
        value: ''
    }
    rejectText: string = '';
    title: string = '';
    selectAtleastOneOption: string = '';
    errorTitle: string = '';
    reasonList: any = [];
    declineparentObj: any;
    controlType: any;
    selectedDeclineReasonID: any;
    isStatic: any = false;
    IsOtherCommentsTextEntered: boolean = false;
    declineTemplateResponsesData: any = [];
    ShowFinishLaterNavMenu: boolean = false;
    resourceKeys = ResourceKeys;
    AllowSignersDownloadFinalContract: boolean = false;
    documentControlsResponse: any;

    constructor(
        public activeModal: NgbActiveModal,
        private toastr: ToastrService,
        private signerLandingService: SignerLandingService,
        private modalService: NgbModal,
        private router: Router,
        private commonService: CommonService,
        private translationsService: TranslationsService
    ) { }

    ngOnInit(): void {
        this.selectAtleastOneOption = this.commonConstants.SelectAtleastOneOption;
        this.errorTitle = this.commonConstants.ErrorTitle;
        this.declineparentObj = this;
        // this.isStatic = localStorage.getItem("isStatic");
        // let isStaticTemplate:boolean =  Boolean(this.isStatic);
        this.isStatic = localStorage.getItem("isStaticTemplate");
        let isStaticTemplate: boolean = Boolean(this.isStatic);
        if (this.isStatic == "false" || this.isStatic == false) {
            isStaticTemplate = false;
        }
        else if (this.isStatic == "true" || this.isStatic == true) {
            isStaticTemplate = true;
        }
        else {
            isStaticTemplate = false;
        }

        let ShowFinishLaterNavMenu: any = localStorage.getItem("ShowFinishLaterNavMenu");

        if (ShowFinishLaterNavMenu == "true") {
            this.ShowFinishLaterNavMenu = true;
        }
        else {
            this.ShowFinishLaterNavMenu = false;
        }

        let documentControlsResp: any = localStorage.getItem("documentControlsResponse");
        this.documentControlsResponse = JSON.parse(documentControlsResp);
        this.AllowSignersDownloadFinalContract = true;//this.documentControlsResponse.AllowSignersDownloadFinalContract;

        let envelopeId: any = localStorage.getItem("EnvelopeID");
        this.showLoader = true;
        let url = this.commonConstants.GetDeclineTemplate + '/' + envelopeId + '/' + isStaticTemplate;
        this.signerLandingService.getDeclineTemplate(url).subscribe(
            (resp: any) => {
                this.showLoader = false;
                if (resp && resp.Data && resp.Data.DeclineTemplateResponsesList) {
                    this.title = resp.Data.Title;
                    this.controlType = resp.Data.ControlType;
                    this.reasonList = resp.Data.DeclineTemplateResponsesList;
                }
            });
    }

    onOtherCLick(selectedRadio: any, reason: any, type: any) {
        if ((reason.ID.toString() === "999" || reason.ID.toString() === 999) && reason.ResponseText === 'Other') {
            let radioOrCheckboxEle: any = 'radio_' + reason.ID;
            if (this.controlType === 2) {
                radioOrCheckboxEle = 'checkbox_' + reason.ID;
            }
            if ((<HTMLInputElement>document.getElementById(radioOrCheckboxEle)).checked === true) {
                selectedRadio.style.display = 'block';
            } else {
                selectedRadio.style.display = 'none';
            }
        }
        if (type === 'radio' && (reason.ID.toString() !== "999" || reason.ID.toString() !== 999) && reason.ResponseText !== 'Other') {
            selectedRadio.style.display = 'none';
        }
    }

    showToastrError(msg: string, type: string) {
        this.toastr.error(msg, type, {
            timeOut: 2000
        });
    }

    showToastrWarning(msg: string) {
        this.toastr.warning(msg, 'Warning', {
            timeOut: 2000
        });
    }

    getFinalValidation() {
        if (document.querySelectorAll("input[name='DeclineReasonRadioGroup1']:checked").length > 0 || this.controlType === 3) {
            let declineReasonId: any;
            let comments: any;
            let docOrder: any = 0;
            let count: any = 0;
            var declineTemplateResponses: any = [];
            if (this.rejectText !== null && this.rejectText !== '' && this.rejectText !== 'undefined') {
                comments = this.rejectText;
                count = comments.length;
            }
            if (this.controlType === 1) {
                this.IsOtherCommentsTextEntered = false;
                Array.from(document.querySelectorAll("input[name='DeclineReasonRadioGroup1']:checked") as NodeListOf<HTMLElement>)
                    .forEach((imgItem: any) => {
                        let responseId: any = imgItem.id;
                        let slDecReasonId: string = responseId.replace("radio_", '');
                        slDecReasonId = slDecReasonId.replace("checkbox_", '');

                        let responseText = imgItem.value;
                        if (responseId == '999' || responseId == 999 || responseText == 'Other' &&
                            (<HTMLInputElement>document.getElementById(responseId)).checked === true) {
                            if (this.rejectText == null || this.rejectText == '') {
                                this.showToastrWarning(this.errorTitle);
                                this.IsOtherCommentsTextEntered = false;
                            } else {
                                this.IsOtherCommentsTextEntered = true;
                                comments = this.rejectText;
                            }
                            responseText = this.rejectText;
                        } else {
                            comments = responseText;
                            this.IsOtherCommentsTextEntered = true;
                        }

                        docOrder++;
                        declineTemplateResponses.push({
                            ID: parseInt(slDecReasonId),
                            ResponseText: comments,
                            Order: docOrder
                        });
                        this.selectedDeclineReasonID = responseId;
                        this.declineTemplateResponsesData = declineTemplateResponses;
                    })
            } else if (this.controlType === 2) {
                Array.from(document.querySelectorAll("input[name='DeclineReasonRadioGroup1']:checked") as NodeListOf<HTMLElement>)
                    .forEach((imgItem: any) => {
                        let responseId = imgItem.id;
                        let slDecReasonId: string = responseId.replace("radio_", '');
                        slDecReasonId = slDecReasonId.replace("checkbox_", '');
                        let responseText = imgItem.value;
                        if (responseId == '999' || responseId == 999 || responseText == 'Other' &&
                            (<HTMLInputElement>document.getElementById(responseId)).checked === true) {
                            if (this.rejectText == null || this.rejectText == '') {
                                this.showToastrWarning(this.errorTitle);
                                this.IsOtherCommentsTextEntered = false;
                            } else {
                                this.IsOtherCommentsTextEntered = true;
                            }
                            responseText = this.rejectText;
                        } else {
                            this.IsOtherCommentsTextEntered = true;
                        }

                        docOrder++;
                        declineTemplateResponses.push({
                            ID: parseInt(slDecReasonId),
                            ResponseText: responseText,
                            Order: docOrder
                        });
                        this.selectedDeclineReasonID = responseId;
                        this.declineTemplateResponsesData = declineTemplateResponses;
                    })
            } else {
                if (this.rejectText == null || this.rejectText == '') {
                    this.showToastrWarning(this.errorTitle);
                    this.IsOtherCommentsTextEntered = false;
                } else if (count > 200) {
                    this.showToastrWarning('Comments should not be more than 250 characters.')
                } else {
                    this.IsOtherCommentsTextEntered = true;
                }

                docOrder++;
                declineTemplateResponses.push({
                    ID: 999,
                    ResponseText: comments,
                    Order: docOrder
                });
                this.selectedDeclineReasonID = 999;
                this.declineTemplateResponsesData = declineTemplateResponses;
            }
        } else {
            this.showToastrWarning(this.selectAtleastOneOption);
        }
        if (this.IsOtherCommentsTextEntered) {
            this.UpdatedEvelopeStatusbySigner();
        }
    }

    UpdatedEvelopeStatusbySigner() {
        let envelopeId: any = localStorage.getItem("EnvelopeID");
        let recipientId: any = localStorage.getItem("RecipientID");
        let copyMailId: any = localStorage.getItem("CopyMailId");
        if (this.selectedDeclineReasonID) {
            let slDecReasonId: string = this.selectedDeclineReasonID.toString();
            slDecReasonId = slDecReasonId.replace("radio_", '');
            slDecReasonId = slDecReasonId.replace("checkbox_", '');
            this.selectedDeclineReasonID = parseInt(slDecReasonId);

            let paramsObj = {
                ID: envelopeId,
                RecipientID: recipientId,
                SignerStatusDescription: JSON.stringify(this.declineTemplateResponsesData),
                DeclineReasonID: parseInt(slDecReasonId),
                IPAddress: '', //localStorage.getItem("IPAddess"),
                CopyEmailAddress: copyMailId == "undefined" ? "" : copyMailId,
                RSignAnonymousToken: ''
            }
            //  console.log('this.paramsObj', paramsObj);
            let url = this.commonConstants.UpdatedEvelopeStatusbySigner;
            this.showLoader = true;
            this.signerLandingService.updatedEvelopeStatusbySigner(url, paramsObj).subscribe(
                (resp: any) => {
                    this.showLoader = false;
                    let returnUrl: any = '';
                    let encryptedSender: any = '';
                    let DisplayMessage: any = '';
                    let LanguageID: any = '';
                    let Success: boolean = false;
                    let MessageType: any = '';

                    if (resp && resp.StatusCode == 200) {
                        Success = true;
                        DisplayMessage = resp.Message;
                        MessageType = "Success";

                        encryptedSender = resp.EncryptedSender;
                        if (resp.IsNonRegisteredUser) {
                            returnUrl = RouteUrls.Info;
                            Success = false;
                            DisplayMessage = resp.Message;
                            LanguageID = resp.CultureInfo;
                            MessageType = "Warning";
                            this.activeModal.dismiss('Cross click');
                            localStorage.setItem("MessageType", MessageType);
                            localStorage.setItem("DisplayMessage", DisplayMessage);
                            localStorage.setItem("LanguageID", LanguageID);
                            this.commonService.getReturnUrlDirection(returnUrl);
                            return;
                        }
                        else {
                            // returnUrl = RouteUrls.Result;
                            returnUrl = RouteUrls.Info;
                        }
                        localStorage.setItem("MessageType", MessageType);
                        localStorage.setItem("DisplayMessage", DisplayMessage);
                        localStorage.setItem("LanguageID", LanguageID);
                    }
                    else if (resp && resp.originalError.error.StatusCode == 400) {
                        Success = false;
                        MessageType = "Warning";
                        if(resp.originalError.error && resp.originalError.error.Message) DisplayMessage = resp.originalError.error.Message;
                        else if(resp.originalError.error && resp.originalError.error.message) DisplayMessage = resp.originalError.error.message;
                        else if(resp.originalError.error) DisplayMessage = resp.originalError.error;                        
                        LanguageID = resp.CultureInfo;
                        encryptedSender = resp.EncryptedSender;
                        returnUrl = RouteUrls.Info;
                        localStorage.setItem("MessageType", MessageType);
                    }

                    if (returnUrl != '') {
                        //localStorage.setItem("encryptedSender", encryptedSender);
                        localStorage.setItem("DisplayMessage", DisplayMessage);
                        localStorage.setItem("LanguageID", LanguageID);
                        let modalPopupObj = {
                            returnUrl: returnUrl,
                            MEmail: resp.EmailAddress,
                            spEmail: resp.EmailAddress,
                            isSignedDocumentService: false,
                            type: "decline",
                            attachSignedPdfID:resp.AttachSignedPdfID
                        };

                        if (localStorage.getItem("prefill") == 'prefill') {
                            this.activeModal.dismiss('Cross click');
                            this.commonService.getReturnUrlDirection(returnUrl);
                        }
                        else if (resp.EnablePostSigningLoginPopup == false) {
                            this.activeModal.dismiss('Cross click');
                            this.commonService.getReturnUrlDirection(returnUrl);
                        }
                        else {
                            this.activeModal.dismiss('Cross click');

                            localStorage.setItem("EnvelopeStatus", "Declined");

                            if (this.AllowSignersDownloadFinalContract == true) {
                                if (resp.RecipientOrder == 0) {
                                    if (resp.EnvelopeStatus.toUpperCase()  == EnvelopeStatus.Completed || resp.EnvelopeStatus.toUpperCase()  == EnvelopeStatus.Terminated) {
                                        const modalRef = this.modalService.open(SignerRegistrationComponent, { size: 'md', keyboard: false, backdrop: 'static', windowClass: 'signerRegistration-class' });
                                        modalRef.componentInstance.modalPopupObj = modalPopupObj;
                                    }
                                    else {
                                        const modalRef = this.modalService.open(SignerIntermediateCloseComponent, { size: 'md', keyboard: false, backdrop: 'static', windowClass: 'signerIntermediate-class' });
                                        modalRef.componentInstance.modalPopupObj = modalPopupObj;
                                    }
                                }
                                else if (resp.RecipientOrder == 1) {
                                    if (resp.EnvelopeStatus.toUpperCase() == EnvelopeStatus.Completed || resp.EnvelopeStatus.toUpperCase()  == EnvelopeStatus.Terminated) {
                                        const modalRef = this.modalService.open(SignerIntermediateComponent, { size: 'md', keyboard: false, backdrop: 'static', windowClass: 'signerIntermediate-class' });
                                        modalRef.componentInstance.modalPopupObj = modalPopupObj;
                                    }
                                    else {
                                        const modalRef = this.modalService.open(SignerIntermediateCloseComponent, { size: 'md', keyboard: false, backdrop: 'static', windowClass: 'signerIntermediate-class' });
                                        modalRef.componentInstance.modalPopupObj = modalPopupObj;
                                    }
                                }
                                else if (resp.RecipientOrder == 2) {
                                    if (resp.EnvelopeStatus.toUpperCase()  == EnvelopeStatus.Terminated) {
                                        const modalRef = this.modalService.open(SignerIntermediateComponent, { size: 'md', keyboard: false, backdrop: 'static', windowClass: 'signerIntermediate-class' });
                                        modalRef.componentInstance.modalPopupObj = modalPopupObj;
                                    }
                                    else {
                                        const modalRef = this.modalService.open(SignerIntermediateCloseComponent, { size: 'md', keyboard: false, backdrop: 'static', windowClass: 'signerIntermediate-class' });
                                        modalRef.componentInstance.modalPopupObj = modalPopupObj;
                                    }
                                    // const modalRef = this.modalService.open(SignerIntermediateContinueComponent, { size: 'md', keyboard: false, backdrop: 'static', windowClass: 'signerIntermediateContinue-class' });
                                    // modalRef.componentInstance.modalPopupObj = modalPopupObj;
                                }
                                else {
                                    this.commonService.getReturnUrlDirection(returnUrl);
                                }
                            }
                            else {
                                this.commonService.getReturnUrlDirection(returnUrl);
                            }
                        }
                    }
                    else {
                        this.showToastrWarning(DisplayMessage);
                    }
                });
        } else {
            this.showToastrWarning(this.selectAtleastOneOption);
        }
    }

    //other text filed
    textAreaInput(e: any) {
        this.rejectText = e.target.value;
    }
    getLanguageTranslationValue(resourcekeyId: any) {
        return this.translationsService.getLanguageTranslationVal(resourcekeyId);
    }
}
