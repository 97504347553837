export class AppError {
    constructor(public originalError?: any) { }
  
    Message: any;
    MessageId: any;
  }
export class TokenError {
  constructor(public originalError?: any) { }
  error: any;
  error_description:any;
}