<app-header></app-header>
<!-- <div [ngStyle]="{'display':showSessionExpireModalDailog}" style="z-index: 9999;" id="showSessionExpireModal"
    class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" style="width: auto;">
        <div class="modal-content">
            <div class="modal-header bg-modal modalhead-padding">
                <h4 class="modal-title">{{getLanguageTranslationValue(resourceKeys.SessionAboutExpire)}}!</h4>
            </div>

            <div class="modal-body modal-bodybg">
                <div class="d-flex mb-2">
                    <div class="timer-container">
                        <label>{{getLanguageTranslationValue(resourceKeys.SessionLogOut)}} </label>
                        <label id="timer" class="modal-timer"> &nbsp; <span
                                style="font-weight: bold;">{{idleTimerValue}}</span></label>
                        <label>{{getLanguageTranslationValue(resourceKeys.SessionStayIn)}}?</label>
                    </div>
                </div>
            </div>

            <div class="modal-footer bg-modal modalfooter-padding"
                style="justify-content: space-between;padding: 7px 5px;">
                <button class="button-29 me-2 px-3" type="button" (click)="resetTimer()"
                    style="border: none;outline: none;">{{getLanguageTranslationValue(resourceKeys.SessionKeepSign)}}</button>
                <button class="button-29 me-2 px-3" type="button" (click)="onSignMeout('sessionKeepOut')"
                    style="border: none;outline: none;background:#c2282e !important;">{{getLanguageTranslationValue(resourceKeys.SessionKeepOut)}}</button>
            </div>
        </div>
    </div>
</div> -->

<div [ngStyle]="{'display':validationPopupStyle}" id="validationPopup" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content" style="box-shadow: rgb(14 30 37 / 60%) 0px 0px 10px;">
            <div class="modal-header bg-modal modalhead-padding">
                <h4 class="modal-title"> Add Attachments </h4>
                <div class="d-flex align-items-center">
                    <span class="material-icons modal-close" aria-label="Close" (click)="onCloseValidationPopup()">
                        close
                    </span>
                </div>
            </div>

            <div class="modal-body py-3">
                <div class="d-flex flex-wrap">
                    <p class="font-14 font-w600 text-center mb-0">
                        {{getLanguageTranslationValue(resourceKeys.UploadDocumentValidation)}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div [ngStyle]="{'display':optionalRolesPopupStyle}" id="OptionalRolesPopup" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document" style="max-width: 500px;">
        <div class="modal-content" style="box-shadow: rgb(14 30 37 / 60%) 0px 0px 10px;">
            <div class="modal-header bg-modal modalhead-padding">
                <h4 class="modal-title"> {{getLanguageTranslationValue(resourceKeys.Lang_Information)}} </h4>
                <div class="d-flex align-items-center">
                    <span class="material-icons modal-close" aria-label="Close"
                        (click)="onCloseOptionalRolesPopupPopup()">
                        close
                    </span>
                </div>
            </div>

            <div class="modal-body py-3">
                <div class="d-flex flex-wrap">
                    <p class="font-14 font-w600 text-center mb-0">
                        {{getLanguageTranslationValue(resourceKeys.Lang_InformationWarning)}}
                    </p>
                </div>
            </div>

            <div class="modal-footer bg-modal modalfooter-padding">
                <button class="button-29 me-2 px-3" type="button" style="border: none;outline: none; width: 100px"
                    (click)="continueOptionalRolePopup()">
                    {{getLanguageTranslationValue(resourceKeys.Continue)}}</button>
            </div>

        </div>
    </div>
</div>

<div [ngStyle]="{'display':sendConfirmEmailPopupStyle}" id="SendConfirmEmailPopup" class="modal SendConfirmEmailPopup"
    tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content" style="box-shadow: rgb(14 30 37 / 60%) 0px 0px 10px;">
            <div class="modal-header bg-modal modalhead-padding">
                <!-- <h4 *ngIf="!enableMessageToMobile" class="modal-title">
                    {{getLanguageTranslationValue(resourceKeys.ConfirmEmailAddress)}}</h4>
                <h4 *ngIf="enableMessageToMobile && (deliverymode == '1' || deliverymode == '11')" class="modal-title">
                    {{getLanguageTranslationValue(resourceKeys.ConfirmEmailAddress)}}</h4>
                <h4 *ngIf="enableMessageToMobile && (returnEmailAndMobileDeliveryModeVal(deliverymode))" class="modal-title">
                    {{getLanguageTranslationValue(resourceKeys.SendConfirmEmailMobileHeaderText)}}</h4>
                <h4 *ngIf="enableMessageToMobile && (deliverymode == '4' || deliverymode == '4' || deliverymode == '6')" class="modal-title">
                    {{getLanguageTranslationValue(resourceKeys.SendConfirmMobileHeaderText)}}</h4> -->

                    <h4 class="modal-title">
                        {{getLanguageTranslationValue(resourceKeys.ConfirmYourIdentityText)}}</h4>

                <div class="d-flex align-items-center">
                    <span class="material-icons modal-close" aria-label="Close"
                        (click)="closeSendConfirmEmailPopupModal()">
                        close
                    </span>
                </div>
            </div>

            <div id="sendConfirmEmailPopupBody" class="modal-body modal-bodybg" style="padding-top: 0px;">
                <div *ngIf="!enableMessageToMobile" class="py-2" style="font-size: 14px;">
                    <p id="RSignMultiSignerConfirmationMail" style="margin-bottom: 0.25rem;">
                        {{getLanguageTranslationValue(resourceKeys.RSignConfirmationMail)}}</p>
                    <p id="RSignSingleSignerConfirmationMail" style="margin-bottom: 0.25rem;">
                        {{getLanguageTranslationValue(resourceKeys.RSignSingleSignerConfirmationMail)}}</p>
                </div>

                <div *ngIf="enableMessageToMobile && (deliverymode == '1' || deliverymode == '11')" class="py-2 1" style="font-size: 14px;">
                    <p id="RSignMultiSignerConfirmationMail" style="margin-bottom: 0.25rem;">
                        {{getLanguageTranslationValue(resourceKeys.RSignConfirmationMail)}}</p>
                    <p id="RSignSingleSignerConfirmationMail" style="margin-bottom: 0.25rem;">
                        {{getLanguageTranslationValue(resourceKeys.RSignSingleSignerConfirmationMail)}}</p>
                </div>

                <div *ngIf="enableMessageToMobile && returnEmailAndMobileDeliveryModeVal(deliverymode)" class="py-2 2" style="font-size: 14px;">
                    <p id="RSignMultiSignerConfirmationMail" style="margin-bottom: 0.25rem;">
                        {{getLanguageTranslationValue(resourceKeys.SendConfirmationEmailMobileText)}}</p>
                    <p id="RSignSingleSignerConfirmationMail" style="margin-bottom: 0.25rem;">
                        {{getLanguageTranslationValue(resourceKeys.SendSingleSignerConfirmationEmailMobileText)}}</p>
                </div>

                <div *ngIf="enableMessageToMobile && (deliverymode == '4' || deliverymode == '6')" class="py-2 3" style="font-size: 14px;">
                    <p id="RSignMultiSignerConfirmationMail" style="margin-bottom: 0.25rem;">
                        {{getLanguageTranslationValue(resourceKeys.SendConfirmationMobileText)}}</p>
                    <p id="RSignSingleSignerConfirmationMail" style="margin-bottom: 0.25rem;">
                        {{getLanguageTranslationValue(resourceKeys.SendSingleSingnerConfirmationMobileText)}}</p>
                </div>

                <div *ngIf="!enableMessageToMobile" class="input-group mb-3">
                    <span class="input-group-text material-icons" style="color:#333333a6;"> mail_outline </span>
                    <input type="text" class="form-control" name="usrname" id="txtSendConfirmerationEmail"
                        maxlength="50" autocomplete="off">
                </div>

                <div *ngIf="enableMessageToMobile && (deliverymode == '1' || deliverymode == '11') && webOrMobile == 'web'"
                    class="input-group mb-3">
                    <span class="input-group-text material-icons" style="color:#333333a6;"> mail_outline </span>
                    <input type="text" class="form-control" name="usrname" id="txtSendConfirmerationEmail"
                        maxlength="100" autocomplete="off">
                </div>

                <div *ngIf="enableMessageToMobile && (deliverymode != '1' && deliverymode != '11' && deliverymode != '4' && deliverymode != '6') && webOrMobile == 'web'"
                    class="form-group mb-3 border-boxsize">
                    <!-- <label for="inviteby" class="label-font14">Send by</label> -->
                    <label for="email" class="label-font14" style="">Email Address</label>

                    <div class="" style="display: flex; align-items: center;">
                        <!-- <div class="col-lg-1 col-md-1 col-sm-12 col-xs-1" style="width: 50px;display: flex;">
                            <input class="chkEmail" id="chkEmail" type="checkbox"
                                style="width: 20px; height: 20px; margin-left: 0px;margin-top: 5px;" />
                        </div> -->
                        <div class="" style="display: inline-block;">
                            <input formControlName="email" placeholder="Enter signer email"
                                id="txtSendConfirmerationEmail" type="text" autocomplete="off"
                                style="width: 325px;" class="form-control txtSendConfirmerationEmail" autoFocus="false">
                        </div>
                    </div>
                </div>

                <div *ngIf="enableMessageToMobile && (deliverymode == '1' || deliverymode == '11') && webOrMobile == 'mobile'"
                    class="input-group mb-3">
                    <span class="input-group-text material-icons" style="color:#333333a6;"> mail_outline </span>
                    <input type="text" class="form-control" name="usrname" id="txtSendConfirmerationEmail"
                        maxlength="100" autocomplete="off">
                </div>

                <div *ngIf="enableMessageToMobile && (deliverymode != '1' && deliverymode != '11' && deliverymode != '4' && deliverymode != '6') && webOrMobile == 'mobile'"
                    class="form-group mb-3 border-boxsize">
                    <!-- <label for="inviteby" class="label-font14">Send by</label> -->
                    <label for="email" class="label-font14">Email Address</label>

                    <div class="" style="display: flex; align-items: center;">
                        <!-- <div class="col-lg-1 col-md-1 col-sm-12 col-xs-1" style="width: 50px;display: flex;">
                            <input class="chkEmail" id="chkEmail" type="checkbox"
                                style="width: 20px; height: 20px; margin-left: 0px;margin-top: 5px;" />
                        </div> -->
                        <div class="d-content" style="display: inline-block;">
                            <input formControlName="email" placeholder="Enter signer email"
                                id="txtSendConfirmerationEmail" type="text" autocomplete="off"
                                style="width: 278px;" class="form-control txtSendConfirmerationEmail" autoFocus="false">
                        </div>
                    </div>
                </div>

                <div id="divDeliveryMobileOnly"
                    *ngIf="enableMessageToMobile && (deliverymode !=='1' && deliverymode !=='11') && webOrMobile == 'web'"
                    class="input-group form-group mb-3 border-boxsize">
                    <label for="email" class="label-font14"
                        >{{getLanguageTranslationValue(resourceKeys.CountryCode)}}</label>
                    <label  for="mobile" class="label-font14"
                        style="margin-left: 53px;">{{getLanguageTranslationValue(resourceKeys.Mobile)}}</label>
                    <!-- <label *ngIf="returnEmailAndMobileDeliveryModeVal(deliverymode)" for="email" class="label-font14 ml-50p"
                        style="margin-left: 75px;">{{getLanguageTranslationValue(resourceKeys.CountryCode)}}</label>
                    <label *ngIf="returnEmailAndMobileDeliveryModeVal(deliverymode)" for="mobile" class="label-font14"
                        style="margin-left: 50px;">{{getLanguageTranslationValue(resourceKeys.Mobile)}}</label> -->
                    <br />

                    <div class="" style="display: flex; align-items: center;">
                        <!-- <div *ngIf="deliverymode !=='1' && deliverymode !=='11' && deliverymode !=='4' && deliverymode !=='6'" class="" style="width: 50px;display: flex;">
                            <input class="chkMobile" id="chkMobile" type="checkbox"
                                style="width: 20px;height: 20px;margin-left: 2px;display: flex;margin-top: 5px;" />
                        </div> -->
                        <div id="divngbDropdown" class="d-flex">
                            <div ngbDropdown class="dropdown" #myDrop="ngbDropdown" id="ddrCountryFlag"
                                style="width: 127px;display: inline-block;">
                                <a ngbDropdownToggle style="text-decoration-line: none; color: #000;">
                                    <span id="spnTitle" style="display: inline;">Select</span>
                                </a>
                                <ul ngbDropdownMenu class="dropdown-menu" id="DialCodeUl"
                                    (click)="selectedCountryDialCode($event);myDrop.close();">
                                    <li class="liCountryItem"
                                        *ngFor="let dialcodes of DialCodeDropdownList; let i = index;">
                                        <a *ngIf="dialcodes.DialCode == 'Select'" [attr.data-maxlength]="0"
                                            style="text-decoration-line: none;color: white;padding-top: 15px;padding-bottom: 15px; background-color: #bc0001;">
                                            <span id="spnCountryFlag"></span>Select</a>
                                        <a style="text-decoration-line: none;color: white;"
                                            *ngIf="dialcodes.DialCode != 'Select'"
                                            [attr.data-countrycode]="dialcodes.CountryCode.toLowerCase()"
                                            [attr.data-maxlength]="dialcodes.MobileMaxLength">
                                            <span id="spnCountryFlag" class="bg-{{dialcodes.CountryCode.toLowerCase()}}"
                                                style="top: -8px; display: inline-block; width: 25px;"></span>{{dialcodes.DialCode}}</a>
                                    </li>
                                </ul>
                            </div>

                            <div class="d-flex" style="display: inline-block;">
                                 <input type="text"
                                    style="margin-left: 10px;width:190px;" class="form-control txtSignerMobileNumber"
                                    id="txtSignerMobileNumber" placeholder="Enter mobile number" name="mobilenumber"
                                    value="{{mobilenumber}}" value="" autocomplete="off" pattern="[0-9]*"
                                    ondrop="return false" inputmode="numeric" typeof="number"
                                    (input)="validateInputValue($event)" spellcheck="false"></div>
                        </div>
                    </div>
                </div>
                <div id="divDeliveryMobileOnly"
                    *ngIf="enableMessageToMobile && (deliverymode !=='1' && deliverymode !=='11') && webOrMobile == 'mobile'"
                    class="input-group form-group mb-3 border-boxsize">
                    <label for="email" class="label-font14"
                        >{{getLanguageTranslationValue(resourceKeys.CountryCode)}}</label>
                    <label for="mobile" class="label-font14"
                        style="margin-left: 45px;">{{getLanguageTranslationValue(resourceKeys.Mobile)}}</label>
                    <!-- <label *ngIf="returnEmailAndMobileDeliveryModeVal(deliverymode)" for="email" class="label-font14"
                        style="margin-left: 61px;">{{getLanguageTranslationValue(resourceKeys.CountryCode)}}</label>
                    <label *ngIf="returnEmailAndMobileDeliveryModeVal(deliverymode)" for="mobile" class="label-font14"
                        style="margin-left: 53px;">{{getLanguageTranslationValue(resourceKeys.Mobile)}}</label> -->
                    <br />

                    <div class="" style="display: flex; align-items: center;">
                        <!-- <div *ngIf="deliverymode !=='1' && deliverymode !=='11' && deliverymode !=='4' && deliverymode !=='6'" class="" style="width: 50px;display: flex;">
                            <input class="chkMobile" id="chkMobile" type="checkbox"
                                style="width: 20px;height: 20px;margin-left: 2px;display: flex;margin-top: 5px;" />
                        </div> -->
                        <div id="divngbDropdown" class="d-flex">
                            <div ngbDropdown class="dropdown" #myDrop="ngbDropdown" id="ddrCountryFlag"
                                style="width: 127px;display: inline-block;">
                                <a ngbDropdownToggle style="text-decoration-line: none; color: #000;">
                                    <span id="spnTitle" style="display: inline;">Select</span>
                                </a>
                                <ul ngbDropdownMenu class="dropdown-menu" id="DialCodeUl"
                                    (click)="selectedCountryDialCode($event);myDrop.close();">
                                    <li class="liCountryItem"
                                        *ngFor="let dialcodes of DialCodeDropdownList; let i = index;">
                                        <a *ngIf="dialcodes.DialCode == 'Select'" [attr.data-maxlength]="0"
                                            style="text-decoration-line: none;color: white;padding-top: 15px;padding-bottom: 15px; background-color: #bc0001;">
                                            <span id="spnCountryFlag"></span>Select</a>
                                        <a style="text-decoration-line: none;color: white;"
                                            *ngIf="dialcodes.DialCode != 'Select'"
                                            [attr.data-countrycode]="dialcodes.CountryCode.toLowerCase()"
                                            [attr.data-maxlength]="dialcodes.MobileMaxLength">
                                            <span id="spnCountryFlag" class="bg-{{dialcodes.CountryCode.toLowerCase()}}"
                                                style="top: -8px; display: inline-block; width: 25px;"></span>{{dialcodes.DialCode}}</a>
                                    </li>
                                </ul>
                            </div>

                            <div style="display: inline-block;"> <input type="text"
                                    style="margin-left: 5px;width:145px;" class="form-control txtSignerMobileNumber"
                                    id="txtSignerMobileNumber" placeholder="Enter mobile number" name="mobilenumber"
                                    value="{{mobilenumber}}" (input)="validateInputValue($event)" pattern="[0-9]*"
                                    ondrop="return false" inputmode="numeric" typeof="number" spellcheck="false"
                                    autocomplete="off" value=""></div>
                        </div>
                    </div>
                </div>
                <input type="hidden" id="hdnDeliveryMode" value="" />
            </div>
            <div class="modal-footer bg-modal modalfooter-padding">
                <button class="button-29 me-2 px-3" type="button" (click)="SendConfirmEmailSend()"
                    style="border: none; outline: none; width: 100px; cursor: pointer;">
                    {{getLanguageTranslationValue(resourceKeys.Send)}}</button>
            </div>
        </div>
    </div>
</div>

<div [ngStyle]="{'display':waitModalPopupStyle}" id="waitModalPopup" class="modal waitModalPopup" tabindex="-1"
    role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content" style="box-shadow: rgb(14 30 37 / 60%) 0px 0px 10px;">
            <div class="modal-header bg-modal modalhead-padding">
                <h4 class="modal-title"> {{getLanguageTranslationValue(resourceKeys.lang_attention)}}</h4>
                <div class="d-flex align-items-center">
                    <span class="material-icons modal-close" aria-label="Close" (click)="closePopupModal()">
                        close
                    </span>
                </div>
            </div>

            <!-- Modal body -->
            <div id="waitModalPopupBody" class="modal-body modal-bodybg">
                <div class="py-2" style="font-size: 16px;">
                    <div class="d-flex justify-content-center">
                        <img class=" mb-3 h-64" src="../../../assets/images/stopwatch.png">
                    </div>
                    <p *ngIf="!enableMessageToMobile" id="changedSignerEmailtxt" style="margin-bottom: 0.25rem;"
                        class="text-center">
                        {{getLanguageTranslationValue(resourceKeys.waitingMsgAddressStatic)}} <b
                            style="font-weight:600"> {{changedSignerEmail}} </b>
                        {{getLanguageTranslationValue(resourceKeys.waitingMsgSignStatic)}} </p>

                    <p *ngIf="enableMessageToMobile && (deliverymode == '1' || deliverymode == '11')" id="changedSignerEmailtxt"
                        style="margin-bottom: 0.25rem;" class="text-center">
                        {{getLanguageTranslationValue(resourceKeys.waitingMsgAddressStatic)}} <b
                            style="font-weight:600"> {{changedSignerEmail}} </b>
                        {{getLanguageTranslationValue(resourceKeys.waitingMsgSignStatic)}} </p>


                    <p *ngIf="enableMessageToMobile && returnEmailAndMobileDeliveryModeVal(deliverymode)" id="changedSignerEmailtxt"
                        style="margin-bottom: 0.25rem;" class="text-center">
                        {{getLanguageTranslationValue(resourceKeys.waitingMsgAddressStatic)}} <b
                            style="font-weight:600"> {{changedSignerEmail}} </b>
                            <span *ngIf="isToShowchangedSignerMobileText"> {{changedSignerMobileText}} </span>
                             <b *ngIf="isToShowchangedSignerMobileText" style="font-weight:600"> {{changedSignerMobile}} </b>
                        {{getLanguageTranslationValue(resourceKeys.waitingMsgSignStatic)}} </p>

                    <p *ngIf="enableMessageToMobile && (deliverymode == '4' || deliverymode == '6')" id="changedSignerEmailtxt"
                        style="margin-bottom: 0.25rem;" class="text-center">
                        {{getLanguageTranslationValue(resourceKeys.lang_MobileNumber)}} <b style="font-weight:600">
                            {{changedSignerEmail}} </b>
                        {{getLanguageTranslationValue(resourceKeys.waitingMsgSignStatic)}} </p>

                    <p id="timerValuetxt" style="margin-bottom: 0.25rem;" class="text-center">
                        {{getLanguageTranslationValue(resourceKeys.waitingMsgTryAgainStatic)}} <b
                            style="color:#49d26d; font-weight:600">{{timerValue}}</b>
                        {{getLanguageTranslationValue(resourceKeys.Sessionpopupminutes)}}</p>
                    <p id="proceddNowtxt" style="margin-bottom: 0.25rem; display: none;" class="text-center">
                        {{getLanguageTranslationValue(resourceKeys.waitingMsgProceedStatic)}}</p>
                </div>

            </div>

            <!-- Modal footer -->
            <div class="modal-footer bg-modal modalfooter-padding">
                <button class="button-29 me-2 px-3" type="button" style="border: none;outline: none; width: 100px"
                    (click)="closePopupModal()">Ok</button>
            </div>
        </div>
    </div>
</div>

<ng-template #termsOfService let-modal>
    <div class="modal-header bg-modal modalhead-padding">
        <h4 class="modal-title"> {{getLanguageTranslationValue(resourceKeys.TermsOfService)}}</h4>
        <div class="d-flex align-items-center">
            <a class="me-3 cursor-pointer" (click)="onTermsOfServiceDownload()">
                <i class="fa fa-download font-18" aria-hidden="true" style="color: #ffffff;
                    margin-top: 5px;"></i>
            </a>
            <a class="me-2 cursor-pointer" (click)="onTermsOfServicePrint()">
                <i class="fa fa-print font-18" aria-hidden="true" style="color: #ffffff;"></i>
            </a>
        </div>
        <!-- <button type="button" class="btn-close" data-bs-dismiss="modal"></button> -->
    </div>

    <!-- Modal body -->
    <div id="disclaimerTermsAndServiceBody" class="modal-body modal-bodybg">
        <div class="py-2" style="font-size: 14px;" [innerHtml]="documentControlsResponse.EnvelopeInfo.Disclaimer"></div>
    </div>

    <!-- Modal footer -->
    <div class="modal-footer bg-modal modalfooter-padding">
        <button class="button-29 me-2 px-3" type="button" (click)="onAgree()" style="border: none;
                outline: none;
                width: 100px">{{getLanguageTranslationValue(resourceKeys.Iagree)}}</button>
    </div>

    <!-- </div>
        </div>
      </div> -->

</ng-template>

<div [ngStyle]="{'display':clickToSignPopupStyle}" id="clickToSign" class="modal clicktoSign-class" tabindex="-1"
    role="dialog">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-modal modalhead-padding">
                <h4 class="modal-title">{{getLanguageTranslationValue(resourceKeys.ClicktoSign)}}</h4>
                <div class="d-flex align-items-center">
                    <span class="material-icons modal-close" aria-label="Close" (click)="onClickSignClose()">
                        close
                    </span>
                </div>
            </div>

            <!-- Modal body -->
            <div class="modal-body modal-bodybg">
                <div class="tab-pane fade active show">
                    <div class="border-boxsize">
                        <div class="container-fluid">
                            <div class=" d-flex align-items-center justify-content-between res-column">
                                <div class="col-sm-12 col-md-9 d-flex flex-column res-w100">
                                    <div class="mb-3 me-4 res-mr0 border-boxsize">
                                        <label for="txtsignerProfileName"
                                            class="form-label">{{getLanguageTranslationValue(resourceKeys.Name)}}</label>
                                        <input type="text" id="txtsignerProfileName" autocomplete="off"
                                            class="form-control" placeholder="Enter Name" maxlength="50"
                                            (input)="handleUpdateSigAndInitialProfileText($event)">
                                    </div>

                                    <div class="form-group mb-3 me-4 res-mr0 border-boxsize">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <label for="txtsignerProfileName"
                                                class="form-label">{{getLanguageTranslationValue(resourceKeys.Preview)}}
                                                {{getLanguageTranslationValue(resourceKeys.Signature)}}</label>
                                            <a class="clearlink" id="ankSignProfpopUploadSignature"
                                                (click)="openClickToSignModal('upload')">Upload Signature</a>
                                        </div>

                                        <div class="img-frame res-h85 p-2 w-100">
                                            <div id="userSignControl" class="signaturewidget">
                                                <img class="img-responsive negative userSignature res-signature settingsPropertyOnChange"
                                                    id="imgProfileSignature" data-is-change="False" src="">
                                            </div>
                                            <p class="mt-2 mb-1">
                                                <a class="uploadlink" id="ankSignProfpopUploadSignature"
                                                    (click)="openClickToSignModal('signature')">
                                                    <i class="fa fa-pencil-square-o editicon_popup" aria-hidden="true"
                                                        style="font-size: 18px;cursor: pointer;"></i>
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-3 d-flex flex-column res-w100">
                                    <div class="mb-3 border-boxsize">
                                        <label for="txtsignerProfileInitials"
                                            class="form-label">{{getLanguageTranslationValue(resourceKeys.Initials)}}</label>
                                        <input type="text" id="txtsignerProfileInitials" autocomplete="off"
                                            class="form-control" placeholder="Enter Initials" maxlength="4"
                                            (input)="handleUpdateInitialsimageFromSignerProfileText($event)">
                                    </div>
                                    <div class="form-group mb-3 border-boxsize">
                                        <label for="txtsignerProfileInitials"
                                            class="form-label">{{getLanguageTranslationValue(resourceKeys.Preview)}}
                                            {{getLanguageTranslationValue(resourceKeys.Initials)}}</label>
                                        <div class="img-frame res-h85 p-2">
                                            <div id="userNewinitialControl" class="signaturewidget">
                                                <img class="img-responsive negative userSignature settingsPropertyOnChange"
                                                    src="" id="imgProfNewInitials" data-is-change="False">
                                            </div>
                                            <p class="mt-2 mb-1">
                                                <a class="uploadlink" id="ankSignProfpopUploadSignature"
                                                    (click)="openClickToSignModal('initials')">
                                                    <i class="fa fa-pencil-square-o editicon_popup" aria-hidden="true"
                                                        style="font-size: 18px;
                                                    cursor: pointer;"></i>
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="checkbox res-mb65">
                                <label class="ps-0">
                                    <input id="chkterms" type="checkbox" [(ngModel)]="clicktoSignAgree"
                                        (change)="onEnableAcceptAndSign($event)">
                                    <span class="cr">
                                        <i class="cr-icon material-icons">check</i></span>
                                    <span
                                        class="moadlspan">{{getLanguageTranslationValue(resourceKeys.SignerTermsCond)}}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="border-boxsize d-none">
                    <div class="form-group">
                        <p class="pfntcolor" style="margin-bottom:5px;">{{clickToSignNote}}</p>
                    </div>
                    <div class="container-fluid bg-light">
                        <div class=" d-flex align-items-center justify-content-between">
                            <div class="col-sm-12 col-md-7">
                                <div class="mb-3 me-4 border-boxsize">
                                    <label for="txtsignerProfileName"
                                        class="form-label">{{getLanguageTranslationValue(resourceKeys.Name)}}</label>
                                    <input type="text" id="txtsignerProfileName" autocomplete="off" class="form-control"
                                        placeholder="Enter Name" maxlength="50"
                                        (keyup)="UpdateSigAndInitialProfileText(1)">
                                </div>

                            </div>
                            <div class="col-sm-12 col-md-5">
                                <div class="mb-3 border-boxsize">
                                    <label for="txtsignerProfileInitials"
                                        class="form-label">{{getLanguageTranslationValue(resourceKeys.Initials)}}</label>
                                    <input type="text" id="txtsignerProfileInitials" autocomplete="off"
                                        class="form-control" placeholder="Enter Initials" maxlength="4"
                                        (keyup)="UpdateInitialsimageFromSignerProfileText()">
                                </div>
                            </div>

                        </div>
                        <div class=" d-flex align-items-center justify-content-between">
                            <div class="col-sm-12 col-md-7">
                                <div class="form-group mb-3 me-4 border-boxsize">
                                    <label for="txtsignerProfileName"
                                        class="form-label">{{getLanguageTranslationValue(resourceKeys.Preview)}}
                                        {{getLanguageTranslationValue(resourceKeys.Signature)}}</label>
                                    <div class="img-frame p-2 ">
                                        <i class="fa fa-pencil-square-o editicon_popup" aria-hidden="true" style="font-size: 18px;
                                    cursor: pointer;
                                    float: right;" id="iconSignControl"
                                            (click)="openClickToSignModal('signature')"></i>
                                        <div id="userSignControl" class="signaturewidget">
                                            <img class="img-responsive negative userSignature settingsPropertyOnChange"
                                                id="imgProfileSignature" data-is-change="False" src=""
                                                style="height:78px !important">
                                        </div>
                                        <p class="mt-2 mb-1">
                                            <a class="uploadlink" id="ankSignProfpopUploadSignature"
                                                (click)="openClickToSignModal('upload')">Upload Signature</a>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-12 col-md-5">
                                <div class="form-group mb-3 border-boxsize">
                                    <label for="txtsignerProfileInitials"
                                        class="form-label">{{getLanguageTranslationValue(resourceKeys.Preview)}}
                                        {{getLanguageTranslationValue(resourceKeys.Initials)}}</label>
                                    <div class="img-frame p-2">
                                        <i class="fa fa-pencil-square-o editicon_popup" aria-hidden="true"
                                            (click)="openClickToSignModal('initials')" style="font-size: 18px;
                                                cursor: pointer;float: right;" id="iconnewSignControl"></i>

                                        <div id="userNewinitialControl" class="signaturewidget pt-2">
                                            <img class="img-responsive negative userSignature settingsPropertyOnChange"
                                                src="" id="imgProfNewInitials" data-is-change="False">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="checkbox">
                            <label class="ps-0">
                                <input id="chkterms" type="checkbox" [(ngModel)]="clicktoSignAgree"
                                    (change)="onEnableAcceptAndSign($event)">
                                <span class="cr">
                                    <i class="cr-icon material-icons">check</i></span>
                                <span
                                    class="moadlspan">{{getLanguageTranslationValue(resourceKeys.SignerTermsCond)}}</span>
                            </label>
                        </div>
                    </div>
                </div> -->
            </div>

            <!-- Modal footer -->
            <div class="modal-footer bg-modal modalfooter-padding mt-70">
                <button type="button" class="btn btncancel px-3 py-1" (click)="onClickSignClose()" id="btnSignerCancel"
                    style="height: 23px;border-radius: 4px;">{{getLanguageTranslationValue(resourceKeys.Cancel)}}</button>
                <!-- <button type="button" class="btn btn-default btn_accept" style="
                    background-color: #344255 !important;
                    color: white;" (click)="onClickSignClose()" id="btnSignerCancel">Cancel </button>  -->
                <button type="button" class="btn btn-default btn_accept button-29 ms-3" id="btnAccept"
                    (click)="onAcceptAndSign()"
                    style="background-color: #2486fc;color: white;">{{getLanguageTranslationValue(resourceKeys.AcceptandSign)}}</button>
            </div>
        </div>
    </div>


    <!-- <div class="modal-footer">
    </div> -->
</div>

<ng-template #fileReview let-modal>
    <!-- Modal Header -->
    <div class="modal-header bg-modal modalhead-padding">
        <h4 class="modal-title" id="modal-basic-title" style="font-size: 16px;">
            {{getLanguageTranslationValue(resourceKeys.FilesforReview)}}</h4>
        <div class="modalHeaderbutton pull-right">
            <span title="close" class="material-icons modal-close" aria-label="Close" (click)="onClickSignClose()">
                close
            </span>
        </div>
    </div>
    <!-- Modal body -->
    <div class="modal-body modal-bodybg">
        <div class="d-flex mb-2">
            <label>{{getLanguageTranslationValue(resourceKeys.DownloadAndReviewFiles)}}</label>
            <!-- <label>Below listed files required to be reviewed and confirmed to complete signing the Document:</label> -->
        </div>

        <div class="mt-4 d-flex justify-content-center">
            <button type="button"
                style="background: #3ab16d;border: none;border-radius: 4px;color: white;padding: 0.375rem 1.5rem;"
                class="btn btn-default d-flex align-items-center" id="btnFileReviewDownload"
                (click)="onFileReviewDownload()">
                <i class="fa fa-download" aria-hidden="true"
                    style="color: #ffffff; margin-top: 2px; margin-right: 8px;"></i>
                {{getLanguageTranslationValue(resourceKeys.DownloadFiles)}}</button>
        </div>

        <label>Files:</label>
        <div class="reviewClass" *ngIf="fileReviewInfo.length > 3"
            style=" max-height: 150px; height: 85px; overflow: scroll; overflow-x: hidden; ">
            <div *ngFor="let file of fileReviewInfo; let i = index;">
                <div class="pb-2">
                    <label>{{file.DocumentName}}</label>
                </div>
            </div>
        </div>
        <div class="reviewClass" *ngIf="fileReviewInfo.length <= 3">
            <div *ngFor="let file of fileReviewInfo; let i = index;">
                <div class="pb-2">
                    <label>{{file.DocumentName}}</label>
                </div>
            </div>
        </div>

    </div>
    <!-- Modal footer -->
    <div class="modal-footer bg-modal modalfooter-padding">
        <button type="button" class="btn btncancel px-3 py-1" (click)="onClickSignClose()" id="btnSignerCancel"
            style="height: 23px;border-radius: 4px;">Cancel</button>
        <button type="button" class="btn btn-default button-29 ms-3" id="btnConfirm" (click)="onConfirmFilesReviewed()"
            style="background-color: #2486fc;color: white;">{{getLanguageTranslationValue(resourceKeys.ConfirmFilesReviewed)}}</button>
    </div>
</ng-template>

<div [ngStyle]="{'display':showFinishModalDailog}" id="showFinishModal" class="modal showfinishmodal-class"
    tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" style="width: 413px">
        <div class="modal-content">
            <div class="modal-header bg-modal modalhead-padding">
                <h4 class="modal-title">Attention</h4>
                <div class="d-flex align-items-center">
                    <span class="material-icons modal-close" aria-label="Close" (click)="onShowFinishModalClose()">
                        close
                    </span>
                </div>
            </div>

            <!-- Modal body -->
            <div class="modal-body modal-bodybg">
                <div class="d-flex mb-2">
                    <label>All done. Please click on Finish to complete the signing.</label>
                </div>
                <div class="mt-4 d-flex justify-content-center">
                    <button type="button"
                        style="background: #3ab16d;border: none;border-radius: 4px;color: white;padding: 0.375rem 1.5rem;"
                        class="btn btn-default d-flex align-items-center" id="btnClickFinish"
                        (click)="staticSubmitEnvelope()">
                        <i class="fa fa-paper-plane me-2" aria-hidden="true"
                            style="color: #ffffff; margin-top: 2px; margin-right: 8px;"></i>
                        Finish</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div [ngStyle]="{'display':showMobilePopup}" tabindex="-1" class="modal" id="dvMaximizeControlModalPopup" role="dialog"
    style="padding-top: 40%;position: fixed;">
    <div class="modal-dialog">
        <div class="modal-content" style="border: 1px solid #c1c1c2;
        box-shadow: 0 10px 70px 0 rgb(103 151 255 / 18%), 0 15px 105px 0 rgb(103 151 255 / 20%);">
            <div class="modal-header" style="padding:5px 10px">
                <h4 class="modal-title" style="font-weight:bold;">
                    <span id="spnMaximizeControlTitle">{{spnMaximizeControlTitle}}</span>
                </h4>
                <div class="modalHeaderbutton pull-right">
                    <a href="javascript:void(0)" class="cancel btn" id="btnMaximizeControlCancel"
                        (click)="closeMobilePopup($event)">
                        <i class="fa fa-ban" aria-hidden="true" style="color: #ffffff;"></i>
                    </a>
                    <!-- <a href="javascript:void(0)" class="done btn" id="btnMaximizeControlOk" (click)="btnMaximizeControlOk()" data-dismiss="modal">
                        <i class="fa fa-check" aria-hidden="true"  style="color: #ffffff;"></i>
                    </a> -->
                </div>
            </div>
            <div class="modal-body" style="background: #dfe4ee;padding: 10px;">
                <div class="row">
                    <div id="txtMaximizeControlContainer" class="col-xs-12"
                        [ngStyle]="{'display':showNotDateControlPopupDisplay}">
                        <input name="" autofocus type="text" id="txtMaximizeControl" class="form-control" value=""
                            (keyup)="mobileViewControlOnchangeEvent($event);" (input)="isMobileViewNumberKey($event)"
                            autocomplete="off"
                            style="background-color: #fff; color: #000; width: 100%; font-size: 17px; height: 35px; border: 1px solid #ddd;" />
                        <!--  (keypress)="isMobileViewNumberKey($event)" (keyup)="eSign.SignerLanding.mobileViewControlOnchangeEvent(event,this.id)"
                       (keypress)="eSign.SignerLanding.isMobileViewNumberKey(event,this.id)"  -->
                    </div>
                    <div id="txtMaximizeControlDateContainer" class="col-xs-12"
                        [ngStyle]="{'display':showDateControlPopupDisplay}">
                        <input name="" tabindex="-1" autofocus type="text" id="txtMaximizeControlDate" value=""
                            class="inpDateControl form-control" autocomplete="off"
                            (keyup)="mobileViewDateControlOnchangeEvent($event,'txtMaximizeControlDate')" ngbDatepicker
                            #dateControl="ngbDatepicker" (dateSelect)="onMobileDateSelected($event, dateControl)"
                            [maxDate]="maxDate" [minDate]="minDate"
                            (click)="dateControl.toggle();isCheckDatePopupOpen(); $event.stopPropagation()"
                            style="width: 100%;" readonly />
                    </div>
                    <div id="txtMaximizeControlTextDateContainer" class="col-xs-12"
                        [ngStyle]="{'display':showTextDateControlPopupDisplay}">
                        <input name="" tabindex="-1" autofocus type="text" id="txtMaximizeControlTextDate" value=""
                            class="inpDateControl form-control" autocomplete="off"
                            (keyup)="mobileViewDateControlOnchangeEvent($event,'txtMaximizeControlTextDate')"
                            ngbDatepicker #dateTextDate="ngbDatepicker" [maxDate]="maxDate" [minDate]="minDate"
                            (dateSelect)="onMobileDateSelected($event, dateTextDate)"
                            (click)="dateTextDate.toggle();isCheckDatePopupOpen();$event.stopPropagation()"
                            style="width: 100%;" readonly />
                    </div>
                    <input type="hidden" id="hdnMaximizeControlId" value="" />
                    <input type="hidden" id="hdnDataCtnType" value="" />
                    <span id="spnMaximizeControlModalErrorMessage"
                        style="margin-left: 17px; color: red; font-size: 14px;"></span>
                </div>
            </div>
            <div style="padding: 0 10px;" id="prevNextBtnAlignMobileContainer" class="d-flex mb-2 mt-3"
                [ngClass]="getPrevNextBtnAlignmentsMobile()">
                <a id="chevronLeft" class="d-flex align-items-center preview-btn" *ngIf="mobilePrevBtn === 'true'"
                    (click)="getControlPrevNav('documentControls')">
                    <span class="material-icons me-1 font-16"> arrow_back_ios_new </span>
                    <span class="font-14">Prev</span>
                </a>

                <a id="chevronRight" class="d-flex align-items-center next-btn" *ngIf="mobileNextBtn === 'true'"
                    (click)="getControlNextNav('documentControls')">
                    <span class="font-14">Next</span>
                    <span class="material-icons ms-1 font-16"> arrow_forward_ios </span>
                </a>
                <a id="chevronRight" class="d-flex align-items-center next-btn"
                    *ngIf="webOrMobile === 'mobile' && mobileDoneBtn === 'true'"
                    (click)="getControlNextNav('documentControls')">
                    <span class="font-14">Done</span>
                    <span class="material-icons ms-1 font-16"> arrow_forward_ios </span>
                </a>
            </div>
        </div>
    </div>
</div>

<div [ngStyle]="{'display':sendPasswordPopupStyle}" id="SendPasswordPopup" class="modal sendpassword-class"
    tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header bg-modal modalhead-padding">
                <h4 class="modal-title">Enter Code</h4>
                <div class="d-flex align-items-center">
                    <span class="material-icons modal-close" aria-label="Close" (click)="closeSendPasswordPopupModal()">
                        close
                    </span>
                </div>
            </div>

            <div class="modal-body modal-bodybg" style="padding:10px;">
                <div style="text-align:justify">
                    <div class="formContent">
                        <div class="front-arrow" id="divFontSendPasswordPopup" style="text-align: left;">
                            <p *ngIf="!enableMessageToMobile" class="my-3 ps-3 pe-2">An email
                                has been sent to <span style="color: #337ab7;" id="emailID">{{emailAddr}}</span> with an
                                access code.
                                Please locate the email, enter it below, and click continue to proceed.</p>

                            <p *ngIf="enableMessageToMobile && (sendAccessCodeTo == 1)" class="my-3 ps-3 pe-2">An email
                                has been sent to <span style="color: #337ab7;" id="emailID">{{emailAddr}}</span> with an
                                access code.
                                Please locate the email, enter it below, and click continue to proceed.</p>

                            <p *ngIf="enableMessageToMobile && (sendAccessCodeTo == 2)" class="my-3 ps-3 pe-2">An SMS
                                message has been sent to your mobile
                                number <span style="color: #337ab7;" id="mobilenumber">{{Mobile}}</span>, and an email
                                message has been sent to your email address
                                <span style="color: #337ab7;" id="emailID">{{emailAddr}}</span> with an access code.
                                Please enter the access code below and click continue to proceed.
                            </p>

                            <p *ngIf="enableMessageToMobile &&  (sendAccessCodeTo == 3)" class="my-3 ps-3 pe-2">An SMS
                                message has been sent to your mobile number <span style="color: #337ab7;"
                                    id="mobilenumber">{{Mobile}}</span> with an access code.
                                Please locate the message, enter it below, and click continue to proceed.</p>

                            <p *ngIf="enableMessageToMobile &&  deliverymode == ''"
                                class="my-3 ps-3 pe-2">An email
                                has been sent to <span style="color: #337ab7;" id="emailID">{{emailAddr}}</span> with an
                                access code.
                                Please locate the email, enter it below, and click continue to proceed.</p>

                            <div class="form-field">
                                <label class="headerSpan mb-0"> Access code</label>
                                <input type="text" class="inp-full-width cursor headerSpan" pattern="[0-9]*"
                                    autocomplete="off" inputmode="numeric" placeholder="Enter the 6-digit code"
                                    typeof="number" maxlength="6" ondrop="return false" spellcheck="false"
                                    id="VerificationCode" (input)="enableSubmitButton($event)"
                                    (keyup.enter)="getCodeAndValidateSigner()">
                                <i class="fa fa-key keyicon" aria-hidden="true"></i>
                            </div>
                        </div>
                        <div class="form-buttons" style="margin-top:10px;">
                            <button id="btnViewandSignDocument" class="btn btn-full-width cursorpoint"
                                data-key-config="ViewandSignDocument"
                                (click)="getCodeAndValidateSigner()">Continue</button>
                        </div>

                        <p class="my-3">Not received? <a id="SendAgainVerificationCode"
                                (click)="resendVerificationCode()" class="font-w600 resend">Resend</a></p>
                    </div>
                    <input type="hidden" id="hdnDeliveryMode" value="{{deliverymode}}" />
                </div>
            </div>
        </div>
    </div>
</div>

<!-- ----------------------Mobile Header Top Menu Section------------------------------------------------------------->
<div class="subheader d-sm-block d-xl-none">
    <div class="d-flex align-items-center px-1 justify-content-between w-100 resw-98">
        <button id="btn" type="button" class="neu-light1 cursor-pointer px-2" style="color: #ffffff;"
            data-bs-toggle="offcanvas" data-bs-target="#filter">
            <i class="material-icons" aria-hidden="true" style="color: #ffffff;">filter_list</i>
            <!-- color: rgb(47, 47, 47); -->
        </button>

        <div class="d-flex align-items-center">
            <div class="fieldscount">
                <div class="d-flex align-items-center ">
                    <!-- *ngIf="mobilePrevBtn === 'true'" -->
                    <img *ngIf="mobilePrevBtn === 'true'" class="h-18 cursor-pointer"
                        src="../../../assets/images/arrowhead-left.png" (click)="getControlPrevNav('headerControls')"
                        (ontouchstart)="getControlPrevNav('headerControls')">

                    <!-- <span class="material-icons cursor-pointer" style="transform: rotate(175deg); color: #ffffff;"
                        (click)="getControlPrevNav('headerControls')"
                        (ontouchstart)="getControlPrevNav('headerControls')"> play_arrow </span> -->
                    <span class="mx-2 res-font13 mobile-rem">Remaining
                        {{remainingFieldsCount}}/{{remainingFieldsTotalCount}}</span>
                    <!-- *ngIf="mobileNextBtn === 'true'" -->
                    <img *ngIf="mobileNextBtn === 'true'" class="h-18 cursor-pointer"
                        src="../../../assets/images/arrowhead-right.png" (click)="getControlNextNav('headerControls')"
                        (ontouchstart)="getControlNextNav('headerControls')">

                    <!-- <span class="material-icons cursor-pointer" style="color: #ffffff;"
                        (click)="getControlNextNav('headerControls')"
                        (ontouchstart)="getControlNextNav('headerControls')"> play_arrow </span> -->
                </div>
            </div>
        </div>

        <div class="d-flex align-items-center">
            <button class="neu-light1 mobilesend me-2 text-center cursor-pointer" type="button"
                (click)="staticSubmitEnvelope()">
                <i class="fa fa-paper-plane sendicon" aria-hidden="true"></i>
            </button>

            <button id="btn" type="button" class="neu-light1 px-2 cursor-pointer"
                style="color: #ffffff;border-right: none;" data-bs-toggle="offcanvas" data-bs-target="#demo">
                <i class="fa fa-bars primarycolor font-22" aria-hidden="true" style="color: #ffffff;"></i>
                <!-- <i class="material-icons primarycolor" aria-hidden="true" style="color: #667988;">more_horiz</i> -->
                <!-- color: rgb(47, 47, 47); -->
            </button>
        </div>

    </div>
</div>

<!-- ---------------------------------------------------Desktop View--------------------------------------------------------------------------- -->
<div class="subheader d-sm-none d-xl-block">
    <div class="d-flex align-items-center justify-content-between">
        <div class="left-column">
            <div class="d-flex align-items-center">
                <button id="btn" type="button" class="neu-light1 px-2 cursor-pointer" (click)="onPreviewPagesView()">
                    <!-- <span class="material-icons" style="font-size: 22px;">list_alt</span> -->
                    <span class="material-icons" title="Filter and Preview" style="font-size: 22px;">
                        format_list_bulleted </span>
                </button>
                <span class="br-nav">|</span>
                <span style="height: 30px; margin-top: 0px; line-height: 16px;"
                    class="neu-light ms-1 d-flex align-items-center">
                    <span class="resfont-13 font-libre text-nowrap" style="font-size: 14px; cursor: auto; margin-left: 1px;"> Fields Remaining:
                        <span class="top1 font-libre resfont-13" style="font-size: 14px; padding-left: 2px;"> {{remainingFieldsCount}}/{{remainingFieldsTotalCount}} </span>
                        <i class="fa fa-caret-down ms-1 d-none" aria-hidden="true"
                            style="font-size: 22px;position: relative; top: 2px;"></i>
                    </span>
                </span>

                <div *ngIf="ShowAttachmentCountDiv" class="d-flex align-items-center m-0 ps-0">
                    <span class="br-nav" style="padding-left: 4px;">|</span>
                    <button
                        class="d-flex btn-danger neu-light justify-content-center bordericon-left cursor-pointer"
                        data-bs-target="#attachement" data-bs-toggle="collapse" type="button" style="border: none;">
                        <span class="fa fa-paperclip iconfont-18" title="View Attachments"
                            style="font-size: 22px; left: 0px; position: relative; margin-left: 0px !important;">
                        </span>
                        <span title="View Attachments" class="wh-25"  style="top: -2px;position: relative;">
                            {{attachmentListCount}} </span>
                    </button>
                    <div *ngIf="ShowAttachmentCountDiv" id="attachement" class="collapse filter-list">
                        <span *ngIf="attachmentListCount > 0" class="material-icons beforearrow-attach">
                            arrow_drop_up </span>
                        <ul class="list-group bg-white" style="max-height: 175px;overflow: auto;width: 250px;">
                            <li class="list-group-item listbg" *ngFor="let file of attachmentList; let i = index;">
                                <div class="file d-flex align-items-center justify-content-between pt-2">
                                    <span class="d-flex align-items-center" title="{{file}}">
                                        <i [ngClass]="getFileNameIconFromExtension(file)" class="me-2 fa"
                                            aria-hidden="true"></i>
                                        <span class="attachedName">{{file}}</span>
                                    </span>
                                    <span class="material-icons ms-2 deleteicon" title="Delete"
                                        (click)="onAttachmentDelete(file)">
                                        delete
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>

        <div class="middle-column px-2">
            <div class="rightMenu d-flex align-items-center justify-content-between">
                <ul class="d-flex mb-0 m-0 left-nav">
<!--
                    <li *ngIf="ShowAttachmentCountDiv" class="d-flex align-items-center m-0 ps-0">
                        <button
                            class="d-flex btn-danger neu-light justify-content-center bordericon-left cursor-pointer"
                            data-bs-target="#attachement" data-bs-toggle="collapse" type="button" style="border: none;">
                            <span class="fa fa-paperclip iconfont-18" title="View Attachments"
                                style="font-size: 22px; left: 3px; position: relative; margin-left: 0px !important;">
                            </span>
                            <span title="View Attachments" class="wh-25 badge" style="top: -2px;">
                                {{attachmentListCount}} </span>
                        </button>
                        <div *ngIf="ShowAttachmentCountDiv" id="attachement" class="collapse filter-list">
                            <span *ngIf="attachmentListCount > 0" class="material-icons beforearrow-attach">
                                arrow_drop_up </span>
                            <ul class="list-group bg-white" style="max-height: 175px;overflow: auto;width: 250px;">
                                <li class="list-group-item listbg" *ngFor="let file of attachmentList; let i = index;">
                                    <div class="file d-flex align-items-center justify-content-between pt-2">
                                        <span class="d-flex align-items-center" title="{{file}}">
                                            <i [ngClass]="getFileNameIconFromExtension(file)" class="me-2 fa"
                                                aria-hidden="true"></i>
                                            <span class="attachedName">{{file}}</span>
                                        </span>
                                        <span class="material-icons ms-2 deleteicon" title="Delete"
                                            (click)="onAttachmentDelete(file)">
                                            delete
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </li> -->

                    <li *ngIf="ShowFilesReviewCountDiv" title="Files for Review" class="d-flex align-items-center">
                        <!-- <span class="br-nav">|</span> -->
                        <button id="btn" type="button" style="height: 30px; margin-top: -5px;" title="Files for Review"
                            class="neu-light me-3 btn-danger d-flex align-items-center">
                            <span class="resfont-13 font-libre" style="font-size: 14px; top: -3px; position: relative; ;"
                                title="Files for Review">
                                <i class="material-icons review" aria-hidden="true"
                                    title="Click here to download files to review" (click)="onFileReviewDownload()">
                                    download</i> Files for Review: {{this.documentControlsResponse.FileReviewCount}}
                            </span>
                        </button>
                    </li>

                </ul>
                <ul class="d-flex mb-0 m-0 middle-nav" *ngIf="webOrMobile === 'web'">
                    <app-pagination-customization [documentData]="documentData"></app-pagination-customization>
                </ul>
                <ul class="d-flex mb-0 m-0 right-nav">

                    <li class="d-flex align-items-center m-0">
                        <button type="button" class="btn-danger neu-light cursor-pointer" data-bs-target="#zoom"
                            data-bs-toggle="collapse" style="height:18px">
                            <span class="material-icons topn3"> zoom_in </span>
                            <span class="material-icons topn3" style="font-size: 20px;">
                                keyboard_arrow_down
                            </span>
                        </button>
                        <!-- <span class="br-nav">|</span> -->
                        <div id="zoom" class="collapse zoom-list">
                            <span class="material-icons beforearrow-zoom"> arrow_drop_up </span>
                            <ul class="list-group">
                                <li class="list-group-item listbg zoomli" style="margin:3px 0 0 0;">
                                    <div class="btn-toolbar d-sm-none d-md-block" style="border: 1px solid #a19f9f29;"
                                        role="toolbar" aria-label="Toolbar with button groups">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div class="btn-group" role="group" aria-label="First group">
                                                <button id="zoomOutbtn" type="button"
                                                    style="padding: 0.312rem 0.4rem !important;"
                                                    class="btn btn-outline-secondary zoom-btn" (click)="onZoomOut()">
                                                    <span class="material-icons"> zoom_out </span>
                                                </button>
                                                <button id="percentageZoomDoc" type="button"
                                                    style="padding: 0.312rem 0.4rem !important;cursor:text;"
                                                    class="btn btn-outline-secondary customeborder1 br-red zoom-btn"
                                                    value="{{percentageValue}}">
                                                    {{ percentageValue }}
                                                </button>
                                                <button id="zoomInbtn" type="button"
                                                    style="padding: 0.312rem 0.4rem !important;"
                                                    class="btn btn-outline-secondary customeborder1 zoom-btn"
                                                    (click)="onZoomIn()">
                                                    <span class="material-icons"> zoom_in </span>
                                                </button>
                                                <div id="zoomOptions" class="btn-group"
                                                    style="border-radius: 0px 20px 20px 0px;">
                                                    <button type="button" style="padding: 0.312rem 0.4rem !important;"
                                                        class="btn btn-outline-secondary dropdown-toggle zoom-btn"
                                                        data-bs-toggle="dropdown">
                                                        <span class="material-icons"> expand_more </span>
                                                    </button>
                                                    <ul class="dropdown-menu">
                                                        <!-- <li (click)="onPercentageChange(1)"
                                                            class="w-100 border-boxsize">
                                                            <a class="dropdown-item">Actual Size</a>
                                                        </li> -->
                                                        <!-- <li (click)="onPercentageChange(0.5)"
                                                            class="w-100 border-boxsize">
                                                            <a class="dropdown-item">50%</a>
                                                        </li>
                                                        <li (click)="onPercentageChange(0.75)"
                                                            class="w-100 border-boxsize">
                                                            <a class="dropdown-item">75%</a>
                                                        </li> -->
                                                        <li (click)="onPercentageChange(1)"
                                                            class="w-100 border-boxsize">
                                                            <a class="dropdown-item">100%</a>
                                                        </li>
                                                        <li (click)="onPercentageChange(1.5)"
                                                            class="w-100 border-boxsize">
                                                            <a class="dropdown-item">150%</a>
                                                        </li>
                                                        <li (click)="onPercentageChange(2)"
                                                            class="w-100 border-boxsize">
                                                            <a class="dropdown-item">200%</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <a class="clear-btn" style="background-color: #ffffff;"
                                                (click)="onPercentageChange(1)">
                                                <span class="material-icons" style="color: #0061d5; font-size: 26px;">
                                                    restore_page </span>
                                            </a>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <!-- <li class="d-flex align-items-center m-0" title="Download">
                        <button id="btn" type="button" class="neu-light btn-danger cursor-pointer"
                            (click)="onDownload()">
                            <i class="fa fa-download ps-1 pe-2" aria-hidden="true"></i>
                        </button>
                        <span class="br-nav">|</span>
                    </li> -->
                    <!-- <li class="d-flex align-items-center m-0" title="Print">
                        <button id="btn" type="button" class="neu-light btn-danger bordericon-right cursor-pointer">
                            <i class="fa fa-print pe-1" aria-hidden="true" style="position: relative; left: -2px;"
                                (click)="onPrint()"></i>
                        </button>
                    </li> -->
                    <!-- <li class="m-0">
                        <button id="previewPageView" type="button" title="Help" style
                            class="neu-light btn-danger cursor-pointer" (click)="onHelp()">
                            <span class="material-icons"
                                style="position: relative; left: -6px; top: -1px;">help_outline</span>
                        </button>
                    </li> -->
                </ul>
            </div>
        </div>

        <div class="right-column">
            <!-- (click)="hideAndShowMenuSection()" -->
            <div class="d-flex align-items-center justify-content-end w-100 pe-4">
                <button class="button-29 cursor-pointer" role="button"
                    style="top: 0px;margin-right: 12px;background-color: #0061d5;" (click)="staticSubmitEnvelope()">
                    <span class="custome-border cursor-pointer">
                        <i class="fa fa-paper-plane me-2" aria-hidden="true"></i> Finish
                    </span>
                </button>
                <span class="br-nav">|</span>
                <button id="btnmore_horiz"
                    class="btn-danger d-flex align-items-center justify-content-center neu-light1 cursor-pointer"
                    type="button" data-bs-target="#more" data-bs-toggle="collapse" style=" border-right: none;"
                    title="More Actions">
                    <span class="material-icons" title="More Actions"
                        style="font-size: 22px; left: 3px; position: relative;">
                        more_horiz
                    </span>
                </button>
            </div>

        </div>
    </div>



    <div id="more" class="collapse menu-list">
        <span class="material-icons beforearrow"> arrow_drop_up </span>
        <div class="arrow_box" id="box">
            <ul class="nav flex-column" *ngIf="webOrMobile === 'web'">
                <li *ngIf="IsEnableClickToSign">
                    <div class="outline1">
                        <a class="cursor-pointer d-flex align-items-start nav-link link-text"
                            (click)="openModal('Add Signature')"><i
                                class="fa fa-pencil-square iconfont-18 me-2"></i>
                            <span>Add/Edit Signature</span></a>
                    </div>
                </li>
                <li style="display:none" id="liShowTags">
                    <div class="outline1">
                        <a class="cursor-pointer  d-flex align-items-start nav-link link-text" id="btnShowTags"
                            (click)="showHideTagsControls()">
                            <i class="fa fa-eye iconfont-18  me-2"></i>
                            <span id="spnShowTagsText">{{getLanguageTranslationValue(resourceKeys.ShowTags)}}</span></a>
                        <input type="hidden" id="hdnShowTagsText" value="" />
                    </div>
                </li>
                <li *ngIf="ShowInviteSigners">
                    <div class="outline1">
                        <a class="cursor-pointer  d-flex align-items-start nav-link link-text"
                            (click)="openModal('Invite Signers')" id="btnInviteSigners">
                            <i class="fa fa-user-plus iconfont-18  me-2"></i>
                            <span>{{getLanguageTranslationValue(resourceKeys.InviteSigners)}}</span></a>
                    </div>
                </li>

                <li *ngIf="ShowAttachmentNavMenu">
                    <div class="outline1">
                        <a class="cursor-pointer  d-flex align-items-start nav-link link-text"
                            (click)="openModal('Attachments')"><i
                                class="fa fa-paperclip iconfont-18  me-2"></i>
                            <span>Add Attachments</span></a>
                    </div>
                </li>
                <!-- <li *ngIf="ShowFinishLaterNavMenu">
                    <div class="outline1 mt-2">
                        <a class="cursor-pointer  d-flex align-items-center nav-link"><i
                                class="fa fa-clock-o iconfont-18  me-2"></i>
                            <span class="link-text">
                                {{getLanguageTranslationValue(resourceKeys.FinishLater)}}</span></a>
                    </div>
                </li> -->

                <li *ngIf="showAndHideDecline !== true">
                    <div class="outline1" *ngIf="ShowDeclineNavMenu">
                        <a class="cursor-pointer  d-flex align-items-start nav-link link-text"
                            (click)="openModal('Static Decline')"><i
                                class="fa fa fa-ban iconfont-18  me-2"></i>
                            <span> {{getLanguageTranslationValue(resourceKeys.Decline)}}</span></a>
                    </div>
                </li>
                <!-- <li>
                    <div class="outline1 mt-2">
                        <a id="lnkChangeSigner" class="d-flex align-items-center nav-link"
                            (click)="openModal('Change Signer')">
                            <i class="fa fa-retweet iconfont-18  me-2"></i>
                            <span
                                class="link-text">{{getLanguageTranslationValue(resourceKeys.ChangeSigner)}}</span></a>
                    </div>
                </li> -->

                <!-- <li>
                    <div class="outline1 mt-2">
                        <a class="cursor-pointer  d-flex align-items-center nav-link" (click)="openModal('Invite Signers')"><i
                                class="fa fa-user-plus iconfont-18  me-2"></i>
                            <span class="link-text">{{getLanguageTranslationValue(resourceKeys.InviteSigners)}}</span></a>
                    </div>
                </li> -->
                <li>
                    <div class="outline1">
                        <a class="cursor-pointer d-flex align-items-start nav-link link-text" (click)="onHelp()">
                            <span class="material-icons iconfont-18  me-2">help_outline</span>
                            <span class="">Help</span></a>
                    </div>
                </li>
                <li>
                    <div class="outline1">
                        <a (click)="onSignupROneRedirection()" class="cursor-pointer d-flex align-items-start nav-link link-text">
                            <i class="fa fa-yelp iconfont-18  me-2"></i>
                            <span> Signup-ROne</span>
                        </a>
                    </div>
                </li>
            </ul>
        </div>
    </div>



</div>

<div class="d-flex res-ipad">

    <div class="col-xl-2 col-lg-12 col-md-12 col-xs-12 col-sm-12 res-column mt-115 ipadnone left-mobile">
        <div class="w-100 bg-light" [hidden]="hidePagesViewSection">
            <div class="card h-100 shadow tabcard">
                <nav>
                    <div class="nav nav-tabs mb-2 d-flex justify-content-between" id="nav-tab" role="tablist">
                        <div class="d-flex align-items-center">
                            <button class="nav-link active d-flex align-items-center" id="nav-profile-tab"
                                data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab"
                                aria-controls="nav-profile" aria-selected="false" style="font-size: 14px;"
                                title="Filter">
                                <i class="material-icons" style="font-size: 18px;" title="Filter">filter_list </i>
                                Filter
                            </button>

                            <button class="nav-link  d-flex align-items-center" id="nav-home-tab" data-bs-toggle="tab"
                                data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home"
                                aria-selected="true" style="font-size: 14px;" (click)="onWebPreviewPagesView()">
                                <i class="fa fa-files-o me-1"></i> {{getLanguageTranslationValue(resourceKeys.Preview)}}
                            </button>
                        </div>


                        <span class="material-icons iconfont-20" (click)="onPreviewPagesView()">
                            close
                        </span>
                    </div>
                </nav>
                <div class="tab-content bg-white w-100 h-100 tab-scroll style-scrollbar" id="nav-tabContent">
                    <div class="tab-pane fade active show" id="nav-profile" role="tabpanel"
                        aria-labelledby="nav-profile-tab" style="padding-right: 2px;">
                        <div class="leftsidebar mobileleftbar">
                         <div class="accordion" id="accordionPanelsStayOpenExample1">
                                <div class="accordion-item" style="border: 0px solid #ffffff;">
                                  <h2 class="accordion-header border-boxsize" id="panelsStayOpen-headingOne1">
                                    <button style="height: 2.165rem;border: 0px solid #ffffff;" class="filteraccordain accordion1 " type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne1" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne1">
                                        <i class="material-icons" style="font-size: 20px;">filter_list </i>
                                    </button>
                                  </h2>
                                  <div id="panelsStayOpen-collapseOne1" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne1">
                                    <div class="accordion-body ps-1">

                                        <div class="checkbox">
                                            <label for="fieldsRemainingFlagWeb" class="ps-0">
                                                <input id="fieldsRemainingFlagWeb" type="checkbox" value=""
                                                    [(ngModel)]="fieldsRemainingFlag">
                                                <span class="cr">
                                                    <i class="cr-icon material-icons">check</i></span>
                                                Fields Remaining
                                            </label>
                                        </div>

                                        <div class="checkbox mt-2">
                                            <label for="requiredFlagWeb" class="ps-0">
                                                <input id="requiredFlagWeb" type="checkbox" value=""
                                                    [(ngModel)]="requiredFlag">
                                                <span class="cr">
                                                    <i class="cr-icon material-icons">check</i></span>
                                                {{getLanguageTranslationValue(resourceKeys.Required)}}
                                            </label>
                                        </div>
                                        <div class="mt-3" style="padding-right: 2px; box-sizing: border-box;">
                                            <select *ngIf="documentControlsResponse && documentControlsResponse.EnvelopeInfo" id="pageSelectDropdownWeb"
                                                class="classic form-control pageSelectDropdown"
                                                #selectedFilterVal [(ngModel)]="searchTermDisplay"
                                                (ontouchstart)="onChangeDropdownSelection($event, selectedFilterVal.value)"
                                                (change)="onChangeDropdownSelection($event,selectedFilterVal.value)">

                                                <option value="" selected>Select All</option>
                                                <option
                                                    *ngFor="let opt of documentControlsResponse.EnvelopeInfo.Controls | keyvalue"
                                                    value="{{opt.key}}"> {{opt.value}}
                                                </option>
                                            </select>
                                        </div>

                                        <div class="my-3 d-flex justify-content-between align-items-center">
                                            <button type="button" class="btn btncancel px-4 py-1"
                                                (click)="onClearAll()" (ontouchstart)="onClearAll()"
                                                id="modal-btn-no">Clear</button>

                                            <button type="button" class="btn btnapply px-4"
                                                (click)="onFilterApply()" (ontouchstart)="onFilterApply()"
                                                id="btnCheckListFilterApply">Apply</button>
                                        </div>
                                    </div>
                                  </div>
                                </div>
                                </div>

                            <div class="w-100">
                                <div class="accordion mb-2" id="accordionExample"
                                    style="position: sticky; z-index: 111;">
                                    <div class="accordion-item"  style=" border-radius: 0px;border-top: 1px solid #dae9f8;
                                    border-left: none; border-right: none;">
                                        <div *ngIf="webOrMobile === 'web'" class="documentControlIndicator"
                                            [style.display]="showDefaultSignatureContol === 'block' ? 'block': 'none'">
                                            <nav class="defaultSignNav">
                                                <div id="showDefaultSignatureContol">
                                                    <div class="heading">
                                                        <span
                                                            class="pageNumber pb-1">{{getLanguageTranslationValue(resourceKeys.DefaultSignature)}}</span>
                                                    </div>
                                                    <ul style="padding:0px;">
                                                        <li id="FooterSignNav" style="list-style: none;"
                                                            (click)="onClickSignature('FooterSignNav')">
                                                            <span class="checkbox" title="Default Signature"
                                                                style="font-weight: bold;">
                                                                <div class="d-flex">
                                                                    <span
                                                                        class="defaultSignNavControlIndicator controlIndicator Signature required requried-color"
                                                                        data-for="defaultSignature">
                                                                        <i
                                                                            class="material-icons defaultSignNavControlIndicatorIcon requried-icon">star</i>
                                                                    </span>
                                                                    <img class="footerSignature" style="width:120px;"
                                                                        id="footerSigSrc"
                                                                        src="../../../assets/images/signature-en-us.png">
                                                                </div>
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </nav>
                                        </div>

                                    </div>
                                </div>
                                <div *ngIf="webOrMobile === 'web'">
                                    <div
                                        *ngIf="documentControlsResponse && documentControlsResponse.PageCount != 0 && showNoFieldsRemainingMsg == false">
                                        <div *ngFor="let fListCtrl of documentCheckListsResponse; let i = index;"
                                            class="w-100 accordain-overflow style-scrollbar chekListAccordainClass"
                                            id="chekListAccordain_{{i}}">
                                            <div *ngIf="documentControlsResponse.DocumentNameList[i]?.DocumentName">
                                                <span class="material-icons me-1 font-18"
                                                    style="color: #5a6271;position: relative;top: -3px;">insert_drive_file</span>
                                                <span style="width: 175px;padding-left:0px;"
                                                    class="document-file-name-style"
                                                    title="{{documentControlsResponse.DocumentNameList[i]?.DocumentName}}">
                                                    {{documentControlsResponse.DocumentNameList[i]?.DocumentName}}</span>
                                            </div>
                                            <button [ngClass]="{ 'active1' : currentIndex === i }"
                                                class="accordion1 border-boxsize"
                                                style=" width: 100%;padding-bottom: 30px;"
                                                (click)="expand(i)">{{getLanguageTranslationValue(resourceKeys.Page)}}
                                                {{fListCtrl.PageNumber}}
                                            </button>
                                            <div class="divChekListAccordain_{{i}}" [hidden]="currentIndex != i">
                                                <div *ngFor="let fList of fListCtrl.ControlsData; let j = index;"
                                                    class="panel ps-2" id="panel_{{fList.ControlHtmlID}}"
                                                    [ngClass]="{ 'visible' : currentIndex === i }">
                                                    <div class="controllist form mt-1">
                                                        <div *ngIf="fList.ControlName.toLowerCase() === 'radio' "
                                                            id="anchor_{{fList.ControlHtmlID}}"
                                                            [attr.data-role-name]="fList.signerName"
                                                            [attr.data-recp]="fList.RecipientId"
                                                            class="checkbox checkspace promoted-checkbox checkListDataItem mb-2 {{fList.ControlName.toLowerCase()}} {{fList.ControlHtmlID}}"
                                                            [ngStyle]="getCtrlDisplayStyle(fList.ConditionDetails.ControllingFieldID, fList)"
                                                            (click)="onFilterControlClick(fList, (i+1))"
                                                            class="checkbox checkspace signNowControl"
                                                            [title]="getLanguageControlName(fList)">
                                                            <label class="ps-0 requried-label font-14">
                                                                <input id="tmp{{i+1}}_{{j+1}}" disabled="true"
                                                                    [(ngModel)]="fList.isControlFiled"
                                                                    [attr.data-exists]="fList.isControlFiled"
                                                                    [attr.data-for]="'control_'+ fList.ControlHtmlID"
                                                                    [attr.data-group-name]="fList.GroupName"
                                                                    type="checkbox"
                                                                    class="promoted-input-checkbox {{fList.ClassAttribute}}" />
                                                                <label
                                                                    class="lblControlField requried-color requried-color1"
                                                                    for="tmp{{i+1}}_{{j+1}}"
                                                                    id="label_{{fList.ControlHtmlID}}"
                                                                    [attr.data-recp]="fList.RecipientId"
                                                                    [attr.data-child]="fList.ConditionDetails.ControllingFieldID"
                                                                    [attr.data-rq]="fList.Required"
                                                                    [attr.data-controlname]="fList.ControlName"
                                                                    [attr.data-for]="'control_'+ fList.ControlHtmlID"
                                                                    [title]="getLanguageControlName(fList)">
                                                                    <i *ngIf="fList.Required"
                                                                        id="iicon_{{fList.ControlHtmlID}}"
                                                                        class="material-icons requried-icon">star</i>
                                                                    <i *ngIf="!fList.Required"
                                                                        id="iicon_{{fList.ControlHtmlID}}"
                                                                        class="d-none material-icons requried-icon">star</i>
                                                                </label>
                                                                <span
                                                                    class="cursor-pointer font-14">{{fList.GroupName}}-{{fList.Label}}-P{{fList.PageNo}}</span>
                                                            </label>
                                                        </div>
                                                        <div *ngIf="fList.ControlName.toLowerCase() === 'checkbox'"
                                                            id="anchor_{{fList.ControlHtmlID}}"
                                                            [attr.data-role-name]="fList.signerName"
                                                            [attr.data-recp]="fList.RecipientId"
                                                            class="promoted-checkbox checkListDataItem mb-2 {{fList.ControlName.toLowerCase()}} {{fList.ControlHtmlID}}"
                                                            [ngStyle]="getCtrlDisplayStyle(fList.ConditionDetails.ControllingFieldID, fList)"
                                                            (click)="onFilterControlClick(fList, (i+1))"
                                                            class="checkbox checkspace signNowControl"
                                                            [title]="getLanguageControlName(fList)">
                                                            <label>
                                                                <input id="tmp{{i+1}}_{{j+1}}" disabled="true"
                                                                    [(ngModel)]="fList.isControlFiled"
                                                                    [attr.data-exists]="fList.isControlFiled"
                                                                    [attr.data-for]="'control_'+ fList.ControlHtmlID"
                                                                    [attr.data-group-name]="fList.GroupName"
                                                                    type="checkbox"
                                                                    class="promoted-input-checkbox {{fList.ClassAttribute}}" />
                                                                <label
                                                                    class="lblControlField requried-color requried-color1"
                                                                    for="tmp{{i+1}}_{{j+1}}"
                                                                    id="label_{{fList.ControlHtmlID}}"
                                                                    [attr.data-recp]="fList.RecipientId"
                                                                    [attr.data-child]="fList.ConditionDetails.ControllingFieldID"
                                                                    [attr.data-rq]="fList.Required"
                                                                    [attr.data-controlname]="fList.ControlName"
                                                                    [attr.data-for]="'control_'+ fList.ControlHtmlID"
                                                                    [title]="getLanguageControlName(fList)">
                                                                    <i *ngIf="fList.Required"
                                                                        id="iicon_{{fList.ControlHtmlID}}"
                                                                        class="material-icons requried-icon">star</i>
                                                                    <i *ngIf="!fList.Required"
                                                                        id="iicon_{{fList.ControlHtmlID}}"
                                                                        class="d-none material-icons requried-icon">star</i>
                                                                </label>
                                                                <span
                                                                    *ngIf="fList.GroupName === null || fList.GroupName === ''"
                                                                    class="cursor-pointer font-14">{{fList.LanguageControlName
                                                                    }}</span>
                                                                <span
                                                                    *ngIf="fList.GroupName !== null && fList.GroupName !== ''"
                                                                    class="cursor-pointer font-14">{{fList.GroupName}}-{{fList.Label}}-P{{fList.PageNo}}</span>
                                                            </label>
                                                        </div>
                                                        <div *ngIf="fList.ControlName.toLowerCase() === 'dropdown'"
                                                            class="promoted-checkbox checkListDataItem mb-2 {{fList.ControlName.toLowerCase()}} {{fList.ControlHtmlID}}"
                                                            id="anchor_{{fList.ControlHtmlID}}"
                                                            [attr.data-role-name]="fList.signerName"
                                                            [attr.data-recp]="fList.RecipientId"
                                                            [ngStyle]="getCtrlDisplayStyle(fList.ConditionDetails.ControllingFieldID, fList)"
                                                            (click)="onFilterControlClick(fList, (i+1))"
                                                            class="checkbox checkspace signNowControl"
                                                            [title]="getLanguageControlName(fList)">
                                                            <label>
                                                                <input id="tmp{{i+1}}_{{j+1}}" disabled="true"
                                                                    [(ngModel)]="fList.isControlFiled"
                                                                    [attr.data-exists]="fList.isControlFiled"
                                                                    [attr.data-for]="'control_'+ fList.ControlHtmlID"
                                                                    type="checkbox"
                                                                    class="promoted-input-checkbox {{fList.ClassAttribute}}" />
                                                                <label
                                                                    class="lblControlField requried-color requried-color1"
                                                                    for="tmp{{i+1}}_{{j+1}}"
                                                                    id="label_{{fList.ControlHtmlID}}"
                                                                    [attr.data-recp]="fList.RecipientId"
                                                                    [attr.data-child]="fList.ConditionDetails.ControllingFieldID"
                                                                    [attr.data-rq]="fList.Required"
                                                                    [attr.data-controlname]="fList.ControlName"
                                                                    [attr.data-for]="'control_'+ fList.ControlHtmlID"
                                                                    [title]="getLanguageControlName(fList)">
                                                                    <i *ngIf="fList.Required === true"
                                                                        id="iicon_{{fList.ControlHtmlID}}"
                                                                        class="material-icons requried-icon">star</i>
                                                                    <i *ngIf="!fList.Required"
                                                                        id="iicon_{{fList.ControlHtmlID}}"
                                                                        class="d-none material-icons requried-icon">star</i>
                                                                </label>
                                                                <span
                                                                    class="cursor-pointer font-14">{{fList.LanguageControlName
                                                                    }}</span>
                                                            </label>
                                                        </div>
                                                        <div *ngIf="fList.ControlName.toLowerCase() !== 'label' && fList.ControlName.toLowerCase() !== 'checkbox' &&
                                                    fList.ControlName.toLowerCase() !== 'radio' && fList.ControlName.toLowerCase() !== 'datetimestamp' &&
                                                    fList.ControlName.toLowerCase() !== 'dropdown' && fList.ControlName.toLowerCase() !== 'hyperlink'"
                                                            class="promoted-checkbox checkListDataItem mb-2 {{fList.ControlName.toLowerCase()}} {{fList.ControlHtmlID}}"
                                                            id="anchor_{{fList.ControlHtmlID}}"
                                                            [attr.data-role-name]="fList.signerName"
                                                            [attr.data-recp]="fList.RecipientId"
                                                            [ngStyle]="getCtrlDisplayStyle(fList.ConditionDetails.ControllingFieldID, fList)"
                                                            (click)="onFilterControlClick(fList, (i+1))"
                                                            class="checkbox checkspace signNowControl"
                                                            [ngClass]="checkSignAndInitialsDisabled()"
                                                            [title]="getLanguageControlName(fList)">
                                                            <label>
                                                                <input id="tmp{{i+1}}_{{j+1}}" disabled="true"
                                                                    [(ngModel)]="fList.isControlFiled"
                                                                    [attr.data-exists]="fList.isControlFiled"
                                                                    [attr.data-for]="'control_'+ fList.ControlHtmlID"
                                                                    type="checkbox"
                                                                    class="promoted-input-checkbox {{fList.ClassAttribute}}" />
                                                                <label
                                                                    class="lblControlField requried-color requried-color1"
                                                                    for="tmp{{i+1}}_{{j+1}}"
                                                                    id="label_{{fList.ControlHtmlID}}"
                                                                    [attr.data-recp]="fList.RecipientId"
                                                                    [attr.data-child]="fList.ConditionDetails.ControllingFieldID"
                                                                    [attr.data-rq]="fList.Required"
                                                                    [attr.data-controlname]="fList.ControlName"
                                                                    [attr.data-for]="'control_'+ fList.ControlHtmlID"
                                                                    [title]="getLanguageControlName(fList)">
                                                                    <i *ngIf="fList.Required === true"
                                                                        id="iicon_{{fList.ControlHtmlID}}"
                                                                        class="material-icons requried-icon">star</i>
                                                                    <i *ngIf="!fList.Required"
                                                                        id="iicon_{{fList.ControlHtmlID}}"
                                                                        class="d-none material-icons requried-icon">star</i>
                                                                </label>
                                                            </label>
                                                            <span
                                                                *ngIf="fList.ControlName.toLowerCase() === 'text' && fList.Label !== '' && fList.Label !== null"
                                                                class="cursor-pointer font-14">{{fList.Label }}</span>
                                                            <span
                                                                *ngIf="fList.ControlName.toLowerCase() === 'text' && (fList.Label === '' || fList.Label === null)"
                                                                class="cursor-pointer font-14">{{fList.LanguageControlName
                                                                }}</span>
                                                            <span *ngIf="fList.ControlName.toLowerCase() !== 'text'"
                                                                class="cursor-pointer font-14">{{fList.LanguageControlName
                                                                }}</span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="documentControlsResponse && documentControlsResponse.PageCount === 0">
                                        <div *ngFor="let docData of documentCheckListsResponse">
                                            <div class="heading sideMenuDocName"
                                                [attr.Control_DocId]="docData.DocumentId">
                                                <span class="material-icons me-1 font-18"
                                                    style="color: #5a6271;position: relative;top: -3px;">
                                                    insert_drive_file</span>
                                                <span id="sideMenuDocName_{{docData.DocumentId}}"
                                                    class="document-file-name-style"
                                                    style="width: 175px;padding-left:0px;"
                                                    title="{{docData.DocumentName}}">{{docData.DocumentName}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="showNoFieldsRemainingMsg === true">
                                        <div class="no-fields">
                                            <div class="d-flex justify-content-center">
                                                <img class=" mb-3 h-64" src="../../../assets/images/no-results.png">
                                            </div>
                                            <p class="text-center font-w500"> No fields remaining</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="webOrMobile === 'web'" class="tab-pane fade" id="nav-home" role="tabpanel"
                        aria-labelledby="nav-home-tab">
                        <!-- Right vside menu [hidden]="hidePagesViewSection"-->

                        <div class="previewpage previewbg">
                            <div class="pagepreview-fixed">
                                <div class="" style="border-bottom: 2px solid #aca2a352;">
                                    <h5
                                        class="my-1 px-2 align-items-start pageheading style-scrollbar document-list-style">
                                        <div class="d-flex align-items-center"
                                            *ngFor="let dList of documentControlsResponse.DocumentNameList ; let i=index;"
                                            style="padding: 2px 0;" class="d-flex">
                                            <span class="material-icons me-1 font-18" style="color: #5a6271;">
                                                insert_drive_file</span>
                                            <span title="{{dList.DocumentName}}"
                                                style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;width: 175px;display: inline-block;">
                                                {{dList.DocumentName}}
                                            </span>
                                        </div>
                                    </h5>
                                </div>
                                <div class=" my-2 px-3 d-flex align-items-center justify-content-between">
                                    <span class="" style="font-size: 14px;">Pages:
                                        {{pagePreviewTotalPages}}</span>
                                    <span style="font-size: 14px;"></span>
                                </div>
                            </div>
                            <div class="mt-2 d-flex flex-column align-items-center page-scrollbar style-scrollbar">
                                <div class="thumbnail" *ngFor="let page of pageViewArr ; let i=index;">
                                    <!-- <img src="../../../assets/images/shield.png" alt="" class="badgeicon" height="40px"
                                        *ngIf="i!=1">
                                    <img src="../../../assets/images/shield2.png" alt="" class="badgeicon" height="40px"
                                        *ngIf="i==1"> -->
                                    <!--  [ngClass]="getPreviewSelected(i+1)" -->
                                    <div class="thumbnails-page-btn" id="pageCountColor{{i+1}}"
                                        style="position: relative;top: 174px;left: 2px;">
                                        <span class="pagenum">{{i+1}}</span>
                                    </div>
                                    <div class="d-flex justify-content-center" style="margin-top: -5px;">
                                        <!-- [ngClass]="getPreviewSelectedColor(i+1)" -->
                                        <img class="prevImg" id="pagePreview{{i+1}}" [attr.page-no]="page.dataPage"
                                            width="140px" height="180px" [src]="getImagePath(page)" alt="Image"
                                            (click)="onPreviewImageSelection($event, i+1)">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-8 col-lg-12 col-md-12 col-xs-12 col-sm-12" [ngClass]="getPageSectionClass(1)">
        <!-- <div *ngIf="sessionTimeoutVal !== '' && webOrMobile === 'web'"
            style="position: fixed;z-index: 9999;float: right;right: 14px;top: 125px;font-size: 14px;">Session Timeout:
            <span style="color:red;"> {{sessionTimeoutVal}}</span>
        </div>
        <div *ngIf="sessionTimeoutVal !== '' && webOrMobile === 'mobile'"
            style="position: fixed;z-index: 9999;float: right;right: 125px;top: 80px;font-size: 13px;color:yellow;">
            Session Timeout: <span style="font-size: 13px;color:yellow;"> {{sessionTimeoutVal}}</span></div> -->
        <div *ngIf="documentControlsResponse && documentControlsResponse.EnvelopeImageControlData"
            #documentPageContainer class="documentPage" [ngClass]="getPageSectionClass(2)"
            [ngStyle]="getDocumentPagStyle()" id="documentPage">

            <!-- mobile [overflow]="'visible'"  pinchzoom{{record.PageNum}} class="pinchzoom{{record.PageNum}}" id="pinchzoom{{record.PageNum}}"-->
            <pinch-zoom [overflow]="'visible'" #pinchZoom="pinchZoom" *ngIf="webOrMobile === 'mobile'"
                [backgroundColor]="'rgb(255 255 255)'" [transition-duration]="400" [limitPan]="true" [limit-zoom]="4">
                <div>
                    <ng-container *ngTemplateOutlet="itemTemplate">
                    </ng-container>
                </div>
            </pinch-zoom>

            <!-- web -->
            <div class="subdocument" *ngIf="webOrMobile === 'web'">
                <ng-container *ngTemplateOutlet="itemTemplate">
                </ng-container>
            </div>
            <ng-template #itemTemplate>
                <div *ngFor="let record of documentControlsResponse.EnvelopeImageControlData; let i=index;"
                    id="imgdiv{{i+1}}" class="sameClass">
                    <div id="imgControl_{{record.PageNum}}" class="imgControl controlContainer"
                        [attr.page-no]="record.PageNum" [style.width]="record.ImgControlWidth"
                        [attr.documentdata]="record.SigningEnvelopeDocumentData">

                        <!-- common for both web and mobile -->
                        <img id="{{record.PageNum}}" class="dispImg res-padding" [attr.page-no]="record.PageNum"
                            [attr.doc-page-no]="record.DocPageNo" [src]="getImagePath(record)" alt="Image">
                        <form [hidden]="showControlsFlag === 'none'" class="document-controls-zoom">
                            <div *ngFor="let control of record.ControlsData; let j=index;">
                                <div *ngIf="control.ControlName === 'Signature'" id="{{control.ControlHtmlID}}"
                                    [attr.data-role-name]="control.signerName"
                                    [attr.data-recipientid]="control.RecipientId"
                                    class="signNowControl popUp openSignatureModalOnClick commonSignatureControl"
                                    [attr.data-page]="control.PageNo" [attr.data-unique]="control.Id"
                                    [attr.data-rq]="control.Required" [attr.data-ctntype]="control.ControlName"
                                    [attr.data-lastupdated]="" [ngStyle]="getCtrlInlineStyleIfIsReadOnlyNull(control)">
                                    <i class="fa fa-pencil-square-o stampicon {{getIphoneClass()}}"
                                        [ngClass]="getPencilClass(control)" aria-hidden="true"
                                        [ngStyle]="getPencilIconStyle()" title="Click to Edit Signature"
                                        (click)="onClickSignature(control.ControlHtmlID)"></i>
                                    <div class="signerControl sign" style="Width:100%;height:100%"
                                        title="{{control.CustomToolTip}}">
                                        <div [ngClass]="getRequiredControlClass(control, control.SignatureScr)"
                                            class="additionalInfo" style="height:100%;Width:100%">
                                            <div class="signature" style="width:100%;height:100%;"
                                                (click)="hideToolTip(control);onClickSignature(control.ControlHtmlID)">
                                                <img tabindex="{{control.TabIndex}}"
                                                    [attr.data-role-name]="control.signerName"
                                                    [attr.data-contrl-value]="" [attr.data-sign-text]=""
                                                    [attr.data-font-color]="control.FontColor"
                                                    [attr.data-document-content-id]="control.Id"
                                                    [attr.data-parent-id]="controlDependencyControllingField(control)"
                                                    [attr.data-rule-id]="control.ConditionDetails.ControllingConditionID"
                                                    [attr.data-specific-text]="control.ConditionDetails.ControllingSupportText"
                                                    style="height:100%;width:100%; max-width:100%"
                                                    (keyup.enter)="isControlFilledOrNot($event, control)"
                                                    id="control_{{control.ControlHtmlID}}" />
                                            </div>
                                        </div>
                                    </div>

                                    <div id="tooltip_{{control.ControlHtmlID}}" class="tooltip noti_tool"
                                        style="display: none;width:90px;padding:0px;">
                                        <div class="right tooltip-width" [ngStyle]="getTooltipAlertposition(control)">
                                            <div class="text-content">
                                                <ul
                                                    style="padding-left: 5px; padding-right: 4px; margin-bottom: 0.56rem;">
                                                    <li>
                                                        <i class="material-icons font-20 cursor-pointer tooltip-close"
                                                            (click)="onCloseTooltip(control.ControlHtmlID)">clear</i>
                                                    </li>
                                                    <li id="note_{{control.ControlHtmlID}}" class="tool-note">

                                                        <div class="d-flex align-items-center">
                                                            <img *ngIf="mobilePrevBtn === 'true'" class="hw-16"
                                                                src="../../../assets/images/left-arrow.png"
                                                                (click)="getControlPrevNav('documentControls')">

                                                            <label class="tooltip-labeltext mb-0 ">Please
                                                                click to add signature</label>
                                                            <img *ngIf="mobileNextBtn === 'true'" class="hw-16"
                                                                src="../../../assets/images/right-arrow.png"
                                                                (click)="getControlNextNav('documentControls')">
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <i class="tooltip-trangle"
                                                [ngStyle]="getTooltipTrangleposition(control)"></i>
                                        </div>
                                    </div>
                                    <div [attr.data-role-name]="control.signerName"
                                        [ngStyle]="getShowTagsStyles(control)"
                                        [attr.data-recipientId]="control.RecipientId"
                                        class="signNowTagsControl showhideTags" [attr.data-unique]="control.Id">
                                        {{control.signerName}}
                                    </div>
                                </div>

                                <div *ngIf="control.ControlName === 'Text'" id="{{control.ControlHtmlID}}"
                                    [attr.data-role-name]="control.signerName"
                                    [attr.data-recipientid]="control.RecipientId"
                                    [ngStyle]="getCtrlInlineStyleIfIsReadOnlyNull(control)" class="signNowControl popUp"
                                    [attr.data-o-width]="control.CalculatedModalWidth" [attr.data-page]="control.PageNo"
                                    [attr.data-unique]="control.Id" [attr.data-rq]="control.Required"
                                    [attr.data-ctntype]="control.ControlName" [attr.data-isfixed-width]="control.IsFixedWidth">
                                    <div class="signerControl" style="Width:100%;height:100%">
                                        <div [ngClass]="getRequiredControlClass(control, control.ControlValue)"
                                            class="additionalInfo" style="Width:100%;height:100%">
                                            <input *ngIf="control.TextType === 'Date'"
                                                class="inpDateControl {{getIphoneBoxSizingClass()}}" autocomplete="off"
                                                name="dControl{{i+1}}" ngbDatepicker #dateText="ngbDatepicker"
                                                (dateSelect)="onDateSelected($event, control, control.TextTypeValue, dateText)"
                                                (keyup)="controlOnchangeEvent($event, control)"
                                                (focus)="onShowDatePicker(dateText, control.TabIndex, control)"
                                                restoreFocus="false" title="{{control.CustomToolTip}}"
                                                [maxDate]="maxDate" [minDate]="minDate"
                                                [attr.data-ctntype]="control.TextTypeMask"
                                                [attr.data-contrl-value]="control.ControlValue"
                                                [attr.data-document-content-id]="control.Id"
                                                [attr.data-parent-id]="controlDependencyControllingField(control)"
                                                [attr.data-rule-id]="control.ConditionDetails.ControllingConditionID"
                                                [attr.data-specific-text]="control.ConditionDetails.ControllingSupportText"
                                                placeholder="{{control.Label}}" type="text"
                                                [ngStyle]="getTextDateCtrlInlineStyle(control)"
                                                tabindex="{{control.TabIndex}}" [attr.data-title]="control.TextType"
                                                id="control_{{control.ControlHtmlID}}" value="{{control.ControlValue}}"
                                                (keyup.enter)="isControlFilledOrNot($event, control)" />
                                            <input *ngIf="control.TextType === 'Email' && control.IsFixedWidth === true" autocomplete="off"
                                                class="textsignerControl {{getIphoneBoxSizingClass()}}"
                                                spellcheck="false"
                                                value="{{control.ControlValue}}" title="{{control.IsFixedWidth ? control.CustomToolTip : ''}}"
                                                [attr.data-submit-required]="0"
                                                [attr.data-contrl-value]="control.ControlValue"
                                                [attr.data-document-content-id]="control.Id"
                                                [attr.data-parent-id]="controlDependencyControllingField(control)"
                                                [attr.data-rule-id]="control.ConditionDetails.ControllingConditionID"
                                                [attr.data-specific-text]="control.ConditionDetails.ControllingSupportText"
                                                type="text" placeholder="{{control.Label}}"
                                                [attr.data-maxlength]="20"
                                                [attr.maxlength]="control.IsFixedWidth ? 50 : ''"
                                                [attr.data-isfixed-width]="control.IsFixedWidth"
                                                tabindex="{{control.TabIndex}}" [attr.data-title]="control.TextType"
                                                [ngStyle]="getTextNotDateAndEmailCtrlStyle(control)"
                                                id="control_{{control.ControlHtmlID}}"
                                                (ontouchstart)="validateInputText($event, control);addFilledfilledControlsClass(control)"
                                                (click)="onShowControlTooltip(control, '')"
                                                (focusout)="onFocusOutEvent($event, control);validateInputText($event, control);addFilledfilledControlsClass(control)"
                                                (keyup.enter)="isControlFilledOrNot($event, control)"
                                                (keyup)="checkAutoExpand($event, control)" />
                                            <textarea *ngIf="control.TextType === 'Email' && control.IsFixedWidth === false" autocomplete="off"
                                                class="textsignerControl {{getIphoneBoxSizingClass()}}"
                                                spellcheck="false"
                                                value="{{control.ControlValue}}" title="{{control.IsFixedWidth ? control.CustomToolTip : ''}}"
                                                [attr.data-submit-required]="0"
                                                [attr.data-contrl-value]="control.ControlValue"
                                                [attr.data-document-content-id]="control.Id"
                                                [attr.data-parent-id]="controlDependencyControllingField(control)"
                                                [attr.data-rule-id]="control.ConditionDetails.ControllingConditionID"
                                                [attr.data-specific-text]="control.ConditionDetails.ControllingSupportText"
                                                type="text" placeholder="{{control.Label}}"
                                                [attr.data-maxlength]="20"
                                                [attr.maxlength]="control.IsFixedWidth ? 50 : control.MaxInputCharLimit"
                                                [attr.data-isfixed-width]="control.IsFixedWidth"
                                                tabindex="{{control.TabIndex}}" [attr.data-title]="control.TextType"
                                                [ngStyle]="getTextNotDateAndEmailCtrlStyle(control)"
                                                id="control_{{control.ControlHtmlID}}"
                                                (ontouchstart)="validateInputText($event, control);addFilledfilledControlsClass(control)"
                                                (click)="onShowControlTooltip(control, '')"
                                                (focusout)="onFocusOutEvent($event, control);validateInputText($event, control);addFilledfilledControlsClass(control)"
                                                (keyup.enter)="isControlFilledOrNot($event, control)"
                                                (keyup)="checkAutoExpand($event, control)" ></textarea>
                                            <div style="width:100%;">
                                                <textarea
                                                    class="textsignerControl {{getIphoneBoxSizingClass()}} mycustomTextAreaClass"
                                                    *ngIf="control.TextType !== 'Date' && control.TextType !== 'Email'"
                                                    autocomplete="off" autocorrect="off" autocapitalize="off"
                                                    spellcheck="false" name="{{control.TextTypeValue}}"
                                                    [title]="((control.TextType === 'Text' || control.TextType === 'Numeric' || control.TextType === 'Alphabet') && control.IsFixedWidth === false ) ? '' : control.CustomToolTip"
                                                    value="{{control.ControlValue}}"
                                                    [attr.data-contrl-value]="control.ControlValue"
                                                    [attr.data-mask]="control.TextTypeMask"
                                                    [attr.data-document-content-id]="control.Id"
                                                    [attr.data-parent-id]="controlDependencyControllingField(control)"
                                                    [attr.data-rule-id]="control.ConditionDetails.ControllingConditionID"
                                                    [attr.data-specific-text]="control.ConditionDetails.ControllingSupportText"
                                                    [attr.data-role]="'none'"
                                                    [ngStyle]="getTextNotDateAndEmailCtrlStyle(control)"
                                                    [attr.data-maxlength]="control.MaxInputLength ? control.MaxInputLength : 20"
                                                    tabindex="{{control.TabIndex}}" [attr.data-title]="control.TextType"
                                                    id="control_{{control.ControlHtmlID}}"
                                                    placeholder="{{control.Label}}"
                                                    (ontouchstart)="controlOnchangeEvent($event, control);validateInputText($event, control);"
                                                    [attr.maxlength]="control.IsFixedWidth ? control.MaxInputLength : control.MaxInputCharLimit"
                                                    [attr.data-isfixed-width]="control.IsFixedWidth"
                                                    (keyup)="controlOnchangeEvent($event, control);checkTextDependenciesControl($event,control);checkAutoExpand($event, control)"
                                                    (click)="onShowControlTooltip(control, '')"
                                                    (focusout)="onFocusOutEvent($event, control);validateInputText($event, control);addFilledfilledControlsClass(control)"
                                                    (keypress)="isNumberKey($event,control.ControlHtmlID)"
                                                    (keyup.enter)="isControlFilledOrNot($event, control)"></textarea>
                                            </div>
                                            <div id="tooltip_{{control.ControlHtmlID}}" class="tooltip noti_tool"
                                                style="display: none;width:90px;padding:0px;">
                                                <div class="right tooltip-width"
                                                    [ngStyle]="getTooltipAlertposition(control)">
                                                    <div class="text-content">
                                                        <ul
                                                            style="padding-left: 5px; padding-right: 4px; margin-bottom: 0.56rem;">
                                                            <li>
                                                                <i class="material-icons font-20 cursor-pointer tooltip-close"
                                                                    (click)="onCloseTooltip(control.ControlHtmlID)">clear</i>
                                                            </li>
                                                            <li id="note_{{control.ControlHtmlID}}" class="tool-note">
                                                                <div class="d-flex align-items-center">
                                                                    <img *ngIf="mobilePrevBtn === 'true'" class="hw-16"
                                                                        src="../../../assets/images/left-arrow.png"
                                                                        (click)="getControlPrevNav('documentControls')">
                                                                    <!-- <span *ngIf="mobilePrevBtn === 'true'" class="material-icons font-18 firsticon cursor-pointer"
                                                                                    (click)="getControlPrevNav('documentControls')">
                                                                                    play_circle_outline
                                                                                </span>  -->
                                                                    <label class="tooltip-labeltext mb-0 ">
                                                                        {{getLanguageTranslationValue(resourceKeys.lang_PleaseEnter)}}
                                                                        {{control.Label | lowercase}}</label>
                                                                    <img *ngIf="mobileNextBtn === 'true'" class="hw-16"
                                                                        src="../../../assets/images/right-arrow.png"
                                                                        (click)="getControlNextNav('documentControls')">
                                                                    <!-- <span *ngIf="mobileNextBtn === 'true'" class="material-icons font-18 secondicon cursor-pointer"
                                                                                    (click)="getControlNextNav('documentControls')">
                                                                                    play_circle_outline
                                                                                </span> -->
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <i class="tooltip-trangle"
                                                        [ngStyle]="getTooltipTrangleposition(control)"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div [attr.data-role-name]="control.signerName"
                                        [ngStyle]="getShowTagsStyles(control)"
                                        [attr.data-recipientId]="control.RecipientId"
                                        class="signNowTagsControl showhideTags" [attr.data-unique]="control.Id">
                                        {{control.signerName}}
                                    </div>
                                </div>

                                <div *ngIf="control.ControlName === 'Name'" [attr.data-role-name]="control.signerName"
                                    id="{{control.ControlHtmlID}}" [attr.data-recipientid]="control.RecipientId"
                                    [ngStyle]="getCtrlInlineStyleIfIsReadOnlyNull(control)"
                                    class="signNowControl popUp nameControlClass" [attr.data-page]="control.PageNo"
                                    [attr.data-unique]="control.Id" [attr.data-rq]="control.Required"
                                    [attr.data-ctntype]="control.ControlName" [attr.data-isfixed-width]="control.IsFixedWidth">
                                    <div class="signerControl" style="Width:100%;height:100%">
                                        <div [ngClass]="getRequiredControlClass(control,control.ControlValue)"
                                            class="additionalInfo" style="Width:100%;height:100%">
                                            <textarea value="{{control.ControlValue}}" autocomplete="off"
                                                spellcheck="false"
                                                title="{{control.IsFixedWidth ? control.CustomToolTip : ''}}"
                                                title="{{control.CustomToolTip}}"
                                                [attr.data-contrl-value]="control.ControlValue"
                                                [attr.data-document-content-id]="control.Id"
                                                [attr.data-parent-id]="controlDependencyControllingField(control)"
                                                [attr.data-rule-id]="control.ConditionDetails.ControllingConditionID"
                                                [attr.data-specific-text]="control.ConditionDetails.ControllingSupportText"
                                                type="text" [attr.data-maxlength]="control.MaxInputLength ? control.MaxInputLength : 20"
                                                [attr.maxlength]="control.IsFixedWidth ? control.MaxInputLength : control.MaxInputCharLimit"
                                                [attr.data-isfixed-width]="control.IsFixedWidth"
                                                placeholder="{{control.LanguageControlName}}"
                                                tabindex="{{control.TabIndex}}"
                                                [ngStyle]="getTextEmailCtrlInlineStyle(control)"
                                                class="{{getIphoneBoxSizingClass()}}"
                                                id="control_{{control.ControlHtmlID}}"
                                                (ontouchstart)="validateInputText($event, control);addFilledfilledControlsClass(control)"
                                                (click)="onShowControlTooltip(control, '')"
                                                (focusout)="validateInputText($event, control);addFilledfilledControlsClass(control)"
                                                (keyup.enter)="isControlFilledOrNot($event, control)"
                                                (keyup)="checkAutoExpand($event, control)"></textarea>
                                        </div>
                                    </div>
                                    <div id="tooltip_{{control.ControlHtmlID}}" class="tooltip noti_tool"
                                        style="display: none;width:90px;padding:0px;">
                                        <div class="right tooltip-width" [ngStyle]="getTooltipAlertposition(control)">
                                            <div class="text-content">
                                                <ul
                                                    style="padding-left: 5px; padding-right: 4px; margin-bottom: 0.56rem;">
                                                    <li>
                                                        <i class="material-icons font-20 cursor-pointer tooltip-close"
                                                            (click)="onCloseTooltip(control.ControlHtmlID)">clear</i>
                                                    </li>
                                                    <li id="note_{{control.ControlHtmlID}}" class="tool-note">
                                                        <!-- <span *ngIf="mobilePrevBtn === 'true'" class="material-icons font-18 firsticon cursor-pointer"
                                                                    (click)="getControlPrevNav('documentControls')">
                                                                    play_circle_outline
                                                                </span>
                                                                <label  class="tooltip-labeltext">Please enter name</label>
                                                                <span *ngIf="mobileNextBtn === 'true'" class="material-icons font-18 secondicon cursor-pointer"
                                                                    (click)="getControlNextNav('documentControls')">
                                                                    play_circle_outline
                                                                </span> -->
                                                        <div class="d-flex align-items-center">
                                                            <img *ngIf="mobilePrevBtn === 'true'" class="hw-16"
                                                                src="../../../assets/images/left-arrow.png"
                                                                (click)="getControlPrevNav('documentControls')">

                                                            <label class="tooltip-labeltext mb-0 ">
                                                                {{getLanguageTranslationValue(resourceKeys.lang_PleaseEnter)}}
                                                                {{control.LanguageControlName | lowercase}}</label>
                                                            <img *ngIf="mobileNextBtn === 'true'" class="hw-16"
                                                                src="../../../assets/images/right-arrow.png"
                                                                (click)="getControlNextNav('documentControls')">
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <i class="tooltip-trangle"
                                                [ngStyle]="getTooltipTrangleposition(control)"></i>
                                        </div>
                                    </div>
                                    <div [attr.data-role-name]="control.signerName"
                                        [ngStyle]="getShowTagsStyles(control)"
                                        [attr.data-recipientId]="control.RecipientId"
                                        class="signNowTagsControl showhideTags" [attr.data-unique]="control.Id">
                                        {{control.signerName}}
                                    </div>
                                </div>

                                <div *ngIf="control.ControlName === 'Email'" [attr.data-role-name]="control.signerName"
                                    id="{{control.ControlHtmlID}}" [attr.data-recipientid]="control.RecipientId"
                                    [ngStyle]="getCtrlInlineStyleIfIsReadOnlyNull(control)"
                                    class="signNowControl popUp emailControlClass" [attr.data-page]="control.PageNo"
                                    [attr.data-unique]="control.Id" [attr.data-rq]="control.Required"
                                    [attr.data-ctntype]="control.ControlName">
                                    <div class="signerControl" style="Width:100%;height:100%">
                                        <div [ngClass]="getRequiredControlClass(control,control.ControlValue)"
                                            class="additionalInfo emailControls" style="Width:100%;height:100%">
                                            <input class="emailControlsInput {{getIphoneBoxSizingClass()}}"
                                                value="{{control.ControlValue}}"
                                                [attr.data-role-name]="control.signerName" autocomplete="off"
                                                title="{{control.CustomToolTip}}"
                                                [attr.data-submit-required]="control.SenderControlValue"
                                                [attr.data-contrl-value]="control.ControlValue"
                                                [attr.data-document-content-id]="control.Id"
                                                [attr.data-parent-id]="controlDependencyControllingField(control)"
                                                [attr.data-rule-id]="control.ConditionDetails.ControllingConditionID"
                                                [attr.data-specific-text]="control.ConditionDetails.ControllingSupportText"
                                                type="text" maxlength="50" placeholder="{{control.LanguageControlName}}"
                                                tabindex="{{control.TabIndex}}"
                                                [ngStyle]="getTextEmailCtrlInlineStyle(control)"
                                                id="control_{{control.ControlHtmlID}}" aria-hidden="true"
                                                (ontouchstart)="validateInputText($event, control);addFilledfilledControlsClass(control)"
                                                (click)="onShowControlTooltip(control, '')"
                                                (focusout)="validateInputText($event, control);addFilledfilledControlsClass(control)"
                                                (keyup.enter)="isControlFilledOrNot($event, control)" />
                                        </div>
                                    </div>

                                    <div id="tooltip_{{control.ControlHtmlID}}" class="tooltip noti_tool"
                                        style="display: none;width:90px;padding:0px;">
                                        <div class="right tooltip-width" [ngStyle]="getTooltipAlertposition(control)">
                                            <div class="text-content">
                                                <ul
                                                    style="padding-left: 5px; padding-right: 4px; margin-bottom: 0.56rem;">
                                                    <li>
                                                        <i class="material-icons font-20 cursor-pointer tooltip-close"
                                                            (click)="onCloseTooltip(control.ControlHtmlID)">clear</i>
                                                    </li>
                                                    <li id="note_{{control.ControlHtmlID}}" class="tool-note">
                                                        <!-- <span *ngIf="mobilePrevBtn === 'true'" class="material-icons font-18 firsticon cursor-pointer"
                                                                    (click)="getControlPrevNav('documentControls')">
                                                                    play_circle_outline
                                                                </span>
                                                                <label  class="tooltip-labeltext">Please enter email</label>
                                                                <span *ngIf="mobileNextBtn === 'true'" class="material-icons font-18 secondicon cursor-pointer"
                                                                    (click)="getControlNextNav('documentControls')">
                                                                    play_circle_outline
                                                                </span> -->
                                                        <div class="d-flex align-items-center">
                                                            <img *ngIf="mobilePrevBtn === 'true'" class="hw-16"
                                                                src="../../../assets/images/left-arrow.png"
                                                                (click)="getControlPrevNav('documentControls')">

                                                            <label class="tooltip-labeltext mb-0 ">
                                                                {{getLanguageTranslationValue(resourceKeys.lang_PleaseEnter)}}
                                                                {{control.LanguageControlName | lowercase}}</label>
                                                            <img *ngIf="mobileNextBtn === 'true'" class="hw-16"
                                                                src="../../../assets/images/right-arrow.png"
                                                                (click)="getControlNextNav('documentControls')">
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <i class="tooltip-trangle"
                                                [ngStyle]="getTooltipTrangleposition(control)"></i>
                                        </div>
                                    </div>
                                    <div [attr.data-role-name]="control.signerName"
                                        [ngStyle]="getShowTagsStyles(control)"
                                        [attr.data-recipientId]="control.RecipientId"
                                        class="signNowTagsControl showhideTags" [attr.data-unique]="control.Id">
                                        {{control.signerName}}
                                    </div>
                                </div>

                                <div *ngIf="control.ControlName === 'Title'" [attr.data-role-name]="control.signerName"
                                    id="{{control.ControlHtmlID}}" [attr.data-recipientid]="control.RecipientId"
                                    [ngStyle]="getCtrlInlineStyleIfIsReadOnlyNull(control)"
                                    class="signNowControl popUp titleControlClass" [attr.data-page]="control.PageNo"
                                    [attr.data-unique]="control.Id" [attr.data-rq]="control.Required"
                                    [attr.data-ctntype]="control.ControlName" [attr.data-isfixed-width]="control.IsFixedWidth">
                                    <div class="signerControl" style="Width:100%;height:100%;">
                                        <div [ngClass]="getRequiredControlClass(control,control.ControlValue)"
                                            class="additionalInfo" id="sfs" style="Width:100%;height:100%;">
                                            <textarea value="{{control.ControlValue}}" autocomplete="off"
                                                title="{{control.IsFixedWidth ? control.CustomToolTip : ''}}" spellcheck="false"
                                                [attr.data-contrl-value]="control.ControlValue"
                                                [attr.data-document-content-id]="control.Id"
                                                [attr.data-parent-id]="controlDependencyControllingField(control)"
                                                [attr.data-rule-id]="control.ConditionDetails.ControllingConditionID"
                                                type="text"
                                                [attr.data-maxlength]="control.MaxInputLength ? control.MaxInputLength : 20"
                                                [attr.maxlength]="control.IsFixedWidth ? control.MaxInputLength : control.MaxInputCharLimit"
                                                [attr.data-isfixed-width]="control.IsFixedWidth"
                                                placeholder="{{control.LanguageControlName}}"
                                                [ngStyle]="getTextEmailCtrlInlineStyle(control)"
                                                tabindex="{{control.TabIndex}}" class="{{getIphoneBoxSizingClass()}}"
                                                id="control_{{control.ControlHtmlID}}"
                                                (click)="onShowControlTooltip(control, '')"
                                                (ontouchstart)="validateInputText($event, control);addFilledfilledControlsClass(control)"
                                                (keyup.enter)="isControlFilledOrNot($event, control)"
                                                (focusout)="validateInputText($event, control);addFilledfilledControlsClass(control)"
                                                (keyup)="checkAutoExpand($event, control)"></textarea>
                                        </div>
                                    </div>
                                    <div id="tooltip_{{control.ControlHtmlID}}" class="tooltip noti_tool"
                                        style="display: none;width:90px;padding:0px;">
                                        <div class="right tooltip-width" [ngStyle]="getTooltipAlertposition(control)">
                                            <div class="text-content">
                                                <ul
                                                    style="padding-left: 5px; padding-right: 4px; margin-bottom: 0.56rem;">
                                                    <li>
                                                        <i class="material-icons font-20 cursor-pointer tooltip-close"
                                                            (click)="onCloseTooltip(control.ControlHtmlID)">clear</i>
                                                    </li>
                                                    <li id="note_{{control.ControlHtmlID}}" class="tool-note">
                                                        <!-- <span *ngIf="mobilePrevBtn === 'true'" class="material-icons font-18 firsticon cursor-pointer"
                                                                    (click)="getControlPrevNav('documentControls')">
                                                                    play_circle_outline
                                                                </span>
                                                                <label  class="tooltip-labeltext">Please enter title</label>
                                                                <span *ngIf="mobileNextBtn === 'true'" class="material-icons font-18 secondicon cursor-pointer"
                                                                    (click)="getControlNextNav('documentControls')">
                                                                    play_circle_outline
                                                                </span> -->
                                                        <div class="d-flex align-items-center">
                                                            <img *ngIf="mobilePrevBtn === 'true'" class="hw-16"
                                                                src="../../../assets/images/left-arrow.png"
                                                                (click)="getControlPrevNav('documentControls')">

                                                            <label class="tooltip-labeltext mb-0 ">
                                                                {{getLanguageTranslationValue(resourceKeys.lang_PleaseEnter)}}
                                                                {{control.LanguageControlName | lowercase}}</label>
                                                            <img *ngIf="mobileNextBtn === 'true'" class="hw-16"
                                                                src="../../../assets/images/right-arrow.png"
                                                                (click)="getControlNextNav('documentControls')">
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <i class="tooltip-trangle"
                                                [ngStyle]="getTooltipTrangleposition(control)"></i>
                                        </div>
                                    </div>
                                    <div [attr.data-role-name]="control.signerName"
                                        [ngStyle]="getShowTagsStyles(control)"
                                        [attr.data-recipientId]="control.RecipientId"
                                        class="signNowTagsControl showhideTags" [attr.data-unique]="control.Id">
                                        {{control.signerName}}
                                    </div>
                                </div>

                                <div *ngIf="control.ControlName === 'Date'" [attr.data-role-name]="control.signerName"
                                    id="{{control.ControlHtmlID}}" [attr.data-recipientid]="control.RecipientId"
                                    [ngStyle]="getCtrlInlineStyleIfIsReadOnlyNull(control)"
                                    class="signNowControl popUp ctnNotResize date" [attr.data-page]="control.PageNo"
                                    [attr.data-unique]="control.Id" [attr.data-rq]="control.Required"
                                    [attr.data-ctntype]="control.ControlName">
                                    <div class="signerControl" style="Width:100%;height:100%">
                                        <div [ngClass]="getRequiredControlClass(control,control.ControlValue)"
                                            class="additionalInfo" style="Width:100%;height:100%;">
                                            <input autocomplete="off" name="dControl{{i+1}}"
                                                class="inpDateControl {{getIphoneBoxSizingClass()}}" ngbDatepicker
                                                #dControl="ngbDatepicker"
                                                (dateSelect)="onDateSelected($event, control, control.Label, dControl)"
                                                (keyup)="controlOnchangeEvent($event, control);"
                                                (focus)="onShowDatePicker(dControl, control.TabIndex, control);"
                                                restoreFocus="false" title="{{control.CustomToolTip}}"
                                                [maxDate]="maxDate" [minDate]="minDate" [attr.data-title]="'Date'"
                                                [attr.data-contrl-value]="control.ControlValue"
                                                [attr.data-document-content-id]="control.Id"
                                                [attr.data-parent-id]="controlDependencyControllingField(control)"
                                                [attr.data-rule-id]="control.ConditionDetails.ControllingConditionID"
                                                [attr.data-specific-text]="control.ConditionDetails.ControllingSupportText"
                                                [placeholder]="control.Label" type="text"
                                                [ngStyle]="getTextEmailCtrlInlineStyle(control)"
                                                tabindex="{{control.TabIndex}}" id="control_{{control.ControlHtmlID}}"
                                                [attr.dateformat]="control.Label"
                                                [attr.data-defaultrequired]="control.IsDefaultRequired"
                                                [attr.value]="control.ControlValue"
                                                [attr.data-default-date]="getDefaultplaceHolder(control.DefaultDateFormat)" />
                                        </div>
                                    </div>

                                    <div id="tooltip_{{control.ControlHtmlID}}" class="tooltip noti_tool"
                                        style="display: none;width:90px;padding:0px;">
                                        <div class="right tooltip-width" [ngStyle]="getTooltipAlertposition(control)">
                                            <div class="text-content">
                                                <ul
                                                    style="padding-left: 5px; padding-right: 4px; margin-bottom: 0.56rem;">
                                                    <li>
                                                        <i class="material-icons font-20 cursor-pointer tooltip-close"
                                                            (click)="onCloseTooltip(control.ControlHtmlID)">clear</i>
                                                    </li>
                                                    <li id="note_{{control.ControlHtmlID}}" class="tool-note">
                                                        <!-- <span *ngIf="mobilePrevBtn === 'true'" class="material-icons font-18 firsticon cursor-pointer"
                                                                    (click)="getControlPrevNav('documentControls')">
                                                                    play_circle_outline
                                                                </span>
                                                                <label  class="tooltip-labeltext">Please enter date</label>
                                                                <span *ngIf="mobileNextBtn === 'true'" class="material-icons font-18 secondicon cursor-pointer"
                                                                    (click)="getControlNextNav('documentControls')">
                                                                    play_circle_outline
                                                                </span> -->
                                                        <div class="d-flex align-items-center">
                                                            <img *ngIf="mobilePrevBtn === 'true'" class="hw-16"
                                                                src="../../../assets/images/left-arrow.png"
                                                                (click)="getControlPrevNav('documentControls')">

                                                            <label class="tooltip-labeltext mb-0 ">Please
                                                                enter
                                                                date</label>
                                                            <img *ngIf="mobileNextBtn === 'true'" class="hw-16"
                                                                src="../../../assets/images/right-arrow.png"
                                                                (click)="getControlNextNav('documentControls')">
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <i class="tooltip-trangle"
                                                [ngStyle]="getTooltipTrangleposition(control)"></i>
                                        </div>
                                    </div>
                                    <div [attr.data-role-name]="control.signerName"
                                        [ngStyle]="getShowTagsStyles(control)"
                                        [attr.data-recipientId]="control.RecipientId"
                                        class="signNowTagsControl showhideTags" [attr.data-unique]="control.Id">
                                        {{control.signerName}}
                                    </div>
                                </div>

                                <div *ngIf="control.ControlName === 'Label'" [attr.data-role-name]="control.signerName"
                                    id="{{control.ControlHtmlID}}"
                                    [ngStyle]="getCtrlInlineStyleIfIsReadOnlyNull(control)"
                                    class="signNowControl popUp ui-draggable orange-bg"
                                    [attr.data-page]="control.PageNo" [attr.data-unique]="control.Id"
                                    [attr.data-rq]="control.Required" [attr.data-ctntype]="control.ControlName">
                                    <div class="">
                                        <div class="additionalInfo signLevel">
                                            <a title="{{control.HoverTitle}}" class=""></a>
                                        </div>
                                        <div class="controls" style="text-align:left;">
                                            <span class="userLabel">
                                                <label [ngStyle]="getCtrlLabelStyleIsReadOnlyNull(control)">
                                                    {{control.Label}}
                                                </label>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="control.ControlName === 'Company'"
                                    [attr.data-role-name]="control.signerName" id="{{control.ControlHtmlID}}"
                                    [attr.data-recipientid]="control.RecipientId"
                                    [ngStyle]="getCtrlInlineStyleIfIsReadOnlyNull(control)"
                                    class="signNowControl popUp companyControlClass" [attr.data-page]="control.PageNo"
                                    [attr.data-unique]="control.Id" [attr.data-rq]="control.Required"
                                    [attr.data-ctntype]="control.ControlName">
                                    <div class="signerControl" style="Width:100%;height:100%;">
                                        <div [ngClass]="getRequiredControlClass(control,control.ControlValue)"
                                            class="additionalInfo" style="Width:100%;height:100%;">
                                            <input value="{{control.ControlValue}}" autocomplete="off"
                                                title="{{control.CustomToolTip}}"
                                                [attr.data-contrl-value]="control.ControlValue"
                                                [attr.data-document-content-id]="control.Id"
                                                [attr.data-parent-id]="controlDependencyControllingField(control)"
                                                [attr.data-rule-id]="control.ConditionDetails.ControllingConditionID"
                                                [attr.data-specific-text]="control.ConditionDetails.ControllingSupportText"
                                                type="text" [ngStyle]="getTextEmailCtrlInlineStyle(control)"
                                                tabindex="{{control.TabIndex}}" class="{{getIphoneBoxSizingClass()}}"
                                                placeholder="{{control.LanguageControlName}}" maxlength="50"
                                                id="control_{{control.ControlHtmlID}}"
                                                (ontouchstart)="validateInputText($event, control);addFilledfilledControlsClass(control)"
                                                (focusout)="validateInputText($event, control);addFilledfilledControlsClass(control)"
                                                (click)="onShowControlTooltip(control, '')"
                                                (keyup.enter)="isControlFilledOrNot($event, control)" />
                                        </div>
                                    </div>
                                    <div id="tooltip_{{control.ControlHtmlID}}" class="tooltip noti_tool"
                                        style="display: none;width:90px;padding:0px;">
                                        <div class="right tooltip-width" [ngStyle]="getTooltipAlertposition(control)">
                                            <div class="text-content">
                                                <ul
                                                    style="padding-left: 5px; padding-right: 4px; margin-bottom: 0.56rem;">
                                                    <li>
                                                        <i class="material-icons font-20 cursor-pointer tooltip-close"
                                                            (click)="onCloseTooltip(control.ControlHtmlID)">clear</i>
                                                    </li>
                                                    <li id="note_{{control.ControlHtmlID}}" class="tool-note">
                                                        <!-- <span *ngIf="mobilePrevBtn === 'true'" class="material-icons font-18 firsticon cursor-pointer"
                                                                    (click)="getControlPrevNav('documentControls')">
                                                                    play_circle_outline
                                                                </span>
                                                                <label  class="tooltip-labeltext">Please enter company</label>
                                                                <span *ngIf="mobileNextBtn === 'true'" class="material-icons font-18 secondicon cursor-pointer"
                                                                    (click)="getControlNextNav('documentControls')">
                                                                    play_circle_outline
                                                                </span> -->
                                                        <div class="d-flex align-items-center">
                                                            <img *ngIf="mobilePrevBtn === 'true'" class="hw-16"
                                                                src="../../../assets/images/left-arrow.png"
                                                                (click)="getControlPrevNav('documentControls')">

                                                            <label class="tooltip-labeltext mb-0 ">
                                                                {{getLanguageTranslationValue(resourceKeys.lang_PleaseEnter)}}
                                                                {{control.LanguageControlName | lowercase}}</label>
                                                            <img *ngIf="mobileNextBtn === 'true'" class="hw-16"
                                                                src="../../../assets/images/right-arrow.png"
                                                                (click)="getControlNextNav('documentControls')">
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <i class="tooltip-trangle"
                                                [ngStyle]="getTooltipTrangleposition(control)"></i>
                                        </div>
                                    </div>
                                    <div [attr.data-role-name]="control.signerName"
                                        [ngStyle]="getShowTagsStyles(control)"
                                        [attr.data-recipientId]="control.RecipientId"
                                        class="signNowTagsControl showhideTags" [attr.data-unique]="control.Id">
                                        {{control.signerName}}
                                    </div>
                                </div>

                                <div *ngIf="control.ControlName === 'DropDown'"
                                    [attr.data-role-name]="control.signerName" id="{{control.ControlHtmlID}}"
                                    [attr.data-recipientid]="control.RecipientId"
                                    [ngStyle]="getCtrlInlineStyleIfIsReadOnlyNull(control)" class="signNowControl popUp"
                                    [attr.data-page]="control.PageNo" [attr.data-unique]="control.Id"
                                    [attr.data-rq]="control.Required" [attr.data-ctntype]="control.ControlName">

                                    <div class="signerControl" style="Width:100%;height:100%;">
                                        <div [ngClass]="getRequiredDropdownControlClass(control)"
                                            class="additionalInfo selectdiv" style="Width:100%;height:100%;">
                                            <select class="custome-select" #selectedVal
                                                [attr.data-contrl-value]="control.ControlValue"
                                                title="{{control.CustomToolTip}}"
                                                [attr.data-document-content-id]="control.Id"
                                                [attr.data-parent-id]="controlDependencyControllingField(control)"
                                                [attr.data-rule-id]="control.ConditionDetails.ControllingConditionID"
                                                [attr.data-specific-text]="control.ConditionDetails.ControllingSupportText"
                                                tabindex="{{control.TabIndex}}" id="control_{{control.ControlHtmlID}}"
                                                [ngStyle]="getSelectTagStyle(control)"
                                                [attr.data-selected-value]="selectedVal.value"
                                                (change)="checkDependenciesControl(control, selectedVal.value);hideToolTip(control);isControlFilledOrNot($event, control);">
                                                <option *ngFor="let listOption of control.ControlOptions"
                                                    [selected]="isSelected(control.ControlValue, listOption.OptionText)"
                                                    value="{{listOption.ID}}" style="font-size:14px;">
                                                    {{listOption.OptionText}}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div id="tooltip_{{control.ControlHtmlID}}" class="tooltip noti_tool"
                                        style="display: none;width:90px;padding:0px;">
                                        <div class="right tooltip-width" [ngStyle]="getTooltipAlertposition(control)">
                                            <div class="text-content">
                                                <ul
                                                    style="padding-left: 5px; padding-right: 4px; margin-bottom: 0.56rem;">
                                                    <li>
                                                        <i class="material-icons font-20 cursor-pointer tooltip-close"
                                                            (click)="onCloseTooltip(control.ControlHtmlID)">clear</i>
                                                    </li>
                                                    <li id="note_{{control.ControlHtmlID}}" class="tool-note">
                                                        <!-- <span *ngIf="mobilePrevBtn === 'true'" class="material-icons font-18 firsticon cursor-pointer"
                                                                    (click)="getControlPrevNav('documentControls')">
                                                                    play_circle_outline
                                                                </span>
                                                                <label  class="tooltip-labeltext">Please select an option</label>
                                                                <span *ngIf="mobileNextBtn === 'true'" class="material-icons font-18 secondicon cursor-pointer"
                                                                    (click)="getControlNextNav('documentControls')">
                                                                    play_circle_outline
                                                                </span> -->
                                                        <div class="d-flex align-items-center">
                                                            <img *ngIf="mobilePrevBtn === 'true'" class="hw-16"
                                                                src="../../../assets/images/left-arrow.png"
                                                                (click)="getControlPrevNav('documentControls')">

                                                            <label class="tooltip-labeltext mb-0 ">
                                                                {{getLanguageTranslationValue(resourceKeys.lang_SlectOption)}}</label>
                                                            <img *ngIf="mobileNextBtn === 'true'" class="hw-16"
                                                                src="../../../assets/images/right-arrow.png"
                                                                (click)="getControlNextNav('documentControls')">
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <i class="tooltip-trangle"
                                                [ngStyle]="getTooltipTrangleposition(control)"></i>
                                        </div>
                                    </div>
                                    <div [attr.data-role-name]="control.signerName"
                                        [ngStyle]="getShowTagsStyles(control)"
                                        [attr.data-recipientId]="control.RecipientId"
                                        class="signNowTagsControl showhideTags" [attr.data-unique]="control.Id">
                                        {{control.signerName}}
                                    </div>
                                </div>

                                <div *ngIf="control.ControlName === 'Radio'" [attr.data-role-name]="control.signerName"
                                    id="{{control.ControlHtmlID}}" [attr.data-recipientid]="control.RecipientId"
                                    [ngStyle]="getCtrlInlineStyleIfIsReadOnlyNull(control)" class="signNowControl popUp"
                                    [attr.data-page]="control.PageNo" [attr.data-unique]="control.Id"
                                    [attr.data-rq]="control.Required" [attr.data-ctntype]="control.ControlName">
                                    <div class="signerControl" style="Width:100%;height:100%; box-sizing:content-box;">
                                        <div class="controls radioControl additionalInfo"
                                            style="Width:100%;display: inline-table;">
                                            <input [ngStyle]="controlHeightWidth(control)"
                                                [attr.checked]="isChecked(control)" title="{{control.CustomToolTip}}"
                                                tabindex="{{control.TabIndex}}"
                                                [attr.data-contrl-value]="control.ControlValue"
                                                [attr.data-sender-value]="control.SenderControlValue"
                                                [attr.data-document-content-id]="control.Id"
                                                [attr.data-parent-id]="controlDependencyControllingField(control)"
                                                [attr.data-rule-id]="control.ConditionDetails.ControllingConditionID"
                                                [attr.data-specific-text]="control.ConditionDetails.ControllingSupportText"
                                                name="{{control.GroupName}}" type="radio"
                                                id="control_{{control.ControlHtmlID}}"
                                                (ontouchstart)="onShowControlTooltip(control, '');validateInputText($event, control);checkDependenciesControl(control, '');isControlFilledOrNot($event, control)"
                                                (click)="onShowControlTooltip(control, '');validateInputText($event, control);checkDependenciesControl(control, '');isControlFilledOrNot($event, control)"
                                                (keyup.enter)="isControlFilledOrNot($event, control)" />
                                        </div>
                                    </div>

                                    <div id="tooltip_{{control.ControlHtmlID}}" class="tooltip noti_tool"
                                        style="display: none;width:90px;padding:0px;">
                                        <div class="right tooltip-width" [ngStyle]="getTooltipAlertposition(control)">
                                            <div class="text-content">
                                                <ul
                                                    style="padding-left: 5px; padding-right: 4px; margin-bottom: 0.56rem;">
                                                    <li>
                                                        <i class="material-icons font-20 cursor-pointer tooltip-close"
                                                            (click)="onCloseTooltip(control.ControlHtmlID)">clear</i>
                                                    </li>
                                                    <li id="note_{{control.ControlHtmlID}}" class="tool-note">
                                                        <!-- <span *ngIf="mobilePrevBtn === 'true'" class="material-icons font-18 firsticon cursor-pointer"
                                                                    (click)="getControlPrevNav('documentControls')">
                                                                    play_circle_outline
                                                                </span>
                                                                <label  class="tooltip-labeltext">Please select {{control.GroupName | lowercase }}</label>
                                                                <span *ngIf="mobileNextBtn === 'true'" class="material-icons font-18 secondicon cursor-pointer"
                                                                    (click)="getControlNextNav('documentControls')">
                                                                    play_circle_outline
                                                                </span> -->
                                                        <div class="d-flex align-items-center">
                                                            <img *ngIf="mobilePrevBtn === 'true'" class="hw-16"
                                                                src="../../../assets/images/left-arrow.png"
                                                                (click)="getControlPrevNav('documentControls')">

                                                            <label *ngIf="control.GroupName == ''"
                                                                class="tooltip-labeltext mb-0 ">
                                                                {{getLanguageTranslationValue(resourceKeys.lang_SelectRadio)}}
                                                            </label>

                                                            <label *ngIf="control.GroupName != ''"
                                                                class="tooltip-labeltext mb-0 ">
                                                                {{getLanguageTranslationValue(resourceKeys.lang_PleaseSelect)}}
                                                                {{control.GroupName | lowercase }}</label>

                                                            <img *ngIf="mobileNextBtn === 'true'" class="hw-16"
                                                                src="../../../assets/images/right-arrow.png"
                                                                (click)="getControlNextNav('documentControls')">
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <i class="tooltip-trangle"
                                                [ngStyle]="getTooltipTrangleposition(control)"></i>
                                        </div>
                                    </div>
                                    <div [attr.data-role-name]="control.signerName"
                                        [ngStyle]="getShowTagsStyles(control)"
                                        [attr.data-recipientId]="control.RecipientId"
                                        class="signNowTagsControl showhideTags" [attr.data-unique]="control.Id">
                                        {{control.signerName}}
                                    </div>
                                </div>

                                <div *ngIf="control.ControlName === 'Checkbox'"
                                    [attr.data-role-name]="control.signerName" id="{{control.ControlHtmlID}}"
                                    [attr.data-recipientid]="control.RecipientId"
                                    [ngStyle]="getCtrlInlineStyleIfIsReadOnlyNull(control)" class="signNowControl popUp"
                                    [attr.data-page]="control.PageNo" [attr.data-unique]="control.Id"
                                    [attr.data-rq]="control.Required" [attr.data-ctntype]="control.ControlName">
                                    <div class="signerControl" style="Width:100%;height:100%">
                                        <div [ngClass]="getRequiredControlClass(control,control.ControlValue)"
                                            class="additionalInfo" style="Width:100%;height:100%">
                                            <span>
                                                <input [ngStyle]="controlHeightWidth(control)"
                                                    title="{{control.CustomToolTip}}" tabindex="{{control.TabIndex}}"
                                                    [attr.checked]="isChecked(control)"
                                                    [attr.data-contrl-value]="control.ControlValue"
                                                    [attr.data-document-content-id]="control.Id"
                                                    [attr.data-parent-id]="controlDependencyControllingField(control)"
                                                    [attr.data-rule-id]="control.ConditionDetails.ControllingConditionID"
                                                    [attr.data-specific-text]="control.ConditionDetails.ControllingSupportText"
                                                    type="checkbox" id="control_{{control.ControlHtmlID}}"
                                                    name="{{control.GroupName}}"
                                                    (ontouchstart)="onShowControlTooltip(control, '');validateInputText($event, control);checkDependenciesControl(control, '');isControlFilledOrNot($event, control);addFilledfilledControlsClass(control)"
                                                    (click)="onShowControlTooltip(control, '');validateInputText($event, control);checkDependenciesControl(control, '');isControlFilledOrNot($event, control);addFilledfilledControlsClass(control)"
                                                    (keyup.enter)="isControlFilledOrNot($event, control)" />
                                            </span>
                                            <span class="userChkBox"></span>
                                        </div>
                                    </div>

                                    <div id="tooltip_{{control.ControlHtmlID}}" class="tooltip noti_tool"
                                        style="display: none;width:90px;padding:0px;">
                                        <div class="right tooltip-width" [ngStyle]="getTooltipAlertposition(control)">
                                            <div class="text-content">
                                                <ul
                                                    style="padding-left: 5px; padding-right: 4px; margin-bottom: 0.56rem;">
                                                    <li>
                                                        <i class="material-icons font-20 cursor-pointer tooltip-close"
                                                            (click)="onCloseTooltip(control.ControlHtmlID)">clear</i>
                                                    </li>
                                                    <li id="note_{{control.ControlHtmlID}}" class="tool-note">
                                                        <!-- <span *ngIf="mobilePrevBtn === 'true'" class="material-icons font-18 firsticon cursor-pointer"
                                                                    (click)="getControlPrevNav('documentControls')">
                                                                    play_circle_outline
                                                                </span>
                                                                <label  class="tooltip-labeltext">Please select checkbox</label>
                                                                <span *ngIf="mobileNextBtn === 'true'" class="material-icons font-18 secondicon cursor-pointer"
                                                                    (click)="getControlNextNav('documentControls')">
                                                                    play_circle_outline
                                                                </span> -->
                                                        <div class="d-flex align-items-center">
                                                            <img *ngIf="mobilePrevBtn === 'true'" class="hw-16"
                                                                src="../../../assets/images/left-arrow.png"
                                                                (click)="getControlPrevNav('documentControls')">

                                                            <label *ngIf="control.GroupName == ''"
                                                                class="tooltip-labeltext mb-0 ">
                                                                {{getLanguageTranslationValue(resourceKeys.lang_SelectCheckbox)}}
                                                            </label>

                                                            <label *ngIf="control.GroupName != ''"
                                                                class="tooltip-labeltext mb-0 ">
                                                                {{getLanguageTranslationValue(resourceKeys.lang_PleaseSelect)}}
                                                                {{control.GroupName | lowercase }}</label>

                                                            <img *ngIf="mobileNextBtn === 'true'" class="hw-16"
                                                                src="../../../assets/images/right-arrow.png"
                                                                (click)="getControlNextNav('documentControls')">
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <i class="tooltip-trangle"
                                                [ngStyle]="getTooltipTrangleposition(control)"></i>
                                        </div>
                                    </div>
                                    <div [attr.data-role-name]="control.signerName"
                                        [ngStyle]="getShowTagsStyles(control)"
                                        [attr.data-recipientId]="control.RecipientId"
                                        class="signNowTagsControl showhideTags" [attr.data-unique]="control.Id">
                                        {{control.signerName}}
                                    </div>
                                </div>

                                <div *ngIf="control.ControlName === 'DateTimeStamp'" id="{{control.ControlHtmlID}}"
                                    class="signNowControl popUp" [attr.data-page]="control.PageNo"
                                    [attr.data-unique]="control.Id" [attr.data-rq]="control.Required"
                                    [attr.data-ctntype]="control.ControlName" style="display:none">
                                    <div class="signerControl">
                                        <div class="controls dateTimeStamp">
                                            <input value="{{control.ControlValue}}"
                                                [attr.data-contrl-value]="control.ControlValue"
                                                [attr.data-document-content-id]="control.Id"
                                                [ngStyle]="getTextInputCtrlInlineStyle(control)"
                                                id="control_{{control.ControlHtmlID}}" />
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="control.ControlName === 'Hyperlink'" id="{{control.ControlHtmlID}}"
                                    [ngStyle]="getCtrlInlineStyleIfIsReadOnlyNull(control)" class="signNowControl popUp"
                                    [attr.data-page]="control.PageNo" [attr.data-unique]="control.Id"
                                    [attr.data-rq]="control.Required" [attr.data-ctntype]="control.ControlName">
                                    <div class="signerControl">
                                        <div>
                                            <a class="ctnHyperlink" title="{{control.SenderControlValue}}"
                                                href="{{control.SenderControlValue}}"
                                                [ngStyle]="getTextInputCtrlInlineStyle(control)"
                                                target="_blank">{{control.Label}} </a>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="control.ControlName === 'Initials'"
                                    [attr.data-role-name]="control.signerName"
                                    [attr.data-recipientid]="control.RecipientId"
                                    [ngStyle]="getCtrlInlineStyleIfIsReadOnlyNull(control)"
                                    class="signNowControl popUp ctnNotResize resizeCntrl initialControlClass"
                                    [attr.data-page]="control.PageNo" [attr.data-unique]="control.Id"
                                    [attr.data-rq]="control.Required" [attr.data-ctntype]="control.ControlName">
                                    <div class="signerControl" style="Width:100%;height:100%;">
                                        <div [ngClass]="getRequiredControlClass(control,control.SignatureScr)"
                                            class="additionalInfo" style="Width:100%;height:100%;"
                                            (click)="onClickInitials(control.ControlHtmlID);onShowControlTooltip(control, '')">
                                            <input value="{{control.ControlValue}}" title="{{control.CustomToolTip}}"
                                                [attr.data-contrl-value]="control.ControlValue"
                                                [attr.data-document-content-id]="control.Id"
                                                [attr.data-parent-id]="controlDependencyControllingField(control)"
                                                [attr.data-rule-id]="control.ConditionDetails.ControllingConditionID"
                                                [attr.data-specific-text]="control.ConditionDetails.ControllingSupportText"
                                                maxlength="3" type="text"
                                                [ngStyle]="getCtrlLabelStyleIsReadOnlyNull(control)"
                                                tabindex="{{control.TabIndex}}"
                                                placeholder="{{control.LanguageControlName}}"
                                                (keyup.enter)="isControlFilledOrNot($event, control)"
                                                id="control_{{control.ControlHtmlID}}" />
                                        </div>
                                    </div>

                                    <div id="tooltip_{{control.ControlHtmlID}}" class="tooltip noti_tool"
                                        style="display: none;width:90px;padding:0px;">
                                        <div class="right tooltip-width" [ngStyle]="getTooltipAlertposition(control)">
                                            <div class="text-content">
                                                <ul
                                                    style="padding-left: 5px; padding-right: 4px; margin-bottom: 0.56rem;">
                                                    <li>
                                                        <i class="material-icons font-20 cursor-pointer tooltip-close"
                                                            (click)="onCloseTooltip(control.ControlHtmlID)">clear</i>
                                                    </li>
                                                    <li id="note_{{control.ControlHtmlID}}" class="tool-note">
                                                        <!-- <span *ngIf="mobilePrevBtn === 'true'" class="material-icons font-18 firsticon cursor-pointer"
                                                                    (click)="getControlPrevNav('documentControls')">
                                                                    play_circle_outline
                                                                </span>
                                                                <label  class="tooltip-labeltext">Please click to add initial</label>
                                                                <span *ngIf="mobileNextBtn === 'true'" class="material-icons font-18 secondicon cursor-pointer"
                                                                    (click)="getControlNextNav('documentControls')">
                                                                    play_circle_outline
                                                                </span> -->
                                                        <div class="d-flex align-items-center">
                                                            <img *ngIf="mobilePrevBtn === 'true'" class="hw-16"
                                                                src="../../../assets/images/left-arrow.png"
                                                                (click)="getControlPrevNav('documentControls')">

                                                            <label class="tooltip-labeltext mb-0 ">Please
                                                                click to
                                                                add initial</label>
                                                            <img *ngIf="mobileNextBtn === 'true'" class="hw-16"
                                                                src="../../../assets/images/right-arrow.png"
                                                                (click)="getControlNextNav('documentControls')">
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <i class="tooltip-trangle"
                                                [ngStyle]="getTooltipTrangleposition(control)"></i>
                                        </div>
                                    </div>
                                    <div [attr.data-role-name]="control.signerName"
                                        [ngStyle]="getShowTagsStyles(control)"
                                        [attr.data-recipientId]="control.RecipientId"
                                        class="signNowTagsControl showhideTags" [attr.data-unique]="control.Id">
                                        {{control.signerName}}
                                    </div>
                                </div>

                                <div *ngIf="control.ControlName === 'NewInitials'"
                                    [attr.data-role-name]="control.signerName" id="{{control.ControlHtmlID}}"
                                    [attr.data-recipientid]="control.RecipientId"
                                    class="signNowControl popUp openewInitialSignatureModalOnClick newInitialcommonSignatureControl"
                                    [attr.data-page]="control.PageNo" [attr.data-unique]="control.Id"
                                    [attr.data-rq]="control.Required" [attr.data-ctntype]="control.ControlName"
                                    [attr.data-lastupdated]="" [ngStyle]="getCtrlInlineStyleIfIsReadOnlyNull(control)">
                                    <div class="signerControl newInitialsign" style="Width:100%;height:auto !important;"
                                        title="{{control.CustomToolTip}}">
                                        <div [ngClass]="getRequiredControlClass(control,control.SignatureScr)"
                                            class="additionalInfo" style="height:100%;Width:100%;">
                                            <div class="newInitialsignature" style="width:100%;height:100%;"
                                                (click)="hideToolTip(control);onClickInitials(control.ControlHtmlID)">
                                                <img tabindex="{{control.TabIndex}}" [attr.data-sign-text]=""
                                                    [attr.data-role-name]="control.signerName"
                                                    [attr.data-font-color]="control.FontColor"
                                                    [attr.data-contrl-value]="control.ControlValue"
                                                    [attr.data-document-content-id]="control.Id"
                                                    [attr.data-parent-id]="controlDependencyControllingField(control)"
                                                    [attr.data-rule-id]="control.ConditionDetails.ControllingConditionID"
                                                    [attr.data-specific-text]="control.ConditionDetails.ControllingSupportText"
                                                    style="height:100%;width:100%;"
                                                    (keyup.enter)="isControlFilledOrNot($event, control)"
                                                    id="control_{{control.ControlHtmlID}}" />
                                            </div>
                                        </div>
                                    </div>

                                    <div id="tooltip_{{control.ControlHtmlID}}" class="tooltip noti_tool"
                                        style="display: none;width:90px;padding:0px;">
                                        <div class="right tooltip-width" [ngStyle]="getTooltipAlertposition(control)">
                                            <div class="text-content">
                                                <ul
                                                    style="padding-left: 5px; padding-right: 4px; margin-bottom: 0.56rem;">
                                                    <li>
                                                        <i class="material-icons font-20 cursor-pointer tooltip-close"
                                                            (click)="onCloseTooltip(control.ControlHtmlID)">clear</i>
                                                    </li>
                                                    <li id="note_{{control.ControlHtmlID}}" class="tool-note">
                                                        <!-- <span *ngIf="mobilePrevBtn === 'true'" class="material-icons font-18 firsticon cursor-pointer"
                                                                    (click)="getControlPrevNav('documentControls')">
                                                                    play_circle_outline
                                                                </span>
                                                                 <label  class="tooltip-labeltext">Please click to add initial</label>
                                                                <span *ngIf="mobileNextBtn === 'true'" class="material-icons font-18 secondicon cursor-pointer"
                                                                    (click)="getControlNextNav('documentControls')">
                                                                    play_circle_outline
                                                                </span> -->
                                                        <div class="d-flex align-items-center">
                                                            <img *ngIf="mobilePrevBtn === 'true'" class="hw-16"
                                                                src="../../../assets/images/left-arrow.png"
                                                                (click)="getControlPrevNav('documentControls')">

                                                            <label class="tooltip-labeltext mb-0 ">Please
                                                                click to
                                                                add initial</label>
                                                            <img *ngIf="mobileNextBtn === 'true'" class="hw-16"
                                                                src="../../../assets/images/right-arrow.png"
                                                                (click)="getControlNextNav('documentControls')">
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <i class="tooltip-trangle"
                                                [ngStyle]="getTooltipTrangleposition(control)"></i>
                                        </div>
                                    </div>
                                    <div [attr.data-role-name]="control.signerName"
                                        [ngStyle]="getShowTagsStyles(control)"
                                        [attr.data-recipientId]="control.RecipientId"
                                        class="signNowTagsControl showhideTags" [attr.data-unique]="control.Id">
                                        {{control.signerName}}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>

<!-------------------------------------------------------- Mobile footer Menu list------------------------------------------------------------------ -->
<div class="d-sm-block d-md-block d-lg-block d-xl-none mobile-menu">
    <div class="px-2 d-flex align-items-center justify-content-center mobile-footer" *ngIf="webOrMobile === 'mobile'">

        <app-pagination-customization [documentData]="documentData"></app-pagination-customization>

    </div>
</div>
<div *ngIf="showLoader" class="loader">
</div>

<!-- mobile Menu List -->
<div class="offcanvas offcanvas-bottom" id="demo">
    <div class="offcanvas-header d-flex align-items-center justify-content-between">
        <h6 class="other-heading">Other Actions</h6>
        <button id="closeCanvasMenu" type="button" class="btn-close float-end p-3" data-bs-dismiss="offcanvas"></button>
    </div>
    <div class="offcanvas-body pt-2">
        <section>
            <aside class="sidebar mobile-h">
                <nav class="sidebar-nav">
                    <ul *ngIf="webOrMobile === 'mobile'">
                        <!-- <li>
                            <div class=" dropdown">
                                <a class=" dropdown-toggle d-flex align-items-center justify-content-between"
                                    role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                    <label >
                                        <i class="fa fa-search-plus iconfont-18"></i>
                                        <span class="">Zoom</span>
                                    </label>
                                    <span class="material-icons">
                                        arrow_drop_down
                                    </span>
                                </a>

                                <ul class="dropdown-menu w-100 px-3" aria-labelledby="dropdownMenuLink">
                                    <li (ontouchstart)="onPercentageChange(1)" (click)="onPercentageChange(1)">
                                        <a class="dropdown-item">Actual Size</a>
                                    </li>
                                    <li (ontouchstart)="onPercentageChange(1)" (click)="onPercentageChange(1)">
                                        <a class="dropdown-item">100%</a>
                                    </li>
                                    <li (ontouchstart)="onPercentageChange(1.25)" (click)="onPercentageChange(1.25)">
                                        <a class="dropdown-item">125%</a>
                                    </li>
                                    <li (ontouchstart)="onPercentageChange(1.5)" (click)="onPercentageChange(1.5)">
                                        <a class="dropdown-item">150%</a>
                                    </li>
                                </ul>
                            </div>
                        </li> -->
                        <li *ngIf="ShowFilesReviewCountDiv">
                            <a class="d-flex align-items-center">
                                <i class="material-icons review" aria-hidden="true" style="top:3px;"
                                    title="Click here to download files to review" (click)="onFileReviewDownload()">
                                    download</i>
                                <span class=""> Files for Review</span>
                                <span class="wh-25 badge"> {{this.documentControlsResponse.FileReviewCount}} </span>
                            </a>
                        </li>
                        <li *ngIf="IsEnableClickToSign">
                            <a (click)="openModal('Add Signature')"><i class="fa fa-pencil-square iconfont-18"></i>
                                <span class="">Add/Edit Signature</span></a>
                        </li>
                        <li *ngIf="ShowAttachmentNavMenu">
                            <a class="d-flex align-items-center" (click)="openModal('Attachments')">
                                <i class="fa fa-paperclip iconfont-18"></i>
                                <span class="">Add Attachments</span>
                                <span class="wh-25 badge"> {{attachmentListCount}} </span>
                            </a>
                        </li>
                        <li style="display:none" id="liShowTags">
                            <a class="d-flex align-items-center" id="btnShowTags" (click)="showHideTagsControls()">
                                <i class="fa fa-eye iconfont-18"></i>
                                <span id="spnShowTagsText"
                                    class="">{{getLanguageTranslationValue(resourceKeys.ShowTags)}}</span>
                                <input type="hidden" id="hdnShowTagsText" value="" />
                            </a>
                        </li>
                        <li *ngIf="ShowInviteSigners">
                            <a class="d-flex align-items-center" id="btnInviteSigners"
                                (click)="openModal('Invite Signers')">
                                <i class="fa fa-user-plus iconfont-18"></i>
                                <span
                                    style="margin-left: 6px;">{{getLanguageTranslationValue(resourceKeys.InviteSigners)}}</span></a>
                        </li>
                        <!-- <li>
                            <a (click)="onDownload()"><i class="fa fa-download iconfont-18"></i>
                                <span class="">Download</span></a>
                        </li> -->
                        <!-- <li *ngIf="ShowFinishLaterNavMenu">
                            <a (click)="finishLater()"><i class="fa fa-clock-o iconfont-18"></i>
                                <span class="">Finish Later</span></a>
                        </li> -->
                        <li *ngIf="showAndHideDecline !== true">
                            <div *ngIf="ShowDeclineNavMenu">
                                <a (click)="openModal('Static Decline')"><i class="fa fa-ban iconfont-18"></i>
                                    <span class="">{{getLanguageTranslationValue(resourceKeys.Decline)}}</span></a>
                            </div>
                        </li>
                        <!-- <li>
                            <a (click)="openModal('Change Signer')"><i class="fa fa-retweet iconfont-18"></i>
                                <span class="">Change Signer </span></a>
                        </li> -->
                        <!-- <li>
                            <a (click)="openModal('Invite Signers')"><i class="fa fa-user-plus iconfont-18"></i>
                                <span class="" style="position: relative;
                                top: 2px;">Invite Signer </span></a>
                        </li> -->
                        <li>
                            <a (click)="onHelp()"><i class="material-icons iconfont-18">help_outline</i>
                                <span class="">Help & Support</span></a>
                        </li>
                        <li class="border-bootom-0">
                            <a (click)="onSignupROneRedirection()"><i class="fa fa-yelp iconfont-18"></i>
                                <span class="">Signup-ROne</span></a>
                        </li>
                    </ul>
                </nav>
            </aside>
        </section>
    </div>
</div>

<!-- Filter Section -->
<div class="offcanvas offcanvas-bottom" id="filter">
    <div class="offcanvas-body pt-2 border-boxsize">
        <section>
            <aside class="sidebar ml-n8">
                <div class="card h-100 shadow tabcard">
                    <div class="offcanvas-header d-flex align-items-center justify-content-between mb-2">
                        <h6 class="other-heading"> More Options</h6>
                        <button type="button" id="closeMoreActionsMenu" class="btn-close float-end p-3"
                            data-bs-dismiss="offcanvas" (click)="closeMoreActionsMenu()"></button>
                    </div>
                    <nav>
                        <div class="nav nav-tabs mb-3" id="nav-tab" role="tablist">

                            <button class="nav-link active d-flex align-items-center" id="nav-profile-tab1"
                                data-bs-toggle="tab" data-bs-target="#nav-profile1" type="button" role="tab"
                                aria-controls="nav-profile1" aria-selected="false" style="font-size: 14px;">
                                <i class="material-icons" style="font-size: 18px;padding-right: 2px;">filter_list </i>
                                Filter
                            </button>

                            <button class="nav-link  d-flex align-items-center" id="nav-home-tab1" data-bs-toggle="tab"
                                data-bs-target="#nav-home1" type="button" role="tab" aria-controls="nav-home1"
                                aria-selected="true" style="font-size: 14px;z-index:9999;"
                                (click)="onMobilePreviewPagesView()">
                                <i class="fa fa-files-o me-1"></i> {{getLanguageTranslationValue(resourceKeys.Preview)}}
                            </button>

                        </div>
                    </nav>
                    <div class="tab-content border border-top-0 bg-white w-100 h-100 tab-scroll style-scrollbar"
                        id="nav-tabContent">
                        <div class="tab-pane fade active show" id="nav-profile1" role="tabpanel"
                            aria-labelledby="nav-profile-tab1">
                            <div class="leftsidebar mobileleftbar">
                                <div class="accordion" id="accordionPanelsStayOpenExample">
                                    <div class="accordion-item" style="border: 0px solid #ffffff;">
                                      <h2 class="accordion-header border-boxsize" id="panelsStayOpen-headingOne">
                                        <button style="height: 32px;border: 0px solid #ffffff;" class="filteraccordain accordion1 " type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                            <i class="material-icons" style="font-size: 20px;">filter_list </i>
                                        </button>
                                      </h2>
                                      <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                                        <div class="accordion-body">

                                            <div class="checkbox">
                                                <label for="fieldsRemainingFlagWeb" class="ps-0">
                                                    <input id="fieldsRemainingFlagWeb" type="checkbox" value=""
                                                        [(ngModel)]="fieldsRemainingFlag">
                                                    <span class="cr">
                                                        <i class="cr-icon material-icons">check</i></span>
                                                    Fields Remaining
                                                </label>
                                            </div>

                                            <div class="checkbox mt-2">
                                                <label for="requiredFlagWeb" class="ps-0">
                                                    <input id="requiredFlagWeb" type="checkbox" value=""
                                                        [(ngModel)]="requiredFlag">
                                                    <span class="cr">
                                                        <i class="cr-icon material-icons">check</i></span>
                                                    {{getLanguageTranslationValue(resourceKeys.Required)}}
                                                </label>
                                            </div>
                                            <div class="mt-3" style="padding-right: 2px;">
                                                <select *ngIf="documentControlsResponse && documentControlsResponse.EnvelopeInfo" id="pageSelectDropdownWeb"
                                                    class="classic form-control pageSelectDropdown"
                                                    #selectedFilterVal [(ngModel)]="searchTermDisplay"
                                                    (ontouchstart)="onChangeDropdownSelection($event, selectedFilterVal.value)"
                                                    (change)="onChangeDropdownSelection($event,selectedFilterVal.value)">

                                                    <option value="" selected>Select All</option>
                                                    <option
                                                        *ngFor="let opt of documentControlsResponse.EnvelopeInfo.Controls | keyvalue"
                                                        value="{{opt.key}}"> {{opt.value}}
                                                    </option>
                                                </select>
                                            </div>

                                            <div class="my-3 d-flex justify-content-between align-items-center">
                                                <button type="button" class="btn btncancel px-4 py-1"
                                                    (click)="onClearAll()" (ontouchstart)="onClearAll()"
                                                    id="modal-btn-no">Clear</button>

                                                <button type="button" class="btn btnapply px-4"
                                                    (click)="onFilterApply()" (ontouchstart)="onFilterApply()"
                                                    id="btnCheckListFilterApply">Apply</button>
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                    </div>

                                <div class="w-100">
                                    <div class="accordion mb-2" id="accordionExample"
                                        style="position: sticky; z-index: 111;">
                                        <div class="accordion-item">

                                            <div *ngIf="webOrMobile === 'mobile'" class="documentControlIndicator"
                                                [style.display]="showDefaultSignatureContol === 'block' ? 'block': 'none'">
                                                <nav class="defaultSignNav">
                                                    <div id="showDefaultSignatureContol">
                                                        <div class="heading">
                                                            <span
                                                                class="pageNumber pb-1">{{getLanguageTranslationValue(resourceKeys.DefaultSignature)}}</span>
                                                        </div>
                                                        <ul style="padding:0px;">
                                                            <li id="FooterSignNav" style="list-style: none;"
                                                                (click)="onClickSignature('FooterSignNav')">
                                                                <span class="checkbox" title="Default Signature"
                                                                    style="font-weight: bold;">
                                                                    <div class="d-flex">
                                                                        <span
                                                                            class="defaultSignNavControlIndicator controlIndicator Signature required requried-color"
                                                                            data-for="defaultSignature">
                                                                            <i
                                                                                class="material-icons defaultSignNavControlIndicatorIcon requried-icon">star</i>
                                                                        </span>
                                                                        <img class="footerSignature"
                                                                            style="width:120px;" id="footerSigSrc"
                                                                            src="../../../assets/images/signature-en-us.png">
                                                                    </div>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </nav>
                                            </div>

                                        </div>
                                    </div>
                                    <div *ngIf="webOrMobile === 'mobile'">
                                        <div
                                            *ngIf="documentControlsResponse && documentControlsResponse.PageCount !== 0 && showNoFieldsRemainingMsg === false">
                                            <div *ngFor="let fListCtrl of documentCheckListsResponse; let i = index;"
                                                class="w-100 accordain-overflow style-scrollbar chekListAccordainClass"
                                                id="chekListAccordain_{{i}}">
                                                <div *ngIf="documentControlsResponse.DocumentNameList[i]?.DocumentName">
                                                    <span class="material-icons me-1 font-18"
                                                        style="color: #5a6271;position: relative;top: -3px;">
                                                        insert_drive_file</span>
                                                    <span style="width: 250px;padding-left:0px;"
                                                        class="document-file-name-style"
                                                        title="{{documentControlsResponse.DocumentNameList[i]?.DocumentName}}">
                                                        {{documentControlsResponse.DocumentNameList[i]?.DocumentName}}</span>
                                                </div>
                                                <button [ngClass]="{ 'active1' : currentIndex === i }"
                                                    class="accordion1 mb-2" style="width: 100%;height: 36px;"
                                                    (click)="expand(i)">{{getLanguageTranslationValue(resourceKeys.Page)}}
                                                    {{fListCtrl.PageNumber}}</button>
                                                <div class="divChekListAccordain_{{i}}" [hidden]="currentIndex != i">
                                                    <div *ngFor="let fList of fListCtrl.ControlsData; let j = index;"
                                                        class="panel ps-2" id="panel_{{fList.ControlHtmlID}}"
                                                        [ngClass]="{ 'visible' : currentIndex === i }">
                                                        <div class="controllist form mt-1">
                                                            <div *ngIf="fList.ControlName.toLowerCase() === 'radio' "
                                                                id="anchor_{{fList.ControlHtmlID}}"
                                                                [attr.data-role-name]="fList.signerName"
                                                                [attr.data-recp]="fList.RecipientId"
                                                                class="checkbox checkspace promoted-checkbox checkListDataItem mb-2 {{fList.ControlName.toLowerCase()}} {{fList.ControlHtmlID}}"
                                                                [ngStyle]="getCtrlDisplayStyle(fList.ConditionDetails.ControllingFieldID, fList)"
                                                                (click)="onFilterControlClick(fList, (i+1))"
                                                                class="checkbox checkspace signNowControl"
                                                                [title]="getLanguageControlName(fList)">
                                                                <label class="ps-0 requried-label font-14">
                                                                    <input id="tmp{{i+1}}_{{j+1}}" disabled="true"
                                                                        [(ngModel)]="fList.isControlFiled"
                                                                        [attr.data-exists]="fList.isControlFiled"
                                                                        [attr.data-for]="'control_'+ fList.ControlHtmlID"
                                                                        [attr.data-group-name]="fList.GroupName"
                                                                        type="checkbox"
                                                                        class="promoted-input-checkbox {{fList.ClassAttribute}}" />
                                                                    <label
                                                                        class="lblControlField requried-color requried-color1"
                                                                        for="tmp{{i+1}}_{{j+1}}"
                                                                        id="label_{{fList.ControlHtmlID}}"
                                                                        [attr.data-recp]="fList.RecipientId"
                                                                        [attr.data-child]="fList.ConditionDetails.ControllingFieldID"
                                                                        [attr.data-rq]="fList.Required"
                                                                        [attr.data-controlname]="fList.ControlName"
                                                                        [attr.data-for]="'control_'+ fList.ControlHtmlID"
                                                                        [title]="getLanguageControlName(fList)">
                                                                        <i *ngIf="fList.Required"
                                                                            id="iicon_{{fList.ControlHtmlID}}"
                                                                            class="material-icons requried-icon">star</i>
                                                                        <i *ngIf="!fList.Required"
                                                                            id="iicon_{{fList.ControlHtmlID}}"
                                                                            class="d-none material-icons requried-icon">star</i>
                                                                    </label>
                                                                    <span
                                                                        class="cursor-pointer font-14">{{fList.GroupName}}-{{fList.Label}}-P{{fList.PageNo}}</span>
                                                                </label>
                                                            </div>
                                                            <div *ngIf="fList.ControlName.toLowerCase() === 'checkbox'"
                                                                id="anchor_{{fList.ControlHtmlID}}"
                                                                [attr.data-role-name]="fList.signerName"
                                                                [attr.data-recp]="fList.RecipientId"
                                                                class="promoted-checkbox checkListDataItem mb-2 {{fList.ControlName.toLowerCase()}} {{fList.ControlHtmlID}}"
                                                                [ngStyle]="getCtrlDisplayStyle(fList.ConditionDetails.ControllingFieldID, fList)"
                                                                (click)="onFilterControlClick(fList, (i+1))"
                                                                class="checkbox checkspace signNowControl"
                                                                [title]="getLanguageControlName(fList)">
                                                                <label>
                                                                    <input id="tmp{{i+1}}_{{j+1}}" disabled="true"
                                                                        [(ngModel)]="fList.isControlFiled"
                                                                        [attr.data-exists]="fList.isControlFiled"
                                                                        [attr.data-for]="'control_'+ fList.ControlHtmlID"
                                                                        [attr.data-group-name]="fList.GroupName"
                                                                        type="checkbox"
                                                                        class="promoted-input-checkbox {{fList.ClassAttribute}}" />
                                                                    <label
                                                                        class="lblControlField requried-color requried-color1"
                                                                        for="tmp{{i+1}}_{{j+1}}"
                                                                        id="label_{{fList.ControlHtmlID}}"
                                                                        [attr.data-recp]="fList.RecipientId"
                                                                        [attr.data-child]="fList.ConditionDetails.ControllingFieldID"
                                                                        [attr.data-rq]="fList.Required"
                                                                        [attr.data-controlname]="fList.ControlName"
                                                                        [attr.data-for]="'control_'+ fList.ControlHtmlID"
                                                                        [title]="getLanguageControlName(fList)">
                                                                        <i *ngIf="fList.Required"
                                                                            id="iicon_{{fList.ControlHtmlID}}"
                                                                            class="material-icons requried-icon">star</i>
                                                                        <i *ngIf="!fList.Required"
                                                                            id="iicon_{{fList.ControlHtmlID}}"
                                                                            class="d-none material-icons requried-icon">star</i>
                                                                    </label>
                                                                    <span
                                                                        *ngIf="fList.GroupName === null || fList.GroupName === ''"
                                                                        class="cursor-pointer font-14">{{fList.LanguageControlName
                                                                        }}</span>
                                                                    <span
                                                                        *ngIf="fList.GroupName !== null && fList.GroupName !== ''"
                                                                        class="cursor-pointer font-14">{{fList.GroupName}}-{{fList.Label}}-P{{fList.PageNo}}</span>
                                                                </label>
                                                            </div>
                                                            <div *ngIf="fList.ControlName.toLowerCase() === 'dropdown'"
                                                                class="promoted-checkbox checkListDataItem mb-2 {{fList.ControlName.toLowerCase()}} {{fList.ControlHtmlID}}"
                                                                id="anchor_{{fList.ControlHtmlID}}"
                                                                [attr.data-role-name]="fList.signerName"
                                                                [attr.data-recp]="fList.RecipientId"
                                                                [ngStyle]="getCtrlDisplayStyle(fList.ConditionDetails.ControllingFieldID, fList)"
                                                                (click)="onFilterControlClick(fList, (i+1))"
                                                                class="checkbox checkspace signNowControl"
                                                                [title]="getLanguageControlName(fList)">
                                                                <label>
                                                                    <input id="tmp{{i+1}}_{{j+1}}" disabled="true"
                                                                        [(ngModel)]="fList.isControlFiled"
                                                                        [attr.data-exists]="fList.isControlFiled"
                                                                        [attr.data-for]="'control_'+ fList.ControlHtmlID"
                                                                        type="checkbox"
                                                                        class="promoted-input-checkbox {{fList.ClassAttribute}}" />
                                                                    <label
                                                                        class="lblControlField requried-color requried-color1"
                                                                        for="tmp{{i+1}}_{{j+1}}"
                                                                        id="label_{{fList.ControlHtmlID}}"
                                                                        [attr.data-recp]="fList.RecipientId"
                                                                        [attr.data-child]="fList.ConditionDetails.ControllingFieldID"
                                                                        [attr.data-rq]="fList.Required"
                                                                        [attr.data-controlname]="fList.ControlName"
                                                                        [attr.data-for]="'control_'+ fList.ControlHtmlID"
                                                                        [title]="getLanguageControlName(fList)">
                                                                        <i *ngIf="fList.Required === true"
                                                                            id="iicon_{{fList.ControlHtmlID}}"
                                                                            class="material-icons requried-icon">star</i>
                                                                        <i *ngIf="!fList.Required"
                                                                            id="iicon_{{fList.ControlHtmlID}}"
                                                                            class="d-none material-icons requried-icon">star</i>
                                                                    </label>
                                                                    <span
                                                                        class="cursor-pointer font-14">{{fList.LanguageControlName
                                                                        }}</span>
                                                                </label>
                                                            </div>
                                                            <div *ngIf="fList.ControlName.toLowerCase() !== 'label' && fList.ControlName.toLowerCase() !== 'checkbox' &&
                                                    fList.ControlName.toLowerCase() !== 'radio' && fList.ControlName.toLowerCase() !== 'datetimestamp' &&
                                                    fList.ControlName.toLowerCase() !== 'dropdown' && fList.ControlName.toLowerCase() !== 'hyperlink'"
                                                                class="promoted-checkbox checkListDataItem mb-2 {{fList.ControlName.toLowerCase()}} {{fList.ControlHtmlID}}"
                                                                id="anchor_{{fList.ControlHtmlID}}"
                                                                [attr.data-role-name]="fList.signerName"
                                                                [attr.data-recp]="fList.RecipientId"
                                                                [ngStyle]="getCtrlDisplayStyle(fList.ConditionDetails.ControllingFieldID, fList)"
                                                                (click)="onFilterControlClick(fList, (i+1))"
                                                                class="checkbox checkspace signNowControl"
                                                                [ngClass]="checkSignAndInitialsDisabled()"
                                                                [title]="getLanguageControlName(fList)">
                                                                <label>
                                                                    <input id="tmp{{i+1}}_{{j+1}}" disabled="true"
                                                                        [(ngModel)]="fList.isControlFiled"
                                                                        [attr.data-exists]="fList.isControlFiled"
                                                                        [attr.data-for]="'control_'+ fList.ControlHtmlID"
                                                                        type="checkbox"
                                                                        class="promoted-input-checkbox {{fList.ClassAttribute}}" />
                                                                    <label
                                                                        class="lblControlField requried-color requried-color1"
                                                                        for="tmp{{i+1}}_{{j+1}}"
                                                                        id="label_{{fList.ControlHtmlID}}"
                                                                        [attr.data-recp]="fList.RecipientId"
                                                                        [attr.data-child]="fList.ConditionDetails.ControllingFieldID"
                                                                        [attr.data-rq]="fList.Required"
                                                                        [attr.data-controlname]="fList.ControlName"
                                                                        [attr.data-for]="'control_'+ fList.ControlHtmlID"
                                                                        [title]="getLanguageControlName(fList)">
                                                                        <i *ngIf="fList.Required === true"
                                                                            id="iicon_{{fList.ControlHtmlID}}"
                                                                            class="material-icons requried-icon">star</i>
                                                                        <i *ngIf="!fList.Required"
                                                                            id="iicon_{{fList.ControlHtmlID}}"
                                                                            class="d-none material-icons requried-icon">star</i>
                                                                    </label>
                                                                </label>
                                                                <span
                                                                    *ngIf="fList.ControlName.toLowerCase() === 'text' && fList.Label !== '' && fList.Label !== null"
                                                                    class="cursor-pointer font-14">{{fList.Label
                                                                    }}</span>
                                                                <span
                                                                    *ngIf="fList.ControlName.toLowerCase() === 'text' && (fList.Label === '' || fList.Label === null)"
                                                                    class="cursor-pointer font-14">{{fList.LanguageControlName
                                                                    }}</span>
                                                                <span *ngIf="fList.ControlName.toLowerCase() !== 'text'"
                                                                    class="cursor-pointer font-14">{{fList.LanguageControlName
                                                                    }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            *ngIf="documentControlsResponse && documentControlsResponse.PageCount === 0">
                                            <div *ngFor="let docData of documentCheckListsResponse">
                                                <div class="heading sideMenuDocName"
                                                    [attr.Control_DocId]="docData.DocumentId">
                                                    <span class="material-icons me-1 font-18"
                                                        style="color: #5a6271;position: relative;top: -3px;">
                                                        insert_drive_file</span>
                                                    <span id="sideMenuDocName_{{docData.DocumentId}}"
                                                        class="document-file-name-style"
                                                        style="width: 250px;padding-left:0px;"
                                                        title="{{docData.DocumentName}}">{{docData.DocumentName}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="showNoFieldsRemainingMsg === true">
                                            <div class="no-fields">
                                                <div class="d-flex justify-content-center">
                                                    <img class=" mb-3 h-64" src="../../../assets/images/no-results.png">
                                                </div>
                                                <p class="text-center font-w500"> No fields remaining</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="webOrMobile === 'mobile'" class="tab-pane fade" id="nav-home1" role="tabpanel"
                            aria-labelledby="nav-home-tab1">
                            <!-- Right vside menu [hidden]="hidePagesViewSection"-->

                            <div class="previewpage previewbg">
                                <div class="pagepreview-fixed">
                                    <div class="d-flex align-items-center justify-content-between"
                                        style="border-bottom: 2px solid #aca2a352;">
                                        <h5
                                            class="my-1 px-2 align-items-start pageheading style-scrollbar document-list-style">
                                            <div *ngFor="let dList of documentControlsResponse.DocumentNameList ; let i=index;"
                                                style="padding: 2px 0;">
                                                <span class="material-icons me-1 font-18" style="color: #5a6271;">
                                                    insert_drive_file</span>
                                                <span title="{{dList.DocumentName}}"
                                                    style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;width: 250px;display: inline-block;">
                                                    {{dList.DocumentName}}
                                                </span>
                                            </div>
                                        </h5>
                                    </div>
                                    <div class=" my-2 px-3 d-flex align-items-center justify-content-between">
                                        <span class="" style="font-size: 14px;">Pages:
                                            {{pagePreviewTotalPages}}</span>
                                    </div>
                                </div>
                                <div class="mt-2 d-flex flex-column align-items-center page-scrollbar style-scrollbar">
                                    <div class="thumbnail" *ngFor="let page of pageViewArr ; let i=index;">
                                        <!-- <img src="../../../assets/images/shield.png" alt="" class="badgeicon"
                                            height="40px" *ngIf="i!=1">
                                        <img src="../../../assets/images/shield2.png" alt="" class="badgeicon"
                                            height="40px" *ngIf="i==1"> -->
                                        <!-- [ngClass]="getPreviewSelected(i+1)" -->
                                        <div class="thumbnails-page-btn" id="pageCountColor{{i+1}}"
                                            style="position: relative;top: 174px;left: 2px;">
                                            <span class="pagenum">{{i+1}}</span>
                                        </div>

                                        <div class="d-flex justify-content-center" style="margin-top: -5px;">
                                            <!-- [ngClass]="getPreviewSelectedColor(i+1)"  -->
                                            <img class="prevImg" id="pagePreview{{i+1}}" [attr.page-no]="page.dataPage"
                                                width="140px" height="180px" [src]="getImagePath(page)" alt="Image"
                                                (click)="onPreviewImageSelection($event, i+1)">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </aside>
        </section>
    </div>
</div>

<div class="ipadnone" [ngClass]="getFooterAlignment()">
    <app-footer *ngIf="webOrMobile === 'web' && isShowFooter === 'true'" class="d-sm-none d-lg-block"></app-footer>
</div>


<div *ngIf="showLoader" class="loader">
</div>