import { Component, EventEmitter, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CommonConstants, EmailAddressFormat, ResourceKeys } from 'src/app/constants/common-constants';
import { CommonService } from 'src/app/services/common.service';
import { SignerLandingService } from 'src/app/services/signer-landing.service';
import { TranslationsService } from 'src/app/services/translations.service';
import * as _ from 'underscore';

@Component({
    selector: 'app-invite-signers',
    templateUrl: './invite-signers.component.html',
    styleUrls: ['./invite-signers.component.scss']
})
export class InviteSignersComponent implements OnInit {
    myDetailsToggleFlag: boolean = false;
    InviteSignerDataList: any[] = [];
    storageSignerList: any[] = [];
    showAssSigner: boolean = false;
    signerId: any;
    signerAs: any;
    signerName: any;
    signerEmail: any;
    showUpdateSigner: boolean = false;
    radioButtonSelection: string = '';
    dropdownSelection: any = '0';
    documentControlsResponse: any;
    DialCodeDropdownList: any;
    currentRoleID: any;
    currentEmail: any
    FirsrtRecipientId: any;
    deletedOptionalrowscount: any;
    StaticTemplateRoles: any;
    dvInviteAddNewStyle: string = "none";
    showLoader: boolean = false;
    public event: EventEmitter<any> = new EventEmitter();
    resourceKeys = ResourceKeys;
    currentRecipientMobileNumber: any;
    currentRecipientDialCode: any;
    currentRecipientDeliveryMode: any;
    currentRecipientCountryCode: any;
    webOrMobile: any = 'web';
    EnableMessageToMobile: boolean = false;
    IsSendMessageCodetoAvailableEmailorMobile: boolean = true;

    constructor(
        public activeModal: NgbActiveModal, private commonService: CommonService,
        private toastr: ToastrService, private translationsService: TranslationsService,
        private signerLandingService: SignerLandingService) { }

    ngOnInit(): void {
        // this.defaultValues();
        // console.log('111111');
        if ((/android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
            this.webOrMobile = 'mobile';
        }
        let documentControlsResp: any = localStorage.getItem("documentControlsResponse");
        this.documentControlsResponse = JSON.parse(documentControlsResp);
        this.DialCodeDropdownList = this.documentControlsResponse.DialCodeDropdownList;
        this.EnableMessageToMobile = (this.documentControlsResponse.EnableMessageToMobile == null || this.documentControlsResponse.EnableMessageToMobile == false) ? false : true;
        this.IsSendMessageCodetoAvailableEmailorMobile = (this.documentControlsResponse.SendMessageCodetoAvailableEmailorMobile == false) ? false : true;

        this.currentRoleID = this.documentControlsResponse.CurrentRoleID;
        this.currentEmail = this.documentControlsResponse.CurrentEmail;
        this.StaticTemplateRoles = this.documentControlsResponse.TemplateRolesInfo;

        let InviteSignerModalData: any = localStorage.getItem("InviteSignerModalData");
        let MyDetailsVal: any = localStorage.getItem("MyDetailsVal");
        if (MyDetailsVal != "" && MyDetailsVal != null && MyDetailsVal != undefined) {
            MyDetailsVal = MyDetailsVal = JSON.parse(MyDetailsVal);
        }

        let CurrentRecitDeliverDetails: any = localStorage.getItem("CurrentRecitDeliverDetails");
        let recDeliverDetails: any = JSON.parse(CurrentRecitDeliverDetails);
        this.currentRecipientDeliveryMode = recDeliverDetails.DeliveryMode;

        setTimeout(() => {
            if (this.EnableMessageToMobile && this.commonService.returnShowMobileDeliveryModeVal(this.currentRecipientDeliveryMode)) {
                this.currentRecipientMobileNumber = recDeliverDetails.MobileNumber;
                this.currentRecipientDialCode = recDeliverDetails.DialCode;
                let signerCode = "bg-" + recDeliverDetails.SignerCode;
                this.currentRecipientCountryCode = recDeliverDetails.SignerCode;
                let spnMyTitle: any = document.getElementById("spnMyTitle");
                if (spnMyTitle != null) {
                    spnMyTitle.innerHTML = '';
                    spnMyTitle.innerHTML = '<a style="text-decoration-line: none; color: #000;"><span id="spnMyCountryFlag" style="top: 4px;display: inline-block;width: 25px;position: relative;" class="' + signerCode + '"></span>' + this.currentRecipientDialCode + '</a>';
                }

                if (this.currentRecipientMobileNumber != null && this.currentRecipientMobileNumber != "") {
                    let txtMyPhone: any = <HTMLSelectElement>document.getElementById("txtMyPhone");
                    if ((txtMyPhone && txtMyPhone != null && txtMyPhone != undefined) && (MyDetailsVal != "" && MyDetailsVal != null && MyDetailsVal != undefined) && this.commonService.returnMobileDeliveryModeVal(this.currentRecipientDeliveryMode)) {
                        this.currentRecipientMobileNumber = MyDetailsVal.mobilenumber;
                        txtMyPhone.setAttribute('maxlength', this.currentRecipientMobileNumber.length);
                    }
                    else if (txtMyPhone && txtMyPhone != null && txtMyPhone != undefined) {
                        txtMyPhone.setAttribute('maxlength', this.currentRecipientMobileNumber.length);
                    }
                }
                else {
                    let txtMyPhone: any = <HTMLSelectElement>document.getElementById("txtMyPhone");
                    if (txtMyPhone && txtMyPhone != null && txtMyPhone != undefined) {
                        for (let index = 0; index < this.DialCodeDropdownList.length; index++) {
                            const element = this.DialCodeDropdownList[index];
                            if (element.CountryCode.toLowerCase() == recDeliverDetails.SignerCode) {
                                txtMyPhone.setAttribute('maxlength', element.MobileMaxLength);
                                break;
                            }
                        }
                    }
                }
            }
            if (InviteSignerModalData != null && MyDetailsVal != null && InviteSignerModalData != "" && MyDetailsVal != "" && MyDetailsVal != undefined) {
                this.UpdateInviteSignerPopupData(InviteSignerModalData, MyDetailsVal);
            }
            else {
                this.BindInviteSignerPopupData();
            }
        }, 100);
    }

    BindInviteSignerPopupData() {
        if (this.StaticTemplateRoles && this.StaticTemplateRoles != null && this.StaticTemplateRoles.length > 0) {
            Array.from(this.StaticTemplateRoles).forEach((element: any, index: any) => {
                if (this.currentRoleID == element.RecipientId) {
                    let txtMyDetailsSignAs: any = document.getElementById("txtMyDetailsSignAs");
                    txtMyDetailsSignAs.value = element.RoleName;
                    this.FirsrtRecipientId = element.RecipientId;
                    if (this.commonService.returnShowEmailBasedOnDeliveryModeVal(this.currentRecipientDeliveryMode)) {
                        let txtMyDetailsEmail: any = document.getElementById("txtMyDetailsEmail");
                        txtMyDetailsEmail.value = this.currentEmail;
                    }
                }
                else {
                    this.InviteSignerDataList.push({
                        roleSeq: index, name: '', email: '', signnow: true, inviteBy: false, selectedRole: '', cultureInfo: element.CultureInfo,
                        deliveryMode: element.DeliveryMode, dialCode: element.DialCode, countryCode: element.CountryCode, recipientId: element.RecipientId
                    });
                }
            });

            setTimeout(() => {
                this.BindInviteRoles(null);
                this.BindInviteRolesdata();
                this.SetDeliveryMode(null, null);
            }, 500);
        }
    }

    UpdateInviteSignerPopupData(InviteSignerModalData: any, MyDetailsVal: any) {
        // MyDetailsVal = JSON.parse(MyDetailsVal);
        InviteSignerModalData = JSON.parse(InviteSignerModalData);
        let txtMyDetailsSignAs: any = document.getElementById("txtMyDetailsSignAs");
        txtMyDetailsSignAs.value = MyDetailsVal.signAs;
        if (this.commonService.returnShowEmailBasedOnDeliveryModeVal(this.currentRecipientDeliveryMode)) {
            let txtMyDetailsEmail: any = document.getElementById("txtMyDetailsEmail");
            txtMyDetailsEmail.value = MyDetailsVal.myEmail;
        }
        if (this.commonService.returnShowMobileDeliveryModeVal(this.currentRecipientDeliveryMode)) {
            this.currentRecipientMobileNumber = MyDetailsVal.mobilenumber;
            let txtMyPhone: any = <HTMLSelectElement>document.getElementById("txtMyPhone");
            txtMyPhone.setAttribute('maxlength', this.currentRecipientMobileNumber.length);
        }
        let txtMyDetailsName: any = document.getElementById("txtMyDetailsName");
        txtMyDetailsName.value = MyDetailsVal.myName;
        let txtComment: any = document.getElementById("txtComment");
        txtComment.value = MyDetailsVal.comment;

        this.InviteSignerDataList = InviteSignerModalData;
        setTimeout(() => {
            this.BindInviteRoles(null);
            this.BindInviteRolesdata();

            if (this.InviteSignerDataList && this.InviteSignerDataList.length > 0) {
                for (let i = 0; i < this.InviteSignerDataList.length; i++) {
                    let selRowId = this.InviteSignerDataList[i].roleSeq;
                    let selectRowOption = <HTMLSelectElement>document.getElementsByClassName(selRowId + "_ddrRolesTypes");
                    let selControl: any = selectRowOption[0];
                    selControl.value = this.InviteSignerDataList[i].selectedRole;
                    let txtInvSignName: any = <HTMLSelectElement>document.getElementById("txtInvSignName_" + selRowId);
                    txtInvSignName.value = this.InviteSignerDataList[i].name;
                    let txtInvSignEmail: any = <HTMLSelectElement>document.getElementById("txtInvSignEmail_" + selRowId);
                    txtInvSignEmail.value = this.InviteSignerDataList[i].email;
                    let radioSignNow: any = <HTMLSelectElement>document.getElementById("radioSignNow_" + selRowId);
                    radioSignNow.checked = this.InviteSignerDataList[i].signnow;
                    let radioInvitebyEmail: any = <HTMLSelectElement>document.getElementById("radioInvitebyEmail_" + selRowId);
                    radioInvitebyEmail.checked = this.InviteSignerDataList[i].inviteBy;
                    let tdCultureInfo: any = <HTMLSelectElement>document.getElementById("tdCultureInfo_" + selRowId);
                    tdCultureInfo.value = this.InviteSignerDataList[i].cultureInfo;

                    if (this.EnableMessageToMobile) {
                        let txtInvSignMobile: any = <HTMLSelectElement>document.getElementById("txtInvSignMobile_" + selRowId);
                        txtInvSignMobile.value = this.InviteSignerDataList[i].signermobile;
                        txtInvSignMobile.setAttribute('maxlength', txtInvSignMobile.value.length);
                        let dialcode = this.InviteSignerDataList[i].signercountrycode;
                        let signerCode = "bg-" + this.InviteSignerDataList[i].signerCode;
                        let signerdeliverymode = this.InviteSignerDataList[i].signerdeliverymode;
                        let hdnDeliveryMode = <HTMLSelectElement>document.getElementById("DeliveryMode_" + selRowId);
                        hdnDeliveryMode.value = signerdeliverymode;
                        let ngbDropdown: any = <HTMLSelectElement>document.getElementById("ngbDropdown_" + selRowId);
                        if (signerCode && signerCode != '') {
                            let spnTitle: any = document.getElementById("spnTitle_" + selRowId);
                            if (spnTitle != null) {
                                spnTitle.innerHTML = '';
                                spnTitle.innerHTML = '<a style="text-decoration-line: none; color: #000;"><span id="spnCountryFlag" style="top: 4px;display: inline-block;width: 25px;position: relative;" class="' + signerCode + '"></span>' + dialcode + '</a>';
                            }
                            this.bindCountryCodeValue(signerCode, selRowId, dialcode);

                            if (signerdeliverymode == 1 || signerdeliverymode == 11 || signerdeliverymode == null || signerdeliverymode == undefined) {
                                let spnTitle: any = <HTMLSelectElement>document.getElementById("spnTitle_" + selRowId);
                                let DialCodeUl: any = <HTMLSelectElement>document.getElementById("DialCodeUl_" + selRowId);
                                let txtInvSignMobile: any = <HTMLSelectElement>document.getElementById("txtInvSignMobile_" + selRowId);
                                spnTitle?.setAttribute('disabled', 'disabled');
                                spnTitle.style.cursor = "no-drop";
                                DialCodeUl.style.display = 'none';
                                txtInvSignMobile.setAttribute('disabled', 'disabled');
                                txtInvSignMobile.style.cursor = "no-drop";
                                txtInvSignMobile.style["background-color"] = "#ddd";
                                ngbDropdown.style["background-color"] = "#ddd";
                                $("#spnTitle_" + selRowId).text('Select');

                                if (signerdeliverymode != 1 && signerdeliverymode != 11) {
                                    signerdeliverymode = 1;
                                }
                            }
                            else if (this.commonService.returnEmailAndMobileDeliveryModeVal(signerdeliverymode)) {
                                let spnTitle: any = <HTMLSelectElement>document.getElementById("spnTitle_" + selRowId);
                                let DialCodeUl: any = <HTMLSelectElement>document.getElementById("DialCodeUl_" + selRowId);
                                spnTitle?.setAttribute('disabled', 'disabled');
                                spnTitle.style.cursor = "no-drop";
                                DialCodeUl.style.display = 'none';
                                txtInvSignMobile.style["background-color"] = "#ffff";
                            }
                            else if (signerdeliverymode == 4 || signerdeliverymode == 6) {
                                let txtInvSignEmail: any = <HTMLSelectElement>document.getElementById("txtInvSignEmail_" + selRowId);
                                txtInvSignEmail.setAttribute('disabled', 'disabled');
                                txtInvSignEmail.style.cursor = "no-drop";
                                txtInvSignEmail.style["background-color"] = "#ddd";
                                let spnTitle: any = <HTMLSelectElement>document.getElementById("spnTitle_" + selRowId);
                                let DialCodeUl: any = <HTMLSelectElement>document.getElementById("DialCodeUl_" + selRowId);
                                spnTitle?.setAttribute('disabled', 'disabled');
                                spnTitle.style.cursor = "no-drop";
                                DialCodeUl.style.display = 'none';
                                txtInvSignMobile.style["background-color"] = "#ffff";
                            }

                            var messageCode = this.commonService.returnCodeToolTipText(signerdeliverymode);
                            if (messageCode != "" && messageCode != null) {
                                var messageCodeArray = messageCode.split('/');
                                if (messageCodeArray.length == 2) {
                                    $("#messageTo_" + selRowId).text(messageCodeArray[0].trim());
                                    $("#codeTo_" + selRowId).text(messageCodeArray[1].trim());
                                    $("#messagecodeTo_" + selRowId).text(messageCodeArray[0].trim()+"/"+messageCodeArray[1].trim());
                                }
                            }
                        }
                        else {
                            let spnTitle: any = <HTMLSelectElement>document.getElementById("spnTitle_" + selRowId);
                            let DialCodeUl: any = <HTMLSelectElement>document.getElementById("DialCodeUl_" + selRowId);
                            let txtInvSignMobile: any = <HTMLSelectElement>document.getElementById("txtInvSignMobile_" + selRowId);
                            spnTitle?.setAttribute('disabled', 'disabled');
                            spnTitle.style.cursor = "no-drop";
                            DialCodeUl.style.display = 'none';
                            txtInvSignMobile.setAttribute('disabled', 'disabled');
                            txtInvSignMobile.style.cursor = "no-drop";
                        }
                    }

                    this.BindRecipientRowProperties(selRowId);
                }
                setTimeout(() => {
                    this.UpdateRolesOptions(-1);
                }, 500);
            }
        }, 500);
    }

    bindCountryCodeValue(signerCode: any, selRowId: any, dialcode: any) {
        if (this.EnableMessageToMobile) {
            let DialCodeUlCol: any = document.getElementById("DialCodeUl_" + selRowId);
            let liCountryItemCol: any = DialCodeUlCol.getElementsByClassName("liCountryItem");

            for (let index = 0; index < liCountryItemCol.length; index++) {
                const item = liCountryItemCol[index];
                let id: any = item.getAttribute("id");
                if (id == "Select" && id == dialcode) {
                    item.getElementsByTagName('a')[0].classList.add('selected');
                    break;
                }
                else if (id == signerCode) {
                    item.getElementsByTagName('a')[0].classList.add('selected');
                    break;
                }
            }
        }
    }

    validateEmail(email: any) {
        //var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
        let re = EmailAddressFormat.ValidEmail;
        return re.test(email);
    }

    BindInviteRoles(ddlRoleControl: any) {
        let roleControlsClass = 'InviteRoles';
        let roleControls = <HTMLSelectElement>document.getElementsByClassName(roleControlsClass);
        for (let i = 0; i < roleControls.length; i++) {
            roleControls[i].innerHTML = "";
            var option = document.createElement("option");
            option.value = "1";
            option.text = "Select";
            roleControls[i].appendChild(option);
        }
        Array.from(this.StaticTemplateRoles).forEach((element: any, index: any) => {
            if (this.currentRoleID != element.RecipientId) {
                for (let i = 0; i < roleControls.length; i++) {
                    var option1 = document.createElement("option");
                    option1.value = element.RecipientId;
                    option1.text = element.RoleName;
                    roleControls[i].appendChild(option1);
                }
            }
        });
    }

    BindInviteRolesdata() {
        let tbodyRowCount: any;
        let table: any = document.getElementById("dbtbody");
        if (table == null || table == undefined) {
            tbodyRowCount = this.InviteSignerDataList.length;
        } else {
            tbodyRowCount = table.tBodies[0].rows.length;
        }
        let dvInviteAddNew: any = document.getElementById("dvInviteAddNew");
        if ((this.StaticTemplateRoles.length - 1) == tbodyRowCount) {
            this.dvInviteAddNewStyle = "none";
            dvInviteAddNew.classList.remove("d-flex");
        }
        else if ((this.StaticTemplateRoles.length - 1) > tbodyRowCount) {
            this.dvInviteAddNewStyle = "block";
        }
        else {
            this.dvInviteAddNewStyle = "none";
            dvInviteAddNew.classList.remove("d-flex");
        }
    }

    deleteOptionalInviteRow(event: any, roleSeq: any) {
        this.InviteSignerDataList = this.InviteSignerDataList.filter((c: any) => { return c.roleSeq !== roleSeq });
        let tbodyRowCount: any;
        setTimeout(() => {
            let table: any = document.getElementById("dbtbody");
            if (table == null || table == undefined) {
                tbodyRowCount = this.InviteSignerDataList.length;
            } else {
                tbodyRowCount = table.tBodies[0].rows.length;
            }

            if ((this.StaticTemplateRoles.length - 1) > tbodyRowCount) {
                let dvInviteAddNew: any = document.getElementById("dvInviteAddNew");
                dvInviteAddNew.classList.add("d-flex");
                this.dvInviteAddNewStyle = "block";
                this.deletedOptionalrowscount = 1;
            }
        }, 300);
    }

    getSignAndInviteValue(event: any, roleSeq: any, type: any) {
        let radioSignNow: any = document.getElementById("radioSignNow_" + roleSeq);
        let radioInvitebyEmail: any = document.getElementById("radioInvitebyEmail_" + roleSeq);
        if (type == "1") {
            radioSignNow.setAttribute("checked", "checked");
            if (radioInvitebyEmail) {
                $('#radioInvitebyEmail_' + roleSeq).prop('checked', '');
                $('#radioInvitebyEmail_' + roleSeq).removeAttr('checked');
            }
        }
        else if (type == "2") {
            radioInvitebyEmail.setAttribute("checked", "checked");
            $("#radioSignNow_" + roleSeq).prop('checked', '');
            $('#radioSignNow_' + roleSeq).removeAttr('checked');
        }
        else if (type == "3") {
            $("#radioSignNow_" + roleSeq).prop('checked', '');
            $('#radioSignNow_' + roleSeq).removeAttr('checked');
        }
    }

    UpdateRolesOptions(roleSeq: any) {
        let roleControls = <HTMLSelectElement>document.getElementsByClassName("InviteRoles");
        let selectedRoles: any[] = [];
        Array.from(roleControls).forEach((element: any, index: any) => {
            if (element.value != "Select" && element.value != "" && element.value != "1") {
                selectedRoles.push(element.value);
            }
        });

        Array.from(roleControls).forEach((element: any, index: any) => {
            Array.from(element.options).forEach((option: any, index: any) => {
                option.removeAttribute("disabled");
            });

            Array.from(element.options).forEach((option: any, index: any) => {
                let slIndex = element.options.selectedIndex;
                if (slIndex !== index) {
                    if (selectedRoles.indexOf(option.value) > -1 && option.value != "Select" && option.value != "" && option.value != "1") {
                        option.setAttribute("disabled", "disabled");
                    }
                    else {
                        option.removeAttribute("disabled");
                    }
                }
            });
        });

        if (roleSeq != -1) {
            this.BindRecipientRowProperties(roleSeq);

            let currentRoleControls = <HTMLSelectElement>document.getElementsByClassName(roleSeq + "_ddrRolesTypes");
            let selControl: any = currentRoleControls[0];
            let selectedRole = selControl.value;
            if (selectedRole && selectedRole != "Select" && selectedRole != "" && selectedRole == "1") {
                this.UpdateDeliveryMode(roleSeq);
            }
            else {
                if (this.StaticTemplateRoles != null && this.StaticTemplateRoles.length > 0) {
                    let selIndex: any = Array.prototype.findIndex.call(this.InviteSignerDataList, (x) => x.roleSeq == roleSeq);
                    Array.from(this.StaticTemplateRoles).forEach((element: any, index: any) => {
                        if (this.currentRoleID != element.RecipientId && element.RecipientId == selectedRole) {
                            this.InviteSignerDataList[selIndex].roleSeq = roleSeq;
                            this.InviteSignerDataList[selIndex].deliveryMode = element.DeliveryMode;
                            this.InviteSignerDataList[selIndex].countryCode = element.CountryCode;
                            this.InviteSignerDataList[selIndex].dialCode = element.DialCode;
                            this.InviteSignerDataList[selIndex].cultureInfo = element.CultureInfo;
                            this.InviteSignerDataList[selIndex].recipientId = element.RecipientId;
                        }
                    });
                }
                this.SetDeliveryMode(roleSeq, selectedRole);
            }
        }
    }

    BindRecipientRowProperties(roleSeq: any) {
        let currentRoleControls = <HTMLSelectElement>document.getElementsByClassName(roleSeq + "_ddrRolesTypes");
        let tdInvSignReq: any = document.getElementById("tdInvSignReq_" + roleSeq);
        let tdInvSignDelete: any = document.getElementById("btnInvSignDelete_" + roleSeq);

        let selControl: any = currentRoleControls[0];
        let selectedRole = selControl.value;

        if (this.StaticTemplateRoles && this.StaticTemplateRoles != null && this.StaticTemplateRoles.length > 0
            && selectedRole && selectedRole != "Select" && selectedRole != "" && selectedRole != "1") {
            Array.from(this.StaticTemplateRoles).forEach((element: any, index: any) => {
                if (selectedRole.toLowerCase() == element.RecipientId.toLowerCase()) {
                    if (element.isRequired) {
                        tdInvSignReq.style["display"] = "block";
                        tdInvSignDelete.style["display"] = "none";
                    }
                    else {
                        tdInvSignReq.style["display"] = "none";
                        tdInvSignDelete.style["display"] = "block";
                    }
                    return;
                }
            });
        }
        else {
            tdInvSignReq.style["display"] = "none";
            tdInvSignDelete.style["display"] = "block";
        }
    }

    InviteAddNewRow(event: any,) {
        let Staticcount = 0;
        let rowHTML = "";
        let roleSeq: number = 0;
        if (this.InviteSignerDataList && this.InviteSignerDataList.length > 0) {
            roleSeq = this.InviteSignerDataList[this.InviteSignerDataList.length - 1].roleSeq + 1;
        }
        else {
            roleSeq = 0;
        }

        this.InviteSignerDataList.push({
            roleSeq: roleSeq, name: '', email: '', signnow: true, inviteBy: false, selectedRole: '', cultureInfo: '',
            deliveryMode: '', countryCode: '', dialCode: '', recipientId: ''
        });

        setTimeout(() => {
            let roleControls = <HTMLSelectElement>document.getElementsByClassName(roleSeq + "_ddrRolesTypes");
            roleControls[0].innerHTML = "";
            var option = document.createElement("option");
            option.value = "1";
            option.text = "Select";
            roleControls[0].appendChild(option);

            Array.from(this.StaticTemplateRoles).forEach((element: any, index: any) => {
                if (this.currentRoleID != element.RecipientId) {
                    var option1 = document.createElement("option");
                    option1.value = element.RecipientId;
                    option1.text = element.RoleName;
                    roleControls[0].appendChild(option1);
                }
            });

            this.BindInviteRolesdata();
            this.UpdateDeliveryMode(roleSeq);
            this.UpdateRolesOptions(roleSeq);
        }, 500);
    }

    closeInviteSignerModal() {
        this.activeModal.dismiss('Cross click');
    }

    CheckNull(val: any, defaultVal: any) {
        if (val.trim() && val != "undefined" && val != "null") { return val; }
        else { return defaultVal; }
    }

    showToaster(msg: string, errorType: any) {
        if (errorType === "Error") {
            this.toastr.error(msg, errorType, {
                timeOut: 2000
            });
        }
        else if (errorType === "Warning") {
            this.toastr.warning(msg, errorType, {
                timeOut: 2000
            });
        }
        else if (errorType === "Success") {
            this.toastr.success(msg, errorType, {
                timeOut: 2000
            });
        }
    }

    onContinueInviteSignerModal() {
        let tbodyRowCount: any;
        let table: any = document.getElementById("dbtbody");
        if (table == null || table == undefined) {
            tbodyRowCount = this.InviteSignerDataList.length;
        } else {
            tbodyRowCount = table.tBodies[0].rows.length;
        }
        this.storageSignerList = [];
        let localSignNowCount = 0;
        let InviteSignerModel: any[] = [];
        let InviteSignerWithCurrntRecipModel: any[] = [];
        let signNowEmails: any[] = [];
        var signNowMobiles: any[] = [];
        let inviteByEmails: any[] = [];
        let inviteByMobiles: any[] = [];
        let flag = false;
        let requiredRolesCount = 0;
        let totalRequiredRoles = 0;
        let InviteSignerWithRoleId: any[] = [];
        let InviteSignerWithRoleIdlist = "";
        let currentInviteEmail: any;
        let CurrentEmail: any;

        let txtMyDetailsSignAs: any = document.getElementById("txtMyDetailsSignAs");
        let txtMyDetailsSignAsValue: any = txtMyDetailsSignAs.value;
        let txtMyDetailsEmail: any = document.getElementById("txtMyDetailsEmail");
        let txtMyDetailsEmailValue = '';
        if (txtMyDetailsEmail) {
            txtMyDetailsEmailValue = txtMyDetailsEmail.value;
        }
        let txtComment: any = document.getElementById("txtComment");
        let txtCommentValue: any = txtComment.value;

        let isMyEmailEmpty = false;
        let isMyMobileEmpty = false;

        if (this.currentRecipientDeliveryMode == "1" || this.currentRecipientDeliveryMode == "11") {
            if (txtMyDetailsEmailValue == "") {
                flag = true;
                this.showToaster("Please enter email address.", 'Warning');
                return;
            }
            if (!this.validateEmail(txtMyDetailsEmailValue)) {
                flag = true;
                this.showToaster(this.translationsService.getLanguageValidationVal("lang_InvalidEmailAddress"), 'Warning');
                return;
            }
        }
        else if (this.commonService.returnEmailAndMobileDeliveryModeVal(this.currentRecipientDeliveryMode)) {
            if (txtMyDetailsEmailValue == "") {
                isMyEmailEmpty = true;
            }
            let txtMyPhone: any = <HTMLSelectElement>document.getElementById("txtMyPhone");
            if (txtMyPhone) {
                this.currentRecipientMobileNumber = txtMyPhone.value;
            }
            if (this.currentRecipientMobileNumber == "" || this.currentRecipientMobileNumber == undefined || this.currentRecipientMobileNumber == null) {
                isMyMobileEmpty = true;
            }

            // if (isMyEmailEmpty && isMyMobileEmpty) {
            //     flag = true;
            //     this.showToaster("Please provide email or mobile number.", "Warning");
            //     return;
            // }

            if (this.IsSendMessageCodetoAvailableEmailorMobile && this.IsSendMessageCodetoAvailableEmailorMobile == true) {
                if (isMyEmailEmpty && isMyMobileEmpty) {
                    flag = true;
                    this.showToaster("Please provide email or mobile number.", "Warning");
                    return;
                }
            }
            else {
                if (isMyEmailEmpty) {
                    flag = true;
                    this.showToaster("Please enter email address.", "Warning"); return;
                }
                if (isMyMobileEmpty) {
                    flag = true;
                    this.showToaster("Please enter mobile number.", "Warning"); return;
                }
            }

            if (!isMyEmailEmpty) {
                if (txtMyDetailsEmailValue == "") {
                    flag = true;
                    this.showToaster("Please enter email address.", 'Warning');
                    return;
                }
                if (!this.validateEmail(txtMyDetailsEmailValue)) {
                    flag = true;
                    this.showToaster(this.translationsService.getLanguageValidationVal("lang_InvalidEmailAddress"), 'Warning');
                    return;
                }
            }
            if (!isMyMobileEmpty) {
                if (this.currentRecipientDialCode == "Select" || this.currentRecipientDialCode == "" || this.currentRecipientDialCode == undefined || this.currentRecipientDialCode == null) {
                    flag = true;
                    this.showToaster("Please select country code.", 'Warning');
                    return;
                }
                if (this.currentRecipientMobileNumber == "" || this.currentRecipientMobileNumber == undefined || this.currentRecipientMobileNumber == null) {
                    flag = true;
                    this.showToaster("Please provide mobile number.", 'Warning');
                    return;
                }

                //var mobileNumber = this.currentRecipientDialCode + this.currentRecipientMobileNumber;
                let txtMyPhone: any = <HTMLSelectElement>document.getElementById("txtMyPhone");
                if (!this.isValidPhone(this.currentRecipientMobileNumber, txtMyPhone.getAttribute('maxlength'))) {
                    flag = true;
                    this.showToaster("Invalid mobile number.", 'Warning');
                    return;
                }
            }
        }
        else if (this.currentRecipientDeliveryMode == "4" || this.currentRecipientDeliveryMode == "6") {
            let txtMyPhone: any = <HTMLSelectElement>document.getElementById("txtMyPhone");
            if (txtMyPhone) {
                this.currentRecipientMobileNumber = txtMyPhone.value;
            }
            if (this.currentRecipientDialCode == "Select" || this.currentRecipientDialCode == "" || this.currentRecipientDialCode == undefined || this.currentRecipientDialCode == null) {
                flag = true;
                this.showToaster("Please select country code.", 'Warning');
                return;
            }
            if (this.currentRecipientMobileNumber == "" || this.currentRecipientMobileNumber == undefined || this.currentRecipientMobileNumber == null) {
                flag = true;
                this.showToaster("Please provide mobile number.", 'Warning');
                return;
            }
            // var mobileNumber = this.currentRecipientDialCode + this.currentRecipientMobileNumber;           
            if (!this.isValidPhone(this.currentRecipientMobileNumber, txtMyPhone.getAttribute('maxlength'))) {
                flag = true;
                this.showToaster("Invalid mobile number.", 'Warning');
                return;
            }
        }

        if (this.currentRecipientDeliveryMode == "1" || this.currentRecipientDeliveryMode == "11") {
            signNowEmails.push(txtMyDetailsEmailValue);
            inviteByEmails.push(txtMyDetailsEmailValue);
        }
        else if (this.commonService.returnEmailAndMobileDeliveryModeVal(this.currentRecipientDeliveryMode)) {
            if (!isMyEmailEmpty) {
                signNowEmails.push(txtMyDetailsEmailValue);
                inviteByEmails.push(txtMyDetailsEmailValue);
            }
            if (!isMyMobileEmpty) {
                signNowMobiles.push(this.currentRecipientDialCode + this.currentRecipientMobileNumber);
                inviteByMobiles.push(this.currentRecipientDialCode + this.currentRecipientMobileNumber);
            }
        }
        else if (this.currentRecipientDeliveryMode == "4" || this.currentRecipientDeliveryMode == "6") {
            signNowMobiles.push(this.currentRecipientDialCode + this.currentRecipientMobileNumber);
            inviteByMobiles.push(this.currentRecipientDialCode + this.currentRecipientMobileNumber);
        }

        let CurrentRoleID: any = localStorage.getItem("currentRoleID");
        if (this.StaticTemplateRoles && this.StaticTemplateRoles.length > 0) {
            let tempRequiredRoles = this.StaticTemplateRoles.filter(function (element: any, index: any) {
                return (CurrentRoleID != element.RecipientId && element.isRequired == true);
            });
            totalRequiredRoles = tempRequiredRoles.length;
        }

        for (let i = 0; i < this.InviteSignerDataList.length; i++) {
            let selRowId = this.InviteSignerDataList[i].roleSeq;
            let selectRowOption = <HTMLSelectElement>document.getElementsByClassName(selRowId + "_ddrRolesTypes");
            let selControl: any = selectRowOption[0];
            let selectedRole: any = selControl.value;
            let selectedRooleName: any = selControl.selectedOptions[0].text;
            let txtInvSignName: any = <HTMLSelectElement>document.getElementById("txtInvSignName_" + selRowId);
            let signname: any = txtInvSignName.value;
            let txtInvSignEmail: any = <HTMLSelectElement>document.getElementById("txtInvSignEmail_" + selRowId);
            let signemail: any = txtInvSignEmail.value;
            let radioSignNow: any = <HTMLSelectElement>document.getElementById("radioSignNow_" + selRowId);
            let isSignNow: boolean = radioSignNow.checked ? true : false;
            let radioInvitebyEmail: any = <HTMLSelectElement>document.getElementById("radioInvitebyEmail_" + selRowId);
            let isInvitebyEmail: boolean = radioInvitebyEmail.checked ? true : false;
            let tdCultureInfo: any = <HTMLSelectElement>document.getElementById("tdCultureInfo_" + selRowId);
            let cultureInfo: any = tdCultureInfo.value;

            let selectedDialCode: any = '';
            let selectedMobileValText = '';
            let isInvitebyMobile: boolean = false;
            let hdnDeliveryModeVal = '';
            let spnCountryCode: any = '';
            if (this.EnableMessageToMobile) {
                let selectedMobileVal = <HTMLSelectElement>document.getElementById("txtInvSignMobile_" + selRowId);
                selectedMobileValText = selectedMobileVal.value;
                let hdnDeliveryMode = <HTMLSelectElement>document.getElementById("DeliveryMode_" + selRowId);
                hdnDeliveryModeVal = hdnDeliveryMode.value;
                if (this.commonService.returnShowMobileDeliveryModeVal(hdnDeliveryModeVal)) {
                    selectedDialCode = $("#spnTitle_" + selRowId).text();
                    if ($("#spnTitle_" + selRowId).find('span').length > 0) {
                        let spnClassList: any = $("#spnTitle_" + selRowId).find('span')[0].classList;
                        if (spnClassList && spnClassList.length > 0) {
                            spnCountryCode = spnClassList[0].split('-')[1];
                        }
                    }
                }
            }
            else {
                hdnDeliveryModeVal = "1";
            }

            if (this.CheckNull(selectedRooleName, "") == "Select" || (!selectedRole || selectedRole == "Select" || selectedRole == "" || selectedRole == "1")) {
                flag = true;
                this.showToaster(this.translationsService.getLanguageTranslationVal(ResourceKeys.SelectSignAs), 'Warning');
                return;
            }
            if (signname == "") {
                flag = true;
                this.showToaster(this.translationsService.getLanguageValidationVal("lang_addName"), 'Warning');
                return;
            }

            var isEmailEmpty = false;
            var isMobileEmpty = false;
            if (hdnDeliveryModeVal == "1" || hdnDeliveryModeVal == "11") {
                if (signemail == "") {
                    flag = true;
                    this.showToaster("Please enter email address.", 'Warning');
                    return;
                }
                if (!this.validateEmail(signemail)) {
                    flag = true;
                    this.showToaster(this.translationsService.getLanguageValidationVal("lang_InvalidEmailAddress"), 'Warning');
                    return;
                }
            }
            else if (this.commonService.returnEmailAndMobileDeliveryModeVal(hdnDeliveryModeVal)) {
                if (signemail == "") {
                    isEmailEmpty = true;
                }
                if (selectedMobileValText == "" || selectedMobileValText == undefined || selectedMobileValText == null) {
                    isMobileEmpty = true;
                }

                // if (isEmailEmpty && isMobileEmpty) {
                //     flag = true;
                //     this.showToaster("Please provide email or mobile number.", 'Warning');
                //     return;
                // }

                if (this.IsSendMessageCodetoAvailableEmailorMobile && this.IsSendMessageCodetoAvailableEmailorMobile == true) {
                    if (isEmailEmpty && isMobileEmpty) {
                        flag = true;
                        this.showToaster("Please provide email or mobile number.", 'Warning');
                        return;
                    }
                }
                else {
                    if (isEmailEmpty) {
                        flag = true;
                        this.showToaster("Please enter email address.", "Warning"); return;
                    }
                    if (isMobileEmpty) {
                        flag = true;
                        this.showToaster("Please enter mobile number.", "Warning"); return;
                    }
                }

                if (!isEmailEmpty) {
                    if (signemail == "") {
                        flag = true;
                        this.showToaster("Please enter email address.", 'Warning');
                        return;
                    }
                    if (!this.validateEmail(signemail)) {
                        flag = true;
                        this.showToaster(this.translationsService.getLanguageValidationVal("lang_InvalidEmailAddress"), 'Warning');
                        return;
                    }
                }
                if (!isMobileEmpty) {
                    if (selectedDialCode == "Select" || selectedDialCode == "" || selectedDialCode == undefined || selectedDialCode == null) {
                        flag = true;
                        this.showToaster("Please select country code.", 'Warning');
                        return;
                    }
                    if (selectedMobileValText == "" || selectedMobileValText == undefined || selectedMobileValText == null) {
                        flag = true;
                        this.showToaster("Please provide mobile number.", 'Warning');
                        return;
                    }

                    var slMobileNumber = selectedDialCode + selectedMobileValText;
                    let selectedMobileVal = <HTMLSelectElement>document.getElementById("txtInvSignMobile_" + selRowId);
                    if (!this.isValidPhone(selectedMobileValText, selectedMobileVal.getAttribute('maxlength'))) {
                        flag = true;
                        this.showToaster("Invalid mobile number.", 'Warning');
                        return;
                    }
                }
            }
            else if (hdnDeliveryModeVal == "4" || hdnDeliveryModeVal == "6") {
                if (selectedDialCode == "Select" || selectedDialCode == "" || selectedDialCode == undefined || selectedDialCode == null) {
                    flag = true;
                    this.showToaster("Please select country code.", 'Warning');
                    return;
                }
                if (selectedMobileValText == "" || selectedMobileValText == undefined || selectedMobileValText == null) {
                    flag = true;
                    this.showToaster("Please provide mobile number.", 'Warning');
                    return;
                }

                var slMobileNumber = selectedDialCode + selectedMobileValText;
                let selectedMobileVal = <HTMLSelectElement>document.getElementById("txtInvSignMobile_" + selRowId);
                if (!this.isValidPhone(selectedMobileValText, selectedMobileVal.getAttribute('maxlength'))) {
                    flag = true;
                    this.showToaster("Invalid mobile number.", 'Warning');
                    return;
                }
            }

            if (isSignNow == false && isInvitebyEmail == false && isInvitebyMobile == false) {
                flag = true;
                this.showToaster("Please select any one Invite signer/Sign Now.", 'Warning');
                return;
            }
            if (totalRequiredRoles > 0) {
                if (this.StaticTemplateRoles && this.StaticTemplateRoles.length > 0) {
                    Array.from(this.StaticTemplateRoles).forEach((element: any, index: any) => {
                        if (CurrentRoleID != element.RecipientId && selectedRole.toLowerCase() == element.RecipientId.toLowerCase()) {
                            if (element.isRequired) {
                                requiredRolesCount = requiredRolesCount + 1;
                            }
                            return;
                        }
                    });
                }
            }

            if (isSignNow == true) {
                localSignNowCount = localSignNowCount + 1;
            }

            if (selectedDialCode == "Select" || selectedDialCode == undefined || selectedDialCode == '') {
               // selectedDialCode = '';
                selectedMobileValText = '';
               // spnCountryCode = '';
            }

            var signerInviteMode;
            if (hdnDeliveryModeVal == "1" || hdnDeliveryModeVal == "11") {
                signNowEmails.push(signemail);
                inviteByEmails.push(signemail);
            }
            else if (this.commonService.returnEmailAndMobileDeliveryModeVal(hdnDeliveryModeVal)) {
                if (!isEmailEmpty) {
                    signNowEmails.push(signemail);
                    inviteByEmails.push(signemail)
                }
                if (!isMobileEmpty) {                    
                    signNowMobiles.push(selectedDialCode + selectedMobileValText);
                }
            }
            else if (hdnDeliveryModeVal == "4" || hdnDeliveryModeVal == "6") {
                signNowMobiles.push(selectedDialCode + selectedMobileValText);
            }

            let InviteSignerData = {
                RoleId: selectedRole,
                RoleName: selectedRooleName,
                SignerName: signname.trim(),
                SignerEmail: signemail.trim(),
                SignerDialCode: selectedDialCode,
                SignerCountryCode: spnCountryCode,
                SignerMobile: selectedMobileValText,
                SignerDeliveryMode: hdnDeliveryModeVal,
                SignNowOrInvitedEmail: isSignNow == true ? 1 : 2,
                CanEdit: false,
                InvitesignerMessage: txtCommentValue,
                IscurrentRecipient: false,
                CertificateSignature: "",
                CultureInfo: this.CheckNull(cultureInfo, localStorage.getItem("cultureInfo"))
            };
            InviteSignerModel.push(InviteSignerData);
            InviteSignerWithCurrntRecipModel.push(InviteSignerData);

            if (InviteSignerData.SignNowOrInvitedEmail == 2) {
                InviteSignerWithRoleIdlist += InviteSignerData.RoleId + ",";
                InviteSignerWithRoleId.push(InviteSignerData.RoleId);
            }

            this.storageSignerList.push({
                roleSeq: selRowId, name: signname, email: signemail, signnow: isSignNow, inviteBy: isInvitebyEmail, selectedRole: selectedRole,
                cultureInfo: cultureInfo, signercountrycode: selectedDialCode, signermobile: selectedMobileValText,
                signerdeliverymode: hdnDeliveryModeVal, inviteByMobile: isInvitebyMobile, signerCode: spnCountryCode
            });
        }

        if (flag == false && requiredRolesCount != totalRequiredRoles) {
            flag = true;
            this.showToaster("Please add recipient details for all mandatory roles.", "Warning");
            return;
        }
        if (flag) {
            return;
        }

        let isDuplicate = signNowEmails.some(function (item, idx) { return signNowEmails.indexOf(item) != idx; });
        if (isDuplicate) {
            this.showToaster(this.translationsService.getLanguageTranslationVal(ResourceKeys.DuplicateEmailsNotAllowed), 'Warning'); return;
        }

        let isinviteByEmailsDuplicate = inviteByEmails.some(function (item, idx) { return inviteByEmails.indexOf(item) != idx; });
        if (isinviteByEmailsDuplicate) {
            this.showToaster(this.translationsService.getLanguageTranslationVal(ResourceKeys.DuplicateEmailsNotAllowed), 'Warning'); return;
        }

        if (this.EnableMessageToMobile) {
            let isDuplicateMobiles = signNowMobiles.some(function (item, idx) { return signNowMobiles.indexOf(item) != idx; });
            if (isDuplicateMobiles) {
                this.showToaster("Mobile number already exists. Please add unique phone numbers.", 'Warning'); return;
            }
        }

        let currentRole = "";
        let tdcultureInfo: any = <HTMLSelectElement>document.getElementById("tdCultureInfo_");
        if (this.StaticTemplateRoles && this.StaticTemplateRoles != null && this.StaticTemplateRoles.length > 0) {
            Array.from(this.StaticTemplateRoles).forEach((element: any, index: any) => {
                if (CurrentRoleID == element.RecipientId) {
                    currentRole = element.RoleName;
                    localSignNowCount = localSignNowCount + 1;
                    tdcultureInfo.value = element.CultureInfo;
                }
            });
        }

        let txtMyDetailsName: any = document.getElementById("txtMyDetailsName");
        let txtMyDetailsNameValue: any = txtMyDetailsName.value;
        if (txtMyDetailsNameValue == "") {
            flag = true;
            this.showToaster(this.translationsService.getLanguageValidationVal("lang_addName"), 'Warning');
            return;
        }

        if (this.currentRecipientDialCode == "Select" || this.currentRecipientDialCode == undefined) {
            //this.currentRecipientDialCode = '';
            this.currentRecipientMobileNumber = '';
           // this.currentRecipientCountryCode = '';
        }

        let currentSignerData = {
            RoleId: CurrentRoleID,
            RoleName: currentRole,
            SignerName: txtMyDetailsNameValue,
            SignerEmail: txtMyDetailsEmailValue,
            SignerDialCode: this.currentRecipientDialCode == undefined ? "" : this.currentRecipientDialCode,
            SignerMobile: this.currentRecipientMobileNumber == undefined ? "" : this.currentRecipientMobileNumber,
            SignerDeliveryMode: String(this.currentRecipientDeliveryMode),
            SignerCountryCode: this.currentRecipientCountryCode == undefined ? "" : this.currentRecipientCountryCode,
            SignNowOrInvitedEmail: 1,
            CanEdit: false,
            InvitesignerMessage: txtCommentValue,
            IscurrentRecipient: true,
            CertificateSignature: "",
            CultureInfo: this.CheckNull(tdcultureInfo.value, localStorage.getItem("cultureInfo"))
        };

        let myDetailsVal = {
            signAs: txtMyDetailsSignAsValue,
            myName: txtMyDetailsNameValue,
            myEmail: txtMyDetailsEmailValue,
            deliverymode: this.currentRecipientDeliveryMode,
            dialcode: this.currentRecipientDialCode,
            mobilenumber: this.currentRecipientMobileNumber,
            countrycode: this.currentRecipientCountryCode,
            comment: txtCommentValue
        };

        currentInviteEmail = txtMyDetailsEmailValue;
        CurrentEmail = txtMyDetailsEmailValue;
        InviteSignerModel.push(currentSignerData);
        InviteSignerWithCurrntRecipModel.push(currentSignerData);

        localStorage.setItem("InviteSignerModalData", JSON.stringify(this.storageSignerList));
        localStorage.setItem("MyDetailsVal", JSON.stringify(myDetailsVal));
        localStorage.setItem("InviteSignerWithCurrntRecipModel", JSON.stringify(InviteSignerWithCurrntRecipModel));
        localStorage.setItem("InviteSignerModel", JSON.stringify(InviteSignerModel));
        localStorage.setItem("localSignNowCount", String(localSignNowCount));
        localStorage.removeItem("MandatoryAttachmentFileCount");
        localStorage.setItem("recipientEmail", CurrentEmail);
        localStorage.setItem("currentEmail", CurrentEmail);
        localStorage.setItem("currentMobile", this.currentRecipientMobileNumber);
        this.activeModal.dismiss('Cross click');
        let returnObj = {
            InviteSignerWithCurrntRecipModel: InviteSignerWithCurrntRecipModel,
            InviteSignerModel: InviteSignerModel,
            InviteSignerWithRoleIdlist: InviteSignerWithRoleIdlist,
            InviteSignerWithRoleId: InviteSignerWithRoleId,
            MyDetailsVal: myDetailsVal
        };
        this.showToaster("Invite signers details updated successfully.", 'Success');
        this.event.next(returnObj);
    }

    getLanguageTranslationValue(resourcekeyId: any) {
        return this.translationsService.getLanguageTranslationVal(resourcekeyId);
    }

    selectedCountryDialCode(event: any, roleSeq: any) {
        $($('#DialCodeUl_' + roleSeq + ' li a')[0]).css('background-color', '');
        let dataCountryCodeMaxLength: any;
        let txtInvSignMobile: any = <HTMLSelectElement>document.getElementById("txtInvSignMobile_" + roleSeq);
        if (this.EnableMessageToMobile) {
            let ed: any = event.target;
            let edd = event.target.parentElement.innerHTML;
            let spnTitle: any = document.getElementById("spnTitle_" + roleSeq);
            if (spnTitle != null) {
                spnTitle.innerHTML = '';
                spnTitle.innerHTML = edd;
                let spnCountryFlagCol: any = document.getElementById("spnTitle_" + roleSeq)!.getElementsByTagName("span");
                if (spnCountryFlagCol && spnCountryFlagCol.length > 0) {
                    document.getElementById(spnCountryFlagCol[0].id)!.style.top = "4px";
                    $('#DialCodeUl_' + roleSeq + ' li a').removeClass('selected');
                    $('#DialCodeUl_' + roleSeq + ' li ').removeClass('selected');
                    if (event.target.parentElement.getElementsByTagName('a').length > 0) {
                        dataCountryCodeMaxLength = event.target.parentElement.getElementsByTagName('a')[0].getAttribute("data-maxlength");
                        event.target.parentElement.getElementsByTagName('a')[0].classList.add('selected');
                    }
                    else {
                        dataCountryCodeMaxLength = event.target.parentElement.getAttribute("data-maxlength");
                        event.target.parentElement.classList.add('selected');
                    }
                    txtInvSignMobile.value = '';
                    if (dataCountryCodeMaxLength != null && dataCountryCodeMaxLength != undefined) {
                        txtInvSignMobile.setAttribute('maxlength', dataCountryCodeMaxLength);
                    }
                }
            }
        }
    }

    SetDeliveryMode(roleSeqOrder: any, selectedRole: any) {
        if (this.EnableMessageToMobile) {
            if (this.InviteSignerDataList && this.InviteSignerDataList.length > 0 && roleSeqOrder != null) {
                for (let i = 0; i < this.InviteSignerDataList.length; i++) {
                    if (this.InviteSignerDataList[i].recipientId == selectedRole) {
                        var deliveryMode = this.InviteSignerDataList[i].deliveryMode;
                        let DeliveryMode: any = <HTMLSelectElement>document.getElementById("DeliveryMode_" + roleSeqOrder);
                        DeliveryMode.value = deliveryMode;
                        let spnTitle: any = <HTMLSelectElement>document.getElementById("spnTitle_" + roleSeqOrder);
                        let DialCodeUl: any = <HTMLSelectElement>document.getElementById("DialCodeUl_" + roleSeqOrder);
                        let txtInvSignMobile: any = <HTMLSelectElement>document.getElementById("txtInvSignMobile_" + roleSeqOrder);
                        let radioInvitebyEmail: any = <HTMLSelectElement>document.getElementById("radioInvitebyEmail_" + roleSeqOrder);
                        let txtInvSignEmail: any = <HTMLSelectElement>document.getElementById("txtInvSignEmail_" + roleSeqOrder);
                        let ngbDropdown: any = <HTMLSelectElement>document.getElementById("ngbDropdown_" + roleSeqOrder);

                        if (deliveryMode == 1 || deliveryMode == 11 || deliveryMode == null || deliveryMode == undefined) {
                            spnTitle?.setAttribute('disabled', 'disabled');
                            spnTitle.style.cursor = "no-drop";
                            DialCodeUl.style.display = 'none';
                            txtInvSignMobile.setAttribute('disabled', 'disabled');
                            txtInvSignMobile.style.cursor = "no-drop";
                            txtInvSignMobile.style["background-color"] = "#ddd";
                            ngbDropdown.style["background-color"] = "#ddd";
                            txtInvSignEmail.removeAttribute('disabled');
                            txtInvSignEmail.style.cursor = "pointer";
                            txtInvSignEmail.style["background-color"] = "#fff";
                            radioInvitebyEmail.removeAttribute('disabled');
                            txtInvSignMobile.value = '';

                            if (deliveryMode != 1 && deliveryMode != 11) {
                                deliveryMode = 1;
                            }
                        }
                        else if (this.commonService.returnEmailAndMobileDeliveryModeVal(deliveryMode)) {
                            txtInvSignEmail.removeAttribute('disabled');
                            txtInvSignEmail.style.cursor = "pointer";
                            txtInvSignEmail.style["background-color"] = "#fff";
                            radioInvitebyEmail.removeAttribute('disabled');
                            spnTitle?.removeAttribute('disabled');
                            spnTitle.style.cursor = "pointer";
                            DialCodeUl.style.display = '';
                            txtInvSignMobile.removeAttribute('disabled');
                            txtInvSignMobile.style.cursor = "pointer";
                            txtInvSignMobile.style["background-color"] = "#fff";
                            ngbDropdown.style["background-color"] = "#ffff";
                        }
                        else if (deliveryMode == 4 || deliveryMode == 6) {
                            txtInvSignEmail.setAttribute('disabled', 'disabled');
                            txtInvSignEmail.style.cursor = "no-drop";
                            txtInvSignEmail.style["background-color"] = "#ddd";
                            radioInvitebyEmail.removeAttribute('disabled');
                            spnTitle?.removeAttribute('disabled');
                            spnTitle.style.cursor = "pointer";
                            DialCodeUl.style.display = '';
                            txtInvSignMobile.removeAttribute('disabled');
                            txtInvSignMobile.style.cursor = "pointer";
                            txtInvSignMobile.style["background-color"] = "#fff";
                            ngbDropdown.style["background-color"] = "#ffff";
                            txtInvSignEmail.value = '';
                        }

                        var messageCode = this.commonService.returnCodeToolTipText(deliveryMode);
                        if (messageCode != "" && messageCode != null) {
                            var messageCodeArray = messageCode.split('/');
                            if (messageCodeArray.length == 2) {
                                $("#messageTo_" + roleSeqOrder).text(messageCodeArray[0].trim());
                                $("#codeTo_" + roleSeqOrder).text(messageCodeArray[1].trim());
                                $("#messagecodeTo_" + roleSeqOrder).text(messageCodeArray[0].trim()+"/"+messageCodeArray[1].trim());
                            }
                        }

                        if (this.commonService.returnShowMobileDeliveryModeVal(deliveryMode)) {
                            let signerCode: any = this.InviteSignerDataList[i].countryCode != null ? this.InviteSignerDataList[i].countryCode.toLowerCase() : this.InviteSignerDataList[i].countryCode;
                            signerCode = "bg-" + signerCode;
                            let dialCode: any = this.InviteSignerDataList[i].dialCode;
                            if (spnTitle != null) {
                                spnTitle.innerHTML = '';
                                spnTitle.innerHTML = '<a style="text-decoration-line: none; color: #000;"><span id="spnCountryFlag" style="top: 4px;display: inline-block;width: 25px;position: relative;" class="' + signerCode + '"></span>' + dialCode + '</a>';
                                let selectedDialCode = $("#spnTitle_" + roleSeqOrder).text();
                                if (selectedDialCode != null && selectedDialCode !== undefined && selectedDialCode != "") {
                                    spnTitle?.setAttribute('disabled', 'disabled');
                                    spnTitle.style.cursor = "no-drop";
                                    DialCodeUl.style.display = 'none';

                                    for (let index = 0; index < this.DialCodeDropdownList.length; index++) {
                                        const element = this.DialCodeDropdownList[index];
                                        if (element.CountryCode.toLowerCase() == this.InviteSignerDataList[i].countryCode.toLowerCase()) {
                                            txtInvSignMobile.setAttribute('maxlength', element.MobileMaxLength);
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                        else {
                            $("#spnTitle_" + roleSeqOrder).text('Select');
                        }
                        break;
                    }
                }
            }
            else if (this.InviteSignerDataList && this.InviteSignerDataList.length > 0 && roleSeqOrder == null) {
                for (let i = 0; i < this.InviteSignerDataList.length; i++) {
                    let selRowId = this.InviteSignerDataList[i].roleSeq;
                    let spnTitle: any = <HTMLSelectElement>document.getElementById("spnTitle_" + selRowId);
                    let DialCodeUl: any = <HTMLSelectElement>document.getElementById("DialCodeUl_" + selRowId);
                    let txtInvSignMobile: any = <HTMLSelectElement>document.getElementById("txtInvSignMobile_" + selRowId);
                    let radioInvitebyEmail: any = <HTMLSelectElement>document.getElementById("radioInvitebyEmail_" + selRowId);

                    spnTitle?.setAttribute('disabled', 'disabled');
                    spnTitle.style.cursor = "no-drop";
                    DialCodeUl.style.display = 'none';
                    txtInvSignMobile.setAttribute('disabled', 'disabled');
                    txtInvSignMobile.style.cursor = "no-drop";
                    radioInvitebyEmail.setAttribute('disabled', 'disabled');
                    radioInvitebyEmail.removeAttribute('checked');
                }
            }
        }
    }

    UpdateDeliveryMode(roleSeqOrder: any) {
        let spnTitle: any = <HTMLSelectElement>document.getElementById("spnTitle_" + roleSeqOrder);
        let DialCodeUl: any = <HTMLSelectElement>document.getElementById("DialCodeUl_" + roleSeqOrder);
        let txtInvSignMobile: any = <HTMLSelectElement>document.getElementById("txtInvSignMobile_" + roleSeqOrder);
        let radioInvitebyEmail: any = <HTMLSelectElement>document.getElementById("radioInvitebyEmail_" + roleSeqOrder);

        spnTitle?.setAttribute('disabled', 'disabled');
        spnTitle.style.cursor = "no-drop";
        DialCodeUl.style.display = 'none';
        txtInvSignMobile.setAttribute('disabled', 'disabled');
        txtInvSignMobile.style.cursor = "no-drop";
        radioInvitebyEmail.setAttribute('disabled', 'disabled');
        radioInvitebyEmail.removeAttribute('checked');
        $(radioInvitebyEmail).prop('checked', '');
        $(radioInvitebyEmail).removeAttr('checked');

        let radioSignNow: any = <HTMLSelectElement>document.getElementById("radioSignNow_" + roleSeqOrder);
        $(radioSignNow).prop('checked', 'checked');

        if (spnTitle != null) {
            spnTitle.innerHTML = '';
            spnTitle.innerHTML = 'Select';
        }

        txtInvSignMobile.value = '';
        let txtInvSignEmail: any = <HTMLSelectElement>document.getElementById("txtInvSignEmail_" + roleSeqOrder);
        txtInvSignEmail.style["background-color"] = "#fff";
        let ngbDropdown: any = <HTMLSelectElement>document.getElementById("ngbDropdown_" + roleSeqOrder);
        ngbDropdown.style["background-color"] = "#ffff";
        txtInvSignMobile.style["background-color"] = "#fff";
    }

    validateInputValue(event: any) {
        let txtSignerMobileNumber: any = event.target.value;
        if (!this.commonService.isNumberExists(txtSignerMobileNumber)) {
            event.target.value = '';
            this.toastr.warning('Please enter only a number.', 'Warning', { timeOut: 1000 });
        }
    }

    isValidPhone(phonenumber: any, slCountryMaxMobileLength: any) {
        var isvalidPhoneNumber = true;
        if (phonenumber == "" || phonenumber.length != slCountryMaxMobileLength) {
            isvalidPhoneNumber = false;
        }
        return isvalidPhoneNumber;
    }

    returnEmailAndMobileDeliveryModeVal(deliverymode: any) {
        return this.commonService.returnEmailAndMobileDeliveryModeVal(deliverymode);
    }

    returnEmailDeliveryModeVal(deliverymode: any) {
        return this.commonService.returnEmailBasedOnDeliveryModeVal(deliverymode);
    }

    returnMobileDeliveryModeVal(deliverymode: any) {
        return this.commonService.returnMobileDeliveryModeVal(deliverymode);
    }

    returnShowMobileDeliveryModeVal(deliverymode: any) {
        return this.commonService.returnShowMobileDeliveryModeVal(deliverymode);
    }

    returnShowEmailBasedOnDeliveryModeVal(deliverymode: any) {
        return this.commonService.returnShowEmailBasedOnDeliveryModeVal(deliverymode);
    }

    returnCodeToolTipText(deliverymode: any) {
        return this.commonService.returnCodeToolTipText(deliverymode);
    }

    ShowHideMessageCodePopOver(deliverymode: any, MyMessageTo: any, MyCodeTo: any, MyCoupontooltip: any) {
        var myMessageCode = this.commonService.returnCodeToolTipText(deliverymode);
        if (myMessageCode != "" && myMessageCode != null) {
            var messageCodeArray = myMessageCode.split('/');
            if (messageCodeArray.length == 2) {
                $("#" + MyMessageTo).text(messageCodeArray[0].trim());
                $("#" + MyCodeTo).text(messageCodeArray[1].trim());
                $("." + MyCoupontooltip).css('display', 'block');
                $("#MySpnRecpGenericMsgDeliveryMode").text(messageCodeArray[0].trim() + "/"+ messageCodeArray[1].trim());
                if((deliverymode == 1 || deliverymode == 11)&& this.webOrMobile == "web"){
                    let MyCoupontooltip:any = document.getElementById("MyCoupontooltip");
                    if(MyCoupontooltip){
                        $("#MyCoupontooltip").css('margin-left',"14px");
                    } 
                    let MyCoupontooltipMsg:any = document.getElementById("MyCoupontooltipMsg");
                    if(MyCoupontooltipMsg){
                        $("#MyCoupontooltipMsg").css('margin-left',"14px").css('margin-top','-47px');
                    }
                }
                else if((deliverymode == 4 || deliverymode == 6) && this.webOrMobile == "web"){
                    let MyCoupontooltip:any = document.getElementById("MyCoupontooltip");
                    if(MyCoupontooltip){
                        $("#MyCoupontooltip").css('margin-left',"-62px").css('margin-top','-107px');
                    }  
                    let MyCoupontooltipMsg:any = document.getElementById("MyCoupontooltipMsg");
                    if(MyCoupontooltipMsg){
                        $("#MyCoupontooltipMsg").css('margin-left',"14px").css('margin-top','-47px');
                    }                  
                }
                else if(this.webOrMobile == "web"){
                    let MyCoupontooltip:any = document.getElementById("MyCoupontooltip");
                    if(MyCoupontooltip){
                        $("#MyCoupontooltip").css('margin-left',"-482px").css('margin-top',"-64px").css('width','483px');
                    }                     
                    let MyCoupontooltipMsg:any = document.getElementById("MyCoupontooltipMsg");
                    if(MyCoupontooltipMsg){
                        $("#MyCoupontooltipMsg").css('margin-left',"-126px").css('margin-top','-81px');
                    } 
                }
            }
        }
    }

    showInviteMessageCodePopOver(type: any, roleSeq: any) { //web
        if (type == 1) {
            let currentRowDeliveryMode: any = document.getElementById("DeliveryMode_" + roleSeq);
            let currentRowDeliveryModeValue = currentRowDeliveryMode.value;
            if (currentRowDeliveryModeValue != "" && currentRowDeliveryModeValue != null && currentRowDeliveryModeValue != undefined) {
                this.ShowHideMessageCodePopOver(currentRowDeliveryModeValue, "messageTo_" + roleSeq, "codeTo_" + roleSeq, "Coupontooltip_" + roleSeq);
            }
        }
        else {
            $(".Coupontooltip_" + roleSeq).css('display', 'none');
        }
    }

    showInviteMessageCodePopOverMobile(roleSeq: any) {//mobile
        let coupontooltip = document.getElementById("Coupontooltip_" + roleSeq);
        let coupontooltipStyle = coupontooltip!.style['display'];

        if (coupontooltipStyle == "none" || coupontooltipStyle == "" || coupontooltipStyle == undefined) {
            let currentRowDeliveryMode: any = document.getElementById("DeliveryMode_" + roleSeq);
            let currentRowDeliveryModeValue = currentRowDeliveryMode.value;
            if (currentRowDeliveryModeValue != "" && currentRowDeliveryModeValue != null && currentRowDeliveryModeValue != undefined) {
                this.ShowHideMessageCodePopOver(currentRowDeliveryModeValue, "messageTo_" + roleSeq, "codeTo_" + roleSeq, "Coupontooltip_" + roleSeq);
            }
        }
        else {
            $(".Coupontooltip_" + roleSeq).css('display', 'none');
        }
    }

    showMessageCodePopOver(type: any) { //web
        if (type == 1) {
            this.ShowHideMessageCodePopOver(this.currentRecipientDeliveryMode, "MyMessageTo", "MyCodeTo", "MyCoupontooltip");
        }
        else {
            $(".MyCoupontooltip").css('display', 'none');
        }
    }

    showMessageCodePopOverMobile() { //mobile
        let MyCoupontooltip = document.getElementById("MyCoupontooltip");
        let MyCoupontooltipStyle = MyCoupontooltip!.style['display'];
        if (MyCoupontooltipStyle == "none" || MyCoupontooltipStyle == "" || MyCoupontooltipStyle == undefined) {
            this.ShowHideMessageCodePopOver(this.currentRecipientDeliveryMode, "MyMessageTo", "MyCodeTo", "MyCoupontooltip");
        }
        else {
            $(".MyCoupontooltip").css('display', 'none');
        }
    }
}

