<app-header></app-header>
<main class="container-login100">
<div class="wrapperIndex">
    <div class="dvRadius">
        <div class="divMainWrapper user_card" >
            <div id="formContent" class="dvglobalInfo_2">
                <div class="dvdevices">
                    <img src="../../../assets/images/security_image.jpg" style="width:60px" />                  
                    <h4 class="Globalinfo_1 mt-3 mb-3 font-w600" style="color: #d21513">Are you a robot?</h4>
                    <span style="font-size: 16px;font-weight: bold;"> We're only accepting e-signature from humans.</span><br />
                    <span style="font-size: 16px;font-weight: bold;"> Please verify below</span>

                    <form #form="ngForm">
                        <div class="row mt-3 divCaptchaForm">
                          <div class="col-sm-12 mb-2">
                            <re-captcha id="recaptcha" name="recaptcha" #recaptcha="ngModel" [(ngModel)]="token" required [class.is-invalid]="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)"></re-captcha>
                            <div *ngIf="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)" class="invalid-feedback">
                              <div style="width: 132px;" *ngIf="recaptcha.errors?.['required']">This field is required.</div>
                            </div>
                          </div>
                        </div>
                        <div class="row form-buttons">
                          <div class="col-sm-12 mb-2">
                            <button type="button" id="btnValidateCaptcha" class="btn btn-full-width cursorpoint" [disabled]="!form.valid"  (click)="validateCaptcha(form)">Continue</button>
                          </div>
                        </div>
                      </form>
                </div>               
            </div>
        </div>
    </div>
</div>
</main>
<footer style="background: #eee;" class="default-footer mt-auto pt-2 pb-2">
    <div style="font-size: 12px;">
        <div class="fotertext" [innerHTML]="htmlFooterString">
        </div>
    </div>
</footer>

<div *ngIf="showLoader" class="loader">
</div>